import formatText from '../../../../../../../../utils/formatText';
import { useQuickMessage } from '../../hooks/useQuickMessage';

export interface PreviewBodyProps {
  body: string;
}
export function PreviewBody({ body }: Readonly<PreviewBodyProps>) {
  const { getMessageTextUI } = useQuickMessage();
  return (
    <p
      dangerouslySetInnerHTML={{
        __html: formatText(getMessageTextUI(body, 'quick')),
      }}
    ></p>
  );
}
