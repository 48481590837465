import React from 'react';

// @ts-ignore
import { Button, Icon } from '@polichat/flamboyant';

import CreditIndexNewPoliImg from '../../../../../../assets/images/newIdentity/CreditIndexImg.svg';

import { Container } from './CreditIndex.styles';

interface ToggleModalAddCredit {
  (): void;
}

interface FooProp {
  toggleModalAddCredit: ToggleModalAddCredit;
  screenAdmin: boolean;
}

const CreditIndex = (props: FooProp) => {
  const { toggleModalAddCredit, screenAdmin } = props;

  return (
    <>
      <Container>
        <img
          src={CreditIndexNewPoliImg}
          alt="Imagem ilustrativa de carrinho de compras."
        />
        <h2>Nenhum crédito adicionado</h2>

        <p>
          {screenAdmin
            ? 'Selecione um cliente e clique em adicionar crédito.'
            : 'Você não possui créditos adicionados, para adicionar créditos clique no botão abaixo.'}
        </p>

        {!screenAdmin && (
          <Button gradient="primary" onClick={toggleModalAddCredit}>
            Adicionar crédito
            <Icon
              icon="poli-icon pi-add-circle-line"
              size={24}
              position={'relative'}
              top={3}
              left={5}
            />
          </Button>
        )}
      </Container>
    </>
  );
};

export default CreditIndex;
