import {
  Modal,
  ModalBody,
  ModalHeader,
  Table,
  Button,
  Icon,
  Form,
  CustomInput,
  FormGroup,
  Label,
  Row,
  Col,
  Alert,
} from '@polichat/flamboyant';
import { useState } from 'react';
import { toast } from 'react-toastify';

import poliConfig from '../../../../../utils/poliConfig';

import { DtoAdditionalPlanInfo } from '../../../../../services/tools/channels/additionalPlans/dto';
import { priceMask } from '../../../../../utils/priceMask';
import { useCustomerHireAdditionalChannel } from '../useCustomerHireAdditionalChannel';
interface ChannelPlanInfo extends DtoAdditionalPlanInfo {
  additional_id: number | undefined;
}

interface ModalHireAdditionalChannelProps {
  modalIsOpen: boolean;
  toggleModal: () => {};
  channelPlanInfo: ChannelPlanInfo | null | undefined;
  customerId: number;
  blocked: boolean;
  closeModalAction: () => void;
}

export function ModalHireAdditionalChannel(
  props: ModalHireAdditionalChannelProps
) {
  /**
   * Destruction
   * https://javascript.info/destructuring-assignment
   * To show JavaScript that it’s not a code block, we can wrap the expression in parentheses (...):
   */
  let name,
    additional_price,
    current_total,
    max_quantity_plan,
    additional_id: number;

  if (props.channelPlanInfo) {
    ({
      name,
      additional_price,
      current_total,
      max_quantity_plan,
      // @ts-ignore
      additional_id,
    } = props?.channelPlanInfo);
  }

  const {
    loading: loadingCustomerHireAdditionalChannel,
    error: errorCustomerHireAdditional,
    hiredChannelInfo,
    hireAdditionalChannels,
  } = useCustomerHireAdditionalChannel();

  const [canCloseTheModal, setCanCloseTheModal] = useState(true);
  const [hasOpenTheBillet, setHasOpenTheBillet] = useState(false);

  /*
   * controll form inputs
   */
  const [quantityAdditionalChannel, setQuantityAdditionalChannel] = useState(1);

  function handleCloseModal() {
    if (canCloseTheModal || errorCustomerHireAdditional) {
      props.toggleModal();
      hasOpenTheBillet && props.closeModalAction();
      return;
    }
    toast.warn('Antes de finalizar salve o seu boleto!');
  }

  async function handleHireAdditionalChannel() {
    setCanCloseTheModal(false);
    await hireAdditionalChannels(
      props.customerId,
      additional_id,
      quantityAdditionalChannel
    );
  }

  function handleOpenBillet() {
    window.open(`${hiredChannelInfo?.link_invoice}`, '_blank');
    setCanCloseTheModal(true);
    setHasOpenTheBillet(true);
  }

  const manageURL = `${poliConfig.poliAppLaravelUrl}/manager/${props.customerId}`;
  function redirectManagerPage() {
    window.location.assign(manageURL);
  }

  return (
    <>
      {/* blocked by overdue */}
      {props.blocked && (
        <>
          <Modal isOpen={props.modalIsOpen} toggle={props.toggleModal}>
            <ModalHeader toggle={props.toggleModal}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Icon
                  icon="poli-icon pi-sad-line"
                  size={40}
                  color="var(--danger)"
                />
                <h5
                  style={{
                    fontSize: '1.3rem',
                    fontWeight: '700',
                    color: `var(--danger)`,
                    marginLeft: '10px',
                  }}
                >
                  Você possui pendências financeiras!
                </h5>
              </div>
            </ModalHeader>
            <ModalBody style={{ marginBottom: '0', padding: '0.5rem' }}>
              <Row>
                <Col></Col>
                <Col xs="auto">
                  Antes de contratar um novo canal adicional, verifique seu
                  plano!
                </Col>
                <Col></Col>
              </Row>
              <Row>
                <Col></Col>
                <Col xs="auto">
                  <Button onClick={redirectManagerPage}>Verificar plano</Button>
                </Col>
                <Col></Col>
              </Row>
            </ModalBody>
          </Modal>
        </>
      )}
      {/* hire additional plan modal */}
      {props?.channelPlanInfo && (
        <>
          <Modal isOpen={props.modalIsOpen} toggle={handleCloseModal}>
            <ModalHeader toggle={handleCloseModal}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Icon
                  icon="poli-icon pi-channels-conections-line"
                  size={40}
                  color="var(--info)"
                />
                <h5
                  style={{
                    fontSize: '1.5rem',
                    fontWeight: '700',
                    color: `var(--info)`,
                    marginLeft: '10px',
                  }}
                >
                  Contratar canal adicional
                </h5>
              </div>
            </ModalHeader>
            <ModalBody style={{ marginBottom: '0', padding: '0.5rem' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <p>
                  <strong>Dados do plano:</strong> {name}
                </p>
              </div>
              <Table hover size="sm">
                <thead>
                  <tr>
                    <th>Quantidade de canais do plano</th>
                    <th>Total de canais contratados</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    style={{
                      borderTop: '1px solid #F7F7F7',
                      borderBottom: '1px solid #F7F7F7',
                      padding: '.2rem 0',
                    }}
                  >
                    <th>{max_quantity_plan}</th>
                    <th>{current_total}</th>
                  </tr>
                </tbody>
              </Table>
            </ModalBody>
            <ModalBody style={{ marginBottom: '0', padding: '0.5rem' }}>
              <Form>
                <FormGroup>
                  <Row>
                    <Col>
                      <Label for="additionalChannelQuantity">
                        Canais adicionais:{' '}
                      </Label>
                      <CustomInput
                        id="additionalChannelQuantity"
                        placeholder="Número de canais adicionais"
                        min={1}
                        max={100}
                        type="number"
                        step="1"
                        value={quantityAdditionalChannel}
                        onChange={(e: any) =>
                          setQuantityAdditionalChannel(e.target.value)
                        }
                      />
                    </Col>
                    <Col>
                      <Label for="additionalChannelPrice">Valor Total:</Label>
                      <span id="additionalChannelPrice">
                        {' '}
                        <strong>
                          {priceMask(
                            String(
                              Number(
                                additional_price ??
                                  1 * quantityAdditionalChannel
                              ).toFixed(2)
                            )
                          )}
                        </strong>
                      </span>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  {!hiredChannelInfo?.success && (
                    <>
                      {errorCustomerHireAdditional && (
                        <Row>
                          <Col></Col>
                          <Col xs="auto">
                            <Alert color="danger">
                              Erro ao comprar canal, tente novamente!
                            </Alert>
                          </Col>
                          <Col></Col>
                        </Row>
                      )}
                      <Row>
                        <Col></Col>
                        <Col xs="auto">
                          <Button
                            primary
                            onClick={() => handleHireAdditionalChannel()}
                          >
                            <Icon icon="poli-icon pi-debit-line" size={20} />
                            {loadingCustomerHireAdditionalChannel
                              ? 'Carregando...'
                              : 'Comprar canal adicional'}
                          </Button>
                        </Col>
                        <Col></Col>
                      </Row>
                    </>
                  )}
                  {hiredChannelInfo?.success && (
                    <>
                      <Row>
                        <Col></Col>
                        <Col xs="auto">
                          <Alert color="success">
                            Canal contratado com sucesso!
                          </Alert>
                        </Col>
                        <Col></Col>
                      </Row>
                      <Row>
                        <Col></Col>
                        <Col xs="auto">
                          <Button
                            color="warning"
                            onClick={() => handleOpenBillet()}
                          >
                            <Icon icon="poli-icon pi-attach-line" size={20} />
                            Abrir boleto
                          </Button>
                        </Col>
                        <Col></Col>
                      </Row>
                    </>
                  )}
                </FormGroup>
              </Form>
            </ModalBody>
          </Modal>
        </>
      )}
    </>
  );
}
