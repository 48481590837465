import {useState} from 'react';
import {Col, FormGroup, Label, CustomText, Input} from '@polichat/flamboyant';

function TemplateTagInput({onUpdate, initialValue}) {
  const [templateTag, setTemplateTag] = useState({
    templateTag: initialValue
  });

  const handleInputChange = ({target: {name, value}}) => {
    setTemplateTag({
      ...templateTag,
      [name]: value
    });
    handleTemplateTagData({
      ...templateTag,
      [name]: value
    })
  };

  const handleTemplateTagData = (data) => {
    onUpdate(data)
  }

  return (
  <Col sm={12} md={6} >
    <FormGroup>
      <Label>Tag:</Label>
      <Input
        type="text"
        id="template-tag"
        name= "templateTag"
        placeholder={"#tag da mensagem"}
        value={templateTag.templateTag}
        onChange={handleInputChange}
        required
        />
    </FormGroup>
  </Col>
)}

export default TemplateTagInput;
