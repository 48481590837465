import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, MultiSelector, FormGroup, CustomInput, Label } from '@polichat/flamboyant';

import ChannelsService from '../../../../../../services/tools/channels/channels';

function SelectAttendants({ onUpdate, initialValues }) {
  const customerID = useSelector((state) => state.general?.current_customer_id);
  const [attendantsList, setAttendantsList] = useState([]);
  const [selectAttendants, setSelectAttendants] = useState('');

  useEffect(() => {
    async function fetchUsers() {
      try {
        const result = await ChannelsService.getAllUsersByCustomer(customerID);
        if (result?.data) {
          const attendantsToSelect = result.data.map((attendant) => ({
            ...attendant,
            value: attendant.id,
            label: attendant.name,
          }));
          // const attendantsToSelect = result.data;

          setAttendantsList(attendantsToSelect);

          // const initialState = attendantsToSelect?.reduce((acc, value) => {
          //   initialValues?.map((a) => (a === value.id ? acc.push(value) : acc));
          //   return acc;
          // }, []);
          setSelectAttendants(initialValues || '');
        }
      } catch (error) {
        console.error(
          'Error in SelectAttendants component when getAllUsersByCustomer => ',
          error
        );
      }
    }
    fetchUsers();
  }, []);

  const handleGetMultiAttendants = (attendant_id) => {
    setSelectAttendants(attendant_id);
    // onUpdate({ attendants: attendant_id.map((attd) => attd.value) });
    onUpdate(attendant_id);
  };

  return (
    <Col sm={12} md={6}>
      <FormGroup>
        <Label>Atendentes:</Label>
        {/* <MultiSelector
          name="select-users-channel"
          id="select-users-channel"
          options={attendantsList}
          selectedValues={handleGetMultiAttendants}
          values={selectAttendants}
        /> */}
        <CustomInput
          type="select"
          id="select-departments-channel"
          name="select-departments-channel"
          value={selectAttendants}
          onChange={(e) => handleGetMultiAttendants(e.target.value)}
        >
          <option> --- Atendentes --- </option>
          {attendantsList?.map((attendant) => (
            <option key={`${attendant.id}${Math.random()}`} value={attendant.id}>
              {attendant.name}
            </option>
          ))}
        </CustomInput>
      </FormGroup>
    </Col>
  );
}

export default SelectAttendants;
