import { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';

const integrationStatus = {
  OFF: {
    status: 'off',
    statusColor: '--gray-75',
    message: [
      'Sua conta ainda não está conectada ao RDStation',
      'Siga os passos abaixo para conectar ao RDStation e ter todas as opções disponiveis em sua conta',
    ],
  },
  ON: {
    status: 'on',
    statusColor: '--success',
    message: [
      'Sua conta está conectada',
      'Você já pode se comunicar com seus contatos!',
    ],
  },
  ERROR: {
    status: 'error',
    statusColor: '--danger',
    message: [
      'Sua conta não está conectada',
      'Tente realizar a conexão novamente.',
    ],
  },
};

function useRDStation({ integrationDataReceived }) {
  const [status, setStatus] = useState(integrationStatus.OFF);

  useEffect(() => {

    console.log("integrationDataReceived", integrationDataReceived);
    if (integrationDataReceived?.error) {
      setStatus(integrationStatus.ERROR);
    } else if (integrationDataReceived?.id) {
      setStatus(integrationStatus.ON);
    } else  {
      setStatus(integrationStatus.OFF);
    }
  }, [integrationDataReceived]);

  return {
    status
  };
}

export default useRDStation;
