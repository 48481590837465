import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ListParams } from '../../../../../../services/tools/channels/logsChannels/logs/channels/params';
import { validationSchema } from './validation';

export function useLocalForm() {
  const form = useForm<Partial<ListParams>>({
    defaultValues: {
      customer_id: undefined,
      channel_id: undefined,
      type_change: '',
      user_id: undefined,
      date_start: '',
      date_end: '',
    },
    resolver: yupResolver(validationSchema),
  });

  const { watch } = form;
  const DateStart = watch('date_start');

  useEffect(() => {
    if (DateStart === '') {
      form.resetField('date_end');
    }
  }, [DateStart]);

  return {
    ...form,
    watchValues: {
      DateStart,
    },
  };
}
