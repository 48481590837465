import React from 'react';

import PageMainHeader from '../../../common/pages/PageMainHeader/PageMainHeader.component';
import PageMainBody from '../../../common/pages/PageMainBody/PageMainBody.component';

import ProgressBar from './ProgressBar/ProgressBar.component';

import FillForm from './steps/FillForm/FillForm.component';
import Congratulations from './steps/Congratulations/Congratulations.component';

import { Container } from './Certification.styles';

const Certification = ({ currentStep, data, dataSet, onUpdate }) => {
  let content = <></>;

  switch (currentStep) {
    case 2:
      content = (
        <FillForm data={data} dataSet={dataSet} currentStep={currentStep} />
      );
      break;

    case 3:
      content = (
        <FillForm data={data} dataSet={dataSet} currentStep={currentStep} />
      );
      break;

    case 4:
      content = <Congratulations onUpdate={onUpdate}></Congratulations>;
      break;

    default:
      break;
  }

  return (
    <>
      <PageMainHeader
        title="Adicionando Canal"
        icon="poli-icon pi-channels-line"
      />

      <PageMainBody>
        <Container>
          <ProgressBar currentStep={currentStep} />
          {content}
        </Container>
      </PageMainBody>
    </>
  );
};

export default Certification;
