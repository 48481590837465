import React from 'react';
import { Icon } from '@polichat/flamboyant';

import { bindActionCreators } from 'redux';
import * as ChatActions from '../../../store/modules/chat/actions';
import { connect } from 'react-redux';

function ChannelIcon({ props, channels }) {
  const channel_id = props.channel_id;
  let channel;

  if (channels && channel_id) {
    channel = channels.find((channel) => channel.id === channel_id);
    if (channel && channel.icon) {
      return (
        <Icon
          icon={channel.icon}
          size={16}
          color={channel.icon_color}
        />
      );
    } else {
      return <Icon icon="poli-icon pi-alert-fill" size={16} color="var(--warning)" />;
    }
  } else {
    return <></>;
  }
}

const mapStateToProps = (state, ownProps) => ({
  channels: state.chat?.config?.general?.channels,
  props: ownProps,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ChatActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChannelIcon);
