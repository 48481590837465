import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { Button } from '@polichat/flamboyant';

import { ActionGroup } from './ActionGroupButtons.styles';

const ActionGroupButtons = ({onClickBack, onClickNext, nextButtonActived}) => (
  <ActionGroup>
    <Button
      color="danger"
      size={isMobile ? 'md' : 'lg'}
      onClick={onClickBack}
    >
      Cancelar
    </Button>
    <Button
      disabled={nextButtonActived}
      color="primary"
      size={isMobile ? 'md' : 'lg'}
      onClick={onClickNext}
    >
      Próximo
    </Button>
  </ActionGroup>
);

export default ActionGroupButtons;
