import { useState } from 'react';
import { InputGroup, Input } from '@polichat/flamboyant'

function SearchTemplateBar({onSearchChange, initialValue}) {

  const [filterTemplate, setFilterTemplate] = useState({
    search: initialValue,
  });

  const handleInputChange = ({target: {name, value}}) => {
    setFilterTemplate({
      ...filterTemplate,
      [name]: value
    });
    onSearchChange({
      ...filterTemplate,
      [name]: value
    })
  };


  return (
    <InputGroup>
      <Input
        type="text"
        placeholder="Buscar..."
        id="filter-template"
        name="search"
        value={filterTemplate.search}
        onChange={handleInputChange}
      />
    </InputGroup>
  );
}

export default SearchTemplateBar;
