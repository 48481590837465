import React, { useRef } from 'react';

import { Button, Icon } from '@polichat/flamboyant';
import Animation from '../../../../common/animation';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ChatActions from '../../../../../store/modules/chat/actions';

import ClickOutsideEvent from '../../../../../events/ClickOutsideEvent';

function FilterModalTemplate(props) {
  const wrapperRef = useRef(null);
  if (props.toggleEvent) {
    ClickOutsideEvent(wrapperRef, props.toggleEvent);
  }

  if (props.isOpen)
    return (
      <div
        className="filter-chat-modal"
        id={props.id || 'attendance_chat_modal'}
        ref={wrapperRef}
      >
        <div className="filter-header">
          <Icon icon={props.icon} size={23} color={'var(--green-polichat)'} />
          <span className="filter-title">{props.title}</span>
        </div>

        <div className="filter-body">{props.content}</div>

        <div className="filter-bottom">
          {props.disabled ? (
            <></>
          ) : props.loading ? (
            <Animation icon="loading" size={'tiny'} />
          ) : (
            <Button
              onClick={props.onSubmit}
              bsclass="filter-button"
              className="filter-button"
              size="lg"
              color="primary"
              block
            >
              Filtrar
            </Button>
          )}
        </div>
      </div>
    );
  else return <></>;
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ChatActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterModalTemplate);
