import React from 'react';

import AttendanceModal from './AttendanceModal';
import DepartmentsModal from './DepartmentsModal';
import TagsModal from './TagsModal';
import ChannelsModal from './ChannelsModal';
import ChatsModal from './ChatsModal';
import DateModal from './DateModal';

function FilterModals() {
  return (
    <>
      <AttendanceModal />
      <DepartmentsModal />
      <TagsModal />
      <ChannelsModal />
      <ChatsModal />
      <DateModal />
    </>
  );
}

export default FilterModals;
