/**
 * Ref.:
 * https://developers.facebook.com/docs/messenger-platform/messenger-profile/supported-locales/
 * https://mypresta.eu/en/art/know-how/facebook-list-of-local-language-codes.html
 */
export const facebookSupportedLanguages = [
  { id: 'af_ZA', language: 'Afrikaans' },
  { id: 'gn_PY', language: 'Guaraní' },
  { id: 'ay_BO', language: 'Aymara' },
  { id: 'az_AZ', language: 'Azeri' },
  { id: 'id_ID', language: 'Indonesian' },
  { id: 'ms_MY', language: 'Malay' },
  { id: 'jv_ID', language: 'Javanese' },
  { id: 'bs_BA', language: 'Bosnian' },
  { id: 'ca_ES', language: 'Catalan' },
  { id: 'cs_CZ', language: 'Czech' },
  { id: 'ck_US', language: 'Cherokee' },
  { id: 'cy_GB', language: 'Welsh' },
  { id: 'da_DK', language: 'Danish' },
  { id: 'se_NO', language: 'Northern Sámi' },
  { id: 'de_DE', language: 'German' },
  { id: 'et_EE', language: 'Estonian' },
  { id: 'en_IN', language: 'English (India)' },
  { id: 'en_PI', language: 'English (Pirate)' },
  { id: 'en_GB', language: 'English (UK)' },
  { id: 'en_UD', language: 'English (Upside Down)' },
  { id: 'en_US', language: 'English (US)' },
  { id: 'es_LA', language: 'Spanish' },
  { id: 'es_CL', language: 'Spanish (Chile)' },
  { id: 'es_CO', language: 'Spanish (Colombia)' },
  { id: 'es_ES', language: 'Spanish (Spain)' },
  { id: 'es_MX', language: 'Spanish (Mexico)' },
  { id: 'es_VE', language: 'Spanish (Venezuela)' },
  { id: 'eo_EO', language: 'Esperanto' },
  { id: 'eu_ES', language: 'Basque' },
  { id: 'tl_PH', language: 'Filipino' },
  { id: 'fo_FO', language: 'Faroese' },
  { id: 'fr_FR', language: 'French (France)' },
  { id: 'fr_CA', language: 'French (Canada)' },
  { id: 'fy_NL', language: 'Frisian' },
  { id: 'ga_IE', language: 'Irish' },
  { id: 'gl_ES', language: 'Galician' },
  { id: 'ko_KR', language: 'Korean' },
  { id: 'hr_HR', language: 'Croatian' },
  { id: 'xh_ZA', language: 'Xhosa' },
  { id: 'zu_ZA', language: 'Zulu' },
  { id: 'is_IS', language: 'Icelandic' },
  { id: 'it_IT', language: 'Italian' },
  { id: 'ka_GE', language: 'Georgian' },
  { id: 'sw_KE', language: 'Swahili' },
  { id: 'tl_ST', language: 'Klingon' },
  { id: 'ku_TR', language: 'Kurdish' },
  { id: 'lv_LV', language: 'Latvian' },
  { id: 'fb_LT', language: 'Leet Speak' },
  { id: 'lt_LT', language: 'Lithuanian' },
  { id: 'li_NL', language: 'Limburgish' },
  { id: 'la_VA', language: 'Latin' },
  { id: 'hu_HU', language: 'Hungarian' },
  { id: 'mg_MG', language: 'Malagasy' },
  { id: 'mt_MT', language: 'Maltese' },
  { id: 'nl_NL', language: 'Dutch' },
  { id: 'nl_BE', language: 'Dutch (België)' },
  { id: 'ja_JP', language: 'Japanese' },
  { id: 'nb_NO', language: 'Norwegian (bokmal)' },
  { id: 'nn_NO', language: 'Norwegian (nynorsk)' },
  { id: 'uz_UZ', language: 'Uzbek' },
  { id: 'pl_PL', language: 'Polish' },
  { id: 'pt_BR', language: 'Portuguese (Brazil)' },
  { id: 'pt_PT', language: 'Portuguese (Portugal)' },
  { id: 'qu_PE', language: 'Quechua' },
  { id: 'ro_RO', language: 'Romanian' },
  { id: 'rm_CH', language: 'Romansh' },
  { id: 'ru_RU', language: 'Russian' },
  { id: 'sq_AL', language: 'Albanian' },
  { id: 'sk_SK', language: 'Slovak' },
  { id: 'sl_SI', language: 'Slovenian' },
  { id: 'so_SO', language: 'Somali' },
  { id: 'fi_FI', language: 'Finnish' },
  { id: 'sv_SE', language: 'Swedish' },
  { id: 'th_TH', language: 'Thai' },
  { id: 'vi_VN', language: 'Vietnamese' },
  { id: 'tr_TR', language: 'Turkish' },
  { id: 'zh_CN', language: 'Simplified Chinese (China)' },
  { id: 'zh_TW', language: 'Traditional Chinese (Taiwan)' },
  { id: 'zh_HK', language: 'Traditional Chinese (Hong Kong)' },
  { id: 'el_GR', language: 'Greek' },
  { id: 'gx_GR', language: 'Classical Greek' },
  { id: 'be_BY', language: 'Belarusian' },
  { id: 'bg_BG', language: 'Bulgarian' },
  { id: 'kk_KZ', language: 'Kazakh' },
  { id: 'mk_MK', language: 'Macedonian' },
  { id: 'mn_MN', language: 'Mongolian' },
  { id: 'sr_RS', language: 'Serbian' },
  { id: 'tt_RU', language: 'Tatar' },
  { id: 'tg_TJ', language: 'Tajik' },
  { id: 'uk_UA', language: 'Ukrainian' },
  { id: 'hy_AM', language: 'Armenian' },
  { id: 'yi_DE', language: 'Yiddish' },
  { id: 'he_IL', language: 'Hebrew' },
  { id: 'ur_PK', language: 'Urdu' },
  { id: 'ar_AR', language: 'Arabic' },
  { id: 'ps_AF', language: 'Pashto' },
  { id: 'fa_IR', language: 'Persian' },
  { id: 'sy_SY', language: 'Syriac' },
  { id: 'ne_NP', language: 'Nepali' },
  { id: 'mr_IN', language: 'Marathi' },
  { id: 'sa_IN', language: 'Sanskrit' },
  { id: 'hi_IN', language: 'Hindi' },
  { id: 'bn_IN', language: 'Bengali' },
  { id: 'pa_IN', language: 'Punjabi' },
  { id: 'gu_IN', language: 'Gujarati' },
  { id: 'ta_IN', language: 'Tamil' },
  { id: 'te_IN', language: 'Telugu' },
  { id: 'kn_IN', language: 'Kannada' },
  { id: 'ml_IN', language: 'Malayalam' },
  { id: 'km_KH', language: 'Khmer' },
];
