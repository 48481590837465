import React from 'react';
import { Icon } from '@polichat/flamboyant';

import {
  Container,
  TitleContainer,
  Title,
  ActionContainer,
} from './PageMainHeader.styles';

function MainHeader({ icon, title, children, iconSize }) {
  return (
    <Container>
      <TitleContainer>
        <Icon icon={icon} size={iconSize ?? 20} />
        <Title>{title}</Title>
      </TitleContainer>
      <ActionContainer>{children}</ActionContainer>
    </Container>
  );
}

export default MainHeader;
