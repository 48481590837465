import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from '@polichat/flamboyant';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ChatActions from '../../../store/modules/chat/actions';
import ContactsScreen from './filter/ContactsScreen';
import FilterDropdown from './filter/FilterDropdown';
import { ChevronDownLine, ChevronUpLine } from '@poli-ink/react';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';

function NewTabs({
  openChatTab,
  activeTab,
  openInProgressTab,
  openUnReadsTab,
  activeFilters,
  removeFilter,
  currentPageState,
  fetchMoreChatsRequest,
  searchState,
  clearResultsInSearchChats,
  clearCustomFilterPage,
  submitFilter,
  clearFilter,
  currentUser,
  hasAsyncRequest,
  openNoAttendantTab,
  noAttendantTabActive,
}) {
  const searchValue = searchState.current || '';
  const allCurrentPage = currentPageState?.all?.page;
  const inProgressCurrentPage = currentPageState?.inProgress?.page;
  const unReadsCurrentPage = currentPageState?.unReads?.page;
  const searchPage = currentPageState?.search?.page;
  const filtersActive = activeFilters;
  const nameTab = {
    inprogress: {
      name: 'Em atendimento',
    },
    chat: {
      name: 'Todas as conversas',
    },
    noAttendant: {
      name: 'Sem atendente',
    },
    unreads: {
      name: 'Não lidas',
    },
  };

  const [toggleDropdown, setToggleDropdown] = useState(false);
  const [showNameTab, setShowNameTab] = useState(nameTab.chat.name);

  /**
   * Effect Hook -
   * verifica qual a aba ativa e se alterou o tipo de filtro de sem atendente
   * para setar o nome do Dropdown
   */
  useEffect(() => {
    if (activeTab === 'inprogress') {
      setShowNameTab(nameTab.inprogress.name);
    } else if (noAttendantTabActive) {
      setShowNameTab(nameTab.noAttendant.name);
    } else if (activeTab === 'chat') {
      setShowNameTab(nameTab.chat.name);
    } else if (activeTab === 'unreads') {
      setShowNameTab(nameTab.unreads.name);
    }
  }, [activeTab, activeFilters?.attendant?.length, noAttendantTabActive]);

  // Essa variável é usada para identificar algum loading dentro do chat
  // Será utilizada como condicional para permitir um clique ou não
  const somethingIsLoading = hasAsyncRequest;
  // Esta como string, devido que removi o count que fica entre parenteses
  // (${ countNoReads || '0' }) Será feito essa parte em outra sprint.
  const titleTab = `${showNameTab}`;

  let activeFiltersIsFill = false;

  if (
    filtersActive.attendant.length > 0 ||
    filtersActive.date.length > 0 ||
    filtersActive.department.length > 0 ||
    filtersActive.tag.length > 0 ||
    filtersActive.chat.length > 0 ||
    filtersActive.channel.length > 0
  ) {
    activeFiltersIsFill = true;
  }

  let customFilterPage = currentPageState?.customFilter.page;
  let page;

  const onClickTab = (screen) => {
    removeFilter('chat');

    if (
      currentPageState?.customFilter.page &&
      currentPageState?.customFilter.page > 0
    ) {
      // Resetar CustomFilter para começar buscando do início
      clearCustomFilterPage();
      customFilterPage = 0;
    }

    if (screen === 'all') {
      page = allCurrentPage;
    } else if (screen === 'currents') {
      page = inProgressCurrentPage;
    } else if (screen === 'noread') {
      page = unReadsCurrentPage;
    }

    // Validação se o campo de busca está ativo / preenchido
    if (searchValue && searchValue.length > 0 && searchPage) {
      page = searchPage;
    }

    if (customFilterPage >= 0 && activeFiltersIsFill) {
      page = customFilterPage;
    }

    fetchMoreChatsRequest(page, filtersActive, searchValue, screen);
  };

  function handleTabToNoAttendant() {
    handleTab('chat');
    let payload = Object.assign({}, activeFilters);
    try {
      let value = parseInt(-1);
      payload.attendant = [
        {
          value: value,
        },
      ];
      setShowNameTab(nameTab.noAttendant.name);
      submitFilter(payload, 'all');
    } catch {
      payload.attendant = [];
      clearFilter(payload);
    }
  }

  // Variável usada para identificar qual "tabContainer" receberá o estilo de ativo
  function handleTab(nameTab) {
    if (searchValue && searchValue.length > 0) {
      clearResultsInSearchChats();
    }
    switch (nameTab) {
      case 'inprogress':
        onClickTab('currents');
        openInProgressTab();
        break;
      case 'chat':
        onClickTab('all');
        openChatTab();
        break;
      case 'unreads':
        onClickTab('noread');
        openUnReadsTab();
        break;
    }
    // se o filtro de sem atendente estiver ativo ao trocar de aba deve limpar
    // caso tenha outros filtro de atendende deve manter os filtro atuais
    if (noAttendantTabActive) {
      removeFilter('attendant');
    }
  }

  function callHandleFuntion(nameTab) {
    if (nameTab !== 'noAttendant') {
      handleTab(nameTab);
    } else {
      handleTabToNoAttendant();
      openNoAttendantTab();
    }
  }

  return (
    <div className="newTabsPanel">
      <Dropdown
        isOpen={toggleDropdown}
        toggle={() => setToggleDropdown((previous) => !previous)}
        className="newTabDropdown"
      >
        <DropdownToggle className="newTabDropdownToggle">
          <div>
            <span>{titleTab}</span>
            {toggleDropdown ? <ChevronUpLine /> : <ChevronDownLine />}
          </div>
        </DropdownToggle>
        <DropdownMenu className="newTabDropdownMenu">
          <DropdownItem
            className="newTabDropdownItem"
            onClick={() => callHandleFuntion('inprogress')}
          >
            <div className="newTabItem">
              <span>{nameTab.inprogress.name}</span>
            </div>
          </DropdownItem>
          <DropdownItem
            className="newTabDropdownItem"
            onClick={() => callHandleFuntion('chat')}
          >
            <div className="newTabItem">
              <span>{nameTab.chat.name}</span>
            </div>
          </DropdownItem>
          {currentUser?.superRole != 'agent' && (
            <DropdownItem
              className="newTabDropdownItem"
              key={-1}
              value={-1}
              onClick={() => callHandleFuntion('noAttendant')}
            >
              <div className="newTabItem">
                <span>{nameTab.noAttendant.name}</span>
              </div>
            </DropdownItem>
          )}
          <DropdownItem
            className="newTabDropdownItem"
            onClick={() => callHandleFuntion('unreads')}
          >
            <div className="newTabItem">
              <span>{nameTab.unreads.name}</span>
            </div>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <div className="newTabButtonsFilters">
        <div className="filterButton">
          <ContactsScreen />
        </div>
        <div className="filterButton">
          <FilterDropdown />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  activeTab: state.chat.config?.activeTabNavigation,
  activeFilters: state.chat?.filters?.activeFilters,
  currentPageState: state.chat?.currentPage,
  searchState: state.chat?.search,
  countNoReads: state.chat?.count_no_read,
  allUsers: state.chat?.config?.general?.users,
  currentUser: state.general.user,
  hasAsyncRequest: state.chat?.hasAsyncRequest,
  noAttendantTabActive: state.chat?.config?.noAttendantTabActive,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ChatActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NewTabs);
