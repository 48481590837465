interface EnvType {
  SYSTEM_USER_ACCESS_TOKEN: string;
  BSP_BUSINESS_ID: number;
}

const Env = () => {
  const _env: EnvType = {
    SYSTEM_USER_ACCESS_TOKEN: process.env.REACT_APP_SYSTEM_USER_ACCESS_TOKEN ?? '',
    BSP_BUSINESS_ID: 1830780546940051,
  };

  return _env;
};

export default Env();
