import styled from 'styled-components';

const Cardpay = styled.div`
  display: inline-block;
  width: calc(50% - 1rem);
  height: 10.75rem;
  margin: 0 0.5rem;
  max-width: 30.75rem;
  .card {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
    height: 175px;
    border: 1px solid var(--border-color);
    min-width: 300px;
  }
  img {
    width: 7.375rem;
    height: 5.3125rem;
    margin: 1rem;
    border-right: 1px solid #80808026;
    border-radius: 0;
    padding-right: 1rem;
    margin-right: 0;
  }
  .card__content {
    display: flex;
    flex-direction: column;
    .card__info {
      text-align: end;
      position: relative;
      .card__switch {
        position: relative;
        display: flex;
        justify-content: end;
        .card__switch--info {
          margin-right: 0.5rem;
          font-size: 0.8rem;
        }
      }
    }

    .card__label {
      position: absolute;

      margin-left: 1.2rem;
      z-index: 1;
      font-size: 0.7rem;
      line-height: 1.6rem;
    }
  }
  .card-title {
    margin-top: 1rem;
    font-weight: bold;
    font-size: 1.5rem;
  }

  button {
    margin-top: 1rem;
    position: relative;
    padding-left: 1.5rem;
    font-size: 0.75rem;
    i {
      position: absolute;
      left: 0.375rem;
      top: 20%;
      font-size: 1rem;
    }
  }

  @media screen and (max-width: 1090px) {
    .card-title {
      font-size: 1.2rem;
    }
  }
  @media screen and (max-width: 1025px) {
    .card-title {
      font-size: 1.1rem;
    }
  }

  @media screen and (max-width: 1010px) {
    width: calc(100% - 3rem);
    margin-bottom: 1rem;
    max-width: calc(100% - rem);
  }
  @media screen and (min-width: 300px) and (max-width: 500px) {
    width: calc(100% - 1rem);
    margin-bottom: 1rem;
    max-width: 100%;

    .card-body {
      padding: 0 0.5rem;
    }
    .card-title {
      font-size: 1rem;
    }
  }
`;

export { Cardpay };
