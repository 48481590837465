import React from 'react';
import { useWhiteLabel } from 'src/hooks/useWhiteLabel';

function TransferImage() {
  let color1 = '#0000c8';
  let color2 = '#3e3eff';
  let color3 = '#1616ff';
  let color4 = '#0000e2';
  const whitelabel = useWhiteLabel();
  if (whitelabel.isWl) {
    if (whitelabel.cssPathWl.includes('chatshub')) {
      color1 = '#FF7038';
      color2 = '#fa8e64';
      color3 = '#ff6445';
      color4 = '#EE4B2B';
    }
    if (whitelabel.cssPathWl.includes('auvo')) {
      color1 = '#6d26c2';
      color2 = '#7b3bc8';
      color3 = '#571e9b';
      color4 = '#41186a';
    }
    if (whitelabel.cssPathWl.includes('nectarcrm')) {
      color1 = '#ffd55b';
      color2 = '#ffe086';
      color3 = '#f3c641';
      color4 = '#fec10d';
    }
  }

  return (
    <svg
      width="72"
      height="72"
      className='img-fluid'
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.069 28.5516V21.1033C26.069 18.3611 28.2923 16.1378 31.0344 16.1378C34.1603 17.7007 37.8397 17.7007 40.9655 16.1378C43.7077 16.1378 45.931 18.3611 45.931 21.1033V28.5516C45.931 29.9233 44.8199 31.0344 43.4483 31.0344H28.5517C27.18 31.0345 26.069 29.9234 26.069 28.5516Z"
        fill={`${color1}`}
      />
      <path
        d="M36 12.4138C39.428 12.4138 42.2069 9.63489 42.2069 6.20691C42.2069 2.77893 39.428 0 36 0C32.572 0 29.7931 2.77893 29.7931 6.20691C29.7931 9.63489 32.572 12.4138 36 12.4138Z"
        fill="#F5F5F5"
      />
      <path
        d="M7.44836 37.2414C6.76309 37.2414 6.20692 36.6853 6.20692 36C6.19567 24.0455 13.3375 13.2455 24.3409 8.57419C24.9715 8.30602 25.7015 8.59908 25.9697 9.22964C26.2378 9.8602 25.9448 10.5902 25.3142 10.8584C15.2268 15.1398 8.67967 25.0411 8.68966 36C8.68966 36.6853 8.13348 37.2414 7.44836 37.2414Z"
        fill="#D8D7D8"
      />
      <path
        d="M64.5516 37.2414C63.8664 37.2414 63.3102 36.6853 63.3102 36C63.3202 25.0411 56.7731 15.1398 46.6856 10.8584C46.0551 10.5902 45.762 9.86035 46.0302 9.22964C46.2984 8.59908 47.0282 8.30602 47.6589 8.57419C58.6625 13.2455 65.8042 24.0455 65.7931 36C65.7931 36.6853 65.2369 37.2414 64.5516 37.2414Z"
        fill="#D8D7D8"
      />
      <path
        d="M36 65.7931C31.994 65.8006 28.0278 64.9949 24.3409 63.4258C23.7103 63.1577 23.4173 62.4278 23.6855 61.7971C23.9536 61.1664 24.6835 60.8735 25.3142 61.1417C32.148 64.0191 39.852 64.0191 46.6858 61.1417C47.3164 60.8735 48.0463 61.1665 48.3145 61.7971C48.5827 62.4277 48.2896 63.1577 47.6591 63.4258C43.9722 64.9949 40.006 65.8006 36 65.7931Z"
        fill="#D8D7D8"
      />
      <path
        d="M27.3103 68.2758C26.8472 68.2771 26.4227 68.02 26.208 67.6093L23.7252 62.8585C23.4074 62.2515 23.6432 61.5005 24.2503 61.184C24.3223 61.1467 24.3992 61.1156 24.4774 61.0921L29.4429 59.636C30.1009 59.4436 30.7911 59.8209 30.9834 60.4789C31.1758 61.1369 30.7985 61.827 30.1405 62.0194L26.6262 63.0498L28.41 66.4598C28.7278 67.0681 28.492 67.8178 27.8849 68.1344C27.7076 68.2275 27.5101 68.2758 27.3103 68.2758Z"
        fill="#D8D7D8"
      />
      <path
        d="M64.5516 37.2415C64.2227 37.2415 63.9062 37.1111 63.674 36.8778L59.9498 33.1536C59.4731 32.6607 59.4867 31.8751 59.9809 31.3984C60.4625 30.934 61.2247 30.934 61.7052 31.3984L64.5518 34.2449L67.3983 31.3984C67.8912 30.9216 68.6769 30.9353 69.1536 31.4294C69.6179 31.9111 69.6179 32.6733 69.1536 33.1538L65.4294 36.8779C65.1972 37.1111 64.8807 37.2415 64.5516 37.2415Z"
        fill="#D8D7D8"
      />
      <path
        d="M22.3449 16.138C22.1588 16.138 21.9762 16.0958 21.8086 16.0138C21.1917 15.7171 20.931 14.976 21.2277 14.3578L23.163 10.3184L19.3482 8.58038C18.7362 8.27129 18.4904 7.52527 18.7982 6.91327C19.0899 6.33474 19.779 6.07782 20.3786 6.32363L25.344 8.59163C25.9672 8.87837 26.2391 9.61581 25.9524 10.2389C25.9499 10.2438 25.9475 10.2489 25.9449 10.2538L23.4622 15.4342C23.256 15.8636 22.8215 16.1367 22.3449 16.138Z"
        fill="#D8D7D8"
      />
      <path
        d="M39.7093 1.25635C41.7724 3.97618 41.2398 7.85307 38.5213 9.91618C36.3129 11.592 33.2578 11.592 31.0494 9.91618C33.0891 12.6596 36.9658 13.2307 39.7093 11.1923C42.4527 9.15399 43.0238 5.27597 40.9854 2.53252C40.6242 2.04708 40.1946 1.61635 39.7093 1.25635Z"
        fill="#D8D7D8"
      />
      <path
        d="M62.069 53.3793C65.4969 53.3793 68.2759 50.6003 68.2759 47.1724C68.2759 43.7444 65.4969 40.9655 62.069 40.9655C58.641 40.9655 55.8621 43.7444 55.8621 47.1724C55.8621 50.6003 58.641 53.3793 62.069 53.3793Z"
        fill="#F5F5F5"
      />
      <path
        d="M65.7782 42.2218C67.8413 44.9416 67.3088 48.8185 64.5902 50.8816C62.3819 52.5575 59.3268 52.5575 57.1184 50.8816C59.158 53.6251 63.0348 54.1962 65.7782 52.1578C68.5217 50.1194 69.0928 46.2414 67.0544 43.498C66.6931 43.0125 66.2637 42.5818 65.7782 42.2218Z"
        fill="#D8D7D8"
      />
      <path
        d="M9.93103 53.3793C13.359 53.3793 16.1379 50.6003 16.1379 47.1724C16.1379 43.7444 13.359 40.9655 9.93103 40.9655C6.50305 40.9655 3.72412 43.7444 3.72412 47.1724C3.72412 50.6003 6.50305 53.3793 9.93103 53.3793Z"
        fill="#F5F5F5"
      />
      <path
        d="M13.6403 42.2218C15.7034 44.9416 15.1709 48.8185 12.4523 50.8816C10.2439 52.5575 7.18884 52.5575 4.98047 50.8816C7.02009 53.6251 10.8968 54.1962 13.6403 52.1578C16.3837 50.1182 16.9548 46.2414 14.9165 43.498C14.5551 43.0125 14.1256 42.5818 13.6403 42.2218Z"
        fill="#D8D7D8"
      />
      <path
        d="M26.069 28.5517C26.069 29.9233 27.18 31.0344 28.5517 31.0344H43.4483C44.8199 31.0344 45.931 29.9233 45.931 28.5517V21.1033C45.9261 20.2393 45.6939 19.3928 45.257 18.6479C40.8415 24.8611 33.6911 28.5517 26.069 28.5517Z"
        fill={`${color2}`}
      />
      <path
        d="M0 69.5173V62.0689C0 59.3267 2.22328 57.1035 4.96547 57.1035C8.09128 58.6664 11.7707 58.6664 14.8965 57.1035C17.6387 57.1035 19.862 59.3267 19.862 62.0689V69.5173C19.862 70.8889 18.7509 72 17.3793 72H2.48273C1.11108 72 0 70.8889 0 69.5173Z"
        fill={`${color3}`}
      />
      <path
        d="M0 69.5173C0 70.8889 1.11108 72 2.48273 72H17.3793C18.7509 72 19.862 70.8889 19.862 69.5173V62.0689C19.8571 61.2049 19.6249 60.3584 19.188 59.6135C14.7724 65.8266 7.62202 69.5173 0 69.5173Z"
        fill={`${color4}`}
      />
      <path
        d="M52.1379 69.5173V62.0689C52.1379 59.3267 54.3612 57.1035 57.1034 57.1035C60.2292 58.6664 63.9087 58.6664 67.0345 57.1035C69.7767 57.1035 72 59.3267 72 62.0689V69.5173C72 70.8889 70.8889 72 69.5172 72H54.6207C53.2489 72 52.1379 70.8889 52.1379 69.5173Z"
        fill={`${color1}`}
      />
      <path
        d="M52.1379 69.5173C52.1379 70.8889 53.249 72 54.6207 72H69.5172C70.8889 72 72 70.8889 72 69.5173V62.0689C71.995 61.2049 71.7629 60.3584 71.3259 59.6135C66.9103 65.8266 59.76 69.5173 52.1379 69.5173Z"
        fill={`${color4}`}
      />
    </svg>
  );
}

export default TransferImage;
