import * as React from 'react';

export interface CustomerHasPaymentIntegratorContext {
  loading: boolean;
  customerHasPaymentIntegrator?: boolean;
  error?: Error;
  refresh: () => void;
}

export const CustomerHasPaymentIntegratorContext =
  React.createContext<CustomerHasPaymentIntegratorContext>({
    loading: false,
    refresh: () => {},
  });
