import styled from 'styled-components';


export const WebchatContainerValuesSelected = styled.ul`
  max-height: 250px;
  max-width: 65vw;
  overflow-y: auto;
  /* border-top: 1px solid var(--border-color); */
  margin: 1rem 1rem;

  @media (max-width: 600px) {
    max-height: 150px;
  }
`;

export const WebchatItem = styled.li`
  padding: 0.3rem 0.5rem;
  margin: 0 0.5rem;
  position: relative;
  line-height: 1.8rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  color: var(--textDefault);
  font-size: 1rem;

  transition: 0.2s;
  span > i {
    padding: 0.2rem 0.3rem;
    cursor: pointer;
    font-size: 1.5rem;
    position: absolute;
    right: 5px;
    top: 0;

    :hover {
      font-size: 1.75rem;
    }
  }

  .url-webchat {
    max-width: 90%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;
