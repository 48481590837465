import { InputGroup, Input, Icon } from '@polichat/flamboyant';
import { useState } from 'react';
import poliConfig from 'src/utils/poliConfig';

export function SearchCustomer() {
  const [value, setValue] = useState<string>('');
  function searchCustomer() {
    window.open(
      `${poliConfig.poliAppLaravelUrl}/admin/customers?search=${value}`
    );
    setValue('');
  }
  return (
    <form className="searchCustomer">
      <InputGroup className="input-group-search">
        <Input
          className="form-search-customer"
          type="text"
          placeholder="Pesquisar na Poli"
          value={value}
          onKeyPress={(e: any) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              searchCustomer();
            }
          }}
          id="filtro-busca"
          onChange={(e: any) => setValue(e.target.value)}
        />
      </InputGroup>
      <div className="icon-search-customer" onClick={searchCustomer}>
        <Icon icon={'poli-icon pi-search-fill'} />
      </div>
    </form>
  );
}
