import { useDispatch } from "react-redux";
import { setOpenAddContact, setOpenEditContact, setOpenImportContact } from "src/store/modules/chat/actions";
import { UserAddM } from '@poli-ink/react';
import ContactsScreen from "src/components/chat/panel/filter/ContactsScreen";
import { isMobile } from "react-device-detect";

export function ContactPanelMobile() {
  const dispatch = useDispatch();

  function handleAddNewContact(value: any) {
    dispatch(setOpenImportContact(false));
    dispatch(setOpenEditContact(false));
    dispatch(setOpenAddContact(value));
  }

  return (
    <>
      <div className="contactPanelMobile hidden">
        <h2>Contatos</h2>
        <div className="buttonsContactMobile">
          <div
            onClick={() => {
              if (isMobile) {
                // Usando deste jeito porque com estado não estava funcionando 100%
                document.querySelector('.chatPanelContainer')?.classList.add('hidden');
                document.querySelector('.contactPanelMobile')?.classList.add('hidden');
                document.querySelector('.topMobile')?.classList.add('hidden');
                document
                  .querySelector('.topNavigationContainer')
                  ?.classList.add('hidden');
              }
              handleAddNewContact(true);
            }}
            className="contactAddMobile"
          >
            <UserAddM className="userButtonMobile" width={16} height={16} />
          </div>
          <ContactsScreen />
        </div>
      </div>
    </>
  );
}
