import { ReactNode, useState } from 'react';
import { Dropdown } from '@polichat/flamboyant';

import styles from '../QuickFields.module.css';

interface MenuItemProps {
  children: ReactNode;
  id: string;

  closeMenuRoot: () => void;
}

export function MenuItem({
  children,
  id,
  closeMenuRoot,
}: Readonly<MenuItemProps>) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  function handleToggle() {
    toggle();
    closeMenuRoot();
  }

  function onMouseEnter() {
    setDropdownOpen(true);
  }

  function onMouseLeave() {
    setDropdownOpen(false);
  }

  return (
    <div className={styles.drop_item}>
      <Dropdown
        direction="right"
        id={`quickMenuItem_${id}`}
        isOpen={dropdownOpen}
        toggle={handleToggle}
        onMouseLeave={onMouseLeave}
        onMouseEnter={onMouseEnter}
      >
        {children}
      </Dropdown>
    </div>
  );
}
