import { Col, FormGroup, Input, Label, Row } from '@polichat/flamboyant';
import { useState } from 'react';
import {
  LabelWithSpan,
  OptionsContainer,
} from '../../FormMessagesBuilder.styles';

function CreateFooterList({ onUpdate, initialValue, isEdit }) {
  const [footerData, setFooterData] = useState(initialValue);

  const handleInputChange = ({ target: { name, value } }) => {
    setFooterData({
      ...footerData,
      [name]: value,
    });
    handleFooterData({
      ...footerData,
      [name]: value,
    });
  };

  const handleFooterData = (data) => {
    onUpdate(data);
  };

  return (
    <Row>
      <Col sm={12}>
        <FormGroup>
          <LabelWithSpan>
            <Label>
              Rodapé:{' '}
              <span>
                Adicione uma linha curta de texto na parte inferior do seu
                modelo de mensagem{' '}
              </span>
            </Label>
          </LabelWithSpan>
          <Input
            type="text"
            id="footer-message"
            name="text"
            placeholder="Rodapé da mensagem"
            value={footerData.text}
            onChange={handleInputChange}
            showCharCounter
            maxLength={60}
            disabled={isEdit}
          />
          <OptionsContainer></OptionsContainer>
        </FormGroup>
      </Col>
    </Row>
  );
}

export default CreateFooterList;
