import React from 'react';
import AddTagModal from './tags/AddTagModal';
import PaymentChatModal from './PaymentChatModal';

function Modals() {
  return (
    <>
      <AddTagModal />
      <PaymentChatModal />
    </>
  );
}

export default Modals;
