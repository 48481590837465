import React, { useEffect, useState } from 'react';
import Organizations from './actions/Organizations';
import ChannelsMonitor from './actions/ChannelsMonitor';
import Changelog from './actions/Changelog';
import VisitorsNotification from '../../../visitors/VisitorsNotification/VisitorsNotification';
import DarkMode from './actions/DarkMode';

import { useSelector } from 'react-redux';

import { ContactCredits } from './actions/ContactCredits';

import { UserStatus } from './actions/UserStatus';

function Actions() {
  /**
   * Logica Whitelabel
   */
  const wlState = useSelector((state) => state?.whitelabel);
  const hideCredits = wlState?.config?.hide_credits;
  const [isWl, setIsWl] = useState(false);

  useEffect(() => {
    const _config = wlState?.config;
    const loadingWl = wlState.loading;
    const _isWl = _config?.is_white_label;

    if (!loadingWl) {
      setIsWl(_isWl);
    }
  }, [wlState]);
  /* end */

  return (
    <div className="actionsTopNav">
      {!hideCredits && (
        <ContactCredits />
      )}
      <UserStatus />
      <Organizations />

      {!isWl && (
        <>
          <ContactCredits />
          {/* <TemplateReport />  // Relatório de Templates, comentado enquanto o back é feito*/}
          <ChannelsMonitor />
          <VisitorsNotification />
          <Changelog />
          <DarkMode />
        </>
      )}
    </div>
  );
}

export default Actions;
