const VariablesList = [
  {
    id: 1,
    label: '[contact_name]',
    description: 'Para inserir o nome completo do contato',
  },
  {
    id: 2,
    label: '[contact_first_name]',
    description: 'Para inserir o primeiro nome do contato',
  },
  {
    id: 3,
    label: '[contact_phone]',
    description: 'Para inserir o número do telefone do contato',
  },
  {
    id: 4,
    label: '[contact_email]',
    description: 'Para inserir o email do contato',
  },
  {
    id: 5,
    label: '[operator_name]',
    description: 'Para inserir o nome do atendente do contato',
  },
  {
    id: 6,
    label: '[department_name]',
    description: 'Para inserir o nome do departamento',
  },
  { id: 7, label: '[date_current]', description: 'Para inserir a data' },
  { id: 8, label: '[time_current]', description: 'Para inserir o horário' },
  {
    id: 9,
    label: '[protocol_number]',
    description: 'Para inserir o protocolo de atendimento',
  },
];

export default VariablesList;
