import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import PageMainHeader from '../../../common/pages/PageMainHeader/PageMainHeader.component';
import PageMainBody from '../../../common/pages/PageMainBody/PageMainBody.component';

import Certification from '../Certification/Certification.component';
import CertificationWabaByPoli from '../CertificationWabaByPoli/Certification.component';
import Animation from '../../../common/animation';
import ChannelsService from '../../../../services/tools/channels/channels';
import ChannelFormSelector from './ChannelFormSelector.component';

const ChannelEdit = () => {
  const customer_id = useSelector((state) => state.general.current_customer_id);
  let { id } = useParams();
  const history = useHistory();

  const [loading, loadingSet] = useState(true);
  const [data, dataSet] = useState(false);
  const [homologation, setHomologation] = useState(data?.config || {});
  const { migrationToWabaStatus } = data?.config || {};

  // This key is responsible for opening the certification screen directly #grt23
  const openCertificationScreen =
    history?.location?.state?.data?.openCertificationScreen;

  /**
   * If user are a ADM Poli, send buy history.location.state the prop
   * customer_id_to_search , contains the ID for customer to edit channel
   *  */
  const userAdmPoliGetChannelFromCustomerId =
    history?.location?.state?.customer_id_to_search;

  const getData = async () => {
    const res = await ChannelsService.getChannelData({
      customer_id: userAdmPoliGetChannelFromCustomerId ?? customer_id,
      id,
    });
    dataSet(res);
    setHomologation(res?.homologation ?? res?.config?.homologation);
    loadingSet(false);
  };

  useEffect(() => {
    getData();
  }, []);

  if (loading) {
    return <Animation icon="chat-loading" top={'50%'} />;
  }

  if (!data) {
    history.push('/tools/channels');
    return null;
  } else {
    if (
      typeof data === 'object' &&
      data.channel_id &&
      Number(data.channel_id) === 4 &&
      data.id
    ) {
      if (
        typeof data.uid === 'undefined' ||
        data.uid.replace(/\D/g, '').toString().length === 0 ||
        data.uid.replace(/[^a-z.]+/g, '') !== 'polichat.webchat'
      ) {
        data.uid = data.id + '@polichat.webchat';
      }
    }
  }

  if (
    (homologation && migrationToWabaStatus !== 'started') ||
    openCertificationScreen
  ) {
    // Waba By Poli <> Meta
    if (data.api == 10) {
      return (
        <CertificationWabaByPoli
          data={data}
          dataSet={dataSet}
          onUpdate={() => {
            getData();
          }}
          currentStep={data.homologation ? data.homologation : homologation}
        />
      );
    }

    return (
      <Certification
        data={data}
        dataSet={dataSet}
        currentStep={data.homologation ? data.homologation : homologation}
      />
    );
  }

  return (
    <>
      <PageMainHeader
        title={data ? 'Editar canal' : 'Adicionar canal'}
        icon="poli-icon pi-channels-line"
      />

      <PageMainBody>
        <ChannelFormSelector
          data={data}
          dataSet={dataSet}
          userAdmPoliGetChannelFromCustomerId={
            userAdmPoliGetChannelFromCustomerId
          }
        />
      </PageMainBody>
    </>
  );
};

export default ChannelEdit;
