import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Lottie from 'react-lottie';
import AuthLayout from '../../components/layouts/authLayout';
import { password_recover } from '../../services/auth/password';
import loadingMain from '../../lotties/loading-main.json';
import sucessImage from '../../assets/images/success.svg';
import errorImage from '../../assets/images/error.svg';
import Animation from '../../components/common/animation';

import { Button, Icon, Form, Input } from '@polichat/flamboyant';

import {
  TextRecoverPassword,
  IconEmail,
  ButtonRecoverPassword,
  ContainerError,
  ContainerSuccess,
} from './stylesRecoverPassword';

function RecoverPassword() {
  const inputEmail = useRef(null);
  const [pageStates, setPageStates] = useState(0);
  let history = useHistory();

  const optionsAnimation = {
    loop: true,
    autoplay: true,
    animationData: loadingMain,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPageStates(3);
    try {
      let res = await password_recover(inputEmail.current.value);
      setPageStates(1);
    } catch (err) {
      if (err.response.status == 429) {
        setPageStates(4);
        return;
      }
      setPageStates(2);
    }
  };

  const handleClickSucess = () => {
    history.push('/login');
  };

  const handleClickError = () => {
    window.location.reload();
  };

  const renderErrorSection = () => {
    return (
      <AuthLayout title="Algo deu errado!" titleColor="#ff665d">
        <ContainerError>
          <img src={errorImage}></img>
          <Button onClick={handleClickError} width="total">
            {' '}
            Tentar Novamente{' '}
          </Button>
        </ContainerError>
      </AuthLayout>
    );
  };

  const renderErrorTooManyRequestSection = () => {
    return (
      <AuthLayout
        title="Você excedeu o limite de requisições, tente novamente em 24h"
        titleColor="#ff665d"
      >
        <ContainerError>
          <img src={errorImage}></img>
          <Button onClick={handleClickError} width="total">
            {' '}
            Tentar Novamente{' '}
          </Button>
        </ContainerError>
      </AuthLayout>
    );
  };

  const renderSuccessSection = () => {
    return (
      <AuthLayout title="Email enviado com sucesso!" titleColor="#606161">
        <ContainerSuccess>
          <img src={sucessImage}></img>
          <Button onClick={handleClickSucess} width="total">
            {' '}
            Fazer login na plataforma{' '}
          </Button>
        </ContainerSuccess>
      </AuthLayout>
    );
  };

  const renderLoadingSection = () => {
    return (
      <AuthLayout>
        <Animation
          icon="loading"
          size={50}
          style={{
            border: '6px solid transparent',
            borderColor: 'var(--background-spinner)',
            borderTopColor: 'var(--green-polichat)',
          }}
        />
        <div style={{ marginTop: 40 }}></div>
      </AuthLayout>
    );
  };

  const renderFormSection = () => {
    return (
      <AuthLayout title="Esqueceu sua senha?">
        <Form onSubmit={handleSubmit}>
          <TextRecoverPassword>
            Sem problemas! Basta inserir seu e-mail e enviaremos as intruções de
            login.
          </TextRecoverPassword>
          <IconEmail>
            <div>
              <Icon size={25} icon={'poli-icon pi-mail-line'}></Icon>
            </div>
            <span>E-mail:</span>
          </IconEmail>

          <Input width="half" type="email" innerRef={inputEmail}></Input>

          <ButtonRecoverPassword>
            <Button
              outline
              color="primary"
              width="half"
              className="buttonBack"
              onClick={handleClickSucess}
            >
              {' '}
              Voltar{' '}
            </Button>
            <Button type="submit" width="half">
              {' '}
              Recuperar senha{' '}
            </Button>
          </ButtonRecoverPassword>
        </Form>
      </AuthLayout>
    );
  };

  switch (pageStates) {
    case 0:
      return renderFormSection();
    case 1:
      return renderSuccessSection();
    case 2:
      return renderErrorSection();
    case 3:
      return renderLoadingSection();
    case 4:
      return renderErrorTooManyRequestSection();
    default:
      return renderFormSection();
  }
}

export default RecoverPassword;
