import styles from './styles.module.css';
import { useSelector } from 'react-redux';

export default  function AvailableUser() {
    //@ts-ignore
    const status = useSelector( (state) => state?.general?.user?.status);
    return (
        status !== 1 && (
            <div className={styles.offline_user}>
                Você está indisponível e não receberá novos contatos
            </div>
        )
    )
}