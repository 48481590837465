export default class AudioRecorderMimeType {
  static mimeTypeList = [
    'audio/webm',
    'audio/webm;codecs=opus',
    'audio/ogg;codecs=opus',
    'audio/mp4;codecs=opus',
    'audio/webm;codecs=avc1',
    'audio/ogg;codecs=avc1',
    'audio/mp4;codecs=avc1',
    'audio/webm;codecs=mp4a',
    'audio/mp4',
    'audio/mpeg',
    'audio/mpeg4-generic',
    'audio/ogg',
    'audio/MP4A-LATM',
    'audio/ogg;codecs=mp4a',
    'audio/mp4;codecs=mp4a',
    'audio/mkv;codecs=opus',
    'audio/mkv;codecs=avc1',
    'audio/mkv;codecs=mp4a',
    'audio/mpeg;codecs=opus',
    'audio/mpeg;codecs=avc1',
    'audio/mpeg;codecs=mp4a',
    'audio/1d-interleaved-parityfec',
    'audio/32kadpcm',
    'audio/3gpp',
    'audio/3gpp2',
    'audio/aac',
    'audio/ac3',
    'audio/AMR',
    'audio/AMR-WB',
    'audio/amr-wb+',
    'audio/aptx',
    'audio/asc',
    'audio/ATRAC-ADVANCED-LOSSLESS',
    'audio/ATRAC-X',
    'audio/ATRAC3',
    'audio/basic',
    'audio/BV16',
    'audio/BV32',
    'audio/clearmode',
    'audio/CN',
    'audio/DAT12',
    'audio/dls',
    'audio/dsr-es201108',
    'audio/dsr-es202050',
    'audio/dsr-es202211',
    'audio/dsr-es202212',
    'audio/DV',
    'audio/DVI4',
    'audio/eac3',
    'audio/encaprtp',
    'audio/EVRC',
    'audio/EVRC-QCP',
    'audio/EVRC0',
    'audio/EVRC1',
    'audio/EVRCB',
    'audio/EVRCB0',
    'audio/EVRCB1',
    'audio/EVRCNW',
    'audio/EVRCNW0',
    'audio/EVRCNW1',
    'audio/EVRCWB',
    'audio/EVRCWB0',
    'audio/EVRCWB1',
    'audio/EVS',
    'audio/example',
    'audio/flac',
    'audio/flexfec',
    'audio/fwdred',
    'audio/G711-0',
    'audio/G719',
    'audio/G7221',
    'audio/G722',
    'audio/G723',
    'audio/G726-16',
    'audio/G726-24',
    'audio/G726-32',
    'audio/G726-40',
    'audio/G728',
    'audio/G729',
    'audio/G7291',
    'audio/G729D',
    'audio/G729E',
    'audio/GSM',
    'audio/GSM-EFR',
    'audio/GSM-HR-08',
    'audio/iLBC',
    'audio/ip-mr_v2.5',
    'audio/L8',
    'audio/L16',
    'audio/L20',
    'audio/L24',
    'audio/LPC',
    'audio/matroska',
    'audio/MELP',
    'audio/MELP600',
    'audio/MELP1200',
    'audio/MELP2400',
    'audio/mhas',
    'audio/midi-clip',
    'audio/mobile-xmf',
    'audio/MPA',
    'audio/mpa-robust',
    'audio/opus',
    'audio/parityfec',
    'audio/PCMA',
    'audio/PCMA-WB',
    'audio/PCMU',
    'audio/PCMU-WB',
    'audio/prs.sid',
    'audio/QCELP',
    'audio/raptorfec',
    'audio/RED',
    'audio/rtp-enc-aescm128',
    'audio/rtploopback',
    'audio/rtp-midi',
    'audio/rtx',
    'audio/scip',
    'audio/SMV',
    'audio/SMV0',
    'audio/SMV-QCP',
    'audio/sofa',
    'audio/sp-midi',
    'audio/speex',
    'audio/t140c',
    'audio/t38',
    'audio/telephone-event',
    'audio/TETRA_ACELP',
    'audio/TETRA_ACELP_BB',
    'audio/tone',
    'audio/TSVCIS',
    'audio/UEMCLIP',
    'audio/ulpfec',
    'audio/usac',
    'audio/VDVI',
    'audio/VMR-WB',
    'audio/vnd.3gpp.iufp',
    'audio/vnd.4SB',
    'audio/vnd.audiokoz',
    'audio/vnd.CELP',
    'audio/vnd.cisco.nse',
    'audio/vnd.cmles.radio-events',
    'audio/vnd.cns.anp1',
    'audio/vnd.cns.inf1',
    'audio/vnd.dece.audio',
    'audio/vnd.digital-winds',
    'audio/vnd.dlna.adts',
    'audio/vnd.dolby.heaac.1',
    'audio/vnd.dolby.heaac.2',
    'audio/vnd.dolby.mlp',
    'audio/vnd.dolby.mps',
    'audio/vnd.dolby.pl2',
    'audio/vnd.dolby.pl2x',
    'audio/vnd.dolby.pl2z',
    'audio/vnd.dolby.pulse.1',
    'audio/vnd.dra',
    'audio/vnd.dts',
    'audio/vnd.dts.hd',
    'audio/vnd.dts.uhd',
    'audio/vnd.dvb.file',
    'audio/vnd.everad.plj',
    'audio/vnd.hns.audio',
    'audio/vnd.lucent.voice',
    'audio/vnd.ms-playready.media.pya',
    'audio/vnd.nokia.mobile-xmf',
    'audio/vnd.nortel.vbk',
    'audio/vnd.nuera.ecelp4800',
    'audio/vnd.nuera.ecelp7470',
    'audio/vnd.nuera.ecelp9600',
    'audio/vnd.octel.sbc',
    'audio/vnd.presonus.multitrack',
    'audio/vnd.qcelp',
    'audio/vnd.rhetorex.32kadpcm',
    'audio/vnd.rip',
    'audio/vnd.sealedmedia.softseal.mpeg',
    'audio/vnd.vmx.cvsd',
    'audio/vorbis',
    'audio/vorbis-config',
    'audio/wav',
    'audio/mp3',
    'audio/x-ms-wma',
    'audio/x-aiff',
    'audio/x-matroska',
    'audio/x-wav',
    'audio/vnd.wav',
    'audio/vnd.wave',
    'audio/x-pn-wav',
    'audio/vnd.rn-realaudio',
    'audio/x-ms-wax',
    'audio/midi',
    'audio/x-pn-realaudio',
    'audio/x-pn-realaudio-plugin',
    'audio/x-sd2',
    'audio/x-wavpack',
    'audio/x-vorbis+ogg',
    'audio/wav;codecs=opus',
    'audio/mp3;codecs=opus',
    'audio/aac;codecs=opus',
    'audio/flac;codecs=opus',
    'audio/x-ms-wma;codecs=opus',
    'audio/x-aiff;codecs=opus',
    'audio/x-matroska;codecs=opus',
    'audio/x-wav;codecs=opus',
    'audio/vnd.wav;codecs=opus',
    'audio/vnd.wave;codecs=opus',
    'audio/x-pn-wav;codecs=opus',
    'audio/vnd.rn-realaudio;codecs=opus',
    'audio/x-ms-wax;codecs=opus',
    'audio/midi;codecs=opus',
    'audio/basic;codecs=opus',
    'audio/x-pn-realaudio;codecs=opus',
    'audio/x-pn-realaudio-plugin;codecs=opus',
    'audio/x-sd2;codecs=opus',
    'audio/x-wavpack;codecs=opus',
    'audio/x-vorbis+ogg;codecs=opus',
    'audio/ac3',
    'audio/basic',
    'audio/midi',
    'audio/mp4',
    'audio/mpeg',
    'audio/mp3',
    'audio/ogg',
    'audio/ogg;codecs=opus',
    'audio/prs.sid',
    'audio/vnd.dece.audio',
    'audio/vnd.digital-winds',
    'audio/vnd.dra',
    'audio/vnd.dts',
    'audio/vnd.dts.hd',
    'audio/vnd.lucent.voice',
    'audio/vnd.ms-playready.media.pya',
    'audio/vnd.nuera.ecelp4800',
    'audio/vnd.nuera.ecelp7470',
    'audio/vnd.nuera.ecelp9600',
    'audio/vnd.qcelp',
    'audio/vnd.rip',
    'audio/vnd.rn-realaudio',
    'audio/wav',
    'audio/x-aiff',
    'audio/x-ms-wax',
    'audio/x-ms-wma',
    'audio/x-pn-realaudio',
    'audio/x-pn-realaudio-plugin',
    'audio/x-wav',
  ];

  static getMimeTypeSupportedByBrowser() {
    return AudioRecorderMimeType.mimeTypeList.find((mime) =>
      window?.MediaRecorder?.isTypeSupported(mime)
    );
  }
}
