import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { LocalFormValues, SelectedOptionProps } from './types';
import { validationSchema } from './validation';

const INITIAL_SELECTED_VALUE: SelectedOptionProps = {
  value: 'all',
  label: 'Todos'
};

export function useLocalForm() {

  const today = new Date();
  today.setDate(today.getDate() - 1);
  
  const yesterday = today.toISOString().split('T')[0];

  const form = useForm<LocalFormValues>({
    defaultValues: {
      start_date: yesterday,
      end_date: yesterday,
      channel: INITIAL_SELECTED_VALUE,
      department: INITIAL_SELECTED_VALUE,
      operator: INITIAL_SELECTED_VALUE,
      ad: INITIAL_SELECTED_VALUE,
      per_page: 10,
      page: 1
    },
    resolver: yupResolver(validationSchema),
  });

  return {
    ...form
  };
}
