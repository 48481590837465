import { useCallback, useEffect, useState } from 'react';

import ChannelCustomerService from '../../../../../services/tools/channels/channels';

export interface ChannelServiceProps {
  customer_id?: number;
  channel_id?: number;
}

interface ChannelData {
  id: string;
  name: string;
  phone: string;
}
export function useChannelService(props: ChannelServiceProps) {
  const [channel, setChannel] = useState<ChannelData>();
  const [loading, setLoading] = useState(false);

  // ---------------------------------------------
  // Functions
  const load = useCallback(async (customer_id: number, channel_id: number) => {
    try {
      setLoading(true);
      const params = {
        customer_id,
        id: channel_id,
      };

      const result = await ChannelCustomerService.getChannelData(params);
      setChannel(result);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, []);
  // ---------------------------------------------
  // Effects
  useEffect(() => {
    if (props.customer_id && props.channel_id) {
      load(props.customer_id, props.channel_id);
    }
  }, [props.customer_id, props.customer_id]);
  // ---------------------------------------------
  // Transformations
  // ---------------------------------------------
  // API

  return {
    channel,
    loading,
  };
}
