import React from 'react';

import { Container, CardNumber, Content } from './InfoCard.styles';

interface Props {
  number?: string;
  height?: string;
  children: JSX.Element;
}

const InfoCard = ({ number, height, children }: Props) => (
  <Container height={height}>
    {number && <CardNumber>{number}</CardNumber>}
    <Content>{children}</Content>
  </Container>
);

export default InfoCard;
