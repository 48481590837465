import React from 'react';
import { Button } from '@polichat/flamboyant';
import Animation from '../../common/animation';

function DeleteButton({ title, onClick, isLoading = false }) {
  if (isLoading) {
    return (
      <Animation
        icon="chat-loading"
        style={{
          maxWidth: '150px',
          height: '100%',
          maxHeight: '100px',
        }}
        background="rgba(0, 0, 0, 0)"
      />
    );
  }
  return (
    <Button color="danger" onClick={onClick}>
      <span style={{ marginLeft: 5 }}>{title}</span>
    </Button>
  );
}

export default DeleteButton;
