import { laravelAxios } from '../../config/axios/laravelAxios';

export async function showCreditAlert(channel_id, quantity_alert) {
  try {
    const path = `credit_alert/${channel_id}/${quantity_alert}`;
    const result = await laravelAxios.get(path);
    return result?.data?.can_open_alert ?? false;
  } catch (error) {
    console.error('Erro ao abrir o alerta de crédito:', error.message);
  }
}

export async function closeCreditAlert(channel_id, quantity_alert) {
  try {
    const path = `close_credit_alert/${channel_id}/${quantity_alert}`;
    await laravelAxios.get(path);
  } catch (error) {
    console.error('Erro ao fechar o alerta de crédito:', error.message);
  }
}
