// Função para aplicar os filtros ao chat
export const applyChatFilters = (chats, activeFilters) => {
  const filterByAttendant = (chats, filter, filterChatByStatus) => {
    if (filter.length) {
      filter.forEach((fil) => {
        if (fil.value === -1) {
          fil.name = 'Sem Atendente';
          chats = chats.filter((chat) => {
            if (!filterChatByStatus.length) {
              return (
                chat.chat &&
                chat.chat.origin_id === null &&
                chat.chat.finished_at === null
              );
            } else {
              return chat.chat && chat.chat.origin_id === null;
            }
          });
        } else {
          chats = chats.filter(
            (chat) => chat.chat && chat.chat.origin_id === fil.value
          );
        }
      });
    }
    return chats;
  };

  const filterByDepartment = (chats, filter) => {
    if (filter.length) {
      filter.forEach((fil) => {
        if (fil.value === -1) {
          fil.name = 'Sem Departamento';
          chats = chats.filter(
            (chat) => chat.chat && chat.chat.department_id === null
          );
        } else {
          chats = chats.filter(
            (chat) =>
              chat.chat &&
              chat.chat.department_id &&
              chat.chat.department_id === fil.value
          );
        }
      });
    }

    return chats;
  };

  const filterByTag = (chats, filter) => {
    if (filter.length) {
      filter.forEach((fil) => {
        chats = chats.filter(
          (chat) =>
            chat.tags &&
            chat.tags.length &&
            chat.tags.find((tag) => tag.tag_id === fil.value)
        );
      });
    }
    return chats;
  };

  const filterByDate = (chats, filter) => {
    if (filter.length) {
      filter.forEach((fil) => {
        if (fil.value.start_date) {
          let start_date = new Date(fil.value.start_date);
          let finish_date = new Date(fil.value.finish_date);

          chats = chats.filter((chat) => {
            // Aguardando updated_at do contato vir do backend
            if (chat && chat.contact && chat.contact.date_last_message) {
              let created_at_date_split =
                chat.contact.date_last_message.split(' ');

              if (created_at_date_split && created_at_date_split[0]) {
                let updated_at;
                updated_at = created_at_date_split[0];
                let updated_at_date = new Date(updated_at);

                if (
                  start_date.getTime() <= updated_at_date.getTime() &&
                  updated_at_date.getTime() <= finish_date.getTime()
                ) {
                  return chat;
                }
              }
            }

            return null;
          });
        }
      });
    }

    return chats;
  };

  const filterByStatus = (chats, filter) => {
    if (filter.length) {
      filter.forEach((fil) => {
        chats = chats.filter((chat) => {
          if (chat && chat.chat) {
            switch (fil.value) {
              // em atendimento
              case 0:
                if (
                  chat.chat.finished_at === null &&
                  chat.chat.origin_id !== null
                ) {
                  return chat;
                }

                break;

              // Finalizados
              case 1:
                if (chat.chat.finished_at != null) {
                  return chat;
                }
                break;

              // Não lidos
              case 2:
                if (chat.chat.chat_read === 0) {
                  return chat;
                }
                break;

              // apenas lidos
              case 3:
                if (chat.chat.chat_read === 1) {
                  return chat;
                }
                break;

              default:
                return null;
            }
          }
          return null;
        });
      });
    }

    return chats;
  };

  const filterByChannel = (chats, filter) => {
    if (filter.length) {
      filter.forEach((fil) => {
        chats = chats.filter((chat) => {
          if (
            chat &&
            chat.talked_channels_ids &&
            chat.talked_channels_ids.indexOf(fil.value) > -1
          ) {
            return chat;
          } else {
            return null;
          }
        });
      });
    }

    return chats;
  };

  if (chats && activeFilters) {
    if (activeFilters.attendant && activeFilters.attendant.length) {
      chats = filterByAttendant(
        chats,
        activeFilters.attendant,
        activeFilters.chat
      );
    }

    if (activeFilters.channel && activeFilters.channel.length) {
      chats = filterByChannel(chats, activeFilters.channel);
    }

    if (activeFilters.chat && activeFilters.chat.length) {
      chats = filterByStatus(chats, activeFilters.chat);
    }

    if (activeFilters.date && activeFilters.date.length) {
      chats = filterByDate(chats, activeFilters.date);
    }

    if (activeFilters.department && activeFilters.department.length) {
      chats = filterByDepartment(chats, activeFilters.department);
    }

    if (activeFilters.tag && activeFilters.tag.length) {
      chats = filterByTag(chats, activeFilters.tag);
    }

    return chats;
  } else {
    return chats;
  }
};
