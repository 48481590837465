import React from 'react';
import { connect } from 'react-redux';
import {
  Icon,
} from '@polichat/flamboyant';

function ChatLoadingFailed({ chat, error, detail }) {
  return <div
           style={styles.container}>
             <span style={styles.iconSpan}>
               <Icon
                icon={'poli-icon pi-poly-sad-fill'}
                color={'var(--danger)'}
                position={'relative'}
                size={80}
              />
             </span>
            <div
            style={styles.erroText}> 
            {error ? error : 'Erro ao carregar chats!'}
            </div>
            {detail?(<><div>&nbsp;&nbsp;{'('+detail+')'}</div></>):''}
           </div>;
}

const styles = {
  container: {
    alignItems: 'center',
    textAlign:'center',
    margin: 'auto',
    padding:'15%',
    fontSize: '14px',
    justifyContent: 'center',
    backgroundColor: 'var(--secondary-background-color)',
    color: 'var(--primary-font-color)',

  },
  erroText: {
    alignItems: 'center',
    fontSize: '26px',
    color: 'var(--danger)',
  },

  iconSpan: {
    alignItems: 'center',
    backgroundColor: 'var(--gray-10)',
    paddingTop:'60px',
    margin: '30px',
    borderRadius:'60px',


  },
};

const mapStateToProps = (state) => ({
  chat: state.chat,
});

export default connect(mapStateToProps)(ChatLoadingFailed);
