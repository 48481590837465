import React from 'react';
import { LogoContainer } from './iconPlusIcon.styles';
import { Icon } from '@polichat/flamboyant';

export interface IconPlusIconProps {
  firstIcon: string;
  secondIcon: string;
  firstColor?: string;
  secondColor?: string;
}

export function IconPlusIcon(props: Readonly<IconPlusIconProps>) {
  const icons = ['onlyLogo', 'nectarIcon', 'rdStationIcon', 'zApiIcon'];
  
  return (
    <LogoContainer>
      {icons.includes(props.firstIcon) ? (
        <div
          className={props.firstIcon}
          style={{ width: '50px', height: '50px', backgroundColor: 'inherit' }}
        />
      ) : (
        <div>
          <Icon
            icon={props.firstIcon}
            size={25}
            color={props.firstColor ? props.firstColor : 'var(--primary)'}
          />
        </div>
      )}

      <div style={{ height: '50px', display: 'flex', alignItems: 'center' }}>
        <Icon icon="poli-icon pi-add-fill" color="#a0d5ff" size={25} />
      </div>

      {icons.includes(props.secondIcon) ? (
        <div
          className={props.secondIcon}
          style={{ width: '50px', height: '50px', backgroundColor: 'inherit' }}
        />
      ) : (
        <div
          style={{
            width: '50px',
            height: '50px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Icon
            icon={props.secondIcon}
            color={props.secondColor ? props.secondColor : 'var(--primary)'}
            size={45}
          />
        </div>
      )}
    </LogoContainer>
  );
}
