import styled from 'styled-components';

export const Container = styled.div`
  box-sizing: border-box;
  display: grid;
  height: 100%;
  overflow-y: auto;
  margin: 1.4rem auto;

  @media (max-width: 600px) {
    margin: 0;
  }
`;

export const ChannelListContainer = styled.div`
`;

export const ChannelItemContainer = styled.div`
  padding: 10px;

  & hr {
    background-color: var(--secondary-background-color) !important;
  }
`;

export const ChannelInfosContainer = styled.div`
  display: grid;
  grid-template-columns: 15% 64% 15%;
  gap: 2rem;

  @media (max-width: 600px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const LogoInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  & span {
    color: var(--primary-font-color);
    font-size: 1.2rem;
    font-weight: bold;
    margin-left: 10px;
  }
`;

export const TextInfoContainer = styled.div`
  & span {
    font-size: 0.9rem;
    color: var(--secondary-font-color);
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

export const ActionButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & button {
    display: flex;
    align-items: center;
    justify-content: space-around;

    & span {
      font-size: 0.85rem;
    }
  }

  @media (max-width: 600px) {
    width: 100%;
    justify-content: flex-end;

    & span {
      font-size: 0.6rem;
      display: none;
    }
    & i:nth-child(3) {
      display: none;
    }
  }
`;

export const IllustrationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & img {
    max-width: 100%;
    margin-bottom: 1rem;

    @media (max-width: 600px) {
      width: auto;
    }
  }
`;
