import { useEffect } from 'react';

function ClickOutsideEvent(ref, listenerFunction) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        listenerFunction();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, listenerFunction]);
}

export default ClickOutsideEvent;
