import React, { useState } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Icon,
  UncontrolledTooltip
} from '@polichat/flamboyant';

import { useDispatch, useSelector } from 'react-redux';
import {
  setOpenAddContact,
  setOpenImportContact,
  setOpenEditContact,
  setAddOrganizationForm,
} from '../../../../store/modules/chat/actions';

import { isMobile } from 'react-device-detect';
import { useFlags } from 'flagsmith/react';
import { useWhiteLabel } from '../../../../hooks/useWhiteLabel';

function MoreActionsDropdown(props) {
  const hasCrm = useSelector(state => state.chat.config.general?.crm.permissions?.
    customerPermission);
  const createCompanyDropdown = useFlags(['create_company_dropdown']).create_company_dropdown.enabled;
  const dispatch = useDispatch();
  const [moreActionsContacts, setMoreActionsContacts] = useState(false);
  const isWl = useWhiteLabel().isWl;

  const toggle = () => setMoreActionsContacts(!moreActionsContacts);

  function handleAddNewContact(value) {
    dispatch(setOpenImportContact(false));
    dispatch(setOpenEditContact(false));
    dispatch(setOpenAddContact(value));
  }

  function handleAddNewCompany(value){
    dispatch(setAddOrganizationForm(value))
  }

  return (
    <div className="filter-content">
      <Dropdown isOpen={moreActionsContacts} toggle={toggle} direction="down">
        <DropdownToggle
          className="btnFilterDropdown"
          border="border-none"
          color="light"
        >
          <Icon size={20} icon="poli-icon pi-bullet-fill" />
        </DropdownToggle>
        <DropdownMenu className="menuDropdownShow">
          <DropdownItem
            className="itemDropdownMenu"
            onClick={() => {
              if (isMobile) {
                // Usando deste jeito porque com estado não estava funcionando 100%
                document
                  .querySelector('.chatPanelContainer')
                  .classList.add('hidden');
              }
              handleAddNewContact(true);
            }}
          >
            <Icon
              icon={'poli-icon pi-perfil-line'}
              color={'var(--green-polichat)'}
              size={20}
            />
            <p className="alternative">Adicionar contato</p>
            <Icon
              icon={'poli-icon poli-icon pi-add-line'}
              color={'var(--secondary-font-color)'}
              size={20}
            />
          </DropdownItem>
          {createCompanyDropdown ? (
            <DropdownItem
              disabled={!hasCrm}
              className="itemDropdownMenu"
              onClick={() => {
                if (isMobile) {
                  // Usando deste jeito porque com estado não estava funcionando 100%
                  document
                    .querySelector('.chatPanelContainer')
                    .classList.add('hidden');
                }
                handleAddNewCompany(true);
              }}
            >
              <Icon
                icon={'poli-icon pi-company-line'}
                color={'var(--green-polichat)'}
                size={20}
              />
              <p className="alternative">Adicionar Empresa</p>
              {!hasCrm && !isWl ? (
                <>
                  <UncontrolledTooltip
                    placement="down"
                    target="create-company-dropdown"
                  >
                    O acesso à edição do campo "Empresa" está disponível apenas
                    para contas com permissões de CRM ativas.
                  </UncontrolledTooltip>
                  <Icon
                    icon="poli-icon pi-info-fill infoChannelIcon"
                    id="create-company-dropdown"
                  />
                </>
              ) : (
                <Icon
                  icon={'poli-icon poli-icon pi-add-line'}
                  color={'var(--secondary-font-color)'}
                  size={20}
                />
              )}
            </DropdownItem>
          ) : (
            <></>
          )}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}

export default MoreActionsDropdown;
