export const ActionTypes = {
  GET_CATEGORIES: '@SHOP/CATEGORIES/GET_CATEGORIES',
  GET_CATEGORIES_SUCCESS: '@SHOP/CATEGORIES/GET_CATEGORIES_SUCCESS',
  GET_CATEGORIES_FAILURE: '@SHOP/CATEGORIES/GET_CATEGORIES_FAILURE',

  RESET_CATEGORIES_STATUS: '@SHOP/CATEGORIES/RESET_CATEGORIES_STATUS',
};

 /**
   * params
   * {
   *  per_page: 10,
   *  page: 1,
   *  status: 1
   * }
 */
export const getCategories = (params = null) => ({
  type: ActionTypes.GET_CATEGORIES, params
});

export const getCategoriesSuccess = (data) => ({
  type: ActionTypes.GET_CATEGORIES_SUCCESS, data
});

export const getCategoriesFailure = (error) => ({
  type: ActionTypes.GET_CATEGORIES_FAILURE, error
});

export const resetCategoriesStatus = () => ({
  type: ActionTypes.RESET_CATEGORIES_STATUS
});
