export const ActionTypes = {
  CREATE_PRODUCT: '@SHOP/PRODUCTS/CREATE_PRODUCT',
  CREATE_PRODUCT_SUCCESS: '@SHOP/PRODUCTS/CREATE_PRODUCT_SUCCESS',
  CREATE_PRODUCT_FAILURE: '@SHOP/PRODUCTS/CREATE_PRODUCT_FAILURE',

  UPDATE_PRODUCT: '@SHOP/PRODUCTS/UPDATE_PRODUCT',
  UPDATE_PRODUCT_SUCCESS: '@SHOP/PRODUCTS/UPDATE_PRODUCT_SUCCESS',
  UPDATE_PRODUCT_FAILURE: '@SHOP/PRODUCTS/UPDATE_PRODUCT_FAILURE',

  GET_CATEGORIES: '@SHOP/PRODUCTS/GET_CATEGORIES',
  GET_CATEGORIES_SUCCESS: '@SHOP/PRODUCTS/GET_CATEGORIES_SUCCESS',
  GET_CATEGORIES_FAILURE: '@SHOP/PRODUCTS/GET_CATEGORIES_FAILURE',

  RESET_PRODUCT_STATUS: '@SHOP/PRODUCTS/RESET_PRODUCT_STATUS',
  RESET_CATEGORIES_STATUS: '@SHOP/PRODUCTS/RESET_CATEGORIES_STATUS',
};

/**
  @param params
  {
    "name": "nome do produto",
    "description": "descricao",
    "status": 1,
    "code": "a6s5d42s3",
    "price": 15000,
    "quantity": 20,
    "Product_id": 5,
    "image_url": null
  }
*/
export const createProduct = (data) => ({
  type: ActionTypes.CREATE_PRODUCT, data
});

export const createProductSuccess = (data) => ({
  type: ActionTypes.CREATE_PRODUCT_SUCCESS, data
});

export const createProductFailure = (error) => ({
  type: ActionTypes.CREATE_PRODUCT_FAILURE, error
});


/**
  @param params
  {
    "name": "nome do produto",
    "description": "descricao",
    "status": 1,
    "code": "a6s5d42s3",
    "price": 15000,
    "quantity": 20,
    "Product_id": 5,
    "image_url": null
  }
*/
export const updateProduct = (id, data) => ({
  type: ActionTypes.UPDATE_PRODUCT, id, data
});

export const updateProductSuccess = (data) => ({
  type: ActionTypes.UPDATE_PRODUCT_SUCCESS, data
});

export const updateProductFailure = (error) => ({
  type: ActionTypes.UPDATE_PRODUCT_FAILURE, error
});

 /**
   * params
   * {
   *  per_page: 1,
   *  page: 1,
   *  status: 1
   * }
 */
export const getCategories = (params = null) => ({
  type: ActionTypes.GET_CATEGORIES, params
});

export const getCategoriesSuccess = (data) => ({
  type: ActionTypes.GET_CATEGORIES_SUCCESS, data
});

export const getCategoriesFailure = (error) => ({
  type: ActionTypes.GET_CATEGORIES_FAILURE, error
});

export const resetProductStatus = () => ({
  type: ActionTypes.RESET_PRODUCT_STATUS,
})
export const resetCategoriesStatus = () => ({
  type: ActionTypes.RESET_CATEGORIES_STATUS,
})
