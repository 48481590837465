import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

import { Container, Col, Row, Button } from '@polichat/flamboyant';
import { useHistory } from 'react-router-dom';

import PageMainHeader from '../../../../common/pages/PageMainHeader/PageMainHeader.component';
import PageMainBody from '../../../../common/pages/PageMainBody/PageMainBody.component';

import ProgressBar from '../ProgressBar/ProgressBar.component';
import GatherDocuments from '../steps/GatherDocuments/GatherDocuments.component';
import ChannelAddWabaClassicModal from './ChannelAddWabaClassicModal';
import channelAddWabaImage from './channelAddWabaImage.svg';

import poliConfig from '../../../../../utils/poliConfig';

// colocando homologação waba
const ColStyled = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ChannelAddWaba = () => {
  const history = useHistory();
  // Pegar state enviado pela URL
  const { state: historyState } = history.location

  // This variables are used to receive state of migrate from polizat to waba
  let channelPhone, channelName;

  if (history && history?.location?.state?.data) {
    channelName = history?.location?.state?.data?.channelName;
    channelPhone = history?.location?.state?.data?.channelPhone;
  }

  // verifica se está na lista de customer liberados
  const currentCustomer = useSelector(
    (state) => state.general.current_customer_id
  );
  const currentCustomerCanUseWabaHomologation =
    poliConfig.customers_enable_homologation_classic_waba.includes(
      currentCustomer
    );

  const [processSelected, processSelectedSet] = useState(
    history?.location?.state?.data?.isMigrationToWaba
  );
  const [processClassic, processClassicSet] = useState(
    history?.location?.state?.data?.isMigrationToWaba
  );

  const handleClassicSelect = () => {
    processClassicSet(true);
  };

  const handleProccessClassicNext = () => {
    processClassicSet(true);
    processSelectedSet(true);
  };

  const handleProccessNewNext = () => {
    processClassicSet(false);
    processSelectedSet(true);
  };

  const handleCloseModal = () => {
    processClassicSet(false);
  };

  useEffect(()=> {
    /**
     * verifica se a empresa passou pela analise do plano antes de criar canal
    */
     if(!historyState?.validated_can_add_channel){
      history.push(`/tools/channels/add`)
      return
    }
  }, [])

  return (
    <>
      <PageMainHeader />
      <PageMainBody>
        {poliConfig.waba_homologation_classic ||
        currentCustomerCanUseWabaHomologation ? (
          <>
            {!processSelected && (
              <>
                <ProgressBar />
                <Container>
                  <Row>
                    <Col sm="12" md="6">
                      <img src={channelAddWabaImage} />
                    </Col>
                    <ColStyled sm="12" md="6">
                      <Button
                        color="primary"
                        gradient="primary"
                        width="total"
                        size={isMobile ? 'md' : 'lg'}
                        style={{ padding: '2rem', marginBottom: '1rem' }}
                        onClick={handleProccessNewNext}
                      >
                        <div>Prosseguir com a homologação</div>
                        <div>da sua conta do WhatsApp</div>
                      </Button>

                      <Button
                        color="light"
                        width="total"
                        size="sm"
                        onClick={handleClassicSelect}
                      >
                        Fazer o processo de homologação clássico
                      </Button>
                    </ColStyled>
                  </Row>
                </Container>
                <ChannelAddWabaClassicModal
                  isOpen={processClassic}
                  handleCloseModal={handleCloseModal}
                  handleProccessClassicNext={handleProccessClassicNext}
                  handleProccessNewNext={handleProccessNewNext}
                />
              </>
            )}
            {processSelected && (
              <>
                <ProgressBar currentStep={1} classic={processClassic} />
                <GatherDocuments classic={processClassic} />
              </>
            )}
          </>
        ) : (
          <>
            <ProgressBar currentStep={1} classic={false} />
            <GatherDocuments classic={false} />
          </>
        )}
      </PageMainBody>
    </>
  );
};

export default ChannelAddWaba;
