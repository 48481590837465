const searchToObject = () => {
  var pairs = window.location.search.substring(1).split('&'),
    obj: any = {},
    pair,
    i;

  for (i in pairs) {
    if (pairs[i] === '') continue;

    pair = pairs[i].split('=');
    const str: string = decodeURIComponent(pair[0]);
    obj[str] = decodeURIComponent(pair[1]);
  }

  return obj;
};

export { searchToObject };
