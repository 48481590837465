import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem;
  padding: 0 1rem;
`;

export const TextInfos = styled.div`
  display: flex;
  flex-direction: column;

  & button {
    margin-left: 1rem;
  }

  @media (max-width: 600px) {
    span {
      font-size: 0.9rem;
    }

    & button {
      width: 100%;
      margin: 0.5rem 0;
    }
  }
`;

export const CardsContainer = styled.div`
  margin: 2rem 0;
  padding: 1rem 0;

  @media (max-width: 600px) {
    margin-top: 1rem;
    padding: 0;
  }
`;

export const FourColumnsGrid = styled.div`
  display: grid;
  grid-template-columns: ${(props: { columns: string }) =>
    props.columns ? props.columns : '1fr 1fr 1fr 1fr'};
  gap: 2rem;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;

export const ContainerSuccessFB = styled.div`
  display: grid;
  grid-template-columns: 1fr 5% 1fr 5% 1fr;
  gap: 2rem;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;

export const IconCenterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ErrorFbContainer = styled.div`
  margin-top: 20px;
  text-align: center;

  h2 {
    font-size: 36px;
    line-height: 46px;
    margin-bottom: 60px;
  }

  p {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 30px;
  }
`;

export const SuccessFbContainer = styled.div`
  margin-top: 20px;

  h2 {
    font-size: 28px;
    line-height: 40px;
    margin-bottom: 40px;
  }

  p {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 30px;
  }
`;

export const ContentInfo = styled.div`
  width: 100%;
  margin-top: 40px;

  p {
    margin: 0;
    margin-top: 15px;
  }

  i {
    font-style: italic;
  }
`;

export const SelectDropdownList = styled.select`
  width: 100%;
  cursor: pointer;

  background-color: white;
  border: thin solid var(--border-color);
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;

  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;

  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;

  &:focus {
    border: thin solid var(--border-color);
  }
`;
