export const ActionTypes = {
  GET_PRODUCTS: '@SHOP/PRODUCTS/GET_PRODUCTS',
  GET_PRODUCTS_SUCCESS: '@SHOP/PRODUCTS/GET_PRODUCTS_SUCCESS',
  GET_PRODUCTS_FAILURE: '@SHOP/PRODUCTS/GET_PRODUCTS_FAILURE',

  RESET_PRODUCTS_STATUS: '@SHOP/PRODUCTS/RESET_PRODUCTS_STATUS',
};

/**
   * @param params
   * {
   *  per_page: 10,
   *  page: 1,
   *  status: 1
   * }
   */
export const getProducts = (params = null) => ({
  type: ActionTypes.GET_PRODUCTS, params
});

export const getProductsSuccess = (data) => ({
  type: ActionTypes.GET_PRODUCTS_SUCCESS, data
});

export const getProductsFailure = (error) => ({
  type: ActionTypes.GET_PRODUCTS_FAILURE, error
});

export const resetProductsStatus = () => ({
  type: ActionTypes.RESET_PRODUCTS_STATUS,
})
