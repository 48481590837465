import { useSelector } from 'react-redux';
import { useFlags } from 'flagsmith/react';

export function useDropzoneLegendImageFlag() {
  const currentCustomer = useSelector(
    (state: any) => state?.chat?.config?.user?.current_customer_id
  ) as number;

  const { enabled, value } = useFlags(['dropzone_legend']).dropzone_legend as {
    enabled: boolean;
    value: string;
  };

  return {
    showDropzoneWithLegendV2:
      enabled && value?.includes(String(currentCustomer)),
  };
}
