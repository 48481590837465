import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from '@polichat/flamboyant';
import history from 'src/services/history';

import PageMainBody from '../../../../../common/pages/PageMainBody/PageMainBody.component';
import PageMainHeader from '../../../../../common/pages/PageMainHeader/PageMainHeader.component';
import { Container } from '../../../../channels/Certification/steps/Congratulations/Congratulations.styles';
import Illustration from 'src/assets/images/illustrations/illustration-welcome-waba-channel.svg';

import { ProgressBarMigrateChannelToPoli } from 'src/components/tools/migrateChannel/components/progressBar';
import {
  StepValidateOTPAndRegisterChannel,
  StepValidateOTPAndRegisterChannelRef,
} from 'src/components/tools/migrateChannel/StepValidateOTPAndRegisterChannel';
import { useQueryParams } from './useQueryParams';
import { useSteps } from 'src/components/tools/migrateChannel/useSteps';
import { toast } from 'react-toastify';
export function RestartChannelUsingRegisterPage() {
  //@ts-ignore
  const { channel_id } = useParams();

  let query = useQueryParams();
  const queryPhoneId = query.get('phone_id');
  const [phoneId] = useState<string>(queryPhoneId ?? '');

  // refs values
  const stepRef = useRef<StepValidateOTPAndRegisterChannelRef>(null);

  const steps = useSteps();
  const [userCanGoNext, setUserCanNext] = useState(false);

  useEffect(() => {
    if (!phoneId) {
      toast.error('Erro ao carregar informações, tente novamente!');
      handleRedirect();
    }
  }, [phoneId]);

  // ---------------------------------------------
  // Functions
  function handleRedirect() {
    history.push(`/tools/channels/edit/${channel_id}`, {
      showChannelHealth: true,
    });
  }

  function handlePrev() {
    handleRedirect();
  }

  function handleNext() {
    stepRef.current?.submit();
  }

  // ---------------------------------------------
  // Transformations
  // ---------------------------------------------
  // Render

  return (
    <>
      <PageMainHeader
        children={undefined}
        icon="poli-icon pi-queue-line"
        iconSize={18}
        title="Registrar canal"
      />
      <PageMainBody>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: '0 1rem',
            padding: '2px',
            height: '-webkit-fill-available',
          }}
        >
          <div id="flex_item_1">
            <ProgressBarMigrateChannelToPoli current_step={steps.current} />
          </div>
          <div id="flex_item_2" style={{ flexGrow: '1' }}>
            {/* STEP : Request, Verify code and Register */}
            <div
              style={{
                display: steps.current === 0 ? 'block' : 'none',
              }}
            >
              <StepValidateOTPAndRegisterChannel
                title={'Confirmação do número do canal'}
                ref={stepRef}
                phone_id={parseInt(phoneId)}
                enableNextStep={() => {
                  setUserCanNext(true);
                }}
                onSuccess={() => {
                  steps.next();
                }}
                onError={() => {
                  setUserCanNext(true);
                }}
              />
            </div>
            {/* STEP : Success register */}
            <div
              style={{
                display: steps.current === 1 ? 'block' : 'none',
              }}
            >
              <div style={{ margin: '1rem', alignContent: 'center' }}>
                <Container>
                  <img src={Illustration} alt="Canal adicionado" />
                  <h2>Registro de canal finalizado com sucesso!</h2>
                  <Button onClick={handleRedirect}>Gerenciar canal</Button>
                </Container>
              </div>
            </div>
          </div>

          {/* Buttons next - prev */}
          <div style={{ display: steps.current === 0 ? 'block' : 'none' }}>
            <div
              id="flex_item_3"
              style={{
                display: 'grid',
                columnGap: '10px',
                gridTemplateColumns: '1fr 1fr',
                alignItems: 'center',
                justifyItems: 'center',
                marginBottom: '1rem',
              }}
            >
              <div>
                <Button onClick={handlePrev} outline>
                  Voltar
                </Button>
              </div>
              <div>
                <Button disabled={!userCanGoNext} onClick={handleNext}>
                  Próximo
                </Button>
              </div>
            </div>
          </div>
        </div>
      </PageMainBody>
    </>
  );
}
