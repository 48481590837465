import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useFlags } from 'flagsmith/react';

export function useQuickMessageSelectorFlag() {
  const currentCustomer = useSelector(
    (state: any) => state?.chat?.config?.user?.current_customer_id
  ) as number;

  const { enabled, value } = useFlags(['quick_selector_v2'])
    .quick_selector_v2 as { enabled: boolean; value: string };

  const showQuickMessageSelectorV2 = useMemo(() => {
    return enabled && value?.includes(String(currentCustomer));
  }, [currentCustomer, value, enabled]);

  return { showQuickMessageSelectorV2 };
}
