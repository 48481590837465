import { useFlags } from 'flagsmith/react';
import { CustomerChannelBalanceContext } from './context';
import { useBalanceService } from './useBalanceService';

export interface CustomerChannelBalanceProviderProps {
  contactId?: number;
  channelCustomerId?: number;
  children?: any;
}

export function CustomerChannelBalanceProvider(
  props: CustomerChannelBalanceProviderProps
 ) {
  const hook = useBalanceService({
    contactId: props.contactId,
    channelCustomerId: props.channelCustomerId,
  });

  // ---------------------------------------------
  // Transformations
  // ---------------------------------------------
  // Render

  return (
    <CustomerChannelBalanceContext.Provider value={hook}>
      {props.children}
    </CustomerChannelBalanceContext.Provider>
  );
}
