import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Button, Icon, Table, Row, Col, Alert } from '@polichat/flamboyant';

import history from '../../../../services/history';
import { bindActionCreators } from 'redux';

import * as ShoppingCategoriesList from '../../../../store/modules/shopping/categories-list/actions';
import * as ShoppingCategoriesManage from '../../../../store/modules/shopping/categories-manage/actions';

import Animation from '../../../common/animation';
import PageTitle from '../../common/PageTitle';
import CancelButton from '../../common/CancelButton';
import DeleteButton from '../../common/DeleteButton';

import TablePagination from '../../TablePagination';

import ModalStatus from '../../../common/modals/ModalStatus';
import ModalConfirmDelete from '../../../common/modals/ModalConfirmDelete';

function CategoryList({
  //actions list categories
  getCategories,
  resetCategoriesStatus,
  // store values list categories
  categories,
  isLoading,
  pagination,
  error,
  categoriesStatus,
  //actions categories manage
  updateCategory,
  resetCategoryStatus,
  //store values categories manage
  categoryStatus,
}) {
  /**
   * Requisição das categorias
   * params
   * {
   *  per_page: 10,
   *  page: 1,
   *  status: 1
   * }
   */

  //constantes para parâmetros de requisição
  const per_page = 10;
  const status = 1;

  useEffect(() => {
    getCategories({ per_page, status });
  }, []);

  //Realizar requisição de acordo com a página
  const onPageChange = (data) => {
    getCategories({ per_page, status, page: data.currentPage });
  };

  //Modal de confirmação
  const [modalConfirmData, setModalConfirmData] = useState('');
  const [modalConfirm, setModalConfirm] = useState(false);
  const toggleConfirm = () => setModalConfirm(!modalConfirm);

  //Estado para armazenar o toggle do modal do status de resposta
  const [modalStatus, setModalStatus] = useState(true);
  const toggleModalStatus = () => setModalStatus(!modalStatus);

  //sideEffects do modal do status de resposta
  useEffect(() => {
    if (!modalStatus) {
      if (categoryStatus) {
        getCategories({
          per_page,
          status,
          page: pagination.current_page,
        });
      }
      resetCategoryStatus();
      setModalStatus(true);
    }
  }, [modalStatus]);

  return (
    <>
      <div className="shoppingTablePageContainer">
        {/* <PageTitle title="Categorias" icon="poli-icon pi-category-line" /> */}

        <div className="containerMobile">
          <div className="shoppingSearchTableContainer">
            <Row style={{ textAlign: 'right' }}>
              <Col xs={12} sm={12} md={12} xl={12}>
                <Button
                  color="primary"
                  onClick={() => {
                    history.push({
                      pathname: 'category',
                      state: {
                        formCategoryType: 'add',
                      },
                    });
                  }}
                >
                  <span>Adicionar categoria</span>{' '}
                  <Icon
                    icon={'poli-icon pi-add-circle-line'}
                    color={'white'}
                    size={18}
                    position={'relative'}
                    top={3}
                    left={2}
                  />
                </Button>
              </Col>
            </Row>
          </div>
          <div className="shoppingTableContainer">
            {categoriesStatus === false && error && !isLoading ? (
              <p>
                <Button onClick={() => getCategories({ per_page, status })}>
                  Tentar novamente
                </Button>
              </p>
            ) : isLoading ? (
              <Animation
                icon="chat-loading"
                top={'50%'}
                background="rgba(0, 0, 0, 0)"
              />
            ) : categories && categories.length ? (
              <Table striped responsive hover className="shoppingCategories">
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th>Descrição</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {categories.map((category) => (
                    <tr key={category.id}>
                      <td>{category.name}</td>
                      <td className="tableEllipsis">{category.description}</td>
                      <td>
                        <Button
                          size={'normal-icon'}
                          color="info"
                          onClick={() => {
                            resetCategoriesStatus();
                            history.push({
                              pathname: 'category',
                              state: {
                                formCategoryType: 'edit',
                                category: category,
                              },
                            });
                          }}
                        >
                          <Icon
                            icon={'poli-icon pi-pencil-fill'}
                            position={'relative'}
                            top={'-3px'}
                            right={'3px'}
                            size={20}
                          />
                        </Button>
                        <Button
                          size={'normal-icon'}
                          color="danger"
                          onClick={() => {
                            toggleConfirm();
                            setModalConfirmData(category);
                          }}
                        >
                          <Icon
                            icon={'poli-icon pi-delete-fill'}
                            position={'relative'}
                            top={'-3px'}
                            right={'3px'}
                            size={20}
                          />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <Alert
                color="attention"
                style={{ margin: 0, justifyContent: 'center' }}
              >
                Não há categorias cadastradas
                <Icon icon="poli-icon pi-sad-fill" size={20} />
              </Alert>
            )}
          </div>
        </div>
        <div className="shoppingPaginationContainer">
          {pagination && (
            <TablePagination
              totalItens={pagination.total_registers_server}
              limitItens={pagination.registers_per_page}
              pageSide={1}
              onPageChange={onPageChange}
            />
          )}
        </div>
      </div>

      {/* Modal confirmação de exclusão */}
      <ModalConfirmDelete
        isOpen={modalConfirm}
        toggle={toggleConfirm}
        title={'Categoria ' + modalConfirmData.name}
        subtitle="essa categoria"
        warning="Categorias excluídas não podem ser recuperadas"
        onClick={() => {
          updateCategory(modalConfirmData.id, {
            ...modalConfirmData,
            status: 0,
          });
          toggleConfirm();
        }}
      />

      {categoryStatus === false && (
        <ModalStatus
          type="danger"
          isOpen={modalStatus}
          toggle={toggleModalStatus}
          title="Algo deu errado!"
          subtitle="Infelizmente tivemos problemas para deletar esta categoria"
        />
      )}
      {categoryStatus && (
        <ModalStatus
          type="success"
          isOpen={modalStatus}
          toggle={toggleModalStatus}
          title="Categoria deletada"
          subtitle="A categoria foi removida do catálogo"
        />
      )}
    </>
  );
}

const mapStateToPros = (state) => ({
  //categories list store
  isLoading: state.shoppingCategoriesList?.loadingGetCategories,
  categories: state.shoppingCategoriesList?.categories?.list,
  pagination: state.shoppingCategoriesList?.categories?.pagination,
  error: state.shoppingCategoriesList?.error,
  categoriesStatus: state.shoppingCategoriesList?.categoriesStatus,
  //categories manage store
  categoryStatus: state.shoppingCategoriesManage?.categoryStatus,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators(ShoppingCategoriesList, dispatch),
    ...bindActionCreators(ShoppingCategoriesManage, dispatch),
  };
};

export default connect(mapStateToPros, mapDispatchToProps)(CategoryList);
