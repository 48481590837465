import React, { useEffect } from 'react';
import ContactListItem from '../contactListItem';
import Animation from '../../common/animation';

import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import * as ChatActions from '../../../store/modules/chat/actions';
import { isMobile } from 'react-device-detect';

function ContactList({
  contactList,
  fetchMoreContactsRequest,
  fetchUniqueChatRequest,
  chats,
  openChat,
  idSelectedChat,
  openChatTab,
  searchValue,
  searchContactsResult,
  showBlockTwo
}) {
  const limit_contacts_per_page = 20;
  const isLoadingMoreContacts = contactList?.isLoadingMoreContacts;
  let contentContactList;

  // Scroll para buscar mais contatos
  const onScrollDownEffect = () => {
    let container = document.getElementById('contactListContainerMaster');

    container.onscroll = function () {
      if (
        container.scrollTop + container.offsetHeight >=
          container.scrollHeight &&
        contactList.length &&
        !isLoadingMoreContacts
      ) {
        if (contactList.length) {
          let page =
            Math.floor(contactList.length / limit_contacts_per_page) + 1;

          fetchMoreContactsRequest(page);
        }
      }
    };
  };

  // Buscar mais contatos ao scrollar para baixo
  useEffect(() => {
    onScrollDownEffect();
  });

  // Função que envia o contato para a aba de conversas
  async function handleSendContactToChat(contact) {
    // Se existir no state do chat um contato com esse ID então abre esse chat
    const hasContactInStore = chats.some((chat) => {
      return chat.id === contact.id;
    });

    if (isMobile) {
      document.querySelector('.contactPanelMobile')?.classList.add('hidden');
      document.querySelector('.topMobile')?.classList.add('hidden');
      document
        .querySelector('.topNavigationContainer')
        ?.classList.add('hidden');
    }

    if (hasContactInStore) {
      // Se existir o contato no Chat então abre o Chat
      openChat(contact.id);
      // Troca o id do chat selecionado para esse novo
      idSelectedChat(contact.id);
      // Redireciona para a Tab de Conversas
      openChatTab();
    } else {
      // Se não existir no state, então busca conversa única no socket
      await fetchUniqueChatRequest(contact.id);
      // Se existir o contato no Chat então abre o Chat
      openChat(contact.id);
      // Troca o id do chat selecionado para esse novo
      idSelectedChat(contact.id);
    }
    // Altera o estado para exibição de componentes específicos no aplicativo móvel
    isMobile && showBlockTwo()
  }

  if (searchValue && searchValue !== '' && !searchContactsResult) {
    return (
      <div className='chatNotFoundContainer'>
        <span>Nenhum contato encontrada.</span>
      </div>
    );
  }

  contactList = (searchContactsResult && searchContactsResult.length)
                  ? searchContactsResult
                  : contactList;

  contentContactList = contactList.map((contact) => {
    return (
      <div
        className="contactListContainer"
        key={contact.id}
        onClick={() => {
          handleSendContactToChat(contact);
        }}
      >
        <ContactListItem
          contact={contact}
          name={contact.name}
          avatarUrl={contact.picture}
          phoneNumber={contact.phone}
        />
      </div>
    );
  });

  return (
    <>
      {contentContactList}

      {isLoadingMoreContacts && (
        <div className="container-loading">
          <Animation icon="loading" size={'tiny'} />
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state, ownProps) => ({
  chat: state.chat,
  chats: state.chat?.chats,
  contactList: state.chat?.contacts,
  props: ownProps,
  searchValue: state.chat?.searchContact?.current,
  searchContactsResult: state.chat?.searchContact?.searchResult,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ChatActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ContactList);
