const facebookAppId = process.env.REACT_APP_META_APP_ID;

export function initFacebookSDK() {
  return new Promise((resolve) => {
    // wait for facebook sdk to initialize before starting the react app
    window.fbAsyncInit = function () {
      // JavaScript SDK K configuration and setup
      window.FB.init({
        appId: facebookAppId, // Facebook App ID
        cookie: true, // enable cookies
        xfbml: true, // parse social plugins on this page
        version: 'v16.0', //Graph API version
      });
    };

    // Load the JavaScript SDK asynchronously
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/pt_BR/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');

    resolve();
  });
}
