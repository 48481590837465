import React, { useState } from 'react';
import ContactList from '../../contacts/contactList';
import Search from '../../common/search';

import ContactsScreen from '../../chat/panel/filter/ContactsScreen';
import MoreActionsDropdown from '../../chat/panel/filter/MoreActionsDropdown';

import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { Loading } from 'src/components/chat/loading';
import NewTabs from 'src/components/chat/panel/NewTabs';

function ContactPanel() {
  // Variables in state
  const mobile = useSelector((state) => state.chat.mobile);
  const canAddContact =
    useSelector(
      (state) => state.chat?.config?.general?.settings.add_contact
    ) === 1;
  const [searching, setSearching] = useState(false);

  return (
    <div
      className={`chatPanelContainer ${
        isMobile && (mobile?.blockOne ? '' : 'hidden')
      }`}
    >
      {!isMobile ? (
        <NewTabs />
      ) : (
        <div style={{ margin: '10px 0px' }}>
          <Search
            placeholder="Pesquisar contato..."
            whoSendAction="contact"
            onSearch={setSearching}
          />
        </div>
      )}
      {!isMobile && (
        <>
          <div className="filtersContainer">
            <div className="searchGroupFilters">
              <Search
                placeholder="Pesquisar contato..."
                whoSendAction="contact"
                onSearch={setSearching}
              />
              &nbsp;
              <div className="filterButton">
                <ContactsScreen />
              </div>
              {canAddContact ? (
                <div className="filterButton">
                  <MoreActionsDropdown />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="panel-divider"></div>
        </>
      )}

      <div
        className="contactListContainerMaster"
        id="contactListContainerMaster"
      >
        {searching ? (
          <div style={{ height: '150px' }}>
            <Loading text="Carregando contatos..." />
          </div>
        ) : (
          <ContactList />
        )}
      </div>
    </div>
  );
}
export default ContactPanel;
