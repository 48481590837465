import { ChevronLeftLineLight2 } from '@poli-ink/react';
import { Button, Icon } from '@polichat/flamboyant';
import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import {
  showBlockTwo,
  showDetailsComponent,
} from '../../../../../store/modules/chat/actions';
import { toast } from 'react-toastify';
import getContactIdCrm from 'src/components/tools/integrations/crmRequests/getContactIdCrm';
import renewToken from '../../../../tools/integrations/crmRequests/renewToken';

export function EmbedAddOpportunitiesOrTickets(props) {
  const isDarkMode = JSON.parse(localStorage.getItem('@polichat/darkmode'));
  const dispatch = useDispatch();
  const contact = useSelector((state) => state?.chat?.currentContact?.contact);
  const crmToken = useSelector((state) => state?.chat?.crmToken);

  const getContactCrm = async () => {
    try {
      const response = await getContactIdCrm(contact);
      if (response && response.data) {
        return response?.data;
      }
    } catch (error) {
      console.error('Error getting contactIdCRM: ', error);
      return undefined;
    }
  };

  const renewCrmToken = async () => {
    try {
      const response = await renewToken();
      if (response && response.data) {
        return response?.data;
      }
    } catch (error) {
      console.error('Error getting new token: ', error);
      return undefined;
    }
  };

  const handleCardSaved = (data) => {
    if (data?.status === 1) {
      toast.success(`${props?.wichAction === 'opportunities' ? 'Oportunidade criada' : 'Ticket criado' } com sucesso!`);
      return props.openEmbedModal();
    }
    return toast.error(`Não foi possível criar ${props?.wichAction === 'opportunities' ? 'a oportunidade' : 'o ticket' }. \n Por favor, tente novamente.`);
  };

  useEffect(() => {
    const TCRM = new TCRMEmbed();
    const runCrm = async (token) => {
      const contactIdCRM = await getContactCrm();

      TCRM.config({
        url: `${process.env.REACT_APP_EMBED_CRM_URL}/embed/${props?.wichAction}/add?contactId=${Number(contactIdCRM?.integrationId)}`,
        apiHost: process.env.REACT_APP_EMBED_CRM_API_URL,
        userToken: token,
        iframeId: 'embed-crm',
        formsSettings: {
          keepOpenOnUpdate: true
        },
        layout: {
          darkMode: isDarkMode
        }
      });
      TCRM.events.addListener("cardSaved", handleCardSaved);
      TCRM.events.addListener("forcedLogout", reRunCrm);
      TCRM.auth().catch(reRunCrm);
      TCRM.show();
    };

    runCrm(crmToken);

    const reRunCrm = async () => {
      const { token } = await renewCrmToken();
      if (token) {
        await runCrm(token);
      }
    };

    return () => {
      TCRM.events.clearListeners();
    };
  }, [props?.wichAction]);

  return (
    <div className="opportunitiesAndTicketsEmbeded">
      <div className="title" onClick={props.openEmbedModal}>
        <ChevronLeftLineLight2 />
        <span>
          <strong>{props?.wichAction === 'opportunities' ? 'Oportunidades' : 'Tickets' }</strong>
        </span>
        <div className="closeDetails">
          <Button
              color="link"
              onClick={() => {
                isMobile && dispatch(showBlockTwo());
                dispatch(showDetailsComponent(false));
              }}
            >
              <Icon icon="poli-icon pi-close-fill" />
            </Button>
        </div>
      </div>
      <div className="opportunities h-100">
        <iframe
          id="embed-crm"
          title="Embed CRM"
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      </div>
    </div>
  );
}
