import {
  Button,
  Modal,
  ModalHeader,
  Icon,
  ModalBody,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Alert,
} from '@polichat/flamboyant';
import { useCallback, useState } from 'react';
import { Controller } from 'react-hook-form';
import { FormFeedback } from 'reactstrap';
import { CustomModalHeader } from 'src/components/tools/channels/CertificationWabaByPoli/DeleteChannelBsp/styles';
import { useChannelCustomerService } from './useChannelCustomerService';
import { toast } from 'react-toastify';
import history from 'src/services/history';
import { useLocalForm } from './useLocalForm';
import { truncate } from 'fs';
export interface DeleteChannelProps {
  channel_id: number;
  customer_id: number;
  channel_name: string;
}
export function DeleteChannelModal(props: DeleteChannelProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleIsModalOpen = useCallback(() => {
    setIsModalOpen((prev) => !prev);
  }, []);

  const form = useLocalForm({ name: props.channel_name });

  const service = useChannelCustomerService({
    channel_id: props.channel_id,
    customer_id: props.customer_id,
  });

  function handleSuccess() {
    toast.success('Canal excluido com sucesso');
    history.push('/tools/channels/list');
  }

  function handleError() {
    form.reset({ name: '' });
    toast.error('Error ao excluir canal, tente novamente');
  }
  function handleSubmit() {
    service.remove(handleSuccess, handleError);
  }

  return (
    <>
      <Button color="danger" onClick={handleIsModalOpen}>
        Excluir canal
      </Button>

      <Modal
        isOpen={isModalOpen}
        toggle={handleIsModalOpen}
        size="lg"
        className="modal-dialog-scrollable"
      >
        <CustomModalHeader>
          <Icon icon="poli-icon pi-delete-fill" color="var(--danger)" />
          Tem certeza?
        </CustomModalHeader>
        <ModalBody>
          <Alert color="danger">
            Atenção: Deseja excluir permanentemente este canal? Esta ação não
            poderá ser desfeita.
          </Alert>

          <form id="delete_channel" onSubmit={form.handleSubmit(handleSubmit)}>
            <Row>
              <Col>
                <FormGroup>
                  <Controller
                    control={form.control}
                    name="name"
                    render={({ field, fieldState }) => (
                      <>
                        <Label for="channel_name">
                          Digite, <strong>{props.channel_name}</strong> abaixo
                          para continuar:{' '}
                        </Label>
                        <Input
                          id="channel_name"
                          type="text"
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          invalid={!!fieldState.error}
                        />
                        <FormFeedback>{fieldState.error?.message}</FormFeedback>
                      </>
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col
                sm={{
                  offset: 3,
                  size: 'auto',
                }}
              >
                <Button
                  type="submit"
                  form="delete_channel"
                  color="danger"
                  disabled={!form.watchedValue.name || service.loading}
                >
                  Quero excluir este canal
                </Button>
              </Col>
            </Row>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
}
