import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import * as GeneralActions from '../../../store/modules/general/actions';
import { connect } from 'react-redux';
import PageMainHeader from '../../common/pages/PageMainHeader/PageMainHeader.component';
import PageMainBody from '../../common/pages/PageMainBody/PageMainBody.component';
import { Button, Icon, Modal, ModalBody, ModalHeader, Table } from '@polichat/flamboyant';
import history from '../../../services/history';
import { useRouteMatch } from 'react-router-dom';
import { getPlans } from '../../../utils/admin/plans/plans';
import Animation from '../../common/animation';

function PlanList() {

  const { url } = useRouteMatch();
  const [plans, setPlans] = useState([]);
  const [isOpenModalDetailsPlan, setIsOpenModalDetailsPlan] = useState(false);
  const toggleModalDetailsPlan = () => setIsOpenModalDetailsPlan(!isOpenModalDetailsPlan);
  const [dataModalDetailsPlan, setDataModalDetailsPlan] = useState({ id: null, name: '', description: '', value: 0, status: 0, isTrial: false, itemsPlan: [] });

  const [isLoading, setIsLoading] = useState(true);

  async function fetchData() {
    const plans = await getPlans();

    if (plans?.length <= 0) {
      history.push({ pathname: `/admin/welcome-plan` });
    }

    setPlans(plans);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchData();

    return () => {
      setPlans([]);
    };
  }, []);

  return (
    <>
      <PageMainHeader title='Planos' icon='poli-icon pi-list-customers-line'>
        <Button color='primary' onClick={() => {
          history.push({ pathname: `${url}/new` });
        }}><Icon icon='poli-icon pi-add-line' /> Adicionar plano </Button>
      </PageMainHeader>
      <PageMainBody>
        {isLoading ? (
          <Animation icon='chat-loading' top={'50%'} />
        ) :
          <Table responsive bordered striped hover>
            <thead>
            <tr>
              <th className='text-center'>Plano</th>
              <th className='text-center'>Preço</th>
              <th className='text-center'>Serviços</th>
              <th className='text-center'>Status</th>
              <th className='text-center' />
            </tr>
            </thead>
            <tbody>
            {plans?.map((plan, index) => {
              return <tr key={index}>
                <td className='text-center'>{plan.name}</td>
                <td className='text-center'>{(Number(plan.value))?.toLocaleString()}</td>
                <td className='text-center'>{plan.itemsPlan?.map((itemPlan, subIndex) => {
                  return <span key={subIndex}>
                  {`${itemPlan.quantity} ${itemPlan.service.name}${plan.itemsPlan.length !== (subIndex + 1) ? ', ' : ''} `}
                </span>;
                })}</td>
                <td style={plan.status === 1 ? { color: 'var(--success)' } : { color: 'var(--attention)' }} className='text-center'>
                  {plan.status === 1 ? 'Ativo' : 'Pausado'}
                </td>
                <td className='text-center'>
                  <Button color='primary' className='btn-sm' style={{ marginRight: '5px' }} onClick={() => {
                    setDataModalDetailsPlan({ ...plan });
                    toggleModalDetailsPlan();
                  }
                  }><Icon icon='poli-icon pi-eye-line' /> Visualizar </Button>
                  <Button color='secondary' className='btn-sm' style={{ marginLeft: '5px' }} onClick={() => {
                    history.push({ pathname: `${url}/${plan.id}/edit` });
                  }}><Icon icon='poli-icon pi-pencil-line' /> Editar </Button>
                </td>
              </tr>;
            })}
            </tbody>
          </Table>}
      </PageMainBody>
      <Modal isOpen={isOpenModalDetailsPlan} toggle={toggleModalDetailsPlan} size='lg' className='modal-dialog-scrollable'>
        <ModalHeader toggle={toggleModalDetailsPlan}>
          <Icon icon='poli-icon pi-list-customers-line' />
          <span>Detalhes do plano</span>
        </ModalHeader>
        <ModalBody>
          <strong>Plano:</strong><span> {dataModalDetailsPlan?.name} </span>
          <br /><br />
          <strong>Seviços</strong>
          <br />
          <Table responsive bordered striped hover>
            <thead>
            <tr>
              <th className='text-center'>Serviço</th>
              <th className='text-center'>Quantidade</th>
              <th className='text-center'>Valor</th>
              <th className='text-center'>Total</th>
            </tr>
            </thead>
            <tbody>
            {dataModalDetailsPlan?.itemsPlan?.map((itemPlan, index) => {
              return <tr key={index}>
                <td className='text-center'>{itemPlan.service.name}</td>
                <td className='text-center'>{itemPlan.quantity}</td>
                <td className='text-center'>{(Number(itemPlan.service.value))?.toLocaleString()}</td>
                <td className='text-center'>{(Number(itemPlan.service.value) * Number(itemPlan.quantity))?.toLocaleString()}</td>
              </tr>;
            })}
            </tbody>
          </Table>
        </ModalBody>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(GeneralActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PlanList);
