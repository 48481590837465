import styled from 'styled-components';

import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from '@polichat/flamboyant';

/**
 * Modal Custom
 */
export const CustomModal = styled(Modal)`
  & .modal-content {
    padding: 0;
  }
`;

export const CustomModalHeader = styled(ModalHeader)`
  padding: 0;
  .close {
    margin-top: -40px;
  }

  & button.close {
    top: 35px;
    left: -20px;
  }
`;

export const CustomModalBody = styled(ModalBody)`
  margin: 1.5rem;
  padding: 0;

  & .modal-title {
    display: block;
    margin-bottom: 25px;

    span {
      justify-content: center !important;
      font-size: 24px;
    }

    img {
      max-width: 40%;
      margin-bottom: 10px;
      margin-top: -10px;
    }
  }
`;

export const CustomModalFooter = styled(ModalFooter)`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  padding: 1.5rem;

  button {
    flex: 1;
    margin: 0;
  }

  .btn-block + .btn-block {
    margin: 0;
  }
`;

/* END - Modal Custom */

/**
 * Formulario
 */
export const CustomForm = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  select {
    padding-right: 1.5rem;
    display: flex;
  }

  .flex-form {
    flex: 1;
  }
`;

export const LabelForm = styled.label`
  font-size: 14px;
  margin: 0;

  color: var(--received-color);
`;

export const RadioGroup = styled.div`
  display: flex;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  .custom-control {
    padding-left: 1.5rem;
    padding-right: 3rem;
  }

  .custom-control-label {
    font-size: 14px;
    color: var(--received-color);
  }
`;
/* END - Formulario */

/**
 * Valor Total
 */
export const CustomModalPrice = styled(ModalBody)`
  margin: 0rem;
  padding: 0;
  padding-bottom: 0.5rem;
  background-color: var(--secondary-background-color);
`;

export const PriceView = styled.div`
  margin: 0.5rem 1.5rem;
  display: flex;
  justify-content: space-between;

  ._flex {
    flex: 1;
  }

  h5 {
    margin: 0;
    margin-top: 0.5rem;
    font-size: 1.5rem;
    color: var(--primary-font-color);
  }
`;
/**
 * End - Valor total
 */
