import { useCallback, useEffect, useState } from 'react';
import { Channel360DialogCustomerService } from '../../service';
import { DtoDialog360Channel } from '../../service/DtoDialog360Channel';
import { LocalFormValues } from '../useLocalForm/types';
import { getDtoDialog360ChannelFromLocalFormValues } from '../helpers';

interface Error {
  error: boolean;
  msg?: string;
}

export interface UseChannelCustomerServiceProps {
  customer_id: number;
  channel_id: number;
}
export function useChannelCustomerService(
  props: UseChannelCustomerServiceProps
) {
  // ---------------------------------------------
  // States
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error>({ error: false });
  const [data, setData] = useState<DtoDialog360Channel>();
  const [saveLoading, setSaveLoading] = useState(false);
  // ---------------------------------------------
  // Functions
  const load = useCallback(async (channelId: number, customerId: number) => {
    try {
      setLoading(true);
      const result = await Channel360DialogCustomerService.get(
        channelId,
        customerId
      );

      //@ts-ignore
      if (result.data && result.data?.statusCode === 401) {
        throw Error('Error ao buscar canal');
      } else {
        setData(result.data);
        setError({ error: false });
        setLoading(false);
      }
    } catch (error) {
      setError({
        error: true,
        msg: 'Erro ao buscar informações do canal, tente novamente!',
      });
      setLoading(false);
    }
  }, []);

  const edit = useCallback(
    async (
      values: LocalFormValues,
      onSuccess: () => void,
      onError: () => void
    ) => {
      try {
        setSaveLoading(true);

        if (!data) throw Error('Não é possivel editar');

        const dto = getDtoDialog360ChannelFromLocalFormValues(values);

        await Channel360DialogCustomerService.edit(
          dto,
          props.customer_id!,
          props.channel_id!
        );
        onSuccess();
        setSaveLoading(false);
      } catch (error) {
        onError();
        setSaveLoading(false);
      }
    },
    [props.customer_id, data]
  );
  // ---------------------------------------------
  // Effects
  useEffect(() => {
    if (props?.channel_id && props.customer_id) {
      load(props.channel_id, props.customer_id);
    }
  }, [props?.channel_id, props.customer_id, load]);
  // ---------------------------------------------
  // Transformations
  // ---------------------------------------------
  // API

  return {
    loading,
    data,
    error,
    edit,
    saveLoading,
  };
}
