import poliApiGatewayRequest from '../../../utils/poliApiGatewayRequest';
import poliConfig from '../../../utils/poliConfig';
import { DtoMetaResponse } from '../types';

import {
  DtoMigrateChannelWabaToPoli,
  DtoMigrateChannelWabaToPoliResponse,
  DtoPhoneCode,
  DtoRequestCode,
} from './types';

export class MetaService {
  static async Migrate(dto: DtoMigrateChannelWabaToPoli) {
    const url = `${poliConfig.apiGatewayBaseURL}/meta/migrate`;
    const result =
      await poliApiGatewayRequest.post<DtoMigrateChannelWabaToPoliResponse>(
        url,
        dto
      );
    return result;
  }
  static async RequestCode(phone_id: number, dto: DtoRequestCode) {
    const url = `${poliConfig.apiGatewayBaseURL}/meta/request_code/${phone_id}`;
    const result = await poliApiGatewayRequest.post<DtoMetaResponse>(url, dto);
    return result;
  }
  static async VerifyCode(phone_id: number, dto: DtoPhoneCode) {
    const url = `${poliConfig.apiGatewayBaseURL}/meta/verify_code/${phone_id}`;
    const result = await poliApiGatewayRequest.post<DtoMetaResponse>(url, dto);
    return result;
  }
  static async Register(phone_id: number) {
    const url = `${poliConfig.apiGatewayBaseURL}/meta/register/${phone_id}`;
    const result = await poliApiGatewayRequest.post<DtoMetaResponse>(url);
    return result;
  }
}
