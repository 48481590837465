export function launchWhatsAppSignup(setDebugToken, customer) {
  /**
   * Validate site
   */
  let site = customer?.site ?? '';
  if (!site.includes('https://') && !site.includes('http://')) {
    site = 'http://' + customer?.site;
  }
  /* end */

  /**
   * Validate number
   */
  let phone_code = 55;
  let phone_number = customer?.phone ?? '';

  let _code = phone_number.substr(0, 3);
  if (_code?.includes('+')) {
    phone_code = _code.substr(1, 3);
    phone_number = phone_number.substr(3, phone_number.length);
  } else if (_code?.includes('55')) {
    phone_number = phone_number.substr(2, phone_number.length);
  }
  /* end */

  // Launch Facebook login
  window.FB.login(
    function (response) {
      if (response.authResponse) {
        const accessToken = response.authResponse.accessToken;

        setDebugToken(accessToken);
      } else {
        setDebugToken('ERROR');
      }
    },
    {
      scope: 'business_management,whatsapp_business_management',
      extras: {
        feature: 'whatsapp_embedded_signup',
        version: 2,
        setup: {
          business: {
            name: customer?.name ?? '',
            email: customer?.contact_email ?? '',
            phone: {
              code: phone_code,
              number: phone_number,
            },
            website: site,
            address: {
              streetAddress1: customer?.adress_street ?? '',
              city: customer?.adress_city ?? '',
              state: customer?.adress_state ?? '',
              zipPostal: customer?.adress_postal_code ?? '',
              country: 'BR',
            },
            timezone: customer?.timezone ?? '',
          },
          phone: {
            displayName: customer?.name ?? '',
            category: '',
            description: '',
          },
        },
      },
    }
  );
}
