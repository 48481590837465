export function FormatedDate(date: string) {
  const dateSplit = date.split(' ')[0];
  const newDate = dateSplit.split('/');
  const finalDate = `${newDate[2]}/${newDate[1]}/${newDate[0]}`;

  return finalDate;
}

export function FormatedTypeChange(type: string) {
  switch(type){
    case 'ativado':
      return "Ativado";
    case 'desativado':
      return 'Desativado';
    case 'excluido':
      return 'Excluído';
    case 'desconectado':
      return 'Desconectado';
    case 'conectado':
      return 'Conectado';
  }
}
