import React from 'react';

function SidebarMainIcon(props) {
  return (
    <>
      <div className="mainLogo"></div>
    </>
  );
}

export default SidebarMainIcon;
// A Logo está vindo por background url no App.css
