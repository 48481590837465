import { useState, useEffect, useRef } from 'react';
import { Button, Icon } from '@polichat/flamboyant';

import useRecorder from './useRecorder';
import ListenAudio from './ListenAudio';

import Animation from '../../../../common/animation';

function RecordAudioBase64({ recording, onChangeRecording, onSend }) {
  const [blob, setBlob] = useState(null);

  const [file, setFile] = useState(null);
  const [hasStopped, setHasStopped] = useState(false);

  const recorder = useRecorder(setBlob);

  function showHiddenActions() {
    // Mostra a section de ações que foi ocultada ao abrir o gravador de audio
    if (document.querySelector('.groupActions')) {
      document.querySelector('.groupActions').classList.remove('hidden');
    }
    // Mostrando o container de Emoji que foi ocultado ao clicar em gravar audio, caso eles estivesse abertos
    if (document.querySelector('.emojiContainer')) {
      document.querySelector('.emojiContainer').classList.remove('hidden');
    }

    // Mostrando o container de Mensagens Rápidas que foi ocultado ao clicar em gravar audio, caso elas estivesse abertos
    if (document.querySelector('.quickMessagesContainer')) {
      document
        .querySelector('.quickMessagesContainer')
        .classList.remove('hidden');
    }

    setBlob(null);
    setFile(null);
  }

  useEffect(() => {
    async function getBase64() {
      const url = URL.createObjectURL(blob);
      const response = await fetch(url);
      const blob64 = await response.blob();
      const base64 = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob64);
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });

      setFile(base64);
    }

    if (blob) {
      getBase64();
    }
  }, [blob]);

  useEffect(() => {
    if (recording) {
      recorder.start();
    }
  }, [recording]);

  return (
    <>
      {recording && (
        <>
          <div className="recordAudioBox" id="recordAudioBox" tabIndex="0">
            {!hasStopped ? (
              <section className="groupAudioRecorder">
                <div className="groupAudioRecorderHeader">
                  <Icon icon="poli-icon pi-microphone-line" /> Gravação de áudio
                </div>
                <div className="groupAudioRecorderBody">
                  <>
                    <Animation icon="microphone" size="minorLittleTiny" />{' '}
                    Gravando...
                    <span className="countRecordAudioBox">
                      <Icon icon="poli-icon pi-timer-fill" />
                      <span id="countRecordAudio">{recorder.duration()}</span>
                    </span>
                  </>
                </div>
              </section>
            ) : (
              <section className="groupAudioRecorder">
                <ListenAudio blob={blob} />
              </section>
            )}

            <section className="groupActionsAudio">
              <div className="groupActionsRight">
                {/* Lixeira */}
                <Button
                  color="link"
                  onClick={() => {
                    recorder.stop();
                    setHasStopped(false);
                    onChangeRecording(false);

                    showHiddenActions();
                  }}
                >
                  <Icon icon={'poli-icon pi-delete-fill'} size={20} />
                </Button>

                {/* Parar Gravação Audio / Enviar Áudio */}
                {!hasStopped ? (
                  <Button
                    color="link"
                    onClick={() => {
                      recorder.stop();
                      setHasStopped(true);
                    }}
                    className="buttonHandleAudio"
                  >
                    <Icon icon={'poli-icon pi-video-stop-fill'} size={20} />
                  </Button>
                ) : (
                  <Button
                    color="link"
                    id="sendAudioMessageButton"
                    onClick={() => {
                      // onSend(base64, blob)
                      onSend(file, blob);
                      setHasStopped(false);
                      onChangeRecording(false);
                      showHiddenActions();
                    }}
                    title="Enviar"
                  >
                    <Icon
                      icon={'poli-icon pi-set-fill'}
                      color="var(--icon-color-font)"
                      size={20}
                    />
                  </Button>
                )}
              </div>
            </section>
          </div>
          <div className="coverRecording"></div>
        </>
      )}
    </>
  );
}

export default RecordAudioBase64;
