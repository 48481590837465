import { useEffect, useRef, useState } from 'react'
import { connect, useDispatch } from 'react-redux'

import { playSoundNotification } from 'src/store/modules/chat/actions'

import NotificationSound from '../../assets/audios/notification.mp3'

import styles from './styles.module.css'

const NotificationElement = ({ shouldPlaySound }: {shouldPlaySound: boolean}) => {

	const [soundEnabled, setSoundEnabled] = useState<boolean>(true)
	const audioRef = useRef<HTMLAudioElement | null>(null)
	const dispatch = useDispatch()

	useEffect(() => {
		const localStorageEnabled = localStorage.getItem('@poli/notificationsEnabled')

		if (localStorageEnabled) {
			setSoundEnabled(localStorageEnabled === 'true')
		}
		else {
			localStorage.setItem('@poli/notificationsEnabled', soundEnabled.toString())
		}
  }, [shouldPlaySound])

	useEffect(() => {
		if (shouldPlaySound && soundEnabled) {
			const audio = audioRef.current

			if (audio) {
				// Se o audio estiver tocando, pausa e volta para o início
				if(!audio.paused) {
					audio.pause()
					audio.currentTime = 0
				}

				/**
				* Se o usuário não tiver interagido com a página ainda,
				* o audio não vai tocar, então é necessário tentar novamente.
				* @see: https://developer.mozilla.org/en-US/docs/Web/Media/Autoplay_guide#the_play_method
				*/
				let attemptToPlay = setInterval(() => {
					audio.play()
					.then(() => {
						// Se o audio tocar, para de tentar tocar
						clearInterval(attemptToPlay)

						// Reseta o estado do audio
						// Esperamos 2500ms (2.5s) pois o audio tem 2s de duração
						setTimeout(() => {
							dispatch(playSoundNotification(false))
						}, 2500)
					})
					.catch(() => {
						console.log('waiting for user interaction to play audio...')
					})
				}, 1000)
			}	
		}

		return () => {
			if (audioRef.current) {
				audioRef.current.pause()
				audioRef.current.currentTime = 0
			}
		}

	}, [shouldPlaySound, soundEnabled])


	return (
		<div className={styles.notification_element}>
			<audio id="audio-notification" ref={audioRef}>
				<source src={NotificationSound} type="audio/mp3" />
			</audio>
		</div>
	)
}

const mapStateToProps = (state: any, ownProps: any) => ({
  shouldPlaySound: state.chat.shouldPlaySound,
  props: ownProps,
});

export default connect(mapStateToProps)(NotificationElement)
