import * as Sentry from '@sentry/browser';
import { CaptureConsole } from '@sentry/integrations';
import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import App from './App';
import history from './services/history';
import store from './store';
// import { datadogRum } from '@datadog/browser-rum';
import pckg from './../package.json';
// Supports weights 100-900
import '@fontsource-variable/raleway';
import { MobileButtonProvider } from './components/chat/content/contexts/mobileButton/provider';
/* import { worker } from './components/tools/channels/LogsChannel/__mocks__/logsChannel/_tests/utils/msw/worker'; */

/* if (process.env.NODE_ENV === `development`) {
  worker.start();
} */


Sentry.init({
  dsn: `${
    process.env.REACT_APP_SENTRY_DSN ??
    'https://960a8924751d4e90856ee72cd4f5c77a@sentry.poli.digital/3'
  }`,
  integrations: [
    new CaptureConsole({
      levels: ['info']
    })
  ],
  environment: `${process.env.REACT_APP_ENV ?? 'production'}`,
  release: pckg ? pckg.version : '',
});

// datadogRum.init({
//   applicationId: process.env.REACT_APP_DATADOG_APP_ID,
//   clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
//   site: 'us5.datadoghq.com',
//   service: process.env.REACT_APP_DATADOG_SERVICE,
//   env: process.env.REACT_APP_ENV,
//   // Specify a version number to identify the deployed version of your application in Datadog
//   // version: '1.0.0',
//   sessionSampleRate:100,
//   premiumSampleRate: 100,
//   trackUserInteractions: true,
//   defaultPrivacyLevel:'mask-user-input'
// });
// datadogRum.startSessionReplayRecording();

const flagSmithKey = process.env.REACT_APP_FLAGSMITH_KEY;

const PoliTheme = React.lazy(() => import('./themes/PoliTheme'));
const WhitelabelTheme = React.lazy(() => import('./themes/WhitelabelTheme'));


const ThemeSelector = ({ children }) => {
  let poliHostArray = ['app-spa', 'beta-spa', 'beta2-spa'];
  let isPoli =
    poliHostArray.includes(window.location.host.split('.')[0]) ||
    window.location.hostname === 'localhost';

  return (
    <>
      <Suspense fallback={<div></div>}>
        { isPoli
          ? (<PoliTheme />)
          : (<WhitelabelTheme />)
        }
        {children}
      </Suspense>

    </>
  )
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <>
    <FlagsmithProvider
      options={{ environmentID: flagSmithKey }}
      flagsmith={flagsmith}
    >
      <Provider store={store}>
        <Router history={history}>
          <ThemeSelector>
            <MobileButtonProvider>
              <App />
            </MobileButtonProvider>
          </ThemeSelector>
        </Router>
      </Provider>
    </FlagsmithProvider>
  </>
);
