import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { SearchQuickFormValues } from '../useSearchQuickForm';
import { QuickMessage } from '../../types/types';
import { QuickTypes } from '../../types/constants';
import { isMessageButtonsType, isMessageListOptionType } from '../utils';

export interface UseQuickMessageProps {
  type: SearchQuickFormValues['type']; // seleção do tipo de mensagem
  text: SearchQuickFormValues['text']; // input de busca
  channelId: number | null; // id do canal
  department_id: number | null; // seleção do departamento
  term: string; // termo de busca armazenado no redux (origem TextBox)
}
export function useFilterQuickMessage(props: UseQuickMessageProps) {
  const VALIDATE_TEMPLATE = 1;

  const messages = useSelector(
    (state: any) => state?.chat?.quickMessages?.messages
  ) as QuickMessage[];

  const filteredQuickMessages = useMemo(() => {
    if (!messages?.length) return [];
    return messages?.filter((message) => {
      // comparação do texto da mensagem
      // comparação da tag
      const containsName =
        message.text
          .toLocaleLowerCase()
          .includes(
            props.text?.toLocaleLowerCase()
              ? props.text.toLocaleLowerCase()
              : ''
          ) ||
        message.tag
          .toLocaleLowerCase()
          .includes(
            props.text?.toLocaleLowerCase()
              ? props.text.toLocaleLowerCase()
              : ''
          );
      //comparação da tag com
      const containsTag = message.tag
        .toLocaleLowerCase()
        .includes(
          props.term?.toLocaleLowerCase() ? props.term.toLocaleLowerCase() : ''
        );

      // comparação do id do departamento
      const containsDepartment = props.department_id
        ? message.department_id === props.department_id
        : true;

      // Filtro baseado no tipo de mensagem
      switch (props.type) {
        case QuickTypes.TEMPLATE:
          // Retorna apenas template validas do canal selecionado
          return (
            message.template !== null &&
            message.template === props.channelId &&
            message.validated === VALIDATE_TEMPLATE &&
            containsName &&
            containsDepartment &&
            containsTag
          );
        case QuickTypes.LIST_OPTION:
          return (
            isMessageListOptionType(message.template_name ?? '') &&
            containsName &&
            containsDepartment &&
            containsTag
          );
        case QuickTypes.BUTTONS:
          return (
            isMessageButtonsType(message.template_name ?? '') &&
            containsName &&
            containsDepartment &&
            containsTag
          );
        case QuickTypes.QUICK:
          return (
            !message.template &&
            !message.template_name?.includes(
              `polichat_${QuickTypes.LIST_OPTION}`
            ) &&
            !message.template_name?.includes(
              `polichat_${QuickTypes.BUTTONS}`
            ) &&
            containsName &&
            containsDepartment &&
            containsTag
          );
        default:
          return containsName && containsDepartment && containsTag;
      }
    });
  }, [
    messages,
    props.type,
    props.text,
    props.channelId,
    props.department_id,
    props.term,
  ]);

  return {
    filteredQuickMessages,
  };
}
