import { DtoDialog360Channel } from '../service/DtoDialog360Channel';

export function addChannelToLocalStorage(channelData: DtoDialog360Channel) {
  window.localStorage.setItem(
    '@poli/createChannel360',
    JSON.stringify(channelData)
  );
}

export function getChannelToLocalStorage(): DtoDialog360Channel | undefined {
  const info = window.localStorage.getItem('@poli/createChannel360');
  if (info) return JSON.parse(info);
  else return undefined;
}

export function removeChannelToLocalStorage() {
  window.localStorage.removeItem('@poli/createChannel360');
}
