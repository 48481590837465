import React, { useMemo } from 'react';
import { connect } from 'react-redux';

import Chat from './chat/Chat';
import Details from './details/Details';
import { isMobile } from 'react-device-detect';
import { bindActionCreators } from 'redux';
import { useSelector } from 'react-redux';
import { useAlteredBackButton } from '../../../utils/customHooks/useAlteredBackButton';
import * as ChatActions from '../../../store/modules/chat/actions';

let content;
let show = false;

function ChatContent({ chats, chatDetails, openTagList }) {
  const mobile = isMobile && useSelector((state) => state.chat.mobile);
  show = chats?.find((chat) => chat.open === true);

  useMemo(() => {
    if (show) {
      content = (
        <>
          <div
            className={`chatsContainer ${
              isMobile && (mobile?.blockTwo && !openTagList ? '' : 'hidden')
            }`}
            id="chatsContainer"
          >
            <Chat />
          </div>

          {chatDetails && (
            <div className="chatDetails">
              <Details />
            </div>
          )}
        </>
      );
    } else {
      content = <></>;
    }
  }, [show, chatDetails, mobile.blockTwo, chats, openTagList]);

  return content ? content : <></>;
}

const mapStateToProps = (state) => ({
  chats: state.chat.chats,
  chatDetails: state.chat.showDetails,
  openTagList: state?.chat?.openTagList,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ChatActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChatContent);
