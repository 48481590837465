import { CustomInput, FormGroup } from 'reactstrap';
import { Filter } from '../../ListMessagesBuilder.styles';

interface FilterStatusProps {
  handleChangeStatusFilter(value: any): void;
  statusValue: string;
  disabled: boolean;
}

export function FilterStatus(props: FilterStatusProps) {
  return (
    <Filter>
      <FormGroup>
        <CustomInput
          disabled={props.disabled}
          type="select"
          id="filter-status"
          name="customSelectStatus"
          value={props.statusValue}
          onChange={props.handleChangeStatusFilter}
        >
          <optgroup label="Filtrar por status">
            <option value="all">Todos os Status</option>
            <option value="approved">Aprovado</option>
            <option value="disapproved">Reprovado</option>
            <option value="waitingForApproval">Aguardando aprovação</option>
          </optgroup>
        </CustomInput>
      </FormGroup>
    </Filter>
  );
}
