import React, { useContext, useEffect, useState } from 'react';
import { Menu, Close } from '@poli-ink/react';
import { CloseButtonMobile } from '../../chat/content/contexts/mobileButton/context';

function MobileMenu() {
  const [changeLogo, setChangeLogo] = useState(true);
  const { isClose } = useContext(CloseButtonMobile);

  useEffect(() => {
    setChangeLogo(isClose)
  }, [isClose])

  return (
      <div className='topMobile'>
        <div
          className="topMenuButton"
          onClick={() => {
            let sidebarElement = document.querySelector('.sidebarContainer')
              ?.classList;
            let chatPanelElement = document.querySelector('.chatPanelContainer')?.classList;
            let mainLogo = document.querySelector('.mainLogo')?.classList;
            let retratilButton = document.querySelector('#retratilButton')?.classList;
            retratilButton?.add('hiddeLogo');
            mainLogo?.add('hiddeLogo');
            let sidebar = document.querySelector('#root')?.classList;
            let sidebarMenu = document.querySelector('.sidebarMenu')?.classList;
            sidebarMenu?.add('sidebarHeight');

            if (sidebarElement?.contains('showMobileMenu')) {
              sidebarElement?.remove('showMobileMenu');
              chatPanelElement?.remove('showMobileMenu');
              sidebarMenu?.remove('sidebarHeight');
              sidebar?.add('menuRetratil');
              setChangeLogo(true)
            } else {
              sidebarElement?.add('showMobileMenu');
              chatPanelElement?.add('showMobileMenu');
              sidebar?.remove('menuRetratil');
              setChangeLogo(false)
            }
          }}
        >
          {changeLogo ? (
            <Menu className="topMenuIcon" />
          ) : (
              <div className="topMenuIconClose">
                <Close className="topMenuIconCloseI" />
              </div>
          ) }

        </div>
        <div className="topMobileImg"></div>
    </div>
  )
}
export default MobileMenu;
