import React, { useEffect, useState } from 'react';
import { Button, Alert, Icon } from '@polichat/flamboyant';

import ChannelsService from '../../../../../../services/tools/channels/channels';
import formatPhone from '../../../../../../utils/formatPhone';
import { handleExternalLink } from '../../../../../../utils/handleExternalLink';

import ProgressBar from '../../ProgressBar/ProgressBar.component';

import {
  Container,
  StatusContainer,
  ContentContainer,
  ItemAprroved,
  IconLoading,
  ButtonOpenFB,
  CardContainer,
  BtnBackContainer,
} from './FacebookStatus.styles';
import { RestartChannelUsingRegister } from '../../RestartChannelUsingRegister';
import Animation from 'src/components/common/animation';
import { RecreateTemplatesOnNewBsp } from '../../RecreateTemplateOnNewBsp';

interface Props {
  channelId: string;
  customer_id: string;
  showProfile: (value: boolean) => void;
}

interface Data {
  phone_name: string;
  phone_number: string;
  status_phone: string;
  response: {
    quality_rating: string;
  };
  account_rejected: boolean;
  account_approved: boolean;
  message: string;
  migratedQuantity: string;
  external_phone_id: string;
  code_verification_status: string;
  business_verification_status: string | undefined;
}


interface DataNew {
  phone_name: string;
  phone_number: string;
  status_phone: string;
  message: string;
  business_verification_status: string;
  migratedQuantity: string;
  external_phone_id: string;
  code_verification_status: string;
  account_rejected: boolean | undefined;
  account_approved: boolean | undefined;
  response: {
    quality_rating: string;
  };
}


const FacebookStatus = ({ channelId, customer_id, showProfile }: Props) => {
  const [data, setData] = useState<Data | DataNew>();
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [codeVerificationStatus, setCodeVerificationStatus] = useState({
    color: '',
    name: '',
  });
  const [qualityRating, setQualityRating] = useState({ color: '', name: '' });


  const getData = async () => {
    try {
      setIsLoading(true);
      const res: any = await ChannelsService.getWabaMetaStatus(channelId);
      const receivedData: any = res?.data ?? null;

      if (receivedData) {
        setIsLoading(false);
        setData(receivedData as Data);
      }
    } catch (error) {
      setIsLoading(false);
      setHasError(true);
      console.error(
        'Error in FacebookStatus component when getData => ',
        error
      );
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleClickFacebook = () => {
    handleExternalLink('https://business.facebook.com/settings/security');
  };
  useEffect(() => {
    switch (data?.response.quality_rating) {
      case 'GREEN':
        setQualityRating({ color: '#00c48c', name: 'Alta' });
        break;
      case 'YELLOW':
        setQualityRating({ color: '#ffcf5c', name: 'Média' });
        break;
      case 'RED':
        setQualityRating({ color: '#ff665d', name: 'Baixa' });
        break;
      case 'NA':
        setQualityRating({ color: '#858585', name: 'Indisponível' });
        break;
      default:
        setQualityRating({
          color: '#858585',
          name: 'Erro ao buscar a qualidade do canal',
        });
        break;
    }
  }, [data?.response.quality_rating]);

  useEffect(() => {
    switch (data?.code_verification_status) {
      case 'APROVED':
        setCodeVerificationStatus({ color: '#00c48c', name: 'Aprovado' });
        break;
      case 'REJECTED':
        setCodeVerificationStatus({ color: '#ff665d', name: 'Rejeitado' });
        break;
      case 'EXPIRED':
        setCodeVerificationStatus({ color: '#ffcf5c', name: 'Expirado' });
        break;
      default:
        setQualityRating({
          color: '#858585',
          name: 'Erro ao buscar a qualidade do canal',
        });
        break;
    }
  }, [data?.code_verification_status]);

  return (
    <>
      <ProgressBar title="Saúde do Canal WhatsApp" />

      {isLoading && (
        <div
          style={{
            padding: '2rem 0',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Animation icon="loading" />
          <p>Carregando informações do canal</p>
        </div>
      )}

      {hasError && (
        <div
          style={{
            padding: '2rem 0',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '5px',
          }}
        >
          <Icon
            icon="poli-icon pi-sad-line"
            size={50}
            color={'var(--danger)'}
          />
          <Alert color="danger">Erro ao buscar as informações do canal.</Alert>
        </div>
      )}

      {!isLoading && data && (
        <Container>
          {/* Status do Numero */}
          <StatusContainer>
            <div className="mb-3">
              <div className="d-flex mb-2">
                <Icon
                  icon="poli-icon pi-channels-fill"
                  color="var(--green-polichat)"
                  size="22"
                />
                <div className="ml-2">Nome do Canal</div>
              </div>
              <div className="ml-4">{data.phone_name}</div>
            </div>

            <div className="mb-3">
              <div className="d-flex mb-2">
                <Icon
                  icon="poli-icon pi-smartphone-fill"
                  color="var(--green-polichat)"
                  size="22"
                />
                <div className="ml-2">Telefone com DDD e código de país</div>
              </div>
              <div className="ml-4">{formatPhone(data.phone_number)}</div>
            </div>

            <div className="mb-3">
              <div className="d-flex mb-2">
                <div>Status</div>
              </div>
              <div className="d-flex mb-2">
                <Icon
                  icon="poli-icon pi-point-fill"
                  color={data.status_phone === 'Ativo' ? '#00c48c' : '#ff665d'}
                  size="22"
                />
                <div>{data.status_phone}</div>
              </div>
            </div>
          </StatusContainer>
          <ContentContainer>
            {/* Analise do facebook */}
            <div className="content">
              <ItemAprroved>
                <IconLoading>
                  <Icon
                    icon="poli-icon pi-loading-fill"
                    size={20}
                    color="#20EFE5"
                  />
                </IconLoading>
                <span>Análise do Facebook</span>
              </ItemAprroved>

                <p>
                  Assim que tivermos uma atualização do Facebook, você <br />
                  será avisado. Caso queira, acompanhe diretamente no <br />
                  Facebook por este link:
                </p>

              <ButtonOpenFB>
                <Button
                  size="sm"
                  color="info"
                  onClick={() => {
                    handleClickFacebook();
                  }}
                >
                  <Icon
                    icon={'poli-icon pi-facebook-fill'}
                    color="white"
                    size={25}
                  />
                  <span>Entrar com Facebook</span>
                </Button>
              </ButtonOpenFB>
            </div>

            {/* Verificação da empresa */}
            <div className="content">
              <CardContainer>
                <div className="d-flex">
                  {/* Aguardando analise */}
                  {!data.account_rejected && !data.account_approved && (
                    <Icon
                      icon="poli-icon pi-firewall-line"
                      size={34}
                      color="#20EFE5"
                    />
                  )}

                  {/* Conta rejeitada */}
                  {data.account_rejected && (
                    <Icon
                      icon="poli-icon pi-alert-fill"
                      size={34}
                      color="#ff665d"
                    />
                  )}

                  {/* Conta aprovada */}
                  {data.account_approved && (
                    <Icon
                      icon="poli-icon pi-finish-line"
                      size={34}
                      color="#00c48c"
                    />
                  )}

                  <h2>Verificação da empresa</h2>
                </div>

                <p>
                  {/* Aguardando analise */}
                  {!data.account_rejected && !data.account_approved && (
                    <>
                      Seus documentos estão sendo analisados. Você receberá um
                      status após a conclusão da análise.
                    </>
                  )}

                  {/* Conta rejeitada */}
                  {data.account_rejected && (
                    <>
                      A verificação da empresa foi rejeitada. Você pode
                      carregar documentos ou enviar os documentos existentes
                      novamente!
                    </>
                  )}

                  {/* Conta aprovada */}
                  {data.account_approved && <>Parabens, empresa aprovada!</>}
                </p>
              </CardContainer>

              <div
                style={{
                  padding: '1rem',
                  width: '80%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <RestartChannelUsingRegister
                  customer_channel_id={channelId}
                  channel_status={data ? data.code_verification_status : ''}
                  phone_id={
                    data.external_phone_id ? data.external_phone_id : ''
                  }
                />
              </div>
            </div>
          </ContentContainer>
          <div style={{ marginTop: '1rem' }}>
            <RecreateTemplatesOnNewBsp
              channelId={channelId}
              custommerId={customer_id}
            />
          </div>
          <BtnBackContainer>
            <Button color="primary" onClick={showProfile}>
              Voltar
            </Button>
          </BtnBackContainer>
        </Container>
      )}
    </>
  );
};

export default FacebookStatus;
