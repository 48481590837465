import * as React from 'react';
import { LogsChannelService } from '../../../../../../../services/tools/channels/logsChannels/logs/channels';
import { DtoAllUsers } from '../../../../../../../services/tools/channels/logsChannels/types/dtoAllUsers';

export interface useAllUsersProps {
  customerFilter: number;
}

export function useAllUsers(props: useAllUsersProps) {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<DtoAllUsers[]>();
  const showDeletedUsers = 1;

  const getUsers = React.useCallback(async (customer: number) => {
    try {
      setLoading(true);
      const response = await LogsChannelService.getAllusers({
        customer_id: customer,
        withDeleted: showDeletedUsers,
      });
      setData(response);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  React.useEffect(() => {
    getUsers(props.customerFilter);
  }, [getUsers, props.customerFilter]);

  return { loading, data };
}
