import poliApiGatewayRequest from '../../../utils/poliApiGatewayRequest';
import poliConfig from '../../../utils/poliConfig';

import Axios from 'axios';

import { DtoDeprecatedCustomer } from './types';

interface GetOrganizationIdParams {
  customer_id: number;
}

export class BusinessManagerService {
  static async DeprecatedCustomerId(params: GetOrganizationIdParams) {
    const url = `${poliConfig.apiGatewayBaseURL}/businessManager/deprecatedCustomerId/${params.customer_id}`;
    const result = await poliApiGatewayRequest.get<DtoDeprecatedCustomer>(url);
    return result.data;
  }
}
