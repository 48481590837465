import requestLaravel from '../laravel-web-app/request';

async function wlConfigRequest() {
  return requestLaravel({
    method: 'GET',
    url: '/api/reseller/settings',
    withCredentials: true,
    maxRedirects: 0,
    redirect: 'manual',
  }).then((response) => response.data);
}

export { wlConfigRequest };
