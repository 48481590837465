import { parseMessageTemplate } from '../../../../../../utils/chat/parseMessageTemplate';

export const getMessageBuilderTemplateText = (currentText: string) => {
  const data = parseMessageTemplate(currentText);

  if (!data.isFromMessageBuilder || !data.parsedMessage) return currentText;

  const { header, body, footer } = data.parsedMessage;

  return [header?.text ?? '', body, footer ?? ''].filter((s) => !!s).join('\n');
};
