import { Col, FormGroup, Label, Row } from '@polichat/flamboyant';
import { useState } from 'react';

import { OptionsContainer } from '../../FormMessagesBuilder.styles';

import CustomTextAreaWithEditor from '../../../../common/CustomTextAreaWithEditor.component';

import { Container } from './CreateBodyList.styles';

import AddVariable from '../../common/variable/AddVariable.component';

interface CreateBodyListProps {
  onUpdate: (value: string) => void;
  initialValue: {
    text: string;
  };
  isEdit: boolean;
}

function CreateBodyList({
  onUpdate,
  initialValue,
  isEdit,
}: CreateBodyListProps) {
  const [bodyData, setBodyData] = useState(initialValue);

  const handleInputChange = (value: string) => {
    setBodyData({
      ...bodyData,
      text: value,
    });
    handleBodyData({
      ...bodyData,
      text: value,
    });
  };

  const handleAddVariable = (data: any) => {
    const { variable } = data;
    const newText = bodyData.text + variable;

    setBodyData({
      ...bodyData,
      text: newText,
    });
    handleBodyData({
      ...bodyData,
      text: newText,
    });
  };

  const handleBodyData = (data: any) => {
    onUpdate(data);
  };

  return (
    <Row>
      <Col sm={12}>
        <FormGroup>
          <Label>Mensagem:</Label>
          <Container>
            <CustomTextAreaWithEditor
              disabled={isEdit}
              id="body-message"
              maxLength={1024}
              onChange={handleInputChange}
              placeholder="Corpo da mensagem"
              required
              showCharCounter
              value={bodyData.text}
            >
              <AddVariable onUpdate={handleAddVariable} isEdit={isEdit} />
            </CustomTextAreaWithEditor>
            <OptionsContainer></OptionsContainer>
          </Container>
        </FormGroup>
      </Col>
    </Row>
  );
}

export default CreateBodyList;
