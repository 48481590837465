import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { CustomInput, Icon, Button } from '@polichat/flamboyant';

import SendDocsImage from '../../../../../../assets/images/send-docs-1.svg';
import FacebookImage from '../../../../../../assets/images/send-docs-facebook-illustration.svg';

import history from '../../../../../../services/history';
import ChannelsService from '../../../../../../services/tools/channels/channels';
import ButtonDoubts from '../../common/ButtonDoubts/ButtonDoubts.component';
import InfoCard from '../../InfoCard/InfoCard.component';
import ActionGroupButtons from '../../ActionGroupButtons/ActionGroupButtons.component';
import {
  Container,
  TextInfos,
  FirstInfo,
  SecondInfo,
  ThirdInfo,
  CardsContainer,
  TwoColumnsGrid,
  CheckBoxContainer,
} from './SendDocs.styles';
import { handleExternalLink } from '../../../../../../utils/handleExternalLink';
import { toast } from 'react-toastify';

const SendDocs = ({ data, dataSet, currentStep }) => {
  const customer_id = useSelector((state) => state.general.current_customer_id);
  const [checked, setChecked] = useState(false);
  const [nextButton, nextButtonSet] = useState(false);

  const wlConfig = useSelector(
    // @ts-ignore
    (state) => state?.whitelabel?.config
  );

  // Actions to Actions Group Button component
  const goBack = () => history.push('/tools/channels');
  const handleNextAction = async () => {
    nextButtonSet(false);
    try {
      const step = parseInt(currentStep) + 1;
      const result = await ChannelsService.updateStepHomologation({
        customer_id,
        channel_id: data.id,
        step,
      });

      if (!result.data.error) {
        dataSet({ ...data, homologation: step });
      } else {
        toast.error(`Erro ao salvar: ${result.data.error}`)
      }

    } catch (error) {
      console.log(
        'Error in SendDocs Component when uptadeStepHomologation. Error => ',
        error
      );
    }
  };
  const handleChangeCheckBox = ({ target: { checked } }) => {
    setChecked(checked);
    nextButtonSet(checked);
  };
  return (
    <>
      <Container>
        {!wlConfig.hide_doubts_button && <ButtonDoubts />}

        <TextInfos>
          <FirstInfo>
            <span>
              Agora um novo item foi liberado para você. Clique em{' '}
              <strong>"Centro de Segurança"</strong> ou
            </span>
            <Button color="primary" size="sm" onClick={() => handleExternalLink('https://business.facebook.com/settings/security')}>
              Clique Aqui
            </Button>
          </FirstInfo>

          <SecondInfo>
            <span>
              Selecione a opção{' '}
              <strong>
                <code>"iniciar Verificação</code>
              </strong>
              , no painel do Facebook Business por favor.
            </span>
          </SecondInfo>

          <ThirdInfo>
            <span>
              Ele te dará acesso ao formulário de verificação do Facebook
              Business, onde você deverá enviar, entre outras informações,{' '}
              <strong>os documentos que reuniu na primeira etapa.</strong>
            </span>
          </ThirdInfo>
        </TextInfos>

        <CardsContainer>
          <TwoColumnsGrid>
            <InfoCard number="1">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <img
                  src={SendDocsImage}
                  alt="Envie os documentos que reuniu na primeira etapa"
                />

                <span
                  style={{
                    fontWeight: 'bold',
                    marginTop: '2rem',
                    fontSize: '1.1rem',
                  }}
                >
                  Envio da documentação que você reuniu na primeira etapa
                </span>
                <span style={{ marginTop: '1rem' }}>
                  O Facebook pedirá os documentos que comprovem o nome da
                  empresa. Nesta seção, envie: Cartão CNPJ, Alvará e Razão
                  Social. <br /> Na outra seção, ele solicita a comprovação do
                  telefone e do endereço. Envie: Conta de água/energia e conta
                  de telefone, contendo o número da linha de vocês, no nome da
                  empresa.
                </span>
              </div>
              <br />
            </InfoCard>

            <InfoCard number="2">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <img
                  src={FacebookImage}
                  alt="Aguarde o Facebook aprovar os documentos."
                  style={{ width: '80%' }}
                />

                <span
                  style={{
                    fontWeight: 'bold',
                    marginTop: '2rem',
                    fontSize: '1.1rem',
                  }}
                >
                  Agora você precisa esperar o Facebook aprovar a sua empresa
                </span>
                <span style={{ marginTop: '1rem' }}>
                  O tempo solicitado pelo Facebook é de 2 semanas, podendo se
                  extender em períodos expecionais, outros documentos podem ser
                  solicitados. Você sempre poderá ver o status de verificação
                  <Button
                    color="primary"
                    size="sm"
                    style={{ marginLeft: '0.3rem' }}
                    onClick={() => handleExternalLink('https://business.facebook.com/settings/security')}
                  >
                    Clique Aqui
                  </Button>
                </span>
              </div>
              <br />
            </InfoCard>
          </TwoColumnsGrid>
        </CardsContainer>

        <CheckBoxContainer>
          <CustomInput
            type="checkbox"
            id="exampleCustomCheckbox"
            label="Submeti a Documentação"
            checked={checked}
            onChange={handleChangeCheckBox}
          />
        </CheckBoxContainer>

        <ActionGroupButtons
          onClickNext={handleNextAction}
          onClickBack={goBack}
          nextButtonActived={!nextButton}
        />
      </Container>
    </>
  );
};

export default SendDocs;
