import React, { useState, useEffect } from 'react';
import FilterModalTemplate from './FilterModalTemplate';
import Animation from '../../../../common/animation';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ChatActions from '../../../../../store/modules/chat/actions';
import {
  handleMergeDepartmentToUsers,
  convertActiveTabInScreen,
  removeAccentString,
} from '../../../../../store/modules/chat/functions';

import InputSearch from '../../../../common/form/InputSearch';

import Avatar from '../../../../common/profile/Avatar';
import checkAvatar from '../../../../../utils/checkAvatar';

import {
  Icon,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
} from '@polichat/flamboyant';

// Array de formatação dos DropItems
let attendancesToShow = [];

var pageFull = 1;
var loadingListModal = false;

// Departamentos mesclado com usuarios
let departmentsMergedWithUsers = [];

function AttendanceModal({
  allUsers,
  isAttendanceMenuOpen,
  toggleFilterMenu,
  activeFilters,
  submitFilter,
  loading,
  clearFilter,
  currentUserDepartments,
  currentUser,
  allDepartments,
  currentTabNavigation,
}) {

  const [observerScroll, setObserverScroll] = useState(false);
  const [loadingListTop, setLoadingListTop] = useState(false);
  const [loadingListDown, setLoadingListDown] = useState(false);
  const limitePage = 4;

  const sortByName = (a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  };

  const filter_type_name = 'attendants';
  const filter_title = 'Filtrar por Atendente';
  const filter_icon = 'poli-icon pi-operator-line';
  const currentSuperRole = currentUser.superRole;
  const currentScreen = convertActiveTabInScreen(currentTabNavigation);

  /**
   * Montando array de usuario copia e original
   * Ordenando por nome
   */
  const [usersOriginal, setUsersOriginal] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (allUsers) {
      const allUsersSort = allUsers.sort(sortByName);
      let usersOriginal = allUsersSort;

      /**
       * Bloquear encaminhar contatos para atendentes offline e indisponíveis
       * blocked_redirect tive true no banco de dados
       */
      if (currentUser && currentUser?.blocked_redirect == 1) {
        usersOriginal = allUsersSort.filter(
          (e) => e.available_service == 1 && e.status == 1
        );
      }
      /* End */

      setUsersOriginal(usersOriginal);
      setUsers(usersOriginal);
    }
  }, [allUsers]);
  /* End */

  /**
   * Criando o array que contem departamento e usuarios separados
   */
  if (allDepartments && allDepartments.length > 0 && users && users.length) {
    if (currentSuperRole === 'adm' || currentSuperRole === 'manager') {
      departmentsMergedWithUsers = handleMergeDepartmentToUsers(
        allDepartments,
        users
      );
    } else if (
      currentSuperRole === 'supervisor' &&
      currentUserDepartments &&
      currentUserDepartments.length
    ) {
      departmentsMergedWithUsers = handleMergeDepartmentToUsers(
        currentUserDepartments,
        users
      );
    }
  }
  /* End */

  let enabled = true;

  function verifyCurrentFilter() {
    if (!activeFilters.length) {
      setFilter('');
    }
  }

  const findCurrentAttendantFilter = () => {
    let active_filter = '';

    if (activeFilters.length) {
      activeFilters.forEach((filter) => {
        if (filter.type === filter_type_name) {
          active_filter = filter.user_id;
        }
      });
    }

    return active_filter;
  };

  const [filter, setFilter] = useState(findCurrentAttendantFilter());
  const [selectedOperatorName, setSelectedOperatorName] = useState('');

  const findName = (id) => {
    verifyCurrentFilter();
    let user = users.find((user) => user.id === id);

    if (user) {
      if (user.name) {
        return user.name;
      } else if (user.email) {
        return user.email;
      } else {
        return '';
      }
    } else {
      return '';
    }
  };

  const onSubmitEvent = () => {
    let payload = Object.assign({}, activeFilters);

    if (filter) {
      let value = parseInt(filter);
      let name = findName(value);

      payload.attendant = [
        {
          name: name,
          value: value,
        },
      ];

      submitFilter(payload, currentScreen);
    } else {
      payload.attendant = [];
      clearFilter(payload);
    }

    toggleFilterMenu(filter_type_name);
  };

  /**
   * O que fazer ao selecionar um operador
   */
  useEffect(() => {
    // Resetando scrool ao selecionar um operador
    setObserverScroll(false);
    pageFull = 1;

    // Usuario Selecionado para mostrar no label do input
    if (usersOriginal && usersOriginal.length > 0) {
      const foundUser = usersOriginal.find((e) => e.id == filter);
      if (foundUser) setSelectedOperatorName(foundUser.name);
      else setSelectedOperatorName('Selecione um atendente');
    }
  }, [filter, isAttendanceMenuOpen]);
  /* End - O que fazer ao selecionar um operador */

  /**
   * Monitora o scroll da lista de transferencia
   */
  useEffect(() => {
    const ref = document.getElementById('listAttendanceScroll');
    if (ref && attendancesToShow?.length > 0 && observerScroll) {
      ref.addEventListener('scroll', (evt) => {
        let sizeHeightContainer = ref.offsetHeight; // tamanho do container visível para o usuário
        let sizeHeightContainerScroll = evt.target.scrollHeight; // tamanho do container inteiro de mensagens
        let scrollTop = evt.target.scrollTop; // quantidade de px foram rolados em uma transição

        const topPage = scrollTop === 0;
        const endPage =
          scrollTop + sizeHeightContainer >= sizeHeightContainerScroll;

        if (topPage) goPage(true);
        if (endPage) goPage();
      });
    }
  }, [observerScroll, attendancesToShow?.length]);

  /**
   * Encrementa mais uma pagina e coloca loading para nao ter loop infinito
   */
  const goPage = (top) => {
    if (!loadingListModal) {
      /**
       * Logica subir
       */
      if (top && pageFull >= limitePage) {
        loadingListPage(top);
        pageFull -= 1;

        setTimeout(() => {
          const ref = document.getElementById('listAttendanceScroll');
          if (ref) ref.scrollTop = 100;
        }, 600);
      }

      /**
       * Logica descer
       */
      if (!top && pageFull < attendancesToShow?.length) {
        loadingListPage();
        pageFull += 1;
      }
    }
  };

  const loadingListPage = (top) => {
    if (top) setLoadingListTop(true);
    else setLoadingListDown(true);

    loadingListModal = true;

    setTimeout(() => {
      if (top) setLoadingListTop(false);
      else setLoadingListDown(false);

      loadingListModal = false;
    }, 500);
  };
  /* End */

  /**
   * Montando a lista
   */
  useEffect(() => {
    /**
     * Redefinindo as configurações ao abrir modal
     */
    attendancesToShow = [];
    pageFull = 1;
    loadingListModal = false;
    const perPage = 25;

    /**
     * Scrool ficava no meio
     */
    const ref = document.getElementById('listAttendanceScroll');
    if (ref) ref.scrollTop = 0;
    /* End */

    /**
     * Filtro de Sem Atendente -> Necessário deixar o value = -1 pois o Laravel espera este valor
     */
    const notAttendance =
        <DropdownItem
          key={-1}
          value={-1}
          className="dropItem-filters"
          onClick={(e) => setFilter(-1)}
        >
          Sem Atendente
        </DropdownItem>
    /* End */

    /**
     * Se tiver departamentos e Merge de Usuario com departamentos cairá aqui
     */
    if (
      departmentsMergedWithUsers &&
      departmentsMergedWithUsers.length > 0 &&
      allDepartments &&
      allDepartments.length > 0
    ) {
      const render = [notAttendance];

      /**
       * Departamentos com seus usuarios
       */
      departmentsMergedWithUsers.map((element, counterFiltered) => {
        /**
         * Montando componente
         * Departamento
         */
        render.push(
          <React.Fragment
            key={`department-name-${element.department.id}-${counterFiltered}`}
          >
            <span
              className="departmentDropdown transfer-chat-margin"
              title={
                element.department.usersWithoutDept
                  ? 'Todos os atendentes que não tem departamentos'
                  : ''
              }
            >
              {element.department.name}
            </span>
            <DropdownItem divider></DropdownItem>
          </React.Fragment>
        );

        /**
         * Se Departamento nao tiver usuario
         */
        if (element?.users?.length === 0) {
          render.push(
            <p
              key={`user-not-exist-${element.department.id}-${counterFiltered}`}
            >
              --
            </p>
          );
        }

        /**
         * Montando componente
         * Usuarios do departamento
         */
        element?.users?.map((user) => {
          render.push(
            <DropdownItem
              key={`${user.id}+${counterFiltered}`}
              value={user.id}
              className="dropItem-filters"
              onClick={(e) => setFilter(user.id)}
            >
              <Avatar
                className="operator"
                title={user.name}
                url={checkAvatar(user.name, user.picture)}
                size={23}
                extraClass="transfer-options-avatar"
              />

              <span title={user.name}>{user.name}</span>

              {statusUserList(user)}
            </DropdownItem>
          );
        });
        /* End - Montando componente */
      });
      /* End - Usuarios do departamento */

      /**
       * Dividindo Array para nao carregar a lista de uma vez;
       */
      attendancesToShow = new Array(Math.ceil(render.length / perPage))
        .fill()
        .map((_) => render.splice(0, perPage));
    } else if (users && users.length) {
      /**
       * Caso a empresa não tenha nenhum departamento cadastrado
       * Necessário ter usuários
       */
      /**
       * Montando componente
       * Usuarios
       */
      const render = users.map((user, index) => {
        return (
          <DropdownItem
            key={`index${index}-user${user.id}`}
            value={user.id}
            className="dropItem-filters"
            onClick={(e) => setFilter(user.id)}
          >
            <Avatar
              className="operator"
              title={user.name}
              url={checkAvatar(user.name, user.picture)}
              size={23}
              extraClass="transfer-options-avatar"
            />
            {user.name?.split(' ')[0]}

            {statusUserList(user)}
          </DropdownItem>
        );
      });
      /* End - Montando componente */

      /**
       * Dividindo Array para nao carregar a lista de uma vez;
       */
      render.unshift(notAttendance);
      attendancesToShow = new Array(Math.ceil(render.length / perPage))
        .fill()
        .map((_) => render.splice(0, perPage));
    } else {
      /* End - Caso a empresa não tenha departamentos */

      /**
       * Cliente nao tem nem usuarios nem departamentos
       */
      attendancesToShow = [<span key="-2"></span>];
    }
    // /* End - Cliente nao tem nem usuarios nem departamentos */
  }, [
    departmentsMergedWithUsers?.length,
    allDepartments?.length,
    users?.length,
  ]);

  const statusUserList = (user) => {
    return (
      <>
        {user.available_service !== 1 ? (
          <>
            <Icon
              id="indisponivel"
              icon={'poli-icon pi-point-fill'}
              size={15}
            />
            <UncontrolledTooltip placement="top" target="indisponivel">
              Indisponível
            </UncontrolledTooltip>
          </>
        ) : user.status === 1 ? (
          <>
            <Icon id="online" icon={'poli-icon pi-point-fill'} size={15} />
            <UncontrolledTooltip placement="top" target="online">
              {'Disponível'}
            </UncontrolledTooltip>
          </>
        ) : (
          <>
            <Icon id="offline" icon={'poli-icon pi-point-fill'} size={15} />
            <UncontrolledTooltip placement="top" target="offline">
              {'Desconectado'}
            </UncontrolledTooltip>
          </>
        )}
      </>
    );
  };

  /* end - Montando a lista */

  /**
   * Campo de pesquisa e toda a logica de pesquisa do atendente
   */
  const [currentValueSearch, setCurrentValueSearch] = useState('');
  const [lastTimeout, setLastTimeout] = useState(0);
  const [loadingSearch, setLoadingSearch] = useState(false);

  const onUpdateSearchValue = (value) => {
    setLoadingSearch(true);
    setUsers([]);

    if (lastTimeout) {
      clearTimeout(lastTimeout);
    }

    setCurrentValueSearch(value);

    setLastTimeout(
      setTimeout(() => {
        setLoadingSearch(false);
        searchList(value);
      }, 1000)
    );
  };

    const filterBySupervisor = (users, currentUserDepartments) => {
      return users.filter((user) => {
          if (!user.departments || user.departments.length === 0) {
              return true;
          }
          return user.departments.some((userDept) => 
              currentUserDepartments.some((currentDept) => userDept.id === currentDept.id)
          );
      });
  };
  
  const searchList = (value = '') => {
      if (usersOriginal && usersOriginal.length) {
          const result = usersOriginal.filter((item) => 
              removeAccentString(item.name.toLowerCase()).includes(removeAccentString(value?.toLowerCase()))
          );
  
          if (currentSuperRole === 'supervisor') {
              setUsers(filterBySupervisor(result, currentUserDepartments));
          } else {
              setUsers(result);
          }
  
          loadingListPage();
      }
  };
  

  const searchRender = (
    <div className="transfer-chat-search">
      <InputSearch
        onChange={(e) => onUpdateSearchValue(e.target.value)}
        value={currentValueSearch}
        loading={loadingSearch}
        placeholder=""
        clearSearch={onUpdateSearchValue}
      />
    </div>
  );

  const notUsersRender = () => {
    if (loadingSearch) {
      return (
        <p className="text-center">
          <small>Buscando...</small>
        </p>
      );
    }

    if (users && users.length > 0) {
      return '';
    } else if (currentValueSearch?.toString()?.length > 0) {
      departmentsMergedWithUsers = [];
      return (
        <p className="text-center">
          <small>Nenhum resuldado encontrado!</small>
        </p>
      );
    } else {
      return (
        <p className="text-center">
          <small>Não existem atendentes cadastrados.</small>
        </p>
      );
    }
  };
  /* End - Search */

  /**
   * Renderizando lista de acordo com scroll
   * Montando o corpo da lista
   */
  const loadindRender = <Animation icon="loading" size="tiny" />;
  const [content, setContent] = useState(loadindRender);

  useEffect(() => {
    if (attendancesToShow && attendancesToShow.length > 0) {
      setContent(
        <UncontrolledDropdown className="dropDown-filters">
          <DropdownToggle
            color="neutral"
            caret
            id="operatorFilter"
            name="operatorFilter"
            className="total dropDownToggle-filters"
            onClick={(e) => {
              setObserverScroll(true);
            }}
          >
            <span>
              {filter != '' && filter != -1 && selectedOperatorName
                ? selectedOperatorName
                : filter == -1
                ? 'Sem Atendente'
                : 'Selecione um atendente'}
            </span>
          </DropdownToggle>
          <DropdownMenu className="transferContainer">
            {searchRender}
            {notUsersRender()}

            <div className="dropDownMenu-filters" id="listAttendanceScroll">
              {loadingListTop && <Animation icon="loading" size={'tiny'} />}

              {attendancesToShow.map((e, i) => {
                if (i > pageFull - limitePage && i < pageFull) return e;
              })}

              {loadingListDown && <Animation icon="loading" size={'tiny'} />}
            </div>
          </DropdownMenu>
        </UncontrolledDropdown>
      );

      enabled = true;
    } else {
      enabled = false;
      setContent(<Animation icon="loading" size="tiny" />);
    }
  }, [
    attendancesToShow?.length,
    pageFull,
    loadingListTop,
    loadingListDown,
    currentValueSearch,
    loadingSearch,
    selectedOperatorName,
    filter,
  ]);
  /* End */

  return (
    <FilterModalTemplate
      icon={filter_icon}
      title={filter_title}
      content={content}
      isOpen={isAttendanceMenuOpen}
      toggleEvent={() => toggleFilterMenu(filter_type_name)}
      onSubmit={onSubmitEvent}
      loading={loading}
      disabled={!enabled}
    />
  );
}

const mapStateToProps = (state) => ({
  allUsers: state.chat?.config?.general?.users,
  isAttendanceMenuOpen: state.chat?.filters?.view?.isAttendanceMenuOpen,
  activeFilters: state.chat?.filters?.activeFilters,
  loading: state.chat?.filters?.loading,
  currentUserDepartments: state.chat?.config?.user?.departments,
  currentUser: state.chat?.config?.user,
  allDepartments: state.chat?.config?.general?.departments,
  currentTabNavigation: state.chat?.config?.activeTabNavigation,
  current_customer_id: state.general?.current_customer_id,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ChatActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AttendanceModal);
