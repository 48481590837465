import axios from 'axios';
import ObjectToQueryString from '../../lib/ObjectToQueryString';

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const defaultConfig = {
  headers: defaultHeaders,
};

const getAllUsersByCustomer = async (params) => {
  const url =
    process.env?.REACT_APP_HTTP_USERS_SERVICE +
    `/users` +
    (params ? '?' + ObjectToQueryString(params) : '');

  const result = await axios.get(url, defaultConfig);
  return result.data;
};

export default {
  getAllUsersByCustomer,
};
