import axios from 'axios';
import poliConfig from '../../utils/poliConfig';

const instance = axios.create({
    baseURL: poliConfig.poliAppLaravelUrl,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
});

async function request(axiosConfig){
    let data = await instance.request(axiosConfig);
    return data;
    //analisar retorno de requiçoes não autorizadas para redirecionar para a tela de login
}

export default request;
