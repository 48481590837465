import React from 'react';

import WhatsAppLogo from '../../../../assets/images/whatsapp.svg';
import { useHistory } from 'react-router-dom';
import { Button, Icon } from '@polichat/flamboyant';

import {
  Container,
  IconLabelContainer,
  HorizontalRule,
} from './ChannelDescriptionBanner.styles';

export default function ChannelDescriptionBannerWebchat() {
  const history = useHistory();
  return (
    <>
      <Container>
        <IconLabelContainer>
          <Icon
            icon={'poli-icon pi-webchat-fill'}
            color={'var(--webchat-color)'}
            size={40}
          />

          <b>WebChat</b>
        </IconLabelContainer>

        <div>
          Os webhooks permitem que você crie integrações personalizadas,
          enviando informações para o seu servidor da web quando um determinado
          evento ocorre.
        </div>

        {/* Não está sendo utilizado por enquanto, mas vou deixar aqui caso queiramos reutilizar*/}
        {/* <Button
          color="primary"
          onClick={() => history.push('/tools/channels/add')}
          style={{
            width: '30rem',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <span
            style={{
              display: 'flex',
              gap: '0.1rem',
              alignItems: 'center',
            }}
          >
            <Icon icon={'poli-icon pi-add-line'} size={20} />
            <span> Adicionar Canal</span>
          </span>
          <Icon icon={'poli-icon pi-simple-arrow-down-line'} size={20} />
        </Button> */}
      </Container>
      <HorizontalRule />
    </>
  );
}
