import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { requestLoginWl } from '../../store/modules/auth/actions';
import Animation from '../../components/common/animation';
import { searchToObject } from '../../utils/locationSearchToObject';

export default function Auth(){
  const dispatch = useDispatch();
  const history = useHistory();
  const wlState = useSelector((state) => state?.whitelabel);

  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    const isWl = wlState?.config?.is_white_label;

    try{

      if(!isWl){
        throw new Error('unauthorized');
      }

      const { token, customer_id } = searchToObject();
      dispatch(requestLoginWl(token, customer_id));
      history.push('/chat');
    }
    catch(err){
      setLoading(false);
      setNotFound(true);
    }

  }, [wlState]);

  return (
    <>
      {loading &&
        <div className='auth-wrapper'>
          <Animation
            icon="loading"
            size={40}
            style={{
              border: '4px solid transparent',
              borderColor: '#ced4da',
              borderTopColor: '#6c757d',
            }}
          />
        </div>
      }

      {notFound && 
        <div className='auth-wrapper'>
          Ops! A página que voce está tentando acessar não existe!
        </div>
      }
    </>
  );
}