const facebookAppId = process.env.REACT_APP_META_APP_ID;

function removeJS(filename) {
  var tags = document.getElementsByTagName('script');
  for (var i = tags.length; i >= 0; i--) {
    if (
      tags[i] &&
      tags[i].getAttribute('src') != null &&
      tags[i].getAttribute('src').indexOf(filename) != -1
    )
      tags[i].parentNode.removeChild(tags[i]);
  }
}

export function initFacebookSDK() {
  // Removendo JS importado pois estava dando erro de nao rederizar o iframe
  removeJS('connect.facebook.net');
  delete window.fbAsyncInit;
  delete window.FB;

  return new Promise((resolve) => {
    // wait for facebook sdk to initialize before starting the react app
    window.fbAsyncInit = function () {
      FB.init({
        appId: facebookAppId,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v14.0',
      });
    };

    // Load the JavaScript SDK asynchronously
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/pt_BR/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');

    resolve();
  });
}
