import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Icon,
  UncontrolledTooltip,
} from '@polichat/flamboyant';
import { useContext, useState } from 'react';

import { isMobile } from 'react-device-detect';
import checkAvatar from '../../../../utils/checkAvatar';
import Avatar from '../../../common/profile/Avatar';
import ContactName from '../../../common/profile/ContactName';

import FinishChatModal from './modals/FinishChatModal';
import FinishedChatModal from './modals/FinishedChatModal';
import TransferChatModal from './modals/TransferChatModal';

import CartChatModal from './modals/CartChatModal';

import Modals from './modals/Modals';

import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Vox } from 'src/components/common/vox';
import { GlobalContext } from 'src/context/globalContext';
import * as ChatActions from '../../../../store/modules/chat/actions';
import poliConfig from '../../../../utils/poliConfig';
import CustomBox from '../../../common/structure/header/CustomBox';
import { BackToComponent } from '../../../mobile/back-to-actions/BackToComponent.component';

function Header({
  chat,
  chatDetails,
  showDetailsComponent,
  openFinishChatModal,
  openTransferChatModal,
  openTagChatModal,
  users,
  openPaymentChatModal,
  openCartChatModal,
  props,

  // mobile action navigation
  showBlockThree,
}) {
  // Context
  const { pictureGlobal } = useContext(GlobalContext);

  function handleDetails(showDetails) {
    if (showDetails === true) {
      showDetails = false;
    } else if (showDetails === undefined || showDetails === false) {
      showDetails = true;
    }
    showDetailsComponent(showDetails);
  }

  const customer_id = useSelector(
    (state) => state.general?.current_customer_id
  );
  const wl = useSelector((state) => state?.whitelabel?.config);

  const isWhiteLabel = useSelector(
    (state) => state?.whitelabel?.config?.is_white_label
  );
  const hideVoxButton = useSelector(
    (state) => state?.whitelabel?.config?.hide_vox_button || false
  );
  const showCustomBoxWL = useSelector((state) => {
    return (
      state?.whitelabel?.config?.script_injection &&
      state?.whitelabel?.config?.script_injection !== ''
    );
  });

  const contact = chat.contact;
  const chat_read = chat.chat_read === 1;
  const [moreActions, setMoreActions] = useState(false);
  const operator = chat?.contact?.operator;
  let operatorData;

  // Responsável por adicionar a imagem do operator atual do chat
  if (operator && operator !== null && users) {
    operatorData = users.find((user) => user.id === operator);

    if (operatorData) {
      if (operatorData.name) {
        operatorData.picture = checkAvatar(
          operatorData.name,
          operatorData.picture
        );
      }
    }
  }
  const isFinishChatModalOpen = chat.finish_chat_modal_open;
  const isTransferChatModalOpen = chat.transfer_chat_modal_open;

  const isCartChatModalOpen = chat.cart_chat_modal_open;

  const isChatOpen = chat.chat.status;

  const toggle = () => setMoreActions((prevState) => !prevState);
  // style={isMobile && { position: 'fixed', width: '92%' }}
  return (
    <div className="headerChat">
      {/* todos os modais */}
      <Modals />
      {isMobile && <BackToComponent />}

      <div
        className="contactInfo"
        onClick={() => {
          handleDetails(chatDetails);
          // Disparar action para mostrar bloco 3
          isMobile && showBlockThree();
        }}
      >
        <Avatar
          className="contact"
          title={chat.contact.name}
          url={
            !!pictureGlobal
              ? pictureGlobal
              : checkAvatar(chat.contact.name, chat.contact.picture)
          }
          size={36}
        />
        <div className="contactNameContainer">
          <ContactName name={contact.name} read={chat_read} />

          {/*   <Status status="Online" /> */}
        </div>
      </div>
      {showCustomBoxWL ? <CustomBox local="headChat" /> : <></>}
      <div className="headerChatActions">
        {/* Pesquisar */}
        {/*   <div className="searchInput">
          <InputSearch invisible placeholder="Pesquisar mensagem" />
        </div>*/}
        {!hideVoxButton && wl.spa_title !== 'NectarCRM' && (
          <Vox
            contact={chat.contact}
            user={users}
            channel={props}
            channels={props}
          />
        )}

        <Button
          id="tooltipOperator"
          color="link"
          onClick={() => {
            openTransferChatModal();
          }}
        >
          <Avatar
            className="operator"
            title={operatorData ? operatorData.name : '-'}
            url={operatorData ? operatorData.picture : '-'}
            size={36}
          />
        </Button>

        <UncontrolledTooltip placement="bottom" target="tooltipOperator">
          {operatorData && operatorData.name
            ? `Atendente: ${operatorData.name}`
            : 'Nenhum atendente atribuído'}
        </UncontrolledTooltip>
        {/* Mais ações */}
        <Dropdown isOpen={moreActions} toggle={toggle}>
          <DropdownToggle color="link">
            <Icon size="20" icon="poli-icon pi-bullet-fill" />
          </DropdownToggle>

          {isFinishChatModalOpen && (
            <>{isChatOpen ? <FinishChatModal /> : <FinishedChatModal />}</>
          )}

          {isTransferChatModalOpen && <TransferChatModal />}

          {isCartChatModalOpen && <CartChatModal />}

          {!isFinishChatModalOpen && (
            <DropdownMenu
              right
              className="menuDropdownShow header__dropdown-actions"
            >
              <DropdownItem
                className="itemDropdownMenu"
                onClick={() => {
                  openTransferChatModal();
                }}
              >
                <Icon icon={'poli-icon pi-talks-line'} size={20} />
                <p className="alternative">Encaminhar Chat</p>
                <Icon
                  icon={'poli-icon poli-icon pi-share-arrow-line'}
                  size={20}
                />
              </DropdownItem>

              {
                /* Sempre deve exibir a opção de etiqueta, mesmo se não houver etiquetas */
                <DropdownItem
                  className="openPaymentForm"
                  onClick={() => {
                    openTagChatModal();
                  }}
                >
                  <Icon icon={'poli-icon pi-tag-line'} size={20} />
                  <p className="alternative">Adicionar Etiqueta</p>
                  <Icon icon={'poli-icon pi-add-line'} size={20} />
                </DropdownItem>
              }

              {/* <DropdownItem
                className="itemDropdownMenu unavailable-item"
                onClick={() => setModalAddCalendar(!modalAddCalendar)}
              >
                <Icon
                  icon={'poli-icon pi-calendar-line'}

                  size={20}
                />
                <p className="alternative">Adicionar à agenda</p>
                <Icon
                  icon={'poli-icon pi-add-line'}

                  size={20}
                />
              </DropdownItem>
              <Modal
                isOpen={modalAddCalendar}
                toggle={() => setModalAddCalendar(!modalAddCalendar)}
                className={
                  localStorage.getItem('@polichat/darkmode') === 'true'
                    ? 'darkMode newFeature'
                    : 'newFeature'
                }
              >
                <ModalHeader
                  toggle={() => setModalAddCalendar(!modalAddCalendar)}
                >
                  <Icon
                    icon="poli-icon pi-calendar-line"
                    color="#4FCAD2"
                    size={50}
                  />
                  <span right="true">Funcionalidade em Desenvolvimento</span>
                </ModalHeader>
                <ModalBody>
                  A funcionalidade <strong>Adicionar à Agenda</strong> está em
                  fase final de desenvolvimento e logo estará disponível para
                  você.
                </ModalBody>
              </Modal> */}

              <DropdownItem
                className="itemDropdownMenu unavailable-item"
                onClick={() =>
                  (window.location.href = `${poliConfig.poliAppLaravelUrl}/actions/${customer_id}`)
                }
              >
                <Icon icon={'poli-icon pi-download-line'} size={20} />
                <p className="alternative">Exportar conversa</p>
                <Icon icon={'poli-icon pi-simple-arrow-right-line'} size={20} />
              </DropdownItem>
              {/* <Modal
                isOpen={modalExportChat}
                toggle={() => setModalExportChat(!modalExportChat)}
                className={
                  localStorage.getItem('@polichat/darkmode') === 'true'
                    ? 'darkMode newFeature'
                    : 'newFeature'
                }
              >
                <ModalHeader
                  toggle={() => setModalExportChat(!modalExportChat)}
                >
                  <Icon
                    icon="poli-icon pi-download-line"
                    color="#4FCAD2"
                    size={50}
                  />
                  <span right="true">Funcionalidade em Desenvolvimento</span>
                </ModalHeader>
                <ModalBody>
                  A funcionalidade <strong>Exportar conversa</strong> está em
                  fase final de desenvolvimento e logo estará disponível para
                  você.
                </ModalBody>
              </Modal> */}

              {/* Pagamento */}
              {!isWhiteLabel && (
                <DropdownItem
                  className="itemDropdownMenu openPaymentForm"
                  onClick={() => openPaymentChatModal()}
                >
                  <Icon icon={'poli-icon pi-pay-line'} size={20} />
                  <p className="alternative">Adicionar cobrança</p>
                  <Icon icon={'poli-icon pi-add-line'} size={20} />
                </DropdownItem>
              )}

              {!isWhiteLabel &&
                (chat.cart ? null : (
                  <DropdownItem
                    className="itemDropdownMenu openCartForm"
                    onClick={() => openCartChatModal(chat.id)}
                  >
                    <Icon icon={'poli-icon pi-ecommerce-line'} size={20} />
                    <p className="alternative">Adicionar carrinho</p>
                    <Icon icon={'poli-icon pi-add-line'} size={20} />
                  </DropdownItem>
                ))}

              <DropdownItem
                className="itemDropdownMenu closeChat"
                onClick={() => {
                  openFinishChatModal();
                }}
              >
                <Icon icon={'poli-icon pi-talks-line'} size={20} />
                <p className="alternative">Encerrar conversa</p>
                <Icon icon={'poli-icon pi-simple-arrow-right-line'} size={20} />
              </DropdownItem>
            </DropdownMenu>
          )}
        </Dropdown>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  chat: state.chat.chats?.find((chat) => chat.open === true),
  chatDetails: state.chat.showDetails,
  users: state.chat?.config?.general?.users,
  hasTags: state.chat?.config?.general?.tags?.length > 0,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ChatActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Header);
