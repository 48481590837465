function replaceUrlWebsite(url) {

  const urlLowerCase = url.toLowerCase();
  const insideDoesntHasHttp = urlLowerCase.substr(0, 4) != 'http';

  if(insideDoesntHasHttp) {
    url = 'http://' + url;
  }

  return url
}

export { replaceUrlWebsite };
