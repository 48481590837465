import {
  Accordion,
  Button,
  Collapse,
  CollapseMenu,
  Icon,
  Navbar,
  NavbarToggler,
} from '@polichat/flamboyant';
import { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import history from '../../services/history';
import './tools.css';

/**
 * Assets
 */
import imageCatalog from '../../assets/images/catalogo.svg';
import IntegrationImg from '../../components/chat/content/chat/modals/src/AppsIntegration';

// Common components
import Animation from '../../components/common/animation';
import {
  PageContentContainer,
  PageMainContainer,
  PageMenuContainer,
} from '../../components/common/pages/PageContainer/PageContainer.styles';
import PageMainBody from '../../components/common/pages/PageMainBody/PageMainBody.component';
import PageMainHeader from '../../components/common/pages/PageMainHeader/PageMainHeader.component';

// import DepartamentIconsModal from '../../components/tools/departments/DepartmentIconsModal';

import GetChannels from '../../components/tools/channels/GetChannels/GetChannels.component';

// Channels pages components
import AddChannel from '../../components/tools/channels/AddChannel/AddChannel.component';
import ChannelAddEditBroker from '../../components/tools/channels/AddChannel/ChannelAddEditBroker.component';
import ChannelAddEditInstagram from '../../components/tools/channels/AddChannel/ChannelAddEditInstagram.component';
import ChannelAddEditMessenger from '../../components/tools/channels/AddChannel/ChannelAddEditMessenger.component';
import ChannelAddEditWebchat from '../../components/tools/channels/AddChannel/ChannelAddEditWebchat.component';
import ChannelAddWaba from '../../components/tools/channels/Certification/ChannelAddWaba/ChannelAddWaba.component';
import ChannelAddWabaByPoli from '../../components/tools/channels/CertificationWabaByPoli/ChannelAddWaba/ChannelAddWaba.component';
import ChannelEdit from '../../components/tools/channels/ChannelEdit/ChannelEdit.component';
import ChannelIndex from '../../components/tools/channels/ChannelIndex/ChannelIndex.component';
// Migrate channel page
import { MigrateChannelToPoliPage } from '../../components/tools/migrateChannel';
// MessageBuilder pages components
import FormMessageEdit from '../../components/tools/messageBuilder/form/FormMessageEdit.component';
import FormMessagesBuilder from '../../components/tools/messageBuilder/form/FormMessagesBuilder.component';
import MessageBuilderImportTemplate from '../../components/tools/messageBuilder/form/MessageBuilderImportTemplate.component';
import ImportTemplates from '../../components/tools/messageBuilder/list/ImportTemplates.component';
import ListMessagesBuilder from '../../components/tools/messageBuilder/list/ListMessagesBuilder.component';
// Integration pages components
import IntegrationSelector from '../../components/tools/integrations/IntegrationSelector.component';
import IntegrationsList from '../../components/tools/integrations/IntegrationsList.component';
// Credits pages components
import CreditList from '../../components/admin/plans/Credit';

import poliConfig from '../../utils/poliConfig';

import { Illustration } from 'src/assets/illustratorElement/illustrators';
import { RestartChannelUsingRegisterPage } from '../../components/tools/channels/CertificationWabaByPoli/RestartChannelUsingRegister/page';
import './tools.css';

import { useFlags } from 'flagsmith/react';
import { useWhiteLabel } from 'src/hooks/useWhiteLabel';
import { ChannelDialog360Page } from '../../components/tools/channels/360Dialog/channel';
import { IntegratedOnboardPage } from '../../components/tools/channels/360Dialog/onboard';
import { LogsChannel } from '../../components/tools/channels/LogsChannel';

function Tools({ currentUser, loading, currentCustomerID }) {
  const flags = useFlags(['logs_channel']);

  const whitelabel = useWhiteLabel();
  let { path, url } = useRouteMatch();
  const pathname = window?.location?.pathname;
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [collapsed, setCollapsed] = useState(true);
  const toggleNavbar = () => setCollapsed(!collapsed);
  const superRole = currentUser?.superRole;
  const [errorLoadPermissions, setErrorLoadPermissions] = useState(false);


  const whiteLabelConfig = useSelector((state) => state?.whitelabel?.config);
  const [wlToolsSubitemsDisplay, setWlToolsSubitemsDisplay] = useState([
    'channels',
    'message_builder',
    'integrations',
    'credits',
  ]);

  const [wlChannelsSubitemsDisplay, setWlChannelsSubitemsDisplay] = useState([
    'add',
    'list',
    'migrate',
  ]);

  const [wlMessageBuilderSubitemsDisplay, setWlMessageBuilderSubitemsDisplay] =
    useState(['add', 'list', 'import']);

  useEffect(() => {
    if (!whiteLabelConfig?.is_white_label) {
      return;
    }

    const toolsSettings = whiteLabelConfig?.buttons?.find(
      (button) => button.id === 'tools'
    );

    if (toolsSettings) {
      const _wlMenuSubItems =
        toolsSettings?.subitems?.map((subitem) => subitem.id) || [];

      setWlToolsSubitemsDisplay(_wlMenuSubItems);

      const channelsSubitems =
        toolsSettings?.subitems
          ?.find((subitem) => subitem.id === 'channels')
          ?.subitems?.map((subitem) => subitem.id) || [];

      setWlChannelsSubitemsDisplay(channelsSubitems);

      const messageBuilderSubitems =
        toolsSettings?.subitems
          ?.find((subitem) => subitem.id === 'message_builder')
          ?.subitems?.map((subitem) => subitem.id) || [];

      setWlMessageBuilderSubitemsDisplay(messageBuilderSubitems);
    }
  }, [whiteLabelConfig]);

  const [menuActive, setMenuActive] = useState();

  useEffect(() => {
    let _menuAtive = pathname;

    // Plans
    if (pathname == '/tools') {
      _menuAtive = '/tools';
    }

    /**
     * O _menuAtive representa o "id" que esta setado no
     * CollapseMenu.
     */

    // Channels
    if (pathname.includes('channels')) {
      _menuAtive = '/tools/channels';
    }
    // templates
    if (
      pathname.includes('templates/import') ||
      pathname.includes('messages-builder')
    ) {
      _menuAtive = '/tools/templates/import';
    }
    // Integrations
    if (pathname.includes('integrations')) {
      _menuAtive = '/tools/integration';
    }
    // Credit
    if (pathname.includes('credit')) {
      _menuAtive = '/tools/credit';
    }
    // Logs
    if (pathname.includes('logs')) {
      _menuAtive = '/tools/logs';
    }
    // Mais
    if (pathname.includes('more')) {
      _menuAtive = '/tools/more';
    }

    setMenuActive('');
    setMenuActive(_menuAtive);
  }, [pathname, path, url]);

  const customers = currentUser?.customers;

  const customerAtual = customers?.find(
    (customer) => customer.id == currentCustomerID
  );

  const enableBSP =
    poliConfig?.bsp_enable ||
    poliConfig?.bsp_enable_custumer?.includes(Number(currentCustomerID));

  if (loading) {
    return <Animation size="full" />;
  }

  // status = 0 Cancelado, status = 3 Teste expirado, status = 5 Suspenso
  function isCustomerActive(status) {
    if (status == 0 || status == 3 || status == 5) {
      return false;
    }
    return true;
  }

  if (currentUser) {
    return (
      <>
        <PageMainContainer>
          {(superRole === 'adm' || superRole === 'manager') &&
          isCustomerActive(customerAtual?.status) ? (
            <>
              <PageMenuContainer>
                <Navbar color="faded" light expand="sm" className="new-sidebar">
                  <NavbarToggler
                    color="primary"
                    // onClick={isMobile && toggleNavbar}
                    className="mr-2"
                  >
                    <Icon size={30} icon="poli-icon pi-menu-mobile-fill" />
                  </NavbarToggler>

                  <Collapse isOpen={!collapsed} navbar>
                    <Accordion defaultActiveKey={menuActive} key={menuActive}>
                      {/* CHANNEL MENU LINKS */}
                      {wlToolsSubitemsDisplay.includes('channels') && (
                        <CollapseMenu
                          id={`${url}/channels`}
                          principalTitle="Canais"
                          principalIcon="poli-icon pi-channels-line"
                          doSomething={() => history.push({
                            pathname: `${url}/channels/list`,
                          })}
                        >
                          {' '}
                          {wlChannelsSubitemsDisplay?.includes('list') && (
                            <>
                              <Link //list channels
                                className="btn-primary-collapse-itens"
                                id="channels-option-2"
                                // onClick={isMobile && toggleNavbar}
                                to={{
                                  pathname: `${url}/channels/list`,
                                }}
                              >
                                <Button
                                  color="primary-collapse-itens"
                                  className={
                                    pathname == `${url}/channels/list`
                                      ? 'active'
                                      : ''
                                  }
                                >
                                  <Icon
                                    size={18}
                                    icon={'poli-icon pi-order-line'}
                                  />
                                  Seus Canais
                                </Button>
                              </Link>
                            </>
                          )}
                          {wlChannelsSubitemsDisplay?.includes('add') && (
                            <Link //add channel
                              className="btn-primary-collapse-itens"
                              id="channels-option-1"
                              // onClick={isMobile && toggleNavbar}
                              to={{
                                pathname: `${url}/channels/add`,
                              }}
                            >
                              <div
                                data-tour={'HelpHero_Migrate_WhatsApp_Step01'}
                              >
                                <Button
                                  color="primary-collapse-itens"
                                  className={
                                    pathname == `${url}/channels/add`
                                      ? 'active'
                                      : ''
                                  }
                                >
                                  <Icon
                                    size={18}
                                    icon={'poli-icon pi-add-line'}
                                  />
                                  Adicionar Canal
                                </Button>
                              </div>
                            </Link>
                          )}
                          
                          {/* Migrate channel to Poli menu link item*/}
                          {wlChannelsSubitemsDisplay?.includes('migrate') && (
                            <Link
                              className="btn-primary-collapse-itens"
                              id="channels-option-2"
                              // onClick={isMobile && toggleNavbar}
                              to={{
                                pathname: `${url}/channels/migrate`,
                              }}
                            >
                              <Button
                                color="primary-collapse-itens"
                                className={
                                  pathname == `${url}/channels/migrate`
                                    ? 'active'
                                    : ''
                                }
                              >
                                <Icon
                                  size={18}
                                  icon={'poli-icon pi-queue-line'}
                                />
                                Migrar Canal <br /> da 360
                              </Button>
                            </Link>
                          )}
                        </CollapseMenu>
                      )}
                      {/* TEMPLATE BUILD MENU LINKS */}
                      {wlToolsSubitemsDisplay?.includes('message_builder') && (
                        <CollapseMenu
                          id={`${url}/templates/import`}
                          principalTitle="Construtor de Template"
                          principalIcon="poli-icon pi-message-builder-line"
                          doSomething={() => history.push({
                            pathname: `${url}/messages-builder`,
                          })}
                        >
                          
                          {wlMessageBuilderSubitemsDisplay.includes('list') && (
                            <Link //templates list
                              id="templates-option-2"
                              className="btn-primary-collapse-itens"
                              // onClick={isMobile && toggleNavbar}
                              to={{
                                pathname: `${url}/messages-builder`,
                              }}
                            >
                              <Button
                                color="primary-collapse-itens"
                                className={
                                  pathname == `${url}/messages-builder`
                                    ? 'active'
                                    : ''
                                }
                              >
                                <Icon
                                  size={18}
                                  icon={'poli-icon pi-message-fill'}
                                />
                                {`${
                                  whitelabel.isWl
                                    ? 'Mensagens rápidas'
                                    : 'Mensagens e Templates'
                                }`}
                              </Button>
                            </Link>
                          )}
                          {wlMessageBuilderSubitemsDisplay.includes('add') && (
                            <Link //templates create
                              id="templates-option-2"
                              className="btn-primary-collapse-itens"
                              // onClick={isMobile && toggleNavbar}
                              to={{
                                pathname: `${url}/messages-builder/add`,
                              }}
                            >
                              <Button
                                color="primary-collapse-itens"
                                className={
                                  pathname == `${url}/messages-builder/add`
                                    ? 'active'
                                    : ''
                                }
                              >
                                <Icon
                                  size={18}
                                  icon={'poli-icon pi-message-builder-fill'}
                                />
                                {`${
                                  whitelabel.isWl
                                    ? 'Adicionar Mensagem rápida'
                                    : 'Adicionar Template'
                                }`}
                              </Button>
                            </Link>
                          )}
                          {wlMessageBuilderSubitemsDisplay.includes(
                            'import'
                          ) && (
                            <Link //templates import
                              id="templates-option-1"
                              className="btn-primary-collapse-itens"
                              // onClick={isMobile && toggleNavbar}
                              to={{
                                pathname: `${url}/templates/import`,
                              }}
                            >
                              <Button
                                color="primary-collapse-itens"
                                className={
                                  pathname == `${url}/templates/import`
                                    ? 'active'
                                    : ''
                                }
                              >
                                <Icon
                                  size={18}
                                  icon={'poli-icon pi-loading-fill'}
                                />
                                Importar Templates
                              </Button>
                            </Link>
                          )}
                        </CollapseMenu>
                      )}

                      {/* INTEGRATION MENU LINKS */}
                      {wlToolsSubitemsDisplay?.includes('integrations') && (
                        <CollapseMenu
                          id={`${url}/integration`}
                          principalTitle="Integrações e Apps"
                          principalIcon="poli-icon pi-integrations-line"
                          doSomething={() => history.push({
                            pathname: `${url}/integrations`,
                          })}
                        >
                          <Link //integrations
                            className="btn-primary-collapse-itens"
                            // onClick={isMobile && toggleNavbar}
                            to={{
                              pathname: `${url}/integrations`,
                            }}
                          >
                            <Button
                              color="primary-collapse-itens"
                              className={
                                pathname == `${url}/integrations`
                                  ? 'active'
                                  : ''
                              }
                            >
                              <Icon
                                size={18}
                                icon={'poli-icon pi-integrations-fill'}
                              />
                              Integrações
                            </Button>
                          </Link>
                        </CollapseMenu>
                      )}

                      {/* CREDITS MENU LINKS */}
                      {wlToolsSubitemsDisplay?.includes('credits') && (
                        <CollapseMenu
                          id={`${url}/credit`}
                          principalTitle="Créditos"
                          principalIcon="poli-icon pi-debit-line"
                          doSomething={() => history.push({
                            pathname: `${url}/credit`,
                          })}
                        >
                          <Link
                            className="btn-primary-collapse-itens"
                            to={{ pathname: `${url}/credit` }}
                            // onClick={isMobile && toggleNavbar}
                          >
                            <Button
                              color="primary-collapse-itens"
                              className={
                                pathname == `${url}/credit` ? 'active' : ''
                              }
                            >
                              <Icon
                                size={18}
                                icon={'poli-icon pi-debit-line'}
                              />
                              Lista de Créditos
                            </Button>
                          </Link>
                        </CollapseMenu>
                      )}
                      {flags.logs_channel?.enabled && (
                        //logs de canais
                        <CollapseMenu
                          id={`${url}/logs`}
                          principalTitle="Logs"
                          principalIcon="poli-icon pi-apps-fill"
                          doSomething={() => history.push({
                            pathname: `${url}/logs/channels`,
                          })}
                        >
                          <Link
                            className="btn-primary-collapse-itens"
                            to={{ pathname: `${url}/logs/channels` }}
                            // onClick={isMobile && toggleNavbar}
                          >
                            <Button
                              color="primary-collapse-itens"
                              className={
                                pathname == `${url}/logs/channels`
                                  ? 'active'
                                  : ''
                              }
                            >
                              <Icon
                                size={18}
                                icon={'poli-icon pi-channels-line'}
                              />
                              Canais
                            </Button>
                          </Link>
                        </CollapseMenu>
                      )}
                      <CollapseMenu
                        id={`${url}/more`}
                        principalTitle="Configurações Gerais"
                        principalIcon="poli-icon pi-setting-line"
                        doSomething={() =>
                          window.location.assign(
                            `${poliConfig.poliAppLaravelUrl}/settings/${currentCustomerID}`
                          )
                        }
                      >
                        <Link
                          className="btn-primary-collapse-itens"
                          // onClick={isMobile && toggleNavbar}
                          to={{
                            pathname: `${url}/more/settings-laravel`,
                          }}
                        >
                          <Button
                            color="primary-collapse-itens"
                            onClick={() =>
                              window.location.assign(
                                `${poliConfig.poliAppLaravelUrl}/settings/${currentCustomerID}`
                              )
                            }
                            className={
                              pathname == `${url}/more/settings-laravel`
                                ? 'active'
                                : ''
                            }
                          >
                            <Icon
                              size={18}
                              icon={'poli-icon pi-setting-line'}
                            />
                            Configurações
                          </Button>
                        </Link>
                      </CollapseMenu>
                    </Accordion>
                  </Collapse>
                </Navbar>
              </PageMenuContainer>

              <PageContentContainer>
                <Switch>
                  <Route exact path={path}>
                    <WelcomeTools />
                  </Route>
                  {/* CHANNEL ROUTES */}
                  <Route exact path={`${path}/channels`}>
                    <ChannelIndex />
                  </Route>
                  <Route exact path={`${path}/channels/list`}>
                    <GetChannels />
                  </Route>
                  <Route exact path={`${path}/channels/add`}>
                    <AddChannel />
                  </Route>
                  <Route exact path={`${path}/channels/add/waba`}>
                    <ChannelAddWaba />
                  </Route>
                  <Route exact path={`${path}/channels/add/waba-by-poli`}>
                    {enableBSP ? (
                      <ChannelAddWabaByPoli />
                    ) : (
                      <Redirect to="/tools/channels/list" />
                    )}
                  </Route>
                  <Route exact path={`${path}/channels/add/instagram`}>
                    <>
                      <PageMainHeader
                        title="Adicionar canal"
                        icon="poli-icon pi-channels-line"
                        iconSize="30"
                      />
                      <PageMainBody>
                        <ChannelAddEditInstagram />
                      </PageMainBody>
                    </>
                  </Route>
                  <Route exact path={`${path}/channels/add/webchat`}>
                    <>
                      <PageMainHeader
                        title="Adicionar canal"
                        icon="poli-icon pi-channels-line"
                        iconSize="30"
                      />
                      <PageMainBody>
                        <ChannelAddEditWebchat />
                      </PageMainBody>
                    </>
                  </Route>
                  <Route exact path={`${path}/channels/add/broker`}>
                    <>
                      <PageMainHeader
                        title="Adicionar canal"
                        icon="poli-icon pi-channels-line"
                        iconSize="30"
                      />
                      <PageMainBody>
                        <ChannelAddEditBroker />
                      </PageMainBody>
                    </>
                  </Route>
                  <Route exact path={`${path}/channels/add/messenger`}>
                    <>
                      <PageMainHeader
                        title="Adicionar canal"
                        icon="poli-icon pi-channels-line"
                        iconSize="30"
                      />
                      <PageMainBody>
                        <ChannelAddEditMessenger />
                      </PageMainBody>
                    </>
                  </Route>
                  <Route path={`${path}/channels/edit/:id`}>
                    <ChannelEdit />
                  </Route>
                  {flags.logs_channel?.enabled && (
                    <Route path={`${path}/logs/channels`}>
                      <LogsChannel />
                    </Route>
                  )}
                  <Route exact path={`${path}/channels/register/:channel_id`}>
                    <RestartChannelUsingRegisterPage />
                  </Route>
                  {/* migrate channel to Poli route */}
                  <Route exact path={`${path}/channels/migrate`}>
                    <MigrateChannelToPoliPage />
                  </Route>
                  <Route exact path={`${path}/channels/migrate/:channel_id`}>
                    <MigrateChannelToPoliPage />
                  </Route>

                  {/* TEMPLATE ROUTES */}
                  <Route exact path={`${path}/templates/import`}>
                    <ImportTemplates />
                  </Route>
                  <Route exact path={`${path}/messages-builder`}>
                    <ListMessagesBuilder />
                  </Route>
                  <Route exact path={`${path}/messages-builder/add`}>
                    <FormMessagesBuilder />
                  </Route>
                  <Route exact path={`${path}/messages-builder/import`}>
                    <MessageBuilderImportTemplate />
                  </Route>
                  <Route path={`${path}/messages-builder/edit/:id`}>
                    <FormMessageEdit />
                  </Route>
                  {/* INTEGRATION ROUTES */}
                  {/* integration NectarCRM */}
                  <Route exact path={`${path}/integration`}>
                    <PageMainBody>
                      <WelcomeTools title="integration" />
                    </PageMainBody>
                  </Route>
                  <Route exact path={`${path}/integrations`}>
                    <IntegrationsList />
                  </Route>
                  <Route exact path={`${path}/integrations/:integrator`}>
                    <IntegrationSelector />
                  </Route>
                  <Route
                    exact
                    path={`${path}/integrations/:integrator/:action`}
                  >
                    <IntegrationSelector />
                  </Route>

                  {/* CREDIT ROUTES */}
                  <Route exact path={`${path}/credit`}>
                    <CreditList />
                  </Route>

                  {/* Channel 360 Dialog */}
                  <Route path={`${path}/channel/360Dialog/onboard`}>
                    <IntegratedOnboardPage />
                  </Route>
                  <Route path={`${path}/channel/360Dialog/:id`}>
                    <ChannelDialog360Page />
                  </Route>
                </Switch>
              </PageContentContainer>
            </>
          ) : errorLoadPermissions ? (
            <div className="erroPay">
              <Icon
                icon={'poli-icon pi-sad-fill'}
                color="var(--danger)"
                size={86}
              />
              <h2>
                Ocorreu um erro ao tentar carregar alguns dados. Acesse a página
                novamente.
                <br />
                Caso o erro persista, contate o nosso suporte.
              </h2>
            </div>
          ) : (
            <div className="erroPay">
              <Icon
                icon={'poli-icon pi-sad-fill'}
                color="var(--danger)"
                size={86}
              />
              <h2>Você não possui permissão para acessar esta página.</h2>
            </div>
          )}
        </PageMainContainer>
      </>
    );
  }
}

function WelcomeTools(props) {
  let returnImage = <></>;
  switch (props.title) {
    case 'departments':
      returnImage = (
        <div className="shoppingScreenImage AnimationImage01">
          <img className="img-fluid imagePay" src={imageCatalog} />
          <h2 style={{ marginTop: '45px', lineHeight: '35px' }}>
            {' '}
            Ferramentas
            <br />
            de comunicação do mundo.
          </h2>{' '}
        </div>
      );
      break;
    case 'integration':
      returnImage = (
        <div className="shoppingScreenImage AnimationImage01">
          <br />
          <br />
          <IntegrationImg />
          <h2 style={{ marginTop: '45px', lineHeight: '35px' }}>
            {' '}
            Em breve mais integrações para você aqui na Poli.
          </h2>{' '}
        </div>
      );
      break;

    default:
      returnImage = (
        <div className="shoppingScreenImage AnimationImage07" style={{ paddingTop: "50px" }}>
          <Illustration imgName="polichat_pay_main" />
          <h2 style={{ marginTop: '45px', lineHeight: '35px' }}>
            Bem vindo ao Menu de Ferramentas
          </h2>
          {/* <DepartamentIconsModal /> */}
        </div>
      );
  }
  return returnImage;
}

const mapStateToProps = (state) => ({
  currentUser: state?.general?.user,
  loading: state?.general?.user ? false : true,
  currentCustomerID: state.general?.current_customer_id,
});
export default connect(mapStateToProps)(Tools);
