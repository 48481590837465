import axios from 'axios';
import poliConfig from 'src/utils/poliConfig';

export interface PostContactsBalanceParams {
  contact_id?: number,
  channel_customer_id?: number
}

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const defaultConfig = {
  headers: defaultHeaders,
  withCredentials: true,
};

let LARAVEL_URL = poliConfig.poliAppLaravelUrl ?? '';

const postContactsBalance = async (params: PostContactsBalanceParams, url?: string) => {
  if (url) {
    LARAVEL_URL = url
  }
  return axios.post(`${LARAVEL_URL}/credits/balance`, params, defaultConfig);
};



export { postContactsBalance };
