import { useCallback, useState } from 'react';
import { QuickMessage } from '../../types/types';

export function useModalState() {
  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);

  const [selectedQuickMessage, setSelectedQuickMessage] =
    useState<QuickMessage>();

  const handleOpenPreviewModal = useCallback(() => {
    setIsOpenPreview((prev) => !prev);
  }, []);

  const handleOpenEditModal = useCallback(() => {
    setIsOpenEdit((prev) => !prev);
  }, []);

  const handleOpenDeleteModal = useCallback(() => {
    setIsOpenDelete((prev) => !prev);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsOpenPreview(false);
    setIsOpenEdit(false);
    setIsOpenDelete(false);

    setSelectedQuickMessage(undefined);
  }, []);

  const handleSetSelectedQuickMessage = useCallback((message: QuickMessage) => {
    setSelectedQuickMessage(message);
  }, []);

  return {
    isOpenPreview,
    handleOpenPreviewModal,
    isOpenEdit,
    handleOpenEditModal,
    isOpenDelete,
    handleOpenDeleteModal,
    handleCloseModal,
    selectedQuickMessage,
    handleSetSelectedQuickMessage,
  };
}
