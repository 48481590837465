export const ChannelList = Object.freeze({
  whatsapp: 1,
  facebook: 2,
  email: 3,
  webchat: 4,
  instagram: 5,
  waba: 6,
});

// TODO: atualizar o adicionando chave com valor da api 34 - 360 cloudapi
export const ApiList = Object.freeze({
  cloudapi: 10,
  waba: 33,
  zapi: 4,
});
