import React from 'react';
import { Icon } from '@polichat/flamboyant';

import { IconPlusIcon } from 'src/assets/illustratorElement/iconPlusIcon/iconPlusIcon';
import {
  Container,
  LogoContainer,
  StepsContainer,
  InformationalTextContainer,
  StepsItemContainer,
  StepItem,
} from './ProgressBar.styles';

// Itens para alimentar o componente de etapas (stepItemsComponent)
const steps = [
  // { id: 0, name: 'Início' },
  { id: 1, name: 'Dados Canal' },
  { id: 2, name: 'Submissão WhatsApp' },
  { id: 3, name: 'Documentação' },
  { id: 4, name: 'Análise Facebook' },
  { id: 5, name: 'Migração' },
  { id: 6, name: 'Token' },
  { id: 7, name: 'Perfil' },
];
const stepsNew = [
  // { id: 0, name: 'Início' },
  { id: 1, name: 'Dados Canal' },
  { id: 2, name: 'Submissão WhatsApp' },
  { id: 3, name: 'Token' },
  { id: 4, name: 'Perfil' },
  { id: 5, name: 'Análise Facebook' },
];

// Lógica do componente que renderizará as etapas da homologação
const stepItemsComponent = ({ currentStep, classic }) =>
  (classic ? steps : stepsNew).map(({ id, name }) => (
    <StepItem key={id} passed={id < currentStep} className={id < currentStep}>
      {id < currentStep ? (
        <Icon icon="poli-icon pi-finish-fill" color={"var(--link-button-dark)"} size={24} />
      ) : (
        <Icon icon="poli-icon pi-finish-line" size={24} />
      )}
      <span>{name}</span>
    </StepItem>
  ));

const ProgressBar = ({ currentStep = 0, classic = false }) => (
  <Container>
    <LogoContainer>
      <IconPlusIcon
      firstIcon="onlyLogo"
      secondIcon="poli-icon pi-whatsapp-fill"
      secondColor="var(--success)"
    />
    </LogoContainer>

    <StepsContainer>
      <InformationalTextContainer>
        {currentStep === 0 && 'Homologação do seu canal WhatsApp Oficial'}
        {currentStep > 0 && 'Etapas para homologação do seu canal oficial'}
      </InformationalTextContainer>
      {currentStep > 0 && (
        <StepsItemContainer>
          {stepItemsComponent({ currentStep, classic })}
        </StepsItemContainer>
      )}
    </StepsContainer>
  </Container>
);

export default ProgressBar;
