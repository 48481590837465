import { Row, Col, FormGroup, Label } from '@polichat/flamboyant';

import { Container } from './CreateBodyButtonsMessage.styles';
import CustomTextAreaWithEditor from 'src/components/tools/common/CustomTextAreaWithEditor.component';
import AddVariable from '../../common/variable/AddVariable.component';
import { OptionsContainer } from '../../FormMessagesBuilder.styles';
import { useState } from 'react';

export interface CreateBodyButtonsMessageProps {
  onUpdate: (values: any) => void;
  initialValue: {
    text: string;
  };
  setBodyText(value: any): void;
}

export function CreateBodyButtonsMessage(props: CreateBodyButtonsMessageProps) {
  const bodyText = props.initialValue;

  const handleInputChange = (value: string) => {
    props.setBodyText({
      ...bodyText,
      text: value,
    });
    handleBodyText({
      ...bodyText,
      text: value,
    });
  };
  const handleAddVariable = (data: any) => {
    const { variable } = data;
    const newText = bodyText?.text + variable;

    props.setBodyText({
      ...bodyText,
      text: newText,
    });
    handleBodyText({
      ...bodyText,
      text: newText,
    });
  };
  const handleBodyText = (data: any) => {
    props.onUpdate(data);
  };
  return (
    <Row>
      <Col sm={12}>
        <FormGroup>
          <Label>Mensagem:</Label>
          <Container>
            <CustomTextAreaWithEditor
              disabled={false}
              id="body-message"
              maxLength={1024}
              onChange={handleInputChange}
              placeholder="Corpo da mensagem"
              required
              showCharCounter
              value={bodyText?.text}
            >
              <AddVariable onUpdate={handleAddVariable} isEdit={false} />
            </CustomTextAreaWithEditor>
            <OptionsContainer></OptionsContainer>
          </Container>
        </FormGroup>
      </Col>
    </Row>
  );
}
