import QuickMessageSelector from './QuickMessageSelector';

class QuickMessagesControll {
  setState(state) {
    this.state = state;
  }
  setMessage(message) {
    this.message = message;
  }

  selectTable(message, opennedChat){
    let regex = /\[protocol_number\]/ig;

    if(regex.test(message.text) && !opennedChat.protocol_number){
      return false
    }
    return true
  }

  searchFromKeyboardShortcut(keyboardShortcut, state) {
    if (!this.state) {
      if (!state) {
        return null;
      } else {
        this.setState(state);
      }
    }
    let chat = this.state;
    for (let key in chat.quickMessages.messages) {
      if (
        chat.quickMessages.messages[key].keyboard_shortcut &&
        chat.quickMessages.messages[key].keyboard_shortcut === keyboardShortcut
      ) {
        this.message = chat.quickMessages.messages[key];
        return true;
      }
    }
    return false;
  }
  onKeyDownEvent(event) {
    if (!this.state) return null;
    let chat = this.state;
    this.message = null;
    if (
      event.nativeEvent.keyCode !== 17 &&
      chat?.quickMessages?.messages?.length
    ) {
      if (
        event.nativeEvent.ctrlKey &&
        this.searchFromKeyboardShortcut('Ctrl+' + event.nativeEvent.key)
      ) {
        event.nativeEvent.stopPropagation();
        event.nativeEvent.preventDefault();
        return false;
      }
    }
  }
  search(term, list) {
    if (!list) {
      if (!this.state) {
        return [];
      }
      list = this.state.quickMessages.messages;
    }

    let messages = [];
    if (term.length > 0) {
      for (let i in list) {
        term = term.replace(/['|"]/g, '');
        /* term = term.replace(/[^0-9a-z:ç]/gi, ''); */
        let regex = new RegExp(`(${term})`, 'ig');
        if (list[i].tag && regex.test(list[i].tag)) {
          messages.push(list[i]);
        }
      }
    } else {
      messages = list;
    }
    return messages;
  }
  existMessage() {
    if (!this.state) return null;
    return this.message !== null;
  }
  getText(opennedChat) {
    if (!this.state || !this.message) return null;
    if (!opennedChat) {
      return this.message.text + '';
    }
    let chat = this.state;
    let department_name = '';
    let protocol_number = '';
    if (opennedChat.chat) {
      if(opennedChat.loading_protocol == true){
        protocol_number = '...';
      }
      else if(opennedChat.protocol_number) {
        protocol_number = opennedChat.protocol_number
      }
      else {
        protocol_number = 'Protocolo Inexistente';
      }
      if (opennedChat.chat.department_id && chat.config.general.departments) {
        for (let i in chat.config.general.departments) {
          if (
            chat.config.general.departments[i].id ===
            opennedChat.chat.department_id
          ) {
            department_name = chat.config.general.departments[i].name;
          }
        }
      } else {
        department_name = 'Contato sem Departamento';
      }
    }
    let date = new Date();

    let nDay = date.getDate();
    let nMonth = date.getMonth() + 1;
    let nHour = date.getHours();
    let nMinutes = date.getMinutes();

    let date_current =
      (nDay < 10 ? '0' : '') +
      nDay +
      '/' +
      (nMonth < 10 ? '0' : '') +
      nMonth +
      '/' +
      date.getFullYear();
    let time_current =
      (nHour < 10 ? '0' : '') +
      nHour +
      ':' +
      (nMinutes < 10 ? '0' : '') +
      nMinutes;
    return (this.message.text + '')
      .replaceAll('[contact_name]', opennedChat?.contact?.name || '[contact_name]')
      .replaceAll('[contact_first_name]',opennedChat?.contact?.name?.split(' ')[0] || '[contact_first_name]')
      .replaceAll('[contact_email]', opennedChat?.contact?.email || '[contact_email]')
      .replaceAll('[contact_phone]', opennedChat?.contact?.phone || '[contact_phone]')
      .replaceAll('[operator_name]', chat?.config?.user?.name || '')
      .replaceAll('[department_name]', department_name || '')
      .replaceAll('[date_current]', date_current)
      .replaceAll('[time_current]', time_current)
      .replaceAll('[protocol_number]', protocol_number);
  }
  updateText(textMessage, opennedChat, replaceAll) {
    if (!this.state || !this.message || !this.state.quickMessages) return null;
    let text = this.getText(opennedChat);
    if (replaceAll) {
      return text;
    }
    let nTextMessage = textMessage.replace(
      '#' + (this.state.quickMessages.term || ''),
      text
    );

    if (textMessage === nTextMessage) {
      textMessage += text;
    } else {
      textMessage = nTextMessage;
    }
    return textMessage;
  }
  getMessage() {
    return this.message;
  }
}

const QuickMessages = new QuickMessagesControll();

export { QuickMessages, QuickMessageSelector };
