import { QuickControlEdit } from './QuickControlEdit';
import { QuickControlPreview } from './QuickControlPreview';
import { QuickControlRemove } from './QuickControlRemove';
import { QuickControls } from './QuickControls';
import { QuickDescription } from './QuickDescription';
import { QuickDescriptionHeader } from './QuickDescriptionHeader';
import { QuickIcon } from './QuickDescriptionIcon';
import { QuickDescriptionText } from './QuickDescriptionText';
import { QuickRoot } from './QuickRoot';

export const QuickItem = {
  Root: QuickRoot,
  Description: QuickDescription,
  DescriptionHeader: QuickDescriptionHeader,
  DescriptionText: QuickDescriptionText,
  DescriptionIcon: QuickIcon,
  Controls: QuickControls,
  ControlPreview: QuickControlPreview,
  ControlEdit: QuickControlEdit,
  ControlRemove: QuickControlRemove,
};
