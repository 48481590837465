export const ActionTypes = {
  CREATE_CATEGORY: '@SHOP/CATEGORIES/CREATE_CATEGORY',
  CREATE_CATEGORY_SUCCESS: '@SHOP/CATEGORIES/CREATE_CATEGORY_SUCCESS',
  CREATE_CATEGORY_FAILURE: '@SHOP/CATEGORIES/CREATE_CATEGORY_FAILURE',

  UPDATE_CATEGORY: '@SHOP/CATEGORIES/UPDATE_CATEGORY',
  UPDATE_CATEGORY_SUCCESS: '@SHOP/CATEGORIES/UPDATE_CATEGORY_SUCCESS',
  UPDATE_CATEGORY_FAILURE: '@SHOP/CATEGORIES/UPDATE_CATEGORY_FAILURE',

  RESET_CATEGORY_STATUS: '@SHOP/CATEGORIES/RESET_CATEGORY_STATUS',
};

export const createCategory = (data) => ({
  type: ActionTypes.CREATE_CATEGORY, data
});

export const createCategorySuccess = (data) => ({
  type: ActionTypes.CREATE_CATEGORY_SUCCESS, data
});

export const createCategoryFailure = (error) => ({
  type: ActionTypes.CREATE_CATEGORY_FAILURE, error
});


export const updateCategory = (id, data) => ({
  type: ActionTypes.UPDATE_CATEGORY, id, data
});

export const updateCategorySuccess = (data) => ({
  type: ActionTypes.UPDATE_CATEGORY_SUCCESS, data
});

export const updateCategoryFailure = (error) => ({
  type: ActionTypes.UPDATE_CATEGORY_FAILURE, error
});

export const resetCategoryStatus = () => ({
  type: ActionTypes.RESET_CATEGORY_STATUS
});
