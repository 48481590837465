import { ChevronLeftLineLight2 } from '@poli-ink/react';
import { Button, Card, CardBody, Icon, Label } from '@polichat/flamboyant';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import Animation from 'src/components/common/animation';
import store from 'src/store';
import { fetchOpportunitiesAndTicketsRequest } from 'src/store/modules/chat/actions';
import {
  showBlockTwo,
  showDetailsComponent,
} from '../../../../../store/modules/chat/actions';
import { Opportunities } from './OpportunitiesDetails';

export function OpportunitiesBox(props) {
  const contact = props.contact;
  const [cards, setCards] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const handleTryAgain = () => {
    setCards(null);
    setLoading(true);
    setError(false);
    store.dispatch(fetchOpportunitiesAndTicketsRequest(contact, true));
  };

  useEffect(() => {
    setCards(props.cards);
    if (props.cards !== null) {
      setLoading(false);
    }
    if (props.cards === undefined) {
      setError(true);
    }
    return ()=>{
      setLoading(true);
    }
  }, [props.cards]);

  useEffect(() => {
    if (props.cards === null) {
      setLoading(true);
      store.dispatch(fetchOpportunitiesAndTicketsRequest(contact, true));
    }
    return ()=>{
      setLoading(true);
    }
  }, [props.contact.id, props.isOpen]);

  return (
      <div className="moreInfosOpportunities">
        <div className="title" onClick={props.toggleOpportunities}>
          <ChevronLeftLineLight2 />
          <span>
            <strong>Oportunidades</strong>
          </span>
          <div className="closeDetails">
            <Button
              color="link"
              onClick={() => {
                isMobile && dispatch(showBlockTwo());
                dispatch(showDetailsComponent(false));
              }}
            >
              <Icon icon="poli-icon pi-close-fill" />
            </Button>
          </div>
        </div>
        <Card className="infoItemsContainer">
          <CardBody className="infoItems">
            {loading ? (
              <Animation icon="loading" top={'50%'} />
            ) : error ? (
              <>
                <Label>Erro ao buscar</Label>
                <Button onClick={handleTryAgain}>Tentar novamente</Button>{' '}
              </>
            ) : (
                <Opportunities isOpen={props.isOpen} toggleOpportunities={props.toggleOpportunities} openEmbedModal={props.openEmbedModal} opportunities={cards?.opportunities?.data} />
            )}
          </CardBody>
        </Card>
      </div>
  );
}
