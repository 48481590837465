import React from 'react';
import { Badge, Icon }from '@polichat/flamboyant';

function FilterBadge(props) {
  return (
    <Badge className="filterBadgeItem" onClick={props.onRemove}>
      <span>{props.title}</span>

      <Icon
        icon={'poli-icon pi-close-line'}
        color={'var(--activated-icons-color)'}
        size={15}
      />
    </Badge>
  );
}

export default FilterBadge;
