import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as GeneralActions from '../../store/modules/general/actions';
import { useSelector } from 'react-redux';

import { generateToken } from '../../services/trigger/request';

import Animation from '../../components/common/animation';
import { useEffect, useState } from 'react';

function Marketing({ loading }) {
  const triggerUrl =
    process.env.REACT_APP_DISPARADOR_URL ?? 'https://disparador.digital';

  const [urlIframe, setUrlIframe] = useState('');
  const currentCustomer = useSelector(
    (state) => state?.chat?.config?.user?.current_customer_id
  );
  useEffect(() => {
    if (!currentCustomer) return;
    (async () => {
      try {
        const token = await generateToken();

        setUrlIframe(`${triggerUrl}/${currentCustomer}/${token}`);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [currentCustomer]);

  if (loading || !urlIframe) {
    return <Animation size="full" />;
  }

  return (
    <iframe src={urlIframe} width="100%" height="100%" title="Disparador" />
  );
}

const mapStateToProps = (state) => ({
  currentUser: state?.general?.user,
  loading: state?.general?.user ? false : true,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(GeneralActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Marketing);
