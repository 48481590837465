export const actionNames = {
  STORE_SPA_API: '@general/STORE_SPA_API',
  STORE_SPA_API_AUTH_TOKEN: '@general/STORE_SPA_API_AUTH_TOKEN',
  STORE_USER_DATA_ON_GENERAL: '@general/STORE_USER_DATA_ON_GENERAL',
  UPDATE_USER_DATA_ON_GENERAL: '@general/UPDATE_USER_DATA_ON_GENERAL',
  SET_CURRENT_CUSTOMER_ID: "@general/SET_CURRENT_CUSTOMER_ID",
  CHANGE_USER_STATUS: '@general/CHANGE_USER_STATUS',
};

export function storeSpaApi(obj) {
  return {
    type: actionNames.STORE_SPA_API,
    payload: obj,
  };
}

export function setCurrentCustomerId(id) {
  return {
    type: actionNames.SET_CURRENT_CUSTOMER_ID,
    payload: id,
  };
}

/**
 * Registrar o token do usuário
 */
export function storeSpaApiAuthToken(token) {
  return {
    type: actionNames.STORE_SPA_API_AUTH_TOKEN,
    payload: token,
  };
}

export function storeUserDataOnGeneral(payload) {
  return {
    type: actionNames.STORE_USER_DATA_ON_GENERAL,
    payload,
  };
}

export function updateUserDataOnGeneral(payload) {
  return {
    type: actionNames.UPDATE_USER_DATA_ON_GENERAL,
    payload,
  };
}

export function ChangeUserStatus(payload){
  return{
    type: actionNames.CHANGE_USER_STATUS,
    payload,
  }
}