import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from './validation';

export function useLocalRequestCodeForm() {
  const form = useForm({
    defaultValues: {
      code_method: 'VOICE',
      language: 'pt_BR',
    },
    resolver: yupResolver(validationSchema),
  });
  // ---------------------------------------------
  // Functions
  // const { watch } = form;

  // ---------------------------------------------
  // Effects
  // ---------------------------------------------
  // Transformations
  // ---------------------------------------------
  // API

  return {
    form,
  };
}
