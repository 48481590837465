import { PreviewBody } from './PreviewBody';
import { PreviewButtons } from './PreviewButtons';
import { PreviewFooter } from './PreviewFooter';
import { PreviewHeader } from './PreviewHeader';
import { PreviewRoot } from './PreviewRoot';

/**
 * React Composition Pattern
 * @ref https://vinniciusgomes.medium.com/implementando-composition-pattern-em-aplicacoes-react-4e8dc92742ff
 * @ref https://youtu.be/oPOKpSFqy-I?si=J3YZAXW_x8yHzINE
 */
export const Preview = {
  Root: PreviewRoot,
  Header: PreviewHeader,
  Body: PreviewBody,
  Footer: PreviewFooter,
  Buttons: PreviewButtons,
};
