import {
  Accordion,
  Button,
  Collapse,
  CollapseMenu,
  Icon,
  Navbar,
  NavbarToggler,
} from '@polichat/flamboyant';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import poliConfig from 'src/utils/poliConfig';
import { PageMenuContainer } from '../../../components/common/pages/PageContainer/PageContainer.styles';
import history from '../../../services/history';
import { useFlags } from 'flagsmith/react';
import { customUseFlags } from 'src/hooks/customUseFlags';

export function FiltersOptionsReport() {
  const flag = useFlags(['metabase_reports']);
  const metabaseReports = customUseFlags(flag.metabase_reports);
  let { path, url } = useRouteMatch();
  const [collapsed, setCollapsed] = useState(true);
  const toggleNavbar = () => setCollapsed(!collapsed);
  const [menuActive, setMenuActive] = useState<string>();
  const pathname = window?.location?.pathname;
  const [error, setError] = useState(false);
  const current_customer_id = useSelector(
    (state: any) => state?.general?.current_customer_id
  );

  const superRole = useSelector(
    (state: any) => state?.general?.user?.superRole
  );

  useEffect(() => {
    const _menuAtive = path;
    setMenuActive('');
    setMenuActive(_menuAtive);
    setError(false);
  }, [pathname, path, url]);

  return (
    <>
      <PageMenuContainer>
        <Navbar color="faded" light expand="sm" className="new-sidebar">
          <NavbarToggler
            color="primary"
            onClick={toggleNavbar}
            className="mr-2"
          >
            <Icon size={30} icon="poli-icon pi-menu-mobile-fill" />
          </NavbarToggler>
          <Collapse isOpen={!collapsed} navbar>
            <Accordion defaultActiveKey={menuActive} key={menuActive}>
              {/* Visão Geral */}
              {superRole != 'agent' && (
                <Link
                  className="reports-laravel-button"
                  to={{ pathname: '/v2-report/reports-laravel' }}
                  onClick={() =>
                    window.location.assign(
                      `${poliConfig.poliAppLaravelUrl}/report/${current_customer_id}`
                    )
                  }
                >
                  <Button color="primary-collapse-itens">Visão Geral</Button>
                </Link>
              )}
              {/* Análise do Operador*/}
              <CollapseMenu
                id="/operatorsPerformance"
                principalTitle="Análise do Operador"
                principalIcon="poli-icon pi-operator-line"
                /**
                 ** Usamos doSomething ao invés de onClick porque é uma gambiarra do Flamboyant
                 * @see https://gitlab.int.poli.digital/polichat/spa/flamboyant/-/blob/master/src/lib/components/CollapseMenu/CollapseMenu.js?ref_type=heads#L11
                 */
                doSomething={() =>
                  history.push('/v2-report/operators-performance')
                }
              >
                <Link
                  className="btn-primary-collapse-itens"
                  to={{ pathname: '/v2-report/operators-performance' }}
                  // onClick={isMobile && toggleNavbar}
                >
                  <Button
                    color="primary-collapse-itens"
                    className={
                      pathname == '/v2-report/operators-performance'
                        ? 'active'
                        : ''
                    }
                  >
                    Desempenho dos Operadores
                  </Button>
                </Link>

                <Link
                  className="btn-primary-collapse-itens"
                  to={{ pathname: '/v2-report/operators-efficiency' }}
                  // onClick={isMobile && toggleNavbar}
                >
                  <Button
                    color="primary-collapse-itens"
                    className={
                      pathname == '/v2-report/operators-efficiency'
                        ? 'active'
                        : ''
                    }
                  >
                    Produtividade dos Operadores
                  </Button>
                </Link>
              </CollapseMenu>

              {/* EXPORTAR RELATORIO*/}

              <CollapseMenu
                id="/exportReport"
                principalTitle="Exportações de relatórios"
                principalIcon="poli-icon pi-download-line"
                doSomething={() => history.push('/v2-report/export-report')}
              >
                <Link
                  className="btn-primary-collapse-itens"
                  to={{ pathname: '/v2-report/export-report' }}
                  // onClick={isMobile && toggleNavbar}
                >
                  <Button
                    color="primary-collapse-itens"
                    className={
                      pathname == '/v2-report/export-report' ? 'active' : ''
                    }
                  >
                    Exportações de relatórios
                  </Button>
                </Link>
              </CollapseMenu>
              {/* Resumo da empresa */}

              {metabaseReports && (
                <Link
                  className="reports-laravel-button"
                  to={{ pathname: '/v2-report/relatorio' }}
                >
                  <Button color="primary-collapse-itens">
                    Resumo da empresa
                  </Button>
                </Link>
              )}
            </Accordion>
          </Collapse>
        </Navbar>
      </PageMenuContainer>
    </>
  );
}
