import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import * as GeneralActions from '../../../store/modules/general/actions';
import { connect } from 'react-redux';
import PageMainHeader from '../../common/pages/PageMainHeader/PageMainHeader.component';
import PageMainBody from '../../common/pages/PageMainBody/PageMainBody.component';
import { useParams } from 'react-router-dom';
import { Button, Card, CardBody, Icon, Input, Modal, ModalBody, ModalFooter, ModalHeader, Table } from '@polichat/flamboyant';
import { getPlans } from '../../../utils/admin/plans/plans';
import { getServices } from '../../../utils/admin/services/services';
import history from '../../../services/history';
import { postContractInLaravel } from '../../../services/admin/contract/contracts';
import Animation from '../../common/animation';

function ContractForm() {

  let { id } = useParams();
  const [isOpenModalAddItem, setIsOpenModalAddItem] = useState(false);
  const toggleModalAddItem = () => setIsOpenModalAddItem(!isOpenModalAddItem);

  const [plans, setPlans] = useState([]);
  const [plan, setPlan] = useState(null);
  const [services, setServices] = useState([]);
  const [periodContractList, setPeriodContractList] = useState(null);
  const [additionalList, setAdditionalList] = useState([]);

  const [valueSelectPlans, setValueSelectPlans] = useState(null);
  const [valueSelectPeriodsContract, setValueSelectPeriodsContract] = useState(null);
  const [valueInputDiscount, setValueInputDiscount] = useState(0);
  const [valueInputDescription, setValueInputDescription] = useState('');
  const [valueSelectServices, setValueSelectServices] = useState(null);
  const [valueInputQuantity, setValueInputQuantity] = useState(1);

  const [indexEditAdditional, setIndexEditAdditional] = useState(null); // Posição do item que será editado na lista

  const [fullValue, setFullValue] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [finalValue, setFinalValue] = useState(0);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {

    async function synchronizePlansAndServices() {

      const tempPlans = await getPlans();
      const existPlans = (tempPlans && tempPlans instanceof Array && tempPlans.length > 0);
      if (existPlans) {
        setPlans(tempPlans);
        setValueSelectPlans(tempPlans[0].id);
      }

      const tempServices = await getServices();
      const existServices = (tempServices && tempServices instanceof Array && tempServices.length > 0);
      if (existServices) {
        setServices(tempServices);
        setValueSelectServices(tempServices[0].id);
      }

      if (existPlans && existServices) {
        setIsLoading(false);
      }

    }

    function definePeriods() {
      const periodList = ['Anual', 'Semestral', 'Trimestral', 'Mensal'];
      setPeriodContractList(periodList);
      setValueSelectPeriodsContract(periodList[0]);
    }

    synchronizePlansAndServices();
    definePeriods();

    return () => {
    };
  }, []);

  useEffect(() => {

    if (isOpenModalAddItem === false) { // Faz o reset de alguns states quando o modal for fechado.
      if (services && services instanceof Array && services.length > 0) {
        setValueSelectServices(services[0].id);
      }
      setValueInputQuantity(1);
      setIndexEditAdditional(null);
    }
  }, [isOpenModalAddItem]);

  useEffect(() => { // Monitorar os input e select que alteram os valores a pagar

    let planValue = 0;
    if (plans && plans instanceof Array && plans.length > 0) {
      const plan = plans.find(plan => Number(plan.id) === Number(valueSelectPlans));
      planValue = (plan) ? Number(plan.value) : 0;
    }

    const discountValue = Number(valueInputDiscount);
    setDiscount(discountValue);

    let additionalValue = 0;
    if (additionalList && additionalList instanceof Array && additionalList.length > 0) {
      const arrayCalcValueAndQuantityFromAdditionalList = additionalList?.map(additional => Number(additional.service.value) * Number(additional.quantity));
      const sum = sumArray(arrayCalcValueAndQuantityFromAdditionalList);
      additionalValue = (sum && sum > 0) ? sum : 0;
    }

    const sumPlanValueAndAdditionalList = planValue + additionalValue;
    setFullValue(sumPlanValueAndAdditionalList);

    const total = sumPlanValueAndAdditionalList - discountValue;
    setFinalValue(total);

  }, [additionalList, valueSelectPlans, valueInputDiscount]);

  useEffect(() => {
    if (valueSelectPlans) {
      const plan = plans.find(plan => Number(plan.id) === Number(valueSelectPlans));
      setPlan(plan);
    }
  }, [valueSelectPlans]);

  return (
    <>
      <PageMainHeader title='Criar assinatura' icon='poli-icon pi-hand-shake-line' />
      <PageMainBody>

        {isLoading ?
          <Animation
            icon='chat-loading'
            style={{
              maxWidth: '150px',
              height: '100%',
              maxHeight: '100px',
            }} /> :

          <div className='row'>

            <div className='col-sm-12 mb-4'>

              <Card>
                <CardBody>

                  <div className='row'>

                    <div className='col-sm-4 mb-4'>
                      <label htmlFor='selectPlan'><strong>Plano</strong></label>
                      <select value={valueSelectPlans} id='selectPlan' name='customSelect' className='custom-select' onChange={(event) => {
                        setValueSelectPlans(event.target.value);
                      }}>
                        {plans?.map((plan, index) => {
                          return <option key={index} value={plan.id}>{plan.name}</option>;
                        })}
                      </select>
                    </div>

                    <div className='col-sm-3'>
                      <label htmlFor='selectPeriod'><strong>Período de assinatura</strong></label>
                      <select value={valueSelectPeriodsContract} id='selectPeriod' name='customSelect' className='custom-select' onChange={(event) => {
                        setValueSelectPeriodsContract(event.target.value);
                      }}>
                        {periodContractList?.map((period, index) => {
                          return <option key={index} value={period}>{period}</option>;
                        })}
                      </select>
                    </div>

                  </div>

                </CardBody>
              </Card>

            </div>

            <div className='col-sm-12 mb-4'>
              <Card>
                <CardBody>

                  <h5 className='card-title mb-4'>Composição do plano</h5>

                  {plan ?
                    <Table responsive bordered striped hover className='table-sm'>
                      <thead>
                      <tr>
                        <th className='font-weight-bold'>Produto</th>
                        <th className='font-weight-bold'>Valor</th>
                      </tr>
                      </thead>
                      <tbody>
                      {plan.itemsPlan?.map((item, index) => {
                        return <tr key={index}>
                          <td>{item.service?.name}</td>
                          <td>{Number(item.service?.value)?.toLocaleString()}</td>
                        </tr>;
                      })}
                      </tbody>
                    </Table> : null}
                </CardBody>
              </Card>
            </div>

            <div className='col-sm-12 mb-4'>
              <Card>
                <CardBody>
                  <div className='mb-2'>
                    <strong>Adicionais</strong>
                    <Button id='buttonAddItems' gradient='success' className='btn-gradient-success btn btn-secondary btn-sm ml-2' onClick={() => {
                      toggleModalAddItem();
                    }}>
                      <Icon icon='poli-icon pi-add-fill' />
                    </Button>
                  </div>
                  {(additionalList?.length) > 0 ?
                    <Table responsive bordered striped hover className='table-sm mt-2'>
                      <thead>
                      <tr>
                        <th className='text-center font-weight-bold'>Serviço</th>
                        <th className='text-center font-weight-bold'>Descrição</th>
                        <th className='text-center font-weight-bold'>Valor</th>
                        <th className='text-center font-weight-bold'>Quantidade</th>
                        <th className='text-center font-weight-bold'>Total</th>
                        <th className='text-center font-weight-bold' />
                      </tr>
                      </thead>
                      <tbody>
                      {additionalList?.map((additional, index) => {
                        return <tr key={index}>
                          <td className='text-center'>{additional.service?.name}</td>
                          <td className='text-center'>{additional.service?.description}</td>
                          <td className='text-center'>{(additional.service?.value).toLocaleString()}</td>
                          <td className='text-center'>{additional.quantity}</td>
                          <td className='text-center'>{(additional.service?.value * additional.quantity).toLocaleString()}</td>
                          <td className='text-center'>
                            {/** Editar item do plano (listagem de itens) */}
                            <Button gradient='info' className='btn-gradient-info btn btn-secondary btn-sm' style={{ marginRight: '5px' }} onClick={() => {
                              setIndexEditAdditional(index);
                              setValueSelectServices(additional.service?.id);
                              setValueInputQuantity(additional.quantity);
                              toggleModalAddItem();
                            }}>
                              <Icon icon='poli-icon pi-pencil-line' />
                            </Button>
                            {/** Remover item do plano (listagem de itens) */}
                            <Button gradient='danger' className='btn-gradient-danger btn btn-secondary btn-sm' style={{ marginLeft: '5px' }} onClick={() => {
                              setAdditionalList(additionalList?.filter((adtItem, subIndex) => subIndex !== index));
                            }}>
                              <Icon icon='poli-icon pi-delete-line' />
                            </Button>
                          </td>
                        </tr>;
                      })}
                      </tbody>
                    </Table> : null}
                </CardBody>
              </Card>
            </div>

            <div className='col-sm-12 mb-4'>
              <Card>
                <CardBody>
                  <div className='mb-4'>
                    <label htmlFor='inputDiscount'><strong>Desconto</strong></label>
                    <Input id='inputDiscount' type='number' className='custom-text form-control col-sm-2 ' value={valueInputDiscount} onChange={(event) => {
                      setValueInputDiscount((Number(event.target.value) >= 0) ? event.target.value : 0);
                    }} />
                  </div>
                  <div className='mb-4'>
                    <label htmlFor='inputDescription'><strong>Descrição</strong></label>
                    <Input id='inputDescription' type='textarea' className='col-sm-8' value={valueInputDescription} onChange={(event) => {
                      setValueInputDescription(event.target.value);
                    }} />
                  </div>
                </CardBody>
              </Card>
            </div>

            <div className='col-sm-12'>
              <Card>
                <CardBody>
                  <h6><strong>Valor cheio:</strong> {(fullValue)?.toLocaleString()}</h6>
                  <h6><strong>Desconto:</strong> {(discount)?.toLocaleString()}</h6>
                  <h5><strong>Valor Final:</strong> {(finalValue)?.toLocaleString()}</h5>

                  <Button gradient='success' className='btn-gradient-success btn btn-secondary mt-2' style={{ float: 'right' }} onClick={() => {

                    setIsLoading(true);

                    const data = {
                      clientId: Number(id),
                      planId: Number(valueSelectPlans),
                      contractPeriod: valueSelectPeriodsContract,
                      additionalList: additionalList,
                      description: valueInputDescription,
                      fullValue: Number(fullValue),
                      discount: Number(discount),
                      finalValue: Number(finalValue),
                    };

                    if (id) { // POST = new contract
                      async function postContract() {
                        try {
                          const response = await postContractInLaravel(data);
                          if (response && (response.status === 200 || response.status === 201)) {
                            alert('Assinatura criada com sucesso!');
                            history.push({ pathname: `/` });
                          } else {
                            alert('Ocorreu um erro ao cadastrar este contrato');
                            setIsLoading(false);
                          }
                        } catch (error) {
                          console.error(error.message, 'message:', error.response?.data?.message);
                          console.error(error.response);
                          console.error('erro', error);
                          alert('Ocorreu um erro ao cadastrar este contrato. Verifique todos os campos. Caso o problema persista, contate o nosso suporte.');
                          setIsLoading(false);
                        }
                      }

                      postContract();
                    }

                  }}>
                    Fechar venda
                  </Button>
                </CardBody>
              </Card>
            </div>

          </div>}

      </PageMainBody>

      <Modal isOpen={isOpenModalAddItem} toggle={toggleModalAddItem} size='lg' className='modal-dialog-scrollable'>
        <ModalHeader toggle={toggleModalAddItem}>
          <Icon icon='poli-icon pi-add-line' />
          <span>Adicionar item na assinatura</span>
        </ModalHeader>
        <ModalBody>
          <div>
            <span>Serviço</span>
            <select value={valueSelectServices} className='form-control' name='modal_service' id='modal_service' onChange={(event) => {
              setValueSelectServices(event.target.value);
            }}>
              {services?.map((service, index) => {
                return <option key={index} value={service.id}>{service.name}</option>;
              })}
            </select>
            <br />
            <span>Quantidade</span>
            <Input type='number' className='custom-text form-control' value={valueInputQuantity} onChange={(event) => {
              setValueInputQuantity((Number(event.target.value) >= 1) ? event.target.value : 1);
            }} />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button outline onClick={toggleModalAddItem}>
            Cancelar
          </Button>
          <Button color='success' onClick={() => {

            const service = services?.find(service => Number(service.id) === Number(valueSelectServices));
            if (service) {
              if (indexEditAdditional !== null) { // Então o Item esta sendo editado
                let tempAdditionalList = [...additionalList];
                tempAdditionalList[indexEditAdditional] = { service: service, quantity: Number(valueInputQuantity) };
                setAdditionalList(tempAdditionalList);
              } else { // Deve salvar um novo Item
                setAdditionalList([...additionalList, { service: service, quantity: Number(valueInputQuantity) }]);
              }
            }
            toggleModalAddItem();
          }}>
            <span>Adicionar</span>
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

const sumArray = (array) => {
  return array?.reduce((acumulador, valorAtual) => acumulador + valorAtual, 0);
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(GeneralActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ContractForm);
