export function userRoleValidator(userRole: string): boolean {
    const validatorInstance = {
        isAdmin: function (): boolean {
            return userRole === 'adm';
        },
        isManager: function (): boolean {
            return userRole === 'manager';
        },
        isSupervisor: function (): boolean {
            return userRole === 'supervisor';
        }
    }

    return validatorInstance.isAdmin() || validatorInstance.isManager() || validatorInstance.isSupervisor();
}