import React from 'react';
import './styles.css';

import { connect } from 'react-redux';

function ContactImport({ chatConfig }) {
  let show;
  if (chatConfig) {
    if (chatConfig.openImportContact) {
      show = true;
    } else {
      show = false;
    }
  }

  if (show) {
    return <h1>Importar Contatos</h1>
  } else {
    return <></>
  }
}

const mapStateToProps = (state) => ({
  chatConfig: state.chat?.config,
})

export default connect(mapStateToProps)(ContactImport)
