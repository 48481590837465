import {
  DtoDialog360Channel,
  DtoDialog360GenerateApiKeyInfo,
} from '../service/DtoDialog360Channel';
import { LocalFormValues as Step1LocalFormValues } from './step1/useForm';

const TYPE_WHATSAPP_WABA_360_CHANNEL_ID = 6;
export function getDtoDialog360ChannelFromLocalFormValues(
  data: Step1LocalFormValues,
  customer_id: number
): Pick<DtoDialog360Channel, 'customer_id' | 'channel_id' | 'name' | 'phone'> {
  return {
    customer_id,
    channel_id: TYPE_WHATSAPP_WABA_360_CHANNEL_ID,
    name: data.name,
    phone: data.phone,
  };
}

const getUrlParameter = (params: string, name: string) => {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  var results = regex.exec(params);
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

const getChannelArray = (channels: string) => {
  return channels.substring(1, channels.length - 1).split(',');
};

type Info360FromQueryString =
  | (Pick<
      DtoDialog360GenerateApiKeyInfo,
      'channel_id_360' | 'client_id_360'
    > & { success: boolean })
  | undefined;
export function getChannel360InfoFromQueryString(
  Dialog360Data: string
): Info360FromQueryString {
  //exemplo de erro: ?[object%20Object]
  if (Dialog360Data.indexOf('=') === -1) return undefined;

  const client_id_360 = getUrlParameter(Dialog360Data, 'client');
  const channel_id_360 = getChannelArray(
    getUrlParameter(Dialog360Data, 'channels')
  )[0];
  // TODO: https://poli-digital.atlassian.net/browse/PL-1112 - tratar a possibilidade de receber um array de channels com o back

  return {
    client_id_360,
    channel_id_360,
    success: channel_id_360 !== '' && client_id_360 ? true : false,
  };
}

export function getApiKeyRequestDto(
  Data360: Pick<
    DtoDialog360GenerateApiKeyInfo,
    'channel_id_360' | 'client_id_360'
  >,
  DataChannel: DtoDialog360Channel
): DtoDialog360GenerateApiKeyInfo {
  return {
    channel_id: DataChannel.id,
    customer_id: DataChannel.customer_id,
    channel_id_360: Data360.channel_id_360,
    client_id_360: Data360.client_id_360,
  };
}
