import axios from 'axios';
import poliConfig from 'src/utils/poliConfig';

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const defaultConfig = {
  headers: defaultHeaders,
  withCredentials: true,
};

const LARAVEL_URL = poliConfig.poliAppLaravelUrl ?? '';

const getServicesCredit = (params) => {
  return axios.get(
    LARAVEL_URL + `/listServicesCredit/${params.customer_id}`,
    defaultConfig
  );
};

const postHireContactsDay = async (data) => {
  return axios.post(`${LARAVEL_URL}/hire_contacts_day`, data, defaultConfig);
};

const getListContactsDay = (customer_id) => {
  if (!customer_id) return;
  return axios.get(
    LARAVEL_URL + `/list_contacts_day/${customer_id}`,
    defaultConfig
  );
};

const getListServicesDiscount = () => {
  return axios.get(LARAVEL_URL + `/list_services_discount`, defaultConfig);
};

const getListCustomer = () => {
  return axios.get(
    LARAVEL_URL + `/list_customer_for_contact_day`,
    defaultConfig
  );
};

const getListPaymentContactDay = (customer_cred_id) => {
  if (!customer_cred_id) return;
  return axios.get(
    LARAVEL_URL + `/list_payment_contact_day/${customer_cred_id}`,
    defaultConfig
  );
};

const getCheckPlanAnnual = (customer_id) => {
  if (!customer_id) return;
  return axios.get(
    LARAVEL_URL + `/check_plan_annual/${customer_id}`,
    defaultConfig
  );
};

const getFinanceUser = (user_id) => {
  if (!user_id) return;
  return axios.get(LARAVEL_URL + `/get_finance_user/${user_id}`, defaultConfig);
};

const postCalculateAnnualPackageValue = (data) => {
  return axios.post(
    LARAVEL_URL + `/calculate_annual_package_value`,
    data,
    defaultConfig
  );
};

const postLiberateToUseContactDay = (data) => {
  return axios.post(
    LARAVEL_URL + `/liberate_to_use_contact_day`,
    data,
    defaultConfig
  );
};

const postRemoveCredit = (data) => {
  return axios.post(LARAVEL_URL + `/remove_credit`,
  data,
  defaultConfig
  );
};

const postDisableRecurrent = (customer_cred_id) => {
  return axios.post(
    LARAVEL_URL + `/disable_recurrent/${customer_cred_id}`,
    {},
    defaultConfig
  );
};

const postApplyDiscount = (customer_cred_payments_id, discounts) => {
  return axios.post(
    LARAVEL_URL + `/apply_discount`,
    { customer_cred_payments_id, discounts },
    defaultConfig
  );
};

export {
  getServicesCredit,
  getListServicesDiscount,
  postHireContactsDay,
  getListContactsDay,
  getListCustomer,
  getListPaymentContactDay,
  getCheckPlanAnnual,
  postCalculateAnnualPackageValue,
  postLiberateToUseContactDay,
  postRemoveCredit,
  postDisableRecurrent,
  postApplyDiscount,
  getFinanceUser,
};
