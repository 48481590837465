import styled from 'styled-components';

const MainBody = styled.div`
  flex: 1;
  box-sizing: border-box;
  background-color: var(--primary-background-color);
  border: 1px solid var(--border-color);
  margin-top: 1rem;
  padding: 0.8rem;
  border-radius: 5px;
  overflow: auto;
  height: 80%;
  .catalogForm {
    height: 80vh;
  }

  @media only screen and (max-width: 990px) {
    .buttonsCenterContainer {
      flex-direction: row !important;
      button {
        margin: 0;
      }
    }
  }
`;

const Logo = styled.img`
  margin-right: 0.5rem;
  margin-bottom: 0.3rem;
`;

const CheckboxList = styled.ul`
  display:flex;
  flex-direction:column;

  margin-top: 1rem;

  .switch {
    display: flex;
    flex-direction: column;
    position: relative;
    display: inline-block;
    width: 30px;
    height: 20px;
	margin-right: 1rem
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: gray;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 40%;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 18px;
    width: 14px;
    left: 0;
    bottom: 1px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(15px);
    -ms-transform: translateX(15px);
    transform: translateX(15px);
  }
  li {
    display: flex;
    margin: .5rem 0;
	flex-direction: row-reverse;
	justify-content: flex-end;
  }
`;

export { MainBody, Logo, CheckboxList };
