const statusList = {
  // [Status, isOk],
  ready: ['Ativo', true],
  verified: ['Verificado', true],
  unverified: ['Não verificado', false],
  error: ['Erro', false],
};

const channelStatus = (status) => {
  return statusList[status] || ['Indefinido', false];
};

export default channelStatus;
