import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from './validation';
import { Step3FormValues } from './types';

export function useLocalVerifyCodeForm() {
  const form = useForm<Step3FormValues>({
    defaultValues: {
      confirmation_code: '',
    },
    resolver: yupResolver(validationSchema),
  });
  // ---------------------------------------------
  // Functions
  // const { watch } = form;
  // ---------------------------------------------
  // Effects
  // ---------------------------------------------
  // Transformations
  // ---------------------------------------------
  // API

  return {
    form,
  };
}
