/**
 * @class WebSocketService
 * @author Samuel de Souza Silva
 */
class WebSocketService {
  constructor(url = 'ws://localhost:3001') {
    this.url = url;
    this.wsTimeout = 250;
    this.ws = null;
    this.callbackOnReceiveMessage = function (callback) {};
    this.callbackOnOpen = function (callback) {};
    this.callbackOnClose = function (callback) {};
    this.hasConnected = false;
    this.ws_unauthorized = false;
    this.dateConnected = new Date().getTime();
  }

  /**
   * @function onReceveMessage
   * Expected a function to call when websocket receive a new message.
   * @param function
   */
  onReceiveMessage(callback) {
    this.callbackOnReceiveMessage = callback;
  }

  /**
   * @function onConnect
   * Expected a function to call when websocket connect to server.
   * @param function
   */
  onOpen(callback) {
    this.callbackOnOpen = callback;

    if (this.isConnected()) this.callbackOnOpen();
  }

  /**
   * @function onConnect
   * Expected a function to call when websocket connect to server.
   * @param function
   */
  onClose(callback) {
    this.callbackOnClose = callback;
  }

  /**
   * @function isConnected
   * Check if websocket client is connected to the server.
   * @return boolean
   */
  isConnected() {
    return this.ws && this.ws.readyState === WebSocket.OPEN;
  }

  /**
   * @function sendMessage
   * Send a message to websocket server.
   * @param String Json
   */
  sendMessage(message) {
    this.ws.send(message);
  }

  /**
   * @function connect
   * This function establishes the connect with the websocket and also ensures constant reconnection if connection closes
   */
  connect = () => {
    this.ws = new WebSocket(this.url);

    let setTimeoutHandler;

    // websocket onopen event listener
    this.ws.onopen = () => {
      this.wsTimeout = 250; // reset timer to 250 on open of websocket connection
      this.hasConnected = true;
      this.dateConnected = new Date().getTime();

      clearTimeout(setTimeoutHandler);

      this.callbackOnOpen();
      ////reiniciar a conexão após 15min
      // setTimeout( ()=>{ this.ws.close(); this.connect(); },1000*60*15)
    };

    this.ws.onmessage = (event) => {
      if (event.data === 'ping') return this.ws.send('pong');
      if (event.data === 'ws_unauthorized') return this.ws_unauthorized = true;
      this.callbackOnReceiveMessage(event.data);
    };

    // websocket onclose event listener
    this.ws.onclose = (e) => {
      /* console.log(
        `Socket is closed. Reconnect will be attempted in ${Math.min(
          10000 / 1000,
          (this.wsTimeout + this.wsTimeout) / 1000
        )} second.`,
        e.reason
      );*/
      console.log("websocket on close - ws_unauthorized", this.ws_unauthorized);

      //Comentando esta verificação. Aguardar o websocket responder
        if (this.ws_unauthorized)
          return this.callbackOnClose({ reason: 'unauthorized' });

      this.wsTimeout = this.wsTimeout + this.wsTimeout; //increment retry interval
      setTimeoutHandler = setTimeout(
        this.checkConnection,
        Math.min(10000, this.wsTimeout)
      );
    };

    // websocket onerror event listener
    this.ws.onerror = (err) => {
      /* console.error(
        'Socket encountered error: ',
        err.message,
        'Closing socket'
      );*/
    };
  };

  /**
   * @function checkConnection
   * utilited by the function wsConnect to check if the connection is close, if so attempts to reconnect
   */
  checkConnection = () => {
    if (!this.ws || this.ws.readyState === WebSocket.CLOSED) this.connect(); //check if websocket instance is closed, if so call `connect` function.
  };
}

export default WebSocketService;
