import styled from 'styled-components';

export const OfflineStyle = styled.ul`
  height: 70px;
  background: #ff665d;
  color: #fff;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  min-width: 112px;
`;

export const OfflineIcone = styled.ul`
  background: #fff;
  color: #ff665d;
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 6px solid;
  border-radius: 50%;
  border-color: #fff;
`;
