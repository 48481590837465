import React from 'react';
import { Button } from '@polichat/flamboyant';

function CancelButton({ title, onClick }) {
  return (
    <Button onClick={onClick} outline color="danger">
      <span>Cancelar</span>
    </Button>
  );
}

export default CancelButton;
