const allSectionsHasRows = (sections) => {
  let sectionOneOption = [];

  sections?.map((section) => {
    let quantity = section?.rows?.length;
    
    if (quantity < 1) {
      sectionOneOption.push(true);
    } else {
      sectionOneOption.push(false);
    }
  });

  return sectionOneOption.includes(true);
};

export default allSectionsHasRows;
