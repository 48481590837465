import { useCallback, Fragment } from 'react';
import { Icon } from '@polichat/flamboyant';
import { QuickMessageSelectorOptions } from '../types/types';

import { QuickTypes } from '../types/constants';

import styles from '../QuickMessageSelector.module.css';
import { Sad } from '@poli-ink/react';

interface EmptyResultProps {
  type: QuickMessageSelectorOptions;
  role?: string;
}

export function EmptyResult({ type, role }: EmptyResultProps) {
  return (
    <div className={styles.empty_results}>
      <div>
        <span>
          <Sad color="" />
        </span>
        {type === QuickTypes.QUICK && <p>Não há mensagem rápida cadastrada!</p>}

        {type !== QuickTypes.QUICK && (
          <Fragment>
            <p>Não há template cadastrado e aprovado!</p>
            <p>
              Para iniciar uma conversa ativa por esse canal é necessário ter um
              template vinculado ao canal e aprovado pela equipe do Facebook.
              <br />
              {role === 'adm' || role === 'manager' ? (
                <Fragment>
                  <span>
                    Crie um template em :
                    <i>{' Configurações -> Construtor de Template'}</i>
                  </span>
                </Fragment>
              ) : (
                'Solicite ao administrador/gestor a criação de um template.'
              )}
            </p>
          </Fragment>
        )}
      </div>
    </div>
  );
}
