import styled from 'styled-components';

export const Container = styled.div`
  box-sizing: border-box;
  overflow: auto;
  height: 100%;

  @media (max-width: 600px) {
    margin: 0;
  }
`;
