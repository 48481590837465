import * as Yup from 'yup';

export const validationSchema = Yup.object({
  confirmation_code: Yup.string()
    .required('O código de verificação é obrigatório')
    .test({
      test: (value) => (value?.length || 0) === 6,
      message: 'Código deve conter 6 dígitos.',
    }),
});
