import { useState } from 'react';
import CombinedLogo from '../../../../../assets/images/poli-plus-whatsapp.svg';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CustomInput,
  Button,
} from '@polichat/flamboyant';
import styles from './style.module.css';

interface ZapiModalProps {
  isOpen: boolean;
  isDarkMode: boolean;
  toggleModal: () => void;
  onSubmit: () => void;
}

export function ZapiToBspModal(props: ZapiModalProps): JSX.Element {
  const checksToHomologation = [
    {
      id: 'checkZapiToBsp0',
      name: 'Cartão CNPJ',
      checked: false,
    },
    {
      id: 'checkZapiToBsp1',
      name: 'Contrato Social',
      checked: false,
    },
    {
      id: 'checkZapiToBsp2',
      name: 'Site com domínio próprio',
      checked: false,
    },
    {
      id: 'checkZapiToBsp3',
      name: 'Email corporativo (email com o mesmo domínio do site)',
      checked: false,
    },
    {
      id: 'checkZapiToBsp4',
      name: 'Gerenciador de Negocios do Facebook no nome da Empresa',
      checked: false,
    },
    {
      id: 'checkZapiToBsp5',
      name: 'Certificado de Segurança (SSL) no site',
      checked: false,
    },
  ];

  const [isChecked, setIsChecked] = useState(checksToHomologation);

  const confirmEnabled =
    isChecked[0].checked === true &&
    isChecked[1].checked === true &&
    isChecked[2].checked === true &&
    isChecked[3].checked === true &&
    isChecked[4].checked === true &&
    isChecked[5].checked === true;

  const handleAgree = (index: any) => {
    const updateIsChecked = isChecked.map((check, idx) => {
      const toggleCheck = check.checked;
      if (idx === index) {
        return {
          ...check,
          checked: !toggleCheck,
        };
      }
      return check;
    });
    setIsChecked(updateIsChecked);
  };

  const handleClose = () => {
    props.toggleModal();
  };

  return (
    <>
      <Modal isOpen={props.isOpen} toggle={props.toggleModal}>
        <ModalHeader>
          <div className={styles.image_header} style={{ textAlign: 'center' }}>
            <img src={CombinedLogo} style={{ margin: '0px 0px 20px 0px' }} />
            Você tem certeza que deseja prosseguir com a homologação?
          </div>
        </ModalHeader>
        <ModalBody>
          Antes de prosseguir, verifique abaixo se você atende{' '}
          <b>todos os requisitos</b> para dar seguimento à sua homologação.
        </ModalBody>
        <ModalBody>
          <div className={styles.checkbox_container}>
            {isChecked.map((check, index) => (
              <CustomInput
                key={index}
                type="checkbox"
                id={check.id}
                label={check.name}
                checked={check.checked}
                onChange={() => handleAgree(index)}
              />
            ))}
          </div>
        </ModalBody>
        <ModalFooter>
          <div className={styles.buttons_container}>
            <Button
              gradient="danger"
              className={styles.custom_button}
              onClick={handleClose}
            >
              Cancelar
            </Button>
            <Button
              disabled={!confirmEnabled}
              className={styles.custom_button}
              onClick={props.onSubmit}
            >
              Continuar
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
}
