import React from 'react';
import { Icon }from '@polichat/flamboyant';

export default function setIconChannel(channelID) {
  switch (channelID) {
    case 1:
      // Whatsapp
      return <Icon icon="poli-icon pi-whatsapp-fill" size={16} />
    case 2:
      // Messenger
      return <Icon icon="poli-icon pi-messenger-fill" size={16} />
    case 3:
      //Email
      return <Icon icon="poli-icon pi-mail-fill" size={16} />
    case 4:
      // Webchat
      return <Icon icon="poli-icon pi-webchat-fill" size={16} />
    case 33:
      // API oficial Whatsapp
      return <Icon icon="poli-icon pi-whatsapp-fill" size={16} />
    default:
      break;
  }
}
