import { laravelAxios } from "../../../../config/axios/laravelAxios";

import { DtoDepartments } from "./dtoDepartments";
import { GetDepartmentsParams } from "./types";

export class DepartmentsService {
  static async getDepartments (params: GetDepartmentsParams){
    const url = `/spa/chats/customer/${params.customer_id}/allDepartments`
    const request = await laravelAxios.get<DtoDepartments>(url)
    return request.data
  }
}
