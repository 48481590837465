import React from 'react';
import { Image } from '@polichat/flamboyant';
import iconPolichat from '../assets/images/Logo-polichat-1.png';
import audioPolichat from '../assets/audios/notification.mp3';

class NotificationAndSong {
  constructor() {
    this.title = 'Chat';
    this.document_title = this.title;
    this.last_time = 0;
    var Notification = window.Notification || false;
    if (!this.pushnotifications_index) {
      this.pushnotifications_index = {};
      this.pushnotifications_models = [];
    }
    if (Notification) {
      this.last_time_event = new Date().getTime();
      let str_events =
        'click mousedown mouseup blur keydown mouseup dblclick mousemove keydown keyup textInput touchstart touchmove touchend touchcancel resize scroll focus';
      str_events.split(' ').map((evt) => {
        window.document.body.addEventListener(
          evt,
          (e) => {
            this.last_time_event = new Date().getTime();
            if (this.pushnotifications_models.length) {
              this.pushnotifications_models.map((model) => {
                model.close();
                delete this.pushnotifications_index[model.id];
              });
            }
          },
          false
        );
      });

      if (Notification.permission !== 'granted')
        Notification.requestPermission();
    }
  }
  lastTime(dtm) {
    if (!dtm) {
      if (!this.last_time) {
        dtm = new Date().getTime() / 1000;
        this.last_time = 0;
      } else {
        return;
      }
    }

    dtm = parseInt(dtm);
    if (!this.last_time || this.last_time < dtm) {
      this.last_time = dtm;
      this.new_time = true;
    }
  }
  fadeTitle(i, t) {
    if (i > 7) return;
    if (t == null) {
      t = 500;
    }
    setTimeout(() => {
      if (i == 0 || i == 2 || i == 4 || i == 6) {
        document.title = 'chamando...';
        this.fadeTitle(++i, 600);
      } else if (i == 1 || i == 3 || i == 5 || i == 7) {
        document.title = this.document_title;
        this.fadeTitle(++i, 500);
      }
    }, t);
  }
  updateTitle(qtde) {
    if (qtde === null) {
      let text = document.title;
      var qtde_now = parseInt(text.replace(/[^0-9]/g, ''));
      if (qtde_now > 0) {
        qtde = qtde_now - 1;
      }
    }
    if (qtde > 0) {
      const newQtd = qtde >= 100 ? '99+' : qtde
      let text = '(' + newQtd + ') ' + this.title;
      document.title = text;
      this.document_title = text;
      this.lastTime();
      if (this.new_time) {
        this.new_time = false;
        this.fadeTitle(0);
      }
    } else {
      document.title = this.title;
    }
  }
  pushNotification(chat) {
    if (!Notification) return;
    if (Notification.permission !== 'granted') Notification.requestPermission();
    else {
      var time = new Date().getTime();
      if (time - this.last_time_event < 5000) {
        //return;
        this.last_time = 0;
      }
      if (
        this.pushnotifications_index &&
        this.pushnotifications_index[chat.chat.id]
      ) {
        this.pushnotifications_index[chat.chat.id].close();
        delete this.pushnotifications_index[chat.chat.id];
        var models = this.pushnotifications_models;
        this.pushnotifications_models = [];
        models.map((model) => {
          if (this.pushnotifications_index[model.id]) {
            this.pushnotifications_models.push(
              this.pushnotifications_index[model.id]
            );
          }
        });
      }
      var body = '';
      if (
        chat.last_message &&
        chat.last_message.dtm &&
        chat.last_message.dtm >= this.last_time
      ) {
        if (chat.last_message.type != 'chat') {
          body = 'Você recebeu ';
          if (chat.last_message.type == 'image') {
            body += 'uma imagem';
          } else if (chat.last_message.type == 'video') {
            body += 'um vídeo';
          } else if (
            chat.last_message.type == 'ptt' ||
            chat.last_message.type == 'audio'
          ) {
            body += 'um arquivo de áudio';
          } else if (chat.last_message.type == 'document') {
            body += 'um arquivo';
          } else if (chat.last_message.type == 'vcard') {
            body += 'um contato';
          } else if (chat.last_message.type == 'location') {
            body += 'uma localização';
          } else if (chat.last_message.type == 'sys_invalid_cont') {
            body = 'Canal inválido';
          } else if (chat.last_message.type == 'sys_cfw') {
            body = 'Houve um redirecionamento';
          } else {
            body += 'uma mídia';
          }
          body += ' deste contato.';
        } else {
          if (chat.last_message.body.length > 80) {
            body = chat.last_message.body.substr(0, 78) + '...';
          } else {
            body = chat.last_message.body;
          }
        }
        if (this.pushnotifications_index[chat.chat.id]) {
          this.pushnotifications_index[chat.chat.id].showNotification(
            chat.contact && chat.contact.name
              ? chat.contact.name
              : chat.contact && chat.contact.phone
              ? chat.contact.phone
              : '--',
            {
              icon: iconPolichat,
              body: body,
            }
          );
        } else {
          this.pushnotifications_index[chat.chat.id] = new Notification(
            chat.contact && chat.contact.name
              ? chat.contact.name
              : chat.contact && chat.contact.phone
              ? chat.contact.phone
              : '--',
            {
              icon: iconPolichat,
              body: body,
            }
          );
        }
        this.pushnotifications_models.push(
          this.pushnotifications_index[chat.chat.id]
        );
        this.pushnotifications_index[chat.chat.id].id = chat.chat.id;
        this.pushnotifications_index[chat.chat.id].onclick = function () {
          window.focus();
          this.close();
        };
      }
    }
  }

  // getQuantity() {
  //   let count_no_read = 0;

  //   if (
  //     this.document_title &&
  //     this.document_title.length &&
  //     typeof this.document_title === 'string'
  //   ) {
  //     count_no_read = this.document_title.replace(/\D/g, '');
  //     if (count_no_read) {
  //       count_no_read = parseInt(count_no_read);
  //     }
  //   }

  //   return count_no_read;
  // }

  // increment() {
  //   let count_no_read = this.getQuantity();
  //   count_no_read++;
  //   this.updateTitle(count_no_read);
  //   return count_no_read;
  // }

  // decrement() {
  //   let count_no_read = this.getQuantity();
  //   if (count_no_read >= 1) {
  //     count_no_read--;
  //     this.updateTitle(count_no_read);
  //   }
  //   return count_no_read;
  // }
}


const notificationAndSong = new NotificationAndSong();
export default notificationAndSong;
