import { DtoGateway } from '../service/types';

export function getPaymentInfoFromGatewayData(
  data: DtoGateway[] | undefined
): boolean {
  if (data) {
    return Boolean(data.filter((item) => item.active == 1).length);
  }
  return false;
}
