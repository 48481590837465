import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Button, Alert, Row, CustomInput, Label } from '@polichat/flamboyant';
import { toast } from 'react-toastify';

import history from '../../../../services/history';
import WabaService from '../../../../services/tools/template-waba/template-waba';
import ChannelsService from '../../../../services/tools/channels/channels';

import { MessagesBuilderImg } from 'src/components/chat/content/chat/modals/src/MessagesBuilderImg.js';

import Animation from '../../../common/animation';

import { ChannelSelectorContainer } from './table/ImportTemplates.styles';
import PageMainHeader from '../../../common/pages/PageMainHeader/PageMainHeader.component';
import PageMainBody from '../../../common/pages/PageMainBody/PageMainBody.component';
import TableMessagesBuilderImport from './table/TableMessagesBuilderImport.component';
import DeleteTemplateModal from './deleteModal/DeleteTemplateModal.component';
import {
  MessageBuilderHome,
  ButtonsContainer,
  Container,
  MessageBuilderLayout,
} from './ListMessagesBuilder.styles';

const ImportTemplates = () => {
  const [channelID, setchannelID] = useState(false);
  const customer_id = useSelector((state) => state.general.current_customer_id);
  const [messagesList, setMessagesList] = useState([]);
  const [isLoadingMessagesTemplate, setIsLoadingMessagesTemplate] =
    useState(false);
  const [hasFirstRequestData, setHasFirstRequestData] = useState(0);
  const [tryFetchDataAgain, setTryFetchDataAgain] = useState(false);

  const [isDeleteTemplateModalOpen, setIsDeleteTemplateModalOpen] =
    useState(false);
  const [templateDeleteData, setTemplateDeleteData] = useState({});
  const [wabaChannelsList, setWabaChannelsList] = useState([]);

  useEffect(() => {
    async function fetchwabaChannelsList() {
      try {
        const result = await ChannelsService.getAllChannels({
          customer_id: customer_id,
        });
        const newWabaChannelList = result.data.filter((channel) => {
          return (
            (channel.api == 33 || channel.api == 34 || channel.api == 10) &&
            channel.status &&
            !channel.homologation
          );
        });
        setWabaChannelsList(newWabaChannelList);
        if (newWabaChannelList.length > 0) {
          setchannelID(newWabaChannelList[0].id);
        }
      } catch (error) {
        console.error(
          'Error in importTemplates component when fetchwabaChannelsList',
          error
        );
      }
    }
    fetchwabaChannelsList();
  }, []);
  async function fetchTemplateList() {
    setIsLoadingMessagesTemplate(true);
    try {
      const { data } = await WabaService.getTemplatesWabaImport({
        customer_id,
        channel_id: channelID,
      });
      if (data) {
        setMessagesList(data);
        setTryFetchDataAgain(false);
        setHasFirstRequestData((old) => old + 1);
      } else {
        setTryFetchDataAgain(true);
      }
    } catch (error) {
      console.log(
        'Error in ImportTemplates component when fetchTemplates => ',
        error
      );
      setTryFetchDataAgain(true);
    }
    setIsLoadingMessagesTemplate(false);
  }

  useEffect(() => {
    channelID ? fetchTemplateList() : null;
  }, [channelID]);

  // const goToTutorials = () => {
  //   window.open(
  //     'https://www.youtube.com/channel/UC6F-t3Qvgo2zMbEGICLQYIg',
  //     '_blank'
  //   );
  // };
  const goToCreateTemplate = () => {
    history.push({
      pathname: '/tools/messages-builder/add',
    });
  };
  const handleInputChange = ({ target: { value } }) => {
    setchannelID(value);
  };
  const handleOptionClicked = ({ option, template_name, tag }) => {
    if (option === 'delete') {
      setIsDeleteTemplateModalOpen(true);
      setTemplateDeleteData({ template_name, tag });
    }
    if (option === 'edit') {
      history.push({
        pathname: `/tools/messages-builder/edit/${id}`,
      });
    }
  };

  function handleCloseDeleteTemplateModal() {
    setIsDeleteTemplateModalOpen(false);
  }

  async function handleDeleteTemplate(name) {
    let params = {
      template_name: name,
      customer_id: customer_id,
      channel_id: channelID,
    };

    const result = await WabaService.deleteImportTemplateWaba(params);
    if (result.error) {
      result.message
        ? toast.error(result.message)
        : toast.error('Erro ao excluir template!');
      return;
    }
    toast.success('Template excluido!');
    setMessagesList(messagesList.filter((item) => item.name !== name));
  }

  const colsTitle = [
    { colID: 1, colTitle: 'Mensagem' },
    { colID: 2, colTitle: 'Categoria' },
    { colID: 3, colTitle: 'Conteúdo' },
    { colID: 4, colTitle: 'Status' },
    { colID: 5, colTitle: '' },
  ];
  return (
    <>
      <PageMainHeader
        title="Importar Templates"
        icon="poli-icon pi-loading-fill"
      >
        {messagesList ? (
          <>
            <Button gradient="primary" onClick={goToCreateTemplate}>
              Criar uma mensagem
            </Button>
          </>
        ) : null}
      </PageMainHeader>
      <PageMainBody>
        <Container>
          {!messagesList.length && hasFirstRequestData <= 1 ? (
            <MessageBuilderHome>
              <MessagesBuilderImg />
              <p>
                Crie um template, mensagem rápida ou mensagem para automatização
              </p>

              {isLoadingMessagesTemplate ? (
                <div className="container-loading">
                  <Animation icon="loading" />
                </div>
              ) : (
                <>
                  <p>
                    {/* TODO tirar o termo Poli */}
                    Com o criador de mensagens você pode criar e
                    visualizar um exemplo do que você criou de forma simples e
                    rápida
                  </p>
                  <ButtonsContainer>
                    {tryFetchDataAgain ? (
                      <Button gradient="primary" onClick={fetchTemplateList}>
                        Tentar novamente
                      </Button>
                    ) : (
                      <>
                        <Button gradient="primary" onClick={goToCreateTemplate}>
                          Criar uma mensagem
                        </Button>
                      </>
                    )}
                  </ButtonsContainer>
                </>
              )}
            </MessageBuilderHome>
          ) : (
            <MessageBuilderLayout>
              <Label>Canal para importação:</Label>
              <ChannelSelectorContainer>
                <CustomInput
                  type="select"
                  id="template-import-customer-channel"
                  name="templateImportChannelCustomer"
                  value={channelID}
                  onChange={handleInputChange}
                >
                  {wabaChannelsList.map((channel) => (
                    <option key={channel.id} value={channel.id}>
                      {`${channel.name} - ${channel.phone}`}
                    </option>
                  ))}
                </CustomInput>
              </ChannelSelectorContainer>

              <div className="header"></div>
              <div className="body">
                {messagesList.length ? (
                  <TableMessagesBuilderImport
                    cols={colsTitle}
                    rows={messagesList}
                    isLoading={isLoadingMessagesTemplate}
                    onOptionsClick={handleOptionClicked}
                  />
                ) : (
                  <Alert color="attention">
                    Não há registros para essa busca
                  </Alert>
                )}
              </div>
            </MessageBuilderLayout>
          )}
        </Container>
      </PageMainBody>
      {
        <DeleteTemplateModal
          isOpen={isDeleteTemplateModalOpen}
          onCloseRequest={handleCloseDeleteTemplateModal}
          data={templateDeleteData}
          onDeleteRequest={handleDeleteTemplate}
        />
      }
    </>
  );
};

export default ImportTemplates;
