import React, { useState } from 'react';

import {
  Icon,
  Button,
  Container,
  Row,
  Col,
  CustomInput,
} from '@polichat/flamboyant';

import history from '../../../../../../services/history';
import ChannelsService from '../../../../../../services/tools/channels/channels';
import ButtonDoubts from '../../common/ButtonDoubts/ButtonDoubts.component';
import { handleExternalLink } from '../../../../../../utils/handleExternalLink';
import ActionGroupButtons from '../../ActionGroupButtons/ActionGroupButtons.component';

import {
  StatusSection,
  ItemAprroved,
  IconAprroved,
  IconLoading,
  InfoTextContainer,
  InputsContainer,
} from './FacebookAnalysis.styles';
import channelFBStatus from '../../../../common/channelFBStatus';

const FacebookAnalysis = ({ data, dataSet, currentStep }) => {
  const goBack = () => history.push('/tools/channels');

  const wlConfig = useSelector(
    // @ts-ignore
    (state) => state?.whitelabel?.config
    );

  // @todo Remover botão avançar de teste
  const handleNextAction = async () => {
    const step = parseInt(currentStep) + 1;
    await ChannelsService.updateStepHomologation({
      channel_id: data.id,
      step,
    });
    dataSet({ ...data, homologation: step });
  };

  const { waba } = data || {};
  const { fb_account_status, fb_business_id } = waba || {};
  const [fbStatus] = channelFBStatus(fb_account_status);
  const fbFailedOrReject = ['failed', 'reject'].includes(fbStatus);
  const linkFacebookBusiness = fb_business_id
    ? `https://business.facebook.com/settings/security/?business_id=${fb_business_id}`
    : `https://business.facebook.com/settings/security/`;

  const [isSkipping, setIsSkipping] = useState(false);

  /* const handleClickFacebook = () =>
    handleExternalLink(
      `https://business.facebook.com/settings/security/?business_id=${fb_business_id}`
    ); */

  return (
    <>
      <Container fluid={true}>
        <Row>
          {!wlConfig.hide_doubts_button && <Col className="d-flex justify-content-end my-2">
            <ButtonDoubts />
          </Col>}
        </Row>
        <Row>
          <Col lg={7}>
            <StatusSection>
              <ItemAprroved>
                <span>Aguardando análise do Facebook</span>
              </ItemAprroved>

              <ItemAprroved>
                <IconAprroved>
                  <Icon
                    icon="poli-icon pi-check-one-fill"
                    size={12}
                    color="#20EFE5"
                  />
                </IconAprroved>
                <span>Cadastro do canal</span>
              </ItemAprroved>

              <ItemAprroved>
                <IconAprroved>
                  <Icon
                    icon="poli-icon pi-check-one-fill"
                    size={20}
                    color="#20EFE5"
                  />
                </IconAprroved>
                <span>Solicitação de integração da WABA</span>
              </ItemAprroved>

              <ItemAprroved>
                <IconAprroved>
                  <Icon
                    icon="poli-icon pi-check-one-fill"
                    size={20}
                    color="#20EFE5"
                  />
                </IconAprroved>
                <span>Aprovação da parceria para utilização da WABA</span>
              </ItemAprroved>

              <ItemAprroved>
                <IconAprroved>
                  <Icon
                    icon="poli-icon pi-check-one-fill"
                    size={20}
                    color="#20EFE5"
                  />
                </IconAprroved>
                <span>Envio da documentação para análise do Facebook</span>
              </ItemAprroved>

              <ItemAprroved>
                <IconLoading>
                  <Icon
                    icon="poli-icon pi-loading-fill"
                    size={20}
                    color="#20EFE5"
                  />
                </IconLoading>
                <span>Análise do Facebook</span>
              </ItemAprroved>

              <InputsContainer>
                <CustomInput
                  type="checkbox"
                  id="skip-analysis"
                  label="Conta verificada no Facebook"
                  checked={isSkipping}
                  onChange={() => setIsSkipping(!isSkipping)}
                />
              </InputsContainer>
            </StatusSection>
          </Col>
          <Col
            lg={5}
            className="d-flex justify-content-center align-items-start"
          >
            {!fbFailedOrReject && (
              <div className="border p-2 mt-5 d-flex justify-content-center align-items-center flex-column">
                <h2 className="d-flex">
                  <Icon
                    icon="poli-icon pi-firewall-line"
                    size={34}
                    color="#20EFE5"
                  />
                  Verificação da empresa
                </h2>
                <p>
                  Seus documentos estão sendo analisados. Você receberá um
                  status após a conclusão da análise.
                </p>

                <p>
                  Assim que tivermos uma atualização do Facebook, iremos te
                  avisar. Caso queira, acompanhe diretamente no Facebook por
                  este link:
                  {'  '}
                </p>
                <center>
                  <Button
                    color="primary"
                    gradient="primary"
                    onClick={() => handleExternalLink(linkFacebookBusiness)}
                  >
                    Clique Aqui
                  </Button>
                </center>
              </div>
            )}
            {fbFailedOrReject && (
              <div className="border p-2 mt-5">
                <h2 className="d-flex">
                  <Icon
                    icon="poli-icon pi-alert-fill"
                    size={34}
                    color="#ff665d"
                  />
                  Verificação da empresa
                </h2>
                <div
                  className="pl-2"
                  style={{ borderLeft: 'solid 5px #ff665d' }}
                >
                  <h3>A verificação da empresa foi rejeitada.</h3>
                  <p>
                    Você pode enviar documentos novos ou enviar os documentos
                    existentes novamente para outra análise
                  </p>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Container>

      {/* <Button onClick={handleNextAction}>Botão Teste - Avançar etapa</Button> */}
      <ActionGroupButtons
        onClickNext={handleNextAction}
        onClickBack={goBack}
        nextButtonActived={!isSkipping}
      />
    </>
  );
};

export default FacebookAnalysis;
