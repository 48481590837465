import React, { useState } from 'react';
import FilterModalTemplate from './FilterModalTemplate';
import Animation from '../../../../common/animation';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ChatActions from '../../../../../store/modules/chat/actions';
import { convertActiveTabInScreen } from '../../../../../store/modules/chat/functions';

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from '@polichat/flamboyant';

function TagsModal({
  tags,
  isTagsMenuOpen,
  toggleFilterMenu,
  activeFilters,
  submitFilter,
  loading,
  clearFilter,
  currentTabNavigation
}) {
  const filter_type_name = 'tags';
  const filter_title = 'Filtrar por Etiqueta';
  const filter_icon = 'poli-icon pi-tag-line';
  const currentScreen = convertActiveTabInScreen(currentTabNavigation);

  let content;
  let tags_select = [];
  let enabled = true;

  function verifyCurrentFilter () {
    if (!activeFilters.length) {
      setFilter('')
    }
  }


  const findCurrentTagFilter = () => {
    let active_filter = '';

    if (activeFilters.length) {
      activeFilters.forEach((filter) => {
        if (filter.type === filter_type_name) {
          active_filter = filter.tag_id;
        }
      });
    }

    return active_filter;
  };

  const [filter, setFilter] = useState(findCurrentTagFilter());
  const selectedTagName = tags.map((tag) => {
    // tem que ser apenas dois iguais
    if (tag.id == filter) {
      return tag.name;
    }
  });

  const findName = (id) => {
    verifyCurrentFilter();
    let tag = tags.find((tag) => tag.id === id);

    if (tag) {
      if (tag.name) {
        return tag.name;
      } else {
        return '';
      }
    } else {
      return '';
    }
  };

  const onSubmitEvent = () => {
    let payload = Object.assign({}, activeFilters);

    if (filter) {
      let value = parseInt(filter);
      let name = findName(value);

      payload.tag = [
        {
          name: name,
          value: value,
        },
      ];

      submitFilter(payload, currentScreen);
    } else {
      payload.tag = [];
      clearFilter(payload);
    }

    toggleFilterMenu(filter_type_name);
  };

  const sortByName = (a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  };

  if (tags && tags.length) {
    tags_select = tags.sort(sortByName).map((tag, index) => {
      return (
        <DropdownItem
          key={`${tag.id}+${index}`}
          value={tag.id}
          className="dropItem-filters"
          onClick={(e) => setFilter(e.target.value)}
        >
          {tag.name}
        </DropdownItem>
      );
    });

    content = (
      <UncontrolledDropdown className="dropDown-filters">
        <DropdownToggle
          color="neutral"
          caret
          id="selecttags"
          name="selecttags"
          className="total dropDownToggle-filters"
        >
          <span>
            {filter != '' && selectedTagName
              ? selectedTagName
              : 'Selecione uma etiqueta'}
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropDownMenu-filters">
          {tags_select}
        </DropdownMenu>
      </UncontrolledDropdown>
    );

    enabled = true;
  } else {
    enabled = false;
    content = <Animation icon="loading" size="tiny" />;
    if (tags && tags.length === 0) {
      content = <span className="noTags">Não existem etiquetas cadastradas.</span>
    }
  }

  return (
    <FilterModalTemplate
      icon={filter_icon}
      title={filter_title}
      content={content}
      isOpen={isTagsMenuOpen}
      toggleEvent={() => toggleFilterMenu(filter_type_name)}
      onSubmit={onSubmitEvent}
      loading={loading}
      disabled={!enabled}
    />
  );
}

const mapStateToProps = (state) => ({
  tags: state.chat?.config?.general?.tags,
  isTagsMenuOpen: state.chat?.filters?.view?.isTagsMenuOpen,
  activeFilters: state.chat?.filters?.activeFilters,
  loading: state.chat?.filters?.loading,
  currentTabNavigation: state.chat?.config?.activeTabNavigation,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ChatActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TagsModal);
