import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export function useUserRole() {
  const userRole = useSelector(
    // @ts-ignore
    (state) => state.general?.user?.superRole
  );

  const [userPermission, setUserPermission] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const handleUserPermission = useCallback(() => {
    if (userRole === 'adm' || userRole === 'manager') setUserPermission(true);
    setIsLoading(false);
  }, [userRole]);

  useEffect(() => {
    if (userRole) handleUserPermission();
  }, [userRole]);

  return {
    userPermission,
    isLoading,
  };
}
