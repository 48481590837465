import React from 'react';
import { Badge } from '@polichat/flamboyant';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ChatActions from '../../../../store/modules/chat/actions';
import { dtmToFormatString } from '../../../../utils/dtmToFormatString';

function SystemMessage({ props, opennedChat }) {
  const chat = opennedChat;
  let icon = styles.icon;
  let badge = styles.badge;
  let content;
  let extraClass;

  /**
   * Estilização da mensagem tipo call_log (chamada perdida)
   */
  if (props.type === 'call_log') {
    icon = {
      ...icon,
      color: 'var(--alert-system-red)',
      icon: 'poli-icon pi-smartphone-fill',
    };

    badge = {
      ...badge,
      color: 'var(--alert-system-red)',
      backgroundColor: 'var(--alert-system-red-background)',
      border: 'var(--alert-system-red-border)',
    };

    content = 'Este contato te ligou';
  }

  /**
   * Estilização da mensagem tipo Chat Encerrado
   */
  if (props.type === 'sys_cfw_closed' && chat) {
    badge = {
      ...badge,
      color: 'var(--primary-background-color)',
      backgroundColor: 'var(--red-polichat)',
      border: 'var(--alert-system-red-border)',
    };

    extraClass = 'finishedAt';

    // Adiciona o horário de envio da mensagem quando tiver sido finalizado pela URA
    if (
      props &&
      !props.userId && // mensagens do tipo sys-cfw-closed sem userId só ocorrem quando o chat é finalizado pela URA
      props.text &&
      !/\d:\d\d/.test(props.text.slice(-4)) // checa se o final da mensagem é do tipo X:XX
    ) {
      // adiciona espaço no final caso não tenha
      if (props.text.slice(props.text.length - 1) != ' ') props.text += ' ';

      const timezone = useSelector(
        (state) => state?.general?.user?.settings?.timezone[0]?.zone
      );
      props.text += dtmToFormatString(props.dtm, timezone)
        .slice(0, -3) // removes seconds
        .split(' ')
        .join(' às '); // retorna "dd/mm/yy às hh:mm"
    }
  }

  /**
   * Estilização da mensagem tipo e2e (criptografia e segurança whatsapp)
   */
  if (props.type === 'e2e_notification') {
    icon = {
      ...icon,
      color: 'var(--alert-system-green)',
      icon: 'poli-icon pi-password-fill',
    };

    badge = {
      ...badge,
      color: 'var(--alert-system-green)',
      backgroundColor: 'var(--alert-system-green-background)',
      border: 'var(--alert-system-green-border)',
    };

    content =
      'Mensagens protegidas com criptografia de ponta a ponta pelo WhatsApp.';
  }
  /**
   * Estilização da mensagem tipo revoked (mensagem excluida pelo contato)
   */
  if (props.type === 'revoked') {
    icon = {
      ...icon,
      color: 'var(--alert-system-red)',
      icon: 'poli-icon pi-delete-fill',
    };

    badge = {
      ...badge,
      color: 'var(--alert-system-red)',
      backgroundColor: 'var(--alert-system-red-background)',
      border: 'var(--alert-system-red-border)',
    };

    content = 'Mensagem excluída pelo contato';
  }
  /**
   * Estilização da mensagem tipo oversized (mensagem excede limite de caracteres do whatsapp)
   */
  if (props.type === 'oversized') {
    icon = {
      ...icon,
      color: 'var( --alert-system-orange)',
      icon: 'poli-icon pi-alert-fill',
    };

    badge = {
      ...badge,
      color: 'var( --alert-system-orange)',
      backgroundColor: 'var( --alert-system-orange-background)',
      border: 'var( --alert-system-orange-border)',
    };

    content = 'Mensagem excluída pelo contato';
  }

  if (props.type === 'sys_cfw') {
    icon = {
      ...icon,
      icon: 'poli-icon pi-auto-talks-line',
    };
  }

  return (
    <Badge
      style={badge}
      className={
        extraClass
          ? `systemMessageContainer ${extraClass}`
          : 'systemMessageContainer'
      }
    >
      {/* {icon && <Icon icon={icon.icon} size={24} color={icon.color} />} */}
      <span>{(props.text || '').replace(/\[bot]/i, '') || content}</span>
    </Badge>
  );
}

const styles = {
  badge: {
    border: 'var(--alert-system-blue-border)',
    backgroundColor: 'var(--alert-system-blue-background)',
    color: 'var(--system-font-color)',
    margin: '0.5em',
    padding: '0.7em',
    width: '100%',
    fontSize: '0.8rem',
    whiteSpace: 'break-spaces',
  },

  icon: {
    color: 'var(--alert-system-blue)',
  },
};

const mapStateToProps = (state, ownProps) => ({
  props: ownProps,
  opennedChat:
    state.chat?.chats?.filter((chat) => {
      return chat.open === true;
    })[0] || [],
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ChatActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SystemMessage);
