import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Row
} from '@polichat/flamboyant';
import { useState } from 'react';
import { LabelWithSpan } from '../../FormMessagesBuilder.styles';

function CreateSectionList({
  id,
  onUpdate,
  removeSection,
  initialValue,
  isDisabledRemove,
}) {
  const [sectionData, setSectionData] = useState(initialValue);

  const handleInputChange = ({ target: { name, value } }) => {
    setSectionData({
      ...sectionData,
      [name]: value,
    });
    handleSectionData({
      ...sectionData,
      [name]: value,
    });
  };

  const handleWithDeleteSection = () => {
    setSectionData({});
    removeSection(id);
  };

  const handleSectionData = (data) => {
    onUpdate(data);
  };

  return (
    <>
      <FormGroup>
        <LabelWithSpan>
          <Label>
            Seção: <span>Informe o titúlo da sua seção </span>
          </Label>
        </LabelWithSpan>
      </FormGroup>
      <Row>
        <Col sm={8} md={8}>
          <Input
            type="text"
            id="section-message"
            name="text"
            placeholder="Título da Mensagem"
            value={sectionData.text}
            onChange={handleInputChange}
            showCharCounter
            maxLength={24}
            required
          />
        </Col>
        <Col sm={{ size: 2, offset: 0 }} md={{ size: 4, offset: 0 }}>
          <Button
            color="danger"
            onClick={handleWithDeleteSection}
            disabled={isDisabledRemove}
          >
            Excluir Seção
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default CreateSectionList;
