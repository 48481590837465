import styled, { css } from 'styled-components';

export const TextRecoverPassword = styled.div`
  color: #606161;
  margin-top: -5%;
  margin-bottom: 5%;
`;

export const IconEmail = styled.div`
  display: flex;
  align-items: center;
  padding-top: 1rem;
  div {
    color: var(--page-title);
    font-size: 0.8rem;
    line-height: 1rem;
    text-align: right;
  }
  span {
    margin-bottom: -0.4rem;
    font-weight: bold;
    flex-flow: row wrap;
    color: #606161;
    font-size: 1rem;
    line-height: 1.25rem;
    padding-left: 0.5rem;
  }
`;

export const ButtonRecoverPassword = styled.div`
  .buttonBack {
    background: #ffffff;
    border-radius: 5px;
    margin-right: 10%;
  }

  button {
    background: var(--primary);
    border-radius: 5px;
    margin-top: 10%;
    margin-bottom: 15%;
  }
`;

export const ContainerSuccess = styled.div`
  img {
    display: flex;
    text-align: center;
    margin: auto;
  }
  button {
    background: var(--primary);
    border-radius: 5px;
    margin-top: 20px;
    margin-bottom: -20px;
  }
`;

export const ContainerError = styled.div`
  img {
    display: flex;
    text-align: center;
    margin: auto;
  }
  button {
    background: linear-gradient(
      225deg,
      rgba(255, 100, 124, 0.9) 3.44%,
      rgba(253, 175, 187, 0.9) 100%
    );
    border-radius: 5px;
    border: none;
    margin-top: 20px;
    margin-bottom: -20px;
  }
`;
