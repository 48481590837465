import React, { useEffect, useState, useRef } from 'react';

import {
  Label,
  Icon,
  CustomInput,
  Switch,
  Input,
  MultiSelector,
  Button,
} from '@polichat/flamboyant';
import { toast } from 'react-toastify';
import formatPhone from '../../../../../../utils/formatPhone';
import ChannelsService from '../../../../../../services/tools/channels/channels';
import FacebookStatus from '../FacebookStatus/FacebookStatus.component';
import ProfileWabaPhoto from './ProfileWabaPhoto.component';

import DeleteChannelBsp from '../../DeleteChannelBsp';

import ProgressBar from '../../ProgressBar/ProgressBar.component';

import {
  Container,
  Section,
  SectionName,
  SectionContent,
  InputsContainer,
  InputItem,
  MultSelectorItem,
  SwitchContainer,
  ButtonContainer,
  PhoneInputContainer,
  BtnStatusChannelContainer,
} from './Profile.styles';
import { useHistory } from 'react-router-dom';

const Profile = ({ data: dataFromDad }) => {
  const {
    id,
    name,
    phone,
    status,
    display_on_webchat: WidgetDad,
  } = dataFromDad || {};

  const [facebookStatusShow, facebookStatusShowSet] = useState(false);

  // Estados de requisição
  const [sendButtonDisabled, sendButtonDisabledSet] = useState(false);

  const [, setUsers] = useState([]);
  const [usersOptions, usersOptionsSet] = useState([]);
  const [usersSelected, usersSelectedSet] = useState([]);

  // Estados locais para lidar com mudanças nos valores de um input
  const [channelName, channelNameSet] = useState(name || '');
  const [channelPhone] = useState(phone || '');
  const [channelWidget, channelWidgetSet] = useState(WidgetDad === '1');
  const [channelStatus, setChannelStatus] = useState(!!status);

  const history = useHistory();

  useEffect(() => {
    async function fetchUsers() {
      try {
        const { data: users } = await ChannelsService.getAllUsersByCustomer(
          dataFromDad.customer_id
        );
        setUsers(users);

        // buscar todos os usuarios que tem acesso a esse canal
        const { data: usersChannel } = await ChannelsService.getAllUsersChannel(
          dataFromDad.customer_id,
          dataFromDad.id
        );

        //Usado para criar o objeto options que irá alimentar o componente MultiSelector
        if (users && users.length) {
          const _userSelected = [];

          const usersOptions = users.map((user) => {
            const add = {
              value: user.id,
              label: user.name,
            };

            if (Array.isArray(usersChannel)) {
              const _i = usersChannel.findIndex((e) => e.user_id == user.id);
              if (_i >= 0) {
                _userSelected.push(add);
              }
            }

            return add;
          });
          usersOptionsSet(usersOptions);
          usersSelectedSet(_userSelected);
        }
        return;
      } catch (error) {
        console.error(
          'Error in Profile component when getAllUsersByCustomer => ',
          error
        );
        return;
      }
    }

    fetchUsers();
  }, []);

  const handleSendForm = async () => {
    sendButtonDisabledSet(true);
    try {
      const finalSettingsObject = {
        name: channelName,
        status: channelStatus ? '1' : '0',
        display_on_webchat: channelWidget ? '1' : '0',
        users: usersSelected.map((user) => user.value),
      };

      const { data, error } = await ChannelsService.updateProfile({
        customer_id: dataFromDad.customer_id,
        channel_id: dataFromDad.id,
        formData: finalSettingsObject,
      });

      if (data) {
        toast.success('Canal editado com sucesso');
        sendButtonDisabledSet(false);
      } else {
        toast.error(error?.message || 'Erro ao salvar os dados');
        sendButtonDisabledSet(false);
      }

      return;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          'Houve ao salvar canal. Caso o erro persista, entre em contato com o suporte.'
      );
      console.error(
        'Error in Profile Component when handleSendForm. Error => ',
        error
      );
      sendButtonDisabledSet(false);
      return;
    }
  };

  const historyLocationState = history.location?.state;
  useEffect(() => {
    if (
      historyLocationState &&
      Object.keys(historyLocationState).includes('showChannelHealth')
    ) {
      facebookStatusShowSet(true);
    }
  }, [historyLocationState]);

  if (facebookStatusShow) {
    return (
      <FacebookStatus
        channelId={dataFromDad.id}
        customer_id={dataFromDad.customer_id}
        showProfile={() => facebookStatusShowSet(false)}
      />
    );
  }

  return (
    <>
      <ProgressBar currentStep={0} />

      <Container>
        <Section>
          <SectionName>Configurações</SectionName>

          <SectionContent>
            <InputsContainer>
              <InputItem>
                <Label id="channel-name-label" for="channel-name">
                  <Icon
                    icon="poli-icon pi-channels-fill"
                    color="var(--green-polichat)"
                    size="20"
                  />
                  Nome do Canal
                </Label>
                <Input
                  type="text"
                  name="channel-name"
                  id="channel-name"
                  value={channelName}
                  onChange={(e) => {
                    channelNameSet(e.target.value);
                  }}
                  maxLength={50}
                />
                <br />
                <Label id="phone-number-label" for="phone-number">
                  <Icon
                    icon="poli-icon pi-smartphone-fill"
                    color="var(--green-polichat)"
                    size="20"
                  />
                  Telefone com DDD e código de país*
                </Label>
                <PhoneInputContainer>
                  <Input
                    type="text"
                    value={formatPhone(channelPhone)}
                    disabled="true"
                    style={{ borderColor: 'var(--secondary-font-color)' }}
                  />
                  <Button
                    onClick={() => {
                      navigator.clipboard.writeText(phone);
                      toast.success('Telefone copiado com sucesso!');
                    }}
                  >
                    <Icon icon="poli-icon pi-copy-fill" size="30" />
                    Copiar
                  </Button>
                </PhoneInputContainer>
                <div style={{ color: 'var(--danger)', fontSize: '0.7rem' }}>
                  *Não é possível alterar o número de telefone de um canal WABA
                  depois de criado
                </div>
                <br />
                <MultSelectorItem>
                  <Label
                    id="select-users-channel-label"
                    for="select-users-channel"
                  >
                    <Icon
                      icon="poli-icon pi-perfil-fill"
                      color={'var(--green-polichat)'}
                      size="20"
                    />
                    Usuários que poderão utilizar este canal
                  </Label>
                  <MultiSelector
                    name="select-users-channel"
                    id="select-users-channel"
                    options={usersOptions}
                    values={usersSelected}
                    selectedValues={(data) => usersSelectedSet(data)}
                  />
                </MultSelectorItem>
              </InputItem>

              <InputItem>
                <ProfileWabaPhoto channelData={dataFromDad} />
              </InputItem>
            </InputsContainer>
          </SectionContent>

          <Section className="mt-3">
            <SectionContent>
              <InputsContainer>
                <InputItem className="py-3">
                  <CustomInput
                    type="checkbox"
                    id="show-widget-at-webchat"
                    label="Exibir canal no Widget do webchat."
                    checked={channelWidget}
                    onChange={(e) => {
                      channelWidgetSet(e.target.checked);
                    }}
                  />
                </InputItem>
                <InputItem>
                  <Label id="channel-status" for="channel-status">
                    <Icon
                      icon="poli-icon pi-channels-fill"
                      color="var(--green-polichat)"
                      size="20"
                    />
                    Status do Canal
                  </Label>
                  <SwitchContainer>
                    <p>Inativo</p>
                    <Switch
                      color="primary"
                      isChecked={channelStatus}
                      onClick={(e) => {
                        setChannelStatus(e.target.checked);
                      }}
                    />
                    <p>Ativo {!!channelStatus}</p>
                  </SwitchContainer>
                </InputItem>
              </InputsContainer>
            </SectionContent>

            <ButtonContainer style={{ justifyContent: 'space-between' }}>
              <Button
                size="lg"
                color="outline-primary"
                onClick={() => {
                  facebookStatusShowSet(true);
                }}
              >
                Verificar qualidade do canal
              </Button>
              <ButtonContainer style={{ marginTop: '0px' }}>
                <Button
                  gradient="primary"
                  size="lg"
                  onClick={handleSendForm}
                  disabled={sendButtonDisabled}
                >
                  Salvar Dados
                </Button>
                <Button
                  color="danger"
                  size="lg"
                  style={{ width: '9rem' }}
                  onClick={() => {
                    history.push('/tools/channels');
                  }}
                >
                  Cancelar
                </Button>
              </ButtonContainer>
              <DeleteChannelBsp channel={dataFromDad} size={'lg'} />
            </ButtonContainer>
          </Section>
        </Section>
      </Container>
    </>
  );
};

export default Profile;
