import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Controller } from 'react-hook-form';
import {
  FormGroup,
  Row,
  Col,
  Button,
  Label,
  Input,
} from '@polichat/flamboyant';
import { SelectCodeMethod } from '../components/selectCodeMethod';
import { SelectCodeLanguage } from '../components/selectCodeLanguage';
import { useLocalRequestCodeForm } from './useLocalRequestCodeForm';
import { useLocalVerifyCodeForm } from './useLocalVerifyCodeForm';
import { useMetaService } from './useMetaService';
import { RequestCodeFormValues } from './useLocalRequestCodeForm/types';
import { VerifyCodeFormValues } from './useLocalVerifyCodeForm/types';
import Animation from '../../../common/animation';

export interface StepValidateOTPAndRegisterChannelRef {
  submit: () => void;
}
export interface StepValidateOTPAndRegisterChannelProps {
  title?: string;
  phone_id: number;
  onSuccess: () => void;
  onError: () => void;
  enableNextStep: () => void;
}

export const StepValidateOTPAndRegisterChannel = forwardRef<
  StepValidateOTPAndRegisterChannelRef,
  StepValidateOTPAndRegisterChannelProps
>((props, ref) => {
  const VERIFY_OTP_STATUS = 200;
  const TOO_MANY_REQUESTS = 202;
  // Forms
  const { form: requestCodeForm } = useLocalRequestCodeForm();
  const { form: verifyCodeForm } = useLocalVerifyCodeForm();

  //Service
  const phone_id = props.phone_id;
  const service = useMetaService({ phone_id });

  /**  TIMER  **/
  const TIMER_5_MINUTES = 5 * 60 * 1000; // 5 min em millisegundos
  const [minutesClock, setMinutesClock] = useState('5');
  const [secondsClock, setSecondsClock] = useState('00');

  // Contador regressivo
  let clockInterval: null | NodeJS.Timeout;

  function startClock() {
    if (!clockInterval) {
      let timer = TIMER_5_MINUTES / 1000,
        minutes,
        seconds;

      clockInterval = setInterval(() => {
        minutes = parseInt(`${timer / 60}`, 10);
        seconds = parseInt(`${timer % 60}`, 10);

        // Altera o mostrador
        setMinutesClock(minutes < 10 ? `${minutes}` : `${minutes}`);
        setSecondsClock(seconds < 10 ? `0${seconds}` : `${seconds}`);

        if (--timer < 0) {
          stopClock();
        }
      }, 1000);
    }
  }

  function stopClock() {
    // @ts-ignore
    clearInterval(clockInterval);
    clockInterval = null;
    // reset do mostrador
    setMinutesClock('5');
    setSecondsClock('00');
  }

  function startDisableButtonRequestCode() {
    startClock();
    setTimeout(() => {
      stopClock();
      setUserCanTryRequestCodeAgain(true);
    }, TIMER_5_MINUTES);
  }

  const [userCanTryRequestCodeAgain, setUserCanTryRequestCodeAgain] =
    useState(true);
  const [userCanTryVerifyCodeAgain, setUserCanTryVerifyCodeAgain] =
    useState(false);
  const [userCanGoToNextStepMessage, setUserCanGoToNextStepMessage] =
    useState('');
  const [userTryNextStepAgainMessage, setUserTryNextStepAgainMessage] =
    useState('');

  // ---------------------------------------------
  // Functions

  // REQUEST CODE
  function handleRequestCodeSubmit(data: RequestCodeFormValues) {
    service.code(
      data,
      handleRequestCodeResponseSuccess,
      handleRequestCodeResponseError
    );
  }

  function handleRequestCodeResponseSuccess(status: number) {
    if (status === TOO_MANY_REQUESTS) {
      requestCodeForm.setError('code_method', {
        type: 'custom',
        message:
          'Não foi possível enviar o código. Aguarde para solicitar novo código!',
      });
      setUserCanTryVerifyCodeAgain(false);
    }

    if (status === VERIFY_OTP_STATUS) {
      setUserCanTryVerifyCodeAgain(true);
    }

    setUserCanTryRequestCodeAgain(false);
    startDisableButtonRequestCode();
  }

  function handleRequestCodeResponseError(message?: string) {
    props.onError();
    // @ts-ignore
    requestCodeForm.setError('code_method', {
      type: 'custom',
      message: message
        ? message
        : 'Erro ao solicitar código de validação. Aguarde para solicitar novo código!',
    });
  }

  // VERIFY CODE
  function handleVerifyCodeResponseError(message?: string) {
    props.onError();
    // @ts-ignore
    verifyCodeForm.setError('confirmation_code', {
      type: 'custom',
      message: message
        ? message
        : 'Erro ao verificar código de validação. Solicite um novo código!',
    });
  }

  function handleVerifyCodeResponseSuccess() {
    setUserCanTryVerifyCodeAgain(false);
    props.enableNextStep();
    setUserCanGoToNextStepMessage(
      'Verificação realizada com sucesso! Siga para o próximo passo.'
    );
  }

  function handleVerifyCodeSubmit(data: VerifyCodeFormValues) {
    service.verify(
      data,
      handleVerifyCodeResponseSuccess,
      handleVerifyCodeResponseError
    );
  }

  // REGISTER
  function handleRegisterMigrateChannel() {
    service.register(
      handleRegisterMigrateResponseSuccess,
      handleRegisterMigrateResponseError
    );
  }

  function handleRegisterMigrateResponseSuccess() {
    setUserTryNextStepAgainMessage('');
    props.onSuccess();
  }

  function handleRegisterMigrateResponseError(message?: string) {
    props.onError();
    setUserTryNextStepAgainMessage(
      message
        ? message
        : 'Erro ao finalizar a migração, clique em próximo novamente!'
    );
  }

  useImperativeHandle(ref, () => ({
    submit: () => handleRegisterMigrateChannel(),
  }));

  // ---------------------------------------------
  // Effects
  useEffect(() => {
    return () => stopClock();
  }, []);
  // ---------------------------------------------
  // Transformations
  // ---------------------------------------------
  // Render

  return (
    <>
      {/* REQUEST CODE FORM */}
      <div style={{ margin: '1rem' }}>
        <h3 style={{ fontWeight: 'bold' }}>
          {props.title ?? `Ótimo! Agora precisamos registrar o seu telefone.`}
        </h3>
        <>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Controller
                  control={requestCodeForm.control}
                  name="code_method"
                  render={({ field, fieldState }) => (
                    <>
                      <SelectCodeMethod
                        value={field.value}
                        onChange={field.onChange}
                        disabled={!userCanTryRequestCodeAgain}
                      />
                      <div style={{ color: 'red', marginTop: '0.5rem' }}>
                        <span>
                          {fieldState?.error && fieldState?.error?.message}
                        </span>
                      </div>
                    </>
                  )}
                />
              </FormGroup>
            </Col>

            <Col md={4} className={'center justify-content-center'}>
              <div>
                <br />
                <br />
              </div>
              <FormGroup>
                <Button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    requestCodeForm.handleSubmit(handleRequestCodeSubmit)();
                  }}
                  disabled={!userCanTryRequestCodeAgain}
                  color="primary"
                >
                  {userCanTryRequestCodeAgain
                    ? 'Solicitar código'
                    : `Reenviar código ${minutesClock}:${secondsClock}`}
                </Button>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Controller
                  control={requestCodeForm.control}
                  name="language"
                  render={({ field, fieldState }) => (
                    <>
                      <SelectCodeLanguage
                        value={field.value}
                        onChange={field.onChange}
                        disabled={!userCanTryRequestCodeAgain}
                      />
                      <div style={{ color: 'red', marginTop: '0.5rem' }}>
                        <span>
                          {fieldState?.error && fieldState?.error?.message}
                        </span>
                      </div>
                    </>
                  )}
                />
              </FormGroup>
            </Col>
          </Row>
        </>
      </div>
      {/* VERIFY CODE FORM */}
      <div style={{ margin: '1rem' }}>
        <h3 style={{ fontWeight: 'bold' }}>Agora confirme o código recebido</h3>
        <>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Controller
                  control={verifyCodeForm.control}
                  // @ts-ignore
                  name="confirmation_code"
                  render={({ field, fieldState }) => (
                    <>
                      <Label for="input_confirm_code">
                        Confirmação de código
                      </Label>
                      <Input
                        id="input_confirm_code"
                        type="number"
                        onChange={field.onChange}
                        value={field.value}
                        error={fieldState?.error}
                        disabled={!userCanTryVerifyCodeAgain}
                      />
                      <div style={{ color: 'red', marginTop: '0.5rem' }}>
                        <span>
                          {fieldState?.error && fieldState?.error?.message}
                        </span>
                      </div>
                      <div
                        style={{
                          color: 'var(--success-75)',
                          marginTop: '0.5rem',
                        }}
                      >
                        <span>{userCanGoToNextStepMessage}</span>
                      </div>
                    </>
                  )}
                />
              </FormGroup>
            </Col>
            <Col md={4} className={'center justify-content-center'}>
              <FormGroup>
                <Button
                  onClick={() => {
                    // @ts-ignore
                    verifyCodeForm.handleSubmit(handleVerifyCodeSubmit)();
                  }}
                  color="primary"
                  disabled={!userCanTryVerifyCodeAgain}
                >
                  Verificar código
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </>
        <div>
          <Row>
            <Col></Col>
            <Col xs="auto">
              {service.loadingRegister && (
                <>
                  <Animation icon="loading" size="tinyRem" />
                  <span>Registrando a migração do seu canal!</span>
                </>
              )}

              {!service.loadingRegister && (
                <div
                  style={{
                    color: 'var(--danger)',
                    marginTop: '0.5rem',
                  }}
                >
                  <span>{userTryNextStepAgainMessage}</span>
                </div>
              )}
            </Col>
            <Col></Col>
          </Row>
        </div>
      </div>
    </>
  );
});
