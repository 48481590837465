import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, FormGroup, Label, CustomInput } from '@polichat/flamboyant';

import WabaService from '../../../../../../services/tools/template-waba/template-waba'

function SelectCategoryWaba({ onUpdate , initialValue , isEdit }) {
  const customer_id = useSelector((state) => state.general.current_customer_id);
  const [categoriesList, setCategoriesList] = useState([]);
  const [categoryType, setCategoryType] = useState({});

  useEffect(()=>{
    async function fetchCategoriesWaba() {
      const result = await WabaService.getWabaCategories({ customer_id });
      if(result){
        setCategoriesList(result);
        setCategoryType({
          category: initialValue !== 'none' ? initialValue : result[0].id
        });
        initialValue === 'none' && handleCategoryData({
          category: result[0].id
        })
      }
    }
    fetchCategoriesWaba();
  },[])

  const handleInputsChange = ({target: {name, value}}) => {
    setCategoryType({
      [name]: Number(value),
    });
    handleCategoryData({
      [name]: Number(value),
    });
  }

  const handleCategoryData = (data) => {
    onUpdate(data);
  }

  const utility = categoriesList.filter(category => category.group === 'UTILITY')
  const marketing = categoriesList.filter(category => category.group === 'MARKETING')
  const authentication = categoriesList.filter(category => category.group === 'AUTHENTICATION')

  return (
    <Col sm={12} md={6}>
      <FormGroup>
        <Label>Categoria:</Label>
        <CustomInput
          type="select"
          id="waba-category"
          name="category"
          value={categoryType.category}
          onChange={handleInputsChange}
          required
          disabled={isEdit}
        >
          <optgroup label="Utilitários">
          {utility?.map(category => (
              <option key={category.id} value={category.id}>
                {category.title}
              </option>
          ))}
          </optgroup>
          <optgroup label="Marketing">
          {marketing?.map(category => (
              <option key={category.id} value={category.id}>
                {category.title}
              </option>
          ))}
          </optgroup>
          <optgroup label="Autenticação">
          {authentication?.map(category => (
              <option key={category.id} value={category.id}>
                {category.title}
              </option>
          ))}
          </optgroup>
        </CustomInput>
      </FormGroup>
    </Col>
  );
}

export default SelectCategoryWaba;
