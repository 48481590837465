import { Button } from '@polichat/flamboyant';
import { useCallback } from 'react';
import { Info } from '@poli-ink/react';
import getUrlViewCrm from '../../../../../components/tools/integrations/crmRequests/getUrlViewCrm';
import { useWhiteLabel } from 'src/hooks/useWhiteLabel';

function ValidationPage(props) {
  const isWL = useWhiteLabel().isWl;
  const hasCrm = props?.permissions?.customerPermission;
  const hasPermission = props?.permissions?.userPermission;
  const urlWhatsapp = 'https://api.whatsapp.com/send/?phone=556299800123&text=Quero+conhecer+o+Poli+Flow%21&type=phone_number&app_absent=0';
  const redirectToAddCrmClient = useCallback(() => {
    window.location.href = urlWhatsapp;
  }, [urlWhatsapp]);

  const getUrl = async () => {
    const response = await getUrlViewCrm();

    if (response && response.data) {
      return response.data;
    }
  };
  const getFlowUrl = useCallback(async () => {
    const url = await getUrl();
    window.location.href = url;
  }, []);

  if (!hasCrm && !isWL) {
    return (
      <>
        <span>
          <strong>Oportunidades & Tickets</strong>
        </span>
        <div>
          <span>
            Agende <strong>mensagens automáticas</strong> de clientes, <strong>disparos</strong> em massa e crie <strong>funis</strong> para gestão à vista facilmente!
          </span>
          <br />
          <br />
          <span>Conheça agora todas essas funcionalidades! 👇</span>
          <Button className="btn-new-crm" onClick={redirectToAddCrmClient}>
            Quero conhecer o Poli Flow
          </Button>
        </div>
      </>
    );
  }

  if (!hasPermission && !isWL) {
    return (
      <>
        <h>
          <strong>Oportunidades & Tickets</strong>
        </h>
        <div className="info-user-crm">
          <span>
            <Info /> <strong>Comece agora a usar o Poli Flow!</strong>
          </span>
          <br />
          <span>
            As funcionalidades de agendamento de mensagens já estão liberadas para a sua empresa.
            Explore e aproveite todas essas novidades para otimizar seu dia-a-dia.
          </span>
          <Button
            className="btn-new-crm"
            onClick={() => getFlowUrl()}
          >
            Usar o Poli Flow
          </Button>
        </div>
      </>
    );
  }

  return <></>;
}

export default ValidationPage;