import { put, all, call, takeLatest } from 'redux-saga/effects';
import {
  ActionTypes,
  createProductSuccess,
  createProductFailure,
  updateProductSuccess,
  updateProductFailure,
  getCategoriesSuccess,
  getCategoriesFailure,
} from './actions';

import ProductService from '../../../../services/shopping/product';

function* createProductRequest(event) {
  try {
    yield call(ProductService.createProduct, event.data);

    yield put(createProductSuccess());
  } catch (e) {
    yield put(createProductFailure(e));
  }
}

function* updateProductRequest(event) {
  try {
    yield call(ProductService.updateProduct, event.id, event.data);

    yield put(updateProductSuccess());
  } catch (e) {
    yield put(updateProductFailure(e));
  }
}

function* getCategoriesRequest(event) {
  try {
    let result = yield call(ProductService.getCategories, event.params);

    let data = result.data.data;

    yield put(getCategoriesSuccess(data));
  } catch (e) {
    yield put(getCategoriesFailure(e));
  }
}

export default all([
  takeLatest(ActionTypes.CREATE_PRODUCT, createProductRequest),
  takeLatest(ActionTypes.UPDATE_PRODUCT, updateProductRequest),
  takeLatest(ActionTypes.GET_CATEGORIES, getCategoriesRequest),
]);
