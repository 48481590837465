import styled from 'styled-components';

export const RecentContainer = styled.div`
  border-radius: 14px;
  background-color: var(--light);
  margin-bottom: 0.2rem;

  height: 139px;
  padding: 0.5rem 2rem;

  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;

  & div {
    display: inline-flex;
    align-items: baseline;
    gap: 0.5rem;

    margin-bottom: 0.5rem;

    & h6 {
      font-weight: 600;
      margin: 0;
    }

    & span {
      font-weight: bold;
      font-size: 0.75rem;
    }
  }

  & p {
    font-weight: 400;
    text-align: left;
    max-width: 350px;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const NumbersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & h5 {
    font-weight: bold;
    font-size: 1.25rem;
  }

  & span {
    font-size: 1.5rem;
  }
`;

export const ImageWrapper = styled.div`
  border: 1px solid var(--primary-font-color);
  border-radius: 20px;
  width: 100px;
  height: 100px;
  padding: 5px;

  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: center;

  & img {
    border-radius: 20px;
    object-fit: cover;
    width: 98px;
    height: 98px;
  }
`;

export const VideoWrapper = styled.div`
  border: 1px solid var(--primary-font-color);
  border-radius: 20px;
  width: 100px;
  height: 100px;
  padding: 5px;

  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: center;

  & video {
    border-radius: 20px;
    object-fit: cover;
    width: 98px;
    height: 98px;
  }
`;

export const EmptyWrapper = styled.div`
  border: 1px solid var(--primary-font-color);
  border-radius: 20px;
  width: 100px;
  height: 100px;
  padding: 5px;

  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: center;
`;
