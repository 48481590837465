import { Button } from '@polichat/flamboyant';
import { Add } from '@poli-ink/react';

interface AddQuickMessageButtonProps {
  handleOpen: () => void;
}
export function AddQuickMessageButton({
  handleOpen,
}: Readonly<AddQuickMessageButtonProps>) {
  return (
    <Button onClick={handleOpen}>
      <Add width={16} />
    </Button>
  );
}
