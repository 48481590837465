import React from 'react';
import {
  FileDetailsContainer,
  FileIconContainer,
  FilenameContainer,
  Iframe,
  IframeContainer,
  TemplateDocumentHeader,
} from './DocumentHeaderPreview.styles';
import { Icon } from '@polichat/flamboyant';

export default function DocumentHeaderPreview({ header }) {
  const knownFileTypes = {
    pdf: 'poli-icon pi-pdf-file-fill',
    zip: 'poli-icon pi-zip-fill',
    '7z': 'poli-icon pi-zip-fill',
    rar: 'poli-icon pi-zip-fill',
    mp3: 'poli-icon pi-song-file-fill',
    wav: 'poli-icon pi-song-file-fill',
    aiff: 'poli-icon pi-song-file-fill',
    aac: 'poli-icon pi-song-file-fill',
    ogg: 'poli-icon pi-song-file-fill',
    wma: 'poli-icon pi-song-file-fill',
    flac: 'poli-icon pi-song-file-fill',
    alac: 'poli-icon pi-song-file-fill',
    doc: 'poli-icon pi-word-file-fill',
    docx: 'poli-icon pi-word-file-fill',
    docm: 'poli-icon pi-word-file-fill',
    dot: 'poli-icon pi-word-file-fill',
    xlsx: 'poli-icon pi-excel-file-fill',
    xlsm: 'poli-icon pi-excel-file-fill',
  };
  const { fileName, fileSize } = header.fileData;
  const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1);

  return (
    <>
      <TemplateDocumentHeader>
        <FileIconContainer>
          <Icon
            icon={knownFileTypes[fileExtension] ?? 'poli-icon pi-file-fill'}
            size="50"
          />
        </FileIconContainer>
        <FilenameContainer title={fileName}>
          <div>{fileName}</div>
          <FileDetailsContainer>
            {fileExtension.toUpperCase() + ' • '}
            {(fileSize / 1024).toFixed(0) + ' kB'}
          </FileDetailsContainer>
        </FilenameContainer>
      </TemplateDocumentHeader>
    </>
  );
}
