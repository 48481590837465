import { useWhiteLabel } from "../../../hooks/useWhiteLabel";

function ReportV2Img() {
  let color1 = '#0000c8';
  const whitelabel = useWhiteLabel();
  if (whitelabel.isWl) {
    if (whitelabel.cssPathWl.includes('chatshub')) {
      color1 = '#FF7038';
    }
    if (whitelabel.cssPathWl.includes('auvo')) {
      color1 = '#6d26c2';
    }
    if (whitelabel.cssPathWl.includes('nectarcrm')) {
      color1 = '#ffd55b';
    }
    if (whitelabel.cssPathWl.includes('aqbank')) {
      color1 = '#D76F21';
    }
    if (whitelabel.cssPathWl.includes('usecredituz')) {
      color1 = '#000000';
    }
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={392}
      width={392}
      viewBox="0 0 500 500"
      className="img-fluid"
    >
      <g id="freepik--Floor--inject-136">
        <path
          d="M98,388.25c87.46,45.69,229.27,45.69,316.73,0s87.46-119.77,0-165.47S185.5,177.09,98,222.78,10.58,342.56,98,388.25Z"
          fill="#f5f5f5"
        ></path>
      </g>
      <g id="freepik--filing-cabinets--inject-136">
        <polygon
          points="73.62 313.32 45.19 296.91 45.19 235.93 73.62 252.35 73.62 313.32"
          fill="#e0e0e0"
        ></polygon>
        <polygon
          points="73.62 252.35 114.24 228.9 114.24 289.87 73.62 313.32 73.62 252.35"
          fill="#ebebeb"
        ></polygon>
        <polygon
          points="114.24 228.9 73.62 252.35 45.19 235.93 85.81 212.48 114.24 228.9"
          fill="#f5f5f5"
        ></polygon>
        <polygon
          points="77.69 254.69 110.18 235.93 110.93 236.36 110.93 258.95 78.43 277.71 77.68 277.28 77.69 254.69"
          fill="#e0e0e0"
        ></polygon>
        <polygon
          points="78.43 255.13 110.93 236.36 110.93 258.95 78.43 277.71 78.43 255.13"
          fill="#f5f5f5"
        ></polygon>
        <path
          d="M91.17,251.6l5.53-3.19c.71-.42,1.29-.23,1.29.42a2.59,2.59,0,0,1-1.29,1.92l-5.53,3.19c-.72.42-1.3.23-1.3-.42A2.58,2.58,0,0,1,91.17,251.6Z"
          fill="#e0e0e0"
        ></path>
        <polygon
          points="76.94 283.27 109.43 264.51 109.43 287.1 110.18 287.53 77.68 306.29 76.94 305.86 76.94 283.27"
          fill="#e0e0e0"
        ></polygon>
        <polygon
          points="77.68 283.7 110.18 264.94 110.18 287.53 77.68 306.29 77.68 283.7"
          fill="#f5f5f5"
        ></polygon>
        <path
          d="M90.42,280.18,96,277c.72-.42,1.3-.23,1.3.42a2.58,2.58,0,0,1-1.3,1.92l-5.53,3.19c-.72.42-1.3.23-1.3-.42A2.58,2.58,0,0,1,90.42,280.18Z"
          fill="#e0e0e0"
        ></path>
        <polygon
          points="73.62 248.83 45.19 232.41 45.19 171.44 73.62 187.85 73.62 248.83"
          fill="#e0e0e0"
        ></polygon>
        <polygon
          points="73.62 187.85 114.24 164.4 114.24 225.38 73.62 248.83 73.62 187.85"
          fill="#ebebeb"
        ></polygon>
        <polygon
          points="114.24 164.4 73.62 187.85 45.19 171.44 85.81 147.99 114.24 164.4"
          fill="#f5f5f5"
        ></polygon>
        <polygon
          points="77.68 190.2 110.18 171.44 110.93 171.87 110.93 194.46 78.43 213.22 77.68 212.78 77.68 190.2"
          fill="#e0e0e0"
        ></polygon>
        <path
          d="M91.17,187.1l5.52-3.19c.72-.41,1.3-.22,1.3.43a2.6,2.6,0,0,1-1.3,1.92l-5.52,3.19c-.72.41-1.3.23-1.3-.42A2.61,2.61,0,0,1,91.17,187.1Z"
          fill="#e0e0e0"
        ></path>
        <polygon
          points="76.94 218.78 109.43 200.02 109.43 222.6 110.18 223.03 77.68 241.79 76.94 241.36 76.94 218.78"
          fill="#e0e0e0"
        ></polygon>
        <polygon
          points="77.68 219.21 110.18 200.45 110.18 223.03 77.68 241.79 77.68 219.21"
          fill="#f5f5f5"
        ></polygon>
        <path
          d="M90.42,215.68,96,212.49c.71-.41,1.3-.22,1.3.43a2.64,2.64,0,0,1-1.3,1.92L90.42,218c-.72.41-1.3.22-1.3-.42A2.61,2.61,0,0,1,90.42,215.68Z"
          fill="#e0e0e0"
        ></path>
        <polygon
          points="77.68 190.2 110.18 171.44 110.18 194.02 77.68 212.78 77.68 190.2"
          fill="#e0e0e0"
        ></polygon>
        <polygon
          points="126.43 182.6 108.64 172.33 108.64 192.23 126.43 202.49 126.43 182.6"
          fill="#e0e0e0"
        ></polygon>
        <polygon
          points="89.87 216 89.87 187.85 96.84 183.81 97.99 185.51 118.3 173.78 118.3 199.58 89.87 216"
          fill="#ebebeb"
        ></polygon>
        <polygon
          points="89.87 216 91.9 191.37 120.33 174.96 118.3 199.58 89.87 216"
          fill="#f5f5f5"
        ></polygon>
        <polygon
          points="93.93 201.36 78.43 192.41 78.43 212.31 93.93 221.25 93.93 201.36"
          fill="#ebebeb"
        ></polygon>
        <polygon
          points="93.93 199.58 126.43 180.82 126.43 203.4 93.93 222.16 93.93 199.58"
          fill="#f5f5f5"
        ></polygon>
        <path
          d="M106.67,196.05l5.52-3.19c.72-.41,1.3-.22,1.3.43a2.61,2.61,0,0,1-1.3,1.92l-5.52,3.19c-.72.41-1.3.22-1.3-.42A2.61,2.61,0,0,1,106.67,196.05Z"
          fill="#e0e0e0"
        ></path>
        <polygon
          points="93.93 199.58 92.85 198.96 92.85 221.54 93.93 222.16 93.93 199.58"
          fill="#e0e0e0"
        ></polygon>
        <polygon
          points="92.85 198.96 125.34 180.19 126.43 180.82 93.93 199.58 92.85 198.96"
          fill="#ebebeb"
        ></polygon>
        <polygon
          points="409.5 276.38 381.06 259.96 381.06 198.99 409.5 215.4 409.5 276.38"
          fill="#e0e0e0"
        ></polygon>
        <polygon
          points="409.5 215.4 450.12 191.95 450.12 252.93 409.5 276.38 409.5 215.4"
          fill="#ebebeb"
        ></polygon>
        <polygon
          points="450.12 191.95 409.5 215.4 381.06 198.99 421.69 175.53 450.12 191.95"
          fill="#f5f5f5"
        ></polygon>
        <polygon
          points="413.56 217.75 446.06 198.99 446.81 199.42 446.8 222 414.31 240.76 413.56 240.33 413.56 217.75"
          fill="#e0e0e0"
        ></polygon>
        <polygon
          points="414.31 218.18 446.81 199.42 446.8 222 414.31 240.76 414.31 218.18"
          fill="#f5f5f5"
        ></polygon>
        <path
          d="M427.05,214.65l5.52-3.19c.72-.41,1.3-.22,1.3.42a2.61,2.61,0,0,1-1.3,1.93L427.05,217c-.72.41-1.3.22-1.3-.42A2.61,2.61,0,0,1,427.05,214.65Z"
          fill="#e0e0e0"
        ></path>
        <polygon
          points="412.81 246.33 445.31 227.56 445.31 250.15 446.06 250.58 413.56 269.34 412.81 268.91 412.81 246.33"
          fill="#e0e0e0"
        ></polygon>
        <polygon
          points="413.56 246.76 446.06 228 446.06 250.58 413.56 269.34 413.56 246.76"
          fill="#f5f5f5"
        ></polygon>
        <path
          d="M426.3,243.23l5.53-3.19c.71-.41,1.29-.22,1.29.42a2.63,2.63,0,0,1-1.29,1.93l-5.53,3.19c-.72.41-1.3.22-1.3-.43A2.61,2.61,0,0,1,426.3,243.23Z"
          fill="#e0e0e0"
        ></path>
      </g>
      <g id="freepik--Plants--inject-136">
        <path
          d="M139,340.11s.11-15.86-9.89-27.75c-8.85-10.53-13.56-17.21-10.77-20.29s8.79-3.82,16.88,4.7,8.72,32.12,7.6,37.46Z"
          fill={color1}
        ></path>
        <path
          d="M139,340.11s.11-15.86-9.89-27.75c-8.85-10.53-13.56-17.21-10.77-20.29s8.79-3.82,16.88,4.7,8.72,32.12,7.6,37.46Z"
          style={{ opacity: '0.2' }}
        ></path>
        <path
          d="M140.68,323.06a.5.5,0,0,1-.49-.39c-3.5-16.37-12.48-25.42-17.53-29.36a.5.5,0,0,1-.09-.7.51.51,0,0,1,.71-.09c5.15,4,14.32,13.26,17.89,29.94a.49.49,0,0,1-.38.59Z"
          fill="#fff"
        ></path>
        <path
          d="M138.62,346l-4.07,2.35a7.36,7.36,0,0,0-5.48-4.69c-4.45-1-9.07-.83-12.19-4.69s.68-8.06,0-10.9-1.12-3.66-6.7-6.5-7.78-7.55-3.58-10.74,11-.85,15.12,2c3.66,2.5,7.14,3.16,11.23,3.67s10.75,2.78,9.73,12.28A74.86,74.86,0,0,1,138.62,346Z"
          fill={color1}
        ></path>
        <path
          d="M123,333.52a.51.51,0,0,1-.48-.35.51.51,0,0,1,.33-.63c.36-.11,9-2.85,12.67.09a.5.5,0,0,1-.62.78c-3.24-2.6-11.66.06-11.75.09Z"
          fill="#fff"
        ></path>
        <path
          d="M139.07,345.24a.49.49,0,0,1-.49-.44c-2.23-21.06-27.6-31.05-27.85-31.15a.5.5,0,0,1-.29-.65.51.51,0,0,1,.64-.29c.27.11,26.2,10.31,28.49,32a.5.5,0,0,1-.44.55Z"
          fill="#fff"
        ></path>
        <path
          d="M387.8,332.17c.11-.35,4.4-22.43,0-38.09-4-14.36-9.22-19.66-16.66-20.83s-10.67,4.19-6.72,11.45,13.46,16.89,15.79,33.11c1,6.68,1.76,12.48,1.76,12.48Z"
          fill={color1}
        ></path>
        <path
          d="M387.8,332.17c.11-.35,4.4-22.43,0-38.09-4-14.36-9.22-19.66-16.66-20.83s-10.67,4.19-6.72,11.45,13.46,16.89,15.79,33.11c1,6.68,1.76,12.48,1.76,12.48Z"
          style={{ opacity: '0.2' }}
        ></path>
        <path
          d="M385.29,319.49h0a.5.5,0,0,1-.47-.53c1-17.88-4.84-33.84-15.31-41.67a.5.5,0,0,1-.1-.7.51.51,0,0,1,.7-.1c10.73,8,16.75,24.32,15.7,42.53A.5.5,0,0,1,385.29,319.49Z"
          fill="#fff"
        ></path>
        <path
          d="M399.31,343.67c-.57-4.11,1.79-10.27,7.4-11.73s8.11-4.68,6.09-8.2-1.55-6.75,3.54-8.21,9.55-4,6.62-9.38c-2.13-3.89-2.6-6.41,2.62-14.07,4.33-6.36.79-10.09-4.63-9.39s-8.77,7.16-12.16,8.24-4.17-4.68-8.64-2.1-4.2,9.19-4.34,13.8-.44,5.65-4.48,5.09-8,.1-7.91,9.87c.07,8.38,4.54,18.76,4.54,18.76Z"
          fill={color1}
        ></path>
        <path
          d="M392.22,337.42h-.05a.51.51,0,0,1-.45-.56c2.82-25.19,24.44-46.44,30.08-50.75a.5.5,0,0,1,.61.8c-5.57,4.25-26.91,25.22-29.69,50.06A.51.51,0,0,1,392.22,337.42Z"
          fill="#fff"
        ></path>
        <path
          d="M402.08,310.49a.51.51,0,0,1-.47-.32.52.52,0,0,1,.3-.65c8.06-3,17.2-1.58,17.59-1.52a.5.5,0,0,1,.41.57.52.52,0,0,1-.57.42c-.09,0-9.27-1.44-17.08,1.47A.57.57,0,0,1,402.08,310.49Z"
          fill="#fff"
        ></path>
        <path
          d="M394.9,325.63a.5.5,0,0,1-.37-.17,29.48,29.48,0,0,1-6.59-13.67.5.5,0,0,1,.42-.57.5.5,0,0,1,.57.42,28.9,28.9,0,0,0,6.35,13.15.51.51,0,0,1-.38.84Z"
          fill="#fff"
        ></path>
      </g>
      <g id="freepik--folder-2--inject-136">
        <polygon
          points="138.62 191.23 138.62 346.01 297.04 254.55 297.03 113.84 179.24 181.85 175.17 170.12 138.62 191.23"
          fill={color1}
        ></polygon>
        <polygon
          points="138.62 191.23 138.62 346.01 297.04 254.55 297.03 113.84 179.24 181.85 175.17 170.12 138.62 191.23"
          style={{ opacity: '0.7000000000000001' }}
        ></polygon>
        <polygon
          points="138.62 346.01 146.74 205.3 305.16 113.84 297.04 254.55 138.62 346.01"
          fill="#e0e0e0"
        ></polygon>
        <polygon
          points="138.62 346.01 150.8 207.65 309.22 116.18 297.04 254.55 138.62 346.01"
          fill={color1}
        ></polygon>
        <polygon
          points="138.62 346.01 150.8 207.65 309.22 116.18 297.04 254.55 138.62 346.01"
          style={{ opacity: '0.7000000000000001' }}
        ></polygon>
      </g>
      <g id="freepik--folder-1--inject-136">
        <polygon
          points="162.99 205.3 162.99 360.09 321.41 268.62 321.41 127.91 203.61 195.92 199.55 184.19 162.99 205.3"
          fill={color1}
        ></polygon>
        <polygon
          points="162.99 205.3 162.99 360.09 321.41 268.62 321.41 127.91 203.61 195.92 199.55 184.19 162.99 205.3"
          style={{ opacity: '0.5' }}
        ></polygon>
        <polygon
          points="162.99 360.09 171.11 219.37 329.53 127.91 321.41 268.62 162.99 360.09"
          fill="#e0e0e0"
        ></polygon>
        <polygon
          points="162.99 360.09 175.17 221.72 333.59 130.25 321.41 268.62 162.99 360.09"
          fill={color1}
        ></polygon>
        <polygon
          points="162.99 360.09 175.17 221.72 333.59 130.25 321.41 268.62 162.99 360.09"
          style={{ opacity: '0.5' }}
        ></polygon>
      </g>
      <g id="freepik--Documents--inject-136">
        <path
          d="M297,409.34c-28.44-16.42-54.82-34.27-66.86-55.21L266.74,333Z"
          fill="#e0e0e0"
        ></path>
        <polygon
          points="191.42 221.72 191.42 376.5 349.84 285.04 349.84 144.33 232.04 212.34 227.98 200.61 191.42 221.72"
          fill={color1}
        ></polygon>
        <polygon
          points="191.42 221.72 191.42 376.5 349.84 285.04 349.84 144.33 232.04 212.34 227.98 200.61 191.42 221.72"
          style={{ opacity: '0.2' }}
        ></polygon>
        <path
          d="M237.06,267.48c-.4,0-.46-.46-.46-.73v-132a3.32,3.32,0,0,1,1.48-2.56l114.34-66a1.06,1.06,0,0,1,.52-.16c.4,0,.46.46.46.73v132a3.33,3.33,0,0,1-1.48,2.57l-114.34,66A1.09,1.09,0,0,1,237.06,267.48Z"
          fill="#fff"
        ></path>
        <path
          d="M352.88,66.49a1,1,0,0,1,0,.22v132a2.87,2.87,0,0,1-1.23,2.14l-114.34,66-.2.09a.61.61,0,0,1,0-.22v-132a2.87,2.87,0,0,1,1.23-2.13l114.34-66a.56.56,0,0,1,.21-.09m.06-1a1.56,1.56,0,0,0-.77.23l-114.34,66a3.85,3.85,0,0,0-1.73,3v132c0,.78.39,1.23,1,1.23a1.54,1.54,0,0,0,.77-.23l114.34-66a3.81,3.81,0,0,0,1.73-3v-132c0-.78-.39-1.24-1-1.23Z"
          fill={color1}
        ></path>
        <path
          d="M352.88,66.49a1,1,0,0,1,0,.22v132a2.87,2.87,0,0,1-1.23,2.14l-114.34,66-.2.09a.61.61,0,0,1,0-.22v-132a2.87,2.87,0,0,1,1.23-2.13l114.34-66a.56.56,0,0,1,.21-.09m.06-1a1.56,1.56,0,0,0-.77.23l-114.34,66a3.85,3.85,0,0,0-1.73,3v132c0,.78.39,1.23,1,1.23a1.54,1.54,0,0,0,.77-.23l114.34-66a3.81,3.81,0,0,0,1.73-3v-132c0-.78-.39-1.24-1-1.23Z"
          style={{ fill: '#fff', opacity: '0.7000000000000001' }}
        ></path>
        <polygon
          points="248.29 139.76 288.91 116.31 288.91 163.21 248.29 186.66 248.29 139.76"
          fill={color1}
        ></polygon>
        <polygon
          points="248.29 139.76 288.91 116.31 288.91 163.21 248.29 186.66 248.29 139.76"
          style={{ fill: '#fff', opacity: '0.8' }}
        ></polygon>
        <path
          d="M282.82,128V142.1l-1.28.71V138.5c0-2.38-1.93-3.19-4.33-1.81L260,146.64c-2.39,1.38-4.33,4.43-4.33,6.81v4.31l-1.27.76V144.45Z"
          fill="#fff"
        ></path>
        <path
          d="M275,163.9c-2.08,1.2-3.77-.06-3.77-2.83V138.5L266,141.34v22.75c0,2.76-1.69,6-3.77,7.17l-3.78,2.18v1.5l20.31-11.73v-1.49Z"
          fill="#fff"
        ></path>
        <path
          d="M297,114c0,1.3,1.17,1.67,2.6.85L339.12,92a5.22,5.22,0,0,0,2.6-3.85c0-1.29-1.17-1.67-2.6-.84l-39.49,22.8A5.2,5.2,0,0,0,297,114Z"
          fill="#455a64"
        ></path>
        <path
          d="M297,128c0,1.3,1.17,1.68,2.6.85l39.49-22.8a5.22,5.22,0,0,0,2.6-3.85c0-1.29-1.17-1.67-2.6-.84l-39.49,22.8A5.22,5.22,0,0,0,297,128Z"
          fill="#455a64"
        ></path>
        <path
          d="M297,142.1c0,1.3,1.17,1.68,2.6.85l39.49-22.8a5.22,5.22,0,0,0,2.6-3.84c0-1.3-1.17-1.68-2.6-.85l-39.49,22.8A5.22,5.22,0,0,0,297,142.1Z"
          fill="#455a64"
        ></path>
        <path
          d="M297,156.17c0,1.3,1.17,1.68,2.6.85l39.49-22.8a5.22,5.22,0,0,0,2.6-3.84c0-1.3-1.17-1.68-2.6-.85l-39.49,22.8A5.22,5.22,0,0,0,297,156.17Z"
          fill="#455a64"
        ></path>
        <path
          d="M248.29,198.39c0,1.3,1.16,1.68,2.6.85l88.23-51a5.2,5.2,0,0,0,2.6-3.84c0-1.3-1.17-1.67-2.6-.85l-88.23,51A5.19,5.19,0,0,0,248.29,198.39Z"
          fill="#455a64"
        ></path>
        <path
          d="M248.29,212.46c0,1.3,1.16,1.68,2.6.85l88.23-50.94a5.22,5.22,0,0,0,2.6-3.85c0-1.29-1.17-1.67-2.6-.84l-88.23,50.94A5.19,5.19,0,0,0,248.29,212.46Z"
          fill="#455a64"
        ></path>
        <path
          d="M248.29,226.54c0,1.29,1.16,1.67,2.6.84l88.23-50.94a5.22,5.22,0,0,0,2.6-3.85c0-1.29-1.17-1.67-2.6-.84l-88.23,50.94A5.19,5.19,0,0,0,248.29,226.54Z"
          fill="#455a64"
        ></path>
        <polygon
          points="191.42 376.5 219.85 252.21 378.27 160.74 349.84 285.04 191.42 376.5"
          fill={color1}
        ></polygon>
        <polygon
          points="230.18 261.94 230.18 354.13 266.74 333.02 266.74 240.84 230.18 261.94"
          fill={color1}
        ></polygon>
        <polygon
          points="230.18 261.94 230.18 354.13 266.74 333.02 266.74 240.84 230.18 261.94"
          style={{ opacity: '0.2' }}
        ></polygon>
        <path
          d="M297.07,408.74c-3.11-2.32-33.09-25.23-48.33-58-16.22-34.84-16.2-75.48-16.2-90.77v-.48l108.68-62.74c0,15.43,0,56.13,16.29,91.09,14.89,32,43.72,54.62,48.29,58.08Z"
          fill="#fff"
        ></path>
        <path
          d="M340.72,197.65c0,16.08.31,56,16.34,90.44,14.39,30.9,41.75,53.12,47.84,57.81L297.1,408.14c-4.21-3.17-33.06-25.7-47.9-57.57C233,315.83,233,275.27,233,260v-.19l107.68-62.17m1-1.73L232,259.24c0,14.39-.3,56.2,16.25,91.75C264.54,385.88,297,409.34,297,409.34L406.71,346s-32.5-23.46-48.74-58.35c-16.56-35.55-16.25-77.36-16.25-91.75Z"
          fill={color1}
        ></path>
        <path
          d="M340.72,197.65c0,16.08.31,56,16.34,90.44,14.39,30.9,41.75,53.12,47.84,57.81L297.1,408.14c-4.21-3.17-33.06-25.7-47.9-57.57C233,315.83,233,275.27,233,260v-.19l107.68-62.17m1-1.73L232,259.24c0,14.39-.3,56.2,16.25,91.75C264.54,385.88,297,409.34,297,409.34L406.71,346s-32.5-23.46-48.74-58.35c-16.56-35.55-16.25-77.36-16.25-91.75Z"
          style={{ fill: '#fff', opacity: '0.7000000000000001' }}
        ></path>
        <path
          d="M329.31,217.15l-34.68,20a4.92,4.92,0,0,0-2.52,3.79c.05,1.27,1.26,1.63,2.69.8l34.69-20A4.92,4.92,0,0,0,332,218C331.94,216.68,330.75,216.32,329.31,217.15Z"
          fill="#455a64"
        ></path>
        <path
          d="M330.05,230.8l-34.68,20a4.62,4.62,0,0,0-2.42,3.74c.11,1.23,1.37,1.56,2.8.73l34.69-20a4.56,4.56,0,0,0,2.39-3.73C332.73,230.3,331.49,230,330.05,230.8Z"
          fill="#455a64"
        ></path>
        <path
          d="M331.44,244.07l-34.69,20c-1.43.83-2.47,2.48-2.3,3.67s1.48,1.5,2.92.67l34.69-20c1.43-.83,2.45-2.47,2.27-3.66S332.87,243.24,331.44,244.07Z"
          fill="#455a64"
        ></path>
        <path
          d="M333.52,256.94l-34.69,20c-1.43.83-2.42,2.45-2.18,3.61s1.6,1.42,3,.59l34.68-20c1.44-.83,2.4-2.44,2.16-3.6S335,256.11,333.52,256.94Z"
          fill="#455a64"
        ></path>
        <path
          d="M336.34,269.38l-34.69,20c-1.43.83-2.36,2.41-2,3.52s1.73,1.36,3.16.53l34.69-20c1.44-.83,2.34-2.4,2-3.52S337.77,268.55,336.34,269.38Z"
          fill="#455a64"
        ></path>
        <path
          d="M340,281.37l-84.54,48.81c-1.43.83-2.3,2.37-1.91,3.44s1.88,1.27,3.31.44l84.54-48.81c1.44-.83,2.27-2.36,1.88-3.44S341.38,280.54,340,281.37Z"
          fill="#455a64"
        ></path>
        <path
          d="M344.45,292.82l-84.53,48.81c-1.44.83-2.23,2.33-1.75,3.35s2,1.18,3.46.35l84.54-48.81c1.44-.83,2.21-2.33,1.73-3.35S345.89,292,344.45,292.82Z"
          fill="#455a64"
        ></path>
        <path
          d="M350,303.73l-84.54,48.81c-1.44.83-2.15,2.29-1.58,3.26s2.2,1.07,3.64.24L352,307.23c1.43-.82,2.12-2.27,1.55-3.24S351.4,302.9,350,303.73Z"
          fill="#455a64"
        ></path>
        <path
          d="M356.47,314.05l-84.53,48.8c-1.44.83-2.08,2.26-1.43,3.17s2.36,1,3.8.16l84.53-48.81c1.44-.83,2.06-2.24,1.41-3.16S357.91,313.22,356.47,314.05Z"
          fill="#455a64"
        ></path>
        <path
          d="M363.85,323.86l-84.54,48.8c-1.43.83-2,2.22-1.28,3.09s2.49.91,3.93.08L366.5,327c1.43-.83,2-2.2,1.26-3.07S365.29,323,363.85,323.86Z"
          fill="#455a64"
        ></path>
        <path
          d="M372,333.2,287.49,382c-1.44.83-2,2.19-1.16,3s2.62.83,4.05,0l84.54-48.81c1.44-.83,1.94-2.17,1.14-3S373.46,332.38,372,333.2Z"
          fill="#455a64"
        ></path>
        <path
          d="M381,342.13l-84.54,48.8c-1.43.83-1.9,2.16-1,2.95s2.74.76,4.18-.07L384.11,345c1.43-.83,1.88-2.13,1-2.93S382.4,341.3,381,342.13Z"
          fill="#455a64"
        ></path>
        <path
          d="M282.06,244.44l-39.88,23c.44,14.45,2.09,35.29,8.13,56.28l39.89-23C284.15,279.73,282.5,258.88,282.06,244.44Z"
          fill={color1}
        ></path>
        <path
          d="M282.06,244.44l-39.88,23c.44,14.45,2.09,35.29,8.13,56.28l39.89-23C284.15,279.73,282.5,258.88,282.06,244.44Z"
          style={{ fill: '#fff', opacity: '0.8' }}
        ></path>
        <path
          d="M258.44,264.26a6.59,6.59,0,0,0-1.94-5.06l-14.32,8.26c.12,3.82.32,8.09.67,12.69a9.39,9.39,0,0,0,5.41-1C253.89,276.57,258.44,269.88,258.44,264.26Z"
          fill={color1}
        ></path>
        <path
          d="M258.44,264.26a6.59,6.59,0,0,0-1.94-5.06l-14.32,8.26c.12,3.82.32,8.09.67,12.69a9.39,9.39,0,0,0,5.41-1C253.89,276.57,258.44,269.88,258.44,264.26Z"
          style={{ fill: '#fff', opacity: '0.6000000000000001' }}
        ></path>
        <path
          d="M286.81,286.92h0c-2.66-3.35-8.07-13.7-10.38-18.21a1,1,0,0,0-1.85.17,109.75,109.75,0,0,0-4.32,26.25,38,38,0,0,1-8.47-9.42.89.89,0,0,0-1.59.28c-4.36,16.79-2.17,33.31-2.17,33.31h0l32.17-18.58C288.86,296.08,287.75,291.46,286.81,286.92Z"
          fill={color1}
        ></path>
        <path
          d="M286.81,286.92h0c-2.66-3.35-8.07-13.7-10.38-18.21a1,1,0,0,0-1.85.17,109.75,109.75,0,0,0-4.32,26.25,38,38,0,0,1-8.47-9.42.89.89,0,0,0-1.59.28c-4.36,16.79-2.17,33.31-2.17,33.31h0l32.17-18.58C288.86,296.08,287.75,291.46,286.81,286.92Z"
          style={{ fill: '#fff', opacity: '0.6000000000000001' }}
        ></path>
      </g>
    </svg>
  );
}

export default ReportV2Img;