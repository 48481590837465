import React from 'react';

export default (props) => {
  let read = props.read;
  let style = styles.contactName;

  if (!read) {
    style = { ...style, color: 'var(--primary-font-color)' };
  }

  return (
    <span title={props.name}>
      {props.name}
    </span>
  );
};

const styles = {};
