import styled, { css } from 'styled-components';

const dragActive = css`
  border-color: #78e5d5;
`;

const dragReject = css`
  border-color: #e57878;
`;

export const DropContainer = styled.div.attrs({
  className: 'dropzone',
})`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #b9b9b9;
  border-radius: 5px;
  cursor: pointer;
  height: 74%;

  transition: height 0.2s ease;

  ${(props) => props.isDragActive && dragActive};
  ${(props) => props.isDragReject && dragReject};
`;

const messageColors = {
  default: 'var(--secondary-font-color)',
  error: 'var(--red-polichat)',
  success: 'var(--green-polichat)',
};

export const UploadMessage = styled.p`
  display: flex;
  color: ${(props) => messageColors[props.type || 'default']};
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
  font-size: 2rem;
  flex-direction: column;
  color: #b9b9b9;

  & > i {
    font-size: 90px !important;
    color: #b9b9b9 !important;
  }
  & > span {
    margin-top: 20px;
  }
`;
