import { ImageContent } from '../ImageContent';
import EmptyDataImage from '../../../img/empty-data.svg';

export function EmptyData() {
  return (
    <ImageContent
      image={EmptyDataImage}
      title='Você ainda não possui dados para esse período.'
      subTitle='Tente filtrar uma nova data ou aguarde até a atualização do próximo dia.'
    />
  );
}