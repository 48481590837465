import * as Yup from 'yup';

const REQUIRED_MESSAGE = 'Campo requerido.';
const INVALID_MESSAGE = 'Valor inválido.';

function allOrNumber(value: string | undefined)
{
  const regex = /^(all|\d+)$/;

  return regex.test(value || '');
}

export const validationSchema = Yup.object({
  start_date: Yup.string().required(REQUIRED_MESSAGE),
  end_date: Yup.string().required(REQUIRED_MESSAGE),
  department: Yup.object({
    value: Yup.string().test({ test: allOrNumber, message: INVALID_MESSAGE }),
    label: Yup.string()
  }),
  channel: Yup.object({
    value: Yup.string().test({ test: allOrNumber, message: INVALID_MESSAGE }),
    label: Yup.string()
  }),
  operator: Yup.object({
    value: Yup.string().test({ test: allOrNumber, message: INVALID_MESSAGE }),
    label: Yup.string()
  }),
  ad: Yup.object({
    value: Yup.string().test({ test: allOrNumber, message: INVALID_MESSAGE }),
    label: Yup.string()
  }),
  per_page: Yup.number(),
  page: Yup.number()
});
