import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as ShoppingProductsManage from '../../../../store/modules/shopping/products-manage/actions';

import history from '../../../../services/history';

import {
  Col,
  Row,
  Form,
  FormGroup,
  Icon,
  Label,
  Input,
  CustomInput,
  CustomTextArea,
  UncontrolledTooltip,
} from '@polichat/flamboyant';

import Animation from '../../../common/animation';
import PageTitle from '../../common/PageTitle';
import SaveButton from '../../common/SaveButton';
import CancelButton from '../../common/CancelButton';

import { priceMask, priceUnmask } from '../../../../utils/priceMask';

import { toast } from 'react-toastify';

function ProductForm({
  //store values
  props,
  categories,
  isLoadingManage,
  isLoadingCategories,
  error,
  productRequestStatus,
  categoriesRequestStatus,
  //actions
  getCategories,
  createProduct,
  updateProduct,
  resetProductStatus,
  resetCategoriesStatus,
}) {
  //usar o location para acessar o state passado pela rota ao definir tipo de formulário
  const location = useLocation();

  const [formProductType, setFormProductType] = useState(
    location.state?.formProductType || 'add'
  );

  // Estados para armazenar variáveis locais
  const [id, setId] = useState('');
  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const [price, setPrice] = useState('000');
  const [quantity, setQuantity] = useState('');
  const [category_id, setCategoryId] = useState('');
  const [description, setDescription] = useState('');
  const [image_url, setImgUrl] = useState('');

  const productData = {
    code,
    name,
    price,
    quantity,
    category_id,
    description,
    image_url,
  };

  const status = 1;

  useEffect(() => {
    getCategories({ status });
  }, []);

  useEffect(() => {
    if (formProductType === 'edit') {
      let product = location.state?.product;
      if (product) {
        setId(product.id);
        setCode(product.code);
        setName(product.name);
        setPrice(product.price);
        setQuantity(product.quantity);
        setCategoryId(product.category_id);
        setDescription(product.description);
        setImgUrl(product.image_url);
      }
    }
  }, [formProductType, location]);

  if (categoriesRequestStatus && !category_id && categories.length) {
    setCategoryId(categories[0].id);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      image_url &&
      !image_url.substr(-4).match(/(\.jpg|\.png|jpeg)(\?.+)?/gi)
    ) {
      toast.warning(
        <p>
          <h6>Link inválido. 😢</h6>
          Verifique se o seu link esta com
          <strong>.jpg</strong> ou <strong>.png</strong> no final, como no
          exemplo: <br />
          <strong style={{ color: 'var(--warning)' }}>
            https://img.techpowerup.org/200506/mesa-centro.jpg
          </strong>
        </p>,
        { autoClose: 6000 }
      );
      return;
    }
    const regExp = /@/ig
    if(regExp.test(image_url) == true){
        toast.warning(
            <p>
              <h6>Link inválido. 😢</h6>
              Verifique se o seu link esta com caracteres especiais. <br/>
              O seu link não deve conter caracteres especiais como '@' <br/>
              Tente um link como o exemplo a baixo: <br/>
              <strong style={{ color: 'var(--warning)' }}>
                https://img.techpowerup.org/200506/mesa-centro.jpg
              </strong>
            </p>,
            { autoClose: 6000 }
        );
        return;
    }

    if (formProductType === 'add') {
      createProduct(productData);
    }

    if (formProductType === 'edit') {
      updateProduct(id, productData);
    }
  };

  const handleChangePrice = useCallback((e) => {
    let dataPrice = e.currentTarget.value;
    dataPrice = priceUnmask(dataPrice);
    setPrice(dataPrice);
  }, []);

  // Resposta da requisição de cadastro
  useEffect(() => {
    if (productRequestStatus && !isLoadingManage) {
      if (formProductType === 'add') {
        toast.success('Produto cadastrado com sucesso');
      } else if (formProductType === 'edit') {
        toast.success('Produto editado com sucesso');
      }
      history.push({
        pathname: 'products',
        formProductType: null,
      });
      resetProductStatus();
      resetCategoriesStatus();
      getCategories({ status });
    } else if (productRequestStatus === false && !isLoadingManage && error) {
      if (error.message.includes('422')) {
        toast.error('Este código de produto já existe');
      } else {
        toast.error('Algo deu errado!');
      }
    }
  }, [productRequestStatus, isLoadingManage, error]);

  return (
    <div className="shoppingFormContainer">
      {formProductType === 'add' && (
        <div style={{ display: 'flex' }}>
          <PageTitle icon="poli-icon pi-products-line" title="Novo produto" />
          <div id={'tooltip-label-desc'} style={{ width: '30px' }}>
            <Icon
              icon="poli-icon pi-question-fill"
              size={20}
              cursor="pointer"
              color="var(--principal-icons-color)"
              position={'relative'}
              top={'3px'}
              right={'-4px'}
            />
          </div>
          <UncontrolledTooltip color={"red"} placement="top" target={'tooltip-label-desc'}>
            Por limitação do WhatsApp, mensagens de mídia com legenda superior à
            1024 caracteres não são permitidas. Caso não consiga enviar algum
            produto, revise a mensagem e confirme o tamanho do texto.
          </UncontrolledTooltip>
        </div>
      )}
      {formProductType === 'edit' && (
        <PageTitle icon="poli-icon pi-products-line" title="Editar produto" />
      )}
      <Form className="catalogForm" onSubmit={handleSubmit}>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Label for="productCode">Código:</Label>
              <Input
                id="productCode"
                type="text"
                name="code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                required
              />
            </FormGroup>
          </Col>
          <Col lg={6}>
            <FormGroup>
              <Label for="name">Nome do produto:</Label>
              <Input
                id="name"
                type="text"
                name="name"
                value={name}
                required
                onChange={(e) => setName(e.target.value)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Label form="price">Preço: </Label>
              <Input
                id="price"
                type="text"
                name="price"
                value={priceMask(price + '')}
                onChange={handleChangePrice}
                required
              />
            </FormGroup>
          </Col>
          <Col lg={6}>
            <FormGroup>
              <Label form="quantity">Quantidade: </Label>
              <Input
                id="quantity"
                type="number"
                name="quantity"
                value={quantity}
                min="0"
                onChange={(e) => setQuantity(e.target.value)}
                required
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={12}>
            <FormGroup>
              <Label form="image_url" id="tooltip-label-url">
                Link da Foto:{' '}
              </Label>
              <UncontrolledTooltip
                placement="top"
                target={'tooltip-label-url'}
                autohide={false}
              >
                <div>
                  <h5 style={{ fontSize: '16px' }}>Esse campo é opcional.</h5>
                  <p style={{ textAlign: 'left' }}>
                    Insira o link da imagem hospedada. Verifique se o seu link é
                    com <strong>.jpg</strong> ou <strong>.png</strong> no final,
                    por exemplo: <br />
                    <strong>
                      https://img.techpowerup.org/200506/mesa-centro-supreme-preto.jpg
                    </strong>
                  </p>
                  <p style={{ textAlign: 'left' }}>
                    Sugestões de site para hospedar a imagem:
                    <br />*
                    <a href="https://www.techpowerup.org/upload" target="blank">
                      https://www.techpowerup.org/upload
                    </a>
                    <br />*
                    <a href="https://postimages.org/" target="blank">
                      https://postimages.org/
                    </a>
                  </p>
                </div>
              </UncontrolledTooltip>
              <Input
                id="image_url"
                type="url"
                name="image_url"
                value={image_url}
                onChange={(e) => setImgUrl(e.target.value)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={12}>
            <FormGroup>
              <Label for="description">Descrição: </Label>
              <CustomTextArea
                id="description"
                type="textarea"
                name="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={12}>
            <FormGroup>
              <Label for="category">Categoria: </Label>
              {isLoadingCategories ? (
                <Animation
                  icon="chat-loading"
                  top={'50%'}
                  background="rgba(0, 0, 0, 0)"
                />
              ) : categoriesRequestStatus ? (
                categories.length ? (
                  <CustomInput
                    id="category"
                    type="select"
                    name="category"
                    value={category_id}
                    onChange={(e) => {
                      setCategoryId(e.target.value);
                      e.preventDefault();
                    }}
                  >
                    <optgroup label="Categorias">
                      {categories.map((category) => (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      ))}
                    </optgroup>
                  </CustomInput>
                ) : (
                  <p> não há categorias cadastradas </p>
                )
              ) : (
                <CustomInput
                  type="select"
                  name="select"
                  id="productCategory"
                  size="lg"
                  disabled
                >
                  <option>Erro ao carregar</option>
                </CustomInput>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row form className="buttonsCenterContainer">
          <CancelButton
            onClick={() => {
              history.goBack({
                formProductType: null,
              });
            }}
          />{' '}
          <SaveButton title="Salvar Produto" />
        </Row>
      </Form>
    </div>
  );
}

//Vai pegar informações do nosso estado
//e vai mapear em formato de propriedade de componente
const mapStateToProps = (state, ownProps) => ({
  categories: state.shoppingProductsManage?.categories,
  isLoadingCategories: state.shoppingProductsManage?.loadingGetCategories,
  isLoadingManage: state.shoppingProductsManage?.loadingManageProducts,
  error: state.shoppingProductsManage?.error,
  productRequestStatus: state.shoppingProductsManage?.productStatus,
  categoriesRequestStatus: state.shoppingProductsManage?.categoriesStatus,
  props: ownProps,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ShoppingProductsManage, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProductForm);
