import React from 'react';
import { useWhiteLabel } from 'src/hooks/useWhiteLabel';

export function MedalIcon() {
  const whitelabel = useWhiteLabel();
  let color1 = '#0000c8';
  let color2 = '#3e3eff';
  let color3 = '#1616ff';
  let color4 = '#0000e2';
  if (whitelabel.isWl) {
    if (whitelabel.cssPathWl.includes('chatshub')) {
      color1 = '#FF7038';
      color2 = '#fa8e64';
      color3 = '#ff6445';
      color4 = '#EE4B2B';
    }
    if (whitelabel.cssPathWl.includes('auvo')) {
      color1 = '#6d26c2';
      color2 = '#7b3bc8';
      color3 = '#571e9b';
      color4 = '#41186a';
    }
    if (whitelabel.cssPathWl.includes('nectarcrm')) {
      color1 = '#ffd55b';
      color2 = '#ffe086';
      color3 = '#f3c641';
      color4 = '#fec10d';
    }
  }

  return (
    <svg
      width="68"
      height="82"
      className='img-fluid'
      viewBox="0 0 68 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2649 60.1632L0.114502 73.2143L11.8287 71.75L14.7573 82L25.0073 68.8214L25.0542 68.7614C23.0247 66.7128 20.8019 64.8664 18.4181 63.244C15.8057 61.957 13.0748 60.926 10.2649 60.1632Z"
        fill={`${color1}`}
      />
      <path
        d="M57.3209 60.1632C54.511 60.9246 51.78 61.9541 49.1678 63.2382C46.7839 64.8591 44.5627 66.707 42.5332 68.7556L52.8285 82L55.7571 71.75L67.4715 73.2143L57.3209 60.1632Z"
        fill={`${color1}`}
      />
      <path
        d="M64.543 36.6071C64.543 30.4716 68.3662 23.2528 65.4991 18.3035C62.5428 13.1917 54.2784 12.9312 49.168 9.97469C44.2143 7.10325 39.9284 0 33.793 0C27.6575 0 23.3716 7.10469 18.418 9.97037C13.3061 12.9283 5.04316 13.1918 2.08684 18.2993C-0.780282 23.2529 3.04297 30.4718 3.04297 36.6071C3.04297 42.7423 -0.780282 49.9614 2.08684 54.9107C5.04316 60.0226 13.3077 60.2831 18.418 63.2396C23.3716 66.1111 27.6575 73.2143 33.793 73.2143C39.9284 73.2143 44.2143 66.1096 49.168 63.2439C54.2798 60.286 62.5428 60.0226 65.4991 54.915C68.3662 49.9614 64.543 42.7425 64.543 36.6071Z"
        fill={`${color2}`}
      />
      <path
        d="M33.7929 61.5C47.5409 61.5 58.6859 50.3551 58.6859 36.6071C58.6859 22.8591 47.5409 11.7142 33.7929 11.7142C20.045 11.7142 8.90002 22.8591 8.90002 36.6071C8.90002 50.3551 20.045 61.5 33.7929 61.5Z"
        fill="#F5F5F5"
      />
      <path
        d="M48.3215 30.75H38.2868L35.1853 21.2072C34.9334 20.4385 34.1048 20.0211 33.3374 20.273C32.8953 20.418 32.5482 20.765 32.4033 21.2072L29.2991 30.75H19.2644C18.456 30.75 17.8 31.406 17.8 32.2143C17.8 32.6829 18.0241 33.1237 18.4034 33.399L26.5214 39.2986L23.4199 48.8428C23.1696 49.6116 23.5913 50.4375 24.36 50.6878C24.8067 50.8328 25.2942 50.7553 25.6735 50.48L33.7929 44.5801L41.9109 50.4782C42.5655 50.954 43.4806 50.8091 43.9566 50.1545C44.2319 49.7753 44.3094 49.2861 44.1645 48.8411L41.0631 39.2969L49.1811 33.3972C49.8342 32.9214 49.9777 32.0046 49.5017 31.3515C49.2265 30.974 48.7887 30.7514 48.3215 30.75Z"
        fill={`${color2}`}
      />
      <path
        d="M65.4991 18.3036C65.4361 18.1937 65.3527 18.1 65.2897 17.9961C63.1344 22.099 60.5791 25.978 57.6609 29.5786C61.5471 42.7572 54.0133 56.5917 40.8347 60.478C32.1749 63.0303 22.8109 60.6918 16.3679 54.3661C11.9766 55.2126 7.51482 55.64 3.04293 55.6431C2.88486 55.6431 2.73111 55.6328 2.57288 55.6314C5.86313 60.0506 13.555 60.4327 18.4179 63.2457C23.3716 66.1098 27.6575 73.2146 33.7929 73.2146C39.9284 73.2146 44.2143 66.1099 49.1679 63.2442C54.2798 60.2863 62.5427 60.0229 65.4991 54.9153C68.3662 49.9617 64.5429 42.7426 64.5429 36.6074C64.5429 30.4721 68.3662 23.2529 65.4991 18.3036Z"
        fill={`${color3}`}
      />
      <path
        d="M42.4499 43.5611L44.166 48.8413C44.4163 49.61 43.9946 50.4359 43.2259 50.6863C42.7792 50.8312 42.2917 50.7537 41.9125 50.4784L36.9925 46.9026C30.5526 50.4637 23.5898 52.9837 16.3635 54.3705C26.1552 64.0069 41.9036 63.8811 51.5386 54.0894C57.8848 47.6407 60.2292 38.2546 57.6608 29.5788C53.3031 34.9599 48.1781 39.6704 42.4499 43.5611Z"
        fill="#D8D7D8"
      />
      <path
        d="M44.1661 48.8412L42.45 43.561C40.687 44.7574 38.8684 45.8716 36.9927 46.9025L41.9127 50.4783C42.5672 50.9542 43.4824 50.8092 43.9584 50.1547C44.2335 49.7754 44.311 49.2879 44.1661 48.8412Z"
        fill={`${color3}`}
      />
      <path
        d="M55.0252 68.8213C58.8205 68.8242 62.5692 69.675 65.9941 71.3107L57.3212 60.1601C54.5112 60.9215 51.7802 61.951 49.1681 63.2351C46.7842 64.856 44.5629 66.7039 42.5334 68.7525L44.3916 71.1436C47.7272 69.612 51.3542 68.8199 55.0252 68.8213Z"
        fill={`${color4}`}
      />
      <path
        d="M18.418 63.2439C15.8057 61.9598 13.0749 60.9303 10.2649 60.1689L9.8125 60.7546C14.7544 64.3231 17.6831 70.0471 17.6859 76.1428C17.6831 76.9072 17.6332 77.6686 17.5396 78.4271L25.0075 68.8214L25.0544 68.7613C23.0246 66.7129 20.8019 64.8663 18.418 63.2439Z"
        fill={`${color4}`}
      />
    </svg>
  )
}
