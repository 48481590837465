import styled from 'styled-components';

export const CustomModalHeader = styled.h5`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  font-weight: bold;
  font-size: 1.6rem;
`;
