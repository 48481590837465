import styled from 'styled-components';

export const PageMainContainer = styled.div`
  flex: 1;
  height: 100%;
  max-width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;

  @media(max-width: 600px){
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }
`;

export const PageMenuContainer = styled.div`
  flex: 1;
`;

export const PageContentContainer = styled.div`
  flex: 4;

  display: flex;
  flex-direction: column;

  @media(max-width: 600px){
    flex: 9;
    width: -webkit-fill-available;
    overflow: hidden;
  }
`;
