import React, {useState, useMemo} from 'react';
import { OfflineStyle, OfflineIcone } from './style';
import { Icon } from '@polichat/flamboyant';

const OfflineComponent = () => {
  const [offline, setOffline] = useState(false);

  window.addEventListener('online', () => {
    if (offline) {
      setOffline(false)
    }
  });

  window.addEventListener('offline', () => {
    if (!offline) {
      setOffline(true)
    }
  });

  const offlineRender = (
    <OfflineStyle>
      <OfflineIcone>
        <Icon icon={'poli-icon pi-conection-line'} />
      </OfflineIcone>
      &nbsp;&nbsp;&nbsp; Sem conexão com a internet
    </OfflineStyle>
  )

  return useMemo(() => {
    return offline ? offlineRender : <></>;
  }, [offline])
};

export default OfflineComponent;
