import {useState} from 'react';
import {Col, FormGroup, Label, Input} from '@polichat/flamboyant';

function TemplateShortcutInput({onUpdate, initialValue}) {
  const [templateShortcut, setTemplateShortcut] = useState({
    templateShortcut: initialValue
  });

  const handleInputChange = ({target: {name, value}}) => {
    setTemplateShortcut({
      ...templateShortcut,
      [name]: value
    });
    handleTemplateShortcutData({
      ...templateShortcut,
      [name]: value
    })
  };

  const handleTemplateShortcutData = (data) => {
    onUpdate(data)
  }

  return (
  <Col sm={12} md={6} >
    <FormGroup>
      <Label>Atalho:</Label>
      <Input
        type="text"
        id="template-shortcut"
        name= "templateShortcut"
        placeholder="Atalho para mensagem"
        value={templateShortcut.templateShortcut}
        onChange={handleInputChange}
        />
    </FormGroup>
  </Col>
)}

export default TemplateShortcutInput;
