import axios from 'axios';
import ObjectToQueryString from '../../lib/ObjectToQueryString';
import poliConfig from 'src/utils/poliConfig';

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const defaultConfig = {
  headers: defaultHeaders,
  withCredentials: true,
};

const getCategories = (params = null) => {
  return axios.get(
    poliConfig.poliAppLaravelUrl +
      `/shopping/categories/search` +
      (params ? '?' + ObjectToQueryString(params) : ''),
    defaultConfig
  );
};

const createCategory = (data) => {
  return axios.post(
    poliConfig.poliAppLaravelUrl + `/shopping/categories`,
    data,
    defaultConfig
  );
};

const updateCategory = (id, data) => {
  return axios.put(
    poliConfig.poliAppLaravelUrl + `/shopping/categories/${id}`,
    data,
    defaultConfig
  );
};

const getProducts = (params = null) => {
  return axios.get(
    poliConfig.poliAppLaravelUrl +
      `/shopping/products/search` +
      (params ? '?' + ObjectToQueryString(params) : ''),
    defaultConfig
  );
};

const createProduct = (data) => {
  return axios.post(
    poliConfig.poliAppLaravelUrl + `/shopping/products`,
    data,
    defaultConfig
  );
};

const updateProduct = (id, data) => {
  return axios.put(
    poliConfig.poliAppLaravelUrl + `/shopping/products/${id}`,
    data,
    defaultConfig
  );
};

const getAllProducts = () => {
  return axios.get(
    poliConfig.poliAppLaravelUrl + `/shopping/products/all`,
    defaultConfig
  );
};

const getAllCategories = () => {
  return axios.get(
    poliConfig.poliAppLaravelUrl +
      `/shopping/categories/all`,
    defaultConfig
  );
};

export default {
  getCategories,
  createCategory,
  updateCategory,
  getProducts,
  createProduct,
  updateProduct,
  getAllProducts,
  getAllCategories,
};
