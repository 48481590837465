import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem;
  padding: 0 1rem;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SectionName = styled.h3`
  color: var(--secondary-font-color);
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 1rem;
`;

export const SectionContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  @media (max-width: 800px) {
    gap: 1rem;
  }
`;

export const InputsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }
`;

export const InputItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  & .form-group {
    display: auto !important;
  }

  & label {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    & i {
      margin-right: 0.8rem;
    }
  }
`;
export const ImageUploadContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  max-height: 7rem;
  margin: 0.3rem;
`;

export const MultSelectorItem = styled(InputItem)`
  z-index: 2;
  &:hover,
  &:focus {
    border: none;
    outline: unset;
  }
`;

export const SwitchContainer = styled.div`
  display: flex;
  & p {
    margin-left: 1rem;
  }

  & p:first-child {
    margin-right: 1rem;
    margin-left: 0;
  }

  @media (max-width: 800px) {
    width: 100%;
    justify-content: space-around;

    & p {
      margin: 0;
    }
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
`;

export const PhoneInputContainer = styled.div`
  display: flex;
  align-items: center;

  & button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    padding-right: 8px;
    margin-left: 0.3rem;
    font-size: 1rem;
  }
`;

export const BtnStatusChannelContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
