import { useEffect, useState } from 'react';
import { Label, Icon, UncontrolledTooltip, Button } from '@polichat/flamboyant';
import { MinusLineDark, AddLineLight, Mail, Users } from '@poli-ink/react';
import './details.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ChatActions from '../../../../../store/modules/chat/actions';
import getOperator from '../../../../tools/integrations/crmRequests/getOperator';
import UnmergeContact from '../UnmergeContact';
import Tag from '../../chat/modals/tags/Tag';
import TagInDetails from './tag/index'

const ContactInfoModal = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [operator, setOperator] = useState(null);
  const [allTags, setAllTags] = useState(props.allTags);
  const [showAddTag, setShowAddTag] = useState(false);
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  
  useEffect(() => {
    setIsOpen(props.openTagList);
  }, [props.openTagList]);

  let isOperator = props.contact.contact.operator;

  const getData = async () => {
    try {
      const response = await getOperator(isOperator);

      if (response && response.data) {
      }
      setOperator(response.data[0]);
    } catch (error) {
      return error;
    }
  };

  const loadChatTags = () => {
    let chatTags = <>Sem etiquetas</>;
    if (props.contact.tags && props.contact.tags.length && allTags) {
      chatTags = props.contact.tags.map((currentTag, index) => {
        let tag = props.allTags.find((t) => t.id === currentTag.tag_id);

        if (tag) {
          let obj = {
            chat_id: props.contact.id,
            tag_id: currentTag.id,
          };
          return (
            <Tag
              key={index}
              onRemove={() => props.removeTagFromContactRequest(obj)}
              color={tag.color}
              name={tag.name}
              close
            />
          );
        } else {
          return chatTags;
        }
      });
    }

    if (props.contact.tags && props.contact.tags.length) {
      return (
        <div>
          {chatTags}
        </div>
      );
    } else {
      return <></>;
    }
  };

  useEffect(() => {
    setOperator(null);
    getData();
  }, [props]);

  return (
    <>
      <Button className='ContactButton' onClick={toggleModal}>{isOpen ? <MinusLineDark /> : <AddLineLight />}</Button>
      {isOpen && (
        <div>
          <div className='align-start'>
            <hr className='vertical-line' />
            <span><Mail /> {props.contact?.contact?.email}</span>
            <span><Users /> {operator?.name}</span>
            <hr className='vertical-line' />
            <UnmergeContact contact={props.contact} />
            <div id="infoItemTags" className='align-start'>
              <hr className='vertical-line' />
              <span
                placement="bottom"
                target="infoItemTags"
              >
                <strong>Etiquetas</strong>
              </span>
              <button className='buttonAddTag' onClick={() => {
                props.getTags()
                setShowAddTag(!showAddTag)
              }}>
                {showAddTag ? (
                  <span>Fechar</span>
                ) : (
                  <span>Adicionar etiqueta</span>
                )}
              </button>
              {props.contact.tags && props.contact.tags.length ? (
                <>
                  <div className='wrapperBadge'>{loadChatTags()}</div>
                  {showAddTag && (
                    <div className="content-tags">
                      <TagInDetails />
                    </div>
                  )}
                </>
              ) : (
                showAddTag && (
                  <div className="content-tags">
                    <TagInDetails />
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ChatActions, dispatch);

export default connect(mapDispatchToProps, mapDispatchToProps)(ContactInfoModal);
