import { CustomInput, Label } from '@polichat/flamboyant';
import { facebookSupportedLanguages } from './languages';

export interface SelectCodeLanguageProps {
  value: string;
  disabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
}
export function SelectCodeLanguage(props: SelectCodeLanguageProps) {
  // ---------------------------------------------
  // Transformations
  const selectItem = facebookSupportedLanguages?.find(
    (item) => item.id === props.value
  );

  // ---------------------------------------------
  // Render

  return (
    <>
      <Label for="select_code_language">Selecione linguagem: </Label>
      <CustomInput
        type="select"
        id="select_code_language"
        value={selectItem?.id}
        onChange={props.onChange}
        disabled={props.disabled}
      >
        <option value={''}>Selecione uma linguagem</option>
        {facebookSupportedLanguages?.map((item) => (
          <option key={item?.id} value={item?.id}>
            {item?.language}
          </option>
        ))}
      </CustomInput>
    </>
  );
}
