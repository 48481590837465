import {
  Accordion,
  Button,
  Collapse,
  CollapseMenu,
  Icon,
  Navbar,
  NavbarToggler,
} from '@polichat/flamboyant';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import poliConfig from 'src/utils/poliConfig';
import ContractForm from '../../components/admin/contract/ContractForm';
import CreditList from '../../components/admin/plans/Credit';
import ImportList from '../../components/admin/plans/ImportList';
import PlanForm from '../../components/admin/plans/PlanForm';
import PlanList from '../../components/admin/plans/PlanList';
import WelcomePlan from '../../components/admin/plans/WelcomePlan';
import ServiceForm from '../../components/admin/services/ServiceForm';
import ServiceImport from '../../components/admin/services/ServiceImport';
import ServiceList from '../../components/admin/services/ServiceList';
import WelcomeService from '../../components/admin/services/WelcomeService';
import Animation from '../../components/common/animation';
import {
  PageContentContainer,
  PageMainContainer,
  PageMenuContainer,
} from '../../components/common/pages/PageContainer/PageContainer.styles';
import history from '../../services/history';
import * as GeneralActions from '../../store/modules/general/actions';

function Admin({ currentUser, loading }) {
  const { path, url } = useRouteMatch();
  const pathname = window?.location?.pathname;
  const superRole = currentUser?.superRole;
  const [collapsed, setCollapsed] = useState(true);
  const toggleNavbar = () => setCollapsed(!collapsed);

  /**
   * Menu ativo
   */
  const [menuActive, setMenuActive] = useState();
  useEffect(() => {
    /**
     * O _menuAtive representa o "id" que esta setado no
     * CollapseMenu.
     */

    let _menuAtive = pathname;

    // Plans
    if (pathname == '/admin' || pathname.includes('plans')) {
      _menuAtive = '/admin/welcome-plan';
    }

    // Services
    if (pathname.includes('service')) {
      _menuAtive = '/admin/welcome-service';
    }

    // Mais
    if (pathname == '/more' || pathname.includes('more')) {
      _menuAtive = '/admin/more';
    }

    setMenuActive('');
    setMenuActive(_menuAtive);
  }, [pathname, path, url]);
  /* end - Menu ativo */

  if (loading) {
    return <Animation size="full" />;
  }

  if (currentUser) {
    return (
      <PageMainContainer>
        {isAdmin(superRole) ? (
          <>
            <PageMenuContainer>
              <Navbar color="faded" light expand="sm" className="new-sidebar">
                <NavbarToggler
                  color="primary"
                  onClick={toggleNavbar}
                  className="mr-2"
                >
                  <Icon size={30} icon="poli-icon pi-menu-mobile-fill" />
                </NavbarToggler>
                <Collapse isOpen={!collapsed} navbar>
                  <Accordion defaultActiveKey={menuActive} key={menuActive}>
                    <CollapseMenu
                      id={`${url}/welcome-plan`}
                      principalTitle="Planos"
                      principalIcon="poli-icon pi-list-customers-line"
                      doSomething={() =>
                        history.push({ pathname: `${url}/plans` })
                      }
                    >
                      <Link
                        className="btn-primary-collapse-itens"
                        to={{ pathname: `${url}/plans` }}
                        // onClick={isMobile && toggleNavbar}
                      >
                        <Button
                          color="primary-collapse-itens"
                          className={
                            pathname == `${url}/plans` ||
                            pathname == `${url}/welcome-plan`
                              ? 'active'
                              : ''
                          }
                        >
                          <Icon size={18} icon={'poli-icon pi-eye-line'} />
                          Visualizar planos
                        </Button>
                      </Link>
                      <Link
                        className="btn-primary-collapse-itens"
                        to={{ pathname: `${url}/plans/import` }}
                        // onClick={isMobile && toggleNavbar}
                      >
                        <Button
                          color="primary-collapse-itens"
                          className={
                            pathname == `${url}/plans/import` ? 'active' : ''
                          }
                        >
                          <Icon
                            size={18}
                            icon={'poli-icon pi-arrow-down-line'}
                          />
                          Importar planos
                        </Button>
                      </Link>
                      <Link
                        className="btn-primary-collapse-itens"
                        to={{ pathname: `${url}/plans/new` }}
                        // onClick={isMobile && toggleNavbar}
                      >
                        <Button
                          color="primary-collapse-itens"
                          className={
                            pathname == `${url}/plans/new` ? 'active' : ''
                          }
                        >
                          <Icon size={18} icon={'poli-icon pi-add-line'} />
                          Adicionar plano
                        </Button>
                      </Link>
                      <Link
                        className="btn-primary-collapse-itens"
                        to={{ pathname: `${url}/plans-credit` }}
                        // onClick={isMobile && toggleNavbar}
                      >
                        <Button
                          color="primary-collapse-itens"
                          className={
                            pathname == `${url}/plans-credit` ? 'active' : ''
                          }
                        >
                          <Icon size={18} icon={'poli-icon pi-debit-line'} />
                          Créditos
                        </Button>
                      </Link>
                    </CollapseMenu>
                    <CollapseMenu
                      id={`${url}/welcome-service`}
                      principalTitle="Serviços"
                      principalIcon="poli-icon pi-catalogo-line"
                      doSomething={() =>
                        history.push({ pathname: `${url}/services` })
                      }
                    >
                      <Link
                        className="btn-primary-collapse-itens"
                        to={{ pathname: `${url}/services` }}
                        // onClick={isMobile && toggleNavbar}
                      >
                        <Button
                          color="primary-collapse-itens"
                          className={
                            pathname == `${url}/services` ? 'active' : ''
                          }
                        >
                          <Icon size={18} icon={'poli-icon pi-eye-line'} />
                          Visualizar serviços
                        </Button>
                      </Link>
                      <Link
                        className="btn-primary-collapse-itens"
                        to={{ pathname: `${url}/services/new` }}
                        // onClick={isMobile && toggleNavbar}
                      >
                        <Button
                          color="primary-collapse-itens"
                          className={
                            pathname == `${url}/services/new` ? 'active' : ''
                          }
                        >
                          <Icon size={18} icon={'poli-icon pi-add-line'} />
                          Adicionar serviço
                        </Button>
                      </Link>
                      <Link
                        className="btn-primary-collapse-itens"
                        to={{ pathname: `${url}/services/import` }}
                        // onClick={isMobile && toggleNavbar}
                      >
                        <Button
                          color="primary-collapse-itens"
                          className={
                            pathname == `${url}/services/import` ? 'active' : ''
                          }
                        >
                          <Icon
                            size={18}
                            icon={'poli-icon pi-arrow-down-line'}
                          />
                          Importar Serviços
                        </Button>
                      </Link>
                    </CollapseMenu>
                    <CollapseMenu
                      id={`${url}/more`}
                      principalTitle="Mais"
                      principalIcon="poli-icon pi-text-left-line"
                      doSomething={() =>
                        history.push({ pathname: `${url}/more` })
                      }
                    >
                      <Link
                        className="btn-primary-collapse-itens"
                        to={{ pathname: `${url}/more/admin-laravel` }}
                        // onClick={isMobile && toggleNavbar}
                      >
                        <Button
                          color="primary-collapse-itens"
                          className={
                            pathname == `${url}/more/admin-laravel`
                              ? 'active'
                              : ''
                          }
                          onClick={() =>
                            window.location.assign(
                              `${poliConfig.poliAppLaravelUrl}/admin`
                            )
                          }
                        >
                          <Icon
                            size={18}
                            icon={'poli-icon pi-admin-polichat-line'}
                          />
                          Administração Laravel
                        </Button>
                      </Link>
                    </CollapseMenu>
                  </Accordion>
                </Collapse>
              </Navbar>
            </PageMenuContainer>
            <PageContentContainer>
              <Switch>
                <Route exact path={path}>
                  <WelcomePlan />
                </Route>
                <Route exact path={`${path}/welcome-plan`}>
                  <WelcomePlan />
                </Route>
                <Route exact path={`${path}/plans`}>
                  <PlanList />
                </Route>
                <Route exact path={`${path}/plans/import`}>
                  <ImportList />
                </Route>
                <Route exact path={`${path}/plans/new`}>
                  <PlanForm />
                </Route>
                <Route exact path={`${path}/plans/:id/edit`}>
                  <PlanForm />
                </Route>
                <Route exact path={`${path}/plans-credit`}>
                  <CreditList />
                </Route>
                <Route exact path={`${path}/welcome-service`}>
                  <WelcomeService />
                </Route>
                <Route exact path={`${path}/services`}>
                  <ServiceList />
                </Route>
                <Route exact path={`${path}/services/new`}>
                  <ServiceForm />
                </Route>
                <Route exact path={`${path}/services/:id/edit`}>
                  <ServiceForm />
                </Route>
                <Route exact path={`${path}/services/import`}>
                  <ServiceImport />
                </Route>
                <Route exact path={`${path}/contract/:id/new`}>
                  <ContractForm />
                </Route>
              </Switch>
            </PageContentContainer>
          </>
        ) : renderPageDontHavePermission()}
      </PageMainContainer>
    );
  }
}

const renderPageDontHavePermission = () => {
  return (
    <div className="erroPay">
      <Icon icon={'poli-icon pi-sad-fill'} color="var(--danger)" size={86} />
      <p>Você não possui permissão para acessar esta página.</p>
    </div>
  );
};

const isAdmin = (superRole) => {
  return superRole === 'adm';
};

const mapStateToProps = (state) => ({
  currentUser: state?.general?.user,
  loading: !state?.general?.user,
  currentCustomerID: state.general?.current_customer_id,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(GeneralActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
