import styled from 'styled-components';

export const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  height: 100%;
`;

export const InfoToHireChannel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10rem;
  margin: auto;

  & img {
    width: 40%;
    margin: 1rem 0;
  }

  & h3 {
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 3rem;
    text-align: center;
    margin-bottom: 2rem;
  }

  & span {
    text-align: center;
    font-size: 1.3rem;
    letter-spacing: 0.2px;
    line-height: 1.8rem;
    margin-bottom: 2rem;
  }

  // MOBILE
  @media (max-width: 600px) {
    overflow-y: auto;
    padding: 0.85rem;
    margin: 0;

    & img {
      width: 100%;
    }

    & h3 {
      font-size: 1.3rem;
      line-height: 1.5rem;
      margin-top: 2rem;
    }

    & span {
      font-size: 1rem;
      letter-spacing: 0.2px;
      line-height: 1.4rem;
    }
  }
`;
