import React, { useEffect, useState } from 'react';
import { useFlags } from 'flagsmith/react';
import {
  Button,
  CustomInput,
  CustomPhoneInput,
  FormGroup,
  Icon,
  Input,
  Label,
  MultiSelector,
  Switch,
  Row,
  Col,
} from '@polichat/flamboyant';

import {
  ActionGroup,
  CheckBoxContainer,
  Container,
  ContrastBackgroundContainer,
  InlineInput,
  InputItem,
  InputsContainer,
  MultiSelectorContainer,
  PhoneContainer,
  Section,
  SwitchContainer,
} from './ChannelAddEditBroker.styles';

import { toast } from 'react-toastify';

import ChannelStatusAlert, {
  ChannelStatuses,
} from '../ChannelStatusAlert/ChannelStatusAlert.component';
import ChannelsService from '../../../../services/tools/channels/channels';
import UsersService from '../../../../services/tools/users';
import ChannelDescriptionBannerInstagram from '../ChannelDescriptionBanner/ChannelDescriptionBannerInstagram.component';
import ChannelBaseboardInstagram from '../ChannelDescriptionBanner/ChannelBaseboardInstagram.component';
import ChannelBaseboardInstagramRefactor from '../ChannelDescriptionBanner/ChannelBaseboardInstagram.component.refactor';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import ChannelCustomerStatusCodes from '../ChannelCustomerStatusCodes';
import { useHistory } from 'react-router-dom';
import DeleteChannelModal from '../DeleteChannelModal/DeleteChannelModal.component';
import { ChannelsDeleteService } from '../../../../services/tools/channels/index';
import ButtonDeleteChannel from '../DeleteChannel/DeleteChannel.componet';

import poliConfig from '../../../../utils/poliConfig';

export default function ChannelAddEditInstagram({
  data: dataFromDad,
  userAdmPoliGetChannelFromCustomerId,
}) {
  const { name } = dataFromDad || {};
  const [channelConnection] = useState(dataFromDad?.connection);
  let usersDad = dataFromDad?.users || [];
  const pagina = dataFromDad?.config?.pagina ?? '';
  const customer_id = useSelector((state) => {
    if (userAdmPoliGetChannelFromCustomerId) {
      return userAdmPoliGetChannelFromCustomerId;
    } else {
      return state.general.current_customer_id;
    }
  });

  // Estados locais para lidar com mudanças nos valores de um input
  const [channelName, setChannelName] = useState(name || '');
  const [channelPagina, setChannelPagina] = useState(pagina || '');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [showChannelOnWidget, setShowChannelOnWidget] = useState(
    dataFromDad?.display_on_webchat == 1 ?? true
  );
  // Guarda o status do canal de acordo com o backend
  const [channelStatus, setChannelStatus] = useState(
    dataFromDad?.config?.status
  );
  const [allowChannelOptimization, setAllowChannelOptimization] = useState(
    dataFromDad?.config?.optimize ?? true
  );
  const [timeForOptimize, setTimeForOptimize] = useState(
    dataFromDad?.config?.time_for_optimize ?? 0
  );

  // Guarda a escolha do usuário sobre a ativação do canal
  const [activateChannel, setActivateChannel] = useState(
    dataFromDad?.status ?? true
  );
  const [usersOptions, setUsersOptions] = useState([]);
  const [sendButtonDisabled, setSendButtonDisabled] = useState(false);

  // Popula a lista de usuários que podem ou não acessar o canal
  useEffect(() => {

    /**
     * verifica se a empresa passou pela analise do plano antes de criar canal
     * e não é edição
     */
    if (!historyState?.validated_can_add_channel && !dataFromDad) {
      history.push(`/tools/channels/add`);
      return;
    }


    async function fetchUsers() {
      try {
        const users_result = await ChannelsService.getAllUsersByCustomer(
          customer_id
        );
        const { data: users } = users_result;
        //Usado para criar o objeto options que irá alimentar o componente MultiSelector
        if (users && users.length) {
          const newUsersOptions = users.map((user) => {
            return {
              value: user.id,
              label: user.name,
            };
          });

          if (poliConfig.bugfix_PS_385) {
            const usersSelected = newUsersOptions.filter((user) =>
              usersDad.includes(user.value)
            );

            setUsersOptions(newUsersOptions);
            setSelectedUsers(usersSelected.length ? usersSelected : newUsersOptions);
          } else {
            setUsersOptions(newUsersOptions);
            setSelectedUsers(newUsersOptions);
          }
        }
        return;
      } catch (error) {
        console.log(
          'Erro no componente ChannelAddEditInstagram na função fetchUsers => ',
          error
        );
        return;
      }
    }

    fetchUsers();
  }, []);

  // Para redirecionar o usuário após criação de um canal
  const history = useHistory();
  // Pegar state enviado pela URL
  const { state: historyState } = history.location

  // Funções utilizadas pelos inputs quando os valores mudarem (OnChange)
  const handleNameChange = (e) => setChannelName(e.target.value);
  const handleGetSelectorValues = (data) => setSelectedUsers(data);
  const handleShowOnWidgetChange = (e) =>
    setShowChannelOnWidget(e.target.checked);
  const handleActivateChannelChange = (e) =>
    setActivateChannel(e.target.checked);
  const handleOptimizeChannelChange = (e) =>
    setAllowChannelOptimization(e.target.checked);
  const handleTimeForOptimizeChange = (e) => setTimeForOptimize(e.target.value);

  const handleSendForm = async () => {
    setSendButtonDisabled(true);

    try {
      const channelObject = {
        name: channelName,
        customer_id,
        channel_id: 5, // Sempre será 5 pois é o código do Instagram no backend
        api: 0,
        status: activateChannel ? 1 : 0,
        display_on_webchat: showChannelOnWidget ? 1 : 0,
        users: selectedUsers.map((user) => user.value),
        config: {
          pagina: channelPagina,
        },
      };

      if (!dataFromDad) {
        // Criar novo canal:
        channelObject.config = JSON.stringify(channelObject.config);

        const result = await ChannelsService.createChannel(channelObject);

        if (result.status == ChannelCustomerStatusCodes.CREATED) {
          toast.success('Canal criado com sucesso!');
          history.push(`/tools/channels/edit/${result.data.id}`);
        } else {
          toast.error('Erro ao criar o canal');
          setSendButtonDisabled(false);
        }
      } else {
        // Editar um canal existente
        channelObject.id = dataFromDad.id;

        const result = await ChannelsService.updateChannel(channelObject);

        if (result.status == ChannelCustomerStatusCodes.OK) {
          toast.success('Canal editado com sucesso!');
          setSendButtonDisabled(false);
          history.push(`/tools/channels/edit/${channelObject.id}`);
        } else {
          throw 'Requisição inválida';
        }
      }
      return;
    } catch (error) {
      console.log(
        `Erro ao ${dataFromDad ? 'editar' : 'criar'} canal => ChannelAddEditInstagram on handleSendForm`,
        error
      );
      toast.error(<p style={{ color: 'var(--danger)' }}>
        Erro ao {dataFromDad ? 'editar' : 'criar'} canal!<br />
        Se erro persistir entre em contato com o suporte.
      </p>);

      setSendButtonDisabled(false);
      return;
    }
  };

  return (
    <>
      <Container>
        <ChannelDescriptionBannerInstagram />

        <Section>
          <InputsContainer>
            <InputItem style={{ marginTop: '1.5rem' }}>
              <Label id="channel-name-label" for="channel-name">
                <Icon
                  icon="poli-icon pi-channels-fill"
                  color="var(--green-polichat)"
                  size="24"
                />
                Nome do Canal
              </Label>

              <Input
                type="text"
                name="channel-name"
                id="channel-name"
                value={channelName}
                onChange={handleNameChange}
                style={{
                  borderColor: 'var(--borderDefault)',
                }}
              />
            </InputItem>

            <InputItem>
              <InputItem style={{ marginTop: '1.5rem' }}>
                <Label id="channel-pagina-label" for="channel-pagina">
                  <Icon
                    icon="poli-icon pi-computer-fill"
                    color={'var(--green-polichat)'}
                    size="24"
                  />
                  Nome da página
                </Label>

                <Input
                  type="text"
                  name="channel-pagina"
                  id="channel-pagina"
                  value={dataFromDad?.config?.name}
                  style={{
                    borderColor: 'var(--borderDefault)',
                  }}
                  disabled={true}
                />
              </InputItem>
            </InputItem>

            <InputItem style={{ marginTop: '1.0rem' }}>
              <MultiSelectorContainer>
                <Label
                  id="select-users-channel-label"
                  for="select-users-channel"
                >
                  <Icon
                    icon="poli-icon pi-perfil-fill"
                    color={'var(--green-polichat)'}
                    size="24"
                  />
                  Usuários que poderão utilizar este canal
                </Label>
                <MultiSelector
                  name="select-users-channel"
                  id="select-users-channel"
                  options={usersOptions}
                  values={selectedUsers}
                  selectedValues={handleGetSelectorValues}
                />
              </MultiSelectorContainer>
            </InputItem>
            <InputsContainer>
              <InputItem style={{ marginTop: '1.5rem' }}>
                <Label id="channel-name-label" for="channel-name">
                  <Icon
                    icon="poli-icon pi-channels-fill"
                    color="var(--green-polichat)"
                    size="24"
                  />
                  Status do Canal
                </Label>

                <SwitchContainer>
                  <Switch
                    color="primary"
                    isChecked={activateChannel}
                    onClick={handleActivateChannelChange}
                  />
                  <p>{activateChannel ? 'Ativado' : 'Desativado'}</p>
                </SwitchContainer>
              </InputItem>

              {/* Exibir canal no Widget do webchat. */}
              <InputItem style={{ marginTop: '1.5rem', marginLeft: '-4rem' }}>
                <Label
                  id="show-channel-on-widget"
                  for="show-channel-on-widget"
                >
                  <Icon
                    icon="poli-icon pi-channels-fill"
                    color="var(--green-polichat)"
                    size="24"
                  />
                  Exibir canal no Widget do webchat.
                </Label>

                <SwitchContainer>
                  <Switch
                    color="primary"
                    isChecked={showChannelOnWidget}
                    onClick={handleShowOnWidgetChange}
                  />
                  <p>{showChannelOnWidget ? 'Ativado' : 'Desativado'}</p>
                </SwitchContainer>
              </InputItem>
            </InputsContainer>
          </InputsContainer>
          <ChannelBaseboardInstagramRefactor
            isConnected={channelConnection}
            namePage={dataFromDad.config.name}
          />
          <ActionGroup
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '3.0rem',
              width: '60%',
            }}
          >
            <Button
              color="primary"
              size={isMobile ? 'md' : 'lg'}
              disabled={sendButtonDisabled}
              onClick={handleSendForm}
            >
              {dataFromDad ? 'Salvar' : 'Criar'} canal
            </Button>
            <Button
              color="danger"
              size={isMobile ? 'md' : 'lg'}
              style={{ width: '9rem' }}
              onClick={() => {
                history.push('/tools/channels');
              }}
            >
              Cancelar
            </Button>
            <ButtonDeleteChannel
              channel={dataFromDad}
              customer_id={customer_id}
              size={isMobile ? 'md' : 'lg'}
            />
          </ActionGroup>
        </Section>
      </Container>
    </>
  );
}
