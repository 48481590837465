import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from '@polichat/flamboyant';
import PageMainBody from '../../common/pages/PageMainBody/PageMainBody.component';
import PageMainHeader from '../../common/pages/PageMainHeader/PageMainHeader.component';
import { Container } from '../channels/Certification/steps/Congratulations/Congratulations.styles';
import { ProgressBarMigrateChannelToPoli } from './components/progressBar';
import { IconPlusIcon } from 'src/assets/illustratorElement/iconPlusIcon/iconPlusIcon';

import Illustration from '../../../assets/images/illustrations/illustration-welcome-waba-channel.svg';

import { useCustomer } from './hooks/useCustomer';

import { useSteps } from './useSteps';
import { Step0, Step0Ref } from './step0';
import { Step1, Step1Ref } from './step1';
import {
  StepValidateOTPAndRegisterChannel,
  StepValidateOTPAndRegisterChannelRef,
} from './StepValidateOTPAndRegisterChannel';
import history from '../../../services/history';
export function MigrateChannelToPoliPage() {
  //@ts-ignore
  const { channel_id } = useParams();
  const [channelId, setChannelId] = useState(channel_id);

  // CUSTOM HOOK - control the steps
  const steps = useSteps();

  // refs values
  const step0Ref = useRef<Step0Ref>(null);
  const step1Ref = useRef<Step1Ref>(null);
  const step2Ref = useRef<StepValidateOTPAndRegisterChannelRef>(null);

  const customer = useCustomer();
  const customer_id = parseInt(customer.current?.id || '0');

  const [phoneId, setPhoneId] = useState<string>('');

  const [userCanGoToStep3, setUserCanGoStep3] = useState(false);

  // ---------------------------------------------
  // Functions

  function handleNext() {
    switch (steps.current) {
      case 0:
        step0Ref.current?.submit();
        step1Ref.current?.onClearForm();
        break;
      case 1:
        step1Ref.current?.submit();
        break;
      case 2:
        step2Ref.current?.submit();
        break;
      default:
        break;
    }
  }

  function handlePrev() {
    switch (steps.current) {
      case 1:
      case 2:
        steps.prev();
        break;
    }
  }

  function handleSetChannelId(value: number) {
    if (!channel_id) {
      setChannelId(value);
    }
  }

  function handleRedirect() {
    history.push(`/tools/channels/edit/${channelId}`);
  }
  // ---------------------------------------------
  // Transformations
  // ---------------------------------------------
  // Render

  return (
    <>
      <PageMainHeader
        children={undefined}
        icon="poli-icon pi-queue-line"
        iconSize={18}
        title="Migrar canal"
      />
      <PageMainBody>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: '0 1rem',
            padding: '2px',
            height: '-webkit-fill-available',
          }}
        >
          <div id="flex_item_1">
            <ProgressBarMigrateChannelToPoli current_step={steps.current} />
          </div>
          {/* ---- FORM STEP CONTENT ---- */}
          <div id="flex_item_2" style={{ flexGrow: '1' }}>
            {/* STEP 0: Facebook pop-up */}
            <div style={{ display: steps.current === 0 ? 'block' : 'none' }}>
              <Step0
                // props
                customer={customer.current}
                // ref
                ref={step0Ref}
                onSubmit={() => {
                  steps.next();
                }}
              />
            </div>
            {/* STEP 1: Manager Business ID*/}
            <div style={{ display: steps.current === 1 ? 'block' : 'none' }}>
              <Step1
                //ref
                ref={step1Ref}
                // props
                channel_id={channel_id}
                customer_id={customer_id}
                onSuccess={(phoneId, channelId) => {
                  setPhoneId(phoneId);
                  handleSetChannelId(channelId);
                  step0Ref.current?.setDisplayErrorFalse();
                  steps.next();
                }}
                onError={() => {
                  step0Ref.current?.setDisplayErrorTrue();
                }}
              />
            </div>
            {/* STEP 2 : Request, Verify code and Register */}
            <div
              style={{
                display: steps.current === 2 ? 'block' : 'none',
              }}
            >
              <StepValidateOTPAndRegisterChannel
                ref={step2Ref}
                phone_id={parseInt(phoneId)}
                enableNextStep={() => {
                  setUserCanGoStep3(true);
                }}
                onSuccess={() => {
                  steps.next();
                }}
                onError={() => {
                  // setUserCanGoStep3(false);
                }}
              />
            </div>
            {/* STEP 3 : finish */}
            <div style={{ display: steps.current === 3 ? 'block' : 'none' }}>
              <div style={{ margin: '1rem', alignContent: 'center' }}>
                <Container>
                  <IconPlusIcon
                    firstIcon="onlyLogo"
                    secondIcon="poli-icon pi-whatsapp-api-fill"
                    secondColor="var(--success)"
                  />
                  <h2>Migração finalizada com sucesso!</h2>
                  <Button onClick={handleRedirect}>Gerenciar canal</Button>
                </Container>
              </div>
            </div>
          </div>
          {/* ------------------------ */}
          {steps.current !== 3 && (
            <div
              id="flex_item_3"
              style={{
                display: 'grid',
                columnGap: '10px',
                gridTemplateColumns: '1fr 1fr',
                alignItems: 'center',
                justifyItems: 'center',
                marginBottom: '1rem',
              }}
            >
              <div>
                <div
                  style={{ display: steps.current === 0 ? 'none' : 'block' }}
                >
                  {<Button onClick={handlePrev}>Anterior</Button>}
                </div>
              </div>
              <div
              // style={{ display: steps.current === 2 ? 'none' : 'block' }}
              >
                <Button
                  disabled={steps.current === 2 && !userCanGoToStep3}
                  onClick={handleNext}
                >
                  Próximo
                </Button>
              </div>
            </div>
          )}
        </div>
      </PageMainBody>
    </>
  );
}
