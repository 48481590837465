import styled from 'styled-components';

import { Alert } from '@polichat/flamboyant';

export const BannerAlert = styled(Alert)`
  height: 3rem;
  justify-content: center;
  padding: 0.7em 0;
  gap: 1rem;
  margin: 0 0 1rem 0;

  button {
    padding: 0.25rem 2rem;
  }
`;
