import request from '../api-gateway/request'

async function password_recover(email){
     return request({
        method: "POST",
        url: "/users/password-recover",
        data: {
            email: email,
        }
    }).then((response) => response.data);
}

async function password_recover_update(email, password, recover_token){
    return request({
       method: "POST",
       url: "/users/password-recover-update",
       data: {
           email: email,
           password: password,
           recover_token: recover_token,
       }
   }).then((response) => response.data);
}


async function valid_token(recover_token){
    return request({
        method: "POST",
        url: "/users/valid-token",
        data: {
            token: recover_token
        }
    }).then((response) => response.data);
   
}


export {password_recover, valid_token, password_recover_update};