import { calculatePercent } from "./calculatePercent";

export function wabaChannelPercent(data: any, channelId: number){

  const groupId = data[channelId].groupId ?? null
  if (!groupId) return 0;


  const groupCredits = data.wabaExpenditureGroups[groupId];
  if(!groupCredits) return 0;

  const percent  = calculatePercent({
    used: Number(groupCredits.used),
    total: Number(groupCredits.total)
  }, data.detachedCredits)

  return percent
}
