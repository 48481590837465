import React, { useEffect, useState } from 'react';

import {
  Button,
  Icon,
  Input,
  Label,
  MultiSelector,
  Switch
} from '@polichat/flamboyant';

import {
  ActionGroup,
  Container,
  InputItem,
  InputsContainer,
  MultiSelectorContainer,
  Section,
  SwitchContainer
} from './ChannelAddEditBroker.styles';

import { toast } from 'react-toastify';

import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ChannelsService from '../../../../services/tools/channels/channels';
import poliConfig from '../../../../utils/poliConfig';
import ChannelCustomerStatusCodes from '../ChannelCustomerStatusCodes';
import ChannelBaseboardMessenger from '../ChannelDescriptionBanner/ChannelBaseboardMessenger.component';
import ChannelDescriptionBannerMessenger from '../ChannelDescriptionBanner/ChannelDescriptionBannerMessenger.component';
import ButtonDeleteChannel from '../DeleteChannel/DeleteChannel.componet';

export default function ChannelAddEditBroker({
  data: dataFromDad,
  dataSet,
  userAdmPoliGetChannelFromCustomerId,
}) {
  const [userCurrent, setUserCurrent] = useState(null);


  const { name } = dataFromDad || {};
  let usersDad = dataFromDad?.users || [];
  const pagina = dataFromDad?.config?.pagina ?? '';

  const customer_id = useSelector((state) => {
    if (userAdmPoliGetChannelFromCustomerId) {
      return userAdmPoliGetChannelFromCustomerId;
    } else {
      return state.general.current_customer_id;
    }
  });

  // Estados locais para lidar com mudanças nos valores de um input
  const [channelName, setChannelName] = useState(name || '');
  const [channelPagina, setChannelPagina] = useState(pagina || '');
  const [pageIdFB, setSageIdFB] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [channelConnection, setChannelConnection] = useState(dataFromDad?.connection);
  const [showChannelOnWidget, setShowChannelOnWidget] = useState(
    dataFromDad?.display_on_webchat == 1 ?? true
  );
  // Guarda o status do canal de acordo com o backend
  const [channelStatus, setChannelStatus] = useState(
    dataFromDad?.config?.status
  );
  const [allowChannelOptimization, setAllowChannelOptimization] = useState(
    dataFromDad?.config?.optimize ?? true
  );
  const [timeForOptimize, setTimeForOptimize] = useState(
    dataFromDad?.config?.time_for_optimize ?? 0
  );

  // Guarda a escolha do usuário sobre a ativação do canal
  const [activateChannel, setActivateChannel] = useState(
    dataFromDad?.status ?? true
  );
  const [usersOptions, setUsersOptions] = useState([]);
  const [sendButtonDisabled, setSendButtonDisabled] = useState(false);

  /**
   * Pegando o usuario logado no storage
   */
  useEffect(() => {
     /**
     * verifica se a empresa passou pela analise do plano antes de criar canal
     * e não é edição
    */
      if(!historyState?.validated_can_add_channel && !dataFromDad){
        history.push(`/tools/channels/add`)
        return
      }

    const _poliAccessStorage = localStorage.getItem('poli_access');
    const _poliAccess = _poliAccessStorage ? JSON.parse(_poliAccessStorage) : null;
    const user = _poliAccess?.user;
    setUserCurrent(user);
  }, [])
  /* end */

  // Popula a lista de usuários que podem ou não acessar o canal
  useEffect(() => {
    async function fetchUsers() {
      try {
        const users_result = await ChannelsService.getAllUsersByCustomer(
          customer_id
        );
        const { data: users } = users_result;
        //Usado para criar o objeto options que irá alimentar o componente MultiSelector
        if (users && users.length) {
          const userSelected = [];
          const newUsersOptions = users.map((user) => {
            const returnValue = {
              value: user.id,
              label: user.name
            };

            // iniciar com os usuarios que estao salvos no banco
            if (Array.isArray(dataFromDad?.users) &&  dataFromDad?.users?.includes(user.id)) {
              userSelected.push(returnValue);
            }
            return returnValue
          });

          if(poliConfig.bugfix_PS_385) {
            const usersSelected = newUsersOptions.filter((user) =>
              usersDad.includes(user.value)
            );

            setUsersOptions(newUsersOptions);
            setSelectedUsers(usersSelected.length ? usersSelected : newUsersOptions);
          } else {
            setUsersOptions(newUsersOptions);
            setSelectedUsers(newUsersOptions);
          }
        }
        return;
      } catch (error) {
        console.log(
          'Erro no componente ChannelAddEditMessenger na função fetchUsers => ',
          error
        );
        return;
      }
    }

    fetchUsers();
  }, []);

  // Para redirecionar o usuário após criação de um canal
  const history = useHistory();
    // Pegar state enviado pela URL
    const { state: historyState } = history.location

  // Funções utilizadas pelos inputs quando os valores mudarem (OnChange)
  const handleNameChange = (e) => setChannelName(e.target.value);
  const handlePaginaChange = (e) => setChannelPagina(e.target.value);
  const handleGetSelectorValues = (data) => setSelectedUsers(data);
  const handleShowOnWidgetChange = (e) => setShowChannelOnWidget(e.target.checked);
  const handleActivateChannelChange = (e) => setActivateChannel(e.target.checked);
  const handleOptimizeChannelChange = (e) => setAllowChannelOptimization(e.target.checked);
  const handleTimeForOptimizeChange = (e) => setTimeForOptimize(e.target.value);

  /**
   * Conectar e desconecta Canal
   * @returns
   */
  const handleChannelConnection = async () => {
    if (dataFromDad?.id) {
      const newConnection = channelConnection == 1 ? 0 : 1;

      setSendButtonDisabled(true);
      setChannelConnection(newConnection);

      try {
        const channelObject = {
          id: dataFromDad.id,
          customer_id,
          connection: newConnection,
          config: JSON.parse(storageStateFB)
        };

        if (channelConnection == 1) {
          const result = await ChannelsService.disconnectFB(channelObject);
          stepsIntegrationDefault[0].activeStep = true;
          stepsIntegrationDefault[1].show = false;
          setStepsIntegration(stepsIntegrationDefault);
          toast.success(`Canal desconectado com sucesso!`);
          setSendButtonDisabled(false);
          return;
        }

        const result = await ChannelsService.updateChannel(channelObject);
        
        if (result.status == ChannelCustomerStatusCodes.OK && !result?.data?.err ) {
           await ChannelsService.connectFB(channelObject)
           .then(() => {
            toast.success(`Canal ${newConnection == 1 ? 'conectado' : 'desconectado'} com sucesso!`);
            setSendButtonDisabled(false);
            history.push(`/tools/channels/edit/${channelObject.id}`);
           })
           .catch(() => {
            toast.error("Erro ao fazer conexão com o Webhook do Facebook");
           });
          
        } else {
          throw 'Requisição inválida';
        }
        return;
      } catch (error) {
        console.log(
          `Erro ao ${newConnection == 1 ? 'conectar' : 'desconectar'} canal => `,
          error
        );
        toast.error(`Erro ao ${newConnection == 1 ? 'conectar' : 'desconectar'} canal!`);
        setSendButtonDisabled(false);
        setChannelConnection(newConnection == 1 ? 0 : 1);
        return;
      }
    }
  }
  /* End - Conectar e desconecta Canal */

  /**
   * Salvar Formulario
   * @returns
   */
  const handleSendForm = async () => {
    setSendButtonDisabled(true);

    try {
      const channelObject = {
        name: channelName,
        customer_id,
        channel_id: 2, // Sempre será 2 pois é o código do Messenger no backend
        status: activateChannel ? 1 : 0,
        display_on_webchat: showChannelOnWidget ? 1 : 0,
        users: selectedUsers.map((user) => user.value),
        config: {
          // pagina: channelPagina,
          user_id: userCurrent?.id,
          page_id: pageIdFB,
        },
      };

      if (!dataFromDad) {
        channelObject.config = JSON.stringify(channelObject.config);

        // Validar campo nome
        if (!(channelObject?.name?.length > 0)) {
          toast.warn('Nome do canal obrigatório!');
          setSendButtonDisabled(false);
          return
        }
        /* end */

        const result = await ChannelsService.createChannel(channelObject);

        if (result.status == ChannelCustomerStatusCodes.CREATED) {
          toast.success('Canal criado com sucesso!');
          localStorage.removeItem("facebookChannel");
          history.push(`/tools/channels/edit/${result.data.id}`);
        } else {
          const msg = result?.data?.message;
          toast.error(msg ?? 'Erro ao criar o canal');
          setSendButtonDisabled(false);
        }
      } else {
        // Editar um canal existente
        channelObject.id = dataFromDad.id;
        channelObject.uid = dataFromDad.uid;
        channelObject.config = dataFromDad.config;

        // delete channelObject.config;


        const result = await ChannelsService.updateChannel(channelObject);

        if (result.status == ChannelCustomerStatusCodes.OK && !result?.data?.err) {
          toast.success('Canal editado com sucesso!');
          setSendButtonDisabled(false);
          history.push(`/tools/channels/edit/${channelObject.id}`);
        } else {
          throw 'Requisição inválida';
        }
      }
      return;
    } catch (error) {
      console.log(
        `Erro ao ${dataFromDad ? 'editar' : 'criar'} canal => `,
        error
      );
      toast.error(`Erro ao ${dataFromDad ? 'editar' : 'criar'} canal!`);
      setSendButtonDisabled(false);
      return;
    }
  };
  /* End - Salvar Formulario */

  /**
   * ##############################
   * Passos integração Messenger
   */
  const stepsIntegrationDefault = [
    {
      step: 'connection',
      title: 'Conecte a sua conta do Facebook ao Polichat',
      description: 'Você será direcionado ao facebook para que a conexão seja autorizada e configurada.',
      activeStep: false,
      show: true
    },
    {
      step: 'selectPageFB',
      title: 'Seleção de página',
      description: 'Página do facebook vinculado ao canal: ',
      activeStep: false,
      show: true
    }
  ]
  /* end */

  const [stepsIntegration, setStepsIntegration] = useState(stepsIntegrationDefault);
  const [linkFacebook, setLinkFacebook] = useState(null);
  const [storageStateFB, setStorageStateFB] = useState(localStorage.getItem('facebookChannel') ?? null);

  const [intervalID, setInterID] = useState();
  const [isRunIntervalFb, setIsRunIntervalFb] = useState(false);

  /**
   * Apos salvar canal
   * Mostrando no passo selectPageFB o nome da pagina configurado
   */
  useEffect(() => {
    const namePage = dataFromDad?.config?.name;
    if (dataFromDad?.id && namePage) {
      let _stepsIntegration = [...stepsIntegration];
      const index = _stepsIntegration.findIndex(e => e.step === 'selectPageFB')
      _stepsIntegration[index].description += `"${namePage}"`;
      setStepsIntegration(_stepsIntegration);
    }
  }, [dataFromDad?.id]);
  /* end */

  /**
   * Requisição de obter o link de conexão do facebook para integração
   * @returns
   */
   const getLinkFacebook = async () => {
    try {
      const result = await ChannelsService.getLinkFacebook();
      const link = result?.data?.link;
      if (link) setLinkFacebook(link)
    } catch (error) {
      console.error(error);
    }
  }
  /* End */

  /**
   * Montando cada passo de acordo de cada retorno do facebook
   */
  const mountNextStep = () => {
    const data = storageStateFB ? JSON.parse(storageStateFB) : null;
    if (data) {
      let _stepsIntegration = [];

      const stepConnection = stepsIntegration.find(e => e.step === 'connection');

      // Desativando todos os passos anteriores
      if (stepConnection) {
        stepConnection.activeStep = false;
        _stepsIntegration.push(stepConnection);
      }

      // Ativar permissoes do facebook
      if (data?.type == 'app_allowed') {
        const newStep = {
          step: data?.type,
          title: 'Ative as permissões!',
          description: 'Algumas permissões não foram ativadas na hora de vincular. É necessário a permissão de:',
          activeStep: true,
          data: data?.perms,
          show: true,
        };

        _stepsIntegration.push(newStep);
      }

      // Erro ao conectar
      if (data?.type == 'error') {
        const newStep = {
          step: data?.type,
          title: 'Erro ao conectar!',
          description:
            'Não foi possível finalizar o vínculo da sua página ao Polichat.',
          activeStep: true,
          show: true,
        };

        _stepsIntegration.push(newStep);
      }

      // Seleção de página
      if (data?.type == 'showPagesToSelect') {
        const newStep = {
          step: 'selectPageFB',
          title: 'Seleção de página',
          description: 'Selecione apenas uma página para vincular ao canal.',
          activeStep: true,
          data: data?.pages,
          show: true,
        };

        _stepsIntegration.push(newStep);
      }

      // Conta sem permissão
      if (data?.type == 'user_allowed') {
        const newStep = {
          step: data?.type,
          title: 'Conta sem permissão!',
          description: `Parece que você não tem permissões suficientes para gerenciar a página ${data?.page_selected}`,
          activeStep: true,
          show: true,
        };

        _stepsIntegration.push(newStep);
      }

      // Sucesso
      if (data?.type == 'success') {
        const newStep = {
          step: data?.type,
          title: 'Sucesso!',
          description: `Página ${data?.page_selected} conectada com sucesso ao Polichat.`,
          activeStep: true,
          show: true,
        };

        _stepsIntegration.push(newStep);
        onPageFBSelected(data?.page_selected_id)
      }

      // Se Tiver link de retorno
      if (data?.link) {
        setLinkFacebook(data?.link);
      }

      setStepsIntegration(_stepsIntegration);

      // Quais tipos que devo parar o interval
      if (['showPagesToSelect', 'success'].includes(data?.type)) {
        setIsRunIntervalFb(false);
      } else {
        // Se tiver encerrado o interval, irar inicia novamente para diferente passos do if anterior
        if (!isRunIntervalFb) {
          setIsRunIntervalFb(true);
        }
      }
    }
  }
  /* end - mountNextStep */

   /**
   * Monitarando se os dados ja inseridos no localStorage pelo pop-up
   * Verificando a cada 5 segundos, se encontrar o interval encerra.
   */
  useEffect(() => {
    if (isRunIntervalFb) {
      let letintervalID = setInterval(() => {
        const getfacebookChannel = localStorage.getItem('facebookChannel');
        // Se tiver algo no storage do facebook e for diferente do estado (para evitar loop)
        if (getfacebookChannel && getfacebookChannel !== storageStateFB) {
          setIsRunIntervalFb(false);
          setStorageStateFB(getfacebookChannel);
        }
      }, 5000);
      setInterID(letintervalID);
    } else {
      clearInterval(intervalID);
    }
  }, [isRunIntervalFb]);
  /* End */

  /**
   * Função responsavel de monitorar e insirir os dados do retorno do facebook que vem e sai do pop-up
   */
  const onGetDataFacebookReturn = () => {
    setSendButtonDisabled(true);

    /**
     * Monitarando se os dados ja inseridos no localStorage pelo pop-up
     * Verificando a cada 5 segundos, se encontrar o interval encerra.
     */
    setIsRunIntervalFb(true)

    /**
     * Esta sendo monitorado o evento para pegar a messagem dos dados do facebook~
     * Pop-up do retorno do facebook
     */
    window.addEventListener('message', (event) => {
      const facebookChannel = event?.data?.facebookChannel
      if (facebookChannel) {
        localStorage.setItem('facebookChannel', JSON.stringify(facebookChannel));
        closeMe();
      }
    });
    /* end */

    /**
     * Função para fechar pop-up
     */
    function closeMe() {
      try {
        window.close();
      } catch (e) {
        console.error(e);
      }
      try {
        self.close();
      } catch (e) {
        console.error(e);
      }
    }
    /* end - closeMe */

  }
  /* end - onGetDataFacebookReturn */

  /**
   * Se novo canal
   * - Deixar ativo conexão com facebook
   * - Obter o link de conexão do facebook via requisição do laravel
   */
  useEffect(() => {
    if (!dataFromDad?.id || dataFromDad?.connection == 0) {
      /**
       * deixar ativo conexão com facebook
       */
      let _stepsIntegration = [...stepsIntegration];
      _stepsIntegration.map((e) => {
        e.activeStep = true;

        // ocultar outros passos para conectar ao facebook
        if (e.step != 'connection') {
          e.show = false;
        }

        return e;
      });
      setStepsIntegration(_stepsIntegration);
      /* end */

      // buscando o link de conexão do facebook
      getLinkFacebook();

      // Buscando dados apos conexão do facebook
      onGetDataFacebookReturn();
    }
  }, [dataFromDad]);
  /* end */

  /**
   * Toda vez que for alteração o estado do storage do facebook
   */
  useEffect(() => {
    if ((!dataFromDad?.id || dataFromDad?.connection == 0)) {
      mountNextStep();
    }
  }, [storageStateFB, dataFromDad?.id]);
  /* end */

  /**
   * Montando o config com dados do facebook e com a pagina selecionado
   * @param {*} pageId
   */
  const onPageFBSelected = (pageId) => {
    if (pageId) {
      setSendButtonDisabled(false);
      setSageIdFB(pageId);
    }
  }
  /* */

  return (
    <>
      <Container>
        <ChannelDescriptionBannerMessenger />

        <Section>
          {/**
           * Botoes de acao
           * - Conectar / Desconectar Canal
           */}
          {dataFromDad && (
            <ActionGroup
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                margin: '0 0 0.5em auto',
                gap: '0.5em',
              }}
            >
              <Button
                color={channelConnection === 1 ? 'danger' : 'success'}
                size="md"
                disabled={stepsIntegration[0].activeStep}
                style={{
                  display: 'flex',
                  gap: '0.5rem',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  paddingRight: '2rem',
                  paddingLeft: '2rem',
                }}
                onClick={handleChannelConnection}
              >
                <Icon
                  icon="poli-icon pi-integrations-fill"
                  color="#FFFFFF"
                  size="20"
                />
                {'  '}
                {channelConnection === 1 ? 'Desconectar' : 'Conectar'}
              </Button>
              <ButtonDeleteChannel
                channel={dataFromDad}
                customer_id={customer_id}
                size={'md'}
              />
            </ActionGroup>
          )}

          <InputsContainer>
            <InputItem
              style={{ marginTop: `${!dataFromDad?.id ? '1.5rem' : '0px'}` }}
            >
              <Label id="channel-name-label" for="channel-name">
                <Icon
                  icon="poli-icon pi-channels-fill"
                  color="var(--green-polichat)"
                  size="24"
                />
                Nome do Canal
              </Label>

              <Input
                type="text"
                name="channel-name"
                id="channel-name"
                value={channelName}
                onChange={handleNameChange}
                style={{
                  borderColor: 'var(--borderDefault)',
                }}
              />
            </InputItem>

            {/* Desativado por enquanto, pois ainda não existe essa funcionalidade */}
            {/*
            <InputItem>
              <InputItem style={{ marginTop: '1.5rem' }}>
                <Label id="channel-pagina-label" for="channel-pagina">
                  <Icon
                    icon="poli-icon pi-computer-fill"
                    color="#0a9dbf"
                    size="24"
                  />
                  Nome da página
                </Label>

                <Input
                  type="text"
                  name="channel-pagina"
                  id="channel-pagina"
                  value={channelPagina}
                  onChange={handlePaginaChange}
                  style={{
                    borderColor: 'var(--borderDefault)',
                  }}
                />
              </InputItem>
            </InputItem>
            */}

            {/* Exibir canal no Widget do webchat. */}
            <InputsContainer>
              <InputItem style={{ marginTop: '1.5rem' }}>
                <Label id="show-channel-on-widget" for="show-channel-on-widget">
                  <Icon
                    icon="poli-icon pi-channels-fill"
                    color="var(--green-polichat)"
                    size="24"
                  />
                  Exibir canal no Widget do webchat.
                </Label>

                <SwitchContainer>
                  <Switch
                    color="primary"
                    isChecked={showChannelOnWidget}
                    onClick={handleShowOnWidgetChange}
                  />
                  <p>{showChannelOnWidget ? 'Ativado' : 'Desativado'}</p>
                </SwitchContainer>
              </InputItem>
            </InputsContainer>

            {/* Usuários que poderão utilizar este canal */}
            <InputItem style={{ marginTop: '1.0rem' }}>
              <MultiSelectorContainer>
                <Label
                  id="select-users-channel-label"
                  for="select-users-channel"
                >
                  <Icon
                    icon="poli-icon pi-perfil-fill"
                    color={'var(--green-polichat)'}
                    size="24"
                  />
                  Usuários que poderão utilizar este canal
                </Label>
                <MultiSelector
                  name="select-users-channel"
                  id="select-users-channel"
                  options={usersOptions}
                  values={selectedUsers}
                  selectedValues={handleGetSelectorValues}
                />
              </MultiSelectorContainer>
            </InputItem>

            {/* Status do Canal */}
            <InputItem style={{ marginTop: '1.0rem' }}>
              <Label id="channel-name-label" for="channel-name">
                <Icon
                  icon="poli-icon pi-channels-fill"
                  color="var(--green-polichat)"
                  size="24"
                />
                Status do Canal
              </Label>

              <SwitchContainer>
                <Switch
                  color="primary"
                  isChecked={activateChannel}
                  onClick={handleActivateChannelChange}
                />
                <p>{activateChannel ? 'Ativado' : 'Desativado'}</p>
              </SwitchContainer>
            </InputItem>
          </InputsContainer>

          {/**
           * Integração com Messenger
           */}
          {stepsIntegration.map((item, key) => {
            return (
              item.show && (
                <ChannelBaseboardMessenger
                  key={key}
                  step={item.step}
                  title={item.title}
                  description={item.description}
                  activeStep={item.activeStep}
                  linkFacebook={linkFacebook}
                  data={item.data}
                  onPageFBSelected={onPageFBSelected}
                />
              )
            );
          })}
          {/* End */}

          <ActionGroup style={{ marginTop: '3.0rem' }}>
            <Button
              color="primary"
              size={isMobile ? 'md' : 'lg'}
              disabled={sendButtonDisabled}
              onClick={handleSendForm}
            >
              {dataFromDad ? 'Salvar' : 'Criar'} canal
            </Button>

            <Button
              color="danger"
              size={isMobile ? 'md' : 'lg'}
              style={{ width: '9rem' }}
              onClick={() => {
                history.push('/tools/channels');
              }}
            >
              Cancelar
            </Button>
          </ActionGroup>
        </Section>
      </Container>
    </>
  );
}
