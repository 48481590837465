// React
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// UI
import { Modal, ModalHeader, ModalBody } from '@polichat/flamboyant';
import {
  Attachment,
  HappyLineLight,
  PhotoLineLight,
  Record,
  Close,
} from '@poli-ink/react';
import { toast } from 'react-toastify';

import { parseMessageTemplate } from '../../../../../../../../utils/chat/parseMessageTemplate';

import { Preview } from '../Preview';

import { QuickMessageTemplate } from '../../types/types';
import { ModalOptions } from './types';

// CSS
import styles from './ModalMessagePreview.module.css';
import { useQuickMessage } from '../../hooks/useQuickMessage';

export function ModalMessagePreview(props: Readonly<ModalOptions>) {
  const customerFirstLetter = useSelector((state: any) => {
    const customer = state?.general?.user?.customers?.find(
      (customer: any) =>
        (customer.id = state?.general?.user?.current_customer_id)
    );

    return customer?.name[0]?.toUpperCase() ?? '';
  }) as string;

  const { getMessageTextUI } = useQuickMessage();

  /**
   * Utilizando estados distintos para armazenar informações de template
   * Quando criadas via TemplateBuilder o contéudo da propriedade TEXT é um JSON
   * contendo as informações de cabeçalho, rodapé e botões de uma template.
   * caso contrario é apenas uma string
   *  */
  const [onlyText, setOnlyText] = useState<string | null>(null);
  const [message, setMessage] = useState<QuickMessageTemplate | null>(null);

  useEffect(() => {
    if (!props.isOpen) return;

    if (!props.message || !Object.keys(props.message).length) {
      toast.error('Não foi possivel carregar o preview da mensagem!');
      props.handleClose();
      return;
    }

    const convertedTemplate = parseMessageTemplate(props.message.text);

    if (convertedTemplate.isFromMessageBuilder) {
      setMessage(
        convertedTemplate.parsedMessage as unknown as QuickMessageTemplate
      );
      /**
       * TIP: isso não é legal mas resolve o problema: a fn parseMessageTemplate diz que retorna
       * um `body.text : string` mas o que retorna de fato é apenas a string. A alteração do tipo quebra aplicação em outros lugares
       * */
    } else {
      setOnlyText(props.message.text);
    }
  }, [props.message, props.isOpen]);

  const handleCloseModal = useCallback(() => {
    props.handleClose();
    _resetState();
  }, []);

  const _resetState = useCallback(() => {
    setOnlyText(null);
    setMessage(null);
  }, []);

  return (
    <Modal isOpen={props.isOpen} toggle={handleCloseModal}>
      <ModalHeader className={styles.modal_title}>
        <div className="d-flex align-items-center justify-content-between w-100">
          <p>Essa é uma pré-visualização da sua mensagem</p>
          <button onClick={() => handleCloseModal()}>
            <Close />
          </button>
        </div>
      </ModalHeader>
      <ModalBody className={styles.modal_body}>
        <div className={styles.container}>
          <div className={styles.header}>
            <span>{getMessageTextUI(customerFirstLetter, 'quick')}</span>
          </div>
          <div className={styles.body}>
            {onlyText && (
              <Preview.Root>
                <Preview.Body body={getMessageTextUI(onlyText, 'quick')} />
              </Preview.Root>
            )}
            {message && (
              <Preview.Root>
                {message.header.type !== 'none' && (
                  <Preview.Header header={message.header} />
                )}
                {message.body !== '' && <Preview.Body body={message.body} />}
                {message.footer !== '' && (
                  <Preview.Footer footer={message.footer} />
                )}
              </Preview.Root>
            )}

            {/* Template com botões de ações */}
            {message?.buttons && message.buttons.length > 0 && (
              <Preview.Buttons buttons={message.buttons} />
            )}
            {/* Mensagem LISTA DE OPÇÕES */}
            {message?.button && <Preview.Buttons buttonList={message.button} />}
          </div>
          <div className={styles.footer}>
            <div>
              <span>
                <HappyLineLight color="#000" />
              </span>
              <Attachment color="#000" />
              <PhotoLineLight color="#000" />
            </div>
            <span>
              <Record />
            </span>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}
