import { useState } from 'react';
import { Options } from '@poli-ink/react';
import { useWhiteLabel } from 'src/hooks/useWhiteLabel';
import { Dropdown, DropdownMenu } from '@polichat/flamboyant';
import { DropdownToggle } from 'reactstrap';
import WavesurferPlayer from '@wavesurfer/react';
import poliConfig from '../../../../../utils/poliConfig';
import { isIOS, isMacOs } from 'react-device-detect';
import { Loading } from '../../../loading';

export function PlayerAudio({ urlAudio, messageId, messageDirection, downloadURL, reply_message }) {
  const whitelabel = useWhiteLabel();
  const [waveSurfer, setWaveSurfer] = useState(null)
  const [isPlaying, setIsPlaying] = useState(false);
  const [speed, setSpeed] = useState('1.0');
  const [duration, setDuration] = useState();
  const [toggleOptions, setToggleOptions] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const convertAudio = (isIOS || isMacOs) && messageDirection === 'received';
  const audioUrl = convertAudio
    ? `${poliConfig.poliAppLaravelUrl}/api/convert-audio-to-mp3?audio_url=${urlAudio}`
    : urlAudio;

  function onReady(ws) {
    setWaveSurfer(ws);
    setIsPlaying(false);
    setDuration(formatDuration(ws.getDuration()));
    setIsLoading(false);
  }

  function onFinish(ws) {
    waveSurfer && waveSurfer.stop();
    setDuration(formatDuration(0));
  }

  function onAudioProcess(ws) {
    setDuration(formatDuration(ws.getCurrentTime()));
  }

  const onPlayPause = () => {
    waveSurfer && waveSurfer.playPause();
  }

  let waveColor = '#232C3D';
  let progressColor = '#636B7B';
  if (whitelabel.isWl) {
    if (whitelabel.cssPathWl.includes('auvo')) {
      waveColor = '#b499cf';
      progressColor = '#550E9B';
    }
    if (whitelabel.cssPathWl.includes('chatshub')) {
      waveColor = '#ffa26b';
      progressColor = '#ff7038';
    }
    if (whitelabel.cssPathWl.includes('nectarcrm')) {
      waveColor = '#ffe086';
      progressColor = '#fec10d';
    }
  }
  if (messageDirection === 'sent') {
    waveColor = '#DCE4ED';
    progressColor = '#232C3D';
  }

  function formatDuration(seconds) {
    const duration = new Date(seconds * 1000).toLocaleDateString(navigator.language,
      { minute: '2-digit', second: '2-digit' }
    ).substring(12, 20)

    return seconds >= 3600 ? '+60:00' : duration;
  }

  const handleSpeed = () => {
    const speeds = {
      '1.0': '1.5',
      '1.5': '2.0',
      '2.0': '1.0'
    };

    setSpeed(speeds[speed]);
  };

  return (
    <>
      <div className="wrapperAudio">
        <div className="audio-container">
          <button id={`button-${messageId}`} className="playPauseAudio" onClick={onPlayPause}>
              <div className={ isLoading ? '' : (isPlaying ? 'pauseIcon' : 'playIcon') }>
                { isLoading && <Loading size={'sm'} /> }
              </div>
          </button>
          <div className="waves">
          <WavesurferPlayer
              height={40}
              barWidth={2.5}
              barRadius={50}
              barGap={2}
              waveColor={waveColor}
              progressColor={progressColor}
              cursorColor="transparent"
              dragToSeek={true}
              audioRate={+speed}
              onReady={onReady}
              onFinish={onFinish}
              onAudioprocess={onAudioProcess}
              onPlay={() => setIsPlaying(true)}
              onPause={() => setIsPlaying(false)}
              url={audioUrl}
            />
          </div>
          <button
            onClick={handleSpeed}
            className="speedControl"
            style={
              messageDirection === 'sent' ?
                { backgroundColor: 'var(--speed-color-sent)', color: 'var(--secondary-font-color)' } :
                { backgroundColor: 'var(--speed-color-received)', color: 'var(--white-font-color)' }
            }
          >
            <span>{speed}x</span>
          </button>
          <Dropdown isOpen={toggleOptions} toggle={() => setToggleOptions((togglePrev) => !togglePrev)}>
            <DropdownToggle className="optionsAudio" style={{ background: 'transparent !important' }}>
              <Options className="optionsAudioIcon" />
            </DropdownToggle>
            <DropdownMenu className="dropdownOptions">
              <button onClick={reply_message} className="dropdownItem">Responder</button>
              <button onClick={() => window.open(downloadURL)} className="dropdownItem">Baixar</button>
            </DropdownMenu>
          </Dropdown>
        </div>
        <div className="audioTime">{duration}</div>
      </div>
    </>
  );
}
