import { actionNames } from './actions';

const defaultState = {
  access: null,
  login: {
    loading: false,
    error: null,
  },
};

export default function auth(state = defaultState, action) {
  switch (action.type) {
    case actionNames.REQUEST_LOGIN: {
      return { ...state, login: { loading: true, error: null } };
    }

    case actionNames.REQUEST_LOGIN_WL: {
      console.log(`reducer login whitelabel`);
      return { ...state, login: { loading: true, error: null } };
    }

    case actionNames.REQUEST_LOGIN_SUCCESS: {
      return {
        ...state,
        access: action.payload,
        login: { loading: false, error: null },
      };
    }

    case actionNames.REQUEST_LOGIN_FAILURE: {
      const errorObject = Object.assign({}, action.payload, {
        message: action.payload.message,
        code: action.payload.response?.status ?? 0,
        stack: action.payload.stack,
        name: action.payload.name ?? 'General Error',
      });
      return {
        ...state,
        login: {
          loading: false,
          error: errorObject,
        },
      };
    }
    case actionNames.LOGOUT_SUCCESS: {
      return { ...defaultState };
    }

    default:
      return state;
  }
}
