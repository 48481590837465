import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Alert, Button } from '@polichat/flamboyant';
import { toast } from 'react-toastify';

import history from '../../../../services/history';
import WabaService from '../../../../services/tools/template-waba/template-waba';

import { MessagesBuilderImg } from 'src/components/chat/content/chat/modals/src/MessagesBuilderImg.js';

import Animation from '../../../common/animation';
import TablePagination from '../../../shopping/TablePagination';

import PageMainBody from '../../../common/pages/PageMainBody/PageMainBody.component';
import PageMainHeader from '../../../common/pages/PageMainHeader/PageMainHeader.component';
import DeleteTemplateModal from './deleteModal/DeleteTemplateModal.component';
import {
  ButtonsContainer,
  Container,
  MessageBuilderHome,
  MessageBuilderLayout,
} from './ListMessagesBuilder.styles';
import SearchTemplateBar from './searchbar/SearchTemplateBar.component';
import TableMessagesBuilder from './table/TableMessagesBuilder.component';
import { useWhiteLabel } from 'src/hooks/useWhiteLabel';
import { FilterType } from './filtersList/filterType';
import { FilterStatus } from './filtersList/filterStatus';
import { useFlags } from 'flagsmith/react';

let handleOnSearchFilterChangeTimer;

const ListMessagesBuilder = () => {
  const customerID = useSelector((state) => state.general.current_customer_id);
  const [messagesList, setMessagesList] = useState([]);
  const whitelabel = useWhiteLabel();
  const [searchData, setSearchData] = useState('');
  const [paginationData, setPaginationData] = useState({});
  const [isLoadingMessagesTemplate, setIsLoadingMessagesTemplate] =
    useState(false);
  const [hasFirstRequestData, setHasFirstRequestData] = useState(0);
  const [tryFetchDataAgain, setTryFetchDataAgain] = useState(false);

  const [isDeleteTemplateModalOpen, setIsDeleteTemplateModalOpen] =
    useState(false);
  const [templateDeleteData, setTemplateDeleteData] = useState({});
  // const [buttonTemplateSyncDisabled, setButtonTemplateSyncDisabled] = useState(false);
  const [buttonTemplateSyncState, setButtonTemplateSyncState] = useState({
    disabled: false,
    content: 'Sincronizar templates',
    loading: '',
  });
  const [typeValue, setTypeValue] = useState('all');
  const [statusValue, setStatusValue] = useState('all');
  const [disabledFilterStatus, setDisabledFilterStatus] = useState(true)
  const flag = useFlags(['filter_by_type']);

  async function fetchTemplateList(fetchData) {
    setIsLoadingMessagesTemplate(true);
    try {
      const { data, pagination } = await WabaService.getTemplatesWaba({
        customer_id: customerID,
        page: fetchData?.currentPage || '',
        q: fetchData?.search || '',
        type: fetchData?.type || null,
        status: fetchData?.status || null
      });
      if (data || pagination) {
        setMessagesList(data);
        setPaginationData(pagination);
        setTryFetchDataAgain(false);
        setHasFirstRequestData((old) => old + 1);
      } else {
        setTryFetchDataAgain(true);
      }
    } catch (error) {
      console.log(
        'Error in ListMessagesBuilder component when fetchTemplates => ',
        error
      );
      setTryFetchDataAgain(true);
    }
    setIsLoadingMessagesTemplate(false);
  }

  async function syncTemplates() {
    const errorMessage =
      'Ocorreu um problema ao tentar sincronizar seus templates. Por favor tente novamente em alguns instantes ou entre em contato com o time de suporte.';
    // setIsLoadingMessagesTemplate(true);
    setButtonTemplateSyncState({
      disabled: true,
      content: (
        <span>
          <Animation
            icon="loading"
            style={{
              float: 'left',
              height: 20,
              width: 20,
              margin: '0 5px 0 0',
              borderColor: '#FFFFFF'
            }}
          />
          {'Sincronizando...'}
        </span>
      ),
      loading: '', // <Animation icon="chat-loading" />
    });
    try {
      const { data } = await WabaService.syncTemplates({
        customer_id: customerID,
      });
      if (!(data && data.error)) {
        toast.success(data?.message ?? 'Sincronização concluída.');
      } else {
        toast.error(data?.message ?? errorMessage);
      }
    } catch (error) {
      toast.error(errorMessage);
      console.log(
        'Error in ListMessagesBuilder component when syncTemplates => ',
        error
      );
    }
    // setIsLoadingMessagesTemplate(false);
    setButtonTemplateSyncState({
      disabled: false,
      content: 'Sincronizar templates',
      loading: '',
    });
    await fetchTemplateList();
  }

  useEffect(() => {
    fetchTemplateList();
  }, []);

  const handleSyncTemplates = () => {
    syncTemplates();
  };

  const goToTutorials = () => {
    window.open(
      'https://www.youtube.com/channel/UC6F-t3Qvgo2zMbEGICLQYIg',
      '_blank'
    );
  };
  const goToCreateTemplate = () => {
    history.push({
      pathname: '/tools/messages-builder/add',
    });
  };

  const handleChangePaginationRequest = (paginationData) => {
    const { currentPage } = paginationData;
    fetchTemplateList({ currentPage, type: typeValue, status: statusValue });
  };

  const handleChangeSearchRequest = (searchData) => {
    setSearchData(searchData?.search || '');

    clearTimeout(handleOnSearchFilterChangeTimer);
    handleOnSearchFilterChangeTimer = setTimeout(() => {
      fetchTemplateList(searchData);
    }, 600);
  };

  async function handleChangeTypeFilter(value) {
    if (value === 'template') {
      setDisabledFilterStatus(false)
      setTypeValue(value)
      fetchTemplateList({ type: value, status: statusValue })
    } else {
      setDisabledFilterStatus(true)
      setStatusValue('all')
      setTypeValue(value)
      fetchTemplateList({ type: value, status: 'all' })
    }
  }

  async function handleChangeStatusFilter(value) {
    setStatusValue(value)
    fetchTemplateList({ status: value, type: typeValue })
  }

  const handleOptionClicked = ({ id, option, template_name, tag }) => {
    if (option === 'delete') {
      setIsDeleteTemplateModalOpen(true);
      setTemplateDeleteData({ id, template_name, tag });
    }
    if (option === 'edit') {
      history.push({
        pathname: `/tools/messages-builder/edit/${id}`,
      });
    }
  };

  function handleCloseDeleteTemplateModal() {
    setIsDeleteTemplateModalOpen(false);
  }

  async function handleDeleteTemplate(id) {
    let params = { template_id: id, customer_id: customerID };

    const result = await WabaService.deleteTemplateWaba(params);
    if (result.error) {
      toast.error('Erro ao excluir template!');
    }
    if (result.success) {
      toast.success('Template excluido!');
      setMessagesList(messagesList.filter((item) => item.id !== id));
    }
  }

  const colsTitle = [
    { colID: 1, colTitle: '#Tag' },
    { colID: 2, colTitle: 'Tipo' },
    { colID: 3, colTitle: 'Categoria' },
    { colID: 4, colTitle: 'Conteúdo' },
    { colID: 5, colTitle: 'Atalho' },
    { colID: 6, colTitle: 'Status' },
    { colID: 7, colTitle: '' },
  ];
  return (
    <>
      <PageMainHeader
        title={`Construtor de ${whitelabel.isWl ? 'Mensagem rápida' : 'Template'}`}
        icon="poli-icon pi-message-builder-fill"
      >
        {(messagesList && !whitelabel.isWl || (messagesList && whitelabel.spaTitle === 'NectarCRM')) && (
          <>
            {/* <Button gradient="primary" onClick={goToCreateTemplate}>
              Criar uma mensagem
            </Button> */}
            {buttonTemplateSyncState.loading}
            <Button
              disabled={buttonTemplateSyncState.disabled}
              gradient="primary"
              onClick={handleSyncTemplates}
            >
              {buttonTemplateSyncState.content}
            </Button>
            {!whitelabel.isWl && 
              <Button color="primary" outline onClick={goToTutorials}>
                Explore nossos tutoriais
              </Button>
            }
          </>
        )}
      </PageMainHeader>
      <PageMainBody>
        <Container>
          {!messagesList.length && hasFirstRequestData <= 1 ? (
            <MessageBuilderHome>
              <MessagesBuilderImg />
              <p>
                Crie um template, mensagem rápida ou mensagem para automatização
              </p>

              {isLoadingMessagesTemplate ? (
                <Animation icon="loading" />
              ) : (
                <>
                  <p>
                    {/* TODO tirar o termo Poli */}
                    Com o criador de mensagens você pode criar e
                    visualizar um exemplo do que você criou de forma simples e
                    rápida
                  </p>
                  <ButtonsContainer>
                    {tryFetchDataAgain ? (
                      <Button gradient="primary" onClick={fetchTemplateList}>
                        Tentar novamente
                      </Button>
                    ) : (
                      <>
                        <Button gradient="primary" onClick={goToCreateTemplate}>
                          Criar uma mensagem
                        </Button>
                      </>
                    )}
                  </ButtonsContainer>
                </>
              )}
            </MessageBuilderHome>
          ) : (
            <MessageBuilderLayout>
              <div className="header">
                {flag.filter_by_type?.enabled && (
                  <>
                    <FilterType
                      handleChangeTypeFilter={(event) => handleChangeTypeFilter(event.target.value)}
                      typeValue={typeValue}
                    />
                    <FilterStatus
                      disabled={disabledFilterStatus}
                      handleChangeStatusFilter={(event) => handleChangeStatusFilter(event.target.value)}
                      statusValue={statusValue}
                    />
                  </>
                )}
                <SearchTemplateBar
                  onSearchChange={handleChangeSearchRequest}
                  initialValue={searchData}
                />
              </div>
              <div className="body">
                {messagesList.length ? (
                  <TableMessagesBuilder
                    cols={colsTitle}
                    rows={messagesList}
                    isLoading={isLoadingMessagesTemplate}
                    onOptionsClick={handleOptionClicked}
                  />
                ) : (
                  <Alert color="attention">
                    Não há registros para essa busca
                  </Alert>
                )}
              </div>
              <div className="footer">
                <TablePagination
                  totalItens={paginationData.totalRegisters}
                  limitItens={paginationData.registersPerPage}
                  pageSide={paginationData.currentPage}
                  onPageChange={handleChangePaginationRequest}
                />
              </div>
            </MessageBuilderLayout>
          )}
        </Container>
      </PageMainBody>
      <DeleteTemplateModal
        isOpen={isDeleteTemplateModalOpen}
        onCloseRequest={handleCloseDeleteTemplateModal}
        data={templateDeleteData}
        onDeleteRequest={handleDeleteTemplate}
      />
    </>
  );
};

export default ListMessagesBuilder;
