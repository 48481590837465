/**
 *  === PLAYER COMPONENT ===
 *    Este componente é responsável por criar uma interface de usuário (UI) de audio utilizando a biblioteca Howler.js
 *    O Howler é um recurso poderoso para padronizar recursos de áudio dentro de uma aplicação, ou seja, manterá o mesmo comportamento em qualquer navegador.
 *
 *  === FOLHA DE ESTILO ===
 *   Existe uma folha de estilos atribuída para este componente de forma que seja reutilizável por todo o código.
 *
 *  === COMPONENTE DE CLASSE ===
 *    Decidi escrever esse componente em formato de Classe porque é o recomendado pela biblioteca Howler
 *    e também por ter mais controle sobre o ciclo de vida deste componente que estará presente em toda a aplicação.
 *
 *  === AUDIO/MPEG ===
 *    A web audio api não suporta alguns tipos de codecs e o m2a ou mpeg estão entre eles.
 *    Por isso existe uma verificação dentro do estado (variavel: html5) que verifica se o audio a ser renderizado é neste formato.
 *    Caso seja, o Howler utilizará a tag audio do html5 ao invés da Web Audio API.
 */

import React from 'react';
import ReactHowler from 'react-howler';
import raf from 'raf'; // usado para criar o polyfill em navegadores mais obsoletos
import { Button, Input, Icon } from '@polichat/flamboyant';
import { isMobile } from 'react-device-detect';
import './Player.css';
import Animation from '../../../common/animation';

const typesHtml = ['audio/mpeg', 'audio/mp4', 'audio/mp3'];

class Player extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      soundURL: props.soundURL,
      isLoading: props.isLoading,
      hasBase64Url: props.hasBase64Url,
      // html5 is a state used to callback when codec == m2a or mpeg
      // html5: props.mimeType === 'audio/mpeg' ? true : false,
      html5: props.mimeType ? typesHtml.includes(props.mimeType) : true,
      playing: false,
      loaded: false,
      loop: false,
      mute: false,
      volume: 1.0,
      seek: 0.0,
      isSeeking: false,
      downloadURL: props.downloadURL,
    };

    // Binding actions => necessário quando usamos class component
    this.handleToggle = this.handleToggle.bind(this);
    this.handleOnLoad = this.handleOnLoad.bind(this);
    this.handleOnEnd = this.handleOnEnd.bind(this);
    this.handleOnPlay = this.handleOnPlay.bind(this);
    this.renderSeekPos = this.renderSeekPos.bind(this);
    this.handleLoopToggle = this.handleLoopToggle.bind(this);
    this.handleMuteToggle = this.handleMuteToggle.bind(this);
    this.handleMouseDownSeek = this.handleMouseDownSeek.bind(this);
    this.handleMouseUpSeek = this.handleMouseUpSeek.bind(this);
    this.handleSeekingChange = this.handleSeekingChange.bind(this);
  }

  componentWillUnmount() {
    this.clearRAF();
  }

  handleToggle() {
    this.setState({
      playing: !this.state.playing,
    });
  }

  handleOnLoad() {
    this.setState({
      loaded: true,
      duration: this.player.duration(),
    });
  }

  handleOnPlay() {
    this.setState({
      //    playing: true,  // Desativado para corrigir loop de áudio.
      loop: false,
    });
    this.renderSeekPos();
  }

  handleOnEnd() {
    this.player.stop();
    this.setState({
      playing: false, // Need to update our local state so we don't immediately invoke autoplay
      loop: false,
    });
    // this.renderSeekPos(); // em caso de loop desativar esse renderSeekPos
    this.clearRAF();
  }

  handleLoopToggle() {
    this.setState({
      loop: false,
    });
  }

  handleMuteToggle() {
    this.setState({
      mute: !this.state.mute,
    });
  }

  handleMouseDownSeek() {
    this.setState({
      isSeeking: true,
      loop: false,
    });
  }

  handleMouseUpSeek(e) {
    this.setState({
      isSeeking: false,
      loop: false,
    });

    this.player.seek(e.target.value);
  }

  handleSeekingChange(e) {
    /**
     * Caso o player seja html5, então ao clicar no range ele dá um stop no audio.
     * Isto serve para evitar que várias instâncias desse audio sejam executadas ao mesmo tempo.
     */
    if (this.state.html5) {
      this.player.stop();
      this.setState({
        playing: false,
      });
    }

    this.setState({
      seek: parseFloat(e.target.value),
      loop: false,
    });
  }

  renderSeekPos() {
    if (!this.state.isSeeking) {
      this.setState({
        seek: this.player.seek(),
      });
    }
    if (this.state.playing) {
      this._raf = raf(this.renderSeekPos);
    }
  }

  clearRAF() {
    raf.cancel(this._raf);
  }

  renderInput() {
    return (
      <div className="container__seek">
        <Input
          type="range"
          min="0"
          max={this.state.duration ? this.state.duration.toFixed(2) : 0}
          step=".01"
          value={this.state.seek}
          onChange={this.handleSeekingChange}
          onMouseDown={this.handleMouseDownSeek}
          onMouseUp={this.handleMouseUpSeek}
        />
      </div>
    );
  }

  renderButtonDownload() {
    // caso a URL de download não seja passada, não exibir o botão de download
    if (!isMobile && this.state.downloadURL && !this.state.hasBase64Url) {
      return (
        <div className="container__third-element">
          <Button
            color="link"
            onClick={() => window.open(this.state.downloadURL)}
          >
            <Icon
              icon="poli-icon pi-download-fill"
              size={25}
              color="var(--secondary-font-color"
            />
          </Button>
        </div>
      );
    }
    return <></>;
  }

  render() {
    if (this.state.isLoading && !this.state.hasBase64Url) {
      return (
        <div className="container__audio-control">
          <div className="container__first-element">
            <Icon
              size={25}
              icon="poli-icon pi-microphone-fill"
              color={'var(--icon-color-font)'}
            />
          </div>

          <div className="container__second-element">
            <Button color="link">
              <Animation icon="loading" size={'tinyRem'} />
            </Button>
            {this.renderInput()}
          </div>

          {this.renderButtonDownload()}
        </div>
      );
    }

    return (
      <div className="audio-control">
        {this.state.soundURL && this.state.soundURL !== '' ? (
          <>
            <ReactHowler
              html5={this.state.html5}
              src={`${this.state.soundURL}`}
              playing={this.state.playing}
              onLoad={this.handleOnLoad}
              onPlay={this.handleOnPlay}
              onEnd={this.handleOnEnd}
              volume={this.state.volume}
              ref={(ref) => (this.player = ref)}
              format={['mp3']}
            />
            <div className="container__audio-control">
              <div className="container__first-element">
                <Icon
                  size={25}
                  icon="poli-icon pi-microphone-fill"
                  color={'var(--icon-color-font)'}
                />
              </div>

              <div className="container__second-element">
                {this.state.isLoading && !this.state.hasBase64Url ? (
                  <Button color="link">
                    <Animation icon="loading" size={'tinyRem'} />
                  </Button>
                ) : (
                  <Button color="link" onClick={this.handleToggle}>
                    {this.state.playing ? (
                      <Icon
                        icon="poli-icon pi-video-pause-fill"
                        size={29}
                        color={'var(--color-primary)'}
                      />
                    ) : (
                      <Icon
                        icon="poli-icon pi-video-play-fill"
                        size={29}
                        color={'var(--color-primary)'}
                      />
                    )}
                  </Button>
                )}

                {this.renderInput()}
              </div>

              {this.renderButtonDownload()}
            </div>
          </>
        ) : (
          <p
            style={{
              fontStyle: 'italic',
              background: 'rgba(0,0,0,0.5)',
              borderRadius: '5px',
              padding: '8px 12px',
            }}
          >
            Áudio corrompido, solicite um novo envio.
          </p>
        )}
      </div>
    );
  }
}

export default Player;
