import { laravelAxios } from 'src/config/axios/laravelAxios';
import { DtoGateway } from './types';

export class PaymentIntegratorService {
  static async list() {
    const url = `/shopping/gateway`;
    const result = await laravelAxios.get<DtoGateway[]>(url);
    return result.data;
  }
}
