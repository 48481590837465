import styled from 'styled-components';

export const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  overflow-y: auto;
  padding: 0 1rem;
  padding-bottom: 0.85rem;
  border-bottom: 1px solid var(--border-color);

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    margin: 0;
    display: block;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.85rem;
  border-right: 1px solid var(--border-color);

  @media (max-width: 600px) {
    width: 100%;
    border-right: none;
  }
`;

export const StepsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 2rem;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0;
  }
`;

export const InformationalTextContainer = styled.div`
  font-size: 1.3rem;
  color: var(--secondary-font-color);
`;

export const StepsItemContainer = styled.div`
  display: flex;
  justify-content: start;
  width: 100%;
  font-size: 0.9rem;
  margin-top: 0.5rem;
  gap: 6rem;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
`;

export const StepItem = styled.div`
  /*cursor: pointer;*/
  display: flex;
  align-items: center;
  justify-content: space-between;

  & i {
    color: ${(prop) =>
      prop.passed ? 'var(--link-button-dark)' : 'var(--secondary-font-color)'};
  }

  & span {
    color: ${(prop) =>
      prop.passed ? 'var(--link-button-dark)' : 'var(--secondary-font-color)'};
    margin-left: 0.1rem;
  }

  @media (max-width: 600px) {
    margin-top: 0.3rem;
  }
`;
