import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-M2LRLQN'
}

function LoadScriptsExternal({isAuthArea}) {
    const authUser = useSelector( (state) => state?.chat?.config?.user);
    useEffect(()=>{
        if(authUser) {
            let timeNow = Date.now();

            let userCreatedAt = new Date(authUser.created_at);
            let timeUser = timeNow - userCreatedAt.getTime();

            window._poli = {
                id: authUser.id,
                email: authUser.email,
                name: authUser.name,
                created_at: authUser.created_at.replace(/ .*/,""),
                permission: authUser.superRole,
                age_days: Math.ceil(timeUser/86400000),
                account: {
                    // completeRegistration: true,
                    // trial_ends_at: '2020-05-26T17:58:14+02:00',
                    // paying_customer: true,
                }
            };

            for(let customer of authUser.customers) {
                if(customer.id == authUser.current_customer_id) {
                    window._poli.account=Object.assign(customer,{});

                    let accountCreatedAt = new Date(customer.created_at);
                    window._poli.account.created_at =  customer.created_at.replace(/ .*/,"");

                    let timeAccount = timeNow - accountCreatedAt.getTime();
                    window._poli.account.age_days =  Math.ceil(timeAccount/86400000);
                }
            }

            const tagManagerArgs = {
                gtmId: 'GTM-M2LRLQN'
            }
            TagManager.initialize(tagManagerArgs)
        }
        else if(!isAuthArea) {
            TagManager.initialize(tagManagerArgs)
        }

    },[authUser]);
    
    useEffect(() => {
        (function (c, l, a, r, i, t, y) {
            c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments); };
            t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i + "?ref=gtm2";
            y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);

            function getDataFromSources() {
                if (typeof window._poli !== 'undefined' && window._poli.id && window._poli.account && window._poli.account.id) {
                    return {
                        userId: window._poli.id.toString(),
                        accountId: window._poli.account.id.toString(),
                    };
                }
                return null;
            }

            setTimeout(function () {
                let userData = getDataFromSources();

                if (userData && userData.userId && userData.accountId) {
                    clarity("set", "userId", userData.userId);
                    clarity("set", "accountId", userData.accountId);
                }
            }, 1000);
        })(window, document, "clarity", "script", "fqo9f8ulad");
    }, []);

    return null;
};

export default LoadScriptsExternal;
