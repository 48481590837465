import { Icon } from '@polichat/flamboyant';
import { EmptyWrapper, ImageWrapper, VideoWrapper } from '../style/recents';

import { EnumMetricFormat } from '../../../../../services/meta-ads/dto/dtoMetaAdsMetricData';
import { useState } from 'react';
// import Lightbox from '../../../../../components/common/lightbox';
interface MetricResourceProps {
  format?: string | null;
  url?: string | null;

  textHelper: string;
}
export function MetricResource(props: MetricResourceProps) {
  const { format, url, textHelper } = props;

  const [isErrorAtDownloadContent, setIsErrorAtDownloadContent] =
    useState(false);

  // ---------------------------------------------
  // Transformations

  function handleImageError() {
    setIsErrorAtDownloadContent(true);
  }
  // ---------------------------------------------
  // Render

  return (
    <div>
      {/* AD with IMAGE content */}
      {format &&
        url &&
        !isErrorAtDownloadContent &&
        format === EnumMetricFormat.IMAGE && (
          <ImageWrapper>
            <img
              src={url}
              onError={(e) => handleImageError()}
              alt={textHelper}
            />
          </ImageWrapper>
        )}
      {/* AD with VIDEO content */}
      {format &&
        url &&
        !isErrorAtDownloadContent &&
        format === EnumMetricFormat.VIDEO && (
          <VideoWrapper>
            <Icon icon={'poli-icon pi-video-play-line'} size={40} />
            {/* TODO: refactor show video modal */}
            {/* <Lightbox
              src={url}
              type={format}
              width="98"
              thumb
              authorContent={
                <div>
                  <Icon icon={'poli-icon pi-alerts-fill'} size={20} />
                  <span>{textHelper}</span>
                </div>
              }
            /> */}
          </VideoWrapper>
        )}
      {/* AD without content or url content */}
      {(!format || !url || isErrorAtDownloadContent) && (
        <EmptyWrapper>
          <Icon icon={'poli-icon pi-alerts-fill'} size={40} />
        </EmptyWrapper>
      )}
    </div>
  );
}
