import { getPlansInLaravel, getImportList, getPlanInLaravel } from '../../../services/admin/plans/plans';
import { getServicesInLaravel } from '../../../services/admin/services/services'

function modelPlan(plans) {

  if(!(plans && plans instanceof Array)) {
    return [];
  }

  let tempPlans = [];

  const idPlansList = Array.from(new Set(plans?.map(plan => plan.id)));

  idPlansList.forEach((idPlan) => {
    const itemsPlan = plans?.filter(plan => plan.id === idPlan);
    const fistItemPlan = itemsPlan[0];

    let objNewPlan = {
      id: fistItemPlan.id,
      name: fistItemPlan.name,
      description: fistItemPlan.description,
      value: fistItemPlan.total,
      status: fistItemPlan.status,
      isTrial: fistItemPlan.isTrial,
      itemsPlan: []
    };

    itemsPlan.forEach((itemPlan) => {
      objNewPlan.itemsPlan.push({
        id: itemPlan.planItemId,
        serviceId: itemPlan.serviceId,
        planId: idPlan,
        quantity: itemPlan.quantity,
        service: {
          id: itemPlan.serviceId,
          name: itemPlan.serviceName,
          description: itemPlan.serviceDescription,
          value: itemPlan.serviceValue
        }
      });
    });

    tempPlans.push(objNewPlan);

  });

  return tempPlans;
}

function modelServices(services) {
  return services;
}

async function getPlans() {
  const response = await getPlansInLaravel();
  const plans = response?.data;
  return modelPlan(plans);
}

async function getImportPlans(){
  const data = await getImportList();
  return data?.data;
}

async function getPlan(id) { // TODO criar um controller no backend para fazer este serviço
  const response = await getPlansInLaravel();
  const plans = response?.data;
  return modelPlan(plans).find(plan => String(plan.id) === String(id));
}

async function getServices() {
  const response = await getServicesInLaravel();
  const services = response?.data;
  return modelServices(services);
}

async function getFullPlan(id) {
  const response = await getPlanInLaravel(id);
  return response.data;
}

export {getPlans, getPlan, getServices, getImportPlans, getFullPlan};
