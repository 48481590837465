import { ImageContent } from '../ImageContent';
import PerformaceOperator from '../../../img/PerformaceOperator';

export function SubMenuWelcome() {
  return (
    <ImageContent
      ImgElement={PerformaceOperator}
      title='Acompanhe o desempenho dos seus operadores!'
      subTitle='Saiba exatamente como cada pessoa da sua equipe está performando.'
    />
  );
}