import { useEffect, useState } from 'react'
import {Modal, ModalHeader, CustomText} from '@polichat/flamboyant'

import { Container, VariableItem } from './VariableModal.styles'

function VariableModal({
  isOpen,
  onCloseRequest,
  variables,
  onUpdate
}) {

  const [variableData, setVariableData] = useState({
    search: '',
    variables,
  });
  const [filteredVariables, setFilteredVariables] = useState(variables);

  const handleCloseRequest = () => {
    // Reset search filter before closing modal
    setFilteredVariables(variableData.variables);
    onCloseRequest();
  }

  const handleInputChange = ({target: {name, value}}) => {
    setVariableData({
      ...variableData,
      [name]: value
    });
  }

  const handleSelectVariable = (selectedData) => {
    onUpdate(selectedData);
    handleCloseRequest();
  }

  useEffect(() => {
    const filteredVariables = variableData.variables.filter(variable => {
      return variable.label.toLowerCase().includes(variableData.search.toLowerCase());
    });
    setFilteredVariables(filteredVariables);
  }, [variableData.search])


  return (
    <Modal isOpen={isOpen} toggle={handleCloseRequest}>
      <ModalHeader toggle={handleCloseRequest}>Variáveis</ModalHeader>
        <Container>
          <div>
            <p>Você pode adicionar variáveis no texto da mensagem clicando sobre o nome da variável</p>
            <CustomText
              type="text"
              id="variable-search"
              name="search"
              onChange={handleInputChange}
            />
          </div>
          <div>
            <ul>
              {filteredVariables.map(variable => (
                <VariableItem
                  key={variable.id}
                  onClick={() => handleSelectVariable(variable)}
                >
                  <span>{variable.label}</span>
                  <span>{variable.description}</span>
                </VariableItem>
              ))}
            </ul>
          </div>
        </Container>
    </Modal>
  );
}

export default VariableModal;
