import { DtoLogsChannel } from '../../types/dtoLogsChannel';
import { DtoData } from '../../types/dtoAllChannels';
import { ListParams, ListParamsUserChannel } from './params';
import { DtoAllUsers } from '../../types/dtoAllUsers';
import { DtoPaginatedResult } from '../../types/dtoPagination';
import poliApiGatewayRequest from '../../../../../../utils/poliApiGatewayRequest';
import poliConfig from '../../../../../../../src/utils/poliConfig';

export class LogsChannelService {
  static async getLogsChannels(params?: ListParams) {
    const url = `${poliConfig.apiGatewayBaseURL}/channel-customer/logs/channels`;
    const response = await poliApiGatewayRequest.get<
      DtoPaginatedResult<DtoLogsChannel>
    >(url, { params });
    return response.data;
  }
  static async getAllChannels(params?: ListParamsUserChannel) {
    const url = `${poliConfig.apiGatewayBaseURL}/channel-customer`;
    const response = await poliApiGatewayRequest.get<DtoData[]>(url, {
      params,
    });
    return response.data;
  }
  static async getAllusers(params?: ListParamsUserChannel) {
    const url = `${poliConfig.apiGatewayBaseURL}/channel-customer/users`;
    const response = await poliApiGatewayRequest.get<DtoAllUsers[]>(url, {
      params,
    });

    return response.data;
  }
}
