import {
  CustomModal,
  CustomModalBody,
  CustomModalFooter,
} from './index.styles';

// @ts-ignore
import { Button, Icon } from '@polichat/flamboyant';

type Data = {
  title: string;
  description: string;
  icon: string;
  colorClass: string;
};

interface OnConfirmed {
  (confirmed: boolean): void;
}

interface FooProp {
  onConfirmed: OnConfirmed;
  isOpen: boolean;
  data: Data;
  isLoading?: boolean;
}

const CreditModalAdd = (props: FooProp) => {
  const { onConfirmed, isOpen, data, isLoading } = props;

  return (
    <>
      <CustomModal isOpen={isOpen}>
        <CustomModalBody>
          <div className="modal-title">
            <div className="text-center">
              <div className={`icone-title ${data?.colorClass}`}>
                <Icon icon={data?.icon} />
              </div>
              <div>
                <span>{data?.title}</span>
              </div>
            </div>
          </div>

          <span>{data?.description}</span>
        </CustomModalBody>

        <CustomModalFooter>
          <Button
            className="btn-block"
            color="primary"
            disabled={isLoading}
            onClick={() => {
              onConfirmed(false);
            }}
          >
            <span>NÃO</span>
          </Button>

          <Button
            className="btn-block"
            outline
            disabled={isLoading}
            onClick={() => {
              onConfirmed(true);
            }}
          >
            <span>{isLoading ? 'Salvando...' : 'SIM'}</span>
          </Button>
        </CustomModalFooter>
      </CustomModal>
    </>
  );
};

export default CreditModalAdd;
