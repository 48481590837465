import { useState } from 'react';
import {
  FormGroup,
  Label,
  Col,
  Input,
  Button,
  CustomPhoneInput,
  FormFeedback,
} from '@polichat/flamboyant';

const actionsType = [
  { id: 1, type: 'call', description: 'Ligar' },
  { id: 2, type: 'link', description: 'Acessar Site' },
];

export interface CreateButtonButtonsMessageProps {
  id: number;
  removeButton(value: any): void;
  onUpdate(value: any): void;
  initialValue: initialValueProps;
  isEdit: boolean;
}

export interface initialValueProps {
  id: any;
  type: any;
  text: any;
  actionType: any;
  actionText: any;
};

export function ModalButton(props: CreateButtonButtonsMessageProps) {
  const [buttonData, setButtonData] = useState(props.initialValue);

  const handleInputsChange = ({ target: { name, value } }: any) => {
    setButtonData({
      ...buttonData,
      [name]: value,
    });
    handleButtonData({
      ...buttonData,
      [name]: value,
    });
  };

  const handleWithDeleteButton = () => {
    setButtonData({
      text: undefined,
      actionText: '',
      id: undefined,
      type: '',
      actionType: '',
    });
    props.removeButton(props.id);
  };

  const handleButtonData = ({
    id,
    type,
    text,
    actionType,
    actionText,
  }: initialValueProps) => {
    if (type === 'reply') {
      actionType = '';
      actionText = '';
    }

    props.onUpdate({ id, type, text, actionType, actionText });
  };

  // https://stackoverflow.com/a/43467144
  const isValidURL = (string: string) => {
    let url;

    if (string.indexOf(' ') >= 0) {
      return false;
    }
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === 'http:' || url.protocol === 'https:';
  };

  return (
    <>
      {buttonData?.type === 'reply' && (
        <>
          <Col sm={12} md={8}>
            <FormGroup>
              <Input
                type="text"
                id={'button-text' + props.id}
                name="text"
                maxLength="20"
                placeholder="Texto do botão"
                value={buttonData?.text}
                onChange={handleInputsChange}
                disabled={props.isEdit}
                required
              />
            </FormGroup>
          </Col>
          <Col sm={{ size: 6, offset: 4 }} md={{ size: 2, offset: 0 }}>
            <FormGroup>
              <Button
                color="danger"
                onClick={handleWithDeleteButton}
                disabled={props.isEdit}
              >
                Excluir
              </Button>
            </FormGroup>
          </Col>
        </>
      )}
      {buttonData?.type === 'action' && (
        <>
          <Col sm={12} md={6}>
            <FormGroup>
              <Label>Ação do botão:</Label>
              <Input
                type="select"
                id={'action-type' + props.id}
                name="actionType"
                maxLength="20"
                value={buttonData?.actionType}
                onChange={handleInputsChange}
                disabled={props.isEdit}
                required
              >
                {actionsType.map((btn) => (
                  <option key={btn.id} value={btn.type}>
                    {btn.description}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col sm={12} md={6}>
            <FormGroup>
              <Label>Texto do botão:</Label>
              <Input
                type="text"
                id={'button-text' + props.id}
                maxLength="20"
                name="text"
                value={buttonData?.text}
                placeholder="Texto do botão"
                onChange={handleInputsChange}
                disabled={props.isEdit}
                required
              />
            </FormGroup>
          </Col>
          <Col sm={12} md={6}>
            {buttonData?.actionType === 'call' && (
              <>
                <FormGroup>
                  <Label>Telefone:</Label>
                  <CustomPhoneInput
                    id={'action-text-call' + props.id}
                    name="actionText"
                    country={'br'}
                    autoFormat={true}
                    value={buttonData?.actionText}
                    onChange={(value: any) =>
                      handleInputsChange({
                        target: { name: 'actionText', value },
                      })
                    }
                    placeholder="+55 (62) 9999-9999"
                    disabled={props.isEdit}
                  />
                </FormGroup>
              </>
            )}
            {buttonData?.actionType === 'link' && (
              <>
                <FormGroup>
                  <Label>Site:</Label>
                  <Input
                    type="url"
                    id={'action-text-link' + props.id}
                    name="actionText"
                    placeholder="Link do seu site"
                    value={buttonData?.actionText}
                    onChange={handleInputsChange}
                    disabled={props.isEdit}
                    required
                    invalid={
                      buttonData?.actionText !== '' &&
                      !isValidURL(buttonData?.actionText)
                    }
                  />
                  <FormFeedback>Por favor insira uma URL válida</FormFeedback>
                </FormGroup>
              </>
            )}
          </Col>
          <Col sm={{ size: 8, offset: 5 }}>
            <FormGroup>
              <Label></Label>
              <Button
                color="danger"
                onClick={handleWithDeleteButton}
                disabled={props.isEdit}
              >
                Excluir
              </Button>
            </FormGroup>
          </Col>
        </>
      )}
    </>
  );
}

