import { DropdownItem } from '@polichat/flamboyant';

type MenuChildrenItemProps = {
  active: boolean;
  text: string;
  handleClick: () => void;
};
export function MenuChildrenItem({
  handleClick,
  active,
  text,
}: Readonly<MenuChildrenItemProps>) {
  return (
    <DropdownItem
      active={active}
      onClick={handleClick}
      style={{
        ...(active && { background: 'var(--primary-25)' }),
        margin: '0 0.25rem',
        padding: '0',
        paddingLeft: '5px',
        color: 'var(--primary) !important',
        borderRadius: '0.25rem',
        hover: {
          backgroundColor: 'var(--primary-25)',
        },
      }}
    >
      <span>{text}</span>
    </DropdownItem>
  );
}
