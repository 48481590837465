export const actionNames = {
  GET_CONTACTS_DAY: '@contact_day/GET_CONTACTS_DAY',
  GET_CONTACTS_DAY_SUCCESS: '@contact_day/GET_CONTACTS_DAY_SUCCESS',
  GET_CONTACTS_DAY_ERROR: '@contact_day/GET_CONTACTS_DAY_ERROR',
  UPDATE_CONTACTS_DAY: '@contact_day/UPDATE_CONTACTS_DAY',
  GET_CONTACT_DAY_LAST_CREDIT_CONSUMED:
    '@contact_day/GET_CONTACT_DAY_LAST_CREDIT_CONSUMED',
  CLOSE_CONTACT_USAGE_WARNING: '@contact_day/CLOSE_CONTACT_USAGE_WARNING',
};

export function getContactsDay(obj) {
  return {
    type: actionNames.GET_CONTACTS_DAY,
    payload: obj,
  };
}

export function getContactsDaySuccess(obj) {
  return {
    type: actionNames.GET_CONTACTS_DAY_SUCCESS,
    payload: obj,
  };
}

export function getContactsDayError(obj) {
  return {
    type: actionNames.GET_CONTACTS_DAY_ERROR,
    payload: obj,
  };
}

export function updateContactsDay(obj) {
  return {
    type: actionNames.UPDATE_CONTACTS_DAY,
    payload: obj,
  };
}

/**
 *
 * @param { contact_id, channel_customer_id } obj
 */
export function getContactLastConsumedCredit(obj) {
  return {
    type: actionNames.GET_CONTACT_DAY_LAST_CREDIT_CONSUMED,
    payload: obj,
  };
}

/**
 * 
 * @param { channelId } obj 
 * @returns 
 */
export function closeContactUsageWarning(obj) {
  return {
    type: actionNames.CLOSE_CONTACT_USAGE_WARNING,
    payload: obj,
  };
}
