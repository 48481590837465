import List from './List';
import Filter from './filter/Filter';
import { connect, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import AvailableUser from './available-user';
import { Loading } from '../loading';
import { useRef, useState } from 'react';
import NewTabs from './NewTabs';
import { CustomerContactDayAlert } from '../content/chat/customerContactDayAlert';
import { CustomerChannelBalanceProvider } from '../content/contexts/customerChannelBalance/provider';
import { CustomerHasPaymentIntegratorProvider } from '../content/contexts/customerHasPaymentIntegrator/provider';
import { useWhiteLabel } from '../../../hooks/useWhiteLabel';

function ChatPanel({ isLoading, opennedChat, chat, settings }) {
  const mobile = useSelector((state) => state.chat.mobile);
  const [searching, setSearching] = useState(false);

  const containerRef = useRef(null);

  const isWL = useWhiteLabel().isWl;

  return (
    <>
      <CustomerChannelBalanceProvider
        contactId={chat?.id}
        channelCustomerId={chat?.view?.selectedChannel?.id}
      >
        <CustomerHasPaymentIntegratorProvider
          customer_id={settings.customer_id}
        >
          <div
            className={`chatPanelContainer ${
              isMobile && (mobile?.blockOne ? '' : 'hidden')
            }`}
            id="chatPanelContainer"
          >
            <div className="chatPanelContainerHeader">
              {!isWL && (
                <CustomerContactDayAlert
                  channelId={Number(opennedChat?.view?.selectedChannel?.id)}
                />
              )}
              <NewTabs />
            </div>
            <Filter
              screen={'all'}
              styled={{ marginBotton: '30px' }}
              onSearch={setSearching}
            />
            <AvailableUser />
            <div className="panel-divider"></div>
            <div
              className="chatListContainerMaster"
              id="chatListContainerMaster"
              ref={containerRef}
            >
              {isLoading || searching ? (
                <div style={{ height: '150px' }}>
                  <Loading text="Carregando conversas..." />
                </div>
              ) : (
                <List whoGetList="chat" container={containerRef} />
              )}
            </div>
          </div>
        </CustomerHasPaymentIntegratorProvider>
      </CustomerChannelBalanceProvider>
    </>
  );
}

const mapStateToProps = (state) => ({
  opennedChat:
    state.chat?.chats?.filter((chat) => {
      return chat.open === true;
    })[0] || [],
  chat: state.chat?.chats?.find((chat) => chat.open === true),
  settings: state.chat?.config?.general?.settings,
});

export default connect(mapStateToProps)(ChatPanel);
