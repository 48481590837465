import React, { useState } from 'react';
import {
  Button,
  Icon,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '@polichat/flamboyant';
import List from '../../../common/list';
import checkAvatar from '../../../../utils/checkAvatar';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ChatActions from '../../../../store/modules/chat/actions';
import Animation from '../../../common/animation';
import Avatar from '../../../common/profile/Avatar';

function UnmergeContact({ chat, props, unmergeContacts }) {
  //////////
  const currentContact = chat.contacts
    ? chat.contacts.find((contact) => props.contact.id === contact.id)
    : null;
  const [isOpenChannels, setIsOpenChannels] = useState(false);
  const toggleChannels = () => setIsOpenChannels(!isOpenChannels);
  const [show, setShow] = useState(false);
  const [contactToUnmerge, setContactToUnmerge] = useState(null);
  const [unmergingStatus, setUnmergingStatus] = useState(
    currentContact?.statusUnmerging || 0
  );
  const [sending, setSending] = useState(false);
  if (
    sending &&
    currentContact &&
    currentContact.statusUnmerging != unmergingStatus
  ) {
    setSending(false);
    setUnmergingStatus(currentContact.statusUnmerging);
  }

  let iconInfos = isOpenChannels
    ? 'poli-icon pi-simple-arrow-up-line'
    : 'poli-icon pi-simple-arrow-down-line';

  if (!currentContact || !currentContact.externals) {
    return null;
  }
  function onConfirmUnmerge() {
    setSending(true);
    unmergeContacts(currentContact.id, contactToUnmerge.contact_id_origin);
  }
  ////verificando se o contactToUnmerge ainda existe
  if (contactToUnmerge) {
    if (currentContact.externals && currentContact.externals.length) {
      if (
        !currentContact.externals.find(
          (contact) =>
            contact.data &&
            contact.data.contact_id_origin ===
              contactToUnmerge.contact_id_origin
        )
      ) {
        setContactToUnmerge(null);
        setUnmergingStatus(0);
      }
    } else {
      setContactToUnmerge(null);
      setUnmergingStatus(0);
    }
  } else if (unmergingStatus !== 0) {
    //setUnmergingStatus(0);
  }
  let modal = null;
  if (show) {
    let modalBody = null;
    let modalFooter = null;
    if (contactToUnmerge) {
      if (unmergingStatus === 1) {
        modalBody = <Animation icon="loading" size={'tiny'} />;
      } else if (unmergingStatus === -1) {
        modalBody = (
          <div className={(props.className || '') + ' errorResults'}>
            <Icon icon="poli-icon pi-alert-fill" /> Não foi possível desvincular
            os contatos. Tente novamente!{' '}
            <span
              onClick={() => {
                setContactToUnmerge(null);
                setUnmergingStatus(0);
              }}
            >
              Voltar
            </span>
          </div>
        );
      } else {
        modalBody = (
          <div className="unmergingContactsBox">
            <strong>
              Você está prestes a transformar este contato em dois.
            </strong>
            <div className="currentContact">
              <span className="avatarContainer mergeimg">
                <Avatar
                  className="contact"
                  title={currentContact.name}
                  url={currentContact.picture}
                />
              </span>{' '}
              <span>
                {currentContact.name} <br /> {currentContact.phone}
              </span>
            </div>
            <div className="spaceBeteweenContacts">
              <span className="before"></span>
              <Icon icon="poli-icon pi-link-delete-fill" />
              <span className="after"></span>
            </div>
            <div className="contactToUnmerge">
              <span className="avatarContainer mergeimg">
                <Avatar
                  className="contact"
                  title={contactToUnmerge.name}
                  url={checkAvatar(
                    contactToUnmerge.name,
                    contactToUnmerge.picture
                  )}
                />
              </span>{' '}
              <span>
                {contactToUnmerge.name} <br /> {contactToUnmerge.phone}
              </span>
            </div>
          </div>
        );
        modalFooter = (
          <>
            <Button
              className="btn-primary"
              size="lg"
              onClick={() => {
                setUnmergingStatus(1); ///loading
                onConfirmUnmerge();
              }}
            >
              Desvincular
            </Button>{' '}
            <Button
              className="btn-danger"
              size="lg"
              onClick={() => setContactToUnmerge(null)}
            >
              Cancelar
            </Button>
          </>
        );
      }
    } else {
      setShow(false);
    }
    modal = (
      <Modal
        isOpen={show}
        toggle={() => setShow(!show)}
        className={
          'modalChat unmergeContacts ' +
          (localStorage.getItem('@polichat/darkmode') === 'true'
            ? 'darkMode'
            : '')
        }
      >
        <ModalHeader toggle={() => setShow(!show)}>
          <Icon icon="poli-icon pi-user-man-fill" size={50} />
          <span right="true">Desvincular contatos</span>
          <span
            className="closeButton"
            onClick={() => {
              setShow(false);
            }}
          >
            <Icon icon="poli-icon pi-close-fill" size={50} />
          </span>
        </ModalHeader>
        <ModalBody>{modalBody}</ModalBody>
        <ModalFooter>{modalFooter}</ModalFooter>
      </Modal>
    );
  }
  return (
    <>
      {modal}
      <div className="align-start">
        <div onClick={toggleChannels}>
          <span><strong>Canais</strong></span>
        </div>
          <span>
            <span>
              <List
                list={currentContact.externals || []}
                keyTitle={(channel) => {
                  return (
                    <div>
                      <span title={channel.uid}>
                      <Icon
                        size={25}
                        icon={channel.icon}
                        color={channel.icon_color}
                      /> {channel.name}
                      </span>
                      {channel.data && channel.data.contact_id_origin ? (
                        <span
                          className="unmergeButton"
                          title={'Desvincular o contato ' + channel.data.name}
                          onClick={() => {
                            setShow(!show);
                            setContactToUnmerge(channel.data);
                            setUnmergingStatus(0);
                          }}
                        >
                          <Icon
                            size={20}
                            icon="poli-icon pi-link-delete-fill"
                          />
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                  );
                }}
                onClick={() => {}}
              />
            </span>
          </span>
      </div>
    </>
  );
}

const mapStateToProps = (state, ownProps) => ({
  chat: state.chat,
  props: ownProps,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ChatActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UnmergeContact);
