import React, { useState, useEffect } from 'react';
import {
  Pagination,
  PaginationItem,
  PaginationLink,
} from '@polichat/flamboyant';

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

const range = (from, to, step = 1) => {
  let i = from;
  const range = [];
  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

function TablePagination(props) {
  /**
   * Recebe como props
   * totalItens: o valor total de itens que a tabela pode ter
   * limitItens: o valor de itens por página, caso não informado será 10
   * pageSide: quantidade de valores a esquerda e direita da página ativa
   */
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(()=>{
    setCurrentPage(1);
  },[props.totalItens])

  let { totalItens = 0, pageSide = 0, limitItens = 10 } = props;
  let totalPages = Math.ceil(totalItens / limitItens);
  //Limitar pageSide para 0, 1 ou 2
  pageSide =
    typeof pageSide === 'number' ? Math.max(0, Math.min(pageSide, 2)) : 0;

  const fetchPageNumbers = () => {
    /**
     * ESTRUTURAR A PAGINAÇÃO
     * (1) < {4 5} (6) {7 8} > (10)
     */
    const totalNumbers = pageSide * 2 + 3; // Número total de paginas que serão mostradas
    const totalBlocks = totalNumbers + 2; // Número total com os botões LEFT (<) e RIGHT (>)

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageSide);
      const endPage = Math.min(totalPages - 1, currentPage + pageSide);
      let pages = range(startPage, endPage);

      const hasLeftHidden = startPage > 2;
      const hasRightHidden = totalPages - endPage > 1;
      const hiddenOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        case hasLeftHidden && !hasRightHidden: {
          const extraPages = range(startPage - hiddenOffset, startPage - 1);
          pages = [LEFT_PAGE, ...extraPages, ...pages];
          break;
        }

        case hasRightHidden && !hasLeftHidden: {
          const extraPages = range(endPage + 1, endPage, hiddenOffset);
          pages = [...pages, ...extraPages, RIGHT_PAGE];
          break;
        }

        default:
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
          break;
      }

      return [1, ...pages, totalPages];
    }
    return range(1, totalPages);
  };

  const handleMoveLeft = () => goToPage(currentPage - pageSide * 2 - 1);

  const handleMoveRight = () => goToPage(currentPage + pageSide * 2 + 1);

  const handleClick = (page) => goToPage(page);

  const goToPage = (page) => {
    const { onPageChange = (f) => f } = props;

    setCurrentPage(Math.max(0, Math.min(page, totalPages)));

    const paginationData = {
      currentPage: Math.max(0, Math.min(page, totalPages)),
      totalPages,
      totalItens,
      limitItens,
    };

    onPageChange(paginationData);
  };

  if (!totalItens || totalPages === 1) {
    return null;
  } else {
    return (
      <Pagination aria-label="Page navigation">
        {fetchPageNumbers().map((page, index) => {
          if (page === LEFT_PAGE)
            return (
              <PaginationItem key={index}>
                <PaginationLink previous onClick={handleMoveLeft} />
              </PaginationItem>
            );

          if (page === RIGHT_PAGE)
            return (
              <PaginationItem key={index}>
                <PaginationLink next onClick={handleMoveRight} />
              </PaginationItem>
            );

          return (
            <PaginationItem key={index} active={page === currentPage}>
              <PaginationLink onClick={() => handleClick(page)}>
                {page}
              </PaginationLink>
            </PaginationItem>
          );
        })}
      </Pagination>
    );
  }
}

export default TablePagination;
