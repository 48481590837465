import { DocumentHeader } from './document';

export type HeaderDataType = {
  type: 'none' | 'text' | 'media';
  text: string;
  mediaUrl?: string;
  mediaFormat?: 'IMAGE' | 'VIDEO' | 'DOCUMENT';
};

type PropsType = {
  header: HeaderDataType;
};

export const TemplateMessageHeader = (props: PropsType): JSX.Element => {
  const { header } = props;

  if (!header || !header.type?.length || header.type === 'none') return <></>;

  // Header of type text
  if (header.type === 'text') return <h1>{header.text}</h1>;

  // mediaUrl is neccessary from here onwards, so if it's invalid, render nothing
  if (header.type !== 'media' || !header.mediaUrl || !header.mediaUrl.length)
    return <></>;

  // Header of type media
  switch (header.mediaFormat) {
    case 'IMAGE':
      return <img src={header.mediaUrl} />;

    case 'VIDEO':
      return <video loop muted src={header.mediaUrl} />;

    case 'DOCUMENT':
      return <DocumentHeader header={header} />;

    default:
      return <>Mídia inválida</>;
  }
};
