import { useEffect, useMemo, useState } from 'react';
import { connect, useSelector } from 'react-redux';

import Avatar from '../../common/profile/Avatar';
import ContactName from '../../common/profile/ContactName';
import ChatFinished from './ChatFinished';
import ChatOperator from './ChatOperator';
import LastMessage from './LastMessage';

import ChannelIcon from './ChannelIcon';
import ChatReadStatus from './ChatReadStatus';
import LastMessageTime from './LastMessageTime';

import Tags from './tags/Tags';

import { WhatsAppCreateEditService } from 'src/services/tools/channels/whatsapp/index';

import { useFlags } from 'flagsmith/react';
import { useContext } from 'react';
import { isMobile } from 'react-device-detect';
import { bindActionCreators } from 'redux';
import { GlobalContext } from 'src/context/globalContext';
import checkAvatar from 'src/utils/checkAvatar';
import * as ChatActions from '../../../store/modules/chat/actions';
import { CloseButtonMobile } from '../content/contexts/mobileButton/context';

function ListItem({
  chat,
  openChat,
  markAsRead,
  props,
  showDetailsComponent,
  fetchContactExternal,
  idSelectedChat,
  showBlockTwo,
  isSavingContact,
  toggleQuickMessagesSelector,
}) {
  const flag = useFlags(['update_picture', 'stack_flags']);
  let currentUser = chat?.config?.user;
  let users = chat?.config?.general?.users;
  let style = styles.container;
  let chatDetails = chat.showDetails;
  let channelType = 'whatsapp';
  let user_id = currentUser?.id;
  let roles = chat?.config?.user?.roles;
  let customer_id = useSelector((state) => state.general?.current_customer_id);
  // Context
  const { setPictureReceived, setBeforePicture } = useContext(GlobalContext);
  const { setIsClose } = useContext(CloseButtonMobile);

  const stackFlags =
    JSON.parse(flag.stack_flags?.value)?.map(Number).includes(customer_id) ||
    flag.stack_flags?.value === '[]';

  const [picture, setPicture] = useState();

  // let somethingIsLoading = chat?.hasAsyncRequest;

  async function syncAvatar(uniqueChat) {
    const contactObj = {
      customerId: customer_id,
      contactId: uniqueChat.id,
      channelId: uniqueChat.chat.channel_id,
      uid: props.uid,
    };
    try {
      const avatar = await WhatsAppCreateEditService.updatePicture(contactObj);
      if (!!avatar.link) {
        setPicture(avatar.link);
        setPictureReceived(avatar.link);
        return;
      }

      setPictureReceived(props.avatarUrl);
      setBeforePicture(props.avatarUrl);
    } catch (error) {
      if (props?.avatarUrl) {
        setPictureReceived(props.avatarUrl);
        setBeforePicture(props.avatarUrl);
        return;
      }
      setPictureReceived(checkAvatar(props.name, null));
    }
  }

  useEffect(() => {
    if (!!picture && picture !== '') {
      setPicture(picture);
    } else {
      setPicture(props.avatarUrl);
    }
  }, [picture, props.avatarUrl]);

  useEffect(() => {
    if (window.location.href.includes('#')) {
      const pathname = window.location.href;
      const contactIdUrl = pathname.split('#');
      if (
        contactIdUrl[1] &&
        contactIdUrl[1] !== NaN &&
        contactIdUrl[1] !== undefined
      ) {
        if (isMobile) {
          let topMobile = document.querySelector('.topMobile')?.classList;
          let topNav = document.querySelector(
            '.topNavigationContainer'
          )?.classList;
          if (topMobile) {
            topMobile.add('hidden');
          }
          if (topNav) {
            topNav.add('hidden')
          }
        }
        if (isSavingContact == undefined) {
          openChat(parseInt(contactIdUrl[1]));
        }
      }
    }
  }, [onload]);

  function handleOpenChat(uniqueChat) {
    toggleQuickMessagesSelector('close');
    // Sincronizar foto de perfil.
    if (flag.update_picture?.enabled) {
      syncAvatar(uniqueChat);
    }
    /**
     * Validação para ver se esse chat está aberto para não disparar o loding messages novamente.
     */
    if (uniqueChat?.open && !isMobile) {
      return null;
    }
    if (users) {
      idSelectedChat(uniqueChat.id);

      openChat(uniqueChat.id);

      if (isMobile) {
        let topMobile = document.querySelector('.topMobile')?.classList;
        let topNav = document.querySelector(
          '.topNavigationContainer'
        )?.classList;

        if (topMobile) {
          topMobile.add('hidden');
        }
        if (topNav) {
          topNav.add('hidden');
        }
      }

      // Usado para o PWA e site móvel
      isMobile && showBlockTwo();

      markRead(uniqueChat);
      // Chat só é marcado como lido quando for aberto pelo seu dono
      // Chat não tem dono entao pode ser lido por qualquer um

      if (uniqueChat && uniqueChat.contact) {
        if (
          (!uniqueChat.contact.externals ||
            !uniqueChat.contact.externals.length) &&
          !uniqueChat.isLoadingExternal
        ) {
          fetchContactExternal(uniqueChat.id);
        }
      }

      if (chatDetails) {
        showDetailsComponent(false);
      }
    }
  }

  function markRead(chat) {
    if (chat.chat?.chat_read) {
      return null;
    }

    if (!roles || !roles.length) {
      return null;
    }

    // operador diferente do operador atual
    if (!user_id || user_id !== chat?.chat?.origin_id) {
      return null;
    }

    // Operador é o mesmo do operator atual
    if (user_id && user_id === chat?.chat?.origin_id) {
      markAsRead(chat.id);
    }
  }

  return useMemo(() => {
    return (
      <div
        style={style}
        className={
          props.read && props.open
            ? 'chatListItem chatRead activeChatItem'
            : props.read
            ? 'chatListItem chatRead'
            : props.open
            ? 'chatListItem activeChatItem'
            : 'chatListItem chatNotRead'
        }
        onClick={() => {
          handleOpenChat(props.chat);
          if (isMobile) {
            let sidebarElement =
              document.querySelector('.sidebarContainer')?.classList;
            if (sidebarElement?.contains('showMobileMenu')) {
              sidebarElement?.remove('showMobileMenu');
              chatPanelElement?.remove('showMobileMenu');
              sidebarMenu?.remove('sidebarHeight');
              sidebar?.add('menuRetratil');
              setIsClose(true);
            }
          }
        }}
        id={props.chat.id}
      >
        <div className="avatarContainer" style={styles.avatar}>
          <Avatar
            className="contact"
            title={props.name}
            url={picture ? picture : props.avatarUrl}
            size={50}
          />
        </div>

        <div style={styles.groupText}>
          <div style={styles.rowChat}>
            <div className="contactNameBox" style={styles.columnName}>
              <div
                className="contactNameChat"
                // style={{
                //   float: 'left',
                //   display: 'block',
                //   overflow: 'hidden',
                //   textWrap: 'nowrap',
                //   textOverflow: 'ellipsis',
                // }}
              >
                <ContactName name={props.name} read={props.read} />
              </div>
              {/* Antigo ChatReadStatus estava aqui. */}
              <div
                className="dateChat"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <div style={{ display: 'inline' }}>
                  <LastMessageTime
                    // time={props.lastMessage.dtm}
                    {...props.lastMessage}
                    isChatRead={props.read}
                    countMessages={props.count_messages}
                    newMessagesCounter={props.newMessageCounter}
                  />
                </div>
              </div>
            </div>
          </div>

          <div style={styles.rowDescription} className="lastMessageContainer">
            {props.finished ? (
              <ChatFinished />
            ) : (
              <>
                <ChannelIcon channel_id={props.channel} type={channelType} />
                <LastMessage
                  isChatRead={props.read}
                  countMessages={props.count_messages}
                  newMessagesCounter={props.newMessageCounter}
                  chatId={props.chat.id}
                  {...props.lastMessage}
                />
                <ChatReadStatus
                  isChatRead={props.read}
                  countMessages={props.count_messages}
                  newMessagesCounter={props.newMessageCounter}
                  chatId={props.chat.id}
                  lastMessage={props.lastMessage}
                />
              </>
            )}
          </div>
          <div
            className={`${
              stackFlags ? 'wrapperNameAndTagStack' : 'wrapperNameAndTag'
            }`}
          >
            <ChatOperator users={users} operator={props.operator_id} />
            <div className="tagsList">
              <Tags chat_id={props.chat.id} />
            </div>
          </div>
        </div>
      </div>
    );
  }, [props, users, picture, chat]);
}

const styles = {
  avatar: {
    display: 'flex',
    alignItens: 'center',
    margin: '12px 15px',
  },

  groupText: {
    display: 'block',
    width: '100%',
    overflow: 'hidden',
  },

  rowChat: {
    height: 'auto',
    overflow: 'hidden',
    display: 'block',
    lineHeight: 'normal',
  },

  rowDescription: {
    display: 'flex',
    alignItems: 'flex-end',
    flex: 1,
    maxHeight: '1.3em',
  },

  columnName: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  columnChat: {
    display: 'flex',
    flex: 1,
    alignSelf: 'flex-end',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0 8px',
  },
};

const mapStateToProps = (state, ownProps) => ({
  chat: state.chat,
  props: ownProps.props ? ownProps.props : ownProps,
  isSavingContact: state?.chat?.isSavingContact,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ChatActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ListItem);
