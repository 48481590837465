import { ImageContent } from '../ImageContent';
import ReportV2Img from '../../../img/ReportV2Img';


export function Welcome(){
  return (
    <ImageContent
      ImgElement={ReportV2Img}
      title="Bem-vindo aos relatórios"
      subTitle="Veja o quanto o seu negócio está evoluindo!"
    />
  );
}