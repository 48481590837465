import React from 'react';
import { Button } from '@polichat/flamboyant';

import Illustration from '../../../../../../assets/images/illustrations/illustration-welcome-waba-channel.svg';
import history from '../../../../../../services/history';
import { Container } from './Congratulations.styles';

const Congratulations = () => {
  const handleRedirect = () => {
    history.push('/tools/messages-builder');
  };
  return (
    <Container>
      <img src={Illustration} alt="Canal adicionado" />
      <h2>Parabéns, seu canal foi adicionado com sucesso.</h2>
      <Button gradient="primary" width="total" onClick={handleRedirect}>
        Gerenciar Templates
      </Button>
    </Container>
  );
};

export default Congratulations;
