import React, { useState } from 'react';
import { CustomInput, Icon, Button, Badge } from '@polichat/flamboyant';

import history from '../../../../../../services/history';
import ChannelsService from '../../../../../../services/tools/channels/channels';
import ButtonDoubts from '../../common/ButtonDoubts/ButtonDoubts.component';
import InfoCard from '../../InfoCard/InfoCard.component';
import ActionGroupButtons from '../../ActionGroupButtons/ActionGroupButtons.component';

import {
  Container,
  TextInfos,
  FirstInfo,
  SecondInfo,
  ThirdInfo,
  CardsContainer,
  FourColumnsGrid,
  PasswordContainer,
  CheckBoxContainer,
} from './Migration.styles';
import { handleExternalLink } from '../../../../../../utils/handleExternalLink';

const Migration = ({ data, dataSet }) => {
  const [checked, setChecked] = useState(false);
  const [nextButton, nextButtonSet] = useState(false);

  const wlConfig = useSelector(
    // @ts-ignore
    (state) => state?.whitelabel?.config
  );

  // Actions to Actions Group Button component
  const goBack = () => history.push('/tools/channels');
  const handleNextAction = async () => {
    nextButtonSet(false);
    try {
      const step = data.homologation + 1;
      await ChannelsService.updateStepHomologation({
        channel_id: data.id,
        step,
      });
      dataSet({ ...data, homologation: step });
    } catch (error) {
      console.log(
        'Error in Migration Component when uptadeStepHomologation. Error => ',
        error
      );
    }
  };
  const handleChangeCheckBox = ({ target: { checked } }) => {
    setChecked(checked);
    nextButtonSet(checked);
  };
  return (
    <>
      <Container>
        {!wlConfig.hide_doubts_button && <ButtonDoubts />}
        <TextInfos>
          <FirstInfo>
            <span>
              O Facebook já verificou e aprovou a sua empresa, agora é hora de
              ativar o telefone.
              <br />
              Para esta etapa, o número de telefone não pode estar instalado em
              nenhum aparelho, portanto é necessário excluir a conta ou trocar
              de número no celular. Para isso, montamos dois tutoriais de como
              fazer, para cada opção. Ela só é necessária se a linha já estiver
              em uso no aplicativo WhatsApp. Segue os PDFs:
              <Button color="primary" size="sm" onClick={() => handleExternalLink('https://drive.google.com/file/d/1L1MSSvdLcmAhaW7twOVpZnOaZBNlgiwT/view?usp=sharing')}>
                <Icon icon="poli-icon pi-pdf-file-fill" size={18} />
                Deletar conta do WhatsApp
              </Button>
              <Button color="primary" size="sm" onClick={() => handleExternalLink('https://drive.google.com/file/d/18IfrBpjcj5VQA21eymVWeeEbWmWlJedw/view?usp=sharing')}>
                <Icon icon="poli-icon pi-pdf-file-fill" size={18} />
                Trocar número da WABA
              </Button>
            </span>
          </FirstInfo>

          <SecondInfo>
            <span>
              Após solicitar a exclusão, é preciso aguardar no mínimo 3 minutos
              antes de partir para a próxima etapa.
            </span>
          </SecondInfo>

          <ThirdInfo>
            <span>
              Número disponível para a ativação? Agora vamos no hub do BSP:
              <Button color="primary" size="sm" onClick={() => handleExternalLink('https://hub.360dialog.com/')}>
                HUB 360 Dialog
              </Button>
              Entrem com o email e senha que foi cadastrado na primeira etapa.
            </span>
          </ThirdInfo>
        </TextInfos>

        <CardsContainer>
          <FourColumnsGrid>
            <InfoCard number="1">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '2rem',
                }}
              >
                <span>
                  Quando estiver na na tela da 360, clique no botão azul
                  "Trigger Registration"
                </span>
                <Button color="info" onClick={() => handleExternalLink('https://hub.360dialog.com/')}>Trigger Registration</Button>
              </div>
            </InfoCard>

            <InfoCard number="2">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '1rem',
                }}
              >
                <span>
                  Uma nova tela irá abrir, perguntando o método que desejam
                  receber o SMS com os 6 dígitos do WhatsApp.
                </span>
                <div>
                  <Button style={{ marginRight: '1rem' }} color="light">
                    Voice
                  </Button>
                  <Button color="light">SMS</Button>
                </div>
              </div>
            </InfoCard>

            <InfoCard number="3">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '1rem',
                }}
              >
                <span>
                  Você irá então receber o código do WhatsApp. Outra tela irá
                  abrir, nela você irá colocar o código de 6 dígitos:
                </span>
                <h3>
                  <PasswordContainer>
                    <span>******</span>
                  </PasswordContainer>
                </h3>
              </div>
            </InfoCard>

            <InfoCard number="4">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '2rem',
                }}
              >
                <span>Agora clique no botão "Generate API Key"</span>
                <Button color="info" onClick={() => handleExternalLink('https://hub.360dialog.com/')}>Generate API Key</Button>
              </div>
            </InfoCard>
          </FourColumnsGrid>
        </CardsContainer>

        <CheckBoxContainer>
          <CustomInput
            type="checkbox"
            id="exampleCustomCheckbox"
            label="Realizei as ações acima"
            checked={checked}
            onChange={handleChangeCheckBox}
          />
        </CheckBoxContainer>

        <ActionGroupButtons
          onClickNext={handleNextAction}
          onClickBack={goBack}
          nextButtonActived={!nextButton}
        />
      </Container>
    </>
  );
};

export default Migration;
