import { ActionTypes } from "./actions";

export default (state = {
  loadingManageCategory: false,
  categoryStatus: null,
  lastError: null,
}, action) => {

  switch (action.type)
  {
    case ActionTypes.CREATE_CATEGORY: {
      return {
        ...state,
        ...{
          loadingManageCategory: true,
        }
      };
    }
    case ActionTypes.CREATE_CATEGORY_SUCCESS: {
      return {
        ...state,
        ...{
          loadingManageCategory: false,
          categoryStatus: true,
        }
      };
    }
    case ActionTypes.CREATE_CATEGORY_FAILURE: {
      return {
        ...state,
        ...{
          loadingManageCategory: false,
          categoryStatus: false,
          error: action.error
        }
      };
    }

    case ActionTypes.UPDATE_CATEGORY: {
      return {
        ...state,
        ...{
          loadingManageCategory: true
        }
      };
    }
    case ActionTypes.UPDATE_CATEGORY_SUCCESS: {
      return {
        ...state,
        ...{
          loadingManageCategory: false,
          categoryStatus: true,
        }
      };
    }
    case ActionTypes.UPDATE_CATEGORY_FAILURE: {
      return {
        ...state,
        ...{
          loadingManageCategory: false,
          categoryStatus: false,
          error: action.error
        }
      };
    }
    case ActionTypes.RESET_CATEGORY_STATUS : {
      return {
        ...state,
        ...{
          categoryStatus: null,
        }
      }
    }
    default: {
      return state;
    }
  }

}
