import styled, { css } from 'styled-components';


export const LoginText = styled.div`
  display: flex;
  align-items: center;
  padding-top: 1rem;
  div {
    color: var(--page-title);
    font-size: 0.8rem;
    line-height: 1rem;
    text-align: right;
  }
  span {
    margin-bottom: -0.4rem;
    font-weight: bold;
    flex-flow: row wrap;
    color: #606161;
    font-size: 1rem;
    line-height: 1.25rem;
    padding-left: 0.5rem;
  }
  i {
    color: var(--primary);
  }
`;

export const LoginCheckBox = styled.div`
  display: flex;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  padding: 1rem 0 1rem 0;

  a {
    margin-left: auto;
    text-align: right;
  }

  #checkboxConectar{
    margin-top: -36px;
    position: relative;
    top: 2px; 
    left: 20px;
  }
  .textCheckbox{
    margin-top: -32px;
    margin-left: 23px;
  }
  .linkCheckbox{
    margin-top: -32px;

  }
`;

export const LoginButtonContainer = styled.div`
  .buttonBack{
    margin-right: 8%;
  }
`;



export const MensagemErro = styled.div`
  color: var(--danger);
`;

