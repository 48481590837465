import { useState } from 'react';
import { additionalPlans } from '../../../../../services/tools/channels/additionalPlans/request';
import { DtoHireAdditionalChannel , DtoVerifyChannelList} from '../../../../../services/tools/channels/additionalPlans/dto';

interface ChannelPlanInfo extends DtoHireAdditionalChannel {
  additional_id: number;
}

export function useCustomerVerifyChannelPlan() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [blocked, setBlocked] = useState(false)
  const [channelPlanInfo, setChannelPlanInfo] =
    useState<ChannelPlanInfo | null>();

  // ---------------------------------------------
  // Functions
  async function verifyCustomerCanAddChannel(
    customer_id: number,
    additional_id: number
  ) {
    try {
      setLoading(true);
      setError(false);
      const result = await additionalPlans.getCustomerHasChannelLimit({
        customer_id,
        additional_id,
      });

      if (result.should_buy) {
        // @ts-ignore
        setChannelPlanInfo({ ...result.plan, additional_id });
      }

      setBlocked(result.blocked);

      return {
        blocked: result.blocked,
        should_buy: result.should_buy,
        error: false,
      };
    } catch (error) {
      setError(true);

      return {
        blocked: false,
        should_buy: false,
        error: true,
      };
    } finally {
      setLoading(false);
    }
  }

  // ---------------------------------------------
  // Effects
  // ---------------------------------------------
  // Transformations
  // ---------------------------------------------
  // API

  return {
    loading,
    error,
    channelPlanInfo,
    blocked,
    verifyCustomerCanAddChannel,
  };
}
