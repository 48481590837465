import axios from 'axios';
import poliConfig from 'src/utils/poliConfig';

const defaultHeaders = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
};

const defaultConfig = {
  headers: defaultHeaders,
  withCredentials: true,
};

const getServicesInLaravel = () => {
  return axios.get(poliConfig.poliAppLaravelUrl + `/admin/services/listServices`, defaultConfig );
}

const postAServiceInLaravel = (data) => {
  return axios.post(poliConfig.poliAppLaravelUrl + `/admin/services/newService`, data, defaultConfig);
};

const putAServiceInLaravel = (data) => {
  return axios.put(poliConfig.poliAppLaravelUrl + `/admin/services/updateService`, data, defaultConfig);
};

const getServicesImportSLInLaravel = () => {
  return axios.get(poliConfig.poliAppLaravelUrl + `/admin/services/listServicesToImportSL`, defaultConfig);
};

export {
  getServicesInLaravel,
  postAServiceInLaravel,
  putAServiceInLaravel,
  getServicesImportSLInLaravel
}
