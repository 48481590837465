import { ActionTypes } from './actions';

export default (
  state = {
    categories: [],
    loadingManageProduct: false,
    loadingGetCategories: false,
    lastError: null,
    productStatus: null,
    categoriesStatus: null,
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.CREATE_PRODUCT: {
      return {
        ...state,
        ...{
          loadingManageProduct: true,
        },
      };
    }
    case ActionTypes.CREATE_PRODUCT_SUCCESS: {
      return {
        ...state,
        ...{
          loadingManageProduct: false,
          productStatus: true,
        },
      };
    }
    case ActionTypes.CREATE_PRODUCT_FAILURE: {
      return {
        ...state,
        ...{
          loadingManageProduct: false,
          error: action.error,
          productStatus: false,
        },
      };
    }

    case ActionTypes.UPDATE_PRODUCT: {
      return {
        ...state,
        ...{
          loadingManageProduct: true,
        },
      };
    }
    case ActionTypes.UPDATE_PRODUCT_SUCCESS: {
      return {
        ...state,
        ...{
          loadingManageProduct: false,
          productStatus: true,
        },
      };
    }
    case ActionTypes.UPDATE_PRODUCT_FAILURE: {
      return {
        ...state,
        ...{
          loadingManageProduct: false,
          error: action.error,
          productStatus: false,
        },
      };
    }
    case ActionTypes.GET_CATEGORIES: {
      return {
        ...state,
        ...{
          loadingGetCategories: true,
        },
      };
    }
    case ActionTypes.GET_CATEGORIES_SUCCESS: {
      return {
        ...state,
        ...{
          categories: action.data,
          loadingGetCategories: false,
          categoriesStatus: true,
        },
      };
    }
    case ActionTypes.GET_CATEGORIES_FAILURE: {
      return {
        ...state,
        ...{
          error: action.error,
          loadingGetCategories: false,
          categoriesStatus: false,
        },
      };
    }
    case ActionTypes.RESET_PRODUCT_STATUS: {
      return {
        ...state,
        ...{
          productStatus: null,
          error: null,
        },
      };
    }
    case ActionTypes.RESET_CATEGORIES_STATUS: {
      return {
        ...state,
        ...{
          categoriesStatus: null,
        },
      };
    }
    default: {
      return state;
    }
  }
};
