import styled, { css } from 'styled-components';

export const ContainerMaster = styled.div`
  background: #f5f6f7;
  padding: 0;
  overflow: scroll;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  `;


export const AdsContainer = styled.div`
  background: var(--gradient-primary);
  height: 100%;
  padding: 4rem;
    `;

export const AdsIconContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 2rem;


`;

export const AdsItemIcon = styled.div`
  width: max-content;
  height: max-content;
  padding: 0.5rem;
  border-radius: 0.5rem;
  color: var(--page-title);
  border: 1px solid #ffffff;
  background-color: #ffffff;

  i {
    display: block;
  }
  `;

export const AdsItemHeader = styled.div`
    color: var(--white);
    font-size: 1.25rem;
    max-width: 80%;
    min-width: 40%;
    word-wrap: break-word;
    margin-left: 1rem;
    text-align: center;


    div:first-child {
      font-size: 2.3rem;
      font-weight: 900;
      letter-spacing: 1.58085px;
    }

    .imgChannels{
      padding-right:1rem;
    }

  `;

export const AdsItemText = styled.div`
    color: var(--white);
    font-size: 0.8rem;
    max-width: 40%;
    min-width: 40%;
    word-wrap: break-word;
    margin-left: 1rem;


    div:first-child {
      font-size: 1.2rem;
      font-weight: bold;
    }

    .imgChannels{
      padding-right:1rem;

    }
    `;

export const AdsItemContainer = styled.div`
  background: var(--gradient-primary);
  height: 100%;
  display: flex;
  justify-content: end;
  margin:0px;
  img {
  margin: -65px;
  object-fit: contain;
  }
`;

export const CallToAction = styled.div`
  display: flex;
  padding: 0.5rem 0 0.5rem 0;
  border-radius: 1rem;
  border: thin solid white;
  width: 48%;
  `;

export const CallToActionIcon = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0.2rem 0 0.2rem;
  border-right: thin solid white;
  `;

export const AdsItemTextFooter = styled.div`
  color: #fff;
  padding: 0 0 0 0.4rem;



  span {
    text-align: top;
    color: #fff;
    text-align: left;
    font: inherit;
    font-size: 0.8rem;
  }
  div {
    border: thin solid white;
    border-radius: 0.5rem;
    line-height: 2px;
    font-size: 0.7rem;
    width: 48%;
  }
  `;

export const LoginContainer = styled.div`
  background: #fff;
  border-radius: 0 8px 8px 0;
  height: 100%;
  min-height: 700px;
  padding: 2rem;
  `;

export const LoginDiv = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  img {
    width: 20vw;
    height: 20vh;
    margin: auto;
  }

  span {
    height: 10vh;
    font-size: 2.25rem;
    font-weight: bold;
    line-height: 2rem;
  }
  `;

export const LoginButtonCookies = styled.div`
    padding: 4rem 0 0 0;
  `;

export const ContainerMain = styled.div`
  padding-top: 1rem;

  `;

