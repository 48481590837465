import React from 'react';
import FilterBadge from './FilterBadge';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ChatActions from '../../../../store/modules/chat/actions';
import { useFlags } from 'flagsmith/react';
import { useSelector } from 'react-redux';

function Badges({
  activeFilters,
  removeFilter,
  clearCustomFilterPage,
  noAttendantTabActive,
}) {
  let filters = <></>;

  const onRemoveBadge = (index) => {
    removeFilter(index);
    clearCustomFilterPage();
  };

  if (activeFilters) {
    filters = Object.keys(activeFilters).map(function (key, index) {
      if (activeFilters[key] && activeFilters[key].length) {
        let filter = activeFilters[key][0]; // como existe apenas 1 de cada tipo, posso pegar ele

        if (filter) {
          // Se o filtro for "Em atendimento" ou "Não Lido" então ele não mostra o badge
          // Esses dois filtros estão sendo usados nas Abas de Navegação.
          if (
            (filter.value === 0 && filter.name !== 'Sem Atendente') ||
            filter.value === 2
          ) {
            return null;
          }
          if (filter.name === 'Sem Atendente' && noAttendantTabActive) {
            return null;
          }
          let name = filter.name;
          return (
            <FilterBadge
              key={index}
              onRemove={() => onRemoveBadge(key)}
              title={name}
            />
          );
        }
      }
      return null;
    });
  }

  return <div className="filterBadgesContainer">{filters}</div>;
}

const mapStateToProps = (state) => ({
  chat: state.chat,
  activeFilters: state.chat?.filters?.activeFilters,
  noAttendantTabActive: state.chat.config?.noAttendantTabActive,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ChatActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Badges);
