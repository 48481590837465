import { Icon, Input, Button } from '@polichat/flamboyant';

import styles from '../Dropzone.module.css';
import { DropDownEmoji } from './DropDownEmoji';
import { Dispatch, SetStateAction } from 'react';
import { Add } from '@poli-ink/react';
export interface CaptionFieldProps {
  id: string;
  value: string;
  onChange: (value: string) => void;
  form: any;
  sendFilesToMessage: () => void;
  uploadingListHasFiles: boolean;
  setOpenFile: Dispatch<SetStateAction<boolean | undefined>>;
}
export function CaptionField(props: Readonly<CaptionFieldProps>) {
  return (
    <div className={styles.input_container}>
      <DropDownEmoji
        onChange={props.onChange}
        value={props.value}
        className={styles.emoji}
      />
      <Input
        className={styles.caption_field}
        {...{
          value: props.value,
          onChange: props.onChange,
          id: props.id,
          type: 'text',
          placeholder: 'Adicione uma legenda ...',
        }}
      />
      {props.value && (
        <span className={styles.icon}>
          <Icon
            icon="poli-icon pi-close-line"
            size={20}
            cursor="pointer"
            onClick={() => props.onChange('')}
          />
        </span>
      )}
      <div
        className="addFile"
        onClick={() => props.setOpenFile((prev) => !prev)}
      >
        <Add />
      </div>
      <Button 
        className={styles.send_button}
        color="link"
        onClick={props.sendFilesToMessage}
        disabled={!props.uploadingListHasFiles}
      >
        <Icon icon={'poli-icon pi-set-fill'} size={20} />
      </Button>
    </div>
  );
}
