import requestLaravel from '../laravel-web-app/request';

async function generateToken() {
  return requestLaravel({
    method: 'GET',
    url: '/trigger/generateToken',
    withCredentials: true,
  }).then((response) => response.data);
}

export { generateToken };
