import * as React from 'react';
import { Picker } from 'emoji-mart';
import {
  Icon,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from '@polichat/flamboyant';

interface DropDownEmojiProps {
  value: string;
  onChange: (value: string) => void;
  className?: string;
}

export function DropDownEmoji({
  value,
  onChange,
  className,
}: Readonly<DropDownEmojiProps>) {
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = React.useState(false);

  let emojiTheme: 'light' | 'dark' = 'light';
  if (localStorage.getItem('@polichat/darkmode') === 'true') {
    emojiTheme = 'dark';
  }

  return (
    <Dropdown
      isOpen={isEmojiPickerOpen}
      toggle={() => setIsEmojiPickerOpen((s) => !s)}
      className={className}
    >
      <DropdownToggle
        id="toolbar-btn-emoji"
        color="transparent"
        className={isEmojiPickerOpen ? 'active' : ''}
      >
        <Icon icon={'poli-icon pi-smille-fill'} size={20} />
      </DropdownToggle>

      <DropdownMenu className='captionsDropdown'>
        {isEmojiPickerOpen && (
          <Picker
            set="apple"
            theme={emojiTheme}
            emoji="point_up"
            onSelect={(emoji: any) => onChange(value + emoji.native)}
            style={{
              width: '100%',
              height: '100%'
            }}
            i18n={{
              search: 'Pesquisar emoji',
              notfound: 'Emoji não encontrado',
              categories: {
                search: 'Emojis Econtrados',
                recent: 'Recentes',
                people: 'Emojis & Pessoas',
                nature: 'Animais & Natureza',
                foods: 'Comida & Bebida',
                activity: 'Atividade',
                places: 'Viagens & Locais',
                objects: 'Objetos',
                symbols: 'Símbolos',
                flags: 'Bandeiras',
                custom: 'Personalizado',
              },
            }}
          />
        )}
      </DropdownMenu>
    </Dropdown>
  );
}
