import React, { useEffect, useCallback } from 'react';
import { Icon, Button } from '@polichat/flamboyant';

import { timeHasPassed } from '../../../store/modules/chat/functions';

import Animation from '../../common/animation';
import { toast } from 'react-toastify';

import { testContactChat } from '../../../store/modules/visitors/functions';

import { Container, BoxDescription } from './styles';

function SessionsWebchat({
  isVisitorsDropDownOpen, //opened visitors dropdown information
  handleCloseVisitorsDropDown, //function to close VisitorNotification dropdown
  //data from visitors
  sessions,
  //actions from visitors
  storeFromVisitor,
  removeReadToOpenChatVisitor,
  tryOpenVisitorChatError,
  //actions from chat
  openChat,
  openChatTab,
  fetchUniqueChatRequest,
}) {
  if (!isVisitorsDropDownOpen) return null;

  if (!Array.isArray(sessions)) {
    sessions = []
  }

  /**
   * after store visitor success this side effect
   * (supposedly) will open the chat
   */
  useEffect(() => {
    let hasSessionReadToOpen = sessions?.find(
      (session) => session.readToOpen ?? false
    );

    if (hasSessionReadToOpen) {
      onOpenChatVisitor(hasSessionReadToOpen);
    }
  }, [sessions]);

  const handleStartChatVisitor = useCallback((session) => {
    // Visitor is not a contact
    if (!session.id) {
      //this action dispatch a websocket request 'store_from_visitor'
      storeFromVisitor(session);
    } else {
      //deals with opening that visitor chat
      onOpenChatVisitor(session);
    }
  }, []);

  const onOpenChatVisitor = useCallback((session) => {
    //checks if that contact exists in the chat list
    let {
      hasContactInChatStore,
      // hasContactInContactStore,
      error,
      sessionError,
    } = testContactChat(session);

    //in case of error to access the state
    if (error) {
      tryOpenVisitorChatError(sessionError);
      return;
    }

    if (hasContactInChatStore) {
      openChat(session.id);
      openChatTab();
    } else {
      fetchUniqueChatRequest(session.id);
    }
    //if session have ***readToOpen*** dispatch action to remove
    if (session.readToOpen) {
      // setTimeout(() => {
      removeReadToOpenChatVisitor(session);
      // }, 300);
    }

    handleCloseVisitorsDropDown();
  }, []);

  return (
    <>
      <div className="selectorVisitorBoxHeader plateBoxHeader">
        <span className="plateBoxName">
          <Icon icon="poli-icon pi-computer-line" />
          <span>Visitantes em seu site</span>
        </span>
        <span className="plateBoxUpLine" onClick={handleCloseVisitorsDropDown}>
          <Icon icon="poli-icon pi-simple-arrow-down-line" />
        </span>
      </div>
      {/* Lista de sessões/visitantes */}
      <Container>
        <ul>
          {sessions?.map((session, index) => (
            <li className="itemListHeader" key={`{${session._id}-${index}`}>
              <BoxDescription>
                {/* Visitor name */}
                <span>
                  {session.user?.name ??
                    `Visitante #${sessions.length - index}`}
                </span>
                {/* Time has passed  */}
                <div>
                  <Icon
                    size={14}
                    icon="poli-icon pi-clock-line"
                    color="var(--green-polichat)"
                  />
                  <span>
                    {`${timeHasPassed(
                      session.created_at ?? session.handled_at
                    )} em seu site`}
                  </span>
                </div>
              </BoxDescription>
              <div>
                {/* Button to open chat, try again or await start*/}
                <Button
                  /**
                   * o arquivo flamboyant.css, usa classes do reactstreap
                   * que estão sobrepondo estilos definidos pelo design system @polichat/flamboyant
                   * para redefinir aspectos desse botão verificar o arquivo ../SessionsWebchat/styles.js
                   */
                  outline
                  color={session.errorStore ? 'danger' : 'attention'}
                  disabled={session.loadingStore ?? false}
                  onClick={() => handleStartChatVisitor(session)}
                >
                  {session.loadingStore || session.readToOpen ? (
                    <>
                      <span title="Carregando...">Aguardando</span>
                      <Animation icon="loading" size={16} />
                    </>
                  ) : session.errorStore ? (
                    <>
                      <span title="Tentar abrir novamente">
                        Abrir novamente
                      </span>
                      <Icon
                        icon="poli-icon pi-alert-fill"
                        color={'var(--danger)'}
                        size={16}
                        title="Erro ao tentar iniciar chat"
                      />
                    </>
                  ) : (
                    <>
                      <span title="Iniciar este chat">Iniciar chat</span>
                      <Icon
                        icon="poli-icon pi-webchat-fill"
                        color={'var(--webchat-color)'}
                        size={16}
                      />
                    </>
                  )}
                </Button>
              </div>
            </li>
          ))}

        </ul>
        {sessions?.length < 1 && <p className="text-center" style={{margin: 0}}>Não há visitantes!</p>}
      </Container>
    </>
  );
}

export default SessionsWebchat;
