import { Icon } from '@polichat/flamboyant';
import { parseMessageTemplate } from '../../../../utils/chat/parseMessageTemplate';
import formatText from '../../../../utils/formatText';
import { TemplateMessageHeader } from './header';
import {
  AdHeader, PreviewBody,
  PreviewButtonsCall,
  PreviewButtonsReply,
  PreviewFooter,
  PreviewHeader
} from './styles';

type PropsType = {
  message: string;
};

export const WabaTemplateMessage: React.FC<PropsType> = ({ message }) => {
  const data = parseMessageTemplate(message);
  if (!data.isFromMessageBuilder || !data.parsedMessage) {
    return <p>{message}</p>;
  }

  const { header, body, footer, buttons, button } = data.parsedMessage;

  const text = body.text ? body.text : body;

  const { ad } = data.parsedMessage || {};

  const BODY_WORD_LIMIT = 300;
  const limitedBody = ad?.body
    ? `${ad?.body.substring(0, BODY_WORD_LIMIT)}...`
    : '';

  return (
    // <TemplateContainer>
    <>
      {/* <BallonContainer> */}
      {data.isAd && (
        <AdHeader>
          <a href={ad?.sourceUrl} target="_blank" rel="noreferrer">
            {ad?.thumbnailUrl && <img src={ad?.thumbnailUrl} />}
            <h1>{ad?.title}</h1>
            <p>{limitedBody}</p>
          </a>
        </AdHeader>
      )}

      <PreviewHeader>
        <TemplateMessageHeader header={header} />
      </PreviewHeader>

      {typeof text === 'string' && (
        <PreviewBody
          dangerouslySetInnerHTML={{
            __html: formatText(text),
          }}
        />
      )}

      {footer ? <PreviewFooter>{footer}</PreviewFooter> : null}
      {/* <span>Agora</span> */}
      {/* </BallonContainer> */}

      {buttons?.length && buttons[0]?.type === 'reply' ? (
        <PreviewButtonsReply id="pbutton1">
          {buttons?.map((button) => (
            <div key={button.id}>
              <span>{button.text || ''}</span>
            </div>
          ))}
        </PreviewButtonsReply>
      ) : null}

      {buttons?.length && buttons[0]?.type === 'action' ? (
        <PreviewButtonsCall id="pbutton2">
          {buttons?.map((button) => (
            <div key={button.id}>
              <Icon
                icon={`poli-icon pi-${button.actionType === 'link' ? 'external-link' : 'phone'
                  }-line`}
                size={18}
              />
              <span>{button.text || ''}</span>
            </div>
          ))}
        </PreviewButtonsCall>
      ) : null}

      {button ? <PreviewButtonsReply><div><span>{button}</span></div></PreviewButtonsReply> : null}
    </>
    // </TemplateContainer>
  );
};
