import { useForm } from 'react-hook-form';
import { QuickMessageSelectorOptions } from '../../types/types';

export interface SearchQuickFormValues {
  type: QuickMessageSelectorOptions;
  text?: string;
  department?: number | null;
}
export function useSearchQuickForm() {
  const form = useForm<SearchQuickFormValues>({
    defaultValues: {
      type: 'quick',
      text: '',
      department: null,
    },
  });

  return {
    ...form,
  };
}
