import poliApiGatewayRequest from '../../../utils/poliApiGatewayRequest';
import poliConfig from '../../../utils/poliConfig';

import { DtoPoliAdsMetric } from './types';
import { DtoMetaAdsPaginatedResult } from '../dto/dtoMetaAdsPaginatedResult';
import { MetaAdsPaginationParams } from '../dto/dtoMetaAdsPaginationParams';

export class AdService {
  static async ListMessageMetric(
    organizationId: string,
    params: MetaAdsPaginationParams
  ) {
    const url = `${poliConfig.apiGatewayBaseURL}/ad/${organizationId}`;

    const result = await poliApiGatewayRequest.get<
      DtoMetaAdsPaginatedResult<DtoPoliAdsMetric[]>
    >(url, { params });
    return result.data;
  }
}
