import { put, all, call, takeLatest } from 'redux-saga/effects';
import {
  ActionTypes,
  getDepartmentsSuccess,
  getDepartmentsFailure,
  updateStatusDepartmentSuccess,
  updateStatusDepartmentFailure,
  createDepartmentFailure,
  createDepartmentSuccess,
  updateDepartmentFailure,
  updateDepartmentSuccess,
  deleteDepartmentFailure,
  deleteDepartmentSuccess,
  searchUsersByCustomerIDFailure,
  searchUsersByCustomerIDSuccess,
} from './actions';

import DepartmentService from '../../../../services/tools/departments';
import UserService from '../../../../services/tools/users';

import history from '../../../../services/history';

function* getDepartmentsRequest(event) {
  try {
    const result = yield call(
      DepartmentService.getAllDepartments,
      event.params
    );
    const data = {
      list: result.data.data,
      pagination: {
        total_registers_server: result.data.pagination.totalDepartments,
        registers_per_page: result.data.pagination.registersPerPage,
        current_page: result.data.pagination.currentPage,
        last_page: result.data.pagination.totalPages,
      },
    };

    yield put(getDepartmentsSuccess(data));
  } catch (e) {
    console.log(e);
    yield put(getDepartmentsFailure(e));
  }
}

function* updateStatusDepartmentRequest(event) {
  try {
    const result = yield call(
      DepartmentService.updateStatusDepartment,
      event.data
    );

    yield put(updateStatusDepartmentSuccess(result));
  } catch (error) {
    console.log(error);
    yield put(updateStatusDepartmentFailure(error));
  }
}

function* createDepartmentRequest(event) {
  try {
    const result = yield call(DepartmentService.createDepartment, event.data);
    yield put(createDepartmentSuccess(result));

    // Voltar para a página de listar todos os departamentos
    history.push({
      pathname: '/tools/departments',
    });
  } catch (error) {
    yield put(createDepartmentFailure(error));
  }
}

function* updateDepartmentRequest(event) {
  try {
    const result = yield call(DepartmentService.updateDepartment, event);

    yield put(updateDepartmentSuccess(result));
    // Voltar para a página de listar todos os departamentos
    history.push({
      pathname: '/tools/departments',
    });
  } catch (error) {
    yield put(updateDepartmentFailure(error));
  }
}

function* deleteDepartmentRequest(event) {
  try {
    const result = yield call(DepartmentService.deleteDepartment, event.data);

    yield put(deleteDepartmentSuccess(result));
  } catch (error) {
    yield put(deleteDepartmentFailure(error));
  }
}

function* searchUsersByCustomerIDRequest(event) {
  try {
    const result = yield call(UserService.getAllUsersByCustomer, event.data);

    yield put(searchUsersByCustomerIDSuccess(result));
  } catch (error) {
    console.log(error);
    yield put(searchUsersByCustomerIDFailure(error));
  }
}

export default all([
  takeLatest(ActionTypes.GET_DEPARTMENTS, getDepartmentsRequest),
  takeLatest(
    ActionTypes.UPDATE_STATUS_DEPARTMENT,
    updateStatusDepartmentRequest
  ),
  takeLatest(ActionTypes.CREATE_DEPARTMENT, createDepartmentRequest),
  takeLatest(ActionTypes.UPDATE_DEPARTMENT, updateDepartmentRequest),

  takeLatest(ActionTypes.DELETE_DEPARTMENT, deleteDepartmentRequest),

  takeLatest(
    ActionTypes.SEARCH_USERS_BY_CUSTOMER_ID,
    searchUsersByCustomerIDRequest
  ),
]);
