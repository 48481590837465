import styled, { keyframes } from "styled-components";

const rotateAnimation = keyframes`
  to {
    transform: rotate(1turn);
  }
`;

const LoadingDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .c-loader {
    animation: ${rotateAnimation} 1s infinite;
    border: 3px solid #e5e5e5;
    border-radius: 50%;
    border-top-color: var(--green-polichat);
    height: 35px;
    width: 35px;
  }
`;

export function Loading() {
  return (
    <LoadingDiv>
      <div className="c-loader"></div>
    </LoadingDiv>
  );
}