import React from 'react';
import Animation from '../../../../common/animation';
import { Button } from '@polichat/flamboyant';

import { ActionGroup, ActionGroupContent } from './ActionGroupButtons.styles';

const ActionGroupButtons = ({
  onClickBack,
  onClickNext,
  nextButtonActived,

  disabledBtnBack = false,
  btnBackShow = false,
  btnCancelShow = true,
  btnNextShow = true,
  isLoading = false,
}) => (
  <ActionGroup>
    <ActionGroupContent>
      {btnBackShow && (
        <Button
          color="primary"
          size="md"
          onClick={onClickBack}
          disabled={disabledBtnBack}
        >
          Voltar
        </Button>
      )}

      {btnCancelShow && (
        <Button color="danger" size="md" onClick={onClickBack}>
          Cancelar
        </Button>
      )}

      {isLoading && (
        <div>
          <Animation icon="loading" size={'lg'} />
        </div>
      )}

      {btnNextShow && (
        <Button
          disabled={nextButtonActived}
          color="primary"
          size="md"
          onClick={onClickNext}
        >
          Próximo
        </Button>
      )}
    </ActionGroupContent>
  </ActionGroup>
);

export default ActionGroupButtons;
