import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { closeChatAlert } from '../../../store/modules/alerts/actions';
import { ChatAlert } from '../../../types/alerts';
import { RootState } from '../../../types/RootState';
import { SanitizedHtml } from '../../sanitized/SanitizeHtml.component';
import { ModalContainer, StylizedAlertMessage } from './ChatAlertModal.styles';
// @ts-ignore
import { Modal, ModalBody, Icon } from '@polichat/flamboyant';
import cdnHost from '../../../utils/formattedCdnUrlFromEnv';

const CloseAlertButton = styled.button`
  background-color: transparent;
  border: none;
  position: absolute;
  top: 2rem;
  right: 2rem;

  @media screen and (max-width: 768px) {
    top: 1rem;
    right: 1rem;
  }
`;

const StyledModalBody = styled(ModalBody)`
  margin-bottom: 0;
  @media screen and (max-width: 768px) {
    margin-top: 1.5rem !important;
  }
`;

export const ChatAlertsModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const alertData = useSelector<RootState, ChatAlert | null>(
    (s) => s.alerts.alert
  );
  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    setIsOpen(false);
    setTimeout(() => alertData && dispatch(closeChatAlert(alertData.id)), 500);
  }, [dispatch]);

  useEffect(() => {
    if (alertData?.image || alertData?.message) {
      setIsOpen(true);
    }
  }, [alertData?.image, alertData?.message]);

  return (
    <Modal isOpen={isOpen} toggle={handleClose}>
      <CloseAlertButton onClick={handleClose}>
        <Icon
          icon={'poli-icon pi-close-fill'}
          color="var(--textDefault)"
          size={20}
        />
      </CloseAlertButton>
      <StyledModalBody>
        <ModalContainer>
          {alertData?.image && (
            <a href={alertData.imageLink ?? undefined} target="_blank">
              <img
                src={cdnHost + alertData.image}
                alt={'Alerta'}
                height="300"
              />
            </a>
          )}
          {alertData?.message && (
            <StylizedAlertMessage>
              <SanitizedHtml html={alertData?.message} />
            </StylizedAlertMessage>
          )}
        </ModalContainer>
      </StyledModalBody>
    </Modal>
  );
};
