import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import {
  createQuickMessage,
  deleteQuickMessage,
  fetchQuickMessages,
  updateQuickMessage,
} from '../../../../../../../../store/modules/chat/actions';

import { QuickMessage } from '../../types/types';

export function useQuickMessageService() {
  const dispatch = useDispatch();
  const save = useCallback((data: Partial<QuickMessage>) => {
    try {
      if (data.id) {
        dispatch(updateQuickMessage(data));
      } else {
        dispatch(createQuickMessage(data));
      }
    } catch (error) {
      console.error('create/update quick message: ', error);
    }
  }, []);

  const remove = useCallback((data: QuickMessage) => {
    try {
      dispatch(deleteQuickMessage(data));
    } catch (error) {
      console.error('remove quick message: ', error);
    }
  }, []);

  const reload = useCallback(() => {
    try {
      dispatch(fetchQuickMessages());
    } catch (error) {
      console.error('reload quick message: ', error);
    }
  }, []);

  return { save, remove, reload };
}
