import { useCallback, useEffect, useState } from 'react';
import { Channel360DialogCustomerService } from '../../../../../service';
import { DtoDialog360Channel } from '../../../../../service/DtoDialog360Channel';

interface Error {
  error: boolean;
  msg?: string;
}

export interface UseChannelCustomerServiceProps {
  customer_id: number;
  channel_id: number;
}
export function useChannelCustomerService(
  props: UseChannelCustomerServiceProps
) {
  // ---------------------------------------------
  // States
  const [loading, setLoading] = useState(false);
  // ---------------------------------------------
  // Functions
  const remove = useCallback(
    async (onSuccess: () => void, onError: () => void) => {
      try {
        setLoading(true);
        const result = await Channel360DialogCustomerService.delete(
          props.channel_id,
          props.customer_id!
        );

        onSuccess();
        setLoading(false);
      } catch (error) {
        console.log('error', error);
        onError();
        setLoading(false);
      }
    },
    [props.customer_id, props.channel_id]
  );
  // ---------------------------------------------
  // Effects
  // ---------------------------------------------
  // Transformations
  // ---------------------------------------------
  // API

  return {
    loading,
    remove,
  };
}
