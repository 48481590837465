import axios from 'axios';

const instance = axios.create({
    baseURL: process.env?.REACT_APP_API_GATEWAY_HOST,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
});

async function request(axiosConfig){
    let data = await instance.request(axiosConfig);
    return data;
    //analisar retorno de requiçoes não autorizadas para redirecionar para a tela de login 
}

export default request;