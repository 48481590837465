import React from 'react';

import { Container, Row, Col, Button, Alert, Icon } from '@polichat/flamboyant';

import ChannelsService from '../../../../../../services/tools/channels/channels';
import ButtonDoubts from '../../common/ButtonDoubts/ButtonDoubts.component';
import formatPhone from '../../../../../../utils/formatPhone';
import facebookStatusImage from './facebookStatusImage.svg';
import { handleExternalLink } from '../../../../../../utils/handleExternalLink';
import channelFBStatus from '../../../../common/channelFBStatus';
import channelStatus from '../../../../common/channelStatus';

const FacebookStatus = ({ data, dataSet, showProfile }) => {
  // @todo Remover botão avançar de teste
  const handleNextAction = async () => {
    await ChannelsService.updateStepHomologation({
      customer_id,
      channel_id: data.id,
      step: 0,
    });
    dataSet({ ...data, homologation: false });
  };

  const { name, phone, waba, customer_id } = data;
  const { fb_account_status, fb_business_id, channel_status } = waba;
  const [fbStatus, , fbAlertaShow] = channelFBStatus(fb_account_status);
  const status = channelStatus(channel_status);

  const wlConfig = useSelector(
    // @ts-ignore
    (state) => state?.whitelabel?.config
  );

  const linkFacebookBusiness = fb_business_id
    ? `https://business.facebook.com/settings/security/?business_id=${fb_business_id}`
    : `https://business.facebook.com/settings/security/`;

  const handleClickFacebook = () => handleExternalLink(linkFacebookBusiness);

  return (
    <>
      <Container fluid={true}>
        <Row>
          {!wlConfig.hide_doubts_button && <Col className="d-flex justify-content-end my-2">
            <ButtonDoubts />
          </Col>}
        </Row>
        <Row>
          <Col className="d-lg-flex justify-content-between align-items-start my-4">
            <div className="mb-3">
              <div className="d-flex mb-2">
                <Icon
                  icon="poli-icon pi-channels-fill"
                  color="var(--green-polichat)"
                  size="22"
                />
                <div>Nome do Canal</div>
              </div>
              <div>{name}</div>
            </div>
            <div className="mb-3">
              <div className="d-flex mb-2">
                <Icon
                  icon="poli-icon pi-smartphone-fill"
                  color="var(--green-polichat)"
                  size="22"
                />
                <div>Telefone</div>
              </div>
              <div>{formatPhone(phone)}</div>
            </div>
            <div className="mb-3">
              <div className="d-flex mb-2">
                <div>Progresso</div>
              </div>
              <div>{fbStatus}</div>
            </div>
            <div className="mb-3">
              <div className="d-flex mb-2">
                <div>Status</div>
              </div>
              <div className="d-flex mb-2">
                <Icon
                  icon="poli-icon pi-point-fill"
                  color={status[1] ? '#00c48c' : '#ff665d'}
                  size="22"
                />
                <div>{status[0]}</div>
              </div>
            </div>
            <Button color="primary" gradient="primary" onClick={showProfile}>
              Editar perfil do canal
            </Button>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col lg="5" className="mb-3 d-flex justify-content-center">
            <img src={facebookStatusImage} alt="Atenção" />
          </Col>
          <Col lg="7" className="mb-3 d-flex justify-content-center">
            <div className="align-self-center">
              <p>
                Depois de gerar a chave API e entrar no modo Sandbox, você deve
                verificar sua conta comercial do Facebook (se ainda não tiver
                feito isso).
              </p>
              <p>
                Assim que você carregar os documentos para verificação
                comercial, você irá entrar no modo Sandbox expandido. O modo
                Sandbox expandido permite que você tenha até 1.500 conversas com
                clientes por dia.{' '}
                <strong>
                  Portanto, é altamente recomendável que você envie os
                  documentos para Verificação de negócios o mais rápido
                  possível, para entrar no modo Sandbox expandido
                </strong>
                .
              </p>
            </div>
          </Col>
        </Row>
        {fbAlertaShow && (
          <Row>
            <Col className="d-lg-flex align-items-center">
              <Alert outline color="attention" className="mr-3 mb-0">
                Atenção requerida
              </Alert>
              <Button
                outline
                color="success"
                className="mr-3"
                onClick={handleClickFacebook}
              >
                Começar analise do Facebook
              </Button>
              <div>Você precisar começar a análise do Facebook.</div>
            </Col>
          </Row>
        )}
      </Container>

      {/*<Button className="mt-5" onClick={handleNextAction}>*/}
      {/*  Botão Teste - Avançar etapa*/}
      {/*</Button>*/}
    </>
  );
};

export default FacebookStatus;
