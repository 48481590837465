import styled from 'styled-components';

import { Modal, ModalBody, ModalFooter } from '@polichat/flamboyant';

export const CustomModal = styled(Modal)`
  & .modal-content {
    padding: 0;
  }
`;

export const CustomModalBody = styled(ModalBody)`
  max-width: 360px;
  text-align: center;
  margin: 1.5rem;
  margin-bottom: 0;
  padding: 0;

  & .modal-title {
    display: block;
    margin-bottom: 25px;

    span {
      justify-content: center !important;
      font-size: 24px;
    }

    .icone-title {
      margin: 30px 0px;
      & i {
        padding: 1.5rem;
        font-size: 60px;
      }
    }
  }

  span {
    font-size: 16px;
  }
`;

export const CustomModalFooter = styled(ModalFooter)`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  padding: 1.5rem;

  button {
    flex: 1;
    margin: 0;
  }

  .btn-block + .btn-block {
    margin: 0;
  }
`;
