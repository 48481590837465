import React from 'react';
import { Switch } from 'react-router-dom';

function createFunctionGlobal(function_name,component_name){
    window.Poli[function_name] = window.Poli[function_name] || function(content){
        let customBox = document.querySelector("#wl_custombox_"+component_name);
        if(!customBox){ return null }
        customBox.innerHTML = '';
        if(typeof content === 'string' || typeof content === 'number'){
            let el = document.createElement("div");
            el.innerHTML = content+''
            customBox.appendChild(el);
        }else if(content instanceof HTMLElement){
            customBox.appendChild(content);
        }
    }
}

function CustomBox({local}) {
    window.customElementsPoli = window.customElementsPoli||{};

    if(local==='head'){
        if(!window.customElementsPoli.head){
            window.customElementsPoli.head = React.createElement("div", { id:"wl_custombox_top", className: "wl_custombox_top" }, "");
        }

        createFunctionGlobal("insertHead","top")

        return (<> {window.customElementsPoli.head} </>);
    }else if(local==='headChat'){
        if(!window.customElementsPoli.headChat){
            window.customElementsPoli.headChat = React.createElement("div", { id:"wl_custombox_headChat", className: "wl_custombox_headChat" }, "");
        }

        createFunctionGlobal("insertChatHeader","headChat")

        return (<> {window.customElementsPoli.headChat} </>);
    }else if(local=='footChat'){
        if(!window.customElementsPoli.footChat){
            window.customElementsPoli.footChat = React.createElement("div", { id:"wl_custombox_footChat", className: "wl_custombox_footChat" }, "");
        }

        createFunctionGlobal("insertChatFooter","footChat")

        return (<> {window.customElementsPoli.footChat} </>);
    }

    return <></>
}
export default CustomBox;
