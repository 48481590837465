import React, { useState, useEffect, useCallback } from 'react';
import { Logo, CheckboxList } from '../gateways.style';
import {
  Button,
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput,
  CustomPassword,
  UncontrolledTooltip,
} from '@polichat/flamboyant';
import logoMercadoPago from '../../../../../assets/images/polipay/mercado-pago.svg';
import CancelButton from '../../../common/CancelButton';
import SaveButton from '../../../common/SaveButton';
import Animation from '../../../../common/animation';

import isEmailValid from '../../../../../utils/isEmailValid';
import order from '../../../../../services/shopping/order';

import paymentConfig from '../../../../../services/shopping/payment/paymentConfig';
import history from '../../../../../services/history';
import { toast } from 'react-toastify';
import PageMainBody from '../../../../common/pages/PageMainBody/PageMainBody.component';
import PageMainHeader from '../../../../common/pages/PageMainHeader/PageMainHeader.component';

function MercadoPago(props) {
  const methodValueDefault = [
    {
      id: 1,
      name: 'billet',
      description: 'Pagamentos via boleto',
      status: 1,
    },
    {
      id: 2,
      name: 'creditCard',
      description: 'Pagamentos via cartão de crédito',
      status: 1,
    },
    {
      id: 3,
      name: 'pix',
      description: 'Pagamentos via PIX',
      status: 1,
    },
  ];
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const [picture, setPicture] = useState('');
  const [name, setName] = useState('');
  const [customer_id, setCustomerId] = useState('');
  const [public_key, setPublicKey] = useState('');
  const [checkedMethods, setCheckedMethods] = useState(methodValueDefault);
  const [isRequestLoading, setRequestLoading] = useState(false);
  const [isPageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    setPageLoading(true);
    paymentConfig
      .fetchConfigMercadoPago()
      .then(function (result) {
        if (
          result &&
          result.data &&
          result.data.email &&
          result.data.token &&
          result.data.public_key
        ) {
          setEmail(result.data.email);
          setToken(result.data.token);
          setPublicKey(result.data.public_key);
          setName(result.data.customer_name);
          setCustomerId(result.data.customer_id);
          if (result.data.method_types) {
            setCheckedMethods(result.data.method_types);
          } else {
            setCheckedMethods(methodValueDefault);
          }
          if (result.data.picture) setPicture(result.data.picture);
        }
      })
      .catch(function (err) {
        toast.error(
          'Houve um erro ao buscar os seus dados de pagamento. Caso o erro persista, entre em contato com o suporte.'
        );
      })
      .finally(function () {
        setPageLoading(false);
      });
  }, []);

  const handleInputChange = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      setCheckedMethods([
        ...checkedMethods,
        methodValueDefault.find((item) => item.name === value),
      ]);
    } else {
      setCheckedMethods(checkedMethods.filter((item) => item.name !== value));
    }
  };

  const setSuccessAlert = () => {
    toast.success('Configurações de pagamento salvas com sucesso!');
    setRequestLoading(false);
  };

  const setFailureAlert = (text) => {
    toast.error(`${text}`);
    setRequestLoading(false);
  };

  const setWarningAlert = (text) => {
    toast.warning(`${text}`);
    setRequestLoading(false);
  };
  const handleAddEmail = (addEmail) => {
    if (isEmailValid(addEmail)) {
      return true;
    }
    return false;
  };

  const onSubmit = useCallback(async () => {
    if (!handleAddEmail(email)) {
      setWarningAlert('insira um email válido.');
      return;
    } else if (!token) {
      setWarningAlert('Informe um token de acesso.');
      return;
    } else if (!public_key) {
      setWarningAlert('Informe uma public key de acesso.');
      return;
    } else if (!name) {
      setWarningAlert('Informe o nome de acesso.');
      return;
    } else if (checkedMethods.length <= 0) {
      setWarningAlert('Escolha pelo menos um método de pagamento.');
      return;
    } else if (
      picture &&
      !picture.substr(-4).match(/(\.jpg|\.png|jpeg)(\?.+)?/gi)
    ) {
      toast.warning(
        <p>
          <span>
            <strong>Link inválido. 😢</strong>
          </span>
          <br />
          Verifique se o seu link esta com
          <strong>.jpg</strong> ou <strong>.png</strong> no final, como no
          exemplo: <br />
          <strong style={{ color: 'var(--warning)' }}>
            https://img.techpowerup.org/200506/mesa-centro.jpg
          </strong>
        </p>,
        { autoClose: 6000 }
      );
      return;
    }

    try {
      setRequestLoading(true);

      let params = {
        email,
        token,
        public_key,
        picture,
        name,
        method_types: checkedMethods,
      };

      paymentConfig
        .storeConfigMercadoPago(params)
        .then((res) => {
          if (res.data) {
            setSuccessAlert();
          } else {
            setFailureAlert(
              'Houve um erro ao salvar as configurações de pagamento. Tente novamente. Caso o erro persista, entre em contato com o nosso suporte.'
            );
          }
        })
        .catch((error) => {
          setFailureAlert(
            'Houve um erro ao salvar as configurações de pagamento. Tente novamente. Caso o erro persista, entre em contato com o nosso suporte.'
          );
          console.error('error', error);
        });
    } catch (error) {
      setFailureAlert(
        'Houve um erro ao salvar as configurações de pagamento. Tente novamente. Caso o erro persista, entre em contato com o nosso suporte.'
      );
      console.error('Error on save PagSeguro configs: ', error);
    } finally {
      setRequestLoading(false);
    }
  }, [name, email, token, customer_id, picture, public_key, checkedMethods]);

  return (
    <>
      <PageMainHeader title="Integrações" icon="poli-icon pi-pay-fill" />
      <PageMainBody>
        <div>
          <Logo
            className="imgMercadoPago"
            src={logoMercadoPago}
            alt="Mercado Pago"
            width={30}
          />
          <strong>Mercado Pago</strong>
        </div>
        <div>
          {isRequestLoading && <Animation icon="chat-loading" />}
        </div>
        {isPageLoading ? (
          <Animation icon="chat-loading" size="large" />
        ) : (
          <Form
            className="catalogForm"
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onSubmit();
            }}
          >
            <Row form>
              <Col lg={4}>
                <FormGroup>
                  <Label for="email">E-mail*</Label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="E-mail da sua conta no Mercado Pago"
                    required
                  />
                </FormGroup>
              </Col>
              <Col lg={4}>
                <FormGroup>
                  <Label for="token">Token de acesso*</Label>

                  <CustomPassword
                    type="password"
                    name="token"
                    id="token"
                    value={token}
                    onChange={(e) => setToken(e.target.value)}
                    placeholder="Token de acesso da sua conta no Mercado Pago"
                    required
                  />
                </FormGroup>
              </Col>

              <Col lg={4}>
                <FormGroup>
                  <Label for="token">Public Key*</Label>
                  <Input
                    required
                    type="text"
                    name="public_key"
                    id="public_key"
                    value={public_key}
                    onChange={(e) => setPublicKey(e.target.value)}
                    placeholder="Public Key da sua conta no Mercado Pago"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col lg={12}>
                <FormGroup>
                  <Label form="name">Nome:*</Label>
                  <Input
                    id="name"
                    type="text"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Nome da sua empresa na tela de checkout"
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col lg={12}>
                <FormGroup>
                  <Label form="picture" id="tooltip-label-picture">
                    Link da Foto:{' '}
                  </Label>
                  <UncontrolledTooltip
                    placement="right"
                    target={'tooltip-label-picture'}
                    autohide={false}
                  >
                    <div>
                      <h5 style={{ fontSize: '16px' }}>
                        Esse campo é opcional.
                      </h5>
                      <p style={{ textAlign: 'left' }}>
                        Insira o link da imagem hospedada. Verifique se o seu
                        link é com <strong>.jpg</strong> ou{' '}
                        <strong>.png</strong> no final, por exemplo: <br />
                        <strong>
                          https://img.techpowerup.org/200506/mesa-centro-supreme-preto.jpg
                        </strong>
                      </p>
                      <p style={{ textAlign: 'left' }}>
                        Sugestões de site para hospedar a imagem:
                        <br />*
                        <a
                          href="https://www.techpowerup.org/upload"
                          target="blank"
                        >
                          https://www.techpowerup.org/upload
                        </a>
                        <br />*
                        <a href="https://postimages.org/" target="blank">
                          https://postimages.org/
                        </a>
                      </p>
                    </div>
                  </UncontrolledTooltip>
                  <Input
                    id="picture"
                    type="url"
                    name="picture"
                    value={picture}
                    onChange={(e) => setPicture(e.target.value)}
                    placeholder="Logomarca da sua empresa na tela de checkout"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col lg={12}>
                <FormGroup className="checkedMethods">
                  Método de pagamento*
                  <CheckboxList>
                    {methodValueDefault.map((item, index) => {
                      return (
                        <li key={index}>
                          <span>{item.description} </span>
                          <Label
                            className="switch"
                            form="checkbox"
                            htmlFor={`payment_method-${item.id}`}
                          >
                            <Input
                              id={`payment_method-${item.id}`}
                              type="checkbox"
                              name={item.name}
                              value={item.name}
                              defaultChecked={checkedMethods.some(
                                (component) => component.name === item.name
                              )}
                              onChange={handleInputChange}
                            />
                            <span className="slider"></span>
                          </Label>
                        </li>
                      );
                    })}
                  </CheckboxList>
                </FormGroup>
              </Col>
            </Row>
            <Row form className="buttonsCenterContainer">
              <CancelButton
                onClick={(e) => {
                  e.preventDefault();
                  history.goBack({
                    formProductType: null,
                  });
                }}
              />{' '}
              <Button
                gradient="primary"
                width="quarter"
                title="confirmar"
                style={{
                  cursor: !email || !token ? 'not-allowed' : 'pointer',
                }}
                disabled={!email || !token ? true : false}
              >
                Confirmar
              </Button>
            </Row>
          </Form>
        )}
      </PageMainBody>
    </>
  );
}

export default MercadoPago;
