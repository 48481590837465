import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as ShoppingCategoriesManage from '../../../../store/modules/shopping/categories-manage/actions';

import PageTitle from '../../common/PageTitle';

import history from '../../../../services/history';

import ModalStatus from '../../../common/modals/ModalStatus';
import {
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  CustomInput,
} from '@polichat/flamboyant';

import CancelButton from '../../common/CancelButton';
import SaveButton from '../../common/SaveButton';

function CategoryForm({
  resetCategoryStatus,
  createCategory,
  updateCategory,
  isLoading,
  error,
  categoryStatus,
  props,
}) {
  //usa o location para acessar o state passado pela rota para definir o tipo de formulário
  const location = useLocation();

  const [formCategoryType, setFormCategoryType] = useState(
    location.state?.formCategoryType || 'add'
  );

  //Estados para armazenar variáveis locais
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  //Inserir os dados da categoria nos inputs
  useEffect(() => {
    if (formCategoryType === 'edit') {
      let category = location.state?.category;
      if (category) {
        setId(category.id);
        setName(category.name);
        setDescription(category.description);
      }
    }
  }, [location]);

  //Objeto categoria para enviar pro Laravel
  let categoryData = {
    name,
    description,
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formCategoryType === 'add') {
      createCategory(categoryData);
    }

    if (formCategoryType === 'edit') {
      updateCategory(id, categoryData);
    }
  };

  //Estado para armazenar o toggle do modal do status de resposta
  const [modalStatus, setModalStatus] = useState(true);
  const toggleModalStatus = () => setModalStatus(!modalStatus);

  //sideEffects do modal do status de resposta
  useEffect(() => {
    if (!modalStatus) {
      if (categoryStatus) {
        history.push({
          pathname: 'categories',
          formCategoryType: null,
        });
      }
      resetCategoryStatus();
      setModalStatus(true);
    }
  }, [modalStatus]);

  return (
    <>
      <div className="shoppingFormContainer">
        {formCategoryType === 'add' && (
          <PageTitle title="Nova categoria" icon="poli-icon pi-category-line" />
        )}
        {formCategoryType === 'edit' && (
          <PageTitle
            title="Editar categoria"
            icon="poli-icon pi-category-line"
          />
        )}
        <Form className="catalogForm" onSubmit={handleSubmit}>
          <Row form>
            <Col lg={12}>
              <FormGroup>
                <Label for="categoryName">Nome categoria:</Label>
                <Input
                  id="categoryName"
                  type="text"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col md={12}>
              <FormGroup>
                <Label for="description">Descrição: </Label>
                <CustomInput
                  id="description"
                  type="textarea"
                  name="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  //maxLength="150"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row form className="buttonsCenterContainer">
            <CancelButton
              onClick={() => {
                resetCategoryStatus();
                history.goBack({
                  formCategoryType: null,
                });
              }}
            />{' '}
            <SaveButton title="Salvar categoria" isLoading={isLoading} />
          </Row>
        </Form>
      </div>

      {categoryStatus && !isLoading && (
        <ModalStatus
          className="modal-categoria"
          isOpen={modalStatus}
          toggle={toggleModalStatus}
          title="Categoria cadastrada com sucesso"
          subtitle="Categoria já está disponível no catálogo"
          type="success"
        />
      )}
      {categoryStatus === false && !isLoading && error && (
        <ModalStatus
          className="modal-categoria"
          isOpen={modalStatus}
          toggle={toggleModalStatus}
          title="Algo deu errado!"
          subtitle="Infelizmente tivemos problemas para salvar sua categoria."
          type="danger"
        />
      )}
    </>
  );
}

const mapStateToPros = (state, ownProps) => ({
  isLoading: state.shoppingCategoriesManage?.loadingManageCategory,
  error: state.shoppingCategoriesManage?.error,
  categoryStatus: state.shoppingCategoriesManage?.categoryStatus,
  props: ownProps,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ShoppingCategoriesManage, dispatch);

export default connect(mapStateToPros, mapDispatchToProps)(CategoryForm);
