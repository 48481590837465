import React, { Component } from 'react';

import Dropzone from 'react-dropzone';
import { Icon } from '@polichat/flamboyant';
import { isMobile } from 'react-device-detect';

import { DropContainer, UploadMessage } from './styles';
import ShowPreview from '../dropzone/v2/FilePreview/ShowPreview';

export default class Upload extends Component {
  renderDragMessage = (isDragActive, isDragReject) => {
    if (!isDragActive) {
      return (
        <UploadMessage>
          <Icon icon="poli-icon pi-add-circle-fill " />
          {isMobile ? (
            <span>Adicione os arquivos aqui...</span>
          ) : (
            <span>Arraste os arquivos aqui...</span>
          )}
        </UploadMessage>
      );
    }

    if (isDragReject) {
      return <UploadMessage type="error">Arquivo não suportado</UploadMessage>;
    }

    return <UploadMessage type="success">Solte os arquivos aqui</UploadMessage>;
  };
  render() {
    const { onUpload, onRejected, selectPreview, chatSelected } = this.props;
    return (
      <Dropzone
        accept="image/*, video/*, audio/*, application/*, message/*, model/*, text/*, windows/metafile, www/mime, xgl/movie, xgl/drawing, x-conference/x-cooltalk, x-music/x-midi, x-world/*"
        onDropAccepted={onUpload}
        onDropRejected={onRejected}
        maxSize={30000000} ///20MB
        noClick={selectPreview ? true : false}
      >
        {({ getRootProps, getInputProps, open, isDragActive, isDragReject }) => (
          <DropContainer
            {...getRootProps()}
            isDragActive={isDragActive}
            isDragReject={isDragReject}
            className={`dropUpload ${selectPreview ? 'border-0' : ''}`}
            style={
              selectPreview && {
                background: 'var(--secondary-background-color)',
              }
            }
          >
            <input {...getInputProps()} />
            {selectPreview?.id ? (
              <div className="wrapperShowPreview">
                <ShowPreview
                  open={open}
                  selectPreview={selectPreview}
                  chatSelected={chatSelected}
                />
              </div>
            ) : (
              this.renderDragMessage(isDragActive, isDragReject)
            )}
          </DropContainer>
        )}
      </Dropzone>
    );
  }
}
