import { Row, Col, Button, Input, Icon } from '@polichat/flamboyant';
import { Controller, UseFormReturn } from 'react-hook-form';
import { ContainerLabelButton } from '../../styles';
import { FormFeedback, UncontrolledTooltip } from 'reactstrap';
import { TypeFiltersProps } from '../../types/Filters';
import { LocalFormValues } from 'src/pages/report/shared/components/FiltersForm/useLocalForm/types';
import ReactSelect, { CSSObjectWithLabel } from 'react-select';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { DateInputComponent } from './useLocalForm/DateInputComponent';

interface FiltersFormProps {
  form: UseFormReturn<LocalFormValues>;
  onSubmit: () => void;
  requestExport: (filters:any) => void;
  filters: TypeFiltersProps | undefined;
  disableSubmit: boolean;
  disableExport: boolean;
  loadingExports: boolean;

}

const selectStyles = {
  indicatorSeparator: () => ({ display: 'none' }),
  control: (base: CSSObjectWithLabel, { isDisabled }: any) => ({
    ...base,
    borderRadius: '0.375rem',
    marginTop: '0.375rem',
    height: '44px',
    minHeight: '44px',
    boxShadow: 'none',
    borderColor: 'var(--borderDefault)',
    '&:hover': {},
    '&:focus': {},
    backgroundColor: isDisabled ? 'transparent' : 'var(--backgroundInput)',
    opacity: isDisabled ? 0.8 : 1,
  }),
  singleValue: (base: CSSObjectWithLabel) => ({ ...base, color: 'var(--textDefault)' }),
  input: (base: CSSObjectWithLabel) => ({ ...base, color: 'var(--textDefault)', margin: '0px', height: '44px', paddingTop: 0, paddingLeft: '2px' }),
  valueContainer: (base: CSSObjectWithLabel) => ({ ...base, height: '44px', padding: '0 6px' }),
  indicatorsContainer: (base: CSSObjectWithLabel) => ({ ...base, height: '44px' }),
  option: (base: CSSObjectWithLabel, { isSelected, isFocused }: any) => ({
    ...base,
    color: isSelected || isFocused ? 'white' : 'var(--textDefault)',
    backgroundColor: isSelected || isFocused ? 'rgb(22, 186, 197)' : undefined
  }),
  menu: (base: CSSObjectWithLabel) => ({ ...base, backgroundColor: 'var(--backgroundInput)' })
};


export function FiltersForm({ form, onSubmit, requestExport,  filters, disableSubmit, disableExport }: FiltersFormProps) {

  const defaultValue = { value: "all", label: "Todos" };
  const [displayIconClearStartDate, setDisplayIconClearStartDate] = useState(false)
  const [displayIconClearEndDate, setDisplayIconClearEndDate] = useState(false)
  const [departmentClear, setDepartmentClear] = useState<boolean>(false)
  const [channelClear, setChannelClear] = useState<boolean>(false)
  const [operatorClear, setOperatorClear] = useState<boolean>(false)
  const [adClear, setAdClear] = useState<boolean>(false)

  const handleSubmitAndExport = async () => {
    const formData = form.getValues();
    requestExport(formData);
  };

  function clearDateStart() {
    form.resetField('start_date');
    setDisplayIconClearStartDate(false)
  }
  function clearDateEnd() {
    form.resetField('end_date');
    setDisplayIconClearEndDate(false)
  }

  return (
    <div style={{ marginBottom: '1rem', marginTop: '2,81rem' }}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Row md={12} className="form-row" style={{ boxShadow: 'none' }}>
          <Col>
            <Controller
              control={form.control}
              name="start_date"
              render={({ field, fieldState }) => (
                <>
                  {displayIconClearStartDate ?
                      <div
                        onClick={(e: any) => {
                          e.preventDefault();
                          clearDateStart();
                        }}
                        style={{
                          width: '25px',
                          height: '25px',
                          position: 'relative',
                          float: 'right',
                          background: 'var(--backgroundInput)',
                          top: '39px',
                          display: 'flex',
                          marginRight: '10px',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                      >X</div> : <></>
                  }
                  <ContainerLabelButton>
                    <Icon
                      icon={`poli-icon pi-calendar-fill`}
                      size={24}
                      color="var(--primary)"
                      position={'relative'}
                      right={2}
                    />
                    <span>Data início</span>
                  </ContainerLabelButton>
                  <DateInputComponent
                    idInput="start_date"
                    value={field.value}
                    onChange={(e: any) => {
                      setDisplayIconClearStartDate(true)
                      field.onChange(e)
                    }}
                    error={!!fieldState.error}
                    style={{ marginTop: '0.375rem' }}
                  />
                  <FormFeedback>{fieldState.error?.message}</FormFeedback>
                </>
              )}
            />
          </Col>
          <Col>
            <Controller
              control={form.control}
              name="end_date"
              render={({ field, fieldState }) => (
                <>
                  {displayIconClearEndDate ?
                    <div
                      onClick={(e: any) => {
                        e.preventDefault();
                        clearDateEnd();
                      }}
                      style={{
                        width: '25px',
                        height: '25px',
                        position: 'relative',
                        float: 'right',
                        background: 'var(--backgroundInput)',
                        top: '39px',
                        display: 'flex',
                        marginRight: '10px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                    >X</div> : <></>
                  }
                  
                  <ContainerLabelButton>
                    <Icon
                      icon={`poli-icon pi-calendar-fill`}
                      size={24}
                      color="var(--primary)"
                      position={'relative'}
                      right={2}
                    />
                    <span>Data término</span>
                  </ContainerLabelButton>
                  <DateInputComponent
                    idInput="end_date"
                    value={field.value}
                    onChange={(e: any) => {
                      setDisplayIconClearEndDate(true)
                      field.onChange(e)
                    }}
                    error={!!fieldState.error}
                    style={{ marginTop: '0.375rem' }}
                  />
                  <FormFeedback>{fieldState.error?.message}</FormFeedback>
                </>
              )}
            />
          </Col>
          <Col>
            <Controller
              control={form.control}
              name="department"
              render={({ field, fieldState }) => (
                <>
                  <ContainerLabelButton>
                    <Icon
                      icon={`poli-icon pi-folder-line`}
                      size={24}
                      color="var(--primary)"
                      position={'relative'}
                      right={2}
                    />
                    <span>Departamento</span>
                  </ContainerLabelButton>
                  <ReactSelect
                    isSearchable={true}
                    components={ departmentClear ? { DropdownIndicator:() => null  } : {}}
                    options={filters?.departments?.map((department) => ({
                      value: department.id,
                      label: department.name,
                    }))}
                    name="department"
                    styles={selectStyles}
                    isDisabled={!filters?.departments?.length}
                    value={field.value || defaultValue}
                    onChange={(selectedValue) => {
                      if (!selectedValue) {
                        setDepartmentClear(false)
                        field.onChange(defaultValue);
                      } else {
                        setDepartmentClear(true)
                        field.onChange(selectedValue);
                      }
                    }}
                    isClearable={departmentClear}
                  />
                  <FormFeedback>{fieldState.error?.message}</FormFeedback>
                </>
              )}
            />
          </Col>
          <Col>
            <Controller
              control={form.control}
              name="channel"
              render={({ field, fieldState }) => (
                <>
                  <ContainerLabelButton>
                    <Icon
                      icon={`poli-icon pi-channels-line`}
                      size={24}
                      color="var(--primary)"
                      position={'relative'}
                      right={2}
                    />
                    <span>Canal</span>
                  </ContainerLabelButton>
                  <ReactSelect
                    isSearchable={true}
                    components={ channelClear ? { DropdownIndicator:() => null  } : {}}
                    options={filters?.channels?.map((channel) => ({
                      value: channel.id,
                      label: channel.name,
                    }))}
                    name="channel"
                    value={field.value || defaultValue}
                    onChange={(selectedValue) => {
                      if (!selectedValue) {
                        setChannelClear(false)
                        field.onChange(defaultValue);
                      } else {
                        setChannelClear(true)
                        field.onChange(selectedValue);
                      }
                    }}
                    isClearable={channelClear}
                    styles={selectStyles}
                    isDisabled={!filters?.channels?.length}
                  />
                  <FormFeedback>{fieldState.error?.message}</FormFeedback>
                </>
              )}
            />
          </Col>
          <Col>
            <Controller
              control={form.control}
              name="operator"
              render={({ field, fieldState }) => (
                <>
                  <ContainerLabelButton>
                    <Icon
                      icon={`poli-icon pi-operator-line`}
                      size={24}
                      color="var(--primary)"
                      position={'relative'}
                      right={2}
                    />
                    <span>Operador</span>
                  </ContainerLabelButton>
                  <ReactSelect
                    isSearchable={true}
                    components={ operatorClear ? { DropdownIndicator:() => null  } : {}}
                    options={filters?.operators?.map((operator) => ({
                      value: operator.id,
                      label: operator.name,
                    }))}
                    name="operator"
                    styles={selectStyles}
                    isDisabled={!filters?.operators?.length}
                    value={field.value || defaultValue}
                    onChange={(selectedValue) => {
                      if (!selectedValue) {
                        setOperatorClear(false)
                        field.onChange(defaultValue);
                      } else {
                        setOperatorClear(true)
                        field.onChange(selectedValue);
                      }
                    }}
                    isClearable={operatorClear}
                  />
                  <FormFeedback>{fieldState.error?.message}</FormFeedback>
                </>
              )}
            />
          </Col>
          <Col>
            <Controller
              control={form.control}
              name="ad"
              render={({ field, fieldState }) => (
                <>
                  <ContainerLabelButton>
                    <Icon
                      icon={`poli-icon pi-alerts-line`}
                      size={24}
                      color="var(--primary)"
                      position={'relative'}
                      right={2}
                    />
                    <span>Anúncio</span>
                  </ContainerLabelButton>
                  <ReactSelect
                    isSearchable={true}
                    components={ adClear ? { DropdownIndicator:() => null  } : {}}
                    options={filters?.ads?.map((ad) => ({
                      value: ad.id,
                      label: `${ad.id} - ${ad.name}`,
                    }))}
                    name="ad"
                    styles={selectStyles}
                    isDisabled={!filters?.ads?.length}
                    value={field.value || defaultValue}
                    onChange={(selectedValue) => {
                      if (!selectedValue) {
                        setAdClear(false)
                        field.onChange(defaultValue);
                      } else {
                        setAdClear(true)
                        field.onChange(selectedValue);
                      }
                    }}
                    isClearable={adClear}
                  />
                  <FormFeedback>{fieldState.error?.message}</FormFeedback>
                </>
              )}
            />
          </Col>
          <Col>
            <Button
              className="button-submit"
              type="submit"
              disabled={!!disableSubmit}
            >
              Filtrar
            </Button>
          </Col>
          <Col>
            <Button
              id="tooltip-export-reports"
              className="button-download"
              onClick={handleSubmitAndExport}
              disabled={disableExport}
            >
              <Icon icon="poli-icon pi-download-line" size={22} />
              {disableExport ? (
                <UncontrolledTooltip
                  placement="top"
                  target={'tooltip-export-reports'}
                  autohide={false}
                >
                  <div>
                    <p style={{ textAlign: 'center' }}>
                      Você não possui permissão para exportar
                    </p>
                  </div>
                </UncontrolledTooltip>
              ) : null}
            </Button>
          </Col>
        </Row>
      </form>
    </div>
  );
}