import * as React from 'react';
import { postContactsBalance } from '../../../../../../services/contacts-day';
import { CustomerChannelBalanceContextBody } from '../types';
import { customerExpendCreditsPercentage } from './contactDayPercent/allChannels';
import { userCanSendMessage } from './contactDayRules/allChannels';
import { useSelector } from 'react-redux';
import { CustomerContactCreditContext } from 'src/context/CustomerContactCredit/context';

export interface UseBalanceServiceProps {
  contactId?: number;
  channelCustomerId?: number;
  flags?: any;
}

export function useBalanceService(
  props: UseBalanceServiceProps
): CustomerChannelBalanceContextBody {
  const creditContext = React.useContext(CustomerContactCreditContext);

  const { channelCustomerId, contactId } = props;
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState();
  const [error, setError] = React.useState<Error>();
  const wl_laravel_url = useSelector(
    // @ts-ignore
    (state) => state?.whitelabel?.config?.laravel_url
  );
  const is_whitelabel = useSelector(
    // @ts-ignore
    (state) => state?.whitelabel?.config?.is_white_label
  )
  const customer_id = useSelector(
    // @ts-ignore
    (state) => state?.general?.current_customer_id
  )

  // ---------------------------------------------
  // Functions

  const refresh = React.useCallback(async () => {
    try {
      setLoading(true);
      const response = await postContactsBalance(
        {
          contact_id: contactId,
          channel_customer_id: channelCustomerId,
        },
        wl_laravel_url
      );
      setData(response.data);
      creditContext.update(response.data);
    } catch (error: any) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [channelCustomerId, contactId]);

  // ---------------------------------------------
  // Effects

  React.useEffect(() => {
    refresh();
  }, [channelCustomerId, contactId]);

  // ---------------------------------------------
  // Transformations

  const isUserAbleToSendMessage = userCanSendMessage(
    channelCustomerId,
    data,
    is_whitelabel,
  );

  const customerChannelPercentage = customerExpendCreditsPercentage(
    channelCustomerId,
    data,
  );
  // ---------------------------------------------
  // API

  return {
    loading,
    error,
    isUserAbleToSendMessage,
    refresh,
    data,
    customerChannelPercentage,
  };
}
