import styled from 'styled-components';
import { ButtonToolbar, DropdownMenu } from '@polichat/flamboyant';

export const TextArea = styled.textarea`
  display: block;
  width: 100% !important;
  min-height: 80px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--secondary-font-color) !important;
  background-clip: padding-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0 !important;
  overflow: auto !important;
  resize: vertical !important;
  background: #ffffff;
  background-color: var(--backgroundInput) !important;
  border: 1px solid !important;
  border-color: var(--secondary-background-color) !important;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-sizing: border-box !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  max-height: none !important;

  :focus {
    color: var(--primary);
    background-color: var(--backgroundInput);
    border-color: var(--primary);
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(79, 202, 210, 0.5);
  }
  ::placeholder {
    color: var(--gray-25);
    opacity: 1;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Toolbar = styled(ButtonToolbar)`
  flex-wrap: none;
  justify-content: none;
  align-items: center;
  gap: 1rem;
`;

export const StyledDropdownMenu = styled(DropdownMenu)`
  background-color: transparent;
  padding: 0;
  box-shadow: 1px 3px 15px #000;
  width: 20rem;
`;
