import { ResponseApiExportReports } from 'src/pages/report/shared/components/Export/report.interface';
import { laravelAxios } from '../../../config/axios/laravelAxios';

function formatFormValues(data: any) {
    return {
      ...data,
      department_id: data.department.value,
      channel_id: data.channel.value,
      operator_id: data.operator.value,
      ad_id: data.ad.value
    };
}

async function allListReports(params?: any) {
    const url = `/report-v2/exports`;
    
    const reports = await laravelAxios.get<ResponseApiExportReports>(url, { params: params})

    return reports.data;
}

async function requestExport(params: any) {
    const url = `/report-v2/request/exports`;
    const paramFormated = formatFormValues(params)

    return await laravelAxios.get(url, { params: paramFormated });
}

async function requestReloadReports(params: any) {
    const url = `/report-v2/reload`;

    return await laravelAxios.get(url, { params: params });
}

export default {
    requestExport,
    requestReloadReports,
    allListReports
};