import axios from 'axios';
import poliConfig from 'src/utils/poliConfig';
//import ObjectToQueryString from '../../lib/ObjectToQueryString';

const defaultHeaders = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
};

const defaultConfig = {
  headers: defaultHeaders,
  withCredentials: true,
};

const getPlansInLaravel = () => {
  return axios.get(poliConfig.poliAppLaravelUrl + `/admin/plans/listPlans`, defaultConfig );
};

const getImportList = () => {
  return axios.get(poliConfig.poliAppLaravelUrl + '/admin/plans/importSL', defaultConfig);
};

const postAPlanInLaravel = (data) => {
  return axios.post(poliConfig.poliAppLaravelUrl + `/admin/plans/newPlan`, data, defaultConfig);
};

const putAPlanInLaravel = (data) => {
  return axios.put(poliConfig.poliAppLaravelUrl + `/admin/plans/updatePlan`, data, defaultConfig);
};

const getPlanInLaravel = (id) => {
  return axios.get(poliConfig.poliAppLaravelUrl + `/admin/plans/getPlanSL/${id}`, defaultConfig);
};

export {
  getPlansInLaravel,
  postAPlanInLaravel,
  putAPlanInLaravel,
  getImportList,
  getPlanInLaravel
}
