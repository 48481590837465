import { useCallback, useState } from 'react';
import { Channel360DialogCustomerService } from '../../service';
import { getDtoDialog360ChannelFromLocalFormValues } from '../helper';
import { LocalFormValues } from '../step1/useForm';

interface Error {
  error: boolean;
  msg?: string;
}

export interface UseChannelCustomerServiceProps {
  customer_id?: number;
}
export function useChannelCustomerService(
  props: UseChannelCustomerServiceProps
) {
  // ---------------------------------------------
  // States
  const [loading, setLoading] = useState(false);
  // ---------------------------------------------
  // Functions
  const create = useCallback(
    async (
      data: LocalFormValues,
      onSuccess: (data: any) => void,
      onError: (message?: string) => void
    ) => {
      try {
        setLoading(true);
        const dto = getDtoDialog360ChannelFromLocalFormValues(
          data,
          props.customer_id!
        );

        const result = await Channel360DialogCustomerService.create(
          dto,
          props.customer_id!
        );
        onSuccess(result.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        //@ts-ignore
        onError(error?.response?.data?.message);
      }
    },
    [props.customer_id]
  );

  const send = useCallback(
    async (data: any, onSuccess: () => void, onError: () => void) => {
      try {
        setLoading(true);
        const result = await Channel360DialogCustomerService.createApiKey(
          data,
          props.customer_id!
        );
        onSuccess();
        setLoading(false);
      } catch (error) {
        onError();
        setLoading(false);
      }
    },
    [props.customer_id]
  );
  // ---------------------------------------------
  // Effects
  // ---------------------------------------------
  // Transformations
  // ---------------------------------------------
  // API

  return {
    loading,
    create,
    send,
  };
}
