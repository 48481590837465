import { useState, useEffect } from 'react';
import {
  Pagination,
  PaginationItem,
  PaginationLink,
} from '@polichat/flamboyant';
import { ORDINATION_TYPES, OrdinationProps } from '../../types/Ordination';

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

const range = (from: any, to: any, step = 1) => {
  let i = from;
  const range = [];
  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

interface TablePaginationProps {
  totalItens?: number;
  pageSide?: number;
  limitItens?: number;
  onPageChange: (page?: any) => void;
}


function TablePagination(props: TablePaginationProps) {
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setCurrentPage(1);
  }, [props.totalItens]);

  let { totalItens = 0, pageSide = 1, limitItens = 10 } = props;
  let totalPages = Math.ceil(totalItens / limitItens);
  //Limitar pageSide para 0, 1 ou 2
  pageSide =
    typeof pageSide === 'number' ? Math.max(0, Math.min(pageSide, 2)) : 0;

  const fetchPageNumbers = () => {
    /**
     * ESTRUTURAR A PAGINAÇÃO
     * (1) < {4 5} (6) {7 8} > (10)
     */
    const totalNumbers = pageSide * 2 + 3; // Número total de paginas que serão mostradas
    const totalBlocks = totalNumbers + 2; // Número total com os botões LEFT (<) e RIGHT (>)

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageSide);
      const endPage = Math.min(totalPages - 1, currentPage + pageSide);
      let pages = range(startPage, endPage);

      const hasLeftHidden = startPage > 2;
      const hasRightHidden = totalPages - endPage > 1;
      const hiddenOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        case hasLeftHidden && !hasRightHidden: {
          const extraPages = range(startPage - hiddenOffset, startPage - 1);
          pages = [LEFT_PAGE, ...extraPages, ...pages];
          break;
        }

        case hasRightHidden && !hasLeftHidden: {
          const extraPages = range(endPage + 1, endPage, hiddenOffset);
          pages = [...pages, ...extraPages, RIGHT_PAGE];
          break;
        }

        default:
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
          break;
      }

      return [1, ...pages, totalPages];
    }
    return range(1, totalPages);
  };

  const handleMoveLeft = () => goToPage(currentPage - 1);

  const handleMoveRight = () => goToPage(currentPage + 1);

  const handleClick = (page: any) => goToPage(page);

  const goToPage = (page: any) => {
    const { onPageChange = (f: any) => f } = props;

    setCurrentPage(Math.max(0, Math.min(page, totalPages)));

    const paginationData = {
      currentPage: Math.max(0, Math.min(page, totalPages)),
      totalPages,
      totalItens,
      limitItens,
    };

    onPageChange(paginationData);
  };

  if (!totalItens || totalPages === 1) {
    return null;
  } else {
    return (
      <Pagination aria-label="Page navigation" style={{ marginBottom: 0 }}>
        {fetchPageNumbers().map((page, index) => {
          if (page === LEFT_PAGE)
            return (
              <PaginationItem key={index}>
                <PaginationLink previous onClick={handleMoveLeft} />
              </PaginationItem>
            );

          if (page === RIGHT_PAGE)
            return (
              <PaginationItem key={index}>
                <PaginationLink next onClick={handleMoveRight} />
              </PaginationItem>
            );

          return (
            <PaginationItem key={index} active={page === currentPage}>
              <PaginationLink onClick={page === currentPage ? () => {} : () => handleClick(page)}>
                {page}
              </PaginationLink>
            </PaginationItem>
          );
        })}
      </Pagination>
    );
  }
}

export function getOrdinationIcon(order: ORDINATION_TYPES) {
  const icons = {
    'asc': 'pi-simple-arrow-down-line',
    'desc': 'pi-simple-arrow-up-line',
    'none': ''
  };

  return icons[order];
}

export function getNewOrdinationProps(currentOrderBy: string, currentOrdinationProps: OrdinationProps, defaultOrdinationProps: OrdinationProps) {
  const getNewOrder = {
    [ORDINATION_TYPES.NONE]: ORDINATION_TYPES.ASCENDING,
    [ORDINATION_TYPES.ASCENDING]: ORDINATION_TYPES.DESCENDING,
    [ORDINATION_TYPES.DESCENDING]: ORDINATION_TYPES.NONE,
  };

  const newOrder = currentOrderBy === currentOrdinationProps?.order_by
    ? getNewOrder[currentOrdinationProps.order]
    : ORDINATION_TYPES.ASCENDING;
  const newOrdinationProps = newOrder !== ORDINATION_TYPES.NONE
    ? { order_by: currentOrderBy, order: newOrder}
    : defaultOrdinationProps;

  return newOrdinationProps;
}

export default TablePagination;
