export const ActionTypes = {
  GET_ORDERS: '@SHOP/ORDERS/GET_ORDERS',
  GET_ORDERS_SUCCESS: '@SHOP/ORDERS/GET_ORDERS_SUCCESS',
  GET_ORDERS_FAILURE: '@SHOP/ORDERS/GET_ORDERS_FAILURE',

  GET_ORDER_DETAIL: '@SHOP/ORDERS/GET_ORDER_DETAIL',
  GET_ORDER_DETAIL_SUCCESS: '@SHOP/ORDERS/GET_ORDER_DETAIL_SUCCESS',
  GET_ORDER_DETAIL_FAILURE: '@SHOP/ORDERS/GET_ORDER_DETAIL_FAILURE',

  DELETE_ORDER: '@SHOP/ORDERS/DELETE_ORDER',
  DELETE_ORDER_SUCCESS: '@SHOP/ORDERS/DELETE_ORDER_SUCCESS',
  DELETE_ORDER_FAILURE: '@SHOP/ORDERS/DELETE_ORDER_FAILURE',

  RESET_ORDERS_STATUS: '@SHOP/ORDERS/RESET_ORDERS_STATUS',
  RESET_ORDER_DETAIL_STATUS: '@SHOP/ORDERS/RESET_ORDER_DETAIL_STATUS',
  RESET_ORDER_DELETE_STATUS: '@SHOP/ORDERS/RESET_ORDER_DELETE_STATUS',

  GET_ORDERS_STATUS: '@SHOP/ORDERS/GET_ORDERS_STATUS',
  GET_ORDERS_STATUS_SUCCESS: '@SHOP/ORDERS/GET_ORDERS_STATUS_SUCCESS',
  GET_ORDERS_STATUS_FAILURE: '@SHOP/ORDERS/GET_ORDERS_STATUS_FAILURE',

  GET_PAYMENT_METHODS: '@SHOP/ORDERS/GET_PAYMENT_METHODS',
  GET_PAYMENT_METHODS_SUCCESS: '@SHOP/ORDERS/GET_PAYMENT_METHODS_SUCCESS',
  GET_PAYMENT_METHODS_FAILURE: '@SHOP/ORDERS/GET_PAYMENT_METHODS_FAILURE',

  GET_PAYMENT_GATEWAYS: '@SHOP/ORDERS/GET_PAYMENT_GATEWAYS',
  GET_PAYMENT_GATEWAYS_SUCCESS: '@SHOP/ORDERS/GET_PAYMENT_GATEWAYS_SUCCESS',
  GET_PAYMENT_GATEWAYS_FAILURE: '@SHOP/ORDERS/GET_PAYMENT_GATEWAYS_FAILURE',
};

/**
 * params
 * {
 *  per_page: 10,
 *  page: 1,
 *  showContact: true
 * }
 */
export const getOrders = (params = null) => ({
  type: ActionTypes.GET_ORDERS,
  params,
});

export const getOrdersSuccess = (data) => ({
  type: ActionTypes.GET_ORDERS_SUCCESS,
  data,
});

export const getOrdersFailure = (error) => ({
  type: ActionTypes.GET_ORDERS_FAILURE,
  error,
});

export const getOrderDetail = (id) => ({
  type: ActionTypes.GET_ORDER_DETAIL,
  id,
});

export const getOrderDetailSuccess = (data) => ({
  type: ActionTypes.GET_ORDER_DETAIL_SUCCESS,
  data,
});

export const getOrderDetailFailure = (error) => ({
  type: ActionTypes.GET_ORDER_DETAIL_FAILURE,
  error,
});

export const deleteOrder = (id) => ({
  type: ActionTypes.DELETE_ORDER,
  id,
});

export const deleteOrderSuccess = (id) => ({
  type: ActionTypes.DELETE_ORDER_SUCCESS,
  id,
});

export const deleteOrderFailure = (error) => ({
  type: ActionTypes.DELETE_ORDER_FAILURE,
  error,
});

export const resetOrdersRequestStatus = () => ({
  type: ActionTypes.RESET_ORDERS_STATUS,
});

export const resetOrderDetailRequestStatus = () => ({
  type: ActionTypes.RESET_ORDER_DETAIL_STATUS,
});

export const resetOrderDeleteRequestStatus = () => ({
  type: ActionTypes.RESET_ORDER_DELETE_STATUS,
});

export const getOrdersStatus = () => ({
  type: ActionTypes.GET_ORDERS_STATUS,
});

export const getOrdersStatusSuccess = (data) => ({
  type: ActionTypes.GET_ORDERS_STATUS_SUCCESS,
  data,
});

export const getOrdersStatusFailure = (error) => ({
  type: ActionTypes.GET_ORDERS_STATUS_FAILURE,
  error,
});

export const getPaymentMethods = () => ({
  type: ActionTypes.GET_PAYMENT_METHODS,
});

export const getPaymentMethodsSuccess = (data) => ({
  type: ActionTypes.GET_PAYMENT_METHODS_SUCCESS,
  data,
});

export const getPaymentMethodsFailure = (error) => ({
  type: ActionTypes.GET_PAYMENT_METHODS_FAILURE,
  error,
});

export const getPaymentGateways = () => ({
  type: ActionTypes.GET_PAYMENT_GATEWAYS,
});

export const getPaymentGatewaysSuccess = (data) => ({
  type: ActionTypes.GET_PAYMENT_GATEWAYS_SUCCESS,
  data,
});

export const getPaymentGatewaysFailure = (error) => ({
  type: ActionTypes.GET_PAYMENT_GATEWAYS_FAILURE,
  error,
});
