import poliApiGatewayRequest from '../../../utils/poliApiGatewayRequest';
import poliConfig from '../../../utils/poliConfig';
import { DtoMetaAdsAccountData } from '../dto/dtoMetaAdsAccountData';

export class AccountMetaService {
  static async get(organizationId: string) {
    const url = `${poliConfig.apiGatewayBaseURL}/account_meta/organization/${organizationId}`;

    const result = await poliApiGatewayRequest.get<DtoMetaAdsAccountData>(url);
    return result.data;
  }

  static async delete(accountMetaId: string) {
    const url = `${poliConfig.apiGatewayBaseURL}/account_meta/${accountMetaId}/install`;

    const result = await poliApiGatewayRequest.delete(url);
    return result.data;
  }
  static async getExternalBusiness(customer_id: number) {
    const url = `${poliConfig.apiGatewayBaseURL}/account_meta/external_business_id/${customer_id}`;

    const result = await poliApiGatewayRequest.get<DtoMetaAdsAccountData>(url);
    return result.data;
  }
}
