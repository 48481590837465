import { Button, Icon } from '@polichat/flamboyant';
import { FaTimes } from 'react-icons/fa';

const TaskReview = ({
  message,
  isMessageBuilder,
  date,
  time,
  prevStep,
  handleSubmit,
  cancelScheduling,
}) => {
  const previewMessage = message;
  const formattedDate = date.split('-').reverse().join('/');

  return (
    <div className="schedule-review textBoxContainer">
      <button className="close-button" onClick={cancelScheduling}>
        <FaTimes size={20} />
      </button>

      <div className="d-flex flex-column">
        <div className="main-text">
          <span className="icon-text-container">
            <Icon
              icon="poli-icon pi-day-fill"
              size={20}
              className="aligned-icon"
            />
            <strong className="aligned-text">Revise para agendar</strong>
          </span>
        </div>

        <div className="message-container">
          <div>
            <strong>Data:</strong> {formattedDate} {time}
          </div>
          <div>
            <strong>Mensagem:</strong>{' '}
            {isMessageBuilder ? JSON.parse(previewMessage).body : previewMessage}
          </div>
        </div>
      </div>
      <div className="buttons reviewbuttons">
        <Button onClick={prevStep} color="outline-primary">
          Voltar
        </Button>
        <Button onClick={handleSubmit}>Agendar</Button>
      </div>
    </div>
  );
};

export default TaskReview;
