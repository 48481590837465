import React, { useMemo } from 'react';
import formatText from '../../../utils/formatText';
import { treatMessageType } from '../../../store/modules/chat/functions';
import { Icon } from '@polichat/flamboyant';
import { parseMessageTemplate } from '../../../utils/chat/parseMessageTemplate';
import { getMessageBuilderTemplateText } from '../content/chat/quickMessages/functions/getMessageBuilderTemplateText';
import Ack from '../content/chat/Ack';

function LastMessage(props) {
  let message = Object.assign({}, props);
  
  // convertendo o ack para number, devido que as vezes vem como string
  const ack = parseInt(props.ack);

  // Se a mensagem for ativa e ackm ser 3 ou 4 (mensagem lida) retorna o ack no LastMessage
  let ackLastMessage =
    props.dir === 'o' && (ack === 3 || ack === 4) ? (
      <Ack
        color="var(--ack-last-message)"
        className="ackLastMessage"
        ack={ack}
        size={10}
      />
    ) : (
      <></>
    );

  if (!message.messageType) {
    message = treatMessageType(message);
  }
  //const read = props.read;
  let iframeRegex = /(<iframe.*>.*< *\/ *iframe *>)/g;

  let message_body = props.body;

  if (message.messageType == 'story_reply' && message.body) {
    let message_story = JSON.parse(message.body);
    if (message_story) {
      message_body = message_story.text;
    }
  }

  let messageFormat = formatText(
    props.body_preview || message_body,
    'whatsapp'
  );
  let titleFormat = formatText(props.body_preview || message_body, 'title');

  const parseResults = parseMessageTemplate(message.body);

  const memoizedTemplateText = useMemo(
    () => getMessageBuilderTemplateText(message.body),
    [message.body]
  );

  let content = !messageFormat.match(iframeRegex) ? (
    parseResults.isFromMessageBuilder ? (
      <div className="lastMessageInChat">
        <span title={memoizedTemplateText}>{memoizedTemplateText}</span>
        {ackLastMessage}
      </div>
    ) : (
      <div className="lastMessageInChat">
        <span
          title={titleFormat}
          dangerouslySetInnerHTML={{
            __html: messageFormat,
          }}
        ></span>
        {ackLastMessage}
      </div>
    )
  ) : (
    <div className="lastMessageInChat">
      <span title={props.body_preview || props.body}>
        {messageFormat}
      </span>
      {ackLastMessage}
    </div>
  );

  if (message.deleted_at) {
    content = (
      <span
        style={{
          textAlign: 'center',
          fontStyle: 'italic',
          color: 'var(--secondary-font-color)',
          paddingLeft: '3px',
        }}
        title={'Deleted message'}
      >
        <Icon
          icon="poli-icon pi-delete-line"
          color={'var(--danger)'}
          size={16}
        />{' '}
        <span>Essa mensagem foi deletada!</span>
      </span>
    );
  } else if (message.isMedia) {
    switch (message.documentType) {
      case 'audio':
        content = (
          <span
            style={styles.mediaMessage}
            title={props.caption || props.fileName || 'Arquivo de áudio'}
          >
            <Icon
              icon="poli-icon pi-microphone-fill"
              size={16}
              color={'var(--icon-color-font)'}
            />{' '}
            <div className="lastMessageInChat">
              <span>Áudio</span>
              {ackLastMessage}
            </div>
          </span>
        );
        break;
      case 'video':
        content = (
          <span
            style={styles.mediaMessage}
            title={props.caption || props.fileName || 'Arquivo de vídeo'}
          >
            <Icon icon="poli-icon pi-camera-video-fill" size={16} />
            <div className="lastMessageInChat">
              <span>Vídeo</span>
              {ackLastMessage}
            </div>
          </span>
        );
        break;
      case 'image':
        content = (
          <span
            style={styles.mediaMessage}
            title={props.caption || props.fileName || 'Arquivo de imagem'}
          >
            <Icon icon="poli-icon pi-photo-fill" size={16} />
            <div className="lastMessageInChat">
              <span>Foto</span>
              {ackLastMessage}
            </div>
          </span>
        );
        break;
      case 'compacted':
        content = (
          <span
            style={styles.mediaMessage}
            title={props.caption || props.fileName || 'Arquivo compactado'}
          >
            <Icon icon="poli-icon pi-zip-fill" size={16} />
            <div className="lastMessageInChat">
              <span>Compactado</span>
              {ackLastMessage}
            </div>
          </span>
        );
        break;
      case 'textEditor':
        content = (
          <span
            style={styles.mediaMessage}
            title={props.caption || props.fileName || 'Documento de texto'}
          >
            <Icon icon="poli-icon pi-word-file-fill" size={16} />
            <div className="lastMessageInChat">
              <span>Documento</span>
              {ackLastMessage}
            </div>
          </span>
        );
        break;
      case 'apresentation':
        content = (
          <span
            style={styles.mediaMessage}
            title={props.caption || props.fileName || 'Apresentação'}
          >
            <Icon icon="poli-icon pi-apresentation-file-fill" size={16} />{' '}
            <div className="lastMessageInChat">
              <span>Apresentação</span>
              {ackLastMessage}
            </div>
          </span>
        );
        break;
      case 'file':
        content = (
          <span
            style={styles.mediaMessage}
            title={props.caption || props.fileName || 'Documento'}
          >
            <Icon icon="poli-icon pi-file-fill" size={16} />
            <div className="lastMessageInChat">
              <span>Documento</span>
              {ackLastMessage}
            </div>
          </span>
        );
        break;
      case 'link':
        content = (
          <span
            style={styles.mediaMessage}
            title={props.caption || props.fileName || 'Link externo'}
          >
            <Icon icon="poli-icon pi-link-fill" size={16} />
            <div className="lastMessageInChat">
              <span>Link</span>
              {ackLastMessage}
            </div>
          </span>
        );
        break;
      case 'location':
        content = (
          <span
            style={styles.mediaMessage}
            title={props.caption || props.fileName || 'Localização'}
          >
            <Icon icon="poli-icon pi-map-fill" size={16} />
            <div className="lastMessageInChat">
              <span>Localização</span>
              {ackLastMessage}
            </div>
          </span>
        );
        break;
      case 'vcard':
        content = (
          <span
            style={styles.mediaMessage}
            title={props.caption || props.fileName || 'Contato'}
          >
            <Icon icon="poli-icon pi-perfil-line" size={16} />
            <div className="lastMessageInChat">
              <span>Contato</span>
              {ackLastMessage}
            </div>
          </span>
        );
        break;
      case 'multi_vcard':
        content = (
          <span
            style={styles.mediaMessage}
            title={props.caption || props.fileName || 'Contato'}
          >
            <Icon icon="poli-icon pi-perfil-line" size={16} />
            <div className="lastMessageInChat">
              <span>Contato</span>
              {ackLastMessage}
            </div>
          </span>
        );
        break;
      default:
    }
  } else if (message.product) {
    content = (
      <span
        style={styles.mediaMessage}
        title={message.product.name || 'Catalogo'}
      >
        <Icon icon="poli-icon pi-catalogo-line" size={16} />{' '}
        <span>{message.product?.name || 'Catálogo'}</span>
      </span>
    );
  } else if (message.payment) {
    content = (
      <span
        style={styles.mediaMessage}
        title={message.payment.name || 'Pagamento'}
      >
        <Icon icon="poli-icon pi-pay-line" size={16} color={'var(--primary)'} />{' '}
        <span>Pagamento</span>
      </span>
    );
  } else if (message.messageType == 'story_mention') {
    content = (
      <span style={styles.mediaMessage} title={'Instagram Story'}>
        <Icon icon="poli-icon pi-instagram-line" size={16} />
        <span>Mencionou você</span>
      </span>
    );
  } else if (message.messageType == 'ad_reply') {
    const newBody = JSON.parse(message.body);

    if (newBody.type === 'image') {
      content = (
        <span
          style={styles.mediaMessage}
          title={props.caption || props.fileName || 'Arquivo de imagem'}
        >
          <Icon icon="poli-icon pi-photo-fill" size={16} />
          <span>Foto</span>
        </span>
      );
    } else if (newBody.type === 'video') {
      content = (
        <span
          style={styles.mediaMessage}
          title={props.caption || props.fileName || 'Arquivo de vídeo'}
        >
          <Icon icon="poli-icon pi-camera-video-fill" size={16} />
          <span>Vídeo</span>
        </span>
      );
    } else {
      content = (
        <span style={styles.mediaMessage} title={newBody.body.text}>
          {formatText(
            newBody.body.text ? newBody.body.text : newBody.body,
            'whatsapp'
          )}
        </span>
      );
    }
  } else if (message.messageType == 'unsupported_type') {
    content = (
      <span
        style={{
          textAlign: 'center',
          fontStyle: 'italic',
          color: 'var(--secondary-font-color)',
          paddingLeft: '3px',
        }}
        title={'Message unsupported'}
      >
        <Icon
          icon="poli-icon pi-alert-line"
          color={'var(--warning)'}
          size={16}
        />{' '}
        <span>Mensagem não suportada</span>
      </span>
    );
  }

  return content;
}

const styles = {
  mediaMessage: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    maxWidth: '100%'
  },
};

export default LastMessage;
