import React from 'react';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';

function SidebarItem({ props, userConfig }) {
  const isExternalLink = props.url.startsWith('https://')
  const target = props.openNewPage===true||(props.openNewPage!==false&&isExternalLink)?'_blank':'_self';
  const userSuperRole = userConfig?.superRole;
  const active = props.active;
  let style = styles.container;
  let iconStyle = styles.icon;
  let className = 'sidebarMenuItem';
  if (active) {
    className = 'sidebarMenuItem active';
    style = {
      ...style,
      color: 'var(--activated-icons-color)',
      backgroundColor: 'var(--icon-sidebar-background)',
      borderLeft: '0.25em solid var(--green-polichat)',
    };

    iconStyle = { ...iconStyle, transform: 'matrix(-1, 0, 0, 1, 0, 0)' };
  }

  // CRM external link
  if (isExternalLink) {
    return (
      <a href={props.url} target={target} style={{ textDecoration: 'none' }}>
        <div style={style} className={className}>
          {props.icon}
          <span>{props.title}</span>
          <div className="escape"></div>
        </div>
      </a>
    );
  } else if (
    userConfig &&
    (userSuperRole === 'adm' || userSuperRole === 'manager')
  ) {
    return (
      <Link to={props.url} style={{ textDecoration: 'none' }}>
        <div style={style} className={className}>
          {props.icon}
          <span>{props.title}</span>
          <div className="escape"></div>
        </div>
      </Link>
    );
  } else {
    if (
      props.url === '/' ||
      props.url === '/chat' ||
      props.url === '/v2-report' ||
      props.url === '/crm'
    ) {
      return (
        <Link to={props.url} style={{ textDecoration: 'none' }}>
          <div style={style} className={className}>
            {props.icon}
            <span>{props.title}</span>
            <div className="escape"></div>
          </div>
        </Link>
      );
    } else {
      return <></>;
    }
  }
}

const styles = {};

const mapStateToProps = (state, ownProps) => ({
  userConfig: state?.general?.user,
  props: ownProps,
});

export default connect(mapStateToProps)(SidebarItem);
