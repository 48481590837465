import { Icon } from '@polichat/flamboyant';
import { useFlags } from 'flagsmith/react';
import { Read, Sent } from '@poli-ink/react';

function Ack(props) {
  const flag = useFlags(['ack_message']);
  let ack = props.ack !== '' && props.ack !== null ? parseInt(props.ack) : '';
  let icon;
  let title;
  let color = 'var(--ack-primary-color)';
  let size = 14;
  let className = props.className || '';

  switch (ack) {
    case 0:
      if (flag.ack_message?.enabled) {
        title = 'Enviando...';
        icon = 'poli-icon pi-clock-line';
        size = 10;
        break;
      }
      title = '';
      icon = null;
      break;
    case 1:
      title = 'Aguardando destino...';
      icon = 'poli-icon pi-check-one-fill';
      break;

    case 2:
      title = 'Chegou ao destino';
      icon = 'poli-icon pi-check-two-fill';
      break;

    case 3:
      title = 'Visualizado';
      icon = 'poli-icon pi-check-two-fill';
      color = '#21d6ff';
      break;
    // Necessário ter o case 4 separado do 3
    case 4:
      title = 'Visualizado';
      icon = 'poli-icon pi-check-two-fill';
      color = '#21d6ff';
      break;
    case -1:
      title = 'Erro ao enviar';
      icon = 'poli-icon pi-alert-fill';
      color = '#ff665d';
      size = 12;
      break;
    case -2:
      title = 'O visitante abandou a sua página';
      icon = 'poli-icon pi-leave-fill';
      break;
    default:
      //0
      // if (flag.ack_message?.enabled) {
      //   title = 'Erro ao enviar. Não será reenviado.';
      //   icon = 'poli-icon pi-alert-fill';
      //   size = 12;
      //   break;
      // }
      // title = '';
      // icon = null;
      title = 'Enviando...';
      icon = 'poli-icon pi-clock-line';
      size = 10;
      break;
  }

  // Alerta ao atualizar a tela de chats PS-181
  // Pop-up de confirmação ao recarregar a página ou pressionar F5
  if (ack === '') {
    window.onbeforeunload = function () {
      return 'Are you sure?';
    };
  } else {
    window.onbeforeunload = function () {
      return;
    };
  }
  // Fim do card PS-181
  return (
    <span className={`Ack ${className} ack-` + ack} title={title}>
      {/* {ack === '' ? (
        <Animation icon="ack-loading" size={14} />
      ) : ( */}
      {icon === 'poli-icon pi-check-two-fill' ? (
        <Read className="ackIcon" color={color} size={size} />
      ) : icon === 'poli-icon pi-check-one-fill' ? (
        <Sent className="ackIcon" color={color} size={size} />
      ) : (
        <Icon icon={icon} color={color} size={size} />
      )}
      {/* )} */}
    </span>
  );
}
export default Ack;
