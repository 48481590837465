import styled from 'styled-components';

export const Container = styled.ul`
  margin: 0 0 2rem 0;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  overflow-y: auto;

  li {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--secondary-font-color);
    padding: 10px;
    background-color: var(--secondary-background-color);
    border-radius: 6px;
    margin-right: 10px;
    margin-bottom: 10px;
    min-width: 112px;
  }
`;
export const FileInfo = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5px;

    strong {
      color: var(--primary-font-color);
      font-size: 14px;
    }

    span {
      font-size: 12px;
      display: flex;
      justify-content: center;
      text-align: center;
      color: var(--secondary-font-color);
      margin-top: 5px;

      button {
        border: 0;
        background: transparent;
        color: var(--red-polichat);
        margin-left: 5px;
        cursor: pointer;
        font-size: 20px;
      }
    }
  }
`;

export const PreviewImage = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 5px;
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  margin-right: 10px;
  margin: 3px solid yellow;
`;

export const PreviewAudio = styled.i.attrs({
  className: 'poli-icon pi-song-file-fill',
})`
  color: var(--green-polichat);
  font-size: 41px;
  margin-right: 10px;
`;

export const PreviewVideo = styled.i.attrs({
  className: 'poli-icon pi-video-fill',
})`
  color: var(--green-polichat);
  font-size: 41px;
  margin-right: 10px;
`;

export const PreviewPdf = styled.i.attrs({
  className: 'poli-icon pi-pdf-file-fill',
})`
  color: #e83f3f;
  font-size: 41px;
  margin-right: 10px;
`;

export const PreviewZip = styled.i.attrs({
  className: 'poli-icon pi-zip-fill',
})`
  color: var(--green-polichat);
  font-size: 41px;
  margin-right: 10px;
`;

export const PreviewIcon = styled.i.attrs({
  className: 'poli-icon pi-file-fill',
})`
  color: var(--green-polichat);
  font-size: 41px;
  margin-right: 10px;
`;
