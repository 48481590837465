import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

export const OpacityActive = styled.div`
  opacity: ${(props) => (props.activeStep ? '100%' : '50%')};
`;

export const Title = styled.div`
  margin-top: ${(props) => (props.step == 'connection' ? '45px' : '10px')};
`;


export const RadioGroup = styled.div`
  display: flex;
  .custom-control {
    padding-left: 0rem;
    padding-right: 1.5rem;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 600px) {
    display: block;
  }

  div {
    font-size: 0.9rem;
    color: var(--secondary-font-color);
    ${!isMobile && 'margin: 0 1rem'};
    line-height: 125%;
  }
`;

export const IconLabelContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  b {
    font-size: 1.2rem;
    color: var(--primary-font-color);
    margin: 1rem;
  }
`;

export const HorizontalRule = styled.hr`
  border: none;
  background-color: var(--gray-25) !important;
  margin-left: -0.5rem;
  height: 1px;
  width: 102%;
`;

export const ContainerDirConectionFace = styled.div`
  width: 100%;
  ${isMobile ? 'display: block' : 'display: flex'};
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-around;
  .text {
    flex: 1
  }
`;
