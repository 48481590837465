import { toast } from 'react-toastify';
import { Button, Alert, Icon } from '@polichat/flamboyant';
import { useRecreateTemplateService } from './useRecreateTemplateService';
import { useEffect, useState } from 'react';
import { string } from 'yup';
import Animation from 'src/components/common/animation';
import {
  IconLoading,
  ItemAprroved,
} from '../steps/FacebookStatus/FacebookStatus.styles';

interface RecreateTemplateOnNewBpsProps {
  channelId: string;
  custommerId: string;
}
export function RecreateTemplatesOnNewBsp(
  props: RecreateTemplateOnNewBpsProps
) {
  const { channelId, custommerId } = props;
  const service = useRecreateTemplateService({ channelId, custommerId });
  const [alertMigrateTemplate, setAlertMigrateTemplate] = useState({
    color: '',
    message: '',
  });
  useEffect(() => {
    if (service.data) {
      if (
        parseInt(service.data.migratedQuantity) === 0 &&
        !service.data.areAlreadyTemplatesMigrated
      ) {
        setAlertMigrateTemplate({
          color: 'warning',
          message: service.data.message,
        });
        return;
      }

      if (
        parseInt(service.data.migratedQuantity) === 0 &&
        service.data.areAlreadyTemplatesMigrated
      ) {
        setAlertMigrateTemplate({
          color: 'success',
          message: service.data.message,
        });
        return;
      }

      if (parseInt(service.data.migratedQuantity) > 0) {
        setAlertMigrateTemplate({
          color: 'success',
          message: `Foram migrados ${service.data.migratedQuantity} templates para aprovação. Acompanhe pelo Menu Construtor de Templates > Mensagens e Templates`,
        });
        return;
      }
    }
  }, [service.data]);

  return (
    <div style={{ display: 'block', flexDirection: 'row' }}>
      <ItemAprroved style={{ marginBottom: '1rem' }}>
        <IconLoading>
          <Icon icon="poli-icon pi-setting-fill" size={20} color="#16bac5" />
        </IconLoading>
        <span>Migrar meus templates para BSP Poli</span>
      </ItemAprroved>
      <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
        <Button
          disabled={service.loading}
          style={{ margin: '0px 0px 5px 0px' }}
          onClick={service.onRecreateTemplate}
        >
          Importar templates
        </Button>
      </div>
      <div style={{ display: 'left', padding: '1rem' }}>
        {service.loading && (
          <Alert
            color={'info'}
            style={{
              display: 'flex',
              padding: '1rem',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            {' '}
            <div style={{ top: '-10px', position: 'absolute', left: '50%' }}>
              <Animation icon="chat-loading" />
            </div>
            <span>Processando templates</span>
          </Alert>
        )}
        {!service.loading && alertMigrateTemplate.message && (
          <Alert color={alertMigrateTemplate.color}>
            {alertMigrateTemplate.message}
          </Alert>
        )}
      </div>
    </div>
  );
}
