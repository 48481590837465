import Carousel from 'react-elastic-carousel';
import { bindActionCreators } from 'redux';
import * as ChatActions from '../../../../store/modules/chat/actions';
import { connect } from 'react-redux';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Preview } from './previews/Preview';
import { Delete } from '@poli-ink/react';

function FilePreview({ removeItemUploadList, props }) {
  const [selected, setSelected] = useState({});

  // Mostra a borda
  function isSelected(file, index) {
    // Se o ID do arquivo é igual ao arquivo selecionado
    const isIdSelected = selected.id === file.id;
    // Se há apenas 1 arquivo
    const thereIsOneMedia = props.files.length === 1;
    // por padrão mostra a borda do primeiro arquivo da lista
    const isFirstFile = props.files[0].id === file.id && !selected.id;

    if (isFirstFile || isIdSelected || thereIsOneMedia) return true;
    return false;
  }

  const handleDeletePreview = (index) => {
    // Se não houver um arquivo posterior, e ainda houver arquivos na lista, seleciona o primeiro arquivo
    let indexFinal = index;
    if (!props.files[index + 1] && props.files.length > 0) {
      setSelected({ id: props.files[0]?.id, index: index });
      indexFinal = 0;
      // Se há um arquivo posterior seleciona.
    } else if (props.files[index + 1]) {
      setSelected({ id: props.files[index + 1]?.id, index: index });
    }
    // Remove o arquivo
    removeItemUploadList(props.chatSelected, index);
    // Remove a legenda do arquivo
    props.handleRemoveCaption(index);
    // Seleciona o preview por último, devido que se não houver arquivo, ele mostra o dropzone.
    props.selectPreview({ file: props.files[indexFinal], index: indexFinal });
  };

  return (
    <Carousel
      style={isMobile ? { padding: '20px 0px' } : { padding: '0px' }}
      isRTL={false}
      breakPoints={[{ width: 1, itemsToShow: isMobile ? 1 : 3 }]}
    >
      {props.files?.map((file, index) => (
        <div
          className={`filesPreviewWrapper ${
            isSelected(file, index) ? 'selected' : ''
          }`}
          key={file.id}
          id={`preview-${file.id}`}
        >
          <div
            className="filePreview"
            onClick={() => {
              setSelected({ id: file.id, index: index });
              props.selectPreview({ file: file, index: index });
              props.setSelectedPreviewIndex(index);
            }}
          >
            <Preview file={file} />
          </div>
          <div className="infoImage">
            <span>{file.name}</span>
            <div className="d-flex align-items-center">
              <p className="mb-0">{file.readableSize}</p>
              <div
                className="cursor-pointer ml-2"
                onClick={() => handleDeletePreview(index)}
              >
                <Delete className="deletePreview" />
              </div>
            </div>
          </div>
        </div>
      ))}
    </Carousel>
  );
}
const mapStateToProps = (state, ownProps) => ({
  props: ownProps,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ChatActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FilePreview);
