import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Icon,
} from '@polichat/flamboyant';

function ModalStatus({ isOpen, type, title, subtitle, toggle, className }) {
  const styles = {
    fontSize: '2rem',
    fontWeight: '700',
    color: `var(--${type})`,
    marginLeft: '10px',
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader className={className} toggle={toggle}>
        <Icon
          icon={
            type === 'success'
              ? 'poli-icon pi-smille-fill'
              : type === 'fail'
              ? 'poli-icon pi-sad-fill'
              : 'poli-icon pi-alert-line'
          }
          size={40}
          color={`var(--${type})`}
        />
        <span style={styles}>{title}</span>
      </ModalHeader>
      <ModalBody>{subtitle}</ModalBody>
      <ModalFooter>
        <Button outline onClick={toggle}>
          Voltar
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalStatus;
