import React from 'react';
import { useWhiteLabel } from 'src/hooks/useWhiteLabel';

function CartImage({ width = 160, height = 120 }) {
  let color1 = '#0000c8';
  let color2 = '#3e3eff';
  let color3 = '#1616ff';
  let color4 = '#0000e2';
  let color5 = '#b3b3ff';
  const whitelabel = useWhiteLabel();
  if (whitelabel.isWl) {
    if (whitelabel.cssPathWl.includes('chatshub')) {
      color1 = '#FF7038';
      color2 = '#fa8e64';
      color3 = '#ff6445';
      color4 = '#EE4B2B';
      color5 = '#ffd4c3';
    }
    if (whitelabel.cssPathWl.includes('auvo')) {
      color1 = '#6d26c2';
      color2 = '#7b3bc8';
      color3 = '#571e9b';
      color4 = '#41186a';
      color5 = '#d3bdec';
    }
    if (whitelabel.cssPathWl.includes('nectarcrm')) {
      color1 = '#ffd55b';
      color2 = '#ffe086';
      color3 = '#f3c641';
      color4 = '#fec10d';
      color5 = '#ffeaae';
    }
  }
  return (
    <svg
      width={width}
      height={height}
      className='img-fluid'
      viewBox="0 0 160 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M137.72 106.08C141.14 98.3776 143.04 89.8508 143.04 80.8802C143.04 46.5502 115.21 18.7202 80.88 18.7202C46.55 18.7202 18.72 46.5502 18.72 80.8802C18.72 89.8508 20.6202 98.3776 24.0402 106.08H137.72Z"
        fill={`${color5}`}
      />
      <rect
        x="8.16"
        y="105.12"
        width="145.44"
        height="1.44"
        rx="0.72"
        fill={`${color1}`}
      />
      <rect
        width="23.04"
        height="0.96"
        rx="0.48"
        transform="matrix(1 0 0 -1 0 90.2402)"
        fill={`${color1}`}
      />
      <rect
        width="11.52"
        height="0.48"
        rx="0.24"
        transform="matrix(1 0 0 -1 18.72 36)"
        fill={`${color1}`}
      />
      <rect
        width="23.04"
        height="0.96"
        rx="0.48"
        transform="matrix(1 0 0 -1 128.16 62.3999)"
        fill={`${color1}`}
      />
      <rect
        width="13.92"
        height="0.96"
        rx="0.48"
        transform="matrix(1 0 0 -1 16.32 83.52)"
        fill={`${color1}`}
      />
      <rect
        width="13.92"
        height="0.96"
        rx="0.48"
        transform="matrix(1 0 0 -1 144.48 55.6802)"
        fill={`${color1}`}
      />
      <path
        d="M60.6767 87.9938H62.8787H100.423C101.33 87.9938 102.129 87.3978 102.388 86.5284L110.477 59.3681C110.662 58.7475 110.543 58.0761 110.156 57.557C109.769 57.0378 109.159 56.732 108.512 56.732H56.3188L53.5591 47.4653C53.3001 46.596 52.5007 46 51.5935 46H43.3909C42.2583 45.9999 41.34 46.918 41.34 48.0507C41.34 49.1833 42.2583 50.1016 43.3909 50.1016H50.0645L52.8111 59.3238C52.8195 59.3542 52.8283 59.3843 52.838 59.4141L60.1343 83.9128C55.9695 84.1929 52.6662 87.6684 52.6662 91.9029C52.6662 96.3199 56.2597 99.9134 60.6766 99.9134H62.7126C63.6023 102.964 66.4221 105.2 69.7561 105.2C73.09 105.2 75.9099 102.964 76.7997 99.9134H87.6809C88.5707 102.964 91.3904 105.2 94.7245 105.2C98.0584 105.2 100.878 102.964 101.768 99.9134H104.41C105.543 99.9134 106.461 98.9951 106.461 97.8625C106.461 96.7299 105.543 95.8116 104.41 95.8116H101.768C100.878 92.761 98.0585 90.525 94.7245 90.525C91.3904 90.525 88.5705 92.761 87.6809 95.8116H76.7997C75.9099 92.761 73.0902 90.525 69.7561 90.525C66.4221 90.525 63.6022 92.761 62.7126 95.8116H60.6766C58.5212 95.8116 56.7678 94.0581 56.7678 91.9027C56.7678 89.7474 58.5213 87.9938 60.6767 87.9938ZM90.7621 60.8334H105.761L102.938 70.312H90.7621V60.8334V60.8334ZM90.762 74.4137H101.717L98.8939 83.8922H90.762V74.4137ZM75.5869 60.8334H86.6606V70.312H75.5869V60.8334ZM75.5868 74.4137H86.6604V83.8922H75.5868V74.4137V74.4137ZM71.4855 83.8922H64.4078L61.585 74.4137H71.4855V83.8922V83.8922ZM57.5406 60.8334H71.4853V70.312H60.3634L57.5406 60.8334ZM94.7246 94.6266C96.5089 94.6266 97.9604 96.0781 97.9604 97.8624C97.9604 99.6466 96.5089 101.098 94.7246 101.098C92.9404 101.098 91.4888 99.6466 91.4888 97.8624C91.4888 96.0781 92.9404 94.6266 94.7246 94.6266ZM69.7563 94.6266C71.5405 94.6266 72.992 96.0781 72.992 97.8624C72.992 99.6466 71.5405 101.098 69.7563 101.098C67.972 101.098 66.5205 99.6466 66.5205 97.8624C66.5205 96.0781 67.972 94.6266 69.7563 94.6266Z"
        fill={`${color1}`}
      />
      <path
        d="M45.5287 33.2044H46.1834H57.3452C57.6149 33.2044 57.8525 33.0272 57.9295 32.7687L60.3344 24.694C60.3893 24.5095 60.3539 24.3099 60.2388 24.1556C60.1237 24.0012 59.9425 23.9103 59.75 23.9103H44.2331L43.4127 21.1554C43.3357 20.8969 43.098 20.7197 42.8283 20.7197H40.3897C40.053 20.7197 39.78 20.9926 39.78 21.3294C39.78 21.6661 40.053 21.9391 40.3897 21.9391H42.3738L43.1903 24.6808C43.1928 24.6899 43.1954 24.6989 43.1983 24.7077L45.3675 31.9911C44.1293 32.0744 43.1472 33.1076 43.1472 34.3665C43.1472 35.6797 44.2156 36.748 45.5287 36.748H46.134C46.3985 37.6549 47.2368 38.3197 48.228 38.3197C49.2192 38.3197 50.0575 37.655 50.3221 36.748H53.557C53.8215 37.6549 54.6599 38.3197 55.6511 38.3197C56.6422 38.3197 57.4806 37.655 57.7451 36.748H58.5306C58.8673 36.748 59.1403 36.475 59.1403 36.1383C59.1403 35.8016 58.8673 35.5286 58.5306 35.5286H57.7451C57.4806 34.6216 56.6423 33.9569 55.6511 33.9569C54.6599 33.9569 53.8215 34.6216 53.557 35.5286H50.3221C50.0575 34.6216 49.2192 33.9569 48.228 33.9569C47.2368 33.9569 46.3985 34.6216 46.134 35.5286H45.5287C44.8879 35.5286 44.3666 35.0073 44.3666 34.3665C44.3666 33.7257 44.888 33.2044 45.5287 33.2044ZM54.4731 25.1297H58.9323L58.0931 27.9476H54.4731V25.1297V25.1297ZM54.473 29.167H57.7299L56.8906 31.985H54.473V29.167ZM49.9615 25.1297H53.2537V27.9476H49.9615V25.1297ZM49.9615 29.167H53.2536V31.985H49.9615V29.167V29.167ZM48.7422 31.985H46.638L45.7988 29.167H48.7422V31.985V31.985ZM44.5964 25.1297H48.7421V27.9476H45.4356L44.5964 25.1297ZM55.6511 35.1763C56.1816 35.1763 56.6131 35.6078 56.6131 36.1383C56.6131 36.6687 56.1816 37.1003 55.6511 37.1003C55.1206 37.1003 54.6891 36.6687 54.6891 36.1383C54.6891 35.6078 55.1206 35.1763 55.6511 35.1763ZM48.2281 35.1763C48.7585 35.1763 49.1901 35.6078 49.1901 36.1383C49.1901 36.6687 48.7585 37.1003 48.2281 37.1003C47.6976 37.1003 47.2661 36.6687 47.2661 36.1383C47.2661 35.6078 47.6976 35.1763 48.2281 35.1763Z"
        fill={`${color1}`}
      />
      <path
        d="M11.6404 65.5936H12.3784H24.9608C25.2648 65.5936 25.5327 65.3939 25.6195 65.1025L28.3304 56.0001C28.3923 55.7922 28.3524 55.5672 28.2227 55.3932C28.0929 55.2192 27.8887 55.1167 27.6717 55.1167H10.1799L9.25503 52.0111C9.16826 51.7197 8.90034 51.52 8.59631 51.52H5.84732C5.46775 51.52 5.16 51.8277 5.16 52.2073C5.16 52.5869 5.46775 52.8946 5.84732 52.8946H8.08389L9.00437 55.9853C9.00718 55.9955 9.01013 56.0056 9.01339 56.0156L11.4586 64.2259C10.0629 64.3198 8.9558 65.4846 8.9558 66.9037C8.9558 68.384 10.1601 69.5883 11.6404 69.5883H12.3227C12.6209 70.6106 13.5659 71.36 14.6832 71.36C15.8006 71.36 16.7456 70.6107 17.0438 69.5883H20.6905C20.9887 70.6106 21.9337 71.36 23.051 71.36C24.1683 71.36 25.1134 70.6107 25.4116 69.5883H26.2971C26.6766 69.5883 26.9844 69.2805 26.9844 68.901C26.9844 68.5214 26.6766 68.2137 26.2971 68.2137H25.4116C25.1134 67.1913 24.1684 66.4419 23.051 66.4419C21.9337 66.4419 20.9886 67.1913 20.6905 68.2137H17.0438C16.7456 67.1913 15.8006 66.4419 14.6832 66.4419C13.5659 66.4419 12.6208 67.1913 12.3227 68.2137H11.6404C10.918 68.2137 10.3304 67.626 10.3304 66.9036C10.3304 66.1813 10.9181 65.5936 11.6404 65.5936ZM21.7231 56.4912H26.7499L25.8038 59.6678H21.7231V56.4912V56.4912ZM21.7231 61.0424H25.3944L24.4483 64.219H21.7231V61.0424ZM16.6373 56.4912H20.3485V59.6678H16.6373V56.4912ZM16.6373 61.0424H20.3485V64.219H16.6373V61.0424V61.0424ZM15.2628 64.219H12.8908L11.9448 61.0424H15.2628V64.219V64.219ZM10.5894 56.4912H15.2628V59.6678H11.5354L10.5894 56.4912ZM23.0511 67.8165C23.649 67.8165 24.1355 68.303 24.1355 68.9009C24.1355 69.4989 23.649 69.9853 23.0511 69.9853C22.4531 69.9853 21.9666 69.4989 21.9666 68.9009C21.9666 68.303 22.4531 67.8165 23.0511 67.8165ZM14.6833 67.8165C15.2812 67.8165 15.7677 68.303 15.7677 68.9009C15.7677 69.4989 15.2812 69.9853 14.6833 69.9853C14.0853 69.9853 13.5989 69.4989 13.5989 68.9009C13.5989 68.303 14.0853 67.8165 14.6833 67.8165Z"
        fill={`${color5}`}
      />
      <path
        d="M117.939 29.7447H118.832H134.053C134.42 29.7447 134.744 29.5031 134.849 29.1506L138.129 18.1397C138.204 17.8881 138.155 17.6159 137.998 17.4055C137.841 17.195 137.594 17.071 137.332 17.071H116.172L115.054 13.3143C114.949 12.9618 114.625 12.7202 114.257 12.7202H110.931C110.472 12.7202 110.1 13.0924 110.1 13.5516C110.1 14.0107 110.472 14.383 110.931 14.383H113.637L114.75 18.1217C114.754 18.1341 114.757 18.1463 114.761 18.1584L117.719 28.0903C116.031 28.2038 114.692 29.6128 114.692 31.3295C114.692 33.1202 116.149 34.577 117.939 34.577H118.765C119.125 35.8137 120.268 36.7202 121.62 36.7202C122.972 36.7202 124.115 35.8137 124.476 34.577H128.887C129.248 35.8137 130.391 36.7202 131.742 36.7202C133.094 36.7202 134.237 35.8137 134.598 34.577H135.669C136.128 34.577 136.5 34.2047 136.5 33.7456C136.5 33.2864 136.128 32.9141 135.669 32.9141H134.598C134.237 31.6774 133.094 30.7709 131.742 30.7709C130.391 30.7709 129.248 31.6774 128.887 32.9141H124.476C124.115 31.6774 122.972 30.7709 121.62 30.7709C120.268 30.7709 119.125 31.6774 118.765 32.9141H117.939C117.065 32.9141 116.355 32.2032 116.355 31.3294C116.355 30.4556 117.065 29.7447 117.939 29.7447ZM130.136 18.7338H136.217L135.072 22.5764H130.136V18.7338V18.7338ZM130.136 24.2393H134.577L133.433 28.0819H130.136V24.2393ZM123.984 18.7338H128.473V22.5764H123.984V18.7338ZM123.984 24.2393H128.473V28.0819H123.984V24.2393V24.2393ZM122.321 28.0819H119.452L118.307 24.2393H122.321V28.0819V28.0819ZM116.668 18.7338H122.321V22.5764H117.812L116.668 18.7338ZM131.742 32.4337C132.466 32.4337 133.054 33.0222 133.054 33.7455C133.054 34.4688 132.466 35.0573 131.742 35.0573C131.019 35.0573 130.431 34.4688 130.431 33.7455C130.431 33.0222 131.019 32.4337 131.742 32.4337ZM121.62 32.4337C122.343 32.4337 122.932 33.0222 122.932 33.7455C122.932 34.4688 122.343 35.0573 121.62 35.0573C120.897 35.0573 120.308 34.4688 120.308 33.7455C120.308 33.0222 120.897 32.4337 121.62 32.4337Z"
        fill={`${color1}`}
      />
      <path
        d="M74.3287 16.8845H74.9834H86.1452C86.4149 16.8845 86.6525 16.7074 86.7295 16.4489L89.1344 8.37421C89.1893 8.18971 89.1539 7.99011 89.0388 7.83576C88.9237 7.68141 88.7425 7.59049 88.55 7.59049H73.0331L72.2127 4.83553C72.1357 4.57708 71.898 4.3999 71.6283 4.3999H69.1897C68.853 4.39986 68.58 4.67283 68.58 5.00958C68.58 5.34629 68.853 5.61929 69.1897 5.61929H71.1738L71.9903 8.36103C71.9928 8.37007 71.9954 8.37903 71.9983 8.38788L74.1675 15.6713C72.9293 15.7545 71.9472 16.7878 71.9472 18.0467C71.9472 19.3599 73.0156 20.4282 74.3287 20.4282H74.934C75.1985 21.3351 76.0368 21.9999 77.028 21.9999C78.0192 21.9999 78.8575 21.3352 79.1221 20.4282H82.357C82.6215 21.3351 83.4599 21.9999 84.4511 21.9999C85.4422 21.9999 86.2806 21.3352 86.5451 20.4282H87.3306C87.6673 20.4282 87.9403 20.1552 87.9403 19.8185C87.9403 19.4818 87.6673 19.2088 87.3306 19.2088H86.5451C86.2806 18.3018 85.4423 17.6371 84.4511 17.6371C83.4599 17.6371 82.6215 18.3018 82.357 19.2088H79.1221C78.8575 18.3018 78.0192 17.6371 77.028 17.6371C76.0368 17.6371 75.1985 18.3018 74.934 19.2088H74.3287C73.6879 19.2088 73.1666 18.6874 73.1666 18.0467C73.1666 17.4059 73.688 16.8845 74.3287 16.8845ZM83.2731 8.80984H87.7323L86.8931 11.6278H83.2731V8.80984V8.80984ZM83.273 12.8472H86.5299L85.6906 15.6652H83.273V12.8472ZM78.7615 8.80984H82.0537V11.6278H78.7615V8.80984ZM78.7615 12.8472H82.0536V15.6652H78.7615V12.8472V12.8472ZM77.5422 15.6652H75.438L74.5988 12.8472H77.5422V15.6652V15.6652ZM73.3964 8.80984H77.5421V11.6278H74.2356L73.3964 8.80984ZM84.4511 18.8565C84.9815 18.8565 85.4131 19.288 85.4131 19.8184C85.4131 20.3489 84.9815 20.7804 84.4511 20.7804C83.9206 20.7804 83.4891 20.3489 83.4891 19.8184C83.4891 19.288 83.9206 18.8565 84.4511 18.8565ZM77.0281 18.8565C77.5585 18.8565 77.9901 19.288 77.9901 19.8184C77.9901 20.3489 77.5585 20.7804 77.0281 20.7804C76.4976 20.7804 76.0661 20.3489 76.0661 19.8184C76.0661 19.288 76.4976 18.8565 77.0281 18.8565Z"
        fill={`${color5}`}
      />
      <path
        d="M147.852 92.7859H148.304H156.016C156.202 92.7859 156.366 92.6635 156.42 92.4849L158.081 86.906C158.119 86.7786 158.095 86.6407 158.015 86.534C157.936 86.4274 157.81 86.3646 157.677 86.3646H146.957L146.39 84.4611C146.337 84.2826 146.172 84.1602 145.986 84.1602H144.301C144.069 84.1601 143.88 84.3487 143.88 84.5814C143.88 84.814 144.069 85.0026 144.301 85.0026H145.672L146.236 86.8969C146.238 86.9032 146.24 86.9094 146.242 86.9155L147.74 91.9476C146.885 92.0052 146.206 92.7191 146.206 93.5889C146.206 94.4961 146.945 95.2343 147.852 95.2343H148.27C148.453 95.8609 149.032 96.3202 149.717 96.3202C150.402 96.3202 150.981 95.8609 151.164 95.2343H153.399C153.581 95.8609 154.161 96.3202 154.845 96.3202C155.53 96.3202 156.109 95.8609 156.292 95.2343H156.835C157.068 95.2343 157.256 95.0456 157.256 94.813C157.256 94.5804 157.068 94.3917 156.835 94.3917H156.292C156.109 93.7651 155.53 93.3058 154.845 93.3058C154.161 93.3058 153.581 93.7651 153.399 94.3917H151.164C150.981 93.7651 150.402 93.3058 149.717 93.3058C149.032 93.3058 148.453 93.7651 148.27 94.3917H147.852C147.409 94.3917 147.049 94.0315 147.049 93.5888C147.049 93.1461 147.409 92.7859 147.852 92.7859ZM154.032 87.207H157.113L156.533 89.154H154.032V87.207V87.207ZM154.032 89.9965H156.282L155.702 91.9434H154.032V89.9965ZM150.915 87.207H153.189V89.154H150.915V87.207ZM150.914 89.9965H153.189V91.9434H150.914V89.9965V89.9965ZM150.072 91.9434H148.618L148.038 89.9965H150.072V91.9434V91.9434ZM147.208 87.207H150.072V89.154H147.788L147.208 87.207ZM154.845 94.1483C155.212 94.1483 155.51 94.4465 155.51 94.813C155.51 95.1795 155.212 95.4776 154.845 95.4776C154.479 95.4776 154.181 95.1795 154.181 94.813C154.181 94.4465 154.479 94.1483 154.845 94.1483ZM149.717 94.1483C150.083 94.1483 150.381 94.4465 150.381 94.813C150.381 95.1795 150.083 95.4776 149.717 95.4776C149.35 95.4776 149.052 95.1795 149.052 94.813C149.052 94.4465 149.35 94.1483 149.717 94.1483Z"
        fill={`${color1}`}
      />
      <path
        d="M122.809 82.6443H123.463H134.625C134.895 82.6443 135.133 82.4671 135.21 82.2086L137.614 74.134C137.669 73.9495 137.634 73.7499 137.519 73.5955C137.404 73.4412 137.223 73.3503 137.03 73.3503H121.513L120.693 70.5953C120.616 70.3368 120.378 70.1597 120.108 70.1597H117.67C117.333 70.1596 117.06 70.4326 117.06 70.7693C117.06 71.1061 117.333 71.3791 117.67 71.3791H119.654L120.47 74.1208C120.473 74.1298 120.475 74.1388 120.478 74.1476L122.647 81.431C121.409 81.5143 120.427 82.5476 120.427 83.8065C120.427 85.1196 121.496 86.188 122.809 86.188H123.414C123.679 87.0949 124.517 87.7597 125.508 87.7597C126.499 87.7597 127.338 87.0949 127.602 86.188H130.837C131.102 87.0949 131.94 87.7597 132.931 87.7597C133.922 87.7597 134.761 87.0949 135.025 86.188H135.811C136.147 86.188 136.42 85.915 136.42 85.5783C136.42 85.2415 136.147 84.9685 135.811 84.9685H135.025C134.761 84.0616 133.922 83.3968 132.931 83.3968C131.94 83.3968 131.102 84.0616 130.837 84.9685H127.602C127.338 84.0616 126.499 83.3968 125.508 83.3968C124.517 83.3968 123.678 84.0616 123.414 84.9685H122.809C122.168 84.9685 121.647 84.4472 121.647 83.8064C121.647 83.1656 122.168 82.6443 122.809 82.6443ZM131.753 74.5696H136.212L135.373 77.3875H131.753V74.5696V74.5696ZM131.753 78.607H135.01L134.171 81.4249H131.753V78.607ZM127.242 74.5696H130.534V77.3875H127.242V74.5696ZM127.241 78.607H130.534V81.4249H127.241V78.607V78.607ZM126.022 81.4249H123.918L123.079 78.607H126.022V81.4249V81.4249ZM121.876 74.5696H126.022V77.3875H122.716L121.876 74.5696ZM132.931 84.6162C133.462 84.6162 133.893 85.0478 133.893 85.5782C133.893 86.1087 133.462 86.5402 132.931 86.5402C132.401 86.5402 131.969 86.1087 131.969 85.5782C131.969 85.0478 132.401 84.6162 132.931 84.6162ZM125.508 84.6162C126.039 84.6162 126.47 85.0478 126.47 85.5782C126.47 86.1087 126.039 86.5402 125.508 86.5402C124.978 86.5402 124.546 86.1087 124.546 85.5782C124.546 85.0478 124.978 84.6162 125.508 84.6162Z"
        fill={`${color5}`}
      />
    </svg>
  );
}

export default CartImage;
