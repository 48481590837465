import { QuickTypes } from '../types/constants';

export function isMessageButtonsType(name: string): boolean {
  return name.includes(`polichat_${QuickTypes.BUTTONS}`);
}
export function isMessageListOptionType(name: string): boolean {
  return name.includes(`polichat_${QuickTypes.LIST_OPTION}`);
}

export function isNumberAPITypeButtonsAndLists(numberApi: number): boolean {
  return [33, 6, 10, 34, 1, 4].includes(numberApi);
}

export function isNumberAPITypeWABA(numberApi: number): boolean {
  return [33, 6, 10, 34].includes(numberApi);
}
