import React from 'react';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Icon,
} from '@polichat/flamboyant';

function ModalConfirmDelete({
  isOpen,
  toggle,
  onClick,
  title,
  subtitle,
  warning,
}) {
  const styles = {
    title: {
      fontSize: '1.75rem',
      fontWeight: '700',
      marginLeft: '10px',
    },
    warning: {
      fontSize: '0.8rem',
      fontWeight: '700',
      color: 'var(--danger)',
    },
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <Icon
          icon="poli-icon pi-delete-fill"
          size={40}
          color={`var(--danger)`}
        />
        <span style={styles.title}>{title}</span>
      </ModalHeader>
      <ModalBody>
        <h6 style={{ ...styles.title, marginLeft: '0' }}>
          Cancelar pagamento
        </h6>
        <p>Você tem certeza que deseja cancelar {subtitle}?</p>
        <p style={styles.warning}>{warning}</p>
      </ModalBody>
      <ModalFooter>
        <Button outline onClick={toggle} size="lg">
          Voltar
        </Button>
        <Button color="danger" size="lg" onClick={onClick}>
          <span style={{ marginLeft: 5 }}>Cancelar</span>
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalConfirmDelete;
