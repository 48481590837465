import styled from 'styled-components';
import { ModalHeader, Row } from '@polichat/flamboyant';

export const BuscarCep = styled.div`
  margin-bottom: 2rem;
  display: flex;
  position: relative;
  input {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--textDefault);
    background-color: var(--backgroundInput) !important;
    background-clip: padding-box;
    border: 1px solid;
    border-color: var(--borderDefault);
    border-radius: 5px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-right: none;
  }
  button {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
`;

export const StyledRow = styled(Row)`
  position:relative;

  .cep_error{
    position: absolute;
    font-size: 14px;
    color: var(--danger);
    bottom: .5rem;
    left: .9rem;
    animation: cssAnimation 0s 5s forwards;
    visibility: visible;
    }
    
    @keyframes cssAnimation {
      to   { visibility: hidden; }
    }
  }
`;

export const StyledModalHeader = styled(ModalHeader)`
  h5 {
    justify-content: start !important;
    i {
      font-size: 1rem;
      padding: 1.5rem;
      &:before {
        font-size: 3rem;
      }
    }
    span {
      margin-left: 1rem;
      font-size: 1.5rem;
    }
  }
  button {
    span {
      font-size: 1.75rem;
    }
  }
`;
export const SendButtonInfo = styled.span`
  position: absolute;
  height: 46px;
  width: 142px;
  z-index: 1;
`;
export const MyCart = styled.div`
  #inputQuantityProducts {
    width: 3rem;
  }
`;
