import { useForm } from 'react-hook-form';
import { LocalFormValues } from './types';

export function useLocalForm() {
  // ---------------------------------------------
  // States
  const form = useForm<LocalFormValues>({
    defaultValues: {
      name: '',
      phone: '',
      display_on_webchat: false,
      status: false,
      users: [],
    },
  });

  // ---------------------------------------------
  // Functions
  // ---------------------------------------------
  // Effects
  // ---------------------------------------------
  // Transformations
  // ---------------------------------------------
  // API

  return { ...form };
}
