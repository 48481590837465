import { Button, Icon, UncontrolledTooltip } from '@polichat/flamboyant';
import { useCallback, useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ChatActions from '../../../../store/modules/chat/actions';

import { Contact, Close } from '@poli-ink/react';
import { isMobile } from 'react-device-detect';

function ContactsScreen({
  // constants
  activeNavigation,
  hasAsyncRequest,

  openChatTab,
  openContactTab,
  removeFilter,
}) {
  const [activeTabNavigation, setActiveTabNavigation] = useState();
  // Usado para permitir ou não o clique na aba de contatos
  const somethingIsLoading = hasAsyncRequest;

  useEffect(() => {
    setActiveTabNavigation(activeNavigation);
  }, [activeNavigation]);

  const handleNavigation = useCallback(() => {
    // Remove Filter é importante para o funcionamento dos filtros
    if (isMobile) {
      let contactPanelMobile = document.querySelector(
        '.contactPanelMobile'
      )?.classList;
      if (contactPanelMobile) {
        document.querySelector('.chatPanelContainer')?.classList.add('hidden');
        contactPanelMobile?.toggle('hidden');
      }
    }
    removeFilter('chat');
    if (activeTabNavigation === 'contact') {
      openChatTab();
    } else {
      openContactTab();
    }
  }, [activeTabNavigation]);

  if (activeTabNavigation) {
    return (
      <div className="filter-content" id="contactsNavigation">
        <div className="dropdown">
          <Button
            className="btnFilterDropdown"
            color="light"
            boder="border-none"
            onClick={() =>
              !somethingIsLoading
                ? handleNavigation()
                : console.log(
                    'Espere o carregamento terminar para clicar. ContactsScreen()'
                  )
            }
          >
            {activeTabNavigation === 'contact' ? (
              <>
                <Close className="closeButton" width={16} height={16} />
                <UncontrolledTooltip
                  placement="bottom"
                  target="contactsNavigation"
                >
                  Fechar Contatos
                </UncontrolledTooltip>
              </>
            ) : (
              <>
                {/*<Icon size={20} icon="poli-icon pi-contacts-line" />*/}
                <Contact width={16} height={16} />
                <UncontrolledTooltip
                  placement="bottom"
                  target="contactsNavigation"
                >
                  Abrir Contatos
                </UncontrolledTooltip>
              </>
            )}
          </Button>
        </div>
      </div>
    );
  }
  return <></>;
}

const mapStateToProps = (state) => ({
  // constants
  activeNavigation: state.chat?.config?.activeTabNavigation,
  hasAsyncRequest: state.chat?.hasAsyncRequest,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ChatActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ContactsScreen);
