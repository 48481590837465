import { DtoLogsChannel } from '../../../../../services/tools/channels/logsChannels/types/dtoLogsChannel';
import { DtoPaginatedResult } from '../../../../../services/tools/channels/logsChannels/types/dtoPagination';
import { Table } from '@polichat/flamboyant';
import TablePagination from '../../../../shopping/TablePagination';
import { FormatedDate, FormatedTypeChange } from './helpers';
import Animation from '../../../../common/animation';

export interface ResultLogsProps {
  items?: DtoPaginatedResult<DtoLogsChannel>;
  loading?: boolean;
  onPageChange: (page: number) => void;
}

export function ResultLogs(props: ResultLogsProps) {
  const totalItens = props.items?.pagination?.total;
  return (
    <>
      {props.loading ? (
        <Animation
          icon="chat-loading"
          top={'50%'}
          style={{
            maxWidth: '150px',
            height: '100%',
            maxHeight: '100px',
          }}
        />
      ) : (
        <Table className="table table-striped table-hover">
          <thead>
            <tr style={{ fontWeight: 'bold', fontSize: '1.25rem' }}>
              <th>Canal</th>
              <th>Data</th>
              <th>Usuário</th>
              <th>Tipo de alteração</th>
            </tr>
          </thead>
          <tbody>
            {props.items?.data.map((log) => (
              <tr key={log.id}>
                <th>{log.channel_name}</th>
                <th>{FormatedDate(log.date_change)}</th>
                <th>{log.user_name}</th>
                <th>{FormatedTypeChange(log.type_change)}</th>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      <TablePagination
        totalItens={totalItens}
        limitItens={props.items?.pagination.per_page}
        pageSide={1}
        onPageChange={(page: any) => props.onPageChange(page.currentPage)}
      />
    </>
  );
}
