import styled from 'styled-components';

export const DropdownList = styled.ul`
  margin: auto;
  padding: 1em 0 0.6em 1em;
  line-height: 1;

  :last-child {
    padding-bottom: 0;
  }

  a:hover {
    text-decoration: none;
    p {
      text-decoration: underline;
    }
  }
`;

export const DropdownListItem = styled.li`
  padding-bottom: 0.6em;

  p {
    padding-left: 0.6em;
    margin: 0;
  }
`;

export const ListItemContents = styled.div`
  display: flex;
  align-items: center;
`;
