import React, { useState } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Icon,
} from '@polichat/flamboyant';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ChatActions from '../../../../store/modules/chat/actions';

function ContactExternalSelector({ chat, opennedChat, selectContactExternal }) {
  const [channelSwitch, setChannelSwitch] = useState(false);
  const toggle = () => setChannelSwitch((prevState) => !prevState);
  const show = opennedChat?.contact?.externals?.length > 1;

  // retorna apenas os tipos de canais existentes praa essa empresa
  const getChannelTypes = (channels) => {
    let channel_types = [];

    channels.forEach((channel) => {
      if (!channel_types.includes(channel.channel_id)) {
        channel_types.push(channel.channel_id);
      }
    });

    return channel_types;
  };

  const renderExternalText = (external) => {
    if (external.selected === true)
      return <small className="selected-contact">{external.name}</small>;

    if (external.type == 'facebook') {
      return <small className="selected-contact">Facebook</small>;
    }

    if (external.type == 'webchat') {
      return <small className="selected-contact">Webchat</small>;
    }

    if (external.type == 'instagram') {
      return <small className="selected-contact">Instagram</small>;
    }

    return <small>{external.name}</small>;
  };

  // relaciona contato externo com canais da empresa (mesmo tipo)
  const availableExternals = () => {
    let externals = opennedChat?.contact?.externals;
    let channels = chat?.config?.user?.channels;
    const lastMessage = opennedChat?.last_message;

    // caso tenha mais de um contact_external
    if (externals.length > 1 && channels.length > 0) {
      let channel_types = getChannelTypes(channels);

      let items = externals.map((external, index) => {
        // filtrar apenas os externals que tenham o mesmo tipo dos canais
        if (channel_types.includes(external.channel_id)) {
          return (
            <DropdownItem
              className={external.selected === true ? 'dropactive' : ' '}
              onClick={() =>
                selectContactExternal(external.id, external.channel_id)
              }
              key={external.id}
            >
              <Icon
                icon={external.icon}
                color={
                  external.selected === true
                    ? 'var(--primary)'
                    : `${external.icon_color}`
                }
                size={20}
              />
              {renderExternalText(external)}
            </DropdownItem>
          );
        }

        return <></>;
      });

      return (
        <Dropdown direction="up" isOpen={channelSwitch} toggle={toggle}>
          <DropdownToggle color="link">
            <Icon
              icon={'poli-icon pi-smartphone-fill'}
              size={20}
            />
          </DropdownToggle>
          <DropdownMenu className="contactExternalSelectorContainer">
            {items}
          </DropdownMenu>
        </Dropdown>
      );
    }
  };

  if (show)
    return (
      <div className="contactExternalDropdown">{availableExternals()}</div>
    );
  else return <></>;
}

const mapStateToProps = (state) => ({
  chat: state.chat,
  opennedChat:
    state.chat?.chats?.filter((chat) => {
      return chat.open === true;
    })[0] || [],
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ChatActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactExternalSelector);
