import React, { useEffect, useState } from 'react';
import { priceMask } from '../../../../../../utils/priceMask';
import { Icon } from '@polichat/flamboyant';

import CreditIndexImg from '../../../../../../assets/images/Credit/CreditIndexImg.svg';
import Animation from '../../../../../common/animation';
import CreditIndexNewPoliImg from '../../../../../../assets/images/newIdentity/CreditIndexImg.svg';

import {
  CustomModal,
  CustomModalHeader,
  CustomModalBody,
  CustomModalPrice,
  CustomModalFooter,
  CustomForm,
  LabelForm,
  RadioGroup,
  PriceView,
} from './CreditModalAdd.styles';

// @ts-ignore
import { Button, CustomInput, Alert } from '@polichat/flamboyant';

/**
 * Requests
 */
import { postCalculateAnnualPackageValue } from '../../../../../../services/credits/request';
/* */

/**
 * Types
 */
import { SendAddCredit } from '../../../../../../types/Credit';
import { ErrorCustomerPlan } from '../..';
import { useFlags } from 'flagsmith/react';

interface ToggleModalAddCredit {
  (): void;
}

type Service = {
  id: number;
  service_id: number;
  name: string;
  description?: string;
  status?: number;
  type?: number;
  value: string;
};

interface OnAddCredit {
  (send: SendAddCredit): void;
}

interface CreditModalAddProps {
  toggleModalAddCredit: ToggleModalAddCredit;
  isOpenModalAdd: boolean;
  servicesData: Service[];
  onAddCredit: OnAddCredit;
  customerId: number;
  dueDatePlan: string;
  loadingAnnualPlanInfo: boolean;
  errorGetCustomerServiceData: ErrorCustomerPlan;
}
/* end - types */

const CreditModalAdd = (props: CreditModalAddProps) => {
  /**
   * Props
   */
  const {
    toggleModalAddCredit,
    isOpenModalAdd,
    servicesData,
    onAddCredit,
    customerId,
    dueDatePlan,
    loadingAnnualPlanInfo: isLoadingAnnualPlanInfo,
  } = props;
  /* end - props */

  /**
   * State Service data
   */
  const [packageMonthly, setPackageMonthly] = useState<Service[]>();
  const [packageYear, setPackageYear] = useState<Service[]>();
  /* end */

  // State form
  const [typeYear, setTypeYear] = useState<boolean>();
  const [selectedId, setSelectedId] = useState<number>();
  const [service_id, setServiceId] = useState<number>();
  const [quantity, setQuantity] = useState<number>(1);
  const [installment, setInstallment] = useState<number>(1);
  /* end */
  /**
   * Calculando valor total do serviço selecionado
   */
  const [valueInstallment, setValueInstallment] = useState<number>();
  const [valueTotal, setValueTotal] = useState<number>();
  const [loadingValue, setLoadingValue] = useState<boolean>();

  const calculateValueInstallmentAnnual = async () => {
    try {
      setLoadingValue(true);

      const data = {
        id: selectedId,
        service_id,
        package_quantity: quantity,
        installment,
        due_date_plan: dueDatePlan,
      };

      const result = await postCalculateAnnualPackageValue(data);

      if (result?.data?.success) {
        // Valor de cada parcela
        if (result?.data?.value_installment) {
          setValueInstallment(result?.data?.value_installment);
        }

        // Valor Total
        if (result?.data?.value_total) {
          setValueTotal(result?.data?.value_total);
        }
      }
      setLoadingValue(false);
    } catch (error) {
      console.error(
        'Error on Services Credit: postCalculateAnnualPackageValue',
        error
      );
      setLoadingValue(false);
    }
  };

  useEffect(() => {
    if (selectedId) {
      const service = servicesData.find((e) => e.id === selectedId);
      if (service && quantity) {
        // Calculo para Anual
        if (typeYear) {
          calculateValueInstallmentAnnual();
        }
        // Calculo para mensal
        else {
          const _price = parseFloat(service.value) * quantity;
          setValueTotal(_price);
        }
      }
    }
  }, [selectedId, quantity, installment]);
  /* end - Calculando valor total do serviço selecionado */

  /**
   * Altera o service_id quando altera o plano selecionado usando o id
   */
  useEffect(() => {
    if (selectedId) {
      setServiceId(
        servicesData.find((item) => item.id === selectedId)?.service_id
      );
    }
  }, [selectedId]);

  /**
   * Salvar Credito
   */
  const onSubmit = () => {
    const send = {
      id: selectedId,
      customer_id: customerId ?? 0,
      service_id: service_id ?? 0,
      package_quantity: quantity,
      type: typeYear ? 'A' : 'M',
      due_date_plan: undefined,
      recurrent: true,
      installment,
    };
    onAddCredit(send);
  };
  /* end */

  /**
   * Filtro Serviços
   * - Separar serviços de anual e mensal
   */
  useEffect(() => {
    const _packageMonthly = servicesData.filter((e) =>
      e?.name?.toString()?.toLowerCase()?.includes('mensal')
    );
    const _packageYear = servicesData.filter((e) =>
      e?.name?.toString()?.toLowerCase()?.includes('anual')
    );

    setPackageMonthly(_packageMonthly);
    setPackageYear(_packageYear);
    //
  }, [servicesData]);
  /* end */

  /**
   * Quando mudar o tipo, deve limpar o serviço selecionado
   */
  useEffect(() => {
    setServiceId(0);
    setSelectedId(0);
    setQuantity(1);
    setValueInstallment(0);
    setValueTotal(0);
  }, [typeYear]);
  /* */

  return (
    <>
      <CustomModal
        isOpen={isOpenModalAdd}
        toggle={toggleModalAddCredit}
        size="lg"
      >
        <CustomModalHeader toggle={toggleModalAddCredit} />
        <div className="shopping_overflow_y">
          <CustomModalBody>
            {/* Header */}
            <div className="modal-title">
              <div className="text-center">
                <img
                  src={CreditIndexNewPoliImg}
                  alt="Imagem ilustrativa de carrinho de compras."
                />

                <div>
                  <span>Adicionar créditos</span>
                </div>
              </div>
            </div>
            {/* end */}

            {/* Radio de Tipo de Contratação */}
            <div>
              <LabelForm>Tipo de contratação</LabelForm>

              {props.errorGetCustomerServiceData.error ? (
                <div>
                  <Alert color="attention">
                    {props.errorGetCustomerServiceData.message}!
                  </Alert>
                </div>
              ) : (
                <RadioGroup>
                  <CustomInput
                    id="Mensal"
                    type="radio"
                    label="Recorrência mensal"
                    value={false}
                    checked={!typeYear}
                    onChange={() => setTypeYear(false)}
                  />

                  {dueDatePlan && dueDatePlan?.toString()?.length > 0 && (
                    <CustomInput
                      id="Anual"
                      type="radio"
                      label="Anual"
                      value={true}
                      checked={typeYear}
                      onChange={() => setTypeYear(true)}
                    />
                  )}
                </RadioGroup>
              )}
            </div>
            {/* end */}

            {/* Inputs */}
            <CustomForm>
              <div className="flex-form">
                <LabelForm>Pacote {typeYear ? 'anual' : 'mensal'}</LabelForm>
                <select
                  defaultValue={0}
                  value={selectedId}
                  name="customSelect"
                  className="custom-select"
                  onChange={(event) => {
                    setSelectedId(parseInt(event.target.value));
                  }}
                >
                  <option value={0} disabled>
                    Selecione
                  </option>
                  {/* Pacote Mensal */}
                  {!typeYear &&
                    packageMonthly?.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}

                  {/* Pacote Anual */}
                  {typeYear &&
                    packageYear?.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                </select>
              </div>

              <div>
                <LabelForm>Qtd</LabelForm>
                <select
                  defaultValue={1}
                  value={quantity}
                  name="customSelect"
                  className="custom-select"
                  onChange={(event) => {
                    setQuantity(parseInt(event.target.value));
                  }}
                >
                  {Array.from(Array(100).keys())?.map((n, i) => {
                    return (
                      <option key={i} value={n + 1}>
                        {n + 1}
                      </option>
                    );
                  })}
                </select>
              </div>

              {/* Qtd de parcelas somente no time anual */}
              {typeYear && (
                <div>
                  <LabelForm>Qtd. Parcelas</LabelForm>
                  <select
                    defaultValue={1}
                    value={installment}
                    name="customSelect"
                    className="custom-select"
                    onChange={(event) => {
                      setInstallment(parseInt(event.target.value));
                    }}
                  >
                    {Array.from(Array(3).keys())?.map((n, i) => {
                      return (
                        <option key={i} value={n + 1}>
                          {n + 1}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
            </CustomForm>
            {/* end - inputs */}
          </CustomModalBody>

          {/* Valor Total */}
          <CustomModalPrice>
            <PriceView>
              {/* Se Anual */}
              {typeYear && (
                <div className="_flex">
                  <LabelForm>Valor</LabelForm>
                  {!loadingValue && (
                    <h5>
                      {installment}x{' '}
                      {priceMask((valueInstallment ?? 0).toFixed(2))}
                    </h5>
                  )}
                </div>
              )}

              {loadingValue && (
                <Animation
                  icon="chat-loading"
                  style={{
                    maxWidth: '84px',
                    height: '100%',
                  }}
                />
              )}

              <div
                className="_flex"
                style={typeYear ? { textAlign: 'end' } : {}}
              >
                <LabelForm>Valor Total</LabelForm>
                {!loadingValue && (
                  <h5>
                    <b>{priceMask((valueTotal ?? 0).toFixed(2))}</b>
                  </h5>
                )}
              </div>
            </PriceView>
          </CustomModalPrice>
          <CustomModalPrice
            style={{
              padding: '1rem',
              backgroundColor: '#FAE0AA',
              margin: '0rem 1rem 1rem 1rem',
              borderRadius: '10px',
              color: '#232C3D',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <Icon
                color="#FFCA5D"
                size={24} 
                icon="poli-icon pi-alert-line"
              />
              <>
                Os créditos contratados não são acumulados para o próximo mês, 
                caso você não os utilize por completo. Se o pacote de créditos 
                for cancelado antes do período de 3 meses, será cobrada a 
                diferença dos créditos no valor de R$ 0,89/crédito.
              </>
            </div>
          </CustomModalPrice>
          <CustomModalFooter>
            <Button
              className="btn-block"
              outline
              onClick={toggleModalAddCredit}
            >
              <span>Cancelar</span>
            </Button>
            <Button
              className="btn-block"
              disabled={!service_id || isLoadingAnnualPlanInfo}
              color="primary"
              onClick={onSubmit}
            >
              {isLoadingAnnualPlanInfo ? (
                <span>Sincronizando dados</span>
              ) : (
                <span>Comprar</span>
              )}
            </Button>
          </CustomModalFooter>
        </div>
      </CustomModal>
    </>
  );
};

export default CreditModalAdd;
