
import { CustomerExpendCreditsPercentage } from "./types"
import { EnumPercentages } from "./enumPercentages"
import { wabaChannelPercent } from "./wabaChannels"
import { calculatePercent } from "./calculatePercent"
import { channelExists, isRegularChannel } from "../contactDayRules/allChannels"

const customerDontHavePercent: CustomerExpendCreditsPercentage = {
  hasPercentage: false
}
//TODO: refactor data types
export function customerExpendCreditsPercentage(channelId: number | undefined, data: any): CustomerExpendCreditsPercentage{

  if (channelId === undefined || data === undefined)
    return customerDontHavePercent

  if(!channelExists(channelId, data))
    return customerDontHavePercent

  const isRegular = isRegularChannel(channelId, data)

  const channelCreditsPercent = isRegular ?
    regularChannelPercent(channelId, data) :
    wabaChannelPercent(data, channelId)

  const isLessThanMinPercent = isChannelCreditLessThanMinPercent(channelCreditsPercent)

  if(isLessThanMinPercent)
    return customerDontHavePercent

  return {
    hasPercentage: true,
    channelId: channelId,
    percentageValue: channelCreditsPercent
  }
}

function regularChannelPercent(channelId:number, data: any){
  let channelData = data[channelId.toString()]
  return calculatePercent(channelData, data.detachedCredits)
}

function isChannelCreditLessThanMinPercent(channelCreditPercent: number): boolean{
  return channelCreditPercent < EnumPercentages.MINIMUM
}


