import { Controller } from 'react-hook-form';
import { useLocalForm } from './useLocalForm';
import * as React from 'react';
import {
  Button,
  FormGroup,
  CustomInput,
  Col,
  Row,
  Label,
  Icon,
  Input,
} from '@polichat/flamboyant';
import { LocalFormValues } from './useLocalForm/types';
import { DtoData } from '../../../../../services/tools/channels/logsChannels/types/dtoAllChannels';
import { DtoAllUsers } from '../../../../../services/tools/channels/logsChannels/types/dtoAllUsers';
import { FormFeedback } from 'reactstrap';

export interface ListChannels {
  loading: boolean;
  data?: DtoData[];
}
export interface ListUsers {
  loading: boolean;
  data?: DtoAllUsers[];
}
export interface FilterLogsProps {
  onSubmit(values: LocalFormValues): void;
  channelList?: ListChannels;
  userList?: ListUsers;
  customerFilter: number;
}
export interface SelectCustomerLogsProps {
  setCurrentCustomer(value: number): void;
}

export function FilterLogs(props: FilterLogsProps) {
  const form = useLocalForm();

  return (
    <>
      <Row style={{ alignItems: 'flex-end' }}>
        <Col sm={12} md={4}>
          <FormGroup>
            <Label for={'filtro-canal'}>
              <Icon icon="poli-icon pi-channels-line" size={18} />
              Canais:{' '}
            </Label>
            <Controller
              control={form.control}
              name={'channel_id'}
              render={({ field }) => (
                <CustomInput
                  type="select"
                  id="filtro-canal"
                  onChange={field.onChange}
                  value={field.value}
                  disabled={props.channelList?.loading}
                >
                  <optgroup
                    disabled={props.channelList?.loading}
                    label="Filtrar por canal"
                  >
                    <option value="">
                      {props.channelList?.loading
                        ? 'Carregando...'
                        : 'Todos os Canais'}
                    </option>
                    {props.channelList?.data ? (
                      props.channelList?.data?.map((channel: any) => (
                        <option key={channel.id} value={channel.id}>
                          {channel.name}
                        </option>
                      ))
                    ) : (
                      <option value="">-- SEM CANAL --</option>
                    )}
                  </optgroup>
                </CustomInput>
              )}
            />
          </FormGroup>
        </Col>
        <Col sm={12} md={4}>
          <FormGroup>
            <Label for={'filtro-usuario'}>
              <Icon icon="poli-icon pi-perfil-line" size={18} />
              Usuários:{' '}
            </Label>
            <Controller
              control={form.control}
              name={'user_id'}
              render={({ field }) => (
                <CustomInput
                  type="select"
                  id="filtro-usuario"
                  onChange={field.onChange}
                  value={field.value}
                  disabled={props.userList?.loading}
                >
                  <optgroup
                    disabled={props.userList?.loading}
                    label="Filtrar por usuario"
                  >
                    <option value="">
                      {props.userList?.loading
                        ? 'Carregando...'
                        : 'Todos os usuários'}
                    </option>
                    {props.userList?.data ? (
                      props.userList.data?.map((user) => (
                        <option key={user.id} value={user.id}>
                          {user.name}
                        </option>
                      ))
                    ) : (
                      <option value="">-- SEM USUÁRIO --</option>
                    )}
                  </optgroup>
                </CustomInput>
              )}
            />
          </FormGroup>
        </Col>
        <Col sm={12} md={4}>
          <FormGroup>
            <Label for={'filtro-estado-canal'}>
              <Icon icon="poli-icon pi-text-left-line" size={18} />
              Tipo de alteração:{' '}
            </Label>
            <Controller
              control={form.control}
              name={'type_change'}
              render={({ field }) => (
                <CustomInput
                  type="select"
                  id="filtro-estado-canal"
                  onChange={field.onChange}
                  value={field.value}
                >
                  <optgroup label="Tipo de alteração">
                    <option value="">Todas as alterações</option>
                    <option value="ativado">Ativado</option>
                    <option value="desativado">Desativado</option>
                    <option value="excluido">Excluído</option>
                    <option value="desconectado">Desconectado</option>
                    <option value="conectado">Conectado</option>
                  </optgroup>
                </CustomInput>
              )}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row style={{ alignItems: 'flex-end' }}>
        <Col sm={12} md={4}>
          <FormGroup>
            <Label for={'filtro-data-start'}>
              <Icon icon="pi-calendar-line" size={18} />
              Desde a data:{' '}
            </Label>
            <Controller
              control={form.control}
              name={'date_start'}
              render={({ field, fieldState }) => (
                <>
                  <Input
                    type="date"
                    invalid={!!fieldState.error}
                    onChange={field.onChange}
                    value={field.value}
                  />
                  <FormFeedback style={{ position: 'absolute' }}>
                    {fieldState.error?.message}
                  </FormFeedback>
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col sm={12} md={4}>
          <FormGroup>
            <Label for={'filtro-data-end'} style={{ marginTop: '1rem' }}>
              <Icon icon="pi-calendar-line" size={18} />
              Até a data:{' '}
            </Label>
            <Controller
              control={form.control}
              name={'date_end'}
              render={({ field, fieldState }) => (
                <>
                  <Input
                    type="date"
                    invalid={!!fieldState.error}
                    disabled={!form.watchValues.DateStart}
                    onChange={field.onChange}
                    value={field.value}
                  />
                  <FormFeedback style={{ position: 'absolute' }}>
                    {fieldState.error?.message}
                  </FormFeedback>
                </>
              )}
            />
          </FormGroup>
        </Col>
        <Col sm={12} md={4}>
          <FormGroup>
            {' '}
            <Button
              style={{ marginTop: '1rem', width: '100%' }}
              onClick={form.handleSubmit(props.onSubmit)}
              disabled={props.channelList?.loading || props.userList?.loading}
            >
              Buscar...
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
}
