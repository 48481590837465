import { put, all, call, takeLatest } from 'redux-saga/effects';
import {
  ActionTypes,
  getCategoriesSuccess,
  getCategoriesFailure
} from './actions';

import ProductService from '../../../../services/shopping/product';

function* getCategoriesRequest(event) {
  try 
  {
    let result = yield call(ProductService.getCategories, event.params);

    let data = {
      list: result.data.data,
      pagination: {
        total_registers_server: result.data.total,
        registers_per_page: result.data.per_page,
        current_page: result.data.current_page,
        last_page: result.data.last_page
      } 
    };

    yield put(getCategoriesSuccess(data));
  } catch (e) {
    yield put(getCategoriesFailure(e));
  }
}

export default all([
  takeLatest(ActionTypes.GET_CATEGORIES, getCategoriesRequest),
]);
