import { useCallback, useEffect, useState } from 'react';

import { ApiList } from '../../../../../../enums/channels';

import ChannelCustomerService from '../../../../../../services/tools/channels/channels';

export interface ChannelsServiceProps {
  customer_id?: number;
}
export function useChannelsService(props: ChannelsServiceProps) {
  const TYPE_WABA_360 = 360;
  const PER_PAGE = 50;

  const [channels, setChannels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  // ---------------------------------------------
  // Functions
  const load = useCallback(async (customer_id: number) => {
    try {
      setLoading(true);
      // (PS-2073)[https://poli-digital.atlassian.net/browse/PS-2073]
      const params = {
        customer_id,
        api: ApiList.waba,
        type: TYPE_WABA_360,
        size: PER_PAGE,
      };
      const { data } = await ChannelCustomerService.getAllChannels(params);

      setChannels(data);
      setError(false);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);
  // ---------------------------------------------
  // Effects
  useEffect(() => {
    if (props.customer_id) {
      load(props.customer_id);
    }
  }, [props.customer_id]);
  // ---------------------------------------------
  // Transformations
  // ---------------------------------------------
  // API

  return {
    channels,
    loading,
    error,
  };
}
