import * as ChatActions from '../../../../../store/modules/chat/actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useEffect } from 'react';

// TODO Só comentei o código de delete devido que futuramente pode ser implementado.

function ShowPreview({ removeItemUploadList, props }) {
  useEffect(() => {
    if (props.openFile !== undefined) {
      props.open();
    }
  }, [props.openFile])
  return (
    <div className="cardShowPreview">
      <div className="buttonsToUpload" />
      <div className="containerShowPreview">
        {props.selectPreview?.typeFile === 'image' ? (
          <img
            className="img-fluid preview"
            src={props.selectPreview?.preview}
            alt="Preview"
          />
        ) : props.selectPreview?.typeFile === 'video' ? (
          <div className="poli-icon pi-video-fill iconPreview" />
        ) : props.selectPreview?.typeFile === 'audio' ? (
          <div className="poli-icon pi-song-file-fill iconPreview" />
        ) : props.selectPreview?.subTypeFile === 'pdf' ? (
          <div className="poli-icon pi-pdf-file-fill iconPreview pdf" />
        ) : props.selectPreview?.subTypeFile === 'zip' ||
          props.selectPreview?.subTypeFile === 'rar' ? (
          <div className="poli-icon pi-zip-fill iconPreview" />
        ) : (
          <div className="poli-icon pi-file-fill iconPreview" />
        )}
      </div>
      <div className="infoPreview">
        <span>{props.selectPreview.name}</span>
        <div className="d-flex flex-row align-items-center">
          <p className="mb-0">{props.selectPreview.readableSize}</p>
          {/* <div
            className="cursor-pointer ml-2"
            onClick={() =>
              removeItemUploadList(
                props.chatSelected,
                props.selectPreview.index
              )
            }
          >
            <Delete className="deletePreview" />
          </div> */}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  props: ownProps,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ChatActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ShowPreview);
