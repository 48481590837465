import styled from 'styled-components';

export const ButtonOpenFB = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & button {
    display: flex;
    align-items: center;
    justify-content: space-around;

    span {
      font-size: 20px;
    }

    & i {
      margin-right: 0.5rem;
    }
  }
`;
