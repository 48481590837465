export function ptBR(){
    return {
        Chats:"Chats"
    }
}

export function enUS(){
    return {
        Chats:"Chats",
        Canais:"Channels",
        Data:"Date",
        Etiquetas:"Tags",
        Departamentos:"Departments",
        Atendentes:"Attendants"
    }
}
