import { createContext, useEffect, useState } from 'react';

const GlobalContext = createContext({});

const AvatarContactProvider = ({ children }: any) => {
  const [pictureGlobal, setPictureGlobal] = useState();
  const [pictureReceived, setPictureReceived] = useState();
  const [beforePicture, setBeforePicture] = useState();

  useEffect(() => {
    if(pictureReceived){
      setPictureGlobal(pictureReceived);
    } else {
      setPictureGlobal(beforePicture);
    }
  }, [pictureReceived, beforePicture]);

  return (
    <GlobalContext.Provider
      value={{
        pictureGlobal,
        setBeforePicture,
        setPictureReceived,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export { GlobalContext, AvatarContactProvider };
