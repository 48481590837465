import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import poliConfig from '../../utils/poliConfig';

export function useWhiteLabel() {
  //@ts-ignore
  const wlState = useSelector((state) => state?.whitelabel);
  const [cssPathWl, setCssPathWl] = useState<string | string[]>();
  const [isWl, setIsWl] = useState<boolean>();
  const [title, setTitle] = useState<string>();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [scriptInjection, setScriptInjection] = useState<string>();

  const currentLaravelUrl =
    localStorage.getItem('current_laravel_url') ??
    process.env.REACT_APP_LARAVEL_URL;

  const [laravelUrl, setLaravelUrl] = useState<string | undefined>(
    currentLaravelUrl
  );

  useEffect(() => {
    const _config = wlState?.config;
    const _pathCss = _config?.path_css_spa;
    const _isWl = _config?.is_white_label;
    const _laravelUrl = _config?.laravel_url;
    const loadingWl = wlState.loading;
    const _spaFavicon = _config?.spa_favicon;
    const _spaTitle = _config?.spa_title;

    const favicon = document.querySelector('link[rel=icon]');

    if (_spaFavicon) {
      if (favicon) {
        //@ts-ignore
        favicon.href = Array.isArray(_spaFavicon)
          ? __dirname + _spaFavicon.join('/')
          : _spaFavicon;
      }
    }

    if (_isWl && !loadingWl) {
      if (_pathCss) {
        const wlCssPath = Array.isArray(_pathCss)
          ? __dirname + _pathCss.join('/')
          : _pathCss;
        setCssPathWl(wlCssPath);
      }

      // If White Label, remove darkmode forced by OS dark theme.
      document.body.classList.remove('darkMode');
      localStorage.removeItem('@polichat/darkmode');

      if (_laravelUrl) {
        localStorage.setItem('current_laravel_url', _laravelUrl);
        setLaravelUrl(_laravelUrl);
      }
    }

    setIsWl(_isWl);
    setTitle(_spaTitle);
    setLoaded(true);
    setScriptInjection(_config?.script_injection || '');
  }, [wlState]);

  poliConfig.poliAppLaravelUrl = laravelUrl;

  return {
    cssPathWl: cssPathWl,
    isWl: isWl,
    loaded: loaded,
    spaTitle: title,
    scriptInjection: scriptInjection,
  };
}
