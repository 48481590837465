import axios from 'axios';
import ObjectToQueryString from '../../lib/ObjectToQueryString';

import gatewayListConfig from './payment/gatewayListConfig';
import poliConfig from 'src/utils/poliConfig';

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const defaultConfig = {
  headers: defaultHeaders,
  withCredentials: true,
};

const getOrders = (params = null) => {
  return axios.get(
    poliConfig.poliAppLaravelUrl +
      `/shopping/orders/search` +
      (params ? '?' + ObjectToQueryString(params) : ''),
    defaultConfig
  );
};

const getOrderDetail = (id) => {
  return axios.get(
    poliConfig.poliAppLaravelUrl + `/shopping/orders/${id}`,
    defaultConfig
  );
};

const deleteOrder = (id) => {
  return axios.delete(
    poliConfig.poliAppLaravelUrl + `/shopping/orders/${id}`,
    defaultConfig
  );
};

const getOrderStatus = () => {
  return axios.get(
    poliConfig.poliAppLaravelUrl + `/shopping/orders/status`,
    defaultConfig
  );
};

const getPaymentMethods = () => {
  return axios.get(
    poliConfig.poliAppLaravelUrl + `/shopping/orders/paymentmethods`,
    defaultConfig
  );
};

const createOrder = (data) => {
  return axios.post(
    poliConfig.poliAppLaravelUrl + `/shopping/orders`,
    data,
    defaultConfig
  );
};

const markOrderSended = (order_id, integration_id = null) => {
  return axios.post(
    poliConfig.poliAppLaravelUrl +
      `/shopping/orders/${order_id}/marksended`,
    integration_id ? { integration_id } : null,
    defaultConfig
  );
};

const updateOrder = (id, data) => {
  return axios.put(
    poliConfig.poliAppLaravelUrl + `/shopping/orders/${id}`,
    data,
    defaultConfig
  );
};

const getPaymentGateways = async () => {
  let result = await gatewayListConfig.fetchGateways();
  return result;
};

export default {
  getOrders,
  getOrderDetail,
  deleteOrder,
  getOrderStatus,
  getPaymentMethods,
  createOrder,
  updateOrder,
  markOrderSended,
  getPaymentGateways,
};
