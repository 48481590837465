import styled, { keyframes } from "styled-components";

const rotateAnimation = keyframes`
  to {
    transform: rotate(1turn);
  }
`;

const LoadingDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  .c-loader {
    animation: ${rotateAnimation} 1s infinite;
    border: 4px solid var(--green-polichat);
    border-radius: 50%;
    border-top-color: transparent !important;
  }

  .loading-text {
    font-size: 15px;
    margin-top: 0.5rem;
  }

  .lg {
    border: 4px solid var(--primary);
    height: 50px;
    width: 50px;
  }

  .md {
    border: 3px solid var(--primary);
    height: 37.5px;
    width: 37.5px;
  }

  .sm {
    border: 2px solid var(--primary);
    height: 25px;
    width: 25px;
  }
`;

interface LoadingProps {
  text?: string;
  size: 'sm' | 'md' | 'lg'
}

export function Loading({ size = 'lg', text }: LoadingProps) {
  return (
    <LoadingDiv>
      <div className={`c-loader ${size}`}></div>
      <span className="loading-text">{ text }</span>
    </LoadingDiv>
  );
}
