import { createStore, applyMiddleware, compose } from 'redux';
import * as Sentry from "@sentry/react";
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './modules/rootReducer';
import rootSaga from './modules/rootSaga';

const sagaMiddleware = createSagaMiddleware();

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

// const devToolEnable = true;

// var composeStore = compose
// if (devToolEnable) {
//   composeStore = composeWithDevTools
// }


// Teste Performace
import { batchedSubscribe } from 'redux-batched-subscribe';
import { unstable_batchedUpdates } from 'react-dom';

const enhancer = composeWithDevTools(
  applyMiddleware(sagaMiddleware),
  sentryReduxEnhancer,
  batchedSubscribe(unstable_batchedUpdates)
)
/* fim - teste */


const store = createStore(
  rootReducer,
  // composeStore(applyMiddleware(sagaMiddleware), sentryReduxEnhancer), // original
  enhancer
);

sagaMiddleware.run(rootSaga);

export default store;
