import React, { useEffect, useState } from 'react';

import {
  Button,
  CustomInput,
  CustomPhoneInput,
  FormGroup,
  Icon,
  Input,
  Label,
  MultiSelector,
  Switch,
  InputGroup,
  InputGroupAddon,
  Modal,
  ModalBody,
  ModalFooter,
} from '@polichat/flamboyant';

import {
  ActionGroup,
  CheckBoxContainer,
  Container,
  ContrastBackgroundContainer,
  InlineInput,
  InputItem,
  InputCopy,
  InputsContainer,
  MultiSelectorContainer,
  PhoneContainer,
  Section,
  SwitchContainer,
} from './ChannelAddEditBroker.styles';

import {
  WebchatContainerValuesSelected,
  WebchatItem,
} from './utils/ChannelAddEdit.styles';

import useModal from './utils/useModal';

import { toast } from 'react-toastify';

import ChannelStatusAlert, {
  ChannelStatuses,
} from '../ChannelStatusAlert/ChannelStatusAlert.component';
import ChannelsService from '../../../../services/tools/channels/channels';
import UsersService from '../../../../services/tools/users';
import ChannelDescriptionBannerWebchat from '../ChannelDescriptionBanner/ChannelDescriptionBannerWebchat.component';
import ChannelBaseboardWebchat from '../ChannelDescriptionBanner/ChannelBaseboardWebchat.component';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import ChannelCustomerStatusCodes from '../ChannelCustomerStatusCodes';
import { useHistory } from 'react-router-dom';
import DeleteChannelModal from '../DeleteChannelModal/DeleteChannelModal.component';
import poliConfig from '../../../../utils/poliConfig';

import isUrlValid from './utils/isUrlValid';

export default function ChannelAddEditBroker({
  data: dataFromDad,
  dataSet,
  userAdmPoliGetChannelFromCustomerId,
}) {
  const { name } = dataFromDad || {};
  let usersDad = dataFromDad?.users || [];

  let currentWebsite = null;
  let website = null;
  if(poliConfig.bugfix_PS_662) {
    website = dataFromDad?.config?.website ?? [];
    currentWebsite = website || [];
  } else {
    website = dataFromDad?.config?.website ?? '';
    currentWebsite = website || '';
  }
  const customer_id = useSelector((state) => {
    if (userAdmPoliGetChannelFromCustomerId) {
      return userAdmPoliGetChannelFromCustomerId;
    } else {
      return state.general.current_customer_id;
    }
  });

  // Estados locais para lidar com mudanças nos valores de um input
  const [channelName, setChannelName] = useState(name || '');

  const [channelWebsite, setChannelWebsite] = useState(currentWebsite);
  const [selectedUsers, setSelectedUsers] = useState([]);

  // Guarda a escolha do usuário sobre a ativação do canal
  const [activateChannel, setActivateChannel] = useState(
    dataFromDad?.status ?? true
  );
  const [usersOptions, setUsersOptions] = useState([]);
  const [sendButtonDisabled, setSendButtonDisabled] = useState(false);

  const [showDeleteChannel, setShowDeleteChannel] = useState(false);

  const { isOpen, onOpenRequest, onCloseRequest, selected } = useModal();

  const [webchatInput, setWebsiteInput] = useState('');
  const [websites, setWebsites] = useState(currentWebsite);

  // Popula a lista de usuários que podem ou não acessar o canal
  useEffect(() => {

    /**
     * verifica se a empresa passou pela analise do plano antes de criar canal
     * e não é edição
    */
    if(!historyState?.validated_can_add_channel && !dataFromDad){
      history.push(`/tools/channels/add`)
      return
    }

    async function fetchUsers() {
      try {
        const users_result = await ChannelsService.getAllUsersByCustomer(
          customer_id
        );
        const { data: users } = users_result;
        //Usado para criar o objeto options que irá alimentar o componente MultiSelector
        if (users && users.length) {
          const newUsersOptions = users.map((user) => {
            return {
              value: user.id,
              label: user.name,
            };
          });

          if (poliConfig.bugfix_PS_385) {
            const usersSelected = newUsersOptions.filter((user) =>
              usersDad.includes(user.value)
            );

            setUsersOptions(newUsersOptions);
            setSelectedUsers(
              usersSelected.length ? usersSelected : newUsersOptions
            );
          } else {
            setUsersOptions(newUsersOptions);
            setSelectedUsers(newUsersOptions);
          }
        }
        return;
      } catch (error) {
        console.log(
          'Erro no componente ChannelAddEditWebchat na função fetchUsers => ',
          error
        );
        return;
      }
    }

    fetchUsers();
  }, []);

  // Para redirecionar o usuário após criação de um canal
  const history = useHistory();
   // Pegar state enviado pela URL
   const { state: historyState } = history.location

  // Funções utilizadas pelos inputs quando os valores mudarem (OnChange)
  const handleNameChange = (e) => setChannelName(e.target.value);
  const handleWebsiteChange = (e) => setChannelWebsite(e.target.value);
  const handleGetSelectorValues = (data) => setSelectedUsers(data);
  const handleShowOnWidgetChange = (e) =>
    setShowChannelOnWidget(e.target.checked);
  const handleActivateChannelChange = (e) =>
    setActivateChannel(e.target.checked);
  const handleOptimizeChannelChange = (e) =>
    setAllowChannelOptimization(e.target.checked);
  const handleTimeForOptimizeChange = (e) => setTimeForOptimize(e.target.value);

  const handleAddWebsite = (addWebsite) => {
    if (isUrlValid(addWebsite)) {
      if (typeof websites == 'object' && websites.find((item) => item === addWebsite)) {
        toast.warning(`O website ${addWebsite}" já foi adicionado.`);

      // caso for canal antigo e o site for uma string
      } else if(typeof websites == 'string') {
        const addingAsVector = [websites, addWebsite];
        setChannelWebsite(addingAsVector);
        setWebsites(addingAsVector);
      } else {
        setChannelWebsite([...websites, addWebsite]);
        setWebsites([...websites, addWebsite]);
      }
      setWebsiteInput('');
    } else {
      toast.error('Por favor, inserir um URL válido.');
    }
  };

  const handleRemoveWebsite = (removeWebsite) => {
    setWebsites(websites.filter((website) => website !== removeWebsite));
    setChannelWebsite(websites.filter((website) => website !== removeWebsite));
  };

  const handleSendForm = async () => {
    setSendButtonDisabled(true);

    try {
      const channelObject = {
        name: channelName,
        customer_id,
        channel_id: 4, // Sempre será 4 pois é o código do Webchat no backend
        status: activateChannel ? 1 : 0,
        users: selectedUsers?.map((user) => user.value),
        config: {
          website: channelWebsite,
        },
      };

      if (!dataFromDad) {
        channelObject.config = JSON.stringify(channelObject.config);

        const result = await ChannelsService.createChannel(channelObject);

        if (result.status == ChannelCustomerStatusCodes.CREATED) {
          toast.success('Canal criado com sucesso!');
          history.push(`/tools/channels/edit/${result.data.id}`);
        } else {
          toast.error('Erro ao criar o canal');
          setSendButtonDisabled(false);
        }
      } else {
        // Editar um canal existente
        channelObject.id = dataFromDad.id;

        const result = await ChannelsService.updateChannel(channelObject);

        if (result.status == ChannelCustomerStatusCodes.OK) {
          toast.success('Canal editado com sucesso!');
          setSendButtonDisabled(false);
          history.push(`/tools/channels/edit/${channelObject.id}`);
        } else {
          throw 'Requisição inválida';
        }
      }
      return;
    } catch (error) {
      console.log(
        `Erro ao ${dataFromDad ? 'editar' : 'criar'} canal => `,
        error
      );
      toast.error(<p style={{ color: 'var(--danger)' }}>
        Erro ao {dataFromDad ? 'editar' : 'criar'} canal!<br/>
        Se erro persistir entre em contato com o suporte.
      </p>);
      setSendButtonDisabled(false);
      return;
    }
  };
  let textoScript = null;
  //altera url if environment is production
  //feature_polichat_canais is false in production
  if (
    poliConfig.feature_polichat_canais ||
    customer_id === Number(poliConfig.feature_enable_customer_tester_id)
  ) {
    //alterado de TAG_SERVICE_URI para REACT_APP_TAG_SERVICE_URI
    textoScript = dataFromDad
      ? `<script type="text/javascript">
  (function(p,o,l,i,c,h,a,t){
p['PolichatObject'] = c;p[c]=p[c]||function(){
(p[c].q=p[c].q||[]).push(arguments);},h=o.createElement(l);p[c].t=p[c].t||new Date();
a=o.getElementsByTagName(l)[0];h.async=1;h.src=i;a.parentNode.insertBefore(h,a);
})(window,document,'script','${process.env?.REACT_APP_TAG_SERVICE_URI}/public/services/serv.js','poli');
poli('create', ${dataFromDad.customer_id});
poli('send','webchat', {uid: '${dataFromDad.uid}'});
</script>`
      : '';
  } else {
    //alterado de TAG_SERVICE_URI para REACT_APP_TAG_SERVICE_URI
    textoScript = dataFromDad
      ? `<script type="text/javascript">
  (function(p,o,l,i,c,h,a,t){
p['PolichatObject'] = c;p[c]=p[c]||function(){
(p[c].q=p[c].q||[]).push(arguments);},h=o.createElement(l);p[c].t=p[c].t||new Date();
a=o.getElementsByTagName(l)[0];h.async=1;h.src=i;a.parentNode.insertBefore(h,a);
})(window,document,'script','${process.env?.TAG_SERVICE_URI}/public/services/serv.js','poli');
poli('create', ${dataFromDad.customer_id});
poli('send','webchat', {uid: '${dataFromDad.uid}'});
</script>`
      : '';
  }

  const handleDeleteChannel = async () => {
    try {
      const { id, customer_id } = dataFromDad;
      const result = await ChannelsService.deleteChannel({ id, customer_id });

      if (result.status == 200) history.push('/tools/channels');
      else throw 'Requisição mal sucedida';
    } catch (error) {
      console.log('Erro encontrado ao tentar deletar canal: ', error);
    }
  };

  return (
    <>
      <Container>
        <ChannelDescriptionBannerWebchat />

        <Section>
          {dataFromDad ? (
            <>
              <div className="d-flex justify-content-center justify-content-xl-end">
                <Button
                  color="danger"
                  size={'md'}
                  onClick={() => setShowDeleteChannel(!showDeleteChannel)}
                  style={{
                    justifyContent: 'space-between',
                    backgroundColor: 'var(--secondary-backgound-color)',
                    color: '#fff',
                    width: '12rem',
                    display: 'flex',
                    gap: '0.5rem',
                    alignItems: 'center',
                    paddingRight: '2rem',
                    paddingLeft: '2rem',
                  }}
                >
                  <Icon
                    icon="poli-icon pi-delete-fill"
                    color="#ffff"
                    size="20"
                  />
                  {'  '}Excluir canal
                </Button>
                <DeleteChannelModal
                  isOpen={showDeleteChannel}
                  toggle={() => setShowDeleteChannel(!showDeleteChannel)}
                  channelData={dataFromDad}
                  handleDeleteChannel={handleDeleteChannel}
                />
              </div>
            </>
          ) : null}
          <InputsContainer>
            <InputItem style={{ marginTop: '1.5rem' }}>
              <Label id="channel-name-label" for="channel-name">
                <Icon
                  icon="poli-icon pi-channels-fill"
                  color="var(--green-polichat)"
                  size="24"
                />
                Nome do Canal
              </Label>

              <Input
                type="text"
                name="channel-name"
                id="channel-name"
                value={channelName}
                onChange={handleNameChange}
                style={{
                  borderColor: 'var(--borderDefault)',
                }}
              />
            </InputItem>
            {poliConfig.bugfix_PS_384 ||
            customer_id ===
              Number(poliConfig.feature_enable_customer_tester_id) ? (
              <>
                <InputItem style={{ marginTop: '1.0rem' }}>
                  <MultiSelectorContainer>
                    <Label
                      id="select-users-channel-label"
                      for="select-users-channel"
                    >
                      <Icon
                        icon="poli-icon pi-perfil-fill"
                        color={'var(--green-polichat)'}
                        size="24"
                      />
                      Usuários que poderão utilizar este canal
                    </Label>
                    <MultiSelector
                      name="select-users-channel"
                      id="select-users-channel"
                      options={usersOptions}
                      values={selectedUsers}
                      selectedValues={handleGetSelectorValues}
                    />
                  </MultiSelectorContainer>
                </InputItem>

                <InputItem>
                  <Label id="channel-website-label" for="channel-website">
                    <Icon
                      icon="poli-icon pi-computer-fill"
                      color={'var(--green-polichat)'}
                      size="24"
                    />
                    Website
                  </Label>
                  <InputGroup>
                    <Input
                      id="search"
                      type="url"
                      placeholder="https://example.com"
                      value={webchatInput}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          handleAddWebsite(webchatInput);
                        }
                      }}
                      onChange={(e) => setWebsiteInput(e.target.value)}
                    />
                    <InputGroupAddon addonType="append">
                      <Button
                        gradient="primary"
                        onClick={() => handleAddWebsite(webchatInput)}
                      >
                        <Icon
                          position="relative"
                          top="2px"
                          icon="poli-icon pi-add-circle-fill"
                          size={20}
                          color={'white'}
                        />
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                </InputItem>
              </>
            ) : (
              <>
                <InputItem>
                  <InputItem style={{ marginTop: '1.5rem' }}>
                    <Label id="channel-website-label" for="channel-website">
                      <Icon
                        icon="poli-icon pi-computer-fill"
                        color={'var(--green-polichat)'}
                        size="24"
                      />
                      Website
                    </Label>

                    <Input
                      type="text"
                      name="channel-website"
                      id="channel-website"
                      value={channelWebsite}
                      onChange={handleWebsiteChange}
                      style={{
                        borderColor: 'var(--borderDefault)',
                      }}
                    />
                  </InputItem>
                </InputItem>

                <InputItem style={{ marginTop: '1.0rem' }}>
                  <MultiSelectorContainer>
                    <Label
                      id="select-users-channel-label"
                      for="select-users-channel"
                    >
                      <Icon
                        icon="poli-icon pi-perfil-fill"
                        color={'var(--green-polichat)'}
                        size="24"
                      />
                      Usuários que poderão utilizar este canal
                    </Label>
                    <MultiSelector
                      name="select-users-channel"
                      id="select-users-channel"
                      options={usersOptions}
                      values={selectedUsers}
                      selectedValues={handleGetSelectorValues}
                    />
                  </MultiSelectorContainer>
                </InputItem>
              </>
            )}

            <InputItem style={{ marginTop: '1.0rem' }}>
              <Label id="channel-name-label" for="channel-name">
                <Icon
                  icon="poli-icon pi-channels-fill"
                  color="var(--green-polichat)"
                  size="24"
                />
                Status do Canal
              </Label>

              <SwitchContainer>
                <Switch
                  color="primary"
                  isChecked={activateChannel}
                  onClick={handleActivateChannelChange}
                />
                <p>{activateChannel ? 'Ativado' : 'Desativado'}</p>
              </SwitchContainer>
            </InputItem>
          </InputsContainer>
          {poliConfig.bugfix_PS_384 ||
          customer_id ===
            Number(poliConfig.feature_enable_customer_tester_id) ? (
            <div>
              <span style={{ marginTop: '1.5rem' }}>
                <strong>Websites adicionados: </strong>
              </span>
              <WebchatContainerValuesSelected>
                {typeof websites == 'object' ? (
                  websites?.map((website, index) => (
                    <WebchatItem key={index}>
                      <span className="url-webchat">{website}</span>
                      <span className="icon-webchat">
                        <Icon
                          icon="poli-icon pi-delete-line"
                          color="var(--danger)"
                          // onClick={() => handleRemoveEmail(email)}
                          onClick={() => onOpenRequest(website)}
                        />
                      </span>
                    </WebchatItem>
                  ))
                ) : (
                  <WebchatItem key={websites}>
                    <span className="url-webchat">{websites}</span>
                    <span className="icon-webchat">
                      <Icon
                        icon="poli-icon pi-delete-line"
                        color="var(--danger)"
                        // onClick={() => handleRemoveEmail(email)}
                        onClick={() => onOpenRequest(websites)}
                      />
                    </span>
                  </WebchatItem>
                )}
              </WebchatContainerValuesSelected>
            </div>
          ) : null}
          {dataFromDad?.id && (
            // Botao de copiar abaixo do script
            // <>
            //   <InputItem style={{ marginTop: '2.5rem' }}>
            //     <Label>
            //       <Icon
            //         icon="poli-icon pi-type-code-fill"
            //         color="#0a9dbf"
            //         size="24"
            //       />
            //       Para inserir o nosso webchat em seu site, copie e cole o
            //       script abaixo no seu código fonte.
            //     </Label>

            //     <textarea disabled className="form-control" rows="9">
            //       {textoScript}
            //     </textarea>
            //   </InputItem>

            //   <Button
            //     color="primary"
            //     onClick={() => {
            //       /* Copiar código */
            //       navigator.clipboard.writeText(textoScript).then(
            //         function () {
            //           /* Copiado com sucesso */
            //           toast.success('O código do Script foi copiado!');
            //           setSendButtonDisabled(false);
            //         },
            //         function () {
            //           /* Falha ao copiar */
            //           throw 'Erro ao copiar Script';
            //         }
            //       );
            //     }}
            //     style={{
            //       width: '10rem',
            //       justifyContent: 'space-between',
            //       alignItems: 'center',
            //       marginTop: '0.5rem',
            //     }}
            //   >
            //     <span
            //       style={{
            //         display: 'flex',
            //         gap: '0.1rem',
            //         alignItems: 'center',
            //       }}
            //     >
            //       <span> Copiar código </span>
            //       <Icon
            //         icon={'poli-icon pi-copy-fill'}
            //         size={30}
            //         color="#fff"
            //       />
            //     </span>
            //   </Button>

            //   <span
            //     style={{
            //           display: 'flex',
            //           gap: '0.1rem',
            //           alignItems: 'center',
            //         }}
            //       >
            //         Clique aqui e saiba mais sobre como inserir em seu Website
            //       </span>
            //     </Label>
            //   </InputsContainer>
            // </>

            //Botao de copiar ao lado do script
            <>
              <InputsContainer
                style={{
                  flex: '1',
                  flexDirection: 'row',
                }}
              >
                <InputItem
                  style={{
                    marginTop: '2.5rem',
                    width: '160%',
                    backgroundColor: 'var(--secondary-background-color)',
                    borderRadius: '10px',
                    padding: '10px',
                    display: 'flex',
                    flex: '1',
                  }}
                >
                  <Label>
                    <Icon
                      icon="poli-icon pi-type-code-fill"
                      color="#0a9dbf"
                      size="24"
                    />
                    <span
                      style={{
                        color: 'var(--secondary-font-color)',
                      }}
                    >
                      Para inserir o nosso webchat em seu site, copie e cole o
                      script abaixo no seu código fonte.
                    </span>
                  </Label>

                  <textarea
                    disabled
                    //readOnly
                    className="form-control"
                    rows="4"
                    style={{
                      color: 'var(--secondary-font-color)',
                      backgroundColor: '#fff',
                      opacity: 1,
                      borderRadius: '15px',
                    }}
                  >
                    {textoScript}
                  </textarea>
                </InputItem>

                <InputItem>
                  <Label
                    style={{
                      alignSelf: 'flex-end',
                      flex: '0',
                      marginTop: '80px',
                    }}
                  >
                    <Button
                      outline
                      size="md"
                      color="primary"
                      style={{
                        width: '14rem',
                        alignItems: 'center',
                      }}
                      onClick={() => {
                        /* Copiar código */
                        navigator.clipboard.writeText(textoScript).then(
                          function () {
                            /* Copiado com sucesso */
                            toast.success('O código do Script foi copiado!');
                            setSendButtonDisabled(false);
                          },
                          function () {
                            /* Falha ao copiar */
                            throw 'Erro ao copiar Script';
                          }
                        );
                      }}
                    >
                      <span
                        style={{
                          display: 'flex',
                          gap: '0.1rem',
                          alignItems: 'center',
                          marginLeft: '2.2rem',
                          color: '#4fcad2',
                        }}
                      >
                        <span>Copiar código </span>
                        <Icon
                          icon={'poli-icon pi-copy-fill'}
                          size={30}
                          color="#4fcad2"
                        />
                      </span>
                    </Button>
                  </Label>
                  <Label
                    style={{
                      width: '48%',
                      display: 'inline',
                      alignSelf: 'flex-end',
                      flex: '1',
                    }}
                  >
                    <span
                      style={{
                        display: 'flex',
                        textAlign: 'center',
                      }}
                    >
                      Clique aqui e saiba mais sobre como inserir em seu Website
                    </span>
                  </Label>
                </InputItem>
              </InputsContainer>
            </>
          )}
          <ActionGroup style={{ marginTop: '3.0rem' }}>
            <Button
              color="primary"
              size={isMobile ? 'md' : 'lg'}
              disabled={sendButtonDisabled}
              onClick={handleSendForm}
            >
              {dataFromDad ? 'Salvar' : 'Criar'} canal
            </Button>
            <Button
              color="danger"
              size={isMobile ? 'md' : 'lg'}
              style={{ width: '9rem' }}
              onClick={() => {
                history.push('/tools/channels');
              }}
            >
              Cancelar
            </Button>
          </ActionGroup>
        </Section>
      </Container>

      {poliConfig.bugfix_PS_384 ||
      customer_id === Number(poliConfig.feature_enable_customer_tester_id) ? (
        <Modal isOpen={isOpen} toggle={onCloseRequest}>
          <ModalBody>Deseja remover o e-mail: {selected}?</ModalBody>
          <ModalFooter>
            <Button outline color="primary" onClick={onCloseRequest}>
              Cancelar
            </Button>
            <Button
              color="danger"
              onClick={() => {
                handleRemoveWebsite(selected);
                onCloseRequest();
              }}
            >
              Excluir
            </Button>
          </ModalFooter>
        </Modal>
      ) : null}
    </>
  );
}
