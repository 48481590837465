import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { EnumMetricType } from '../../../../../../services/meta-ads/dto/dtoMetaAdsMetricData';
import { validationSchema } from './validation';
import { LocalFormValues } from './types';
import { useEffect } from 'react';

export function useLocalForm() {
  // ---------------------------------------------
  // States
  const form = useForm<LocalFormValues>({
    defaultValues: {
      type: EnumMetricType.AD,
    },
    resolver: yupResolver(validationSchema),
  });
  // ---------------------------------------------
  // Functions
  // ---------------------------------------------
  // Effects
  const watchedValueType = form.watch('type');
  // ---------------------------------------------
  // Transformations
  // ---------------------------------------------
  // API

  return { ...form, watchedValueType };
}
