import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Icon } from '@polichat/flamboyant';
import InfoCard from '../../channels/CertificationWabaByPoli/InfoCard/InfoCard.component';
import { EmbeddedSignupFlow } from '../../channels/CertificationWabaByPoli/MetaSDK/EmbeddedSignupFlow';
import {
  Container,
  FourColumnsGrid,
  CardsContainer,
  TextInfos,
} from '../../channels/CertificationWabaByPoli/steps/FillForm/FillForm.styles';
import { useFacebookSdkService } from '../hooks/useFacebookSdkService';

import { Customer } from '../../channels/CertificationWabaByPoli/steps/FillForm/FillForm.component';

export interface Step0Props {
  onSubmit: () => void;
  customer: Customer | undefined;
}

export interface Step0Ref {
  submit: () => void;
  setDisplayErrorTrue: () => void;
  setDisplayErrorFalse: () => void;
}
export const Step0 = forwardRef<Step0Ref, Step0Props>((props, ref) => {
  const facebookSDK = useFacebookSdkService();

  const [isDisplayError, setIsDisplayError] = useState(false);

  useImperativeHandle(ref, () => ({
    submit: () => {
      props.onSubmit();
    },
    setDisplayErrorTrue: () => setIsDisplayError(true),
    setDisplayErrorFalse: () => setIsDisplayError(false),
  }));

  // ---------------------------------------------
  // Transformations
  useEffect(() => {
    /**
     * No proceso de migração só estamos interessados em ter acesso à conta WABA
     * desta forma quando for solicitado o telefone, precisa fechar o popup.
     * Quando fecha o popup, na criação retorna erro da callback do FacebookSDK.
     * Como na portabilidade, este é um processo esperado, assim que desparado o erro,
     * o usuário será liberado para o próximo passo da migração
     */
    if (facebookSDK.hasError || facebookSDK.data) {
      props.onSubmit();
    }
  }, [facebookSDK.hasError, facebookSDK.data]);
  // ---------------------------------------------
  // Render

  return (
    <div>
      <Container style={{ margin: '0.5rem', padding: '0' }}>
        <TextInfos>
          <span>
            Agora, você entrará no ambiente do Facebook por um popup.
            <br />
            Estas são as etapas que você irá passar:
          </span>
        </TextInfos>

        {isDisplayError && (
          <div>
            <h2>Ops! Algo deu errado. Realize os passos abaixo movamente</h2>
          </div>
        )}

        <CardsContainer style={{ margin: '0.5rem 0' }}>
          <InfoCard>
            <>
              <span style={{ fontWeight: 'bold' }}>
                Desative o PIN em seu antigo provedor
              </span>
              <p>
                Para continuar o processo, tenha certeza que o{' '}
                <strong>PIN</strong> (Código de Verificação de Duas Etapas) do
                seu antigo fornecedor esteja desativado.
              </p>
            </>
          </InfoCard>
          <br />
          <FourColumnsGrid columns={'1fr 1fr 1fr 1fr'}>
            <InfoCard number="1">
              <>
                <div>
                  <span style={{ fontWeight: 'bold' }}>Login</span>
                </div>

                <span className="mt-3">
                  Ao clicar em <strong>Entrar com o Facebook</strong>, irá abrir
                  um popup. Nele, faça login ou se certifique de estar logado no
                  Facebook com sua conta correta.
                </span>
              </>
            </InfoCard>

            <InfoCard number="2">
              <>
                <div>
                  <span style={{ fontWeight: 'bold' }}>
                    Escolher conta Business
                  </span>
                </div>

                <span className="mt-3">
                  Em seguida, caso já possua uma conta no gerenciador de
                  negócios do Facebook, escolha a opção correta, caso contrário,
                  crie uma nova conta de negócio do Facebook. <br />{' '}
                  <strong>Obs.:</strong> É importante que esta conta seja a
                  mesma que você utiliza para anúncios do Facebook e no
                  Instagram Ads.
                </span>
              </>
            </InfoCard>

            <InfoCard number="3">
              <>
                <div>
                  <span style={{ fontWeight: 'bold' }}>
                    Conta de gestão WABA
                  </span>
                </div>

                <span className="mt-3">
                  A próxima etapa, será criar uma conta do WhatsApp Business
                  API.
                </span>
              </>
            </InfoCard>

            <InfoCard number="4">
              <>
                <div>
                  <span style={{ fontWeight: 'bold', display: 'inline-flex' }}>
                    Fechar o pop-up
                    <Icon icon={'poli-icon pi-alert-line'} size={24} />
                  </span>
                </div>

                <span className="mt-3">
                  <strong>
                    Não finalize os passos orientados pelo Facebook!{' '}
                  </strong>
                  <br />
                  Adicione o número de telefone que será utilizado e prossiga.{' '}
                  <br /> Irá retornar um erro informando que o número já está em
                  uso. Neste momento, feche o processo e retorne para a Poli.
                </span>
              </>
            </InfoCard>
          </FourColumnsGrid>
        </CardsContainer>

        {isDisplayError && (
          <p style={{ textAlign: 'center' }}>Tentar novamente</p>
        )}
        {facebookSDK.loading && (
          <div style={{ textAlign: 'center' }}>
            <span>Carregando...</span>
          </div>
        )}
        {/* Button Facebook SDK */}
        {facebookSDK.started && !facebookSDK.data && (
          <EmbeddedSignupFlow
            setErrorSdkFb={facebookSDK.handleError}
            onCallbackSDK={facebookSDK.handleData}
            customer={props.customer}
          />
        )}
      </Container>
    </div>
  );
});
