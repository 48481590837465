import React from 'react';
import { Badge } from '@polichat/flamboyant';

function DateChat(props) {
  return (
    <Badge style={styles.badge}>
      <span style={styles.text}>{props.title}</span>
    </Badge>
  );
}

const styles = {
  badge: {
    display: 'flex',
    height: '0.8em',
    minWidth: '7.5em',
    maxWidth: '7.5em',
    justifyContent: 'center',
    alignItems: 'center',
    border: 0,
    backgroundColor: 'var(--dateChat-background)',
    margin: '1em',
    padding: '15px 0',
    borderRadius: '4px',
  },
};

export default DateChat;
