import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { postContactsBalance } from 'src/services/contacts-day';

export function useCustomerContactCredit() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [error, setError] = useState(false);

  const wl_laravel_url = useSelector(
    // @ts-ignore
    (state) => state?.whitelabel?.config?.laravel_url
  );

  // ---------------------------------------------
  // Functions

  const refresh = useCallback(async () => {
    try {
      setLoading(true);
      const response = await postContactsBalance({}, wl_laravel_url);

      setData(response.data);
      if (error) setError(false);
    } catch (error: any) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  const update = useCallback((data: any) => {
    if (Object.keys(data).length) {
      setData(data);
    }
  }, []);

  // ---------------------------------------------
  // Effects

  useEffect(() => {
    refresh();
  }, []);

  // ---------------------------------------------
  // Transformations
  // ---------------------------------------------
  // API

  return {
    loading,
    error,
    data,
    refresh,
    update,
  };
}
