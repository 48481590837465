import {
  DtoMigrateChannelWabaToPoli,
  DtoPhoneCode,
  DtoRequestCode,
} from '../../../services/meta-business/meta/types';
import { Step1FormValues } from './step1/useStep1Form/types';
import { RequestCodeFormValues } from './StepValidateOTPAndRegisterChannel/useLocalRequestCodeForm/types';
import { VerifyCodeFormValues } from './StepValidateOTPAndRegisterChannel/useLocalVerifyCodeForm/types';

export function getDtoMetaMigrateFromStep1FormValues(
  customer_id: number,
  step1values: Step1FormValues
) {
  const dto: DtoMigrateChannelWabaToPoli = {
    customer_id,
    business_manager_id: parseInt(step1values.business_manager_id),
    channel_id: parseInt(step1values.channel_id),
  };

  return dto;
}

export function getDtoRequestCodeFromRequestCodeFormValues(
  step2values: RequestCodeFormValues
) {
  const dto: DtoRequestCode = {
    code_method: step2values.code_method,
    language: step2values.language,
  };

  return dto;
}
export function getDtoPhoneCodeMetaFromVerifyCodeFormValues(
  step3values: VerifyCodeFormValues
) {
  const dto: DtoPhoneCode = {
    code: step3values.confirmation_code,
  };

  return dto;
}
