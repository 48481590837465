import { all } from 'redux-saga/effects';

import whitelabel from './whitelabel/sagas';
import auth from './auth/sagas';
import general from './general/sagas';
import chat from './chat/sagas';
import visitors from './visitors/sagas';
import departments from './tools/departments/sagas';

import shoppingCategoriesList from './shopping/categories-list/sagas';
import shoppingCategoriesManage from './shopping/categories-manage/sagas';
import shoppingProductsList from './shopping/products-list/sagas';
import shoppingProductsManage from './shopping/products-manage/sagas';
import shoppingOrdersList from './shopping/orders-list/sagas';
import alertsSagas from './alerts/sagas';

export default function* rootSaga() {
  return yield all([
    whitelabel,
    auth,
    general,
    visitors,
    chat,
    shoppingCategoriesList,
    shoppingCategoriesManage,
    shoppingProductsList,
    shoppingProductsManage,
    shoppingOrdersList,
    departments,
    alertsSagas,
  ]);
}
