import styled from 'styled-components';

import { Modal } from '@polichat/flamboyant';
import { isMobile } from 'react-device-detect';

export const Container = styled.div`
  flex: 1;

  height: 85%;
  padding: 0.4rem;

  max-width: 350px;
  min-width: 300px;
  margin: 0 auto;

  h1 {
    font-size: 1.125rem;
    line-height: 2.25rem;
    word-break: break-word;
  }
`;

export const CustomModal = styled(Modal)`
  & .modal-content {
    padding: 0rem;
    border-radius: 20px !important;
  }

  .options {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
  }

  .click {
    cursor: pointer;
  }

  h2 {
    color: white;
    padding-left: 2rem;
  }

  h3 {
    padding: 1rem;
    color: #0a8f70;
    font-size: 25px;
  }

  h4 {
    padding-left: 1rem;
    color: #000000;
    font-size: 20px;
  }

  p {
    padding-left: 1rem;
  }

  header {
    padding: 0.5rem;
    padding-top: 0.7rem;
    background-color: #075e55;
    display: inline-flex;
    align-items: center;
  }
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  background: var(--mb-preview-background);
  border-radius: 1.2rem;
  height: 100%;
  width: 100%;

  header {
    padding: 0.5rem 0.8rem;
    background-color: #075e55;
    border-radius: 1.2rem 1.2rem 0 0;

    display: inline-flex;
    align-items: center;

    div {
      max-width: fit-content;
      padding: 0.5rem;
      background-color: var(--white);
      border-radius: 50%;

      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    span {
      padding-left: 1rem;
      color: var(--white);
      font-weight: bold;
      font-size: 1.5rem;
    }
  }
`;

export const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  background: var(--mb-preview-background);
  border-radius: 1.2rem;
  height: 100%;

  header {
    padding: 0.5rem 0.8rem;
    background-color: #075e55;
    border-radius: 1.2rem 1.2rem 0 0;

    display: inline-flex;
    align-items: center;

    div {
      width: 40px;
      height: 40px;
      background-color: var(--white);
      border-radius: 50%;

      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    span {
      padding-left: 1rem;
      color: var(--white);
      font-weight: bold;
      font-size: 1.5rem;
    }
  }

  .body {
    //height: -webkit-fill-available;
    overflow-y: auto;
  }

  footer {
    width: 100%;
    display: inline-flex;
    align-items: center;
    align-content: stretch;
    justify-content: space-between;
    gap: 0.5rem;
    padding: 0.4rem;
    ${isMobile && 'margin-bottom: 20px;'}

    div:first-child {
      width: 90%;
      height: 100%;
      background-color: var(--white);
      border-radius: 30rem;
      padding: 0 1rem;

      display: inline-flex;
      align-items: center;
      justify-content: flex-end;

      i:first-child {
        margin-right: auto;
      }
    }

    div + div {
      align-self: flex-end;

      background-color: #00887c;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      padding: 0.5rem;
      text-align: center;
    }
  }
`;

export const BallonContainer = styled.div`
  flex-grow: 3;

  margin: 1rem;
  margin-bottom: 0.4rem;
  padding: 0.5rem;
  padding-bottom: 0.3rem;
  background-color: var(--mb-preview-balloon);
  border-radius: 0.5rem;
  border-top-left-radius: 0;
  height: fit-content;

  display: flex;
  flex-direction: column;

  position: relative;

  span {
    font-size: 0.7rem;
    color: var(--textDefault);
    align-self: flex-end;
    margin-bottom: 0.1rem;

    &:not(.emoji-message) {
      opacity: 0.7;
    }
  }

  :before {
    content: '';
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 8px solid transparent;
    border-right: 8px solid var(--mb-preview-balloon);
    border-top: 8px solid var(--mb-preview-balloon);
    border-bottom: 8px solid transparent;
    left: -10px;
    top: 0px;
  }
`;

export const PreviewHeader = styled.div`
  h1 {
    font-size: 1rem;
    font-weight: bold;
    color: var(--textDefault);
    line-height: inherit;
  }

  img {
    width: 100%;
  }

  video {
    width: 100%;
  }
`;

export const PreviewBody = styled.p`
  font-size: 0.8rem;
  font-weight: normal;
  color: var(--textDefault);
  word-break: break-all;
  white-space: break-spaces;

  .emoji-message {
    position: relative;
    top: 6px;
  }
`;

export const PreviewFooter = styled.p`
  font-size: 0.8rem;
  font-weight: normal;
  color: var(--textDefault);
  opacity: 0.7;
  margin-bottom: 0.5rem;
  word-break: break-all;
`;

export const ButtonsContainer = styled.div`
  margin: 0.2rem 1rem;

  div {
    background: var(--mb-preview-button);
    border-radius: 0.4rem;
    padding: 0.2rem 0.5rem;
    margin-bottom: 0.2rem;
    height: 2.5rem;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.3rem;

    span {
      color: var(--info);
      opacity: inherit;
      font-weight: bold;
      font-size: 0.8rem;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    i {
      color: var(--info);
    }
  }
`;

export const PreviewButtonsReply = styled(ButtonsContainer)`
  display: flex;
  gap: 0.2rem;
  flex-wrap: wrap;

  div {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & div:nth-child(2) {
    min-width: 50%;
  }

  & div:nth-child(3) {
    min-width: 100%;
  }
`;

export const PreviewButtonsCall = styled(ButtonsContainer)`
  display: flex;
  flex-direction: column;
  div {
    cursor: pointer;
  }
`;
