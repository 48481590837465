import { actionNames } from './actions';

const defaultState = {
  config: {
    is_white_label: false,
  },
};

export default function wl(state = defaultState, action) {
  switch (action.type) {
    case actionNames.REQUEST_WL_CONFIG: {
      return { ...state, loading: true, error: null };
    }

    case actionNames.REQUEST_WL_CONFIG_SUCCESS: {
      return {
        ...state,
        config: action.payload,
        loading: false,
        error: null,
      };
    }

    case actionNames.REQUEST_WL_CONFIG_FAILURE: {
      const errorObject = Object.assign({}, action.payload, {
        message: action.payload.message,
        code: action.payload.response?.status ?? 0,
        stack: action.payload.stack,
        name: action.payload.name ?? 'General Error',
      });

      return {
        ...state,
        config: {
          is_white_label: false,
        },
        loading: false,
        error: errorObject,
      };
    }

    default:
      return state;
  }
}
