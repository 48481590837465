import { toast } from 'react-toastify';

import { actionNames } from './actions';

export default function visitors(state = {}, action) {
  switch (action.type) {
    /**
     * inicialização do serviço Visitor (loading)
     */
    case actionNames.START_VISITORS_SERVICE: {
      return {
        ...state,
        hasAsyncRequest: true,
      };
    }
    /**
     * falha ao inicializar serviço (error)
     */
    case actionNames.START_VISITORS_SERVICE_FAILURE: {
      return {
        ...state,
        hasAsyncRequest: false,
        error: true,
      };
    }
    /**
     * sucesso ao inicializar serviço
     */
    case actionNames.START_VISITORS_SERVICE_SUCCESS: {
      return {
        ...state,
        hasAsyncRequest: false,
        error: false,
        sessions: action.payload,
      };
    }
    /**
     * adiciona novo visitante a lista de sessões
     */
    case actionNames.NEW_VISITOR_WEBCHAT: {
      if (!state.sessions) {
        return state;
      }

      let updateListSessions = state.sessions;
      if (!updateListSessions.find((session) => session._id === action.payload._id) && action.payload?.status === 1) {
        updateListSessions.push(action.payload);
      }
      return {
        ...state,
        sessions: updateListSessions,
      };
    }
    /**
     * atualiza dados da sessão de um visitante
     */
    case actionNames.UPDATED_VISITOR_WEBCHAT: {
      if (!state.sessions) {
        return state;
      }

      let updateSessionsList = state.sessions;
      let index = updateSessionsList.findIndex(
        (sessionId) => sessionId._id == action.payload._id
      );

      // Update visitor
      if (index != -1) {
        // Atualizar se tiver ativo
        if (action.payload?.status === 1 && !action.payload?.contact_assign) {
          updateSessionsList[index] = action.payload;
        } 
        
        // Remover se tiver inativo
        else {
          updateSessionsList.splice(index, 1);
        }
      } 
      
      // New Visitor
      else {
        if (action.payload?.status === 1 && !action.payload?.contact_assign) {
          updateSessionsList.push(action.payload);
        }
      }

      return {
        ...state,
        sessions: updateSessionsList,
      };
    }
    /**
     * trás a lista de sessões a cada 30s
     */
    case actionNames.FETCH_SESSIONS_WEBCHAT: {
      if (!state.sessions) {
        return {
          ...state,
          hasAsyncRequest: false,
          error: false,
          sessions: action.payload,
        };
      }

      let newListSessions = [];
      action.payload.map((session) => {
        let oldSession = state.sessions.find((se) => se._id === session._id);
        if (oldSession?.user && !session.user) {
          session.user = oldSession.user;
        } else {
          session = { ...oldSession, ...session };
        }
        newListSessions.push(session);
      });

      return {
        ...state,
        hasAsyncRequest: false,
        error: false,
        sessions: newListSessions,
      };
    }
    /**
     * adiciona loading para sessão/visitante selecionado
     */
    case actionNames.STORE_FROM_VISITOR: {
      let newSessions = state.sessions.map((session) => {
        if (session._id === action.payload._id) {
          return {
            ...session,
            loadingStore: true,
            errorStore: false,
            readToOpen: false,
          };
        } else {
          return session;
        }
      });
      return {
        ...state,
        sessions: newSessions,
      };
    }
    /**
     * error ao criar contato para sessão/visitante
     */
    case actionNames.STORE_FROM_VISITOR_FAILURE: {
      let updateSessions = state.sessions.map((session) => {
        if (session._id === action.payload._id) {
          return {
            ...session,
            loadingStore: false,
            errorStore: true,
            readToOpen: false,
          };
        } else {
          return session;
        }
      });
      return { ...state, sessions: updateSessions };
    }
    /**
     * sucesso ao criar contato para sessão/visitante
     * adiciona a flag ***readToOpen*** para que o componente SessionsWechat
     * consiga abrir o chat desse contato que não estava na lista
     */
    case actionNames.STORE_FROM_VISITOR_SUCCESS: {
      let updateSessions = state.sessions.map((session) => {
        if (session._id === action.payload._id) {
          return {
            ...session,
            ...action.payload,
            loadingStore: false,
            errorStore: false,
            readToOpen: true,
          };
        } else {
          return session;
        }
      });
      return { ...state, sessions: updateSessions };
    }
    case actionNames.TRY_OPEN_VISITOR_CHAT_ERROR: {
      let updateSessions = state.sessions.map((session) => {
        if (session._id === action.payload._id) {
          return {
            ...session,
            loadingStore: false,
            errorStore: true,
            readToOpen: false,
          };
        } else {
          return session;
        }
      });
      return { ...state, sessions: updateSessions };
    }
    case actionNames.REMOVE_READ_TO_OPEN: {
      let updateListSessions = state.sessions.map((session) => {
        if (session._id === action.payload._id) {
          delete session.readToOpen;
          return session;
        } else {
          return session;
        }
      });

      return { ...state, sessions: updateListSessions };
    }

    default:
      return state;
  }
}
