import { useState } from "react";
import { CloseButtonMobile } from "./context";

export const MobileButtonProvider = ({children}: any) => {
  const [isClose, setIsClose] = useState<boolean | undefined>(true);
  return (
    <CloseButtonMobile.Provider value={{ isClose, setIsClose }}>
      {children}
    </CloseButtonMobile.Provider>
  );
}
