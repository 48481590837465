import { useEffect, useState } from 'react';
import { ButtonsMessageValuesType } from './types';
import { CreateBodyButtonsMessage } from './body';
import { CreateHeaderButtonsMessage } from './header';
import { CreateFooterButtonsMessage } from './footer';
import { CreateButtonButtonsMessage } from './button';

export interface FormButtonsMessageMessageProps {
  onUpdate(values: any): void;
  initialValue: ButtonsMessageValuesType;
  isEdit: boolean;
}

export function FormButtonsMessage(props: FormButtonsMessageMessageProps) {
  const { header, body, buttons, footer } = props.initialValue;

  const [messageButtons, setMessageButtons] = useState(buttons);
  const [messageHeader, setMessageHeader] = useState(header);
  const [messageBody, setMessageBody] = useState(body);
  const [messageFooter, setMessageFooter] = useState(footer);

  const updateHeaderData = (headerData: any) => {
    setMessageHeader(headerData);
  };
  const updateBodyData = (bodyData: any) => {
    setMessageBody(bodyData);
  };

  const updateFooterData = (footerData: any) => {
    setMessageFooter(footerData);
  };

  const updateButtonData = (buttonData: any) => {
    setMessageFooter(buttonData);
  };

  useEffect(() => {
    props.onUpdate({
      header: messageHeader,
      body: messageBody,
      footer: messageFooter,
      buttons: messageButtons,
    });
  }, [messageHeader, messageBody, messageFooter, messageButtons]);

  return (
    <>
      <CreateHeaderButtonsMessage
        onUpdate={updateHeaderData}
        initialValue={messageHeader}
      />
      <CreateBodyButtonsMessage
        onUpdate={updateBodyData}
        initialValue={messageBody}
        setBodyText={setMessageBody}
      />
      <CreateFooterButtonsMessage
        onUpdate={updateFooterData}
        initialValue={messageFooter}
        setFooterData={setMessageFooter}
      />
      <CreateButtonButtonsMessage
        onUpdate={updateButtonData}
        initialValue={messageButtons}
        setMessageButtons={setMessageButtons}
      />
    </>
  );
}
