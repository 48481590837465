import { useEffect, useRef, useState } from 'react';

import ReactHowler from 'react-howler';
import WaveSurfer from 'wavesurfer.js';

import { Icon, Button } from '@polichat/flamboyant';
import { WaveformContianer, Wave } from './styles';
import { useWhiteLabel } from 'src/hooks/useWhiteLabel';

const ListenAudio = ({ blob }) => {
  const waveRef = useRef(null);
  const howlerRef = useRef(null);

  const [speed, setSpeed] = useState(1);
  const [isPlaying, setIsPlaying] = useState(false);
  const whitelabel = useWhiteLabel();

  let progressColor = '#0000C8';
  let waveColor = '#009EFFbf';
  if (whitelabel.isWl) {
    if (whitelabel.cssPathWl.includes('auvo')) {
      progressColor = '#550E9B';
      waveColor = '#b499cf';
    }
    if (whitelabel.cssPathWl.includes('chatshub')) {
      progressColor = '#ff7038';
      waveColor = '#ffa26b';
    }
    if (whitelabel.cssPathWl.includes('nectarcrm')) {
      waveColor = '#ffe086';
      progressColor = '#fec10d';
    }
  }
    useEffect(() => {
      if (blob) {
        waveRef.current = WaveSurfer.create({
          barWidth: 3,
          barRadius: 3,
          barGap: 2,
          barMinHeight: 1,
          cursorWidth: 1,
          container: '#waveform',
          height: 60,
          progressColor: `${progressColor}`,
          responsive: true,
          waveColor: `${waveColor}`,
          cursorColor: 'transparent',
          hideScrollbar: true,
        });

        waveRef.current.load(URL.createObjectURL(blob));

        waveRef.current.on('finish', () => {
          setIsPlaying(false);
        });

        waveRef.current.on('seeking', () => {
          howlerRef.current.seek(waveRef.current.getCurrentTime());
        });

        return () => {
          waveRef.current.destroy();
          waveRef.current = null;
          howlerRef.current = null;
        };
      }
    }, [blob]);

  const handlePlay = () => {
    const currentPlayingState = isPlaying;

    waveRef.current.playPause();

    if (currentPlayingState) {
      howlerRef.current.pause();
    } else {
      howlerRef.current.play();
    }

    setIsPlaying((playing) => !playing);
  };

  const handleSpeed = () => {
    howlerRef.current.stop();
    waveRef.current.stop();
    setIsPlaying(false);

    switch (speed) {
      case 1:
        setSpeed(1.5);
        waveRef.current.setPlaybackRate(1.5);
        break;
      case 1.5:
        setSpeed(2);
        waveRef.current.setPlaybackRate(2);
        break;
      case 2:
        setSpeed(1);
        waveRef.current.setPlaybackRate(1);
        break;
      default:
        setSpeed(1);
        waveRef.current.setPlaybackRate(1);
        break;
    }
  };

  return (
    <>
      {blob && (
        <WaveformContianer>
          <Button
            color="link"
            onClick={handlePlay}
            className="buttonHandleAudio"
            size="sm"
          >
            {isPlaying ? (
              <Icon
                icon={'poli-icon pi-video-pause-fill'}
                size={40}
                color={'var(--icon-color-font)'}
              />
            ) : (
              <Icon
                icon={'poli-icon pi-video-play-fill'}
                color={'var(--icon-color-font)'}
                size={40}
              />
            )}
          </Button>
          <Wave id="waveform"></Wave>

          <Button color="primary" onClick={handleSpeed} size="sm">
            {speed}x
          </Button>

          <ReactHowler
            src={URL.createObjectURL(blob)}
            playing={false}
            rate={speed}
            format={['mp3']}
            ref={(ref) => (howlerRef.current = ref)}
          />
        </WaveformContianer>
      )}
    </>
  );
};

export default ListenAudio;
