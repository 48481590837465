import React from 'react';

function SidebarMainIcon(props) {
  return (
    <>
      <div className="mainLogo"></div>
    </>
  );
}

export default SidebarMainIcon;
