import { ActionTypes } from "./actions";

export default (state = {
  products: [],
  loadingGetProducts: false,
  lastError: null,
  productsStatus: null,
}, action) => {

  switch (action.type)
  {
    case ActionTypes.GET_PRODUCTS: {
      return {
        ...state,
        ...{
          loadingGetProducts: true
        }
      };
    }
    case ActionTypes.GET_PRODUCTS_SUCCESS: {
      return {
        ...state,
        ...{
          products: action.data,
          loadingGetProducts: false,
          productsStatus: true,
        }
      };
    }
    case ActionTypes.GET_PRODUCTS_FAILURE: {
      return {
        ...state,
        ...{
          error: action.error,
          loadingGetProducts: false,
          productsStatus: false,
        }
      };
    }
    default: {
      return state;
    }
  }

}
