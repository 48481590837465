import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Routers } from '../../../../routes';
import ChatLoading from '../../../../components/chat/content/chat/ChatLoading';
import axios from 'axios';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Icon,
} from '@polichat/flamboyant';

import { connect, useSelector } from 'react-redux';
import poliAuthTokenGet, { poliGetUserIDFromLocalStorage } from '../../../../utils/poliAuthTokenGet';
import poliConfig from '../../../../utils/poliConfig';
import { useFlags } from 'flagsmith/react';

function Body({ current_customer_id, userCustomers }) {
  const flags = useFlags(['can_view_crm']);
  const routes = Routers();
  const currentCustomerId = current_customer_id;

  const currentOrganization = userCustomers?.find(
    (customer) => customer.id === currentCustomerId
  );

  const [haveCurrentOrganization, setHaveCurrentOrganization] = useState(true);

  useEffect(() => {
    if (Array.isArray(userCustomers)) {
      setHaveCurrentOrganization(currentOrganization ? true : false)
    }
  }, [currentOrganization, userCustomers])

  const modalNotAccessOrganization = () => {
    const callSupport = () => {
      window.open('https://web.whatsapp.com/send?phone=556282361759');
    };
    const backToAdminPanel = () => {
      window.location.assign(`${poliConfig.poliAppLaravelUrl}`);
    };

    return (
      <div className="chatContainer">
        <div
          className="ChatLoadingError"
          style={{ display: 'none', width: '100%' }}
        >
          <ChatLoading />
        </div>

        <Modal
          isOpen={true}
          className={localStorage.getItem('@polichat/darkmode') === 'true' ? 'darkMode' : ''}
        >
          <ModalHeader>
            <Icon icon="poli-icon pi-alert-fill" color="#FFA26B" size={50} />
            <h3 right="true">Sem permissão!</h3>
          </ModalHeader>
          <ModalBody>
            <span>
              Você não possui acesso a esta organização! Entre em contato com o administrador do sistema e solicite acesso.
            </span>
          </ModalBody>
          <ModalFooter>
            <Button color="warning" size="lg" onClick={backToAdminPanel}>
              Voltar ao Painel
            </Button>
            <Button color="info" size="lg" onClick={callSupport}>
              Falar com Suporte
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }

  return (
    <Switch>
      {routes.map((route, index) => {
        // Redirecionamento para pagina inicial do Laravel
        if (route.path === '/') {
          route.content = () => {
            if (process.env.REACT_APP_USE_VALHALLA === 'true') {
              window.location.assign(
                `${
                  process.env?.REACT_APP_VALHALLA_URL
                }/home?access=${poliAuthTokenGet()}&user=${poliGetUserIDFromLocalStorage()}`
              );
            } else {
              window.location.assign(`${poliConfig.poliAppLaravelUrl}/home`);
            }
            return <ChatLoading />;
          };
        }

        // Redirecionamento para Relatórios do Laravel no reportsv2
        if (route.path === '/v2-report/reports-laravel') {
          route.content = () => {
            window.location.assign(
              `${poliConfig.poliAppLaravelUrl}/report/${currentCustomerId}`
            );
            return <ChatLoading />;
          };
        }

        // Redirecionamento para Relatórios do Laravel
        if (route.path === '/reports') {
          route.content = () => {
            window.location.assign(
              `${poliConfig.poliAppLaravelUrl}/report/${currentCustomerId}`
            );
            return <ChatLoading />;
          };
        }

        // Redirecionamento para Configurações do Laravel
        if (route.path === '/settings') {
          route.content = () => {
            window.location.assign(
              `${poliConfig.poliAppLaravelUrl}/settings/${currentCustomerId}`
            );
            return <ChatLoading />;
          };
        }

        if (
          route.path === '/automation'
        ) {
          route.content = () => {
            window.location.assign(
              `${poliConfig.poliAppLaravelUrl}/settings/${currentCustomerId}/chatbot`
            );
            return <ChatLoading />;
          };
        }

        if (route.path === '/crm') {
          route.content = () => {
            flags.can_view_crm?.value?.includes(current_customer_id) &&
              window.location.assign(
                `${poliConfig.poliAppLaravelUrl}/crm/index`
              );
            return <ChatLoading />;
          };
        }

        if (!haveCurrentOrganization) {
          return modalNotAccessOrganization();
        }

        return (
          <Route
            key={index}
            exact={route.exact}
            path={route.path}
            component={route.content}
          />
        );
      })}
      <Redirect path="*" to="/" />
    </Switch>
  );
}

const mapStateToProps = (state, ownProps) => ({
  props: ownProps,
  current_customer_id: state.general?.current_customer_id,
  userCustomers: state.chat?.config?.user?.customers,
});

function getUserToken(){
  axios.get(`${poliConfig.poliAppLaravelUrl}/admin/crm-auth`, {
    headers: {
      Authorization: `Bearer ${poliAuthTokenGet()}`,
    },
  }).then((response) => {
    return response.data.access_token;
  }).catch((error) => {
    console.log(error);
  });
}

export default connect(mapStateToProps)(Body);
