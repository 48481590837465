import ImageAdsMainNewPoli from '../../../../assets/images/newIdentity/ads_welcome.svg';

export function WelcomeFacebookAdsPage() {
  // ---------------------------------------------
  // Transformations
  // ---------------------------------------------
  // Render

  return (
    <div className="shoppingScreenImage AnimationImage07">
      <img
        className="img-fluid imagePay"
        src={ImageAdsMainNewPoli}
      />
      <h2 style={{ marginTop: '45px', lineHeight: '35px' }}>
        A Poli agora é parceira oficial da Meta, dona do Facebook. <br />
        E com isso conseguimos fornecer uma forma fácil e prática de criar,
        <br /> visualizar e gerenciar seus anúncios no Facebook e no Instagram!
      </h2>
    </div>
  );
}
