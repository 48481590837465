import React from 'react';
import history from '../../../../services/history';
import { Button } from '@polichat/flamboyant';

import PageMainHeader from '../../../common/pages/PageMainHeader/PageMainHeader.component';
import PageMainBody from '../../../common/pages/PageMainBody/PageMainBody.component';

import { Container } from './ChannelIndex.styles';
import { Illustration } from 'src/assets/illustratorElement/illustrators';

const ChannelIndex = () => {
  const goToChannelsList = () => {
    history.push({
      pathname: '/tools/channels/add',
    });
  };

  return (
    <>
      <PageMainHeader
        title="Canais de comunicação"
        icon="poli-icon pi-channels-line"
      ></PageMainHeader>
      <PageMainBody>
        <Container>
          <Illustration imgName="channelIndexImg" />
          <h2>Adicione canais de atendimento para seu time</h2>
          <p>
            Descubra uma variedade de canais de comunicação para ajudar sua
            equipe a fornecer um atendimento ao cliente excepcional.
          </p>
          <Button
            id="get-channels"
            gradient="primary"
            onClick={goToChannelsList}
          >
            Explore nossos canais
          </Button>
        </Container>
      </PageMainBody>
    </>
  );
};

export default ChannelIndex;
