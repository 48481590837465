function timeErrorBrowser(time) {
  //usando conversão base do js
  let date = new Date(time * 1000);
  let hours = date.getHours();
  let minutes = '0' + date.getMinutes();
  return hours + ':' + minutes.substr(-2);
}

/**
 * Retorna string no formato 24H, com hora e minutos com dois digitos cada.
 * Caso a locale zone esteja errada, retorna o horário convertido
 * com o timezone de São Paulo
 * @param {int} time is the UNIX time
 * @param {string} timeZone contains a property zone: https://momentjs.com/timezone/
 * @param {Object} format contains the desired formatting. Example: {hour: '2-digit', minute: '2-digit'}
 * @returns {string}
 */
export const dtmToFormatString = (time, timeZone, format) => {
  try {
    //time * 1000, o
    return new Date(time * 1000).toLocaleString('pt-BR', {
      timeZone: timeZone.zone,
      ...format,
    });
  } catch (e) {
    if (e.name == 'RangeError') {
      try {
        return new Date(time * 1000).toLocaleString('pt-BR', {
          timeZone: 'America/Sao_Paulo',
          ...format,
        });
      } catch (error) {
        return timeErrorBrowser(time);
      }
    } else {
      // em caso de problema de compatibilidade com browser e timeZone
      return timeErrorBrowser(time);
    }
  }
};
