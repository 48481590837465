import React from 'react';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';

function ChatStartContent() {
  const mobile = isMobile && useSelector((state) => state.chat.mobile);
  const chats = useSelector((state) => state.chat.chats);
  const someChatIsOpen = chats?.find((chat) => chat.open);

  if (!someChatIsOpen) {
    return (
      <div
        className={`startContent ${
          isMobile && (mobile?.blockTwo ? '' : 'hidden')
        }`}
      >
      </div>
    );
  } else {
    return <></>;
  }
}

export default ChatStartContent;
