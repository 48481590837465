import sanitizeHtml from 'sanitize-html';

const defaultSanitizeOptions = {
  allowedTags: [
    'b',
    'i',
    'em',
    'strong',
    'a',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'p',
    'br',
    'ul',
    'ol',
    'li',
    'blockquote',
    'pre',
    'code',
    'img',
  ],
  allowedAttributes: {
    a: ['href'],
    img: ['src', 'alt', 'title', 'height', 'width'],
  },
  allowedIframeHostnames: ['www.poli.digital', 'www.youtube.com'],
};

const sanitize = (
  dirty: string,
  options: sanitizeHtml.IOptions = defaultSanitizeOptions
) => ({
  __html: sanitizeHtml(dirty, options),
});

interface SanitizeHtmlProps {
  html: string;
  options?: sanitizeHtml.IOptions;
}

export const SanitizedHtml = ({ html, options }: SanitizeHtmlProps) => {
  return <div dangerouslySetInnerHTML={sanitize(html, options)} />;
};
