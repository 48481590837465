import { HeaderDataType } from '../../components/chat/message-types/WabaTemplate/header';

type ButtonType = {
  id: string;
  text: string;
  type: 'reply' | 'action';
  actionType: 'call' | 'link';
};

type Ad = {
  body: string;
  sourceId: string;
  sourceUrl: string;
  thumbnailUrl: string;
  title: string;
};

type WabaTemplateMessageData = {
  header: HeaderDataType;
  body: {
    text: string;
  };
  footer: string;
  buttons?: ButtonType[];
  ad?: Ad;
  button?: any
};

type TemplateInfo = {
  isFromMessageBuilder: boolean;
  hasHeader: boolean;
  hasFooter: boolean;
  hasButtons: boolean;
  isAd: boolean;
  parsedMessage?: WabaTemplateMessageData;
};

export function parseMessageTemplate(message: string): TemplateInfo {
  const isNotMessageFromTemplateBuilder: TemplateInfo = {
    isFromMessageBuilder: false,
    hasHeader: false,
    hasFooter: false,
    hasButtons: false,
    isAd: false,
  };

  function testTemplateBuilderJSON(json: WabaTemplateMessageData) {
    if (json.body) {
      return {
        isFromMessageBuilder: true,
        hasHeader: json.header !== undefined,
        hasFooter: json.footer !== undefined,
        hasButtons: json.buttons !== undefined,
        isAd: json.ad !== undefined,
        parsedMessage: json,
      };
    }

    return isNotMessageFromTemplateBuilder;
  }

  try {
    //removing string prefix attendant name
    const removestring = message.split('*\n\n{')[0];
    const escapedMessage = message.replace(removestring + '*\n\n', '');

    let json = JSON.parse(escapedMessage);

    return testTemplateBuilderJSON(json);
  } catch (e) {
    try {
      let json = JSON.parse(message);
      return testTemplateBuilderJSON(json);
    } catch (e) {
      return isNotMessageFromTemplateBuilder;
    }
  }
}
