import { ORDINATION_TYPES, OrdinationProps } from "src/pages/report/shared/types/Ordination"
import { EfficiencyRecordProps, EfficiencyResumeProps } from "../../types/OperatorsEfficiencyResponse";
import { TypeFiltersProps } from "src/pages/report/shared/types/Filters";

export interface TableProps {
  filters?: TypeFiltersProps;
  records?: EfficiencyRecordProps[];
  resume?: EfficiencyResumeProps;
  ordinationProps: OrdinationProps;
  onOrdinationChange: (event: any) => void;
  loading: boolean;
}

export interface OrderTable {
  id: string;
  title: string
  text?: string
}

export const DEFAULT_ORDINATION_CONFIG: OrdinationProps = {
  order_by: 'operator_name',
  order: ORDINATION_TYPES.ASCENDING
};

export const ORDERS_TABLE_TOOLTIP: OrderTable[] = [
  { id: 'operator_name', title: 'Nome do Operador' },
  { id: 'interactions', title: 'Interações', text: 'Número de vezes que o operador falou com os contatos no período filtrado. <br></br>Se um operador falar com o mesmo contato em 2 dias diferentes, serão contabilizados 2 interações.' },
  { id: 'clients', title: 'Clientes', text: 'Número de pessoas diferentes que cada operador interagiu. <br></br>Caso o operador fale com o mesmo contato 4 vezes, ele será considerado 1 vez por operador.' },
  { id: 'new_clients', title: 'Clientes Novos', text: 'Quantidade de clientes que houve uma troca de mensagem e que tiveram seus contatos criados no período filtrado.' },
  { id: 'average_first_reply_time', title: 'Tempo médio de 1ª resposta', text: 'A diferença do momento que a conversa foi atribuída a um atendente pelo bot ou por um encaminhamento até a primeira resposta do operador.' },
  { id: 'average_service_time', title: 'Tempo médio de atendimento', text: 'A diferença do momento que a conversa foi atribuída a um atendente pelo bot ou por um encaminhamento até o encerramento da conversa.' },
  { id: 'number_of_ratings', title: 'Quantidade de Avaliações', text: 'Quantidade de avaliações do tipo "Avaliação do Atendente" que o operador recebeu naquele período.' },
  { id: 'average_rating', title: 'Nota de Atendimento', text: 'A média das notas recebidas pelo operador no período de tempo filtrado.' },
];
