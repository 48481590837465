import { actionNames } from './actions';

export default function contactDay(state = {}, action) {
  switch (action.type) {
    case actionNames.GET_CONTACTS_DAY: {
      return {
        ...state,
        loading: true,
      };
    }
    case actionNames.GET_CONTACTS_DAY_ERROR: {
      const { httpStatusCode } = action.payload;
      return {
        ...state,
        loading: false,
        error: true,
        showErrorModal: [401, 403].includes(httpStatusCode)
      };
    }
    case actionNames.GET_CONTACTS_DAY_SUCCESS: {
      let contacts_day = Object.assign({}, action.payload);

      let detachedCredits = contacts_day.detachedCredits;
      let wabaChannels = contacts_day.wabaChannels;
      delete contacts_day.detachedCredits;
      delete contacts_day.wabaChannels;
      delete contacts_day.contact;

      let channels = Object.keys(contacts_day).map((key) => ({
        id: Number(key),
        showContactDayUsageWarning: state?.channels?.find(c => c.id == key)?.showContactDayUsageWarning ??
          true,
        ...contacts_day[key],
      }));

      wabaChannels.showContactDayUsageWarning =
        state?.waba?.showContactDayUsageWarning ?? true;

      return {
        ...state,
        loading: false,
        error: false,
        detachedCredits,
        channels,
        waba: wabaChannels
      };
    }

    case actionNames.UPDATE_CONTACTS_DAY: {
      let contacts_day = Object.assign({}, action.payload);

      let contact = contacts_day.contact;
      let detachedCredits = contacts_day.detachedCredits;
      let wabaChannels = contacts_day.wabaChannels;
      delete contacts_day.detachedCredits;
      delete contacts_day.wabaChannels;
      delete contacts_day.contact;

      let channels = Object.keys(contacts_day).map((key) => ({
        id: Number(key),
        showContactDayUsageWarning:
          state?.channels?.find(d => d.id == key)?.showContactDayUsageWarning ??
          true,
        ...contacts_day[key],
      }));

      wabaChannels.showContactDayUsageWarning =
        state?.waba?.showContactDayUsageWarning ?? true;

      let oldContacts = state?.contacts ?? [];
      let contacts = oldContacts?.filter(
        (item) =>
          !(
            item.channel_customer_id == contact.channel_customer_id &&
            item.contact_id == contact.contact_id
          )
      );
      contacts = [...contacts, contact];

      return {
        ...state,
        loading: false,
        error: false,
        detachedCredits,
        channels,
        waba: wabaChannels,
        contacts,
      };
    }

    case actionNames.CLOSE_CONTACT_USAGE_WARNING: {
      const isChannelWaba = state?.waba?.channels?.some(c => c.id == action.payload);
      return {
        ...state,
        channels: state?.channels?.map((channel) => {
          if (channel.id == action.payload) {
            return {
              ...channel,
              showContactDayUsageWarning: false,
            };
          }
          return channel;
        }),
        waba: {
          ...state.waba,
          showContactDayUsageWarning: !isChannelWaba,
        }
      };
    }

    default:
      return state;
  }
}
