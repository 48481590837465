import { Icon, Label, MultiSelector } from '@polichat/flamboyant';
import { useChannelCustomerService } from './useChannelCustomerService';
import { useEffect, useState } from 'react';
import {
  MultiSelectorOption,
  getMultiSelectorOptionArrayFromUserArray,
  getUserArrayFromSelectedOption,
} from './helper';

import './index.css';
export interface UsersAtChannelFieldProps {
  customer_id: number;
  value: number[];
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
}

export function UsersAtChannelField(props: UsersAtChannelFieldProps) {
  const [selectedItems, setSelectedItems] = useState<MultiSelectorOption[]>([]);

  const service = useChannelCustomerService({
    customer_id: props.customer_id,
  });
  // ---------------------------------------------
  // Transformations
  function handleChangeSelector(data: any) {
    props.onChange(getUserArrayFromSelectedOption(data) as any);
  }

  useEffect(() => {
    if (props.value && service.data.length) {
      const filterItems = props.value
        .map((item) => service.data.find((i: any) => i.id === item))
        .filter((item) => item);
      setSelectedItems(getMultiSelectorOptionArrayFromUserArray(filterItems));
    }
  }, [props.value, service.data]);
  // ---------------------------------------------
  // Render

  return (
    <div>
      <div className="inputLabelIcon">
        <Icon
          icon="poli-icon pi-perfil-fill"
          color={'var(--secondary)'}
          size="24"
        />
        <Label id="select-users-channel-label" for="select-users-channel">
          Usuários que poderão utilizar este canal
        </Label>
      </div>
      {service.loading && (
        <span className="loading">Carregando usuários...</span>
      )}
      {!service.loading && (
        <MultiSelector
          name="select-users-channel"
          id="select-users-channel"
          options={getMultiSelectorOptionArrayFromUserArray(service.data)}
          values={selectedItems}
          selectedValues={handleChangeSelector}
        />
      )}
    </div>
  );
}
