import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { DropdownToggle, DropdownMenu } from '@polichat/flamboyant';

import { MenuChildrenItem } from './MenuFilters/ChildrenItem';

import { GeneralDepartment } from '../../types/store';

import styles from './QuickFields.module.css';

export interface MenuDepartmentSelectorProps {
  id: string;
  value: string;
  onChange: (value: number | null) => void;
  role: string;
}
export function MenuDepartmentSelector(
  props: Readonly<MenuDepartmentSelectorProps>
) {
  const userDepartments = useSelector((state: any) => {
    if (props.role === 'agent') {
      return state?.chat?.config?.user?.departments;
    }
    return state?.chat?.config?.general?.departments;
  }) as GeneralDepartment[];

  const handleValueChange = useCallback(
    /**
     * transforma a string do input em number ou null
     * que é o tipo esperado pelo formulário
     * @param event
     */
    (value: number | null) => {
      props.onChange(value ?? null);
    },
    [props.onChange]
  );

  return (
    <div id="quick_department_dropdown">
      <DropdownToggle caret nav>
        Filtrar por departamento
      </DropdownToggle>
      <DropdownMenu className={styles.drop_container}>
        <div className={styles.menu_container}>
          <MenuChildrenItem
            key={`dp-all`}
            active={'' === props.value}
            handleClick={() => handleValueChange(null)}
            text="Todos os departamentos"
          />

          {userDepartments.length > 0 &&
            userDepartments.map((department) => (
              <MenuChildrenItem
                key={`dp-${department.id}`}
                text={department.name}
                handleClick={() => handleValueChange(department.id)}
                active={department.id === Number(props.value)}
              />
            ))}
        </div>
      </DropdownMenu>
    </div>
  );
}
