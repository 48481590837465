import React from 'react';
import darkLoading from '../../../../assets/images/loading-dark.gif'
import lightLoading from '../../../../assets/images/loading-light.gif'
import { LoaderMain } from 'src/components/common/animation/loader';

function ChatLoading() {
  const hasDarkMode = document.getElementsByClassName('darkMode').length > 0 ? true : false;
  if (hasDarkMode) {
    return (
      <div style={styles.container} className="chatLoadingLoader">
        <LoaderMain size={4} />
      </div>
    );
  } else {
    return (
      <div style={styles.container} className="chatLoadingLoader">
        <LoaderMain size={4} />
      </div>
    );
  }
}

const styles = {
  container: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'var(--secondary-background-color)',
    color: 'var(--primary-font-color)',
  },
};

export default ChatLoading;
