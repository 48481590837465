import React from 'react';
import { timeHasPassed } from '../../../store/modules/chat/functions';

function LastMessageTime(props) {
  const time = props.dtm;
  let classTime =
    !props.isChatRead &&
    (props.countMessages !== 0 || props.newMessagesCounter !== 0)
      ? 'timeLastMessage'
      : '';
    
  return <span className={`${classTime}`}>{timeHasPassed(time)}</span>;
}

// const styles = {
//   lastMessageTime: {
//     fontSize: '10px',
//     lineHeight: '12px',
//     textAlign: 'right',
//     color: 'var(--tertiary-font-color)',
//     marginRight: '0.3em',
//     textOverflow: 'ellipsis',
//     overflow: 'hidden',
//     whiteSpace: 'nowrap',
//     maxWidth: '60px',
//     display: 'block',
//   },
// };
export default LastMessageTime;
