import React from 'react';

import WhatsAppLogo from '../../../../assets/images/whatsapp.svg';
import { useHistory } from 'react-router-dom';
import { Button, Icon } from '@polichat/flamboyant';

import {
  Container,
  IconLabelContainer,
  HorizontalRule,
} from './ChannelDescriptionBanner.styles';

export default function ChannelDescriptionBanner() {
  const history = useHistory();
  return (
    <>
      <Container>
        <IconLabelContainer>
          <img src={WhatsAppLogo} alt="Homologação do WhatsApp Oficial" />
          <b>WhatsApp</b>
        </IconLabelContainer>

        <div>
          Esse é o aplicativo utilizado no dia-a-dia. Qualquer pessoa pode
          baixar e conversar livremente com os amigos, colegas e familiares.
          Utilizamos nossa API própria para conectar múltiplos agentes nesse
          tipo de conta de WhatsApp.
        </div>

        {/* Não está sendo utilizado por enquanto, mas vou deixar aqui caso queiramos reutilizar*/}
        {/* <Button
          color="primary"
          onClick={() => history.push('/tools/channels/add')}
          style={{
            width: '30rem',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <span
            style={{
              display: 'flex',
              gap: '0.1rem',
              alignItems: 'center',
            }}
          >
            <Icon icon={'poli-icon pi-add-line'} size={20} />
            <span> Adicionar Canal</span>
          </span>
          <Icon icon={'poli-icon pi-simple-arrow-down-line'} size={20} />
        </Button> */}
      </Container>
      <HorizontalRule />
    </>
  );
}
