import { ReactNode } from 'react';

import {
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
} from '@polichat/flamboyant';

import { Options } from '@poli-ink/react';

import styles from './QuickItem.module.css';
import dropStyles from '../Fields/QuickFields.module.css';

interface QuickRootProps {
  children: ReactNode;
}
export function QuickControls({ children }: Readonly<QuickRootProps>) {
  return (
    <div className={styles.control}>
      <UncontrolledDropdown direction="left">
        <DropdownToggle outline color="primary">
          <Options />
        </DropdownToggle>
        <DropdownMenu container="body" className={dropStyles.drop_container}>{children}</DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
}
