import { DropdownItem } from '@polichat/flamboyant';
import { QuickMessage } from '../../types/types';
export interface QuickControlPreviewProps {
  handleView: (message: QuickMessage) => void;
}
export function QuickControlPreview({
  handleView,
}: Readonly<QuickControlPreviewProps>) {
  return <DropdownItem onClick={handleView}>Visualizar</DropdownItem>;
}
