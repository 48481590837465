import styled from "styled-components";

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.85rem;
  align-items: center;

  @media (max-width: 600px) {
    border-right: none;
  }
`;
