import React, { useState, useEffect } from 'react';
import { Icon, Modal, ModalHeader, ModalBody } from '@polichat/flamboyant';
import Animation from '../animation';

import { useFlags } from 'flagsmith/react';
import { customUseFlags } from '../../../hooks/customUseFlags';

function Lightbox(props) {
  const flag = useFlags(['image_scaling']);
  const [show, setShow] = useState(false);
  const [box, setBox] = useState(null);
  const [authorContent] = useState(props.authorContent);
  const [src, setSrc] = useState(props.src);
  const imageScaling = customUseFlags(flag.image_scaling);
  const [type] = useState(
    /image|jpg|png|jpeg|gif|bmp/gi.test(props.type)
      ? 'image'
      : /video|avi|mp4|mpeg|webm|mov|rmvb|mkv/gi.test(props.type)
      ? 'video'
      : ''
  );

  const images = document.querySelectorAll('.chat-image');
  
  useEffect(() => {
    if (imageScaling) {
      images.forEach((img) => {
        img.onload = () => {
          const width = img.naturalWidth;
          const height = img.naturalHeight;

          if (width === height) {
            img.classList.add('img-square');
          } else if (width > height) {
            img.classList.add('img-horizontal');
          } else {
            img.classList.add('img-vertical');
          }
        };
      });
    }
  }, [images, imageScaling]);

  const contentError = (type) => {
    if (props.detail_message_type == 'instagram_story') {
      return `
        <div>
          <p class="message-info-baloon">Este Story não está mais disponível</p>
        </div>`;
    }

    return (
      `
    <div>
      <div className="mx-auto text-center mb-1" style="border-radius: 50%; background: white; width: fit-content; margin-top: 5px; margin-left: 5px;">
        <i className="poli-icon pi-poly-crazy-fill	" style="color: var(--activated-icons-color); font-size: 45px;"></i>
      </div>
      <p class="message-info-baloon">Ops, algo deu errado, ` +
      type +
      `</p>
    </div>`
    );
  };

  const toggle = () => setShow(!show);
  useEffect(() => {
    if (show) {
      if (type !== 'image' && type !== 'video') {
        window.open(src);
        return;
      }
      setBox(
        <Modal isOpen={show} toggle={toggle} className={'modalLightBox'}>
          <ModalHeader toggle={toggle}>
            {authorContent}
            <div className="btnActionLightBox">
              <span
                onClick={() => {
                  setShow(false);
                  window.open(src);
                }}
                style={{ cursor: 'pointer', marginRight: '10px' }}
              >
                <Icon
                  icon="poli-icon pi-download-line"
                  size={24}
                  color="#fff"
                />
              </span>
              <span
                onClick={() => {
                  setShow(false);
                }}
              >
                <Icon icon="poli-icon pi-close-line" size={24} color="#fff" />
              </span>
            </div>
          </ModalHeader>
          <ModalBody>
            {type === 'image' ? (
              <img
                alt=""
                src={src}
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  margin: 0,
                  padding: 0,
                  borderRadius: 0,
                }}
              />
            ) : type === 'video' ? (
              <div
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  margin: 0,
                  padding: 0,
                  borderRadius: 0,
                }}
              >
                <video controls>
                  <source src={src} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            ) : (
              ''
            )}
          </ModalBody>
        </Modal>
      );
    } else {
      setBox(null);
    }
  }, [show, authorContent, src, type]);

  let newProps = {
    align: props.align || null,
    height: props.height || null,
    width: props.width || null,
    border: props.border || null,
    name: props.name || null,
  };

  return (
    <>
      {box}
      {type === 'image' ? (
        <>
          {props?.isLoading ? (
            <Animation icon="loading" size={'tinyRem'} />
          ) : (
            <img
              className="img-fluid chat-image"
              id={props.id || ''}
                style={{
                cursor: 'pointer',
                ...(props.style || {}),
              }}
              {...newProps}
              onClick={() => {
                setSrc(props.src);
                setShow(true);
              }}
              src={props.src || props.thumb}
              width={props.width || '150'}
              alt={props.alt || 'media'}
              onError={(e) => {
                e.target.onerror = null;

                let errorDiv = document.getElementById(
                  `media-${props.messageId}`
                );
                errorDiv.innerHTML = contentError('com sua imagem.');
              }}
            />
          )}
        </>
      ) : type === 'video' ? (
        <div
          {...newProps}
          onClick={() => {
            setShow(true);
          }}
          style={{
            cursor: 'pointer',
          }}
        >
          {props?.isLoading ? (
            <Animation icon="loading" size={'tinyRem'} />
          ) : (
            <video
              style={{
                maxWidth: '100%',
                minWidth: 'auto',
                maxHeight: '100%',
                minHeight: 'auto',
                margin: 0,
                width: '100%',
                height: '100%',
              }}
              onError={(e) => {
                e.target.onerror = null;

                let errorDiv = document.getElementById(
                  `media-${props.messageId}`
                );
                errorDiv.innerHTML = contentError('com seu video');
              }}
            >
              <source src={src} type="video/mp4" />
            </video>
          )}
        </div>
      ) : (
        <a href={src} target="_blank" rel="noopener noreferrer">
          {src}
        </a>
      )}
    </>
  );
}
export default Lightbox;
