import React, { useCallback, useEffect, useState } from 'react';
import Animation from '../../../../../common/animation';
import { priceMask } from '../../../../../../utils/priceMask';
import {
  formatDate,
  formatDateAbbreviation,
} from '../../../../../../utils/formatDate';

import ModalConfirmation from '../ModalConfirmation/index.component';

import {
  CustomModal,
  CustomModalClose,
  CustomModalHeader,
  CustomModalBody,
  CustomModalStatus,
  LabelForm,
  StatusView,
  CustomModalTab,
  Tabs,
  TabItem,
  TabsContainer,
} from './CreditModalView.styles';

import poliConfig from '../../../../../../utils/poliConfig';

// @ts-ignore
import { Button, Icon } from '@polichat/flamboyant';

/**
 * Requests
 */
import {
  getListPaymentContactDay,
  getListServicesDiscount,
  postApplyDiscount,
  postLiberateToUseContactDay,
  postRemoveCredit
} from '../../../../../../services/credits/request';
import { Input } from 'reactstrap';

/* */

/**
 * Types
 */
interface ToggleModalViewCredit {
  (id: number): void;
}

type Payment = {
  id: number;
  customer_email: string;
  customer_name: string;
  due_date: string;
  date_ref: string;
  invoice_id_sl: number;
  link_invoice: string;
  status_payment?: number;
  value_payment: number;
  value: number;
};

type Discount = {
  service_id: number;
  amount: number;
};

type SelectDiscount = {
  service_id: number;
  quantity: number;
  amount: number;
};

interface FooProp {
  toggleModalViewCredit: ToggleModalViewCredit;
  isOpenModalView: boolean;
  customerCredSelected: {
    id?: number;
    type?: string;
    isRecurrent?: boolean;
    hasDiscount?: boolean;
  };
  screenAdmin: boolean;
  isUserFinance: boolean | undefined;
  updateDatalist: Function;
  updateValue: Function;
  setHasDiscount: Function;
}
/* end - types */

const CreditModalView = (props: FooProp) => {

  /**
   * Props
   */
  const {
    toggleModalViewCredit,
    isOpenModalView,
    customerCredSelected,
    screenAdmin,
    isUserFinance,
    updateDatalist,
    updateValue,
    setHasDiscount,
  } = props;

  /* end */

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDiscount, setIsLoadingDiscount] = useState(false);
  const [paymentsData, setPaymentsData] = useState<Payment[]>();

  const [discountData, setDiscountData] = useState<Discount[] | null>();
  const [successDiscount, setSuccessDiscount] = useState<Boolean>(false);
  const [listDiscounts, setListDiscounts] = useState<Boolean>(false);
  const [errorDiscount, setErrorDiscount] = useState<String>('');
  const [selectDiscountList, setSelectDiscountList] =
    useState<SelectDiscount[]>();

  const [hasDiscountApplied, setHasDiscountApplied] = useState<Boolean>(false);
  const [paymentSelected, setPaymentSelected] = useState<Payment>();

  if (!isOpenModalView && (successDiscount || errorDiscount || listDiscounts)) {
    setSuccessDiscount(false);
    setListDiscounts(false);
    setSelectDiscountList([]);
    setErrorDiscount('');
  }

  /**
   * Get Payments Data
   */
  const getPaymentsData = async () => {
    try {
      setIsLoading(true);
      const result = await getListPaymentContactDay(customerCredSelected?.id);
      if (result?.data.success && Array.isArray(result?.data?.data)) {
        const _data = result?.data?.data ?? [];
        setPaymentsData(_data);

        // O primeiro Pre-selecioado
        if (_data.length > 0) {
          setPaymentSelected(_data[0]);
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.error(
        'Error on Services Credit: getListPaymentContactDay',
        error
      );
      setIsLoading(false);
    }
  };
  /* End - Get Payments Data */

  /**
   * Get Discount Data
   */
  const getDiscountData = async () => {
    try {
      setIsLoadingDiscount(true);
      const result = await getListServicesDiscount();
      if (result.status == 200 && Array.isArray(result?.data)) {
        let data: Discount[] = [];
        result.data.forEach((discount: any) => {
          data.push({
            service_id: discount.id,
            amount: discount.amount,
          });
        });
        setDiscountData(data);
      } else {
        setDiscountData([]);
      }
      setIsLoadingDiscount(false);
    } catch (error) {
      setDiscountData([]);
      console.error('Error on Services Discount: getListDiscountData', error);
      setIsLoadingDiscount(false);
    }
  };
  /* End - Get Discount Data */

  /**
   * Handle Change Discount
   */

  const handleChangeDiscount = (event: any) => {
    let discountData: SelectDiscount = {
      service_id: parseInt(event.target.attributes.serviceId.value),
      quantity: parseInt(event.target.value),
      amount: parseFloat(event.target.attributes.amount.value),
    };

    let aux: SelectDiscount[] = [];
    if (selectDiscountList?.length) {
      let exist = false;
      selectDiscountList.forEach((selectDiscount) => {
        if (discountData.service_id == selectDiscount.service_id) {
          exist = true;
          if (discountData.quantity > 0) {
            aux.push(discountData);
          }
        } else {
          aux.push(selectDiscount);
        }
      });
      if (!exist && discountData.quantity > 0) {
        aux.push(discountData);
      }
    } else if (discountData.quantity > 0) {
      aux.push(discountData);
    }

    setSelectDiscountList(aux);
  };

  /* End - Get Handle Change Discount */

  const handleSubmit = async () => {
    setIsLoadingDiscount(true);
    try {
      if (selectDiscountList?.length) {
        let totalDiscount = 0;
        selectDiscountList.forEach((discount) => {
          totalDiscount = totalDiscount - discount.amount * discount.quantity;
        });

        if (
          !paymentSelected?.value ||
          totalDiscount >= paymentSelected?.value
        ) {
          setErrorDiscount('Desconto não pode ser maior que o valor pago!');
        } else {
          const result = await postApplyDiscount(
            paymentSelected?.id,
            selectDiscountList
          );
          if (!result.data.success) {
            setErrorDiscount(result.data.msg);
          } else {
            let paymentsSelect = paymentSelected;
            paymentsSelect.value -= totalDiscount;
            setPaymentSelected(paymentsSelect);
            updateValue(paymentSelected.value);

            setHasDiscount(true);
            setHasDiscountApplied(true);

            setSuccessDiscount(true);
            setDiscountData(null);
            setErrorDiscount('');
          }
        }
      }
    } catch (ex) {
      setErrorDiscount('Erro ao tentar aplicar descontos');
    }
    setIsLoadingDiscount(false);
  };

  /* End - Handle Submit */

  /**
   * Mounted
   */
  useEffect(() => {
    if (customerCredSelected?.id) getPaymentsData();

    setHasDiscountApplied(customerCredSelected.hasDiscount || false);
  }, [customerCredSelected]);
  /* End */

  /**
   * Abrir Boleto
   */
  const openLink = (link: string) => {
    window.open(link, 'blank');
  };
  /* */

  /**
   * Status Payment
   */
  const getStatusLabel = (statusId?: number) => {
    switch (statusId) {
      case 1:
        return 'Pagamento Atrasado';
      case 2:
        return 'Pagamento pendente';
      case 3:
        return 'Pagamento aprovado';
      case 4:
        return 'Recorrência pendente';
      case 5:
        return 'Liberação Manual';
      case 6:
        return 'Crédito Removido';
      default:
        return '';
    }
  };

  const getStatusLabelClass = (statusId?: number) => {
    switch (statusId) {
      case 1:
        return 'text-danger';
      case 2:
        return 'text-warning';
      case 3:
        return 'text-success';
      case 4:
        return 'text-attention';
      case 5:
        return 'text-primary';
      case 6:
        return 'text-danger';
      default:
        return '';
    }
  };
  /* end - status Payment */

  /**
   * Liberar Pagamento Manualmente
   */
  const handleLiberateCredit = async () => {
    try {
      const params = {
        customer_cred_id: customerCredSelected?.id ?? 0,
        invoice_id_sl: paymentSelected?.invoice_id_sl ?? 0,
      };
      const result = await postLiberateToUseContactDay(params);
      if (result?.data.success) {
        /**
         * Colocando como pago no pagamento Selecionado
         */
        if (paymentSelected?.id && Array.isArray(paymentsData)) {
          const _paymentSelected = { ...paymentSelected, status_payment: 5 };
          const _paymentsData = [...paymentsData];
          setPaymentSelected(_paymentSelected);

          // Atualizar o array de pagamentos
          const index = paymentsData?.findIndex(
            (e) => e.id === _paymentSelected.id
          );
          if (index >= 0) {
            _paymentsData[index] = _paymentSelected;
            setPaymentsData(_paymentsData);
            updateDatalist();
          }
        }
        /* */
      }
    } catch (error) {
      console.error(
        'Error on Services Credit: postLiberateToUseContactDay',
        error
      );
    }
  };
  /* end - Liberar Pagamento Manualmente */

  /**
   * Modal de confirmação de liberação de Pagamento Manualmente
   */
  const [openConfirmedLiberateCredit, setOpenConfirmedLiberateCredit] =
    useState<boolean>();

  const dataModalConfirmedLiberateCredit = {
    title: 'Deseja liberar este crédito?',
    description:
      'Se liberar este crédito, o mesmo constara como "Pagamento aprovado" e o processo não podera ser desfeito.',
    icon: 'poli-icon pi-alert-fill',
    colorClass: 'text-warning',
  };

  const onConfirmedModal = (confirmed: boolean) => {
    setOpenConfirmedLiberateCredit(false);
    if (confirmed) {
      handleLiberateCredit();
    }
  };
  /* end */

  /**
   * Remoção de Creditos 
   */
  const handleRemoveCredit = async () => {
    try {
      const params = {
        payment_id: paymentSelected?.id ?? 0,
      };
      const result = await postRemoveCredit(params);
      if (result?.data.success) {
        /**
         * Colocando como pago no pagamento Selecionado
         */
        if (paymentSelected?.id && Array.isArray(paymentsData)) {
          const _paymentSelected = { ...paymentSelected, status_payment: 6 };
          const _paymentsData = [...paymentsData];
          setPaymentSelected(_paymentSelected);

          // Atualizar o array de pagamentos
          const index = paymentsData?.findIndex(
            (e) => e.id === _paymentSelected.id
          );
          if (index >= 0) {
            _paymentsData[index] = _paymentSelected;
            setPaymentsData(_paymentsData);
            updateDatalist();
          }
        }
        /* */
      }
    } catch (error) {
      console.error(
        'Error on Services Credit: postRemoveCredit',
        error
      );
    }
  };
  /* end - Remoção de Creditos */

  /**
   * Modal de confirmação de remoção de Creditos
   */
  const [openConfirmedRemoveCredit, setOpenConfirmedRemoveCredit] =
    useState<boolean>();

  const dataModalConfirmedRemomveCredit = {
    title: 'Deseja remover este crédito?',
    description:
      'Se remover este crédito, o mesmo constara como "Crédito Removido" e o processo não podera ser desfeito.',
    icon: 'poli-icon pi-alert-fill',
    colorClass: 'text-warning',
  };

  const onConfirmedRemoveCreditModal = (confirmed: boolean) => {
    setOpenConfirmedRemoveCredit(false);
    if (confirmed) {
      handleRemoveCredit();
    }
  };
  /* end */

  /**
   * Ver mais tags item
   * - Função responsavel por fazer a rolagem do scroll de mostrar mais tags (mes jan/22 ...)
   */
  const seeMoreTagsScroll = useCallback((right?: boolean) => {
    const _ele = document?.getElementById('tag-select-payment');
    if (_ele) {
      if (right) {
        _ele.scrollLeft += 50;
      } else {
        _ele.scrollLeft -= 50;
      }
    }
  }, []);

  const [showBtnMoreTags, setShowBtnMoreTags] = useState<boolean>();
  useEffect(() => {
    if (!isLoading) {
      const _ele = document?.getElementById('tag-select-payment');
      if (_ele) {
        if (_ele.scrollWidth > _ele.clientWidth) {
          setShowBtnMoreTags(true);
        } else {
          setShowBtnMoreTags(false);
        }
      }
    }
  }, [isLoading]);
  /* end - Ver mais tags item  */

  let viewDiscount = null;
  if (successDiscount) {
    viewDiscount = (
      <div>
        <hr></hr>
        <StatusView>
          <LabelForm>Escolha o desconto:</LabelForm>
        </StatusView>
        <StatusView>
          <h5 className="text-success">Desconto aplicado com sucesso!</h5>
        </StatusView>
      </div>
    );
  } else if (errorDiscount) {
    viewDiscount = (
      <div>
        <hr></hr>
        <StatusView>
          <LabelForm>Escolha o desconto:</LabelForm>
        </StatusView>
        <StatusView>
          <h5 className="text-danger">{errorDiscount}</h5>
          <Button
            color="danger"
            outline
            onClick={() => {
              setErrorDiscount('');
              setSelectDiscountList([]);
            }}
          >
            <span>
              Tentar novamente!
              <Icon
                icon={'poli-icon pi-update-fill'}
                color={'danger'}
                position={'absolute'}
                size={24}
                right={1}
              />
            </span>
          </Button>
        </StatusView>
      </div>
    );
  } else if (isLoadingDiscount) {
    viewDiscount = (
      <div>
        <hr></hr>
        <StatusView>
          <LabelForm>Escolha o desconto:</LabelForm>
        </StatusView>
        <StatusView>
          <Animation
            icon="chat-loading"
            top={'50%'}
          />
        </StatusView>
      </div>
    );
  } else if (Array.isArray(discountData)) {
    if (!discountData.length) {
      viewDiscount = (
        <div>
          <hr></hr>
          <StatusView>
            <LabelForm>Escolha o desconto:</LabelForm>
          </StatusView>
          <StatusView>
            <h5 className="text-danger">Nenhum desconto encontrado!</h5>
          </StatusView>
        </div>
      );
    } else if (
      poliConfig.displayDiscountButton &&
      !hasDiscountApplied &&
      paymentSelected?.status_payment != 3 &&
      listDiscounts
    ) {
      let listDiscounts = <></>;
      discountData.forEach((discount) => {
        listDiscounts = (
          <>
            {listDiscounts}
            <div className="discount">
              {/* <Button
            color="primary"
            outline
          > */}
              <span>
                <h6>R$ {discount.amount}</h6>

                <Input
                  style={{
                    maxWidth: '75px',
                  }}
                  type="number"
                  min={0}
                  onChange={handleChangeDiscount}
                  serviceId={discount.service_id}
                  amount={discount.amount}
                />
              </span>
              {/* </Button> */}
            </div>
          </>
        );
      });
      viewDiscount = (
        <div>
          <hr></hr>

          <StatusView>
            <LabelForm>Escolha o desconto:</LabelForm>
          </StatusView>

          <StatusView>
            {listDiscounts}
            <div>
              <Button color="primary" outline onClick={handleSubmit}>
                Confirmar
              </Button>
            </div>
          </StatusView>
        </div>
      );
    }
  }
  return (
    <>
      <CustomModal
        isOpen={isOpenModalView}
        toggle={() => toggleModalViewCredit(0)}
        size="lg"
      >
        <CustomModalClose toggle={() => toggleModalViewCredit(0)} />

        {/**
         * Header
         * - Titulo do modal
         * - Tabs de selecionar parcela ou mes da recorrencia
         */}
        <CustomModalHeader>
          <div className="modal-title">
            <span>Resumo da transação</span>
          </div>
        </CustomModalHeader>
        {/* Header */}

        {isLoading ? (
          <Animation
            icon="chat-loading"
            top={'50%'}
          />
        ) : (
          <>
            {/**
             * Tabs de selecionar parcela ou mes da recorrencia
             */}
            {Array.isArray(paymentsData) && paymentsData.length > 0 && (
              <CustomModalTab>
                {/**
                 * Botão de itens do tags anterior
                 */}
                <div
                  className="iconeContainer"
                  onClick={() => {
                    showBtnMoreTags ? seeMoreTagsScroll() : null;
                  }}
                >
                  {showBtnMoreTags && (
                    <Icon
                      icon={'poli-icon pi-simple-arrow-left-line'}
                      position={'relative'}
                      size={18}
                      left={2}
                    />
                  )}
                </div>
                {/* end */}

                <Tabs id="tag-select-payment">
                  {/**
                   * Tabs de parcelar Anual
                   */}
                  {customerCredSelected?.type === 'A' && (
                    <>
                      {paymentsData?.map((e, i) => {
                        const _installmentKey = i + 1;
                        const _installment =
                          _installmentKey < 10
                            ? `0${_installmentKey}`
                            : `${_installmentKey}`;

                        return (
                          <TabsContainer
                            key={i}
                            className={
                              paymentSelected?.id === e.id ? 'active' : ''
                            }
                            onClick={() => {
                              setPaymentSelected(e);
                            }}
                          >
                            <TabItem>
                              <span>Parc/{_installment}</span>
                            </TabItem>
                          </TabsContainer>
                        );
                      })}
                    </>
                  )}
                  {/* end - Tabs de parcelar Anual  */}

                  {/**
                   * Tabs de recorrencia Mensal
                   */}
                  {customerCredSelected?.type === 'M' && (
                    <>
                      {paymentsData?.map((e, i) => {
                        return (
                          <TabsContainer
                            key={i}
                            className={
                              paymentSelected?.id === e.id ? 'active' : ''
                            }
                            onClick={() => {
                              setPaymentSelected(e);
                            }}
                          >
                            <TabItem>
                              <span>{formatDateAbbreviation(e?.date_ref)}</span>
                            </TabItem>
                          </TabsContainer>
                        );
                      })}
                    </>
                  )}
                  {/* end - Tabs de parcelar Anual  */}
                </Tabs>

                {/**
                 * Botão de itens do tags anterior
                 */}
                <div
                  className="iconeContainer"
                  onClick={() => {
                    showBtnMoreTags ? seeMoreTagsScroll(true) : null;
                  }}
                >
                  {showBtnMoreTags && (
                    <Icon
                      icon={'poli-icon pi-simple-arrow-right-line'}
                      position={'relative'}
                      size={18}
                      left={2}
                    />
                  )}
                </div>
                {/* end */}
              </CustomModalTab>
            )}

            {/* end - Tabs */}

            {/**
             * Status do pagamento
             */}
            <CustomModalStatus>
              <StatusView>
                <div className="content">
                  <LabelForm>Status do pagamento</LabelForm>
                  <h5
                    className={getStatusLabelClass(
                      paymentSelected?.status_payment
                    )}
                  >
                    {getStatusLabel(paymentSelected?.status_payment)}
                  </h5>
                </div>

                {screenAdmin &&
                  paymentSelected?.status_payment != 1 &&
                  paymentSelected?.status_payment != 2 &&
                  paymentSelected?.status_payment != 6 &&
                  isUserFinance && (
                    <div>
                      <Button
                        color="danger"
                        outline
                        onClick={() => {
                          setOpenConfirmedRemoveCredit(true);
                        }}
                      >
                        <span>
                          Remover Crédito
                          <Icon
                            icon={'poli-icon pi-delete-fill'}
                            color={'danger'}
                            position={'absolute'}
                            size={24}
                            right={1}
                          />
                        </span>
                      </Button>
                    </div>
                  )}
                  {screenAdmin &&
                  paymentSelected?.status_payment != 3 &&
                  paymentSelected?.status_payment != 5 &&
                  paymentSelected?.status_payment != 6 && (
                    <div>
                      <Button
                        color="primary"
                        outline
                        onClick={() => {
                          setOpenConfirmedLiberateCredit(true);
                        }}
                      >
                        <span>
                          Liberar crédito
                          <Icon
                            icon={'poli-icon pi-pay-fill'}
                            color={'var(--messenger-color)'}
                            position={'absolute'}
                            size={24}
                            right={1}
                          />
                        </span>
                      </Button>
                    </div>
                  )}
                {poliConfig.displayDiscountButton &&
                  screenAdmin &&
                  paymentSelected?.status_payment != 3 &&
                  isUserFinance &&
                  !hasDiscountApplied && (
                    <>
                      <div>
                        <Button
                          color="primary"
                          outline
                          style={{
                            marginLeft: '10px',
                          }}
                          onClick={() => {
                            if (!isLoadingDiscount) {
                              getDiscountData();
                              setListDiscounts(true);
                            }
                          }}
                        >
                          <span>
                            Desconto
                            <Icon
                              icon={'poli-icon pi-remove-circle-fill'}
                              color={'var(--messenger-color)'}
                              position={'absolute'}
                              size={24}
                              right={1}
                            />
                          </span>
                        </Button>
                      </div>
                    </>
                  )}
              </StatusView>
              {viewDiscount}
            </CustomModalStatus>

            {/**
             * Conteudo
             */}
            <CustomModalBody>
              <div className="formBody">
                <LabelForm>Status do pagamento</LabelForm>
                <p>48 horas após o pagamento do boleto</p>
              </div>

              <div className="formBody">
                <LabelForm>Razão Social</LabelForm>
                <p>{paymentSelected?.customer_name}</p>
              </div>

              <div className="formBody">
                <LabelForm>E-mail cadastrado</LabelForm>
                <p>{paymentSelected?.customer_email}</p>
              </div>

              <div className="formBody">
                <LabelForm>
                  Vencimento{' '}
                  {customerCredSelected?.type === 'A' ? 'da parcela' : ''}
                </LabelForm>
                <p>
                  <b>{formatDate(paymentSelected?.due_date)}</b>
                </p>
              </div>

              {customerCredSelected?.type === 'A' && (
                <div className="formBody">
                  <LabelForm>Valor da parcela</LabelForm>
                  <p>
                    <b>
                      {priceMask(
                        (paymentSelected?.value_payment ?? 0).toFixed(2)
                      )}
                    </b>
                  </p>
                </div>
              )}

              <div className="formBody">
                <LabelForm>Valor Total</LabelForm>
                <p>
                  <b>{priceMask((paymentSelected?.value ?? 0).toFixed(2))}</b>
                </p>
              </div>

              {paymentSelected?.status_payment != 3 && (
                <div className="formBody">
                  <LabelForm>Opções de Pagamento</LabelForm>
                  <div className="formBtn">
                    <Button
                      className="btn-block"
                      color="primary"
                      outline
                      onClick={() => {
                        openLink(
                          'https://api.whatsapp.com/send/?phone=5562999800123&text=Contratei%20cr%C3%A9ditos%20e%20quero%20pagar%20no%20cart%C3%A3o&app_absent=0'
                        );
                      }}
                    >
                      <span>Pagar via Cartão</span>
                      <Icon
                        icon="poli-icon pi-credit-card-line"
                        size={24}
                        position={'absolute'}
                        right={10}
                      />
                    </Button>

                    <Button
                      className="btn-block"
                      color="primary"
                      disabled={!paymentSelected?.link_invoice}
                      onClick={() => {
                        openLink(paymentSelected?.link_invoice ?? '');
                      }}
                    >
                      <span>
                        Baixar boleto{' '}
                        {!paymentSelected?.link_invoice && '(indisponível)'}
                      </span>
                      <Icon
                        icon="poli-icon pi-download-line"
                        size={24}
                        position={'absolute'}
                        right={10}
                      />
                    </Button>
                  </div>
                </div>
              )}
            </CustomModalBody>
          </>
        )}
      </CustomModal>

      <ModalConfirmation
        isOpen={openConfirmedLiberateCredit ?? false}
        onConfirmed={onConfirmedModal}
        data={dataModalConfirmedLiberateCredit}
        isLoading={false}
      />
       <ModalConfirmation
        isOpen={openConfirmedRemoveCredit ?? false}
        onConfirmed={onConfirmedRemoveCreditModal}
        data={dataModalConfirmedRemomveCredit}
        isLoading={false}
      />
    </>
  );
};

export default CreditModalView;
