import { ActionTypes } from './actions';

export default (
  state = {
    orders: [],
    order_detail: null,
    loadingGetOrders: false,
    loadingGetOrderDetail: false,
    loadingDeleteOrder: false,
    lastError: null,

    ordersRequestStatus: null,
    orderDetailRequestStatus: null,
    orderDeleteRequestStatus: null,

    ordersStatus: [],
    loadingGetOrdersStatus: false,
    paymentMethods: [],
    loadingGetPaymentMethods: false,
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.GET_ORDERS: {
      return {
        ...state,
        ...{
          loadingGetOrders: true,
        },
      };
    }
    case ActionTypes.GET_ORDERS_SUCCESS: {
      return {
        ...state,
        ...{
          orders: action.data,
          loadingGetOrders: false,
          ordersRequestStatus: true,
        },
      };
    }
    case ActionTypes.GET_ORDERS_FAILURE: {
      return {
        ...state,
        ...{
          error: action.error,
          loadingGetOrders: false,
          ordersRequestStatus: false,
        },
      };
    }
    case ActionTypes.GET_ORDER_DETAIL: {
      return {
        ...state,
        ...{
          order_detail: null,
          loadingGetOrderDetail: true,
        },
      };
    }
    case ActionTypes.GET_ORDER_DETAIL_SUCCESS: {
      return {
        ...state,
        ...{
          order_detail: action.data,
          loadingGetOrderDetail: false,
          orderDetailRequestStatus: true,
        },
      };
    }
    case ActionTypes.GET_ORDER_DETAIL_FAILURE: {
      return {
        ...state,
        ...{
          error: action.error,
          loadingGetOrderDetail: false,
          orderDetailRequestStatus: false,
        },
      };
    }
    case ActionTypes.DELETE_ORDER: {
      return {
        ...state,
        ...{
          loadingDeleteOrder: true,
        },
      };
    }
    case ActionTypes.DELETE_ORDER_SUCCESS: {
      let newOrders = [];
      if (state.orders && state.orders.list)
        newOrders = state.orders.list.filter((o) => o.id !== action.id);
      return {
        ...state,
        ...{
          loadingDeleteOrder: false,
          orders: newOrders,
          orderDeleteRequestStatus: true,
        },
      };
    }
    case ActionTypes.DELETE_ORDER_FAILURE: {
      return {
        ...state,
        ...{
          error: action.error,
          loadingDeleteOrder: false,
          orderDeleteRequestStatus: false,
        },
      };
    }
    case ActionTypes.RESET_ORDERS_STATUS: {
      return {
        ...state,
        ordersRequestStatus: null,
      };
    }
    case ActionTypes.RESET_ORDER_DETAIL_STATUS: {
      return {
        ...state,
        orderDetailRequestStatus: null,
      };
    }
    case ActionTypes.RESET_ORDER_DELETE_STATUS: {
      return {
        ...state,
        orderDeleteRequestStatus: null,
      };
    }

    case ActionTypes.GET_ORDERS_STATUS: {
      return {
        ...state,
        ...{
          loadingGetOrdersStatus: true,
        },
      };
    }
    case ActionTypes.GET_ORDERS_STATUS_SUCCESS: {
      return {
        ...state,
        ...{
          ordersStatus: action.data,
          loadingGetOrdersStatus: false,
        },
      };
    }
    case ActionTypes.GET_ORDERS_STATUS_FAILURE: {
      return {
        ...state,
        ...{
          error: action.error,
          loadingGetOrdersStatus: false,
        },
      };
    }
    case ActionTypes.GET_PAYMENT_METHODS: {
      return {
        ...state,
        ...{
          loadingGetPaymentMethod: true,
        },
      };
    }
    case ActionTypes.GET_PAYMENT_METHODS_SUCCESS: {
      return {
        ...state,
        ...{
          paymentMethods: action.data,
          loadingGetPaymentMethod: false,
        },
      };
    }
    case ActionTypes.GET_PAYMENT_METHODS_FAILURE: {
      return {
        ...state,
        ...{
          error: action.error,
          loadingGetPaymentMethod: false,
        },
      };
    }
    case ActionTypes.GET_PAYMENT_GATEWAYS: {
      return {
        ...state,
        ...{
          loadingGetPaymentGateways: true,
        },
      };
    }
    case ActionTypes.GET_PAYMENT_GATEWAYS_SUCCESS: {
      return {
        ...state,
        ...{
          paymentGateways: action.data,
          loadingGetPaymentGateways: false,
        },
      };
    }
    case ActionTypes.GET_PAYMENT_GATEWAYS_FAILURE: {
      return {
        ...state,
        ...{
          error: action.error,
          loadingGetPaymentGateways: false,
        },
      };
    }
    default: {
      return state;
    }
  }
};
