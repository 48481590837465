import React, { useEffect, useMemo, useState } from 'react';
import DateChat from './DateChat';
import MessageGroup from './MessageGroup';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Animation from '../../../common/animation';
import * as ChatActions from '../../../../store/modules/chat/actions';
import RoundedFloatingButton from '../../../common/form/RoundedFloatingButton';
import checkAvatar from '../../../../utils/checkAvatar';

var lastMessageDtm = null;
let msgs = [];

var scrollCurrent = {
  top: false,
  down: false,
};

function Messages({
  chats,
  user,
  users,
  fetchMoreMessagesRequest,
  resetCounterNewMessage,
  markAsRead,
  updateChatDropzone,
  configUserId,
  hasAsyncRequest,
  messageUpdated,
}) {
  const chat = chats?.find((chat) => chat.open === true);
  const contact = chat.contact;
  const lastMessage = chat.last_message;
  const allMessages = chat.messages;
  const isLoadingNewMessages = chat.isLoadingNewMessages; // só existe quando executa o FETCH_MORE_MESSAGES_REQUEST

  const [isLoading, setIsLoading] = useState();

  /* ############################################# */
  /* ************ INICIO - LOGICA SCROLL ************ */

  const [qtdScrollTop, setQtdScrollTop] = useState(0);

  /**
   * Ordenar mensagens, definir primeira mensagem
   */
  useEffect(() => {
    // Ordenação das mensagens por message_dtm
    let currentMessages = chat.messages?.sort(function (a, b) {
      if (a.dtm === b.dtm) {
        if (a.id > b.id) return 1;
        else if (a.id < b.id) return -1;
        else return 0;
      }

      if (a.dtm > b.dtm) return 1;
      else if (a.dtm < b.dtm) return -1;
      else return 0;
    });
    if (currentMessages) {
      lastMessageDtm = currentMessages[0]?.dtm;
    }
  }, [chat]);

  /**
   * Define se o botão de scroll para baixo na tela de chats está visível ou não
   */
  const [isScrollButtonVisible, setIsScrollButtonVisible] = useState(false);

  /**
   * Ao abrir o chat ir para fim da pagina e esconder botao de ir para baixo
   * validar chat nao lido
   */
  useEffect(() => {
    goScrollDown();
    if (!isLoadingNewMessages && !chat.last_scroll_height) {
      setIsScrollButtonVisible(false);
    }

    // Chat não lido ou novas mensagens
    if (chat.chat.chat_read === 0 || chat.newMsgCounter) {
      // Apenas muda o chat_read e zera o contador se o usuário for o dono do chat
      if (
        chat.chat.origin_id === configUserId ||
        chat.chat.origin_id === null
      ) {
        markAsRead(chat.id);
      }

      setTimeout(() => {
        resetCounterNewMessage(chat.id);
      }, 1000);
    }

    // Inicializar Dropzone para funcionar colocar imagem sem precisar de abri-lo
    updateChatDropzone(chat.id, false, false);
  }, [chat.id]);

  /**
   * Monitorar o scroll se esta no topo ou no fim da pagina
   * function setIsTopo
   * function setIsDown
   */
  const [isTopo, setIsTopo] = useState(false);
  const [isDown, setIsDown] = useState(true);

  const currentContainer = document.getElementById('messagesContainer');
  useEffect(() => {
    const ref = document.getElementById('messagesContainer');
    if (ref) {
      ref.addEventListener('scroll', (evt) => {
        let sizeHeightContainer = ref.offsetHeight; // tamanho do container visível para o usuário
        let sizeHeightContainerScroll = evt.target.scrollHeight; // tamanho do container inteiro de mensagens
        let scrollTop = evt.target.scrollTop; // quantidade de px foram rolados em uma transição

        const topPage = scrollTop === 0;
        const endPage =
          scrollTop + sizeHeightContainer + 20 > sizeHeightContainerScroll;

        if (topPage !== scrollCurrent.top) {
          setIsTopo(topPage);
          scrollCurrent.top = topPage;
        }

        if (endPage !== scrollCurrent.down) {
          setIsDown(endPage);
          scrollCurrent.down = endPage;
        }
      });
    }
  }, [chat.id, currentContainer]);

  /**
   * Logica do IR TOPO
   * Buscar conversas antigas
   */
  useEffect(() => {
    if (!hasAsyncRequest && !chat.finishingNewMessages && isTopo) {
      fetchMoreMessagesRequest(chat.id, lastMessageDtm);
      setIsLoading(false);
      // Qtd de vezes usuario deu scroll neste chat
      setQtdScrollTop((prev) => prev + 1);
    }

    // Descer um pouco apos iniciar requisicao e se tiver no topo
    if (hasAsyncRequest && isTopo && currentContainer) {
      currentContainer.scrollTop = 100;
    }
  }, [hasAsyncRequest, lastMessageDtm, chat.finishingNewMessages, isTopo]);

  /**
   * Logica do ir para Baixo
   */

  // funcao ir para baixo
  const goScrollDown = () => {
    const currentContainer = document.getElementById('messagesContainer');
    if (currentContainer) {
      // ir para baixo depois de 0,5 segs para da um tom de suavidade
      setTimeout(() => {
        currentContainer.scroll({
          top: currentContainer.scrollHeight,
          behavior: 'smooth',
        });
      }, 500);
    } else {
      goScrollDown();
    }
  };

  // Identifica quando o scroll está no final do chat
  useEffect(() => {
    if (isDown) {
      // Quando chega novas mensagens
      if (chat.newMsgCounter && !hasAsyncRequest) {
        resetCounterNewMessage(chat.id);
        markAsRead(chat.id);
        goScrollDown();
      }

      setIsScrollButtonVisible(false);
    } else {
      setIsScrollButtonVisible(true);
    }
  }, [isDown, chat.newMsgCounter, chat.last_message, hasAsyncRequest]);

  // Ir para fim da pagina, se nao tiver no fim da pagina e se a enviar nova mensagem
  useEffect(() => {
    if (lastMessage.dir !== 'i' && user?.id === lastMessage?.user_id) {
      goScrollDown();
    }
  }, [chat.last_message]);

  /* ############ FIM - LOGICA SCROLL ############ */
  /* ############################################# */

  function getBadgeDay(time) {
    let messageDate = new Date(parseInt(time) * 1000);
    messageDate = new Date(
      messageDate.getFullYear(),
      messageDate.getMonth(),
      messageDate.getDate(),
      0,
      0,
      0
    );
    let nowDate = new Date();
    nowDate = new Date(
      nowDate.getFullYear(),
      nowDate.getMonth(),
      nowDate.getDate(),
      0,
      0,
      0
    );
    let messageDateS = Math.floor(messageDate.getTime() / 1000);
    let nowDateS = Math.floor(nowDate.getTime() / 1000);
    let diff = (nowDateS - messageDateS) / 86400;
    let badgeText = '';
    if (diff === 0) {
      badgeText = 'Hoje';
    } else if (diff === 1) {
      badgeText = 'Ontem';
    } else if (diff < 7) {
      ////dias da semana
      let currentDay = messageDate.getDay();
      if (currentDay === 0) {
        badgeText = 'Domingo';
      } else if (currentDay === 1) {
        badgeText = 'Segunda-feira';
      } else if (currentDay === 2) {
        badgeText = 'Terça-feira';
      } else if (currentDay === 3) {
        badgeText = 'Quarta-feira';
      } else if (currentDay === 4) {
        badgeText = 'Quinta-feira';
      } else if (currentDay === 5) {
        badgeText = 'Sexta-feira';
      } else if (currentDay === 6) {
        badgeText = 'Sábado';
      }
    } else {
      let day = messageDate.getDate();
      let month = messageDate.getMonth();
      let year = messageDate.getFullYear();
      badgeText =
        (day < 10 ? '0' : '') +
        day +
        '/' +
        (month < 9 ? '0' : '') +
        (month + 1) +
        '/' +
        year;
    }

    if (allMessages && allMessages.length > 0) {
      return (
        <div className="dateContainer">
          <DateChat title={badgeText} />
        </div>
      );
    } else {
      return <></>;
    }
  }

  const dateFormated = (date) => {
    let messageDate = new Date(parseInt(date) * 1000);
    messageDate = new Date(
      messageDate.getFullYear(),
      messageDate.getMonth(),
      messageDate.getDate(),
      0,
      0,
      0
    );
    let nowDate = new Date();
    nowDate = new Date(
      nowDate.getFullYear(),
      nowDate.getMonth(),
      nowDate.getDate(),
      0,
      0,
      0
    );
    let messageDateS = Math.floor(messageDate.getTime() / 1000);
    let nowDateS = Math.floor(nowDate.getTime() / 1000);
    let diff = (nowDateS - messageDateS) / 86400;
    let badgeText = '';
    if (diff === 0) {
      badgeText = 'Hoje';
    } else if (diff === 1) {
      badgeText = 'Ontem';
    } else if (diff < 7) {
      ////dias da semana
      let currentDay = messageDate.getDay();
      if (currentDay === 0) {
        badgeText = 'Domingo';
      } else if (currentDay === 1) {
        badgeText = 'Segunda-feira';
      } else if (currentDay === 2) {
        badgeText = 'Terça-feira';
      } else if (currentDay === 3) {
        badgeText = 'Quarta-feira';
      } else if (currentDay === 4) {
        badgeText = 'Quinta-feira';
      } else if (currentDay === 5) {
        badgeText = 'Sexta-feira';
      } else if (currentDay === 6) {
        badgeText = 'Sábado';
      }
    } else {
      let day = messageDate.getDate();
      let month = messageDate.getMonth();
      let year = messageDate.getFullYear();
      badgeText =
        (day < 10 ? '0' : '') +
        day +
        '/' +
        (month < 9 ? '0' : '') +
        (month + 1) +
        '/' +
        year;
    }
    return badgeText;
  };

  function getBadgeDayTag(time) {
    badgeText = dateFormated(time);

    if (allMessages && allMessages.length >= 0) {
      return (
        <div className="dateContainer">
          <DateChat title={badgeText} />
        </div>
      );
    } else {
      return <></>;
    }
  }
  let beforeDay = null;
  function changeDay(time) {
    let date = new Date(parseInt(time) * 1000);
    let currentDay =
      date.getFullYear() + '' + date.getMonth() + '' + date.getDate();
    if (!beforeDay || beforeDay !== currentDay) {
      beforeDay = currentDay;
      return true;
    } else {
      return false;
    }
  }
  // Ordenação das mensagens por message_dtm
  let messages = chat.messages?.sort(function (a, b) {
    if (a.dtm === b.dtm) {
      if (a.id > b.id) return 1;
      else if (a.id < b.id) return -1;
      else return 0;
    }

    if (a.dtm > b.dtm) return 1;
    else if (a.dtm < b.dtm) return -1;
    else return 0;
  });

  /**
   * Montando html das Mensagens
   */
  let firstMessageDtm =
    allMessages &&
    allMessages[0]?.dtm &&
    getBadgeDayTag(`${allMessages[0]?.dtm}`);

  useMemo(() => {
    if (messages && messages.length && contact) {
      msgs = allMessages.slice(0).map((message, index) => {
        const onlyMessageBefore = allMessages[index - 1];
        const onlyMessage = allMessages[index];
        const messageBefore = parseInt(
          dateFormated(onlyMessageBefore?.dtm).replace(/\//gi, '')
        );
        const messageAfter = parseInt(
          dateFormated(onlyMessage?.dtm).replace(/\//gi, '')
        );
        let badge = null;
        if (
          !!messageBefore &&
          !!messageAfter &&
          messageBefore !== messageAfter
        ) {
          badge = getBadgeDayTag(`${onlyMessage?.dtm}`);
        } else if (!messageAfter) {
          if (!!messageBefore && !messageAfter) {
            badge = getBadgeDayTag(`${onlyMessage?.dtm}`);
          } else if (
            !!messageBefore &&
            dateFormated(onlyMessageBefore?.dtm) !==
              dateFormated(onlyMessage?.dtm)
          ) {
            badge = getBadgeDayTag(`${onlyMessage?.dtm}`);
          } else if (
            dateFormated(allMessages[index]?.dtm) !==
            dateFormated(onlyMessageBefore?.dtm).replace(/\//gi, '')
          ) {
            badge = getBadgeDayTag(`${allMessages[index]?.dtm}`);
          }
        }

        let ack = message.ack;
        if (ack !== '' && ack < 3 && ack > 1 && lastMessage) {
          if (lastMessage.dir === 'i') {
            ack = 3;
          } else if (lastMessage.ack !== '' && lastMessage.ack > ack) {
            ack = lastMessage.ack;
          }
        }

        // Detectando Usuário que enviou a mensagem
        let userSendedMessagePicture, userSendedMessage, userSendedMessageName;
        if (users) {
          userSendedMessage = users.find((user) => user.id === message.user_id);
          // UR significa que o contato foi direcionado pela URA
          userSendedMessagePicture = userSendedMessage
            ? checkAvatar(userSendedMessage.name, userSendedMessage.picture)
            : 'UR';
          userSendedMessageName =
            userSendedMessage?.name || 'Mensagem do Sistema';
        }

        /**
         * PS-106: Detectando se a mensagem foi enviada diretamente pelo celular conectado
         * ao broker, sem passar pela plataforma. Se sim, coloca o mesmo ícone da URA na mensagem
         * Para isso, verificamos se o dir da mensagem é 'o' e se o cuid começa com 'rec'
         */
        if (message?.dir === 'o' && message?.cuid?.startsWith('rec')) {
          userSendedMessagePicture = 'UR';
        }

        const propsMessageGroup = {
          chatType: contact.type,
          direction: message.dir === 'i' ? 'received' : 'sent',
          uid: message.uid,
          author_name: message.author_name,
          reply_message: message.reply_message,
          type: message.type,
          event: message.event,
          thumb: message.thumb,
          url: message.url,
          urlBase64: message.message_body?.url_base64 ?? undefined,
          urlAvatar:
            message.dir === 'i' ? contact.picture : userSendedMessagePicture,
          authorMessage:
            message.dir === 'i' ? contact.name : userSendedMessageName,
          authorMessageId:
            message.dir === 'i'
              ? contact.id
              : userSendedMessage
              ? userSendedMessage.id
              : '',
          message: message.body,
          messagePreview: message.body_preview,
          timeMessage: message.dtm,
          ack: ack,
          mimeType: message.mimetype,
          fileName: message.file_name,
          vCard: message.vcard,
          location: {
            latitude: message.lat,
            longitude: message.lng,
            name:
              chat.contact && chat.contact.name
                ? chat.contact.name
                : message.author_name,
          },
          caption: message.caption,
          channelId: message.channel_id,
          errorMessage: message.error,
          messageId: message.id,
          userId: message.user_id,
          deleted_at: message.deleted_at,
        };

        /**
         * Evitar que o componente (MessageGroup), seja renderizado novamente
         */
        if (msgs && msgs.length > 0 && propsMessageGroup?.type != 'media') {
          const foundMsgRendered = msgs.find((e) => e.key == message.id);
          if (foundMsgRendered?.props?.children?.length) {
            const propsRendered =
              foundMsgRendered?.props?.children[1]?.props?.props;
            if (
              JSON.stringify(propsRendered) ===
              JSON.stringify(propsMessageGroup)
            ) {
              return foundMsgRendered;
            }
          }
        }

        return (
          <div key={message.id ? message.id : `key-${index}`}>
            {badge}
            <MessageGroup props={propsMessageGroup} />
          </div>
        );
      });
    } else {
      // Detectando Usuário que enviou a mensagem
      let userSendedMessage = users
        ? users.find((user) => user.id === lastMessage.user_id)
        : null;
      // UR significa que o contato foi direcionado pela URA
      let userSendedMessagePicture = userSendedMessage
        ? checkAvatar(userSendedMessage.name, userSendedMessage.picture)
        : 'UR';
      let userSendedMessageName =
        userSendedMessage?.name || 'Mensagem do Sistema';

      /**
       * PS-106: Detectando se a mensagem foi enviada diretamente pelo celular conectado
       * ao broker, sem passar pela plataforma. Se sim, coloca o mesmo ícone da URA na mensagem
       * Para isso, verificamos se o dir da mensagem é 'o' e se o cuid começa com 'rec'
       */
      if (lastMessage.dir === 'o' && lastMessage.cuid?.startsWith('rec')) {
        userSendedMessagePicture = 'UR';
      }

      const propsMessageGroup = {
        chatType: contact.type,
        direction: lastMessage.dir === 'i' ? 'received' : 'sent',
        uid: lastMessage.uid,
        author_name: lastMessage.author_name,
        reply_message: lastMessage.reply_message,
        type: lastMessage.type,
        urlAvatar:
          lastMessage.dir === 'i' ? contact.picture : userSendedMessagePicture,
        authorMessage:
          lastMessage.dir === 'i' ? contact.name : userSendedMessageName,
        message: lastMessage.body,
        timeMessage: lastMessage.dtm,
        mimeType: lastMessage.mimetype,
        thumb: lastMessage.thumb,
        url: lastMessage.url,
        vCard: lastMessage.vcard,
        location: {
          latitude: lastMessage.lat,
          longitude: lastMessage.lng,
          name: lastMessage.author_name,
        },
        caption: lastMessage.caption,
        channelId: lastMessage.channel_id,
        errorMessage: lastMessage.error,
        userId: lastMessage.user_id,
        delete_at: lastMessage.deleted_at,
      };

      msgs = <MessageGroup props={propsMessageGroup} />;
    }
  }, [messages, messages?.length, contact, messageUpdated]);

  let badgeText = '';
  if (changeDay(Math.floor(Date.now() / 1000))) {
    // badgeText = getBadgeDay(Math.floor(Date.now() / 1000));
  }
  // styled={isMobile && {marginTop: '52px'}}j
  return (
    <div id="messagesContainerBox" className="messagesContainerBox">
      <div id="messagesContainer" className="messagesContainer">
        <RoundedFloatingButton
          onClick={() => {
            goScrollDown();
            resetCounterNewMessage(chat.id);
            setIsScrollButtonVisible(false);
          }}
          visible={isScrollButtonVisible}
          newMessagesCounter={chat.newMsgCounter}
        />
        {(isLoadingNewMessages || isLoading) && (
          <Animation icon="loading" size={'tiny'} />
        )}
        {firstMessageDtm}
        {msgs}
        {badgeText}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  chats: state.chat?.chats,
  user: state.chat?.config?.user,
  users: state.chat?.config?.general.users,
  configUserId: state.chat?.config?.user?.id,
  // constants
  hasAsyncRequest: state.chat?.hasAsyncRequest,
  messageUpdated: state.chat?.messageUpdated,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ChatActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
