import { ReactNode, forwardRef, useImperativeHandle, useState } from 'react';
import { DropdownToggle, DropdownMenu, Dropdown } from '@polichat/flamboyant';

import { Crm } from '@poli-ink/react';

import styles from '../QuickFields.module.css';

export interface MenuRootRef {
  closeMenu: () => void;
}

interface MenuRoot extends React.HTMLProps<HTMLDivElement> {
  children: ReactNode;
}

export const MenuRoot = forwardRef<MenuRootRef, MenuRoot>(
  ({ children, ...rest }, ref) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    useImperativeHandle(ref, () => ({
      closeMenu: () => {
        setDropdownOpen(false);
      },
    }));

    return (
      <Dropdown
        direction="down"
        id="quickFilterMenuRoot"
        isOpen={dropdownOpen}
        toggle={toggle}
      >
        <DropdownToggle outline color="primary">
          <Crm />
        </DropdownToggle>
        <DropdownMenu container="body" className={styles.drop_container}>{children}</DropdownMenu>
      </Dropdown>
    );
  }
);
