import { useContext, useEffect, useState } from 'react';
import { CustomerChannelBalanceContext } from '../../../contexts/customerChannelBalance/context';

import { EnumPercentages } from '../../../contexts/customerChannelBalance/useBalanceService/contactDayPercent/enumPercentages';

import { closeCreditAlert, showCreditAlert } from '../../../../../../services/contacts-day/creditAlert'
import { useWhiteLabel } from '../../../../../../hooks/useWhiteLabel';

interface CustomerContactDayAlertProps {
  channelId: number;
}

interface CustomerChannelPercentage {
  id: number;
  percentage: number;
  wasClosed: boolean;
}

export function useCustomerContactDayAlert(
  props: CustomerContactDayAlertProps
) {
  const { customerChannelPercentage, isUserAbleToSendMessage } =
    useContext(CustomerChannelBalanceContext);

  const [channelsPercentage, setChannelsPercentage] = useState<
    CustomerChannelPercentage[]
  >([]);

  const [isOpenAlert, setIsOpenAlert] = useState<boolean>(false);

  const [percentage, setPercentage] = useState(0);
  const [selectedChannelId, setSelectedChannelId] = useState(0);

  const isWhiteLabel = useWhiteLabel().isWl;
  // ---------------------------------------------
  // Functions

  function getQuantityPercentageNumber(percentage: number) {
    if (percentage >= 100) {
      return 100;
    } else if (percentage >= 80 && percentage < 100) {
      return 80;
    }
    return null;
  }

  async function closeAlertOnBackend() {
    const quantity_alert = getQuantityPercentageNumber(percentage)

    if (selectedChannelId && quantity_alert) {
      await closeCreditAlert(selectedChannelId, quantity_alert);
    }
  }

  //close alerts
  const closeAlert = () => {
    setIsOpenAlert(false);
    if (!isWhiteLabel) {
      closeAlertOnBackend()
    }
  };

  //open alerts
  const openAlert = () => setIsOpenAlert(true);

  function updatePercentValues(percentValue: number, channelId: number) {
    let findChannel = channelsPercentage.find((item) => item.id === channelId);

    //update item
    if (findChannel) {
      findChannel.percentage = percentValue;

      const newChannels = channelsPercentage.map((item) => {
        if (item.id === channelId) return findChannel;
        return item;
      });

      // @ts-ignore
      setChannelsPercentage(newChannels);
      //add item
    } else {
      let newChannel: CustomerChannelPercentage = {
        id: channelId,
        percentage: percentValue,
        wasClosed: false,
      };
      setChannelsPercentage((prev) => [...prev, newChannel]);
    }
  }

  async function evaluatePercentageToSetTheAlert(channelId: number) {
    const findChannel = channelsPercentage.find((item) => item.id === channelId);

    if(!findChannel) return;

    const quantity_alert = getQuantityPercentageNumber(findChannel.percentage);
    setSelectedChannelId(findChannel.id);
    setPercentage(findChannel.percentage);

    // @ts-ignore
    if (findChannel.percentage >= EnumPercentages.EIGHTY) {
      if (findChannel?.id && quantity_alert) {
        const canOpenAlert = await showCreditAlert(findChannel.id, quantity_alert);

        if (canOpenAlert) {
          openAlert()
        }
      }
    };
  }

  // ---------------------------------------------
  // Effects
  useEffect(() => {
    setIsOpenAlert(false);
    if (customerChannelPercentage?.hasPercentage) {
      updatePercentValues(
        customerChannelPercentage?.percentageValue || 0,
        props.channelId
      );
    }
  }, [customerChannelPercentage]);

  useEffect(() => {
    if (channelsPercentage.length){
      evaluatePercentageToSetTheAlert(props.channelId);
      setSelectedChannelId(props.channelId)
    }
  }, [channelsPercentage]);
  // ---------------------------------------------
  // Transformations
  // ---------------------------------------------
  // API

  return {
    isOpenAlert,
    closeAlert,
    isUserAbleToSendMessage,
    percentage,
  };
}
