import React, { useContext, useEffect, useState } from 'react';

import Avatar from '../../../common/profile/Avatar';
import MessageBalloon from './MessageBalloon';
import SystemMessage from './SystemMessage';
import PolyAvatar from '../../../../assets/images/poly.png';
import { GlobalContext } from 'src/context/globalContext';

function MessageGroup(props) {
  const propsObj = props.props;
  const { pictureGlobal } = useContext(GlobalContext);
  const [picture, setPicture] = useState()
  const isPictureContext = propsObj.direction === 'received' && pictureGlobal;

  useEffect(() => {
    if (isPictureContext) {
      setPicture(pictureGlobal);
    } else {
      setPicture(propsObj.urlAvatar);
    }
  }, [isPictureContext, propsObj.urlAvatar]);

  let styleGroup = styles.group;
  let styleAvatar = styles.avatarMessage;
  let extraClass = '';

  if (propsObj.direction === 'sent') {
    extraClass = 'avatarSenderStyle';
    styleGroup = {
      ...styleGroup,
      flexDirection: 'row-reverse',
    };

    styleAvatar = {
      ...styleAvatar,
      marginLeft: '0.6em',
    };
  }

  if (propsObj.direction === 'received') {
    styleGroup = {
      ...styleGroup,
      flexDirection: 'row',
    };

    styleAvatar = {
      ...styleAvatar,
      marginRight: '0.6em',
    };
  }

  const MESSAGE_TYPES = [
    'chat',
    'ad_reply',
    'text',
    'link',
    'media',
    'image',
    'video',
    'ptt',
    'audio',
    'media up',
    'location',
    'vcard',
    'multi_vcard',
    'document',
    'url',
    'sticker',
    'attachment',
    'application',
    'product',
    'payment_link',
    'story_mention',
    'story_reply',
    'unsupported_type',
    'call_missed_voice',
    'call_missed_video',
    'call_voice',
    'call_video',
    'waiting_message'
  ];

  if (MESSAGE_TYPES.includes(propsObj.type)) {
    return (
      <div
        style={styleGroup}
        className="messageItem"
        data-origin={propsObj.direction}
      >
        <span style={styleAvatar}>
          {propsObj.urlAvatar && propsObj.urlAvatar === 'UR' ? (
            <img
              src={PolyAvatar}
              alt={propsObj.authorMessage}
              title={propsObj.authorMessage}
              style={{
                background: '#f3f3f3',
                objectFit: 'cover',
                maxWidth: '40px',
                maxHeight: '40px',
                minHeight: '40px',
                minWidth: '40px',
                borderRadius: '50%',
              }}
            />
          ) : (
            <Avatar
              className={
                propsObj.direction === 'received' ? 'contact' : 'operator'
              }
              url={picture}
              size={40}
              extraClass={extraClass}
              alt={propsObj.authorMessage}
              title={propsObj.authorMessage}
            />
          )}
        </span>

        <MessageBalloon
          chatType={propsObj.chatType}
          direction={propsObj.direction}
          uid={propsObj.uid}
          author_name={propsObj.author_name}
          reply_message={propsObj.reply_message}
          thumb={propsObj.thumb}
          url={propsObj.url}
          urlBase64={propsObj.urlBase64}
          authorMessage={propsObj.authorMessage}
          authorMessageId={propsObj.authorMessageId}
          type={propsObj.type}
          time={propsObj.timeMessage}
          text={propsObj.message}
          textPreview={propsObj.messagePreview}
          ack={propsObj.ack}
          mimeType={propsObj.mimeType}
          vCard={propsObj.vCard}
          caption={propsObj.caption}
          location={propsObj.location}
          fileName={propsObj.fileName}
          channelId={propsObj.channelId}
          errorMessage={propsObj.errorMessage}
          messageId={propsObj.messageId}
          deletedAt={propsObj.deleted_at}
          isTemplate={propsObj.isTemplate}
        />
      </div>
    );
  }
  // call_log / sys_cfw_closed / sys_cfw
  else if (propsObj.event === 'system' || propsObj.type === 'call_log') {
    //comentei aqui
    return (
      <div style={styleGroup} className="messageItem">
        <SystemMessage
          text={propsObj.message}
          event={propsObj.event}
          type={propsObj.type}
          userId={propsObj.userId}
          dtm={propsObj.timeMessage}
        />
      </div>
    );
  } else {
    return <div style={styleGroup} className="messageItem"></div>;
  }
}

const styles = {
  group: {
    display: 'flex',
  },
};

export default MessageGroup;
