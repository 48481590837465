import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from '@polichat/flamboyant';
import { useState } from 'react';
import { LabelWithSpan } from '../../FormMessagesBuilder.styles';

function CreateOptionList({
  id,
  removeOption,
  onUpdate,
  initialValue,
  isDisabledRemove,
}) {
  const [optionData, setOptionData] = useState(initialValue);

  const handleInputChange = ({ target: { name, value } }) => {
    setOptionData({
      ...optionData,
      [name]: value,
    });
    handleOptionData({
      ...optionData,
      [name]: value,
    });
  };

  const handleOptionData = (data) => {
    onUpdate(data);
  };

  const handleWithDeleteOption = () => {
    setOptionData({});
    removeOption(id);
  };

  return (
    <Row>
      <Col sm={12}>
        <FormGroup>
          <LabelWithSpan>
            <Label>
              Opção: <span>Informe o titúlo da sua opção </span>
            </Label>
          </LabelWithSpan>
          <Input
            type="text"
            id="option-message"
            name="title"
            placeholder="Título da Opção"
            value={optionData.title}
            onChange={handleInputChange}
            showCharCounter
            maxLength={24}
            required
          />
          <LabelWithSpan>
            <Label>
              <span>Informe a descrição da sua opção </span>
            </Label>
          </LabelWithSpan>
          <Input
            type="text"
            id="option-description"
            name="description"
            placeholder="Descrição da Opção"
            value={optionData.description}
            onChange={handleInputChange}
            showCharCounter
            maxLength={72}
            required
          />
          <br></br>
          <Button
            color="danger"
            onClick={handleWithDeleteOption}
            disabled={isDisabledRemove}
          >
            Remover Opção
          </Button>
        </FormGroup>
      </Col>
    </Row>
  );
}

export default CreateOptionList;
