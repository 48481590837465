import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Icon,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  Switch,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
} from '@polichat/flamboyant';

import PageMainHeader from '../../../common/pages/PageMainHeader/PageMainHeader.component';
import PageMainBody from '../../../common/pages/PageMainBody/PageMainBody.component';
import Animation from '../../../common/animation';

import history from '../../../../services/history';
import useNectar from './useNectar';
import useModal from './useModal';

import { useIntegrationService } from '../useIntegrationService';

import {
  StatusContainer,
  ButtonsContainer,
  LabelContainer,
  EmailItem,
  EmailsContainer,
} from './NectarIntegration.styles';
import integrations from '../integrations-driver';
import poliConfig from '../../../../utils/poliConfig';
import { IconPlusIcon } from '../../../../assets/illustratorElement/iconPlusIcon/iconPlusIcon';

function NectarIntegration({ data }) {
  const [nectarData, setNectarData] = useState(data);

  const { isOpen, onOpenRequest, onCloseRequest, selected } = useModal();
  const {
    isOpen: isOpenDisconnect,
    onOpenRequest: onOpenDisconnect,
    onCloseRequest: onCloseDisconnect,
  } = useModal();

  const {
    nectarStatus,
    nectarToken,
    handleToken,
    nectarEmails,
    nectarEmailInput,
    setNectarEmailInput,
    handleAddEmail,
    handleRemoveEmail,
    useAttendantEmail,
    handleToggleUseAttendantEmail,
  } = useNectar({ nectarData: nectarData });

  const {
    isLoading,
    isError,
    isSuccess,
    isDeleted,
    saveIntegrationData,
    deleteIntegrationData,
  } = useIntegrationService();

  const handleSwitchDisconnect = () => {
    deleteIntegrationData(integrations.NECTAR);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    e.stopPropagation();
    history.goBack();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let nectarParams = {
      driver: integrations.NECTAR,
      token: nectarToken,
      config: {
        useAttendantEmail: useAttendantEmail,
        emails: nectarEmails,
      },
    };

    if (!nectarParams.token.length) {
      toast.warning('Por favor inserir token');
      return;
    }
    if (!nectarParams.config.emails.length) {
      toast.warning('Por favor inserir email de comunicação');
      return;
    }

    saveIntegrationData(nectarParams);
  };

  const handleTextAlert = () => {
    if (!useAttendantEmail) {
      return;
    }
    return (
      <Row>
        <Col>
          <Label>
            Essa opção só está disponível para{' '}
            <b>auto atendimento de Finalização</b>, pois somente neste momento
            que o chat possui um operador atribuído o qual deverá ter o mesmo
            e-mail na NectarCRM.
          </Label>
        </Col>
      </Row>
    );
  };
  const shouldUseAttendantEmail = () => {
    return (
      <>
        <Row>
          <Col>
            <FormGroup>
              <Switch
                id="try-email-nectarcrm"
                isChecked={useAttendantEmail}
                onClick={(e) => handleToggleUseAttendantEmail()}
              ></Switch>
              <Label for="try-email-nectarcrm">
                Tentar utilizar o email do atendente quando enviar para o
                NectarCRM
              </Label>
            </FormGroup>
          </Col>
        </Row>
        {handleTextAlert()}
      </>
    );
  };

  useEffect(() => {
    //request post response error
    if (isError) {
      setNectarData({ error: isError });
    }
    //request post response success
    if (isSuccess) {
      setNectarData({ success: isSuccess });
    }
  }, [isError, isSuccess]);

  useEffect(() => {
    if (isDeleted) {
      history.goBack();
    }
  }, [isDeleted]);

  return (
    <>
      <PageMainHeader
        title="Integrações"
        icon="poli-icon pi-integrations-line"
      />
      <PageMainBody>
        <StatusContainer color={nectarStatus.statusColor}>
          <IconPlusIcon
            firstIcon="onlyLogo"
            secondIcon="nectarIcon"
            secondColor="var(--success)"
          />
          {isLoading ? (
            <Animation icon="chat-loading" />
          ) : (
            <>
              <h2>{nectarStatus.message?.[0] || 'Poli + NectarCRM'}</h2>
              <h3>{nectarStatus.message?.[1] || ''}</h3>
            </>
          )}
        </StatusContainer>
        <Form onSubmit={handleSubmit} className="col">
          <Row>
            <Col>
              <FormGroup>
                <LabelContainer>
                  <Icon
                    icon="poli-icon pi-key-line"
                    size={26}
                    color="var(--primary)"
                  />
                  <Label for="token-nectarcrm">Token do Nectar:</Label>
                </LabelContainer>
                <Input
                  type="text"
                  id="token-nectarcrm"
                  name="nectarToken"
                  value={nectarToken}
                  onChange={(e) => handleToken(e.target.value)}
                  placeholder="Insira seu token NectarCRM"
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <LabelContainer>
                  <Icon
                    icon="poli-icon pi-mail-line"
                    size={26}
                    color="var(--primary)"
                  />
                  <Label>E-mail para comunicação:</Label>
                </LabelContainer>
                <InputGroup>
                  <Input
                    id="search"
                    type="email"
                    placeholder="Email"
                    value={nectarEmailInput}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        handleAddEmail(nectarEmailInput);
                      }
                    }}
                    onChange={(e) => setNectarEmailInput(e.target.value)}
                  />
                  <InputGroupAddon addonType="append">
                    <Button
                      gradient="primary"
                      onClick={() => handleAddEmail(nectarEmailInput)}
                    >
                      <Icon
                        position="relative"
                        top="2px"
                        icon="poli-icon pi-add-circle-fill"
                        size={20}
                        color={'white'}
                      />
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          {shouldUseAttendantEmail()}
          <Row>
            <Col>
              <span>
                <strong>E-mails adicionados: </strong>
              </span>
              <EmailsContainer>
                {nectarEmails?.map((email, index) => (
                  <EmailItem key={index}>
                    <span>{email}</span>
                    <span>
                      <Icon
                        icon="poli-icon pi-delete-line"
                        color="var(--danger)"
                        // onClick={() => handleRemoveEmail(email)}
                        onClick={() => onOpenRequest(email)}
                      />
                    </span>
                  </EmailItem>
                ))}
              </EmailsContainer>
            </Col>
          </Row>
          {nectarStatus.status === 'on' || nectarStatus.status === 'error' ? (
            <>
              <Row style={{ marginTop: '2rem' }}>
                <Col>
                  <FormGroup>
                    <span>
                      <strong>Desconectar integração?</strong>
                    </span>
                    <Button
                      color="danger"
                      size={'normal-icon'}
                      title="Desconectar"
                      id="button-tooltip"
                      onClick={onOpenDisconnect}
                    >
                      <Icon
                        position={'relative'}
                        top={'-5px'}
                        right={'5px'}
                        size={22}
                        icon={'poli-icon pi-integrations-line'}
                      />
                    </Button>
                    <UncontrolledTooltip
                      placement="top"
                      target="button-tooltip"
                    >
                      Desconectar
                    </UncontrolledTooltip>
                  </FormGroup>
                </Col>
              </Row>
            </>
          ) : null}
          <Row>
            <ButtonsContainer>
              <Button
                style={{
                  cursor:
                    nectarEmails.length == 0 || nectarEmailInput
                      ? 'not-allowed'
                      : 'default',
                }}
                gradient="primary"
                width="quarter"
                title="confirmar"
                disabled={
                  nectarEmails.length == 0 || nectarEmailInput ? true : false
                }
              >
                Confirmar
              </Button>
              <Button color="danger" onClick={handleCancel}>
                Cancelar
              </Button>
            </ButtonsContainer>
          </Row>
        </Form>
      </PageMainBody>
      {/* Remove e-mail confirmation */}
      <Modal isOpen={isOpen} toggle={onCloseRequest}>
        <ModalBody>Deseja remover o e-mail: {selected}?</ModalBody>
        <ModalFooter>
          <Button outline color="primary" onClick={onCloseRequest}>
            Cancelar
          </Button>
          <Button
            color="danger"
            onClick={() => {
              handleRemoveEmail(selected);
              onCloseRequest();
            }}
          >
            Excluir
          </Button>
        </ModalFooter>
      </Modal>
      {/* Remove connection confirmation */}
      <Modal isOpen={isOpenDisconnect} toggle={onCloseDisconnect}>
        <ModalBody>Deseja desconectar sua integração com o Nectar?</ModalBody>
        <ModalFooter>
          <Button outline color="primary" onClick={onCloseDisconnect}>
            Cancelar
          </Button>
          <Button color="danger" onClick={handleSwitchDisconnect}>
            Excluir
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default NectarIntegration;
