import { Table, Row, Col } from '@polichat/flamboyant';
import TablePagination from '../../../../../components/shopping/TablePagination';
import {
  NumbersContainer,
  RecentContainer,
  TextContainer,
} from '../../components/style/recents';
import { MetricResource } from '../../components/MetricResource';
import {
  PaginationInfo,
  PoliAdsMetricItem,
} from '../useMetaAdMetricService/types';
import { NotPermissionPage } from '../../../components/common/notPermission';

export interface MetricsResultsProps {
  loading: boolean;
  items?: PoliAdsMetricItem[];
  pagination?: PaginationInfo;
  onPageChange: (value: any) => void;
}

// TODO: thayron table

export function MetricsResults(props: MetricsResultsProps) {
  const { items, pagination } = props;
  // ---------------------------------------------
  // Transformations
  const totalItemsToTablePagination =
    items && items?.length * (pagination?.total_pages || 1);
  // ---------------------------------------------
  // Render

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          margin: '0.1rem 1rem',
          padding: '2px',
          height: '89%',
        }}
      >
        <div
          style={{
            flexGrow: '2',
            padding: '1rem',
            overflowY: 'auto',
          }}
        >
          <Row>
            {!props.loading && !items?.length && (
              <NotPermissionPage helperText="Você não possui PoliAds" />
            )}
            {items?.length && (
              <Table size="small">
                <thead>
                  <tr>
                    <th style={{ fontWeight: 'bold', padding: '0 1px' }}>
                      Anúncios
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr key={'loading'}>
                    <td>
                      {props.loading && (
                        <span style={{ margin: '0 1rem', fontWeight: 'bold' }}>
                          Carregando...
                        </span>
                      )}
                    </td>
                  </tr>
                  {!props.loading &&
                    items?.map((item) => (
                      <tr key={item.id}>
                        <td colSpan={3} style={{ margin: '0 1px' }}>
                          <RecentContainer key={item.id}>
                            <MetricResource
                              format={item.format}
                              url={item.resources_url}
                              textHelper={item.title}
                            />

                            <TextContainer>
                              <div>
                                <h6>{item.title}</h6>
                              </div>
                              <p>{item.description}</p>
                            </TextContainer>
                            <NumbersContainer>
                              <h5>{'Mensagens'}</h5>
                              <span>{item.messages_counter ?? '0'}</span>
                            </NumbersContainer>
                          </RecentContainer>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            )}
          </Row>
        </div>
        <div style={{ flexGrow: '1' }}>
          <Row>
            <Col>
              {/* Pagination */}
              {items?.length ? (
                <TablePagination
                  totalItens={totalItemsToTablePagination}
                  limitItens={pagination?.per_page}
                  onPageChange={(info: any) =>
                    props.onPageChange(info.currentPage)
                  }
                />
              ) : null}
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
