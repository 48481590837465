import { useCallback, useEffect, useState } from 'react';
import { Channel360DialogCustomerService } from '../../../../../service';

interface Error {
  error: boolean;
  msg?: string;
}

export interface UseChannelCustomerServiceProps {
  customer_id: number;
}
export function useChannelCustomerService(
  props: UseChannelCustomerServiceProps
) {
  // ---------------------------------------------
  // States
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error>({ error: false });
  const [data, setData] = useState([]);
  // ---------------------------------------------
  // Functions
  const get = useCallback(async (customerId: number) => {
    try {
      setLoading(true);
      const result = await Channel360DialogCustomerService.getUsers(customerId);
      setData(result.data);
      setError({ error: false });
      setLoading(false);
    } catch (error) {
      console.log('error', error);
      setError({
        error: true,
        msg: 'Erro ao buscar informações de usuários, tente novamente!',
      });
      setLoading(false);
    }
  }, []);

  // ---------------------------------------------
  // Effects
  useEffect(() => {
    if (props.customer_id) {
      get(props.customer_id);
    }
  }, [props.customer_id, get]);
  // ---------------------------------------------
  // Transformations
  // ---------------------------------------------
  // API

  return {
    loading,
    data,
    error,
    get,
  };
}
