import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { getChannelExtra } from '../../../../utils/channels/getChannelExtra';
import ChannelsService from '../../../../services/tools/channels/channels';
import ChannelList from '../ChannelList/ChannelList.component';
import HireChannels from '../HireChannels/HireChannels.component';
import Animation from '../../../common/animation';
import { Button, Icon } from '@polichat/flamboyant';
import history from '../../../../services/history';
import { toast } from 'react-toastify';
import { useFlags } from 'flagsmith/react';

const GetChannels = () => {
  const currentCustomerID = useSelector(
    (state) => state.general.current_customer_id
  );

  const [customer_id, setCustomerId] = useState(currentCustomerID);
  const [allChannels, setAllChannels] = useState([]);
  const [loadend, loadendSet] = useState(false);
  const [loading, loadingSet] = useState(false);
  const [channels, setChannels] = useState([]);
  const [pagination, setPagination] = useState({});

  const channelNotFound = 'Nenhum canal foi encontrado, tente novamente.';

  //Realizar requisição de acordo com a página (paginação)
  const handleOnPageChange = async (data) => {
    const { page, status, channel_id, q } = data;
    const changePageObject = {
      customer_id,
      page,
    };

    if (status) {
      changePageObject.status = parseInt(status);
    }

    if (channel_id) {
      changePageObject.channel_id = parseInt(channel_id);
    }
    if (channel_id == 1) {
      changePageObject.isZapiAndWaba = true;
      delete changePageObject.channel_id;
    }

    if (q) {
      changePageObject.q = q;
    }

    loadingSet(true);
    const result = await ChannelsService.getAllChannels(changePageObject);
    loadingSet(false);
    if (!result?.data?.length) {
      result.data.error = channelNotFound;
    }

    result?.data?.map((channel) => {
      channel.extra = getChannelExtra(channel);
    });
    setChannels(result?.data);
    setPagination(result.pagination);
  };

  const handleOnStatusFilterChange = async (data) => {
    const statusFilterObject = {
      customer_id,
    };

    if (data.status) {
      statusFilterObject.status = parseInt(data.status);
    }

    if (data.channel_id) {
      statusFilterObject.channel_id = parseInt(data.channel_id);
    }

    if (data.q) {
      statusFilterObject.q = data.q;
    }
    loadingSet(true);

    const result = await ChannelsService.getAllChannels(statusFilterObject);
    loadingSet(false);
    if (!result?.data?.length) {
      result.data.error = channelNotFound;
    }

    result?.data?.map((channel) => {
      channel.extra = getChannelExtra(channel);
    });
    setChannels(result?.data);
    setPagination(result.pagination);
  };

  const handleOnTypeFilterChange = async (data) => {
    const typeFilterObject = {
      customer_id,
    };

    if (data.channel_id) {
      typeFilterObject.channel_id = parseInt(data.channel_id);
    }

    if (data.api) {
      typeFilterObject.api = parseInt(data.api);
    }

    if (data.isZapiAndWaba) {
      typeFilterObject.isZapiAndWaba = data.isZapiAndWaba;
    }

    if (data.status) {
      typeFilterObject.status = parseInt(data.status);
    }

    if (data.q) {
      typeFilterObject.q = data.q;
    }

    loadingSet(true);
    const result = await ChannelsService.getAllChannels(typeFilterObject);
    loadingSet(false);
    if (!result?.data?.length) {
      result.data.error = channelNotFound;
    }

    result?.data?.map((channel) => {
      channel.extra = getChannelExtra(channel);
    });

    setChannels(result?.data);
    setPagination(result.pagination);
  };

  const handleOnSearchFilterChange = async (data) => {
    const searchFilterObject = {
      customer_id,
      q: data.q,
    };

    if (data.channel_id) {
      searchFilterObject.channel_id = parseInt(data.channel_id);
    }

    if (data.status) {
      searchFilterObject.status = parseInt(data.status);
    }

    loadingSet(true);
    const result = await ChannelsService.getAllChannels(searchFilterObject);
    loadingSet(false);
    if (!result?.data?.length) {
      result.data.error = channelNotFound;
    }

    result?.data?.map((channel) => {
      channel.extra = getChannelExtra(channel);
    });
    setChannels(result?.data);
    setPagination(result.pagination);
  };

  const handleOnCustomerFilterChange = async (data) => {
    setCustomerId(data.customer);
    const customerFilterObject = {
      customer_id: data.customer,
    };

    if (data.channel_id) {
      customerFilterObject.channel_id = parseInt(data.channel_id);
    }

    if (data.status) {
      customerFilterObject.status = parseInt(data.status);
    }
    if (data.q) {
      customerFilterObject.q = data.q;
    }

    try {
      loadingSet(true);
      const result = await ChannelsService.getAllChannels(customerFilterObject);
      if (!result?.data?.length) {
        result.data.error = channelNotFound;
      }

      result?.data?.map((channel) => {
        channel.extra = getChannelExtra(channel);
      });
      setChannels(result?.data);
      setPagination(result.pagination);
    } catch (e) {
      toast(channelNotFound);
      console.log('Error handleOnCustomerFilterChange => ', e);
    } finally {
      loadingSet(false);
    }
  };

  const fetchChannels = useCallback(async () => {
    let retryCount = 0;
    const maxRetries = 3;

    while (retryCount < maxRetries) {
      try {
        const result = await ChannelsService.getAllChannels({ customer_id });
        result?.data?.map((channel) => {
          channel.extra = getChannelExtra(channel);
        });
        setAllChannels(result.data);
        setChannels(result.data);
        setPagination(result.pagination);
        loadendSet(true);
        return;
      } catch (error) {
        console.log(
          'Error in GetChannels component when getAllChannels => ',
          error
        );
        retryCount++;
      }
    }
    console.log('Failed to fetch channels after maximum retries.');
    toast('Erro ao carregar canais. Tente novamente mais tarde.');
    loadendSet(true);
  }, []);

  useEffect(() => {
    fetchChannels();
  }, [fetchChannels]);

  if (!loadend) {
    return <Animation icon="chat-loading" top={'50%'} />;
  }

  if (allChannels?.length) {
    return (
      <ChannelList
        channels={channels}
        loading={loading}
        pagination={pagination}
        handleOnPageChange={handleOnPageChange}
        handleOnStatusFilterChange={handleOnStatusFilterChange}
        handleOnTypeFilterChange={handleOnTypeFilterChange}
        handleOnSearchFilterChange={handleOnSearchFilterChange}
        handleOnCustomerFilterChange={handleOnCustomerFilterChange}
      />
    );
  }

  if (!allChannels?.length) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '30px',
        }}
      >
        <Icon icon="poli-icon pi-sad-line" size={30} color={'var(--danger)'} />
        <span>Você ainda não possui canais.</span>
        <Button
          onClick={() => history.push('/tools/channels/add')}
          style={{
            marginTop: '11px',
            width: '30%',
          }}
        >
          adquirir canais
        </Button>
      </div>
    );
  }
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '30px',
        }}
      >
        <Icon icon="poli-icon pi-sad-line" size={30} color={'var(--danger)'} />
        <span>Erro ao comunicar com o servidor.</span>
        <Button
          onClick={fetchChannels}
          style={{
            marginTop: '11px',
            width: '30%',
          }}
        >
          Tente Novamente
        </Button>
      </div>
    </>
  );
};

export default GetChannels;
