import { combineReducers } from 'redux';

import whitelabel from './whitelabel/reducer';
import auth from './auth/reducer';
import changelog from './changelog/reducer';
import general from './general/reducer';
import chat from './chat/reducer';
import departments from './tools/departments/reducer';
import shoppingCategoriesList from './shopping/categories-list/reducer';
import shoppingCategoriesManage from './shopping/categories-manage/reducer';
import shoppingProductsList from './shopping/products-list/reducer';
import shoppingProductsManage from './shopping/products-manage/reducer';
import shoppingOrdersList from './shopping/orders-list/reducer';
import visitors from './visitors/reducer';
import contactDay from './contact-day/reducer';
import alerts from './alerts/reducer';

export default combineReducers({
  whitelabel,
  auth,
  changelog,
  general,
  chat,
  visitors,
  shoppingCategoriesList,
  shoppingCategoriesManage,
  shoppingProductsList,
  shoppingProductsManage,
  shoppingOrdersList,
  departments,
  contactDay,
  alerts,
});
