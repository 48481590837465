export function Preview({ file }) {
  return (
    <div className="containerPreview cursor-pointer">
      <div className="wrapperPreview">
        {file.typeFile === 'image' ? (
          <img
            className="img-fluid preview"
            src={file.preview}
            alt="Imagem de preview"
          />
        ) : file.typeFile === 'video' ? (
          <div className="poli-icon pi-video-fill iconPreview" />
        ) : file.typeFile === 'audio' ? (
          <div className="poli-icon pi-song-file-fill iconPreview" />
        ) : file.subTypeFile === 'pdf' ? (
          <div className="poli-icon pi-pdf-file-fill iconPreview pdf" />
        ) : file.subTypeFile === 'zip' || file.subTypeFile === 'rar' ? (
          <div className="poli-icon pi-zip-fill iconPreview" />
        ) : (
          <div className="poli-icon pi-file-fill iconPreview" />
        )}
      </div>
    </div>
  );
}
