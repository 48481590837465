import { useState } from 'react';
import { Col, FormGroup, Label, CustomInput } from '@polichat/flamboyant';

function TemplateSelectBuilderType({
  onUpdate,
  initialValue: { templateTypeList, initialTemplateType },
  isEdit,
}) {
  const [templateType, setTemplateType] = useState({
    templateType: initialTemplateType,
  });

  const handleInputChange = ({ target: { name, value } }) => {
    setTemplateType({
      ...templateType,
      [name]: value,
    });
    handleTemplateTypeData({
      ...templateType,
      [name]: value,
    });
  };

  const handleTemplateTypeData = (data) => {
    onUpdate(data);
  };

  return (
    <Col sm={12} md={6}>
      <FormGroup>
        <Label>Tipo de mensagem:</Label>
        <CustomInput
          type="select"
          id="template-type"
          name="templateType"
          value={templateType.templateType}
          onChange={handleInputChange}
          required
          disabled={isEdit}
        >
          <optgroup label="Tipo de mensagem">
            {templateTypeList.map((type) => (
              <option key={type.id} disabled={!type.active} value={type.type}>
                {type.description}
              </option>
            ))}
          </optgroup>
        </CustomInput>
      </FormGroup>
    </Col>
  );
}

export default TemplateSelectBuilderType;
