const statusList = {
  // [Status, isOk, needAtention],
  verified: ['Verificado', true, false],
  not_verified: ['Não verificado', false, false],
  pending: ['Pendente', false, false],
  pending_need_more_info: ['Pendente mais informação', false, true],
  rejected: ['Rejeitado', false, true],
  pending_submission: ['Pendente envio', false, true],
  failed: ['Falhou', false, false],
};

const channelFBStatus = (status) => {
  return statusList[status] || ['Indefinido', false, false];
};

export default channelFBStatus;
