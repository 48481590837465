import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import * as Sentry from '@sentry/browser';

import { bindActionCreators } from 'redux';
import poliConfig from '../../utils/poliConfig';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Icon,
} from '@polichat/flamboyant';

import ChatPanel from '../../components/chat/panel/ChatPanel';
import InProgressPanel from '../../components/chat/panel/InProgressPanel';
import UnReadsPanel from '../../components/chat/panel/UnReadsPanel';
import ContactPanel from '../../components/contacts/contactPanel';

import ChatContent from '../../components/chat/content/ChatContent';
import ContactAdd from '../../components/contacts/contactAdd';
import ContactEdit from '../../components/contacts/contactEdit';
import ContactImport from '../../components/contacts/contactImport';

import ChatStartContent from '../../components/chat/content/chatStartContent';
import ContactsStartContent from '../../components/contacts/contactsStartContent';

import ChatLoading from '../../components/chat/content/chat/ChatLoading';
import ChatLoadingFailed from '../../components/chat/content/chat/ChatLoadingFailed';

import * as ChatActions from '../../store/modules/chat/actions';
import * as AlertsActions from '../../store/modules/alerts/actions';
import { isMobile } from 'react-device-detect';
import { ChatAlertsModal } from '../../components/chat/alerts/ChatAlertsModal.component';
import { AvatarContactProvider } from 'src/context/globalContext';
import OrganizationAdd from '../../components/organization/organizationAdd';
import NotificationElement from 'src/components/sound_notification';

var content;

// window.document.body.onkeydown((e)=>{
//   alert("ok");
//   console.log("teste",e);
// });

function Chat({
  chat,
  openSocketRequest,
  fetchChatConfigRequest,
  setupContactContent,
  fetchCountNoReads,
  fetchChatRequest,
  fetchMoreChatsRequest,
  fetchAllContacts,
  fetchUsersRequest,
  openChatTab,
  fetchQuickMessages,
  openChat,
  fetchUniqueChatRequest,
  fetchPendencesChatRequest,
  fetchCatalog,
  // constants
  socket,
  config,
  initiatedApp,
  hasAsyncRequest,
  isLoading,
  isAlertLoading,
  isLoadingChatRequest,
  error,
  errorCode,
  errorAction,
  user,
  chats,
  pendences,
  messagesLoaded,
  activeNavigation,
  openAddContact,
  openImportContact,
  openEditContact,
  customerOnboarding,
  showBlockTwo,
  showBlockOne,
  fetchChatAlertsRequest,
  availableUser,
  openAddCompany
}) {
  const wlState = useSelector((state) => state?.whitelabel);

  useEffect(() => {
    if (window.performance) {
      window.onbeforeunload = () => {
        return true;
      };
    }
    document.body.onkeydown = (e) => {
      if (e.key == 'F5') {
        window.onbeforeunload = null;
        if (
          !window.confirm(
            'Atualizar a página? \n\n É possível que as alterações feitas não sejam salvas.'
          )
        ) {
          e.preventDefault();
          if (window.performance) {
            window.onbeforeunload = () => {
              return true;
            };
          }
        }
      } else if (window.performance && window.onbeforeunload == null) {
        window.onbeforeunload = () => {
          return true;
        };
      }
    };
  }, []);

  const callSupport = () => {
    window.open('https://web.whatsapp.com/send?phone=556282361759');
  };

  const backToAdminPanel = () => {
    window.location.assign(`${poliConfig.poliAppLaravelUrl}`);
  };

  const tryReconnect = () => {
    document.getElementsByClassName('modal')[0].style.display = 'none';
    document.getElementsByClassName('modal-backdrop')[0].style.display = 'none';
    document.getElementsByClassName('ChatLoadingError')[0].style.display =
      'block';
    document.getElementsByClassName('socketErrorModal')[0].style.display =
      'none';
    window.location.reload();
  };

  // End - Modal Customer Onbording WABA

  //busca as configs de chat
  useEffect(() => {
    if (typeof config === 'undefined') {
      if (socket && user && isLoading === false) {
        if (chat && typeof chat.fetch_chat_config_fail === 'undefined') {
          fetchChatConfigRequest();
          setupContactContent();
          fetchCatalog();
        }
      }
    }
  }, [socket, user, isLoading, config, chat]);

  // busca os avisos para serem exibidos na tela de chat
  useEffect(() => {
    if (socket && user && !isAlertLoading) {
      fetchChatAlertsRequest({ user });
    }
  }, [socket, user]);

  let noreads = 0;

  // useEffect(() => {
  //   console.log(`re-render para buscar contador de não lidos: isLoading ${isLoading}, typeof chats ${typeof chats}`)

  //   if (config) {
  //     if (typeof chats === 'undefined' && isLoading === false) {
  //       if (chat && typeof chat.count_no_read === 'undefined') {
  //         console.log('re-render fetch api')
  //         fetchCountNoReads();
  //       }
  //     }
  //   }
  // }, [isLoading, config, chats, chat]);

  // const memoizedChats = useMemo(() => {
  //   return typeof chats === 'undefined';
  // }, [chats]);

  // //busca todos os chats
  // useEffect(() => {
  //   if(memoizedChats){
  //     //garantir a chamada do fetchChatRequest após a abertura do ws
  //     setTimeout(()=>{
  //       fetchChatRequest();
  //     }, 2000)
  //   }
  // }, [memoizedChats])

  // busca todos os chats
  useEffect(() => {
    if (config && isLoading === false) {
      if (typeof chats === 'undefined' && typeof noreads === 'number' && config.users) {
        fetchMoreChatsRequest(0, {}, '', 'noread');
        fetchChatRequest();
      }
    }
  }, [config, noreads, isLoading, chats]);

  //busca as mensagens dos chats (inicial)
  /*
  useEffect(() => {
    if (!isLoading && !messagesLoaded && isMessagesLoading && chats && chats.length > 0) {
      isMessagesLoading = false

      for (let i = 0; i < chats.length; i++) {
        let id = chats[i].contact?.id;
        if (id) fetchMessagesRequest(id);
      }

      createMessageTextArea();
      createUploadList();
    }
  }, [isLoading, messagesLoaded, isMessagesLoading, chats])
  */

  //busca todos os usuários da empresa
  useEffect(() => {
    if (chat && typeof chat.loadingUsers === 'undefined') {
      if (config) {
        if (typeof config.users === 'undefined') {
          fetchUsersRequest();
          fetchAllContacts();
        }
      }
    }
  }, [chats, config, chat]);

  //busca todos as mensagens rápidas
  useEffect(() => {
    if (
      chats &&
      chats.length &&
      (!chat.quickMessages ||
        (!chat.quickMessages.loading && !chat.quickMessages.messages)) &&
      messagesLoaded
    ) {
      fetchQuickMessages();
    }
  }, [chats, chat, messagesLoaded]);

  let chat_id = parseInt(localStorage.getItem('chat_id'));
  if (
    !isNaN(chat_id) &&
    chat_id &&
    chat_id > 0 &&
    chats &&
    chats.length &&
    !isLoadingChatRequest
  ) {
    let hasContactInStore = chats.some((chat) => {
      return chat.id === chat_id;
    });
    if (hasContactInStore) {
      let chat = chats.find((chat) => chat.id === chat_id);
      if (chat && !chat.open) {
        openChat(chat_id);
        openChatTab();
      }
    } else {
      fetchUniqueChatRequest(chat_id);
    }
  }

  const fetchPendencesChatRetry = () => {
    const min = 1;
    const max = 5;
    let rand = Math.floor(Math.random() * (max - min + 1)) + min;
    let randTimeout = rand * 1000;

    setTimeout(() => {
      fetchPendencesChatRequest();
    }, randTimeout);
  };

  if (
    !isLoading &&
    chats &&
    pendences &&
    pendences.length &&
    !chat.pendencesLoading
  ) {
    if (chat.pendencesError) {
      fetchPendencesChatRetry();
    } else {
      fetchPendencesChatRequest();
    }
  }

  if (isLoading || isLoadingChatRequest) {
    content = <ChatLoading />;
  } else if (error) {
    let print_error = null;
    if (error && typeof error === 'object') {
      print_error = error.msg
        ? error.msg
        : error.description
        ? error.description
        : null;
    }
    let detail_error = null;
    if (!print_error && typeof error === 'string') {
      detail_error = error;
    } else {
      /////caso não tenha nenhuma informação do erro tenta mostrar na tela o objeto dentro de state.error
      detail_error = JSON.stringify(error);
    }
    detail_error += '[';
    if (errorCode) {
      detail_error += ' Code: ' + errorCode;
    }
    if (errorAction) {
      detail_error += ' Action: ' + errorAction;
    }
    detail_error += ']';

    content = <ChatLoadingFailed error={print_error} detail={detail_error} />;
  }

  // Voltar para tela de chat (Mobile)
  const location = useLocation();
  useEffect(() => {
    if (isMobile) {
      const ele = document.getElementById('backToMenuMobile');
      if (!location.hash && ele) {
        ele.click();
      }
    }
  }, [location, isMobile]);

  /**
   * Abrir chat com id do hash da url
   */
  useEffect(() => {
    if (!isLoading && !hasAsyncRequest && initiatedApp) {
      const chatId = parseInt(location?.hash?.replace('#', ''));
      if (!isNaN(chatId) && chatId && chatId > 0) {
        fetchUniqueChatRequest(chatId);
        if (isMobile) showBlockTwo();
      }
    }
  }, [initiatedApp, location]);
  /* end */

  useMemo(() => {
    if (isMobile && !chat?.mobile) {
      // Inicializando o estado mobile e a navegação via componentes
      showBlockOne();
    }
    if (!error && activeNavigation) {
      content = (
        <>
          <NotificationElement />
          <ChatAlertsModal />
          {activeNavigation === 'chat' ? (
            <ChatPanel isLoading={chats === undefined || isLoading} />
          ) : (activeNavigation === 'contact' || activeNavigation === 'company') ? (
            <ContactPanel />
          ) : activeNavigation === 'inprogress' ? (
            <InProgressPanel isLoading={chats === undefined || isLoading} />
          ) : activeNavigation === 'unreads' ? (
            <UnReadsPanel isLoading={chats === undefined || isLoading} />
          ) : (
            ''
          )}

          {['chat', 'inprogress', 'unreads'].includes(activeNavigation) && (
            <>
              <ChatStartContent />
              <ChatContent />
            </>
          )}

          {activeNavigation === 'contact' && <ContactsStartContent />}

          {activeNavigation === 'contact' && openAddContact ? (
            <ContactAdd />
          ) : activeNavigation === 'contact' && openImportContact ? (
            <ContactImport />
          ) : activeNavigation === 'contact' && openEditContact ? (
            <ContactEdit />
          ) : activeNavigation === 'contact' ? (
            <ContactsStartContent />
          ) : activeNavigation === 'company' && openAddCompany ? <OrganizationAdd/> : (
            <></>
          )}
        </>
      );
    }
  }, [
    chats,
    isLoading,
    isLoadingChatRequest,
    error,
    activeNavigation,
    openAddContact,
    openImportContact,
    openEditContact,
    availableUser,
    openAddCompany
  ]);

  /**
   * Modal Customer Onbording WABA
   */
  if (poliConfig?.featureOnboardingWaba) {
    const openLinkOnboarding = (link) => {
      window.open(link, '_blank');
    };

    const modalOnboardingRender = (data) => {
      return (
        <div className="chatContainer">
          <div
            className="ChatLoadingError"
            style={{ display: 'none', width: '100%' }}
          >
            <ChatLoading />
          </div>

          <Modal
            isOpen={true}
            className={
              localStorage.getItem('@polichat/darkmode') === 'true'
                ? 'darkMode '
                : ''
            }
          >
            <ModalHeader>
              <Icon
                icon="poli-icon pi-whatsapp-fill"
                color="#34af23"
                size={50}
              />
              <h3 right="true">Homologação WABA</h3>
            </ModalHeader>

            <ModalBody>
              <span dangerouslySetInnerHTML={{ __html: data?.text }}></span>
            </ModalBody>

            <ModalFooter>
              {Array.isArray(data?.linkBtn) &&
                data?.linkBtn?.map((item, key) => {
                  return (
                    <Button
                      key={key}
                      color={item?.color}
                      size="lg"
                      onClick={() => openLinkOnboarding(item?.url)}
                      className={item?.class}
                    >
                      {item?.label}
                    </Button>
                  );
                })}
            </ModalFooter>
          </Modal>
        </div>
      );
    };

    // type 2 (WABA Embedded Signup) e status = 1 (Onboarding iniciado)
    if (customerOnboarding?.type == 2 && customerOnboarding?.status == 1) {
      const dataModalOnboarding = {
        // text: 'Você entrou no processo de onboarding para WABA e precisa iniciar a homologação do seu canal WhatsApp. Para isso, preencher o formulário abaixo.',
        text: 'Seja bem vindo a plataforma Poli! É um grande prazer tê-lo em nossa família 💙 <br><br>Daqui para frente, você precisará passar pelo processo de implantação para iniciar o uso da plataforma. A sua jornada de configuração começa com a homologação da sua conta de WhatsApp, o que leva em média 30 dias. Lembre-se a plataforma será liberada apenas com o início do processo.<br><br>Caso ainda não esteja agendado o início clique no botão abaixo e fale com a nossa equipe de Suporte',
        linkBtn: [
          // {
          //   url: 'https://hub.360dialog.com/lp/whatsapp/1QcGcbPA/',
          //   label: 'Preencher Formulário',
          //   color: 'info',
          // },
          {
            url: 'https://api.whatsapp.com/send?phone=556299800123&text=Estou%20no%20processo%20de%20homologa%C3%A7%C3%A3o%20da%20WABA%20e%20preciso%20de%20ajuda!',
            label: 'Falar com Suporte',
            color: 'warning',
            class: 'btn-block',
          },
        ],
      };

      return modalOnboardingRender(dataModalOnboarding);
    }

    // type 3 (WABA Classico) e status = 10 (Onboarding iniciado - Modo Classico)
    if (customerOnboarding?.type == 3 && customerOnboarding?.status == 10) {
      const dataModalOnboarding = {
        // text: 'Você entrou no processo de onboarding para WABA e precisa iniciar a homologação do seu canal WhatsApp. Para isso, preencher o formulário abaixo.<br><br> Após isso, é necessário o envio de alguns documentos da empresa para o Facebook. Para isso, clique no botão "Enviar Documentos para o Facebook".<br> Se tiver dúvidas, solicite atendimento do nosso suporte',
        text: 'Seja bem vindo a plataforma Poli! É um grande prazer tê-lo em nossa família 💙 <br><br>Daqui para frente, você precisará passar pelo processo de implantação para iniciar o uso da plataforma. A sua jornada de configuração começa com a homologação da sua conta de WhatsApp, o que leva em média 30 dias. Lembre-se a plataforma será liberada apenas com o início do processo.<br><br>Caso ainda não esteja agendado o início clique no botão abaixo e fale com a nossa equipe de Suporte',
        linkBtn: [
          // {
          //   url: 'https://hub.360dialog.com/lp/submit-waba/1QcGcbPA',
          //   label: 'Preencher Formulário',
          //   color: 'info',
          // },
          {
            url: 'https://api.whatsapp.com/send?phone=556299800123&text=Estou%20no%20processo%20de%20homologa%C3%A7%C3%A3o%20da%20WABA%20e%20preciso%20de%20ajuda!',
            label: 'Falar com Suporte',
            color: 'warning',
            class: 'btn-block',
          },
          // {
          //   url: 'https://business.facebook.com/settings/security',
          //   label: 'Enviar Documentos para o Facebook',
          //   color: 'info',
          // },
        ],
      };

      return modalOnboardingRender(dataModalOnboarding);
    }

    // type 3 (WABA Classico) e status = 12 (Cliente Aprovado pelo FB)
    if (customerOnboarding?.type == 3 && customerOnboarding?.status == 12) {
      const dataModalOnboarding = {
        text: 'Seu cadastro foi aprovado no Facebook, agora você está no processo de migração para WABA. Para finalizamos a migração clique em "Finalizar Migração" e nos envie codigo OTP e API KEY.',
        linkBtn: [
          {
            url: 'https://hub.360dialog.com',
            label: 'Finalizar Migração',
            color: 'info',
          },
          {
            url: 'https://api.whatsapp.com/send?phone=556299800123&text=Estou%20no%20processo%20de%20homologa%C3%A7%C3%A3o%20da%20WABA%20e%20preciso%20de%20ajuda!',
            label: 'Falar com Suporte',
            color: 'warning',
          },
        ],
      };

      return modalOnboardingRender(dataModalOnboarding);
    }
  }

  //abre o socket
  if ((!isLoading && !socket) || (chat && chat.fetch_chat_config_fail)) {
    if (chat.tryConnection && chat.tryConnection > 5) {
      return (
        <div className="chatContainer">
          <div
            className="ChatLoadingError"
            style={{ display: 'none', width: '100%' }}
          >
            <ChatLoading />
          </div>

          <Modal
            isOpen={true}
            className={
              localStorage.getItem('@polichat/darkmode') === 'true'
                ? 'darkMode socketErrorModal'
                : ' socketErrorModal'
            }
          >
            <ModalHeader>
              <Icon icon="poli-icon pi-alert-fill" color="#FFA26B" size={50} />
              <span right="true">Erro ao abrir o Chat</span>
            </ModalHeader>
            <ModalBody>
              <span>
                Houve um erro inesperado ao comunicar com o servidor. Tente
                novamente ou entre em contato com o Polichat.
                {chat && chat.fetch_chat_config_fail ? (
                  <small className="errorCode">
                    Erro:{' '}
                    {chat.error || 'Erro ao carregar configurações do chat'}
                  </small>
                ) : null}
              </span>
            </ModalBody>
            <ModalFooter>
              <Button color="info" size="lg" onClick={tryReconnect}>
                Tentar Novamente
              </Button>
              <Button outline color="secondary" size="lg" onClick={callSupport}>
                Falar com Suporte
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      );
    } else {
      openSocketRequest();
      return (
        <div className="chatContainer">
          <ChatLoading />
        </div>
      );
    }
  }

  if (socket && user && (!user.channels || !user.channels.length)) {
    if (user.id == 54444 || user.id == 58669 || user.id == 58745) {
      Sentry.captureMessage('Usuario sem canal ' + JSON.stringify(user));
    }
    return (
      <div className="chatContainer">
        <div
          className="ChatLoadingError"
          style={{ display: 'none', width: '100%' }}
        >
          <ChatLoading />
        </div>

        <Modal
          isOpen={true}
          className={
            localStorage.getItem('@polichat/darkmode') === 'true'
              ? 'darkMode channelErrorModal'
              : ' channelErrorModal'
          }
        >
          <ModalHeader>
            <Icon icon="poli-icon pi-alert-fill" color="#FFA26B" size={50} />
            <h3 right="true">Sem canal de atendimento</h3>
          </ModalHeader>
          <ModalBody>
            <span>
              Você não possui acesso a nenhuma canal de atendimento da sua
              organização! Entre em contato com o administrador do sistema e
              solicite acesso.
            </span>
          </ModalBody>
          <ModalFooter>
            <Button color="warning" size="lg" onClick={backToAdminPanel}>
              Voltar ao Painel
            </Button>
            {!wlState?.config?.is_white_label && (
              <Button color="info" size="lg" onClick={callSupport}>
                Falar com Suporte
              </Button>
            )}
          </ModalFooter>
        </Modal>
      </div>
    );
  }

  return (
    <AvatarContactProvider>
      <div className="chatContainer">{content}</div>
    </AvatarContactProvider>
  );
}

//Vai pegar informações do nosso estado
//e vai mapear em formato de propriedade de componente
const mapStateToProps = (state) => ({
  chat: state.chat,

  // constants
  socket: state.chat.socket,
  config: state.chat.config?.general,
  isLoading: state.chat.loading,
  initiatedApp: state.chat.initiatedApp,
  hasAsyncRequest: state.chat.hasAsyncRequest,
  isAlertLoading: state.alerts?.loading,
  isLoadingChatRequest: state.chat.loadingChatRequest,
  error: state.chat.error,
  errorCode: state.chat.errorCode || null,
  errorAction: state.chat.errorAction || null,
  user: state.chat.config?.user,
  chats: state.chat.chats,
  pendences: state.chat.pendences || null,
  messagesLoaded: state.chat.messagesLoaded,
  activeNavigation: state.chat.config?.activeTabNavigation,
  openAddContact: state.chat.config?.openAddContact,
  openAddCompany: state.chat.config?.openAddCompany,
  openImportContact: state.chat.config?.openImportContact,
  openEditContact: state.chat.config?.openEditContact,
  customerOnboarding: state.chat.config?.general?.customer_onboarding,
  availableUser: state.general?.user?.status,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(Object.assign({}, ChatActions, AlertsActions), dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
