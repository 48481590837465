import { useEffect, useState } from 'react';
import { Col, MultiSelector , FormGroup , CustomInput, Label} from '@polichat/flamboyant';
import { useSelector } from 'react-redux';

function SelectDepartments({ onUpdate , initialValues, departmentData}) {

  const [selectedDepartments, setSelectedDepartments] = useState(initialValues || '');

  const handleGetMultiDepartments = (department_id) => {
    setSelectedDepartments(department_id)

    // onUpdate({departments: department_id.map(depart => depart.value)})
    onUpdate(department_id)
  }

  return (
  <Col sm={12} md={6} >
    <FormGroup>
      <Label>Departamentos:</Label>
      {/* <MultiSelector
        name="select-users-channel"
        id="select-users-channel"
        options={departmentsList}
        selectedValues={handleGetMultiDepartments}
        values={selectedDepartments}
      /> */}
       <CustomInput
          type="select"
          id="select-departments-channel"
          name="select-departments-channel"
          value={selectedDepartments}
          onChange={(e) => handleGetMultiDepartments(e.target.value)}
          style={{ cursor: departmentData.isLoading ? 'wait' : '' }}
        >
          {departmentData.isLoading ? (
            <option>Carregando...</option>
          ) : (
            <option value="" disabled>
              {' '}
              --- Departamentos ---{' '}
            </option>
          )}

          {!departmentData.isLoading && !departmentData.departments.length ? (
            <option value="empty" disabled>
              Não há departamentos cadastrados
            </option>
          ) : (
            departmentData.departments?.map((department) => (
              <option
                key={`${department.id}${Math.random()}`}
                value={department.id}
                disabled={department.disabled}
              >
                {department.name}
              </option>
            ))
          )}
        </CustomInput>
    </FormGroup>
  </Col>
  );
}

export default SelectDepartments;
