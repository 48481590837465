export default function setBgColorMessages(initials, source) {

    // Tratativa se o initials for uma url
    if (initials && source && source === 'replyInTextBox' && initials.length > 4) {
      return `rgb(13,157,167)`
    }

    // Tratativa se o initials for uma url
    if (initials && source && source === 'replyInBalloon' && initials.length > 4) {
      return `rgb(255,100, 124)`
    }

  let styleColor = [
    [255, 207, 92],// A ok
    [255, 162, 107],// B ok
    [0, 132, 244],// C ok
    [0, 196, 140],// D ok
    [148, 82, 165],// E ok
    [144, 145, 145],// F ok
    [255, 100, 124],// G ok
    [149, 121, 121],// H ok
    [97, 221, 188],// I ok
    [245, 185, 69],// J ok
    [217, 68, 82],// K ok
    [115, 176, 244],// L ok
    [227, 182, 146],// M ok
    [53, 186, 155],// N ok
    [250, 108, 81],// O ok
    [100, 132, 162],// P ok
    [224, 122, 183],// Q ok
    [216, 166, 42],// R ok
    [171, 168, 168],// S ok
    [79, 192, 232],// T ok
    [131, 193, 57],// U ok
    [247, 108, 130],// V ok
    [131, 110, 218],// W ok
    [250, 152, 81],// X ok
    [11, 123, 131],// Y ok
    [122, 153, 230]// Z ok
  ]

  let char = initials;

  let bgColor;

  if( /[A-Z]/.test(char) ){
    let index = char.charCodeAt() - 65
    bgColor = styleColor[index]
  }else if( /[\d]/.test(char) ){
    bgColor = styleColor[parseInt(char)]
  }else{
    bgColor = [13,157,167]
  }

 return `rgb(${bgColor})`

}

