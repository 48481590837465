import { DtoMetaResponse } from '../types';

export enum EnumCodeMethod {
  SMS = 'SMS',
  VOICE = 'VOICE',
}

export interface DtoMigrateChannelWabaToPoliResponse extends DtoMetaResponse {
  phone_id: string;
}

export interface DtoMigrateChannelWabaToPoli {
  channel_id: number;
  customer_id: number;
  business_manager_id: number;
}
export interface DtoPhoneCode {
  code: string;
}

export interface DtoRequestCode {
  code_method: string;
  language: string;
}
