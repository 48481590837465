import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import * as GeneralActions from '../../../store/modules/general/actions';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import PageMainHeader from '../../common/pages/PageMainHeader/PageMainHeader.component';
import PageMainBody from '../../common/pages/PageMainBody/PageMainBody.component';
import { Button, Icon, Input, Modal, ModalBody, ModalFooter, ModalHeader, Table } from '@polichat/flamboyant';
import history from '../../../services/history';
import { getPlan, getServices } from '../../../utils/admin/plans/plans';
import { postAPlanInLaravel, putAPlanInLaravel } from '../../../services/admin/plans/plans';
import {useLocation} from 'react-router-dom';
import Animation from '../../common/animation';

function PlanForm() {

  let { id } = useParams();
  const [plan, setPlan] = useState(mountInitialPlan());
  const [isOpenModalAddItem, setIsOpenModalAddItem] = useState(false);
  const toggleModalAddItem = () => setIsOpenModalAddItem(!isOpenModalAddItem);
  const [servicesCombobox, setServicesCombobox] = useState([]);
  const [erros, setErros] = useState(getErros());
  const [idItemsToRemove, setIdItemsToRemove] = useState([]);
  const [visible, setVisible] = useState(true);
  const [canSave, setCanSave] = useState(true);

  const [itemPlanToModal, setItemPlanToModal] = useState(mountInitialItemToModal());

  const [isLoading, setIsLoading] = useState(true);

  const { state } = useLocation();

  async function fetchData() {
    if (id) {
      const plan = await getPlan(id);
      setPlan(plan);
    }
  }

  useEffect(() => {
    async function fetchServices(){
      const services = await getServices();
      if(services?.length <= 0){
        history.push({ pathname: `/admin/welcome-plan` });
        return;
      }
      setServicesCombobox(services);
      setItemPlanToModal({...itemPlanToModal, serviceId:services[0]?.id, service:services[0]});
      setIsLoading(false);
    }

    fetchServices();

    //caso seja redirecionado pela tela de importar planos, state possui atributo origin
    if(state?.origin === 'importList'){
      console.log(state?.origin);

      //Preenche apenas os itens necessários para pre-preencher o formulario
      let plan = {
        superlogica_id: state.data.id,
        name: state.data.nome,
        description: state.data.descricao,
        status: 1,
        isTrial: false,
        itemsPlan: state.data.itemsPlan ? state.data.itemsPlan : [],
      }

      setVisible(false);

      setPlan(plan);
    }else{
      fetchData();
    }

    return () => {
      setPlan(mountInitialPlan());
    };
  }, []);

  useEffect(() => {
    if(!id && !(state?.origin === 'importList')) {
      setPlan(mountInitialPlan());
    }
  }, [id]);

  return (
    <>
      <PageMainHeader title={id ? 'Editar Plano' : 'Novo Plano'} icon='poli-icon pi-list-customers-line' />
      <PageMainBody>
        { isLoading ?
          <Animation icon='chat-loading' top={'50%'} />
          :
          <>
            <p>Nome</p>
            <Input type='text' value={plan?.name} className={erros.inputName.error ? 'is-invalid' : ''} disabled={!visible} onChange={(event) => {
              setPlan({ ...plan, name: event.target.value });
            }} />
            {erros.inputName.error ? <span className='small is-invalid'>{erros.inputName.description}<br /></span> : null}
            <br />
            <p>Descrição</p>
            <Input type='textarea' value={plan?.description} className={erros.inputDescription.error ? 'is-invalid' : ''} disabled={!visible} onChange={(event) => {
              setPlan({ ...plan, description: event.target.value });
            }} />
            {erros.inputDescription.error ? <span className='small is-invalid'>{erros.inputDescription.description}<br /></span> : null}
            <br />
            <span className='custom-switch custom-control'>
              <Input type='checkbox' id='checkIsTrial' className='custom-control-input' checked={plan?.isTrial} disabled={!visible} onChange={() => {
                setPlan({ ...plan, isTrial: !(plan?.isTrial) });
              }} />
              <label className='custom-control-label' htmlFor='checkIsTrial'>Este plano é trial</label>
            </span>
            <span className='custom-switch custom-control'>
              <Input type='checkbox' id='checkIsStatus' className='custom-control-input' checked={!plan?.status} disabled={!visible} onChange={() => {
                setPlan({ ...plan, status: !(plan?.status) });
              }} />
              <label className='custom-control-label' htmlFor='checkIsStatus'>Pausado</label>
            </span>
            <br /><br />
            <p><strong>Itens do plano</strong></p>
            <Table responsive bordered striped hover>
              <thead>
              <tr>
                <th className='text-center'>Serviço</th>
                <th className='text-center'>Descrição</th>
                <th className='text-center'>Valor</th>
                <th className='text-center'>Quantidade</th>
                <th className='text-center'>Total</th>
                <th className='text-center' />
              </tr>
              </thead>
              <tbody>
              {plan?.itemsPlan?.map((itemPlan, index) => {
                return <tr key={index}>
                  <td className='text-center'>{itemPlan?.service?.name}</td>
                  <td className='text-center'>{itemPlan?.service?.description}</td>
                  <td className='text-center'>{Number(itemPlan?.service?.value)?.toLocaleString()}</td>
                  <td className='text-center'>{itemPlan?.quantity}</td>
                  <td className='text-center'>{(Number(itemPlan?.service?.value) * Number(itemPlan?.quantity))?.toLocaleString()}</td>
                  <td className='text-center'>
                    {/** Editar item do plano (listagem de itens) */}
                    <Button gradient='info' className='btn-gradient-info btn btn-secondary btn-sm' style={{ marginRight: '5px' }} disabled={!visible} onClick={() => {
                      setItemPlanToModal({...itemPlan, index:index});
                      toggleModalAddItem();
                    }}><Icon icon='poli-icon pi-pencil-line' /></Button>
                    {/** Remover item do plano (listagem de itens) */}
                    <Button gradient='danger' className='btn-gradient-danger btn btn-secondary btn-sm' style={{ marginLeft: '5px' }} disabled={!visible} onClick={() => {
                      const itemToRemove = itemPlan;
                      if(itemToRemove.id){
                        setIdItemsToRemove([...idItemsToRemove, itemToRemove.id]);
                      }
                      const modifiedItems = plan?.itemsPlan?.filter((subItemPlan, subIndex) => subIndex !== index);
                      setPlan({ ...plan, itemsPlan: modifiedItems });
                    }}><Icon icon='poli-icon pi-delete-line' /></Button>
                  </td>
                </tr>;
              })}
              </tbody>
            </Table>
            {erros.tableItemsPlan?.error ? <><span className='small is-invalid'>{erros.tableItemsPlan?.description}</span><br /></> : null}
            <Button gradient='success' className='btn-gradient-success btn btn-secondary btn-sm' disabled={!visible} onClick={()=>{
              setItemPlanToModal({...itemPlanToModal, serviceId:servicesCombobox[0]?.id, service:servicesCombobox[0]});
              toggleModalAddItem();
            }}>
              <Icon icon='poli-icon pi-add-fill' />
            </Button>
            <br />
            <Button gradient='success' className='btn-gradient-success btn btn-secondary' style={{ float: 'right' }} disabled={!canSave} onClick={() => {

              if (!(plan?.name) || plan?.name === '' || plan?.name?.trim() === '') { // Nome do plano não existe
                setErros({ ...getErros(), inputName: { error: true, description: 'Este campo deve ser preenchido' } });
                return;
              }
              if (!(plan?.description) || plan?.description === '' || plan?.description?.trim() === '') { // Descrição do plano não existe
                setErros({ ...getErros(), inputDescription: { error: true, description: 'Este campo deve ser preenchido' } });
                return;
              }
              if (!(plan?.itemsPlan) || !(plan?.itemsPlan instanceof Array) || plan?.itemsPlan?.length === 0) { // Não existe itens adicionado ao plano
                setErros({ ...getErros(), tableItemsPlan: { error: true, description: 'Você precisa adicionar itens ao plano' } });
                return;
              }

              setErros(getErros);
              setCanSave(false);
              if(id){ // Editando o plano
                async function putAPlan() {
                  try {
                    const response = await putAPlanInLaravel({ ...plan, idItemsToRemove:idItemsToRemove });
                    if(response.status === 200){
                      history.push({ pathname: `/admin/plans` });
                    }
                  }catch (error) {
                    console.error(error.message, 'message:', error.response?.data?.message);
                    alert('Ocorreu um erro ao atualizar este plano. Verifique todos os campos. Caso o problema persista, contate o nosso suporte.');
                  }
                }
                putAPlan();
              }else{ // Salvando um novo plano
                async function postAPlan() {
                  try{
                    const response = await postAPlanInLaravel(plan);
                    if(response.status === 200){
                      history.push({ pathname: `/admin/plans` });
                    }
                  }catch (error) {
                    console.error(error.message, 'message:', error.response?.data?.message);
                    alert('Ocorreu um erro ao cadastrar este plano. Verifique todos os campos. Caso o problema persista, contate o nosso suporte.');
                  }
                }
                postAPlan();
              }
            }}>Salvar</Button>
          </>}
      </PageMainBody>
      <Modal isOpen={isOpenModalAddItem} toggle={toggleModalAddItem} size='lg' className='modal-dialog-scrollable'>
        <ModalHeader toggle={toggleModalAddItem}>
          <Icon icon='poli-icon pi-add-line' />
          <span>Adicionar item no plano</span>
        </ModalHeader>
        <ModalBody>
          <div>
            <span>Serviço</span>
            <select className='form-control' name='modal_service' id='modal_service' required='' value={itemPlanToModal?.service?.id} onChange={(event) => {
              setItemPlanToModal({...itemPlanToModal, serviceId:event.target.value, service: servicesCombobox.find(service => String(service?.id) === String(event.target.value))});
            }}>
              {servicesCombobox?.map((service, index) => {
                return <option key={index} value={service?.id}>{service?.name}</option>;
              })}
            </select>
            <br />
            <span>Quantidade</span>
            <Input type='number' className='custom-text form-control' value={itemPlanToModal?.quantity} onChange={(event) => {
              const number = parseInt(event.target.value);
              if (number <= 0) {
                setItemPlanToModal({...itemPlanToModal, quantity: 1})
                return;
              }
              setItemPlanToModal({...itemPlanToModal, quantity: number})
            }} />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button outline onClick={toggleModalAddItem}>
            Cancelar
          </Button>
          <Button color='success' onClick={() => {

            const itemPlan = {...itemPlanToModal};
            let tempItemsPlan = [...plan?.itemsPlan];

            if (itemPlanToModal.index === null || itemPlanToModal.index === undefined) { // Item não existe, criar um novo
              const service = servicesCombobox.find(service => String(service.id) === String(itemPlan.serviceId));
              const newItemPlan = {...itemPlan, serviceId:service?.id, planId:plan?.id, service: service}
              setPlan({ ...plan, itemsPlan: [...tempItemsPlan, newItemPlan] });
            } else { // Item já existe, basta somente editar
              tempItemsPlan[itemPlanToModal.index] = itemPlan;
              setPlan({ ...plan, itemsPlan: [...tempItemsPlan] });
            }

            toggleModalAddItem();
            setItemPlanToModal(mountInitialItemToModal());
          }}>
            <span>Adicionar</span>
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

function mountInitialItemToModal() {
  return {quantity:1, serviceId:1, service:null};
}

function mountInitialPlan() {
  return {
    id: null,
    name: '',
    description: '',
    value: 0,
    status: 1,
    isTrial: false,
    superlogica_id: 0,
    itemsPlan: []
  };
}

function getErros() {
  return {
    inputName: { error: false, description: '' },
    inputDescription: { error: false, description: '' },
    tableItemsPlan: { error: false, description: '' },
  };
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(GeneralActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PlanForm);
