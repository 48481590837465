import { Icon } from '@polichat/flamboyant';

import { QuickMessageTemplate } from '../../types/types';

import styles from '../Modal/ModalMessagePreview.module.css';

export interface PreviewButtonsProps {
  buttons?: QuickMessageTemplate['buttons'];
  buttonList?: string;
}
export function PreviewButtons({
  buttons,
  buttonList,
}: Readonly<PreviewButtonsProps>) {
  return (
    <div className={styles.buttons}>
      {buttonList && (
        <div className={styles.button}>
          <Icon icon={`poli-ícone pi-barmenu-fill`} size={18} />
          <span>{buttonList}</span>
        </div>
      )}
      {buttons?.map((button) => (
        <div key={button.id} className={styles.button}>
          {button.type === 'action' && (
            <Icon
              icon={`poli-icon pi-${
                button.actionType === 'link' ? 'external-link' : 'phone'
              }-line`}
              size={18}
            />
          )}
          <span>{button.text || ''}</span>
        </div>
      ))}
    </div>
  );
}
