import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import * as GeneralActions from '../../../store/modules/general/actions';
import { connect } from 'react-redux';
import PageMainBody from '../../common/pages/PageMainBody/PageMainBody.component';
import { Button, Icon, Table } from '@polichat/flamboyant';
import history from '../../../services/history';
import { getFullPlan, getImportPlans } from '../../../utils/admin/plans/plans';
import { modelServicesSl } from '../../../utils/admin/services/services';
import Animation from '../../common/animation';

function ImportList() {

  const [plans, setPlans] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  async function fetchData() {
    const plans = await getImportPlans();

    if (plans.length <= 0) {
      history.push({ pathname: `/admin/welcome-plan` });
    }
    setPlans(plans);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>

      <PageMainBody>
        {isLoading ?
          <Animation icon='chat-loading' top={'50%'} /> :
          <Table responsive bordered striped hover>
            <thead>
            <tr>
              <th className='text-center'>Nome</th>
              <th className='text-center'>Descrição</th>
              <th className='text-center'>Valor</th>
              <th className='text-center' />
            </tr>
            </thead>
            <tbody>
            {plans.map((plan, index) => {
              return <tr key={index}>
                <td className='text-center'>{plan.nome}</td>
                <td dangerouslySetInnerHTML={{ __html: plan.descricao }} />
                <td className='text-center'>{plan.recorrencia_parcela}</td>
                <td className='text-center'>
                  <Button color='secondary' className='btn-sm' style={{ marginLeft: '5px' }} onClick={() => {
                    async function findFullPlan(plan) {
                      const fullPlan = await getFullPlan(plan.id);
                      const joinPlan = { ...fullPlan, ...plan };

                      const servicesSL = joinPlan?.compo_recebimento;
                      const remodelServices = modelServicesSl(servicesSL);

                      let tempItemsPlan = [];
                      remodelServices.forEach((service, index) => {
                        tempItemsPlan.push({
                          planId: plan.id,
                          quantity: Number(servicesSL[index].nm_quantidade_comp),
                          service: service,
                          serviceId: null,
                        });
                      });

                      history.push({
                        pathname: '/admin/plans/new',
                        state: { 'origin': 'importList', data: { ...joinPlan, itemsPlan: tempItemsPlan } },
                      });
                    }

                    findFullPlan(plan);
                  }}><Icon icon='poli-icon pi-arrow-down-line' /> Importar </Button>
                </td>
              </tr>;
            })}
            </tbody>
          </Table>}
      </PageMainBody>
    </>
  );
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(GeneralActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ImportList);
