import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  Button,
  Col,
  Container,
  FormFeedback,
  FormGroup,
  Icon,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  UncontrolledTooltip,
} from '@polichat/flamboyant';

import { QuickMessage } from '../../types/types';

import {
  QuickMessageFormValues,
  useQuickMessageForm,
} from '../../hooks/useQuickMessageForm';
import { useQuickMessageService } from '../../hooks/useQuickMessageService';
import { ModalOptions } from './types';

export function ModalQuickMessageForm(props: Readonly<ModalOptions>) {
  const loadingForm = useSelector(
    (state: any) => state?.chat?.quickMessages?.loadingForm
  );

  const errorForm = useSelector(
    (state: any) => state?.chat?.quickMessages?.createOrUpdateError
  ) as
    | Partial<Pick<QuickMessage, 'tag' | 'text' | 'keyboard_shortcut'>>
    | undefined;

  const successForm = useSelector(
    (state: any) => state?.chat?.quickMessages?.createOrUpdateSuccess
  ) as boolean | undefined;

  const [editId, setEditId] = useState<number | null>(null);

  const form = useQuickMessageForm();
  const { save } = useQuickMessageService();

  const handleCloseModal = useCallback(() => {
    setEditId(null);
    form.reset({
      shortcut: '',
      tag: '',
      text: '',
    });
    props.handleClose();
  }, [setEditId]);

  const handleSubmit = useCallback(
    (data: QuickMessageFormValues) => {
      save({
        ...data,
        keyboard_shortcut: data.shortcut,
        ...(editId && { id: editId }),
      });
    },
    [save, editId]
  );

  const handleError = useCallback((data: any) => {
    console.error('Submit quick message form errors: ', data);
  }, []);

  useEffect(() => {
    if (errorForm) {
      toast.error(
        <div>
          {`Não foi possivel ${
            editId ? 'editar' : 'criar'
          } sua mensagem rápida.`}
          <br />
          {errorForm.tag ?? ''}
          <br />
          {errorForm.keyboard_shortcut ?? ''}
          <br />
          {errorForm.text ?? ''}
          <br />
        </div>
      );

      errorForm.tag &&
        form.setError('tag', { type: 'custom', message: errorForm.tag });

      errorForm.keyboard_shortcut &&
        form.setError('shortcut', {
          type: 'custom',
          message: errorForm.keyboard_shortcut,
        });

      errorForm.text &&
        form.setError('text', { type: 'custom', message: errorForm.text });
    }
  }, [errorForm]);
  useEffect(() => {
    if (successForm) {
      toast.success(
        `Mensagem rápida ${editId ? 'editada' : 'criada'} com sucesso!`
      );
      handleCloseModal();
    }
  }, [successForm]);
  useEffect(() => {
    if (!props.isOpen) return;
    if (!props.message || !Object.keys(props.message).length) return;

    setEditId(props.message.id);
    form.reset({
      text: props.message.text,
      tag: props.message.tag,
      shortcut: props.message.keyboard_shortcut.replace(/^Ctrl\+/i, '') ?? '',
    });
  }, [props.isOpen, props.message]);

  return (
    <Modal isOpen={props.isOpen} toggle={handleCloseModal}>
      <ModalHeader toggle={handleCloseModal}>
        {editId ? 'Editar' : 'Criar'}
        {' Mensagem Rápida'}
      </ModalHeader>
      <ModalBody>
        <div>
          <form onSubmit={form.handleSubmit(handleSubmit, handleError)}>
            <Container>
              <Row form>
                <Col md={12}>
                  <FormGroup>
                    <Label for="quick-tag">
                      <Icon
                        icon="poli-icon pi-text-fast-fill"
                        size={20}
                        color={'var(--color-primary)'}
                      />
                      Tag da mensagem
                    </Label>
                    <Controller
                      control={form.control}
                      name="tag"
                      render={({ field, fieldState }) => (
                        <React.Fragment>
                          <Input
                            type="text"
                            id="quick-tag"
                            name="quick-tag"
                            placeholder=""
                            value={field.value}
                            onChange={field.onChange}
                            invalid={!!fieldState.error}
                            // Ao editar não permitir a alteração da tag
                            disabled={!!editId}
                            cursor={editId ? 'not-allowed' : 'initial'}
                          />
                          <FormFeedback>
                            {fieldState.error?.message}
                          </FormFeedback>
                          {!!editId && (
                            <UncontrolledTooltip
                              placement="top"
                              target="quick-tag"
                            >
                              Campo não pode ser alterado
                            </UncontrolledTooltip>
                          )}
                        </React.Fragment>
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <FormGroup>
                    <Label for="quick-shortcut">
                      <Icon
                        icon="poli-icon pi-shortcuts-fill"
                        size={20}
                        color="var(--green-polichat)"
                      />
                      <span>Tecla de atalho (Ctrl+)</span>
                    </Label>
                    <Controller
                      control={form.control}
                      name="shortcut"
                      render={({ field, fieldState }) => (
                        <React.Fragment>
                          <Input
                            type="text"
                            id="quick-shortcut"
                            name="quick-shortcut"
                            autoComplete="off"
                            placeholder=""
                            maxLength="1"
                            value={field.value}
                            onChange={field.onChange}
                            invalid={!!fieldState.error}
                          />
                          {!!fieldState.error && (
                            <FormFeedback>
                              {fieldState.error?.message}
                            </FormFeedback>
                          )}
                        </React.Fragment>
                      )}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="quick-text">
                      <Icon
                        icon="poli-icon pi-rocket-fill"
                        size={20}
                        color="var(--green-polichat)"
                      />
                      Mensagem Rápida
                    </Label>

                    <Controller
                      control={form.control}
                      name="text"
                      render={({ field, fieldState }) => (
                        <React.Fragment>
                          <Input
                            type="textarea"
                            id="quick-text"
                            name="quick-text"
                            placeholder=""
                            value={field.value}
                            onChange={field.onChange}
                            invalid={!!fieldState.error}
                          />

                          <FormFeedback>
                            {fieldState.error?.message}
                          </FormFeedback>
                        </React.Fragment>
                      )}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col>
                  <FormGroup style={{ textAlign: 'center' }}>
                    <Button type="submit" color="primary" disable={loadingForm}>
                      {!loadingForm ? 'Salvar' : 'Carregando...'}
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Container>
          </form>
        </div>
      </ModalBody>
    </Modal>
  );
}
