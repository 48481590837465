import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Row, Col, Button } from '@polichat/flamboyant';

import PageMainBody from 'src/components/common/pages/PageMainBody/PageMainBody.component';
import { ProgressBarMigrateChannelToPoli } from 'src/components/tools/migrateChannel/components/progressBar';

import { Step1, Step1Ref } from './step1';
import { Step2 } from './step2';
import { Step3 } from './step3';
import {
  getApiKeyRequestDto,
  getChannel360InfoFromQueryString,
} from './helper';
import { useChannelCustomerService } from './useChannelCustomerService';
import { DtoDialog360Channel } from '../service/DtoDialog360Channel';

import './index.css';
import {
  addChannelToLocalStorage,
  getChannelToLocalStorage,
  removeChannelToLocalStorage,
} from '../common/helpers';
export function IntegratedOnboardPage() {
  const location = useLocation();
  const customer_id = useSelector(
    //@ts-ignore
    (state) => state.general?.current_customer_id
  );
  const service = useChannelCustomerService({ customer_id });

  const [channel, setChannel] = useState<DtoDialog360Channel>();
  const [step, setStep] = useState<number | undefined>();
  const step1Ref = useRef<Step1Ref>(null);

  function handleNextStep() {
    step1Ref.current?.submit();
  }

  function handleSuccessAtStep1(channelData: DtoDialog360Channel) {
    addChannelToLocalStorage(channelData);
    setChannel(channelData);
    setStep(2);
  }

  function handleSuccessAtStep2() {
    removeChannelToLocalStorage();
    setStep(3);
  }

  function handleErrorAtStep2(msg?: string) {
    toast.error(msg ?? 'Erro ao conectar canal, repita o processo!');
  }

  //TODO: fix type location
  function handleWhitRouteLocationToSetStates(location: any) {
    if (location.search) {
      setStep(2);

      const channelStorageInfo = getChannelToLocalStorage();

      if (!channelStorageInfo) {
        handleErrorAtStep2(
          'Ocorreu um erro ao conectar o canal, entre em contato com o suporte!'
        );
        return;
      }

      setChannel(channelStorageInfo);

      const InfoFrom360 = getChannel360InfoFromQueryString(location.search);

      if (InfoFrom360?.success) {
        const dto = getApiKeyRequestDto(InfoFrom360, channelStorageInfo);
        service.send(dto, handleSuccessAtStep2, handleErrorAtStep2);
      } else {
        handleErrorAtStep2();
      }
    } else {
      setStep(1);
    }
  }

  useEffect(() => {
    handleWhitRouteLocationToSetStates(location);
  }, [location]);

  return (
    <PageMainBody>
      <div className="container360OnboardPage">
        <ProgressBarMigrateChannelToPoli title="Criação de canal WABA" />
        <br />
        <br />
        {step === 1 && (
          <Step1
            ref={step1Ref}
            customer_id={customer_id}
            onSuccess={(channelData: DtoDialog360Channel) => {
              handleSuccessAtStep1(channelData);
            }}
          />
        )}

        {step === 2 && (
          <Step2
            phone_number={channel?.phone!}
            channel_id={channel?.id!}
            loading={service.loading}
          />
        )}

        {step === 3 && <Step3 channel_id={channel?.id!} />}

        <div className="containerButtons360Onboard">
          <Row>
            <Col
              sm={{
                offset: 8,
                size: 'auto',
              }}
            >
              {step === 1 && (
                <Button color="primary" onClick={handleNextStep}>
                  Próximo
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </PageMainBody>
  );
}
