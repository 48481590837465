import * as React from 'react';
import { LogsChannelService } from '../../../../../../../services/tools/channels/logsChannels/logs/channels';
import { DtoLogsChannel } from '../../../../../../../services/tools/channels/logsChannels/types/dtoLogsChannel';
import { DtoPaginatedResult } from '../../../../../../../services/tools/channels/logsChannels/types/dtoPagination';
import { LocalFormValues } from '../../../FilterLogs/useLocalForm/types';
import { initialSerachParams, paginationInitialValues } from './initialValues';
import { SearchParams } from './types';
import { toast } from 'react-toastify';

export interface useLogsChannelsServiceProps {
  customerFilter: number;
}

export function useLogsChannelsService(props?: useLogsChannelsServiceProps) {
  const [data, setData] =
    React.useState<DtoPaginatedResult<DtoLogsChannel>>();
  const [loading, setLoading] = React.useState(false);
  const [params, setParams] = React.useState<SearchParams>(initialSerachParams);

  const search = React.useCallback(
    async (params: SearchParams, customerFilter: number) => {
      try {
        setLoading(true);
        const response = await LogsChannelService.getLogsChannels({
          customer_id: customerFilter,
          channel_id: params.filters.channel_id,
          type_change: params.filters.type_change,
          date_start: params.filters.date_start,
          date_end: params.filters.date_end,
          user_id: params.filters.user_id,
          page: params.pagination.page,
          per_page: params.pagination.per_page,
        });
        setData(response);
      } catch (error) {
        toast('Erro ao buscar Logs');
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const setFilters = React.useCallback((filters: LocalFormValues) => {
    setParams(() => ({
      filters,
      pagination: paginationInitialValues,
    }));
  }, []);

  const setPage = React.useCallback((page: number) => {
    setParams((prev: any) => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        page,
      },
    }));
  }, []);

  React.useEffect(() => {
    if (props?.customerFilter && params) {
      search(params, props?.customerFilter);
    }
  }, [search, params]);

  return { data, loading, setFilters, setPage };
}
