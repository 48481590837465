import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Label, Icon, CustomPassword, CustomInput, Button } from '@polichat/flamboyant';
import history from '../../../../../../services/history';
import ChannelsService from '../../../../../../services/tools/channels/channels';
import ButtonDoubts from '../../common/ButtonDoubts/ButtonDoubts.component';
import ButtonDeleteChannel from '../../../DeleteChannel/DeleteChannel.componet';
import ActionGroupButtons from '../../ActionGroupButtons/ActionGroupButtons.component';
import DeleteChannelModal from '../../../DeleteChannelModal/DeleteChannelModal.component';
import { ChannelsDeleteService } from '../../../../../../services/tools/channels/index';

import {
  Container,
  TextInfos,
  InputsContainer,
  InputItem,
} from './Token.styles';
import { toast } from 'react-toastify';

const Token = ({ data, dataSet, currentStep }) => {
  const customer_id = useSelector((state) => state.general.current_customer_id);
  const [checked, setChecked] = useState(false);
  const [nextButton, nextButtonSet] = useState(false);
  const [address, setAddress] = useState('');
  const [token, setToken] = useState('');

  const wlConfig = useSelector(
    // @ts-ignore
    (state) => state?.whitelabel?.config
  );

  // Actions to Actions Group Button component
  const goBack = () => history.push('/tools/channels');
  const handleNextAction = async () => {
    nextButtonSet(false);
    try {
      const result = await ChannelsService.createAddressToken({
        customer_id,
        channel_id: data.id,
        address,
        token,
      });

      if (result.data) {
        if (data?.config?.migrationToWabaStatus === 'started') {
          toast.success(
            'Processo de Migração para API Oficial concluído com sucesso.'
          );
        }

        const step = parseInt(currentStep) + 1;
        await ChannelsService.updateStepHomologation({
          customer_id,
          channel_id: data.id,
          step,
        });
        data = { ...data, waba: { address: address, token: token } };
        dataSet({ ...data, homologation: step });
      } else {
        nextButtonSet(true);
        toast.error('Valor inválido');
      }
    } catch (error) {
      console.log(
        'Error in Token Component when uptadeStepHomologation. Error => ',
        error
      );
    }
  };
  const handleChangeCheckBox = ({ target: { checked } }) => {
    setChecked(checked);
    nextButtonSet(checked);
  };

  return (
    <>
      <Container>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          {!wlConfig.hide_doubts_button && <ButtonDoubts />}
          <ButtonDeleteChannel
            channel={data}
            customer_id={customer_id}
            size={'sm'}
          />
        </div>

        <TextInfos>
          <span>
            Esta nova tela irá aparecer com a "API Key" e com o "Address".{' '}
          </span>
          <span>Copie as informações e insira nos campos abaixo.</span>
          <span>
            Confirme nesta tela que você copiou os dados e em seguida clique em
            "Close" para fechar
          </span>
        </TextInfos>

        <InputsContainer>
          <InputItem>
            <Label id="address-api-label" for="address-api">
              <Icon
                icon="poli-icon pi-link-fill"
                color="var(--green-polichat)"
                size="20"
              />
              ADDRESS
            </Label>
            <CustomPassword
              type="password"
              name="address-api"
              id="address-api"
              onChange={(e) => setAddress(e.target.value)}
              value={address}
            />
          </InputItem>

          <InputItem>
            <Label id="api-key-label" for="api-key">
              <Icon icon="poli-icon pi-key-fill" color="#0a9dbf" size="20" />
              API Key
            </Label>
            <CustomPassword
              type="password"
              name="api-key"
              id="api-key"
              onChange={(e) => setToken(e.target.value)}
              value={token}
            />
          </InputItem>
        </InputsContainer>

        <CustomInput
          type="checkbox"
          id="copyApiKey"
          label="Copiei e salvei minha API Key"
          checked={checked}
          onChange={handleChangeCheckBox}
        />

        <ActionGroupButtons
          onClickNext={handleNextAction}
          onClickBack={goBack}
          nextButtonActived={!nextButton}
        />
      </Container>
    </>
  );
};

export default Token;
