import Avatar from '../profile/Avatar';
export default function List(props) {

  let list = props.list;
  let qtde_variants = 0;
  let onClick = props.onClick || (() => {});
  if (!props.keyTitle) return null;
  if (list && list.length) {
    if (props.ordering) {
      if (props.ordering === 'alphabet') {
        let maxItems = 1000;
        let variants =
          '|àáãâäÀÁÃÂÄ:a|èéêëÈÉÊË:e|ìíîïÌÍÎÏ:i|òóôõöÒÓÔÕÖ:o|ùúûüÙÚÛÜ:u|ñÑ:n|ç:C|';
        variants = variants.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        let aux = {
          _: [],
          a: [],
          b: [],
          c: [],
          d: [],
          e: [],
          f: [],
          g: [],
          h: [],
          i: [],
          j: [],
          k: [],
          l: [],
          m: [],
          n: [],
          o: [],
          p: [],
          q: [],
          r: [],
          s: [],
          t: [],
          u: [],
          v: [],
          w: [],
          x: [],
          y: [],
          z: [],
        };
        for (let i in list) {
          let letter = (list[i][props.keyTitle] || '')
            .trim()
            .substr(0, 1)
            .toLowerCase();
          if (letter && aux[letter]) {
            aux[letter].push(list[i]);
          } else if (aux['_']) {
            aux['_'].push(list[i]);
          } else {
            let regex = new RegExp(letter, 'g');
            letter = '_';
            if (regex.test(variants)) {
              let parts = variants.split('|');
              for (let variant of parts) {
                if (regex.test(variant)) {
                  letter = variant.substr(-1, 1);
                  break;
                }
              }
            }
            aux[letter].push(list[i]);
          }
        }
        list = [];
        for (let cat in aux) {
          if (aux[cat].length) {
            qtde_variants++;
            list.push({ __header__: cat });
            list = list.concat(aux[cat]);
          }
        }
        list = list.slice(0, maxItems);
      }
    }
    let items = list.map((item, id) => {
      if (item.__header__) {
        return (
          <li
            key={id}
            className={
              'itemListHeader' +
              (item.__header__ === '_' ? ' especialsChars' : '')
            }
          >
            {item.__header__ === '_' ? '9.*/$' : item.__header__.toUpperCase()}
          </li>
        );
      }
      if (typeof item == 'object') {
        let avatar = props.keyAvatar ? (
          <div className="avatarContainer">
            {typeof props.keyAvatar === 'function' ? (
              props.keyAvatar(item, id)
            ) : item[props.keyAvatar] ? (
              <div
                style={{ height: '30px', cursor: 'pointer', display: 'flex' }}
              >
                <Avatar
                  className="operator"
                  url={item[props.keyAvatar]}
                  cursor="default"
                />
              </div>
            ) : (
              ''
            )}
          </div>
        ) : null;

        return (
          <li
            key={id}
            className={'itemList item-' + id}
            onClick={() => {
              onClick(item, id);
            }}
          >
            {typeof props.contentBefore === 'function'
              ? props.contentBefore(item, id)
              : props.contentBefore || ''}
            {avatar}
            <div className="contentContainer">
              <span>
                {typeof props.keyTitle === 'function'
                  ? props.keyTitle(item, id)
                  : `${item[props.keyTitle]} ` || ''}
                {props.keySubtitle ? (
                  typeof props.keySubtitle === 'function' ? (
                    props.keySubtitle(item, id)
                  ) : (
                    <span>{item[props.keySubtitle] || ''}</span>
                  )
                ) : null}
                {props.keyDescription ? (
                  typeof props.keyDescription === 'function' ? (
                    props.keyDescription(item, id)
                  ) : (
                    <small>{item[props.keyDescription] || ''}</small>
                  )
                ) : null}
              </span>
            </div>
            {typeof props.contentAfter === 'function'
              ? props.contentAfter(item, id)
              : props.contentAfter || ''}
          </li>
        );
      } else {
        return (
          <li
            key={id}
            className={'itemList item-' + id}
            onClick={onClick(item, id)}
          >
            {item}
          </li>
        );
      }
    });
    return (
      <div className={(props.className || '') + ' listResults'}>
        <ul
          className={
            props.ordering === 'alphabet' ? 'vars_' + qtde_variants : ''
          }
        >
          {items}
        </ul>
      </div>
    );
  }
  return null;
}
