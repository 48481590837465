import React, { useCallback, useEffect, useState } from 'react';
import { Content } from './SelectCustomer.styles';

import Animation from '../../../../../common/animation';
import InputSearch from '../../../../../common/form/InputSearch';

import removeAccentString from '../../../../../../utils/removeAccentString';

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  /* @ts-ignore */
} from '@polichat/flamboyant';

/**
 * Types
 */
interface OnChange {
  (customerId: number): void;
}

type Customer = {
  id: number;
  name: string;
};

interface FooProp {
  customers: Customer[];
  value: number;
  onChange: OnChange;
}
/* end - types */

const SelectCustomer = (props: FooProp) => {
  const { customers, value, onChange } = props;
  const [customersAux, setCustomersAux] = useState<Customer[]>();

  const [pageCurrent, setPageCurrent] = useState<number>();
  const [maxPages, setMaxPages] = useState<number>();
  const perPage = 25;

  /**
   * Mounted
   */
  useEffect(() => {
    if (Array.isArray(customers)) {
      setCustomersAux(customers);
    }
  }, [customers]);
  /* end */

  /**
   * Nome do Empresa selecionado
   */
  const [selectedCustomerName, setSelectedCustomerName] = useState<string>();
  useEffect(() => {
    if (Array.isArray(customers)) {
      const found = customers.find((e) => e.id === value);
      setSelectedCustomerName(
        found && found?.name ? found?.name?.toString() : ''
      );
    }
  }, [value, customers]);
  /* end */

  /**
   * Quantas paginas tem essa lista de empresas?
   */
  useEffect(() => {
    if (Array.isArray(customersAux)) {
      const sizeArray = customersAux.length;
      const _maxPages = sizeArray / perPage;
      setPageCurrent(1);
      setMaxPages(Math.ceil(_maxPages));
    }
  }, [customersAux]);
  /* end */

  /**
   * Logica do campo de pesquisa
   */
  const [currentValueSearch, setCurrentValueSearch] = useState<string>();
  const [lastTimeout, setLastTimeout] = useState<number>();
  const [loadingSearch, setLoadingSearch] = useState<boolean>();

  const onUpdateSearchValue = (value: string) => {
    setLoadingSearch(true);
    setCustomersAux([]);

    if (lastTimeout && lastTimeout > 0) clearTimeout(lastTimeout);

    setCurrentValueSearch(value);

    const time = setTimeout(() => {
      setLoadingSearch(false);
      searchList(value);
    }, 1000);

    setLastTimeout(time ? parseInt(time.toString()) : time);
  };

  const searchList = (value = '') => {
    if (Array.isArray(customers)) {
      const result = customers.filter((item) => {
        const _name = `${item?.id} ${item?.name} `;
        return removeAccentString(_name.toLowerCase()).includes(
          removeAccentString(value?.toLowerCase())
        );
      });
      setCustomersAux(result);
    }
  };
  /* end - Logica do campo de pesquisa */

  /**
   * Monitora o scroll da lista de customers
   */
  const [observerScroll, setObserverScroll] = useState<boolean>();
  useEffect(() => {
    const ref = document.getElementById('listCustomerScroll');
    if (
      ref &&
      Array.isArray(customersAux) &&
      customersAux?.length > 0 &&
      observerScroll
    ) {
      ref.addEventListener('scroll', (evt: any) => {
        let sizeHeightContainer = ref.offsetHeight; // tamanho do container visível para o usuário
        let sizeHeightContainerScroll = evt.target.scrollHeight; // tamanho do container inteiro
        let scrollTop = evt.target.scrollTop; // quantidade de px foram rolados em uma transição

        const topPage = scrollTop === 0;
        const endPage =
          scrollTop + sizeHeightContainer >= sizeHeightContainerScroll;

        if (topPage) handleWhereIsPage({ top: true });
        if (endPage) handleWhereIsPage({ top: false });
      });
    }
  }, [observerScroll, customersAux?.length]);

  const [isPageTop, setIsPageTop] = useState<boolean>();
  const [updateScroll, setUpdateScroll] = useState<number>();
  const handleWhereIsPage = useCallback(({ top }: { top: boolean }) => {
    setIsPageTop(top);
    setUpdateScroll((prev) => (prev ?? 1) + 1);
  }, []);

  /* end - Monitora o scroll da lista de customers */

  /**
   * Encrementa mais uma pagina e coloca loading para nao ter loop infinito
   */
  const [loading, setLoading] = useState<boolean>();

  useEffect(() => {
    if (!loading) {
      const _pageCurrent = pageCurrent ?? 1;
      const _maxPages = maxPages ?? 1;

      /**
       * Logica subir
       */
      if (isPageTop && _pageCurrent > 1) {
        loadingListPage();
        setPageCurrent(_pageCurrent - 1);

        // Para nao da um loop de scroll
        setTimeout(() => {
          const ref = document.getElementById('listCustomerScroll');
          if (ref) ref.scrollTop = 100;
        }, 600);
      }
      /* end */

      /**
       * Logicar descer
       */
      if (!isPageTop && _pageCurrent < _maxPages) {
        loadingListPage();
        setPageCurrent(_pageCurrent + 1);

        setTimeout(() => {
          const ref = document.getElementById('listCustomerScroll');
          if (ref) ref.scrollTop = 30;
        }, 600);
      }
      /* end */
    }
  }, [updateScroll]);

  const loadingListPage = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };
  /* End */

  return (
    <Content>
      <UncontrolledDropdown>
        <DropdownToggle
          color="neutral"
          caret
          id="select_custumers"
          name="select_custumers"
          onClick={() => {
            setObserverScroll(true);
          }}
          onChange={(e: any) => {
            if (e?.target?.value) {
              onChange(parseInt(e.target.value));
            }
          }}
        >
          <span className='textSearchCustomer'>
            {selectedCustomerName &&
            selectedCustomerName?.toString()?.length > 0
              ? selectedCustomerName
              : 'Selecione um cliente'}
          </span>
        </DropdownToggle>

        <DropdownMenu>
          <div className="list-containner">
            <InputSearch
              onChange={(e: any) => onUpdateSearchValue(e.target.value)}
              value={currentValueSearch ?? ''}
              loading={loadingSearch}
              placeholder=""
              clearSearch={onUpdateSearchValue}
            />
          </div>

          <div className="select-customer-dropdown" id="listCustomerScroll">
            {loading && isPageTop && <Animation icon="loading" size={'tiny'} />}

            {/* Nenhum resultado da pesquisa encontrado! */}
            {customersAux?.length === 0 &&
              !loadingSearch &&
              currentValueSearch &&
              currentValueSearch?.toString().length > 0 && (
                <p className="text-center">
                  <small>Nenhum resuldado encontrado!</small>
                </p>
              )}
            {/* end */}

            {/* Lista de Empresas */}
            {Array.isArray(customersAux) &&
              customersAux
                .slice(
                  (pageCurrent ?? 1) * perPage - perPage,
                  (pageCurrent ?? 1) * perPage
                )
                .map((item, index) => {
                  return (
                    <DropdownItem
                      key={index}
                      value={item.id}
                      className="dropItem-filters"
                      onClick={(e: any) => {
                        if (e?.target?.value) {
                          onChange(parseInt(e.target.value));
                        }
                      }}
                    >
                      {/* Nome que irar exixir no select */}
                      {item?.name?.toString().length > 0 ? (
                        `${item?.id} - ${item?.name}`
                      ) : (
                        <div>
                          {`${item?.id} - `}
                          <small
                            style={{ opacity: '50%', fontStyle: 'italic' }}
                          >
                            Sem Nome
                          </small>
                        </div>
                      )}
                      {/* end */}
                    </DropdownItem>
                  );
                })}
            {/* end */}

            {loading && !isPageTop && (
              <Animation icon="loading" size={'tiny'} />
            )}
          </div>
        </DropdownMenu>
      </UncontrolledDropdown>
    </Content>
  );
};

export default SelectCustomer;
