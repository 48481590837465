import { useState } from 'react';
import { Col, FormGroup, Label, CustomInput } from '@polichat/flamboyant';

function SelectChannelWaba({
  onUpdate,
  initialValue: { initialChannelCustomer, channelsWabaList },
  isEdit,
}) {
  const [wabaChannel, setWabaChannel] = useState({
    templateChannelCustomer: initialChannelCustomer,
  });

  const handleInputChange = ({ target: { name, value } }) => {
    setWabaChannel({
      [name]: Number(value),
    });
    onUpdate({
      [name]: Number(value),
    });
  };

  return (
    <Col sm={12} md={6}>
      <FormGroup>
        <Label>Canal da template:</Label>
          <CustomInput
            type="select"
            id="template-customer-channel"
            name="templateChannelCustomer"
            value={wabaChannel.templateChannelCustomer}
            onChange={handleInputChange}
            required
            disabled={isEdit}
          >
            {channelsWabaList.map((channel) => (
              <option key={channel.id} value={channel.id}>
                {`${channel.name} - ${channel.phone}`}
              </option>
            ))}
          </CustomInput>
      </FormGroup>
    </Col>
  );
}

export default SelectChannelWaba;
