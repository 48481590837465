import { LoaderMain } from 'src/components/common/animation/loader';

function MessageLoading() {
  // const hasDarkMode = document.getElementsByClassName('darkMode').length > 0 ? true : false;

  return (
    <div className="messagesLoadingContainer">
      <LoaderMain />
    </div>
  );
}

export default MessageLoading;
