import { useCallback, useEffect, useState } from 'react';
import { AccountMetaService } from 'src/services/meta-ads/accountMeta';
import { DtoMetaAdsAccountData } from 'src/services/meta-ads/dto/dtoMetaAdsAccountData';
import { customerHasFacebookInstalled } from './helper';

interface LocalError {
  error: boolean;
  msg?: string;
}

export interface UseCustomerHasPluginInstalledProps {
  customer_id: number;
}
export function useCustomerHasPluginInstalled(
  props: UseCustomerHasPluginInstalledProps
) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<DtoMetaAdsAccountData>();
  const [error, setError] = useState<LocalError>({ error: false });

  const load = useCallback(async (customer_id: number) => {
    try {
      setLoading(true);

      const customerAccount = await AccountMetaService.getExternalBusiness(
        customer_id
      );
      setData(customerAccount);

      setLoading(false);
      setError({ error: false });
    } catch (error) {
      setError({
        error: true,
        msg: 'Erro ao buscar o account_meta ',
      });
      setLoading(false);
    }
  }, []);

  const uninstall = useCallback(
    async (
      accountMetaId: string,
      onSuccess: () => void,
      onError: () => void
    ) => {
      try {
        setLoading(true);

        const result = await AccountMetaService.delete(accountMetaId);

        onSuccess();
        setLoading(false);
      } catch (error) {
        setError({
          error: true,
          msg: 'Erro ao remover facebook plugin account meta',
        });
        onError();
        setLoading(false);
      }
    },
    []
  );

  useEffect(() => {
    if (props.customer_id) load(props.customer_id);
  }, [props.customer_id]);

  return {
    loading,
    hasAccountMetaInstall: customerHasFacebookInstalled(data!),
    accountMetaId: data?.id,
    error,
    uninstall,
  };
}
