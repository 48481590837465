import React from 'react';
import { connect } from 'react-redux';
import { dtmToFormatString } from '../../../../utils/dtmToFormatString';

function TimeMessage({ props, timezone }) {
  let time = props.time;
  /**
   * Retorna string no formato 24H, com hora e minutos com dois digitos cada.
   * Caso a timezone esteja errada, retorna o horário convertido
   * com o timezone de São Paulo
   */
  const messageTime = dtmToFormatString(time, timezone, {
    hour: '2-digit',
    minute: '2-digit',
  });

  let style = styles.time;

  if (props.send) {
    style = {
      ...style,
      color: 'var(--quartet-font-color)',
    };
  }

  return (
    <span style={style} className="timeMessage">
      {messageTime}
    </span>
  );
}

const styles = {};
const mapStateToProps = (state, ownProps) => ({
  props: ownProps,
  timezone: state.general?.user?.settings?.timezone?.[0],
});

export default connect(mapStateToProps)(TimeMessage);
