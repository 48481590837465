import { isMobile } from 'react-device-detect';
import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100%;
`;

const fadeIn = keyframes`
  from { opacity : 0 }
  to { opacity: 1 }
`;

export const MessageBuilderHome = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  max-width: 80%;
  height: 100%;
  margin: auto;
  padding: 1rem 0;

  & img {
    animation: ${fadeIn} 2s;
    width: 100%;
    height: auto;
    max-width: 300px;
  }

  & p {
    margin-bottom: 0;
    text-align: center;
    font-size: 1rem;

    max-width: 80%;
  }

  & p:first-of-type {
    font-weight: bold;
    font-size: 1.5rem;
  }

  /* Tablets */
  @media (max-width: 768px) {
    max-width: 90%;
    gap: 0.5rem;

    & p {
      max-width: 90%;
    }
  }

  /* Mobile */
  @media (max-width: 600px) {
    max-width: 100%;
    justify-content: flex-start;
    padding: 1rem 0;
    gap: 0;
    overflow-y: auto;
    overflow-x: hidden;

    & img {
      max-width: 200px;
      max-height: 200px;
    }

    & p {
      max-width: 100%;
      font-size: 0.8rem;
      margin-bottom: 0.5rem;
    }

    & p:first-of-type {
      font-size: 1rem;
      margin-bottom: 0;
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

export const MessageBuilderLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;

  height: 100%;
  width: 100%;

  & .header {
    margin: 0.5rem;
    display: grid;
    grid-template-columns: 3fr 3fr 3fr;
    grid-template-rows: 1fr;
    gap: 1rem;
  }

  & .body {
    height: 100%;
    overflow-y: auto;
  }

  & .footer {
    height: fit-content;
    & .pagination {
      margin-bottom: 0;
    }
  }
`;

export const Filter = styled.div`
  & .input-group-search .form-group {
    width: 90%;
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    gap: 0;

    & .input-group-search .form-group {
      width: 100%;
    }
  }
`;
