import { getListCustomer } from 'src/services/credits/request';
interface getCustomerProps {
  setCustomers(value: any): void;
}

export const getCustomer = async (props: getCustomerProps) => {
  try {
    const result = await getListCustomer();
    if (result?.data.success && Array.isArray(result?.data?.data)) {
      const _resData = result.data.data;
      // Order Name
      _resData.sort((a: any, b: any) => {
        const _aName = a.name?.toString().length > 0 ? a.name : 'Sem Nome';
        const _bName = b.name?.toString().length > 0 ? b.name : 'Sem Nome';
        return _aName.localeCompare(_bName);
      });

      props.setCustomers(_resData);
    }
  } catch (error) {
    console.error('Error on Channel List: getListCustomer', error);
  }
};
