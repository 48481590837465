import poliApiGatewayRequest from '../../../utils/poliApiGatewayRequest';
import poliConfig from '../../../utils/poliConfig';

import { DtoMetaAdsPaginatedResult } from '../dto/dtoMetaAdsPaginatedResult';
import { MetaAdsPaginationParams } from '../dto/dtoMetaAdsPaginationParams';
import { DtoPoliAdsPost } from './types';

export class PostService {
  static async ListPostMetrics(
    organizationId: string,
    params: MetaAdsPaginationParams
  ) {
    const url = `${poliConfig.apiGatewayBaseURL}/post/${organizationId}`;

    const result = await poliApiGatewayRequest.get<
      DtoMetaAdsPaginatedResult<DtoPoliAdsPost[]>
    >(url, { params });

    return result.data;
  }
}
